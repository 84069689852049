import React, { createContext, useState } from "react";
import defaultTranslate from "../data/defaultLanguage";

export const StoreContext = createContext();

const StoreContextProvider = (props) => {
  const [translation, setTranslation] = useState(() => {
    const localData = localStorage.getItem("completetraslation");
    return localData ? JSON.parse(localData) : defaultTranslate;
  });
  const [translationId, setTranslationId] = useState(() => {
    const tranId = localStorage.getItem("prevodidentifier");
    return tranId ? tranId : [];
  });
  const [reciter, setReciter] = useState(() => {
    const reciterId = localStorage.getItem("audioidentifier");
    return reciterId ? reciterId : 'ar.husary';
  });
  const [mode, setMode] = useState(() => {
    const modeId = localStorage.getItem("modeofreadingpage");
    return modeId ? modeId : 1;
  });
  const [recentPage, setRecentPage] = useState(() => {
    const recent = localStorage.getItem("recentPage");
    return recent ? recent : 3;
  });
    
  const [menuHide, setMenuHide] = useState(true);

  const updateTranslation = (dataw, translateIdentifier) => {
    localStorage.setItem("completetraslation", dataw);
    localStorage.setItem("prevodidentifier", translateIdentifier);
    setTranslation(JSON.parse(dataw));
    setTranslationId(translateIdentifier);
  };
  const updateReciter = (audioIdentifier) => {
    localStorage.setItem("audioidentifier", audioIdentifier);
    setReciter(audioIdentifier);
  };
  const updateMode = (modeNo) => {
    localStorage.setItem("modeofreadingpage", modeNo);
    setMode(modeNo);
  };
  const updateRecentPage = (pageNo) => {
    localStorage.setItem("recentPage", pageNo);
    setRecentPage(pageNo);
    console.log(pageNo);
  };

  return (
    <StoreContext.Provider
      value={{ translation, translationId, updateTranslation, reciter, updateReciter, mode, updateMode, menuHide, setMenuHide, recentPage, updateRecentPage }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
