export default [
    {
        "englishName": "Džuz 1",
        "data": [
            {
                "number": 1,
                "page": 1,
                "sura": "El-Fatiha",
                "startajah": 1
            },
            {
                "number": 2,
                "page": 11,
                "sura": "El-Bekara",
                "startajah": 75
            }
        ]
    },
    {
        "englishName": "Džuz 2",
        "data": [
            {
                "number": 3,
                "page": 22,
                "sura": "El-Bekara",
                "startajah": 142
            },
            {
                "number": 4,
                "page": 32,
                "sura": "El-Bekara",
                "startajah": 203
            }
        ]
    },
    {
        "englishName": "Džuz 3",
        "data": [
            {
                "number": 5,
                "page": 42,
                "sura": "El-Bekara",
                "startajah": 253
            },
            {
                "number": 6,
                "page": 51,
                "sura": "Ali-Imran",
                "startajah": 15
            }
        ]
    },
    {
        "englishName": "Džuz 4",
        "data": [
            {
                "number": 7,
                "page": 62,
                "sura": "Ali-Imran",
                "startajah": 93
            },
            {
                "number": 8,
                "page": 72,
                "sura": "Ali-Imran",
                "startajah": 171
            }
        ]
    },
    {
        "englishName": "Džuz 5",
        "data": [
            {
                "number": 9,
                "page": 82,
                "sura": "En-Nisa",
                "startajah": 24
            },
            {
                "number": 10,
                "page": 92,
                "sura": "En-Nisa",
                "startajah": 88
            }
        ]
    },
    {
        "englishName": "Džuz 6",
        "data": [
            {
                "number": 11,
                "page": 102,
                "sura": "En-Nisa",
                "startajah": 148
            },
            {
                "number": 12,
                "page": 112,
                "sura": "El-Maida",
                "startajah": 27
            }
        ]
    },
    {
        "englishName": "Džuz 7",
        "data": [
            {
                "number": 13,
                "page": 121,
                "sura": "El-Maida",
                "startajah": 82
            },
            {
                "number": 14,
                "page": 132,
                "sura": "El-An'aam",
                "startajah": 36
            }
        ]
    },
    {
        "englishName": "Džuz 8",
        "data": [
            {
                "number": 15,
                "page": 142,
                "sura": "El-An'aam",
                "startajah": 111
            },
            {
                "number": 16,
                "page": 151,
                "sura": "El-A'raaf",
                "startajah": 1
            }
        ]
    },
    {
        "englishName": "Džuz 9",
        "data": [
            {
                "number": 17,
                "page": 162,
                "sura": "El-A'raaf",
                "startajah": 88
            },
            {
                "number": 18,
                "page": 173,
                "sura": "El-A'raaf",
                "startajah": 171
            }
        ]
    },
    {
        "englishName": "Džuz 10",
        "data": [
            {
                "number": 19,
                "page": 182,
                "sura": "El-Anfaal",
                "startajah": 41
            },
            {
                "number": 20,
                "page": 192,
                "sura": "Et-Tavba",
                "startajah": 34
            }
        ]
    },
    {
        "englishName": "Džuz 11",
        "data": [
            {
                "number": 21,
                "page": 201,
                "sura": "Et-Tavba",
                "startajah": 93
            },
            {
                "number": 22,
                "page": 212,
                "sura": "Junus",
                "startajah": 26
            }
        ]
    },
    {
        "englishName": "Džuz 12",
        "data": [
            {
                "number": 23,
                "page": 222,
                "sura": "Hud",
                "startajah": 6
            },
            {
                "number": 24,
                "page": 231,
                "sura": "Hud",
                "startajah": 84
            }
        ]
    },
    {
        "englishName": "Džuz 13",
        "data": [
            {
                "number": 25,
                "page": 242,
                "sura": "Jusuf",
                "startajah": 53
            },
            {
                "number": 26,
                "page": 252,
                "sura": "Er-Ra'd",
                "startajah": 19
            }
        ]
    },
    {
        "englishName": "Džuz 14",
        "data": [
            {
                "number": 27,
                "page": 262,
                "sura": "El-Hidžr",
                "startajah": 1
            },
            {
                "number": 28,
                "page": 272,
                "sura": "El-Nahl",
                "startajah": 51
            }
        ]
    },
    {
        "englishName": "Džuz 15",
        "data": [
            {
                "number": 29,
                "page": 282,
                "sura": "El-Israa",
                "startajah": 1
            },
            {
                "number": 30,
                "page": 292,
                "sura": "El-Israa",
                "startajah": 99
            }
        ]
    },
    {
        "englishName": "Džuz 16",
        "data": [
            {
                "number": 31,
                "page": 302,
                "sura": "El-Kehf",
                "startajah": 75
            },
            {
                "number": 32,
                "page": 312,
                "sura": "Ta-Ha",
                "startajah": 1
            }
        ]
    },
    {
        "englishName": "Džuz 17",
        "data": [
            {
                "number": 33,
                "page": 322,
                "sura": "El-Anbija",
                "startajah": 1
            },
            {
                "number": 34,
                "page": 332,
                "sura": "El-Hadž",
                "startajah": 1
            }
        ]
    },
    {
        "englishName": "Džuz 18",
        "data": [
            {
                "number": 35,
                "page": 342,
                "sura": "El-Muminun",
                "startajah": 1
            },
            {
                "number": 36,
                "page": 352,
                "sura": "En-Nur",
                "startajah": 21
            }
        ]
    },
    {
        "englishName": "Džuz 19",
        "data": [
            {
                "number": 37,
                "page": 362,
                "sura": "El-Furqaan",
                "startajah": 21
            },
            {
                "number": 38,
                "page": 371,
                "sura": "El-Šu'araa",
                "startajah": 111
            }
        ]
    },
    {
        "englishName": "Džuz 20",
        "data": [
            {
                "number": 39,
                "page": 382,
                "sura": "El-Naml",
                "startajah": 56
            },
            {
                "number": 40,
                "page": 392,
                "sura": "El-Kasas",
                "startajah": 51
            }
        ]
    },
    {
        "englishName": "Džuz 21",
        "data": [
            {
                "number": 41,
                "page": 402,
                "sura": "El-Ankabut",
                "startajah": 46
            },
            {
                "number": 42,
                "page": 413,
                "sura": "Lukman",
                "startajah": 22
            }
        ]
    },
    {
        "englishName": "Džuz 22",
        "data": [
            {
                "number": 43,
                "page": 422,
                "sura": "El-Ahzaab",
                "startajah": 31
            },
            {
                "number": 44,
                "page": 431,
                "sura": "Saba",
                "startajah": 24
            }
        ]
    },
    {
        "englishName": "Džuz 23",
        "data": [
            {
                "number": 45,
                "page": 442,
                "sura": "Jasin",
                "startajah": 28
            },
            {
                "number": 46,
                "page": 451,
                "sura": "Es-Saaffaat",
                "startajah": 145
            }
        ]
    },
    {
        "englishName": "Džuz 24",
        "data": [
            {
                "number": 47,
                "page": 462,
                "sura": "Ez-Zumar",
                "startajah": 32
            },
            {
                "number": 48,
                "page": 472,
                "sura": "Ghafir",
                "startajah": 41
            }
        ]
    },
    {
        "englishName": "Džuz 25",
        "data": [
            {
                "number": 49,
                "page": 482,
                "sura": "Fussilet",
                "startajah": 47
            },
            {
                "number": 50,
                "page": 491,
                "sura": "Ez-Zuhruf",
                "startajah": 24
            }
        ]
    },
    {
        "englishName": "Džuz 26",
        "data": [
            {
                "number": 51,
                "page": 502,
                "sura": "Ez-Ahqaf",
                "startajah": 1
            },
            {
                "number": 52,
                "page": 513,
                "sura": "El-Feth",
                "startajah": 18
            }
        ]
    },
    {
        "englishName": "Džuz 27",
        "data": [
            {
                "number": 53,
                "page": 522,
                "sura": "Az-Zaarijat",
                "startajah": 31
            },
            {
                "number": 54,
                "page": 531,
                "sura": "Er-Rahmaan",
                "startajah": 1
            }
        ]
    },
    {
        "englishName": "Džuz 28",
        "data": [
            {
                "number": 55,
                "page": 542,
                "sura": "El-Mudžaadela",
                "startajah": 1
            },
            {
                "number": 56,
                "page": 553,
                "sura": "El-Džumu'a",
                "startajah": 1
            }
        ]
    },
    {
        "englishName": "Džuz 29",
        "data": [
            {
                "number": 57,
                "page": 562,
                "sura": "El-Mulk",
                "startajah": 1
            },
            {
                "number": 58,
                "page": 572,
                "sura": "El-Džinn",
                "startajah": 1
            }
        ]
    },
    {
        "englishName": "Džuz 30",
        "data": [
            {
                "number": 59,
                "page": 582,
                "sura": "El-Naba",
                "startajah": 1
            },
            {
                "number": 60,
                "page": 591,
                "sura": "El-A'laa",
                "startajah": 1
            }
        ]
    }
]