import Bss from "../data/bs";
import Juzbs from "../data/juzbs";
import Surahbs from "../data/surabs";

let bs = {
  translation: {
    "Home title": "Plemeniti Kuran",
    "English": "Engleski",
    "Bosnian": "Bosanski",
    "German": "Njemački",
    "Home search": "Pretraga po arapskom tekstu, prijevodu ili broju stranice",
    "Search text": "Ukucajte text ili broj stranice...",
    "Quick links": "Brzi linkovi",
    "Recent page": "Posljednja otvarana",
    "Al-Faatiha": "El-Fatiha",
    "Last two ayahs Al-Baqara": "Poslednja dva ajeta El-Bekare",
    "Ayatul Kursi": "Ajetul Kursija",
    "Al-Kehf": "El-Kef",
    "Surah Yaseen": "Sura Jasin",
    "Surah Ar-Rahmaan": "Sura Er-Rahmaan",
    "Al-Mulk": "El-Mulk",
    "Three surahs": "El-Ihlaas, El-Felek, En-Naas",
    "Mobile Apps": "Mobilne aplikacije",
    "Contact": "Kontakt",
    "Surah": "Sure",
    "Juz": "Džuz",
    "Duas": "Dove",
    "The Noble Quran": "Plemeniti Kuran",
    "Duas from the Quran": "Dove iz Kurana",
    "Download": "Preuzmite",
    "Set the settings": "Izaberite prevod i učača",
    "Select Translation": "Prevodi",
    "Select Audio": "Učači",
    "DuasData": Bss,
    "JuzData": Juzbs,
    "SuraData": Surahbs
  },
};

export default bs;
