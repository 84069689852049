import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import translateData from "../data/completeByPage";
import { StoreContext } from "../contexts/StoreContext";
import { ThemeContext } from "../contexts/ThemeContext";
import ShareLinks from "../components/ShareLinks";
import { useTranslation } from "react-i18next";

let arr1 = translateData;
let newdata = [];

function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const { translation, recentPage } = useContext(StoreContext);
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  const [pretraga, setPretraga] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const [translateone, setTranslateone] = useState(() => {
    let arraymerge = arr1.slice();
    arraymerge.map((item, index) => {
      Object.values(item)[0].map((item2, index2) => {
        item2.texttransl = Object.values(translation[index])[0][index2]["text"];
      });
    });
    arraymerge.reverse();
    return arraymerge ? arraymerge : arr1;
  });
  const [recent, setRecent] = useState(() => {
    let recentArray = arr1.slice();
    let recentObj = {};
    let initrecentpage = 1 + (603 - recentPage);
    recentArray.map((item, index) => {
      Object.values(item)[0].map((itat, index2) => {
        if (itat.numpage == initrecentpage) {
          recentObj.recentNumberPage = itat.numpage;
          recentObj.recetnSuraName = itat.englishName;
        }
      });
    });
    return recentObj;
  });
  const [searchData, setSearchData] = useState([]);

  const searchfunction = () => {
    if (pretraga) {
      newdata = [];
      let query = pretraga;
      let data = translateone;
      let parsepretraga = parseInt(pretraga);
      if (isNaN(parsepretraga)) {
        data.map((item, key) => {
          Object.values(item)[0].map((itat, keys) => {
            if (
              itat.texttransl.includes(query) ||
              itat.textar.includes(query)
            ) {
              newdata.push(itat);
            }
          });
        });
        setSearchData(newdata);
      } else {
        let suraname = "";
        arr1.map((item, key) => {
          Object.values(item)[0].map((itat, keys) => {
            if (itat.numpage === parsepretraga) {
              return (suraname = itat.englishName);
            }
          });
        });
        history.push("/surah/" + suraname + "/page/" + parsepretraga);
      }
    }
  };

  const searchHandler = (event) => {
    setPretraga(event.target.value);
    // searchfunction();
  };

  const handleSubmit = (event) => {
    setSearchShow(true);
    searchfunction();
    event.preventDefault();
  };

  useEffect(() => {
    console.log(recentPage);
  }, [recentPage]);

  return (
    <div className="Home scrolling" style={{ background: theme.background }}>
      <div className="Home-banner-wrapper" style={{ background: theme.bg }}>
        <div
          className="Home-banner"
          style={{ background: theme.ui, color: theme.syntax }}
        >
          <img
            className="Banner-logos"
            src="/images/quran-logo-white.png"
            alt="The Noble Quran"
          />
          <h1 className="Banner-title">{t("Home title")}</h1>
          <div className="Search">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder={t("Search text")}
                onChange={searchHandler}
              />
              <button type="submit">
                <IoIosSearch
                  className="Search-icon"
                  style={{ background: theme.ui, color: theme.lightfont }}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="Search-wrapper-parent">
        <div
          className="Search-wrapper"
          style={{ background: theme.background }}
        >
          {!searchShow ? (
            <div
              className="Search-info"
              style={{ boxShadow: `0px 1px 3px 1px ${theme.border}` }}
            >
              <h4 className="Search-text" style={{ color: theme.syntax }}>
                {t("Home search")}
              </h4>
              <h4 className="Search-text" style={{ color: theme.syntax }}>
                {t("Quick links")}
              </h4>
              <ul className="Search-quick-list">
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname:
                        "/surah/" +
                        recent.recetnSuraName +
                        "/page/" +
                        recent.recentNumberPage,
                      data: {
                        pageNumber: recent.recentNumberPage,
                      },
                    }}
                  >
                    {t("Recent page")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Al-Faatiha/page/1",
                      data: {
                        pageNumber: 1,
                      },
                    }}
                  >
                    {t("Al-Faatiha")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Al-Baqara/page/49",
                      data: {
                        pageNumber: 50,
                      },
                    }}
                  >
                    {t("Last two ayahs Al-Baqara")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Al-Baqara/page/42",
                      data: {
                        pageNumber: 1,
                      },
                    }}
                  >
                    {t("Ayatul Kursi")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Al-Kahf/page/293",
                      data: {
                        pageNumber: 293,
                      },
                    }}
                  >
                    {t("Al-Kehf")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Yaseen/page/440",
                      data: {
                        pageNumber: 440,
                      },
                    }}
                  >
                    {t("Surah Yaseen")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Ar-Rahmaan/page/531",
                      data: {
                        pageNumber: 531,
                      },
                    }}
                  >
                    {t("Surah Ar-Rahmaan")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Al-Mulk/page/562",
                      data: {
                        pageNumber: 562,
                      },
                    }}
                  >
                    {t("Al-Mulk")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="Quick-links"
                    style={{ color: theme.links }}
                    to={{
                      pathname: "/surah/Al-Ihlas-Al-Falaq-An-Naas/page/604",
                      data: {
                        pageNumber: 604,
                      },
                    }}
                  >
                    {t("Three surahs")}
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            <div className="Search-box">
              {searchData.map((item, key) => (
                <Link
                  key={key}
                  style={{
                    color: theme.syntax,
                    borderBottomColor: theme.border,
                  }}
                  className="Search-items"
                  to={{
                    pathname:
                      "/surah/" +
                      `${item.englishName}` +
                      "/page/" +
                      `${item.numpage}`,
                    data: {
                      pageNumber: `${item.numpage}`,
                    },
                  }}
                >
                  <h4>{item.textar}</h4>
                  <h4>{item.texttransl}</h4>
                  <div>{item.numpage}</div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <ShareLinks />
    </div>
  );
}

export default Home;
