import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from '../contexts/ThemeContext';

function MenuLink({ title, urli }) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  return (
    <div className="Sidebar-links">
      <button className="Sidebar-button">
        <div className="Sidebar-button-title">
          <Link
            style={{ color: theme.syntax }}
            className="Sidebar-title-link"
            to={{
              pathname:
                "/" + `${urli}`,
            }}
          >
            {title}
          </Link>
        </div>
      </button>
    </div>
  );
}

export default MenuLink;
