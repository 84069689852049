export default [
    {
      surah: 'El-Fatiha',
      duas: [
        {
          id: '1',
          name: 'El-Fatiha 6-7',
          ayat: 'اهْدِنَا الصِّرَاطَ الْمُسْتَقِيم  صِرَاطَ الَّذِينَ أَنْعَمْتَ عَلَيْهِمْ غَيْرِ الْمَغْضُوبِ عَلَيْهِمْ وَلَا الضَّالِّينَ',
          text: 'Uputi nas na Pravi put! Na put onih kojim si blagodati podario, a ne na put onih na koje se srdžba izlila i koji su zalutali!'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'El-Bekare',
      duas: [
        {
          id: '2',
          name: 'El-Bekare 127',
          ayat: ' رَبَّنَا تَقَبَّلْ مِنَّا إِنَّكَ أَنْتَ السَّمِيعُ الْعَلِيمُ ',
          text: 'Gospodaru naš, primi od nas, jer Ti, uistinu, sve čuješ i sve znaš!'
        },
        {
          id: '3',
          name: 'El-Bekare 128',
          ayat: 'رَبَّنَا وَاجْعَلْنَا مُسْلِمَيْنِ لَكَ وَمِنْ ذُرِّيَّتِنَا أُمَّةً مُسْلِمَةً لَكَ وَأَرِنَا مَنَاسِكَنَا وَتُبْ عَلَيْنَا إِنَّكَ أَنْتَ التَّوَّابُ الرَّحِيمُ',
          text: 'Gospodaru naš, učini nas dvojicu Tebi odanim, i porod naš neka bude odan Tebi, i pokaži nam obrede naše i oprosti nam, jer Ti primaš pokajanje i samilostan si!'
        },
        {
          id: '4',
          name: 'El-Bekare 129',
          ayat: 'رَبَّنَا وَابْعَثْ فِيهِمْ رَسُولًا مِنْهُمْ يَتْلُو عَلَيْهِمْ آيَاتِكَ وَيُعَلِّمُهُمُ الْكِتَابَ وَالْحِكْمَةَ وَيُزَكِّيهِمْ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ',
          text: 'Gospodaru naš, pošalji im poslanika, jednog od njih, koji će im ajete Tvoje kazivati i Knjizi ih i mudrosti učiti i očistiti ih, jer Ti si, uistinu, silan i mudar!'
        },
        {
          id: '5',
          name: 'El-Bekare 201',
          ayat: 'وَمِنْهُمْ مَنْ يَقُولُ رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ',
          text: 'Gospodaru naš, podaj nam dobro i na ovome i na onome svijetu, i sačuvaj nas patnje u ognju!'
        },
        {
          id: '6',
          name: 'El-Bekare 250',
          ayat: 'وَلَمَّا بَرَزُوا لِجَالُوتَ وَجُنُودِهِ قَالُوا رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Gospodaru naš, nadahni nas izdržljivošću i učvrsti korake naše i pomozi nas protiv naroda koji ne vjeruje!'
        },
        {
          id: '7',
          name: 'El-Bekare 285',
          ayat: ' وَقَالُوا سَمِعْنَا وَأَطَعْنَا غُفْرَانَكَ رَبَّنَا وَإِلَيْكَ الْمَصِير',
          text: 'Čujemo i pokoravamo se; oprosti nam, Gospodaru naš; Tebi ćemo se vratiti.'
        },
        {
          id: '8',
          name: 'El-Bekare 286',
          ayat: 'رَبَّنَا لَا تُؤَاخِذْنَا إِنْ نَسِينَا أَوْ أَخْطَأْنَا رَبَّنَا وَلَا تَحْمِلْ عَلَيْنَا إِصْرًا كَمَا حَمَلْتَهُ عَلَى الَّذِينَ مِنْ قَبْلِنَا رَبَّنَا وَلَا تُحَمِّلْنَا مَا لَا طَاقَةَ لَنَا بِهِ وَاعْفُ عَنَّا وَاغْفِرْ لَنَا وَارْحَمْنَا أَنْتَ مَوْلَانَا فَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Gospodaru naš, ne kazni nas ako zaboravimo ili što nehotice učinimo! Gospodaru naš, ne tovari na nas breme kao što si ga tovario na one prije nas! Gospodaru naš, ne stavljaj nam u dužnost ono što ne možemo podnijeti, pobriši grijehe naše i oprosti nam, i smiluj se na nas. Ti si Gospodar naš pa nam pomozi protiv naroda koji ne vjeruje!'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'Ali-Imran',
      duas: [
        {
          id: '9',
          name: 'Ali-Imran 8-9',
          ayat: ' رَبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِنْ لَدُنْكَ رَحْمَةً إِنَّكَ أَنْتَ الْوَهَّابُ. رَبَّنَا إِنَّكَ جَامِعُ النَّاسِ لِيَوْمٍ لَا رَيْبَ فِيهِ إِنَّ اللَّهَ لَا يُخْلِفُ الْمِيعَادَ ',
          text: 'Gospodaru naš, ne dopusti srcima našim da skrenu, kada si nam već na Pravi put ukazao, i daruj nam Svoju milost; Ti si, uistinu, Onaj koji mnogo daruje! Gospodaru naš, Ti ćeš sakupiti sve ljude na Dan u koji nema nikakve sumnje." – Allah će, zaista, održati obećanje.'
        },
        {
          id: '10',
          name: 'Ali-Imran 16',
          ayat: '  رَبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّار ',
          text: 'Gospodaru naš, mi, zaista, vjerujemo; zato nam oprosti grijehe naše i sačuvaj nas patnje u ognju!'
        },
        {
          id: '11',
          name: 'Ali-Imran 38',
          ayat: ' رَبِّ هَبْ لِي مِنْ لَدُنْكَ ذُرِّيَّةً طَيِّبَةً إِنَّكَ سَمِيعُ الدُّعَاءِ',
          text: 'Gospodaru moj podari mi od Sebe čestita potomka, jer se Ti, uistinu, molbi odazivaš!'
        },
        {
          id: '12',
          name: 'Ali-Imran 53',
          ayat: 'رَبَّنَا آمَنَّا بِمَا أَنْزَلْتَ وَاتَّبَعْنَا الرَّسُولَ فَاكْتُبْنَا مَعَ الشَّاهِدِينَ',
          text: 'Gospodaru naš, mi u ono što Ti objavljuješ vjerujemo i mi poslanika slijedimo, zato nas upiši među vjernike!'
        },
        {
          id: '13',
          name: 'Ali-Imran 147',
          ayat: ' رَبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Gospodaru naš, oprosti nam krivice naše i neumjerenost našu u postupcima našim, i učvrsti korake naše i pomozi nam protiv naroda koji ne vjeruje!'
        },
        {
          id: '14',
          name: 'Ali-Imran 191-194',
          ayat: ' رَبَّنَا مَا خَلَقْتَ هَذَا بَاطِلًا سُبْحَانَكَ فَقِنَا عَذَابَ النَّارِ. رَبَّنَا إِنَّكَ مَنْ تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنْصَار. رَبَّنَا إِنَّنَا سَمِعْنَا مُنَادِيًا يُنَادِي لِلْإِيمَانِ أَنْ آمِنُوا بِرَبِّكُمْ فَآمَنَّا رَبَّنَا فَاغْفِرْ لَنَا ذُنُوبَنَا وَكَفِّرْ عَنَّا سَيِّئَاتِنَا وَتَوَفَّنَا مَعَ الْأَبْرَار. رَبَّنَا وَآتِنَا مَا وَعَدْتَنَا عَلَى رُسُلِكَ وَلَا تُخْزِنَا يَوْمَ الْقِيَامَةِ إِنَّكَ لَا تُخْلِفُ الْمِيعَادَ ',
          text: 'Gospodaru naš, Ti nisi ovo uzalud stvorio; hvaljen Ti budi i sačuvaj nas patnje u vatri!  Gospodaru naš, onoga koga Ti budeš u vatru ubacio Ti si već osramotio, a nevjernicima neće niko u pomoć priteći. Gospodaru naš, mi smo čuli glasnika koji poziva u vjeru: "Vjerujte u Gospodara vašeg!" – i mi smo mu se odazvali. Gospodaru naš, oprosti nam grijehe naše i pređi preko hrđavih postupaka naših, i učini da poslije smrti budemo s onima dobrima. Gospodaru naš, podaj nam ono što si nam obećao po poslanicima Svojim i na Sudnjem danu nas ne osramoti! Ti ćeš, doista, Svoje obećanje ispuniti! '
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'En-Nisa',
      duas: [
        {
          id: '15',
          name: 'En-Nisa 75',
          ayat: ' رَبَّنَا أَخْرِجْنَا مِنْ هَذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا',
          text: 'Gospodaru naš, izbavi nas iz ovoga grada, čiji su stanovnici nasilnici, i Ti nam odredi zaštitnika i Ti nam podaj onoga ko će nam pomoći!'
        }
      ],
      icon: 'cloudy-night'
    },
    {
        surah: 'El-Araf',
        duas: [
          {
            id: '16',
            name: 'El-Araf 23',
            ayat: '  رَبَّنَا ظَلَمْنَا أَنْفُسَنَا وَإِنْ لَمْ تَغْفِرْ لَنَا وَتَرْحَمْنَا لَنَكُونَنَّ مِنَ الْخَاسِرِينَ',
            text: 'Gospodaru naš sami smo sebi krivi, i ako nam Ti ne oprostiš i ne smiluješ nam se, sigurno ćemo biti izgubljeni.'
          },
          {
            id: '17',
            name: 'El-Araf 47',
            ayat: ' رَبَّنَا أَخْرِجْنَا مِنْ هَذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا',
            text: 'Gospodaru naš, ne daj nam da budemo s narodom grješnim!'
          },
          {
            id: '18',
            name: 'El-Araf 89',
            ayat: ' رَبَّنَا افْتَحْ بَيْنَنَا وَبَيْنَ قَوْمِنَا بِالْحَقِّ وَأَنْتَ خَيْرُ الْفَاتِحِينَ',
            text: 'Gospodaru naš, Ti presudi nama i narodu našem po pravdi, Ti si sudija najpravedniji!'
          },
          {
            id: '19',
            name: 'El-Araf 126',
            ayat: ' رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَتَوَفَّنَا مُسْلِمِينَ',
            text: 'Gospodaru naš, daj nam snage da izdržimo i učini da kao vjernici umremo!'
          },
          {
            id: '20',
            name: 'El-Araf 151',
            ayat: ' رَبِّ اغْفِرْ لِي وَلِأَخِي وَأَدْخِلْنَا فِي رَحْمَتِكَ وَأَنْتَ أَرْحَمُ الرَّاحِمِينَ',
            text: 'Gospodaru moj oprosti meni i bratu mome i učini da budemo pod okriljem Tvoje milosti, Ti si od milostivih najmilostiviji!'
          },
          {
            id: '21',
            name: 'El-Araf 155-156',
            ayat: ' أَنْتَ وَلِيُّنَا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الْغَافِرِينَ. وَاكْتُبْ لَنَا فِي هَذِهِ الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ إِنَّا هُدْنَا إِلَيْكَ',
            text: ' Ti si Gospodar naš, pa nam oprosti i smiluj nam se, jer Ti praštaš najviše; I dosudi nam milost na ovome svijetu, i na onome svijetu – mi se vraćamo Tebi! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Junus',
        duas: [
          {
            id: '22',
            name: 'Junus 85-86',
            ayat: ' عَلَى اللَّهِ تَوَكَّلْنَا رَبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِلْقَوْمِ الظَّالِمِينَ ',
            text: 'U Allaha se uzdamo! Gospodaru naš, ne učini da zbog nas dođu u iskušenje ljudi koji nasilje čine, i spasi nas, milošću Svojom, od naroda koji ne vjeruje!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Hud',
        duas: [
          {
            id: '23',
            name: 'Hud 47',
            ayat: ' َرَبِّ إِنِّي أَعُوذُ بِكَ أَنْ أَسْأَلَكَ مَا لَيْسَ لِي بِهِ عِلْمٌ وَإِلَّا تَغْفِرْ لِي وَتَرْحَمْنِي أَكُنْ مِنَ الْخَاسِرِينَ ',
            text: 'Gospodaru moj Tebi se ja utječem da Te više nikad ne zamolim za ono što ne znam! Ako mi ne oprostiš i ne smiluješ mi se, biću izgubljen.(47)'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Jusuf',
        duas: [
          {
            id: '24',
            name: 'Jusuf 101',
            ayat: '  فَاطِرَ السَّمَاوَاتِ وَالْأَرْضِ أَنْتَ وَلِيِّي فِي الدُّنْيَا وَالْآخِرَةِ تَوَفَّنِي مُسْلِمًا وَأَلْحِقْنِي بِالصَّالِحِينَ',
            text: 'O Stvoritelju nebesa i Zemlje, Ti si Zaštitnik moj i na ovome i na onome svijetu; daj da umrem kao musliman i pridruži me onima koji su dobri!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Ibrahim',
        duas: [
          {
            id: '25',
            name: 'Ibrahim 40-41',
            ayat: ' َرَبِّ اجْعَلْنِي مُقِيمَ الصَّلَاةِ وَمِنْ ذُرِّيَّتِي رَبَّنَا وَتَقَبَّلْ دُعَاءِ. رَبَّنَا اغْفِرْ لِي وَلِوَالِدَيَّ وَلِلْمُؤْمِنِينَ يَوْمَ يَقُومُ الْحِسَابُ',
            text: 'Gospodaru moj, daj da ja i neki potomci moji obavljamo molitvu; Gospodaru naš, Ti usliši molbu moju! Gospodaru naš, oprosti meni, i roditeljima mojim, i svim vjernicima – na Dan kad se bude polagao račun! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'El-Isra',
        duas: [
          {
            id: '26',
            name: 'El-Isra 80',
            ayat: ' َرَبِّ أَدْخِلْنِي مُدْخَلَ صِدْقٍ وَأَخْرِجْنِي مُخْرَجَ صِدْقٍ وَاجْعَلْ لِي مِنْ لَدُنْكَ سُلْطَانًا نَصِيرًا',
            text: 'Gospodaru moj, učini da umrem, a da si Ti zadovoljan mnome i učini da iz mrtvih ustanem, a da si Ti zadovoljan mnome, i daruj mi od Sebe snagu koja će mi pomoći!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'El-Kehf',
        duas: [
          {
            id: '27',
            name: 'El-Kehf 10',
            ayat: ' َرَبَّنَا آتِنَا مِنْ لَدُنْكَ رَحْمَةً وَهَيِّئْ لَنَا مِنْ أَمْرِنَا رَشَدًا',
            text: 'Gospodaru naš, daj nam Svoju milost i pruži nam u ovom našem postupku prisebnost'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Merjem',
        duas: [
          {
            id: '28',
            name: 'Merjem 5',
            ayat: ' هَبْ لِي مِنْ لَدُنْكَ وَلِيًّا',
            text: ' Pokloni mi od Sebe sina'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Ta-ha',
        duas: [
          {
            id: '29',
            name: 'Ta-ha 25-28',
            ayat: ' رَبِّ اشْرَحْ لِي صَدْرِي, وَيَسِّرْ لِي أَمْرِي, َاحْلُلْ عُقْدَةً مِنْ لِسَانِي, يَفْقَهُوا قَوْلِي ',
            text: ' Gospodaru moj učini prostranim prsa moja  i olakšaj zadatak moj; odriješi uzao sa jezika moga da bi razumjeli govor moj! '
          },
          {
            id: '30',
            name: 'Ta-ha 114 ',
            ayat: ' رَبِّ زِدْنِي عِلْمًا ',
            text: ' Gospodaru moj, Ti znanje moje proširi! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'El-Enbija',
        duas: [
          {
            id: '31',
            name: 'El-Enbija 87',
            ayat: ' لَا إِلَهَ إِلَّا أَنْتَ سُبْحَانَكَ إِنِّي كُنْتُ مِنَ الظَّالِمِينَ ',
            text: ' Nema boga osim Tebe, hvaljen neka si, a ja sam se zaista ogriješio prema sebi!'
          },
          {
            id: '32',
            name: 'El-Enbija 89',
            ayat: ' رَبِّ لَا تَذَرْنِي فَرْدًا وَأَنْتَ خَيْرُ الْوَارِثِينَ ',
            text: ' Gospodaru moj, ne ostavljaj me sama, a Ti si jedini vječan!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'El-Muminun',
        duas: [
          {
            id: '33',
            name: 'El-Muminun 29',
            ayat: '  رَبِّ أَنْزِلْنِي مُنْزَلًا مُبَارَكًا وَأَنْتَ خَيْرُ الْمُنْزِلِينَ ',
            text: ' Gospodaru moj, iskrcaj me na blagoslovljeno mjesto, Ti to najbolje umiješ! '
          },
          {
            id: '34',
            name: 'El-Muminun 97-98',
            ayat: ' رَبِّ أَعُوذُ بِكَ مِنْ هَمَزَاتِ الشَّيَاطِينِ, وَأَعُوذُ بِكَ رَبِّ أَنْ يَحْضُرُونِ ',
            text: ' Tebi se ja, Gospodaru moj, obraćam za zaštitu od priviđenja šejtanskih,  i Tebi se, Gospodaru moj, obraćam da me od njihova prisustva zaštitiš!'
          },
          {
            id: '35',
            name: 'El-Muminun 109',
            ayat: ' رَبَّنَا آمَنَّا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الرَّاحِمِينََ',
            text: ' Gospodaru naš, mi vjerujemo, zato nam oprosti i smiluj nam se, jer Ti si najmilostiviji! '
          },
          {
            id: '36',
            name: 'El-Muminun 118',
            ayat: ' رَبِّ اغْفِرْ وَارْحَمْ وَأَنْتَ خَيْرُ الرَّاحِمِينَ ',
            text: ' Gospodaru moj, oprosti i smiluj se, Ti si najmilostiviji! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'El-Furkan',
        duas: [
          {
            id: '37',
            name: 'El-Furkan 65-66',
            ayat: ' رَبَّنَا اصْرِفْ عَنَّا عَذَابَ جَهَنَّمَ إِنَّ عَذَابَهَا كَانَ غَرَامًا إِنَّهَا سَاءَتْ مُسْتَقَرًّا وَمُقَامًا ',
            text: ' Gospodaru naš, poštedi nas patnje u Džehennemu, jer je patnja u njemu, doista, propast neminovna, on je ružno prebivalište i boravište.'
          },
          {
            id: '38',
            name: 'El-Furkan 74',
            ayat: ' رَبَّنَا هَبْ لَنَا مِنْ أَزْوَاجِنَا وَذُرِّيَّاتِنَا قُرَّةَ أَعْيُنٍ وَاجْعَلْنَا لِلْمُتَّقِينَ إِمَامًا ',
            text: ' Gospodaru naš, podari nam u ženama našim i djeci našoj radost i učini da se čestiti na nas ugledaju!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Eš-Šuara',
        duas: [
          {
            id: '39',
            name: 'Eš-Šuara 83-87',
            ayat: ' رَبِّ هَبْ لِي حُكْمًا وَأَلْحِقْنِي بِالصَّالِحِينَ, وَاجْعَلْ لِي لِسَانَ صِدْقٍ فِي الْآخِرِينَ, وَاجْعَلْنِي مِنْ وَرَثَةِ جَنَّةِ النَّعِيمِ, وَاغْفِرْ لِأَبِي إِنَّهُ كَانَ مِنَ الضَّالِّينَ, وَلَا تُخْزِنِي يَوْمَ يُبْعَثُونَ ',
            text: ' Gospodaru moj, podari mi znanje i uvrsti me među one koji su dobri i učini da me po lijepom spominju oni što će poslije mene doći,  i učini me jednim od onih kojima ćeš džennetske blagodati darovati –  i ocu mome oprosti, on je jedan od zalutalih –  i ne osramoti me na Dan kad će ljudi oživljeni biti. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'En-Neml',
        duas: [
          {
            id: '40',
            name: 'En-Neml 19',
            ayat: ' رَبِّ أَوْزِعْنِي أَنْ أَشْكُرَ نِعْمَتَكَ الَّتِي أَنْعَمْتَ عَلَيَّ وَعَلَى وَالِدَيَّ وَأَنْ أَعْمَلَ صَالِحًا تَرْضَاهُ وَأَدْخِلْنِي بِرَحْمَتِكَ فِي عِبَادِكَ الصَّالِحِينَ ',
            text: ' Gospodaru moj, omogući mi da budem zahvalan na blagodati Tvojoj, koju si ukazao meni i roditeljima mojim, i da činim dobra djela na zadovoljstvo Tvoje, i uvedi me, milošću Svojom, među dobre robove Svoje! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'El-Kasas',
        duas: [
          {
            id: '41',
            name: 'El-Kasas 16',
            ayat: ' قَالَ رَبِّ إِنِّي ظَلَمْتُ نَفْسِي فَاغْفِرْ لِي فَغَفَرَ لَهُ إِنَّهُ هُوَ الْغَفُورُ الرَّحِيمُ ',
            text: ' Gospodaru moj ja sam sâm sebi zlo nanio, oprosti mi! '
          }
        ],
        icon: 'cloudy-night'
      },
       {
      surah: 'El-Ankebut',
        duas: [
          {
            id: '42',
            name: 'El-Ankebut 30 ',
            ayat: '  رَبِّ انْصُرْنِي عَلَى الْقَوْمِ الْمُفْسِدِينَ ',
            text: ' Gospodaru moj pomozi mi protiv naroda grješnog! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'El-Gafir (EL-Mumin)',
        duas: [
          {
            id: '43',
            name: 'El-Gafir 7-9',
            ayat: ' َرَبَّنَا وَسِعْتَ كُلَّ شَيْءٍ رَحْمَةً وَعِلْمًا فَاغْفِرْ لِلَّذِينَ تَابُوا وَاتَّبَعُوا سَبِيلَكَ وَقِهِمْ عَذَابَ الْجَحِيم, رَبَّنَا وَأَدْخِلْهُمْ جَنَّاتِ عَدْنٍ الَّتِي وَعَدْتَهُمْ وَمَنْ صَلَحَ مِنْ آبَائِهِمْ وَأَزْوَاجِهِمْ وَذُرِّيَّاتِهِمْ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ, وَقِهِمُ السَّيِّئَاتِ وَمَنْ تَقِ السَّيِّئَاتِ يَوْمَئِذٍ فَقَدْ رَحِمْتَهُ وَذَلِكَ هُوَ الْفَوْزُ الْعَظِيمُ',
            text: ' Gospodaru naš, Ti sve obuhvaćaš milošću i znanjem; zato oprosti onima koji su se pokajali i koji slijede Tvoj put i sačuvaj ih patnje u vatri! Gospodaru naš, uvedi ih u edenske vrtove, koje si im obećao, i pretke njihove i žene njihove i potomstvo njihovo – one koji su bili dobri; Ti si, uistinu, silan i mudar. I poštedi ih kazne zbog ružnih djela, jer koga Ti toga dana poštediš kazne zbog ružnih djela – Ti si mu se smilovao, a to će, zaista, veliki uspjeh biti! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Ed-Duhan',
        duas: [
          {
            id: '44',
            name: 'Ed-Duhan  12',
            ayat: ' رَبَّنَا اكْشِفْ عَنَّا الْعَذَابَ إِنَّا مُؤْمِنُونَ',
            text: ' Gospodaru naš, otkloni patnju od nas, mi ćemo, sigurno, vjerovati!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'El-Ahkaf',
        duas: [
          {
            id: '45',
            name: 'El-Ahkaf 15',
            ayat: ' رَبِّ أَوْزِعْنِي أَنْ أَشْكُرَ نِعْمَتَكَ الَّتِي أَنْعَمْتَ عَلَيَّ وَعَلَى وَالِدَيَّ وَأَنْ أَعْمَلَ صَالِحًا تَرْضَاهُ وَأَصْلِحْ لِي فِي ذُرِّيَّتِي إِنِّي تُبْتُ إِلَيْكَ وَإِنِّي مِنَ الْمُسْلِمِينََ',
            text: ' Gospodaru moj, dozvoli Mi da Ti budem zahvalan na blagodati koju si darovao meni i roditeljima mojim, i pomozi mi da činim dobra djela kojima ćeš zadovoljan biti, i učini dobrim potomke moje; ja se, zaista, kajem i odan sam Tebi. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'El-Hašr',
        duas: [
          {
            id: '46',
            name: 'El-Hašr 10',
            ayat: ' رَبَّنَا اغْفِرْ لَنَا وَلِإِخْوَانِنَا الَّذِينَ سَبَقُونَا بِالْإِيمَانِ وَلَا تَجْعَلْ فِي قُلُوبِنَا غِلًّا لِلَّذِينَ آمَنُوا رَبَّنَا إِنَّكَ رَءُوفٌ رَحِيمٌ َ',
            text: ' Gospodaru naš, oprosti nama i braći našoj koja su nas u vjeri pretekla i ne dopusti da u srcima našim bude imalo zlobe prema vjernicima; Gospodaru naš, ti si, zaista, dobar i milostiv! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'El-Mumtehine',
        duas: [
          {
            id: '47',
            name: 'El-Mumtehine 4-5 ',
            ayat: '  رَبَّنَا عَلَيْكَ تَوَكَّلْنَا وَإِلَيْكَ أَنَبْنَا وَإِلَيْكَ الْمَصِيرُ رَبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِلَّذِينَ كَفَرُوا وَاغْفِرْ لَنَا رَبَّنَا إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ َ',
            text: '  Gospodaru naš, u Tebe se uzdamo i Tebi se obraćamo i Tebi ćemo se vratiti. Ne dopusti, Gospodaru naš, da nas nevjernicima staviš u iskušenje i oprosti nam, Gospodaru naš! Ti si, zaista, Silni i Mudri.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Et-Tahrim',
        duas: [
          {
            id: '48',
            name: 'Et-Tahrim 8',
            ayat: '  رَبَّنَا أَتْمِمْ لَنَا نُورَنَا وَاغْفِرْ لَنَا إِنَّكَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ َ',
            text: '  Gospodaru naš" – govoriće oni – "učini potpunim svjetlo naše i oprosti nam jer Ti, doista, sve možeš.'
          },
          {
            id: '49',
            name: 'Et-Tahrim 11',
            ayat: '  رَبِّ ابْنِ لِي عِنْدَكَ بَيْتًا فِي الْجَنَّةِ َ',
            text: '  Gospodaru moj, sagradi mi kod Sebe kuću u Džennetu. '
          },
          {
            id: '50',
            name: 'Et-Tahrim 11',
            ayat: ' نَجِّنِي مِنَ الْقَوْمِ الظَّالِمِينََ',
            text: '  izbavi me od naroda nepravednog! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Nuh',
        duas: [
          {
            id: '51',
            name: 'Nuh 26-28',
            ayat: ' رَبِّ لَا تَذَرْ عَلَى الْأَرْضِ مِنَ الْكَافِرِينَ دَيَّارًا, إِنَّكَ إِنْ تَذَرْهُمْ يُضِلُّوا عِبَادَكَ وَلَا يَلِدُوا إِلَّا فَاجِرًا كَفَّارًا, رَبِّ اغْفِرْ لِي وَلِوَالِدَيَّ وَلِمَنْ دَخَلَ بَيْتِيَ مُؤْمِنًا وَلِلْمُؤْمِنِينَ وَالْمُؤْمِنَاتِ وَلَا تَزِدِ الظَّالِمِينَ إِلَّا تَبَارًا ',
            text: '  Gospodaru moj, ne ostavi na Zemlji nijednog nevjernika, jer, ako ih ostaviš, oni će robove Tvoje u zabludu zavoditi i samo će grješnika i nevjernika rađati! Gospodaru moj, oprosti meni, i roditeljima mojim, i onome koji kao vjernik u dom moj uđe, i vjernicima i vjernicama, a nevjernicima samo propast povećaj!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'El-Felek',
        duas: [
          {
            id: '52',
            name: 'El-Felek 1-5',
            ayat: ' أَعُوذُ بِرَبِّ الْفَلَق مِنْ شَرِّ مَا خَلَقَ وَمِنْ شَرِّ غَاسِقٍ إِذَا وَقَبَ وَمِنْ شَرِّ النَّفَّاثَاتِ فِي الْعُقَدِ وَمِنْ شَرِّ حَاسِدٍ إِذَا حَسَدَ ',
            text: 'Utječem se Gospodaru svitanja od zla onoga što On stvara, i od zla mrkle noći kada razastre tmine, i od zla smutljivca kad smutnje sije, i od zla zavidljivca kad zavist ne krije! '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'En-Nas',
        duas: [
          {
            id: '53',
            name: 'En-Nas 1-6',
            ayat: '  أَعُوذُ بِرَبِّ النَّاسِ مَلِكِ النَّاسِ إِلَهِ النَّاسِ مِنْ شَرِّ الْوَسْوَاسِ الْخَنَّاسِ الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ مِنَ الْجِنَّةِ وَالنَّاسِ ',
            text: 'Tražim zaštitu Gospodara ljudi, Vladara ljudi, Boga ljudi, od zla šejtana-napasnika, koji zle misli unosi u srca ljudi – od džina i od ljudi!'
          }
        ],
        icon: 'cloudy-night'
      }
  ];
  