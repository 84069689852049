import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

function CustomLink({ title, urli }) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  return (
    <div className="Sidebar-links">
      <button className="Sidebar-button">
        <div className="Sidebar-button-title">
          <a
            style={{ color: theme.syntax }}
            className="Sidebar-title-link"
            href={urli}
          >
            {title}
          </a>
        </div>
      </button>
    </div>
  );
}

export default CustomLink;
