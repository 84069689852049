import React, { Component, createContext } from 'react';

export const ThemeContext = createContext();

class ThemeContextProvider extends Component {
  state = {
    isLightTheme: true,
    light: { 
        syntax: '#333', 
        ui: '#85e0d8', 
        bg: '#49d0c5' ,
        lightfont: '#fff',
        background: '#fff', 
        links: '#49d0c5',
        border: '#ebebeb',
        sidebarbg: '#fff',
        audioBg: '#f1f3f4'
    },
    dark: { 
        syntax: '#fff', 
        ui: '#555', 
        bg: '#333',
        lightfont: '#fff',
        background: '#333',
        links: '#ddd',
        border: '#555',
        sidebarbg: '#000',
        audioBg: '#555'
    }
  }
  toggleTheme= () => {
    this.setState({ isLightTheme: !this.state.isLightTheme });
  }
  render() { 
    return (
      <ThemeContext.Provider value={{...this.state, toggleTheme: this.toggleTheme}}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}
 
export default ThemeContextProvider;