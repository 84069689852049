import Dee from "../data/de";
import Juzde from "../data/juzde";
import Surahde from "../data/surade";

let de = {
  translation: {
    "Home title": "Der edle Koran",
    "English": "Englisch",
    "Bosnian": "Bosnisch",
    "German": "Deutsche",
    "Home search": "Durchsuchen Sie den Edlen Koran nach arabischem Text, Übersetzungstext oder Seitenzahl",
    "Search text": "Geben Sie Text oder Seitenzahl ein...",
    "Quick links": "Schnelle Links für einige Suren und Ayahs",
    "Recent page": "Letzte Seite",
    "Al-Faatiha": "Al-Faatiha",
    "Last two ayahs Al-Baqara": "Die letzten beiden Ayahs Al-Baqara",
    "Ayatul Kursi": "Ayatul Kursi",
    "Al-Kehf": "Al-Kehf",
    "Surah Yaseen": "Surah Yaseen",
    "Surah Ar-Rahmaan": "Surah Ar-Rahmaan",
    "Al-Mulk": "Al-Mulk",
    "Three surahs": " Al-Ikhlaas, Al-Falaq, An-Naas",
    "Mobile Apps": "Mobile Apps",
    "Contact": "Kontakt",
    "Surah": "Surah",
    "Juz": "Juz",
    "Duas": "Duas",
    "The Noble Quran": "Der edle Koran",
    "Duas from the Quran": "Gebete aus dem Quran",
    "Download": "Download",
    "Set the settings": "Stellen Sie die Einstellungen des Edlen Korans ein",
    "Select Translation": "Wählen Sie Übersetzung",
    "Select Audio": "Wählen Sie Audioreciter aus",
    "DuasData": Dee,
    "JuzData": Juzde,
    "SuraData": Surahde

  },
};

export default de;
