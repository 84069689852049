export default [
    {
      surah: 'Al-Faatiha',
      duas: [
        {
          id: '1',
          name: 'Al-Faatiha 6-7',
          ayat: 'اهْدِنَا الصِّرَاطَ الْمُسْتَقِيم  صِرَاطَ الَّذِينَ أَنْعَمْتَ عَلَيْهِمْ غَيْرِ الْمَغْضُوبِ عَلَيْهِمْ وَلَا الضَّالِّينَ',
          text: 'Leite uns den geraden Weg, den Weg derjenigen, denen Du Gunst erwiesen hast, nicht derjenigen, die (Deinen) Zorn erregt haben, und nicht der Irregehenden!'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'Al-Baqara',
      duas: [
        {
          id: '2',
          name: 'Al-Baqara 127',
          ayat: ' رَبَّنَا تَقَبَّلْ مِنَّا إِنَّكَ أَنْتَ السَّمِيعُ الْعَلِيمُ ',
          text: 'Unser Herr, nimm (es) von uns an. Du bist ja der Allhörende und Allwissende.'
        },
        {
          id: '3',
          name: 'Al-Baqara 128',
          ayat: 'رَبَّنَا وَاجْعَلْنَا مُسْلِمَيْنِ لَكَ وَمِنْ ذُرِّيَّتِنَا أُمَّةً مُسْلِمَةً لَكَ وَأَرِنَا مَنَاسِكَنَا وَتُبْ عَلَيْنَا إِنَّكَ أَنْتَ التَّوَّابُ الرَّحِيمُ',
          text: 'Unser Herr, mache uns Dir ergeben und von unserer Nachkommenschaft eine Dir ergebene Gemeinschaft. Und zeige uns unsere Riten, und nimm unsere Reue an. Du bist ja der Reue-Annehmende und Barmherzige.'
        },
        {
          id: '4',
          name: 'Al-Baqara 129',
          ayat: 'رَبَّنَا وَابْعَثْ فِيهِمْ رَسُولًا مِنْهُمْ يَتْلُو عَلَيْهِمْ آيَاتِكَ وَيُعَلِّمُهُمُ الْكِتَابَ وَالْحِكْمَةَ وَيُزَكِّيهِمْ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ',
          text: 'Unser Herr, schicke zu ihnen einen Gesandten von ihnen, der ihnen Deine Worte verliest und sie das Buch und die Weisheit lehrt und sie läutert. Du bist ja der Allmächtige und Allweise.'
        },
        {
          id: '5',
          name: 'Al-Baqara 201',
          ayat: 'وَمِنْهُمْ مَنْ يَقُولُ رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ',
          text: 'Unser Herr, gib uns im Diesseits Gutes und im Jenseits Gutes, und bewahre uns vor der Strafe des (Höllen)feuers!'
        },
        {
          id: '6',
          name: 'Al-Baqara 250',
          ayat: 'وَلَمَّا بَرَزُوا لِجَالُوتَ وَجُنُودِهِ قَالُوا رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Unser Herr, überschütte uns mit Standhaftigkeit, festige unsere Füße und verhilf uns zum Sieg über das ungläubige Volk!'
        },
        {
          id: '7',
          name: 'Al-Baqara 285',
          ayat: ' وَقَالُوا سَمِعْنَا وَأَطَعْنَا غُفْرَانَكَ رَبَّنَا وَإِلَيْكَ الْمَصِير',
          text: 'Wir hören und gehorchen. (Gewähre uns) Deine Vergebung, unser Herr! Und zu Dir ist der Ausgang.'
        },
        {
          id: '8',
          name: 'Al-Bakara 286',
          ayat: 'رَبَّنَا لَا تُؤَاخِذْنَا إِنْ نَسِينَا أَوْ أَخْطَأْنَا رَبَّنَا وَلَا تَحْمِلْ عَلَيْنَا إِصْرًا كَمَا حَمَلْتَهُ عَلَى الَّذِينَ مِنْ قَبْلِنَا رَبَّنَا وَلَا تُحَمِّلْنَا مَا لَا طَاقَةَ لَنَا بِهِ وَاعْفُ عَنَّا وَاغْفِرْ لَنَا وَارْحَمْنَا أَنْتَ مَوْلَانَا فَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Unser Herr, belange uns nicht, wenn wir (etwas) vergessen oder einen Fehler begehen. Unser Herr, lege uns keine Bürde auf, wie Du sie denjenigen vor uns auferlegt hast. Unser Herr, bürde uns nichts auf, wozu wir keine Kraft haben. Verzeihe uns, vergib uns und erbarme Dich unser! Du bist unser Schutzherr. So verhilf uns zum Sieg über das ungläubige Volk!'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'Aal-Imran',
      duas: [
        {
          id: '9',
          name: 'Aal-Imran 8-9',
          ayat: ' رَبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِنْ لَدُنْكَ رَحْمَةً إِنَّكَ أَنْتَ الْوَهَّابُ. رَبَّنَا إِنَّكَ جَامِعُ النَّاسِ لِيَوْمٍ لَا رَيْبَ فِيهِ إِنَّ اللَّهَ لَا يُخْلِفُ الْمِيعَادَ ',
          text: 'Unser Herr, lasse unsere Herzen nicht abschweifen, nachdem Du uns rechtgeleitet hast, und schenke uns Erbarmen von Dir aus. Du bist ja der unablässig Schenkende. Unser Herr, Du wirst die Menschen gewiß zu einem Tag versammeln, an dem es keinen Zweifel gibt. Allah bricht nicht, was Er versprochen hat.'
        },
        {
          id: '10',
          name: 'Aal-Imran 16',
          ayat: '  رَبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّار ',
          text: 'Unser Herr, gewiß, wir glauben. Darum vergib uns unsere Sünden und bewahre uns vor der Strafe des (Höllen)feuers.'
        },
        {
          id: '11',
          name: 'Aal-Imran 38',
          ayat: ' رَبِّ هَبْ لِي مِنْ لَدُنْكَ ذُرِّيَّةً طَيِّبَةً إِنَّكَ سَمِيعُ الدُّعَاءِ',
          text: 'Mein Herr, schenke mir von Dir aus gute Nachkommenschaft! Du bist ja der Gebetserhörer.'
        },
        {
          id: '12',
          name: 'Aal-Imran 53',
          ayat: 'رَبَّنَا آمَنَّا بِمَا أَنْزَلْتَ وَاتَّبَعْنَا الرَّسُولَ فَاكْتُبْنَا مَعَ الشَّاهِدِينَ',
          text: 'Unser Herr, wir glauben an das, was Du (als Offenbarung) herabgesandt hast, und folgen dem Gesandten. So schreibe uns auf unter die Zeugnis Ablegenden!'
        },
        {
          id: '13',
          name: 'Aal-Imran 147',
          ayat: ' رَبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Unser Herr, vergib uns unsere Sünden und die Maßlosigkeit in unserer Angelegenheit, festige unsere Füße und hilf uns gegen das ungläubige Volk!'
        },
        {
          id: '14',
          name: 'Aal-Imran 191-194',
          ayat: ' رَبَّنَا مَا خَلَقْتَ هَذَا بَاطِلًا سُبْحَانَكَ فَقِنَا عَذَابَ النَّارِ. رَبَّنَا إِنَّكَ مَنْ تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنْصَار. رَبَّنَا إِنَّنَا سَمِعْنَا مُنَادِيًا يُنَادِي لِلْإِيمَانِ أَنْ آمِنُوا بِرَبِّكُمْ فَآمَنَّا رَبَّنَا فَاغْفِرْ لَنَا ذُنُوبَنَا وَكَفِّرْ عَنَّا سَيِّئَاتِنَا وَتَوَفَّنَا مَعَ الْأَبْرَار. رَبَّنَا وَآتِنَا مَا وَعَدْتَنَا عَلَى رُسُلِكَ وَلَا تُخْزِنَا يَوْمَ الْقِيَامَةِ إِنَّكَ لَا تُخْلِفُ الْمِيعَادَ ',
          text: 'Unser Herr, Du hast (all) dies nicht umsonst erschaffen. Preis sei Dir! Bewahre uns vor der Strafe des (Höllen)feuers. Unser Herr, gewiß, wen Du ins (Höllen)feuer eingehen läßt, den hast Du (damit) in Schande gestürzt. Und die Ungerechten werden keine Helfer haben.  Unser Herr, gewiß, wir hörten einen Rufer, der zum Glauben aufrief: .Glaubt an euren Herrn. Da glaubten wir. Unser Herr, vergib uns unsere Sünden, tilge unsere bösen Taten und berufe uns ab unter den Gütigen. Unser Herr, und gib uns, was Du uns durch Deine Gesandten versprochen hast, und stürze uns nicht in Schande am Tag der Auferstehung. Gewiß, Du brichst nicht, was Du versprochen hast.'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'An-Nisaa',
      duas: [
        {
          id: '15',
          name: 'An-Nisaa 75',
          ayat: ' رَبَّنَا أَخْرِجْنَا مِنْ هَذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا',
          text: 'Unser Herr, bringe uns aus dieser Stadt heraus, deren Bewohner ungerecht sind, und schaffe uns von Dir aus einen Schutzherrn, und schaffe uns von Dir aus einen Helfer.'
        }
      ],
      icon: 'cloudy-night'
    },
    {
        surah: 'Al-Araaf',
        duas: [
          {
            id: '16',
            name: 'Al-Araaf 23',
            ayat: '  رَبَّنَا ظَلَمْنَا أَنْفُسَنَا وَإِنْ لَمْ تَغْفِرْ لَنَا وَتَرْحَمْنَا لَنَكُونَنَّ مِنَ الْخَاسِرِينَ',
            text: 'Unser Herr, wir haben uns selbst Unrecht zugefügt. Wenn Du uns nicht vergibst und Dich unser erbarmst, werden wir ganz gewiß zu den Verlorenen gehören.'
          },
          {
            id: '17',
            name: 'Al-Araaf 47',
            ayat: ' رَبَّنَا أَخْرِجْنَا مِنْ هَذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا',
            text: 'Unser Herr, lasse uns nicht mit dem ungerechten Volk sein!'
          },
          {
            id: '18',
            name: 'Al-Araaf 89',
            ayat: ' رَبَّنَا افْتَحْ بَيْنَنَا وَبَيْنَ قَوْمِنَا بِالْحَقِّ وَأَنْتَ خَيْرُ الْفَاتِحِينَ',
            text: 'Unser Herr, entscheide zwischen uns und unserem Volk der Wahrheit entsprechend! Du bist ja der beste Entscheider'
          },
          {
            id: '19',
            name: 'Al-Araaf 126',
            ayat: ' رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَتَوَفَّنَا مُسْلِمِينَ',
            text: 'Unser Herr, überschütte uns mit Standhaftigkeit und berufe uns ab als (Dir) Ergebene!'
          },
          {
            id: '20',
            name: 'Al-Araaf 151',
            ayat: ' رَبِّ اغْفِرْ لِي وَلِأَخِي وَأَدْخِلْنَا فِي رَحْمَتِكَ وَأَنْتَ أَرْحَمُ الرَّاحِمِينَ',
            text: 'Mein Herr, vergib mir und meinem Bruder, und lasse uns in Deine Barmherzigkeit eingehen. Du bist ja der Barmherzigste der Barmherzigen.'
          },
          {
            id: '21',
            name: 'Al-Araaf 155-156',
            ayat: ' أَنْتَ وَلِيُّنَا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الْغَافِرِينَ. وَاكْتُبْ لَنَا فِي هَذِهِ الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ إِنَّا هُدْنَا إِلَيْكَ',
            text: ' Du bist unser Schutzherr, so vergib uns und erbarme Dich unser! Du bist der Beste derer, die vergeben. Und bestimme für uns in diesem Diesseits Gutes und auch im Jenseits! Gewiß, wir haben zu Dir zurückgefunden.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Yunus',
        duas: [
          {
            id: '22',
            name: 'Yunus 85-86',
            ayat: ' عَلَى اللَّهِ تَوَكَّلْنَا رَبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِلْقَوْمِ الظَّالِمِينَ ',
            text: 'Auf Allah verlassen wir uns. Unser Herr, mache uns nicht zu einer Versuchung für das ungerechte Volk, und errette uns durch Deine Barmherzigkeit von dem ungläubigen Volk!'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Hud',
        duas: [
          {
            id: '23',
            name: 'Hud 47',
            ayat: ' َرَبِّ إِنِّي أَعُوذُ بِكَ أَنْ أَسْأَلَكَ مَا لَيْسَ لِي بِهِ عِلْمٌ وَإِلَّا تَغْفِرْ لِي وَتَرْحَمْنِي أَكُنْ مِنَ الْخَاسِرِينَ ',
            text: 'Mein Herr, ich suche Schutz bei Dir (davor), daß ich Dich um etwas bitte, wovon ich kein Wissen habe! Wenn Du mir nicht vergibst und Dich meiner nicht erbarmst, werde ich zu den Verlierern gehören.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Yusuf',
        duas: [
          {
            id: '24',
            name: 'Yusuf 101',
            ayat: '  فَاطِرَ السَّمَاوَاتِ وَالْأَرْضِ أَنْتَ وَلِيِّي فِي الدُّنْيَا وَالْآخِرَةِ تَوَفَّنِي مُسْلِمًا وَأَلْحِقْنِي بِالصَّالِحِينَ',
            text: '(O Du) Erschaffer der Himmel und der Erde, Du bist mein Schutzherr im Diesseits und Jenseits. Berufe mich als (Dir) ergeben ab und nimm mich unter die Rechtschaffenen auf.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Ibrahim',
        duas: [
          {
            id: '25',
            name: 'Ibrahim 40-41',
            ayat: ' َرَبِّ اجْعَلْنِي مُقِيمَ الصَّلَاةِ وَمِنْ ذُرِّيَّتِي رَبَّنَا وَتَقَبَّلْ دُعَاءِ. رَبَّنَا اغْفِرْ لِي وَلِوَالِدَيَّ وَلِلْمُؤْمِنِينَ يَوْمَ يَقُومُ الْحِسَابُ',
            text: 'Mein Herr, mach, daß ich das Gebet verrichte, (ich) und (auch einige) aus meiner Nachkommenschaft. Unser Herr, und nimm mein Gebet an. Unser Herr, vergib mir und meinen Eltern und den Gläubigen an dem Tag, da die Abrechnung stattfinden wird. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Israa',
        duas: [
          {
            id: '26',
            name: 'Al-Israa 80',
            ayat: ' َرَبِّ أَدْخِلْنِي مُدْخَلَ صِدْقٍ وَأَخْرِجْنِي مُخْرَجَ صِدْقٍ وَاجْعَلْ لِي مِنْ لَدُنْكَ سُلْطَانًا نَصِيرًا',
            text: 'Mein Herr, gewähre mir einen wahrhaftigen Eingang, und gewähre mir einen wahrhaftigen Ausgang, und schaffe mir von Dir aus eine hilfreiche Macht.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Kahf',
        duas: [
          {
            id: '27',
            name: 'Al-Kahf 10',
            ayat: ' َرَبَّنَا آتِنَا مِنْ لَدُنْكَ رَحْمَةً وَهَيِّئْ لَنَا مِنْ أَمْرِنَا رَشَدًا',
            text: 'Unser Herr, gib uns Barmherzigkeit von Dir aus, und bereite uns in unserer Angelegenheit einen rechten (Aus)weg.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Maryam',
        duas: [
          {
            id: '28',
            name: 'Maryam 5',
            ayat: ' هَبْ لِي مِنْ لَدُنْكَ وَلِيًّا',
            text: 'Schenke mir von Dir aus einen nahen Verwandten'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Taa-haa',
        duas: [
          {
            id: '29',
            name: 'Taa-haa 25-28',
            ayat: ' رَبِّ اشْرَحْ لِي صَدْرِي, وَيَسِّرْ لِي أَمْرِي, َاحْلُلْ عُقْدَةً مِنْ لِسَانِي, يَفْقَهُوا قَوْلِي ',
            text: 'Mein Herr, weite mir meine Brust, und mache mir meine Angelegenheit leicht. Und löse den Knoten in meiner Zunge, so daß sie meine Worte verstehen. '
          },
          {
            id: '30',
            name: 'Ta-ha 114 ',
            ayat: ' رَبِّ زِدْنِي عِلْمًا ',
            text: 'Mein Herr, lasse mich an Wissen zunehmen.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Enbiyaa',
        duas: [
          {
            id: '31',
            name: 'Al-Enbiyaa 87',
            ayat: ' لَا إِلَهَ إِلَّا أَنْتَ سُبْحَانَكَ إِنِّي كُنْتُ مِنَ الظَّالِمِينَ ',
            text: 'Es gibt keinen Gott außer Dir! Preis sei Dir! Gewiß, ich gehöre zu den Ungerechten.'
          },
          {
            id: '32',
            name: 'Al-Enbiyaa 89',
            ayat: ' رَبِّ لَا تَذَرْنِي فَرْدًا وَأَنْتَ خَيْرُ الْوَارِثِينَ ',
            text: 'Mein Herr, lasse mich nicht kinderlos bleiben, und Du bist der beste Erbe.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Muminoon',
        duas: [
          {
            id: '33',
            name: 'Al-Muminoon 29',
            ayat: '  رَبِّ أَنْزِلْنِي مُنْزَلًا مُبَارَكًا وَأَنْتَ خَيْرُ الْمُنْزِلِينَ ',
            text: 'Mein Herr, gewähre mir einen gesegneten Abstieg, denn Du bist der Beste derjenigen, die Abstieg gewähren.'
          },
          {
            id: '34',
            name: 'Al-Muminoon 97-98',
            ayat: ' رَبِّ أَعُوذُ بِكَ مِنْ هَمَزَاتِ الشَّيَاطِينِ, وَأَعُوذُ بِكَ رَبِّ أَنْ يَحْضُرُونِ ',
            text: 'Mein Herr, ich nehme Zuflucht bei Dir vor den Aufstachelungen der Satane. Und ich nehme Zuflucht bei Dir davor, mein Herr, daß sie mich aufsuchen.'
          },
          {
            id: '35',
            name: 'Al-Muminoon 109',
            ayat: ' رَبَّنَا آمَنَّا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الرَّاحِمِينََ',
            text: 'Unser Herr, wir glauben, so vergib uns und erbarme Dich unser, denn Du bist der Beste der Barmherzigen. '
          },
          {
            id: '36',
            name: 'Al-Muminoon 118',
            ayat: ' رَبِّ اغْفِرْ وَارْحَمْ وَأَنْتَ خَيْرُ الرَّاحِمِينَ ',
            text: ' Mein Herr, vergib und erbarme Dich, denn Du bist der Beste der Barmherzigen. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Furqan',
        duas: [
          {
            id: '37',
            name: 'Al-Furqaan 65-66',
            ayat: ' رَبَّنَا اصْرِفْ عَنَّا عَذَابَ جَهَنَّمَ إِنَّ عَذَابَهَا كَانَ غَرَامًا إِنَّهَا سَاءَتْ مُسْتَقَرًّا وَمُقَامًا ',
            text: 'Unser Herr, wende von uns die Strafe der Hölle ab." Ihre Strafe ist ja bedrängend; gewiß, sie ist böse als Aufenthaltsort und Bleibe.'
          },
          {
            id: '38',
            name: 'Al-Furqaan 74',
            ayat: ' رَبَّنَا هَبْ لَنَا مِنْ أَزْوَاجِنَا وَذُرِّيَّاتِنَا قُرَّةَ أَعْيُنٍ وَاجْعَلْنَا لِلْمُتَّقِينَ إِمَامًا ',
            text: 'Unser Herr, schenke uns an unseren Gattinnen und unseren Nachkommenschaften Grund zur Freude, und mache uns für die Rechtschaffenen zu einem Vorbild.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Ash-Shuaraa',
        duas: [
          {
            id: '39',
            name: 'Ash-Shuaraa 83-87',
            ayat: ' رَبِّ هَبْ لِي حُكْمًا وَأَلْحِقْنِي بِالصَّالِحِينَ, وَاجْعَلْ لِي لِسَانَ صِدْقٍ فِي الْآخِرِينَ, وَاجْعَلْنِي مِنْ وَرَثَةِ جَنَّةِ النَّعِيمِ, وَاغْفِرْ لِأَبِي إِنَّهُ كَانَ مِنَ الضَّالِّينَ, وَلَا تُخْزِنِي يَوْمَ يُبْعَثُونَ ',
            text: 'Mein Herr, schenke mir Urteil(skraft), und nimm mich unter die Rechtschaffenen auf Und verleihe mir einen Ruf an Wahrhaftigkeit unter den späteren (Geschlechtern). Und mach mich zu einem (der) Erben des Gartens der Wonne.  Und vergib meinem Vater, denn er gehört zu den Irregehenden. Und stürze mich nicht in Schande am Tag, da sie auferweckt werden,'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'En-Neml',
        duas: [
          {
            id: '40',
            name: 'En-Neml 19',
            ayat: ' رَبِّ أَوْزِعْنِي أَنْ أَشْكُرَ نِعْمَتَكَ الَّتِي أَنْعَمْتَ عَلَيَّ وَعَلَى وَالِدَيَّ وَأَنْ أَعْمَلَ صَالِحًا تَرْضَاهُ وَأَدْخِلْنِي بِرَحْمَتِكَ فِي عِبَادِكَ الصَّالِحِينَ ',
            text: 'Mein Herr, veranlasse mich, für Deine Gunst zu danken, die Du mir und meinen Eltern erwiesen hast, und rechtschaffen zu handeln, womit Du zufrieden bist. Und lasse mich durch Deine Barmherzigkeit eingehen in die Reihen Deiner rechtschaffenen Diener.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Qasas',
        duas: [
          {
            id: '41',
            name: 'Al-Qasas 16',
            ayat: ' قَالَ رَبِّ إِنِّي ظَلَمْتُ نَفْسِي فَاغْفِرْ لِي فَغَفَرَ لَهُ إِنَّهُ هُوَ الْغَفُورُ الرَّحِيمُ ',
            text: 'Mein Herr, ich habe mir selbst Unrecht zugefügt; so vergib mir." Da vergab Er ihm, denn Er ist ja der Allvergebende und Barmherzige. '
          }
        ],
        icon: 'cloudy-night'
      },
       {
      surah: 'Al-Ankaboot',
        duas: [
          {
            id: '42',
            name: 'Al-Ankaboot 30 ',
            ayat: '  رَبِّ انْصُرْنِي عَلَى الْقَوْمِ الْمُفْسِدِينَ ',
            text: 'Mein Herr, hilf mir gegen das Volk, das Unheil stiftet. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Gafir (EL-Mumin)',
        duas: [
          {
            id: '43',
            name: 'El-Gafir 7-9',
            ayat: ' َرَبَّنَا وَسِعْتَ كُلَّ شَيْءٍ رَحْمَةً وَعِلْمًا فَاغْفِرْ لِلَّذِينَ تَابُوا وَاتَّبَعُوا سَبِيلَكَ وَقِهِمْ عَذَابَ الْجَحِيم, رَبَّنَا وَأَدْخِلْهُمْ جَنَّاتِ عَدْنٍ الَّتِي وَعَدْتَهُمْ وَمَنْ صَلَحَ مِنْ آبَائِهِمْ وَأَزْوَاجِهِمْ وَذُرِّيَّاتِهِمْ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ, وَقِهِمُ السَّيِّئَاتِ وَمَنْ تَقِ السَّيِّئَاتِ يَوْمَئِذٍ فَقَدْ رَحِمْتَهُ وَذَلِكَ هُوَ الْفَوْزُ الْعَظِيمُ',
            text: 'Unser Herr, Du umfaßt alles in Deiner Barmherzigkeit und Deinem Wissen. So vergib denjenigen, die bereuen und Deinem Weg folgen, und bewahre sie vor der Strafe des Höllenbrandes. Unser Herr, lasse sie in die Gärten Edens, die Du ihnen versprochen hast, eingehen, (sie) und wer rechtschaffen ist von ihren Vätern, ihren Gattinnen und ihren Nachkommen, Du bist ja der Allmächtige und Allweise.  Und bewahre sie vor den bösen Taten. Wen Du an jenem Tag vor den bösen Taten bewahrst, dessen hast Du dich ja erbarmt, und das ist der großartige Erfolg. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Ad-Dukhaan',
        duas: [
          {
            id: '44',
            name: 'Ad-Dukhaan 12',
            ayat: ' رَبَّنَا اكْشِفْ عَنَّا الْعَذَابَ إِنَّا مُؤْمِنُونَ',
            text: 'Unser Herr, nimm die Strafe von uns hinweg; wir sind (jetzt) ja gläubig.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Ahqaf',
        duas: [
          {
            id: '45',
            name: 'Al-Ahqaf 15',
            ayat: ' رَبِّ أَوْزِعْنِي أَنْ أَشْكُرَ نِعْمَتَكَ الَّتِي أَنْعَمْتَ عَلَيَّ وَعَلَى وَالِدَيَّ وَأَنْ أَعْمَلَ صَالِحًا تَرْضَاهُ وَأَصْلِحْ لِي فِي ذُرِّيَّتِي إِنِّي تُبْتُ إِلَيْكَ وَإِنِّي مِنَ الْمُسْلِمِينََ',
            text: 'Mein Herr, veranlasse mich, für Deine Gunst zu danken, die Du mir und meinen Eltern erwiesen hast, und rechtschaffen zu handeln, womit Du zufrieden bist. Und gib mir Rechtschaffenheit in meiner Nachkommenschaft. Ich wende mich Dir ja in Reue zu, und ich gehöre ja zu den (Dir) Ergebenen. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Hashr',
        duas: [
          {
            id: '46',
            name: 'Al-Hashr 10',
            ayat: ' رَبَّنَا اغْفِرْ لَنَا وَلِإِخْوَانِنَا الَّذِينَ سَبَقُونَا بِالْإِيمَانِ وَلَا تَجْعَلْ فِي قُلُوبِنَا غِلًّا لِلَّذِينَ آمَنُوا رَبَّنَا إِنَّكَ رَءُوفٌ رَحِيمٌ َ',
            text: 'Unser Herr, vergib uns und unseren Brüdern, die uns im Glauben vorausgegangen sind, und lasse in unseren Herzen keinen Groll sein gegen diejenigen, die glauben. Unser Herr, Du bist ja Gnädig und Barmherzig. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Mumtahana',
        duas: [
          {
            id: '47',
            name: 'Al-Mumtahana 4-5 ',
            ayat: '  رَبَّنَا عَلَيْكَ تَوَكَّلْنَا وَإِلَيْكَ أَنَبْنَا وَإِلَيْكَ الْمَصِيرُ رَبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِلَّذِينَ كَفَرُوا وَاغْفِرْ لَنَا رَبَّنَا إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ َ',
            text: 'Unser Herr, auf Dich verlassen wir uns, und Dir wenden wir uns reuig zu. Und zu Dir ist der Ausgang. Unser Herr, mache uns nicht zu einer Versuchung für diejenigen, die ungläubig sind. Und vergib uns, unser Herr. Du bist ja der Allmächtige und Allweise.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'At-Tahrim',
        duas: [
          {
            id: '48',
            name: 'At-Tahrim 8',
            ayat: '  رَبَّنَا أَتْمِمْ لَنَا نُورَنَا وَاغْفِرْ لَنَا إِنَّكَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ َ',
            text: 'Unser Herr, vollende für uns unser Licht und vergib uns. Gewiß, Du hast zu allem die Macht.'
          },
          {
            id: '49',
            name: 'At-Tahrim 11',
            ayat: '  رَبِّ ابْنِ لِي عِنْدَكَ بَيْتًا فِي الْجَنَّةِ َ',
            text: 'Mein Herr, baue mir bei Dir ein Haus im (Paradies)garten.'
          },
          {
            id: '50',
            name: 'At-Tahrim 11',
            ayat: ' نَجِّنِي مِنَ الْقَوْمِ الظَّالِمِينََ',
            text: 'Errette mich von dem Volk der Ungerechten. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Nuh',
        duas: [
          {
            id: '51',
            name: 'Nuh 26-28',
            ayat: ' رَبِّ لَا تَذَرْ عَلَى الْأَرْضِ مِنَ الْكَافِرِينَ دَيَّارًا, إِنَّكَ إِنْ تَذَرْهُمْ يُضِلُّوا عِبَادَكَ وَلَا يَلِدُوا إِلَّا فَاجِرًا كَفَّارًا, رَبِّ اغْفِرْ لِي وَلِوَالِدَيَّ وَلِمَنْ دَخَلَ بَيْتِيَ مُؤْمِنًا وَلِلْمُؤْمِنِينَ وَالْمُؤْمِنَاتِ وَلَا تَزِدِ الظَّالِمِينَ إِلَّا تَبَارًا ',
            text: 'Mein Herr, lasse auf der Erde von den Ungläubigen nicht einen einzigen Bewohner. Denn, gewiß, wenn Du sie (leben) läßt, führen sie Deine Diener in die Irre und zeugen nur Sittenlose und sehr beharrliche Ungläubige. Mein Herr, vergib mir und meinen Eltern und demjenigen, der als Gläubiger mein Haus betritt, und den gläubigen Männern und den gläubigen Frauen. Und stürze die Ungerechten nur noch tiefer ins Verderben.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Falaq',
        duas: [
          {
            id: '52',
            name: 'Al-Falaq 1-5',
            ayat: ' أَعُوذُ بِرَبِّ الْفَلَق مِنْ شَرِّ مَا خَلَقَ وَمِنْ شَرِّ غَاسِقٍ إِذَا وَقَبَ وَمِنْ شَرِّ النَّفَّاثَاتِ فِي الْعُقَدِ وَمِنْ شَرِّ حَاسِدٍ إِذَا حَسَدَ ',
            text: 'Ich nehme Zuflucht beim Herrn des Tagesanbruchs vor dem Übel dessen, was Er erschaffen hat, und vor dem Übel der Dunkelheit, wenn sie zunimmt,  und vor dem Übel der Knotenanbläserinnen und vor dem Übel eines (jeden) Neidenden, wenn er neidet. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'An-Naas',
        duas: [
          {
            id: '53',
            name: 'An-Naas 1-6',
            ayat: '  أَعُوذُ بِرَبِّ النَّاسِ مَلِكِ النَّاسِ إِلَهِ النَّاسِ مِنْ شَرِّ الْوَسْوَاسِ الْخَنَّاسِ الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ مِنَ الْجِنَّةِ وَالنَّاسِ ',
            text: 'Ich nehme Zuflucht beim Herrn der Menschen, dem König der Menschen, dem Gott der Menschen, vor dem Übel des Einflüsterers, des Davonschleichers, der in die Brüste der Menschen einflüstert, von den Ginn und den Menschen.'
          }
        ],
        icon: 'cloudy-night'
      }
  ];
  