import React, {useContext} from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeContext } from '../contexts/ThemeContext';

function Layout(props) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  return (
    <Router>
      <div className="App" style={{ background: theme.background }}>
        <Sidebar />
        <main className="Main">
          <Navbar />
          <div className="Content">{props.children}</div>
        </main>
      </div>
    </Router>
  );
}

export default Layout;
