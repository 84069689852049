import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ThemeContext } from '../contexts/ThemeContext';

function MenuListDuas({ title, list }) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  const [showList, setShowList] = useState(false);
  const listShowHide = () => {
    setShowList(!showList);
  };
  return (
    <div className="Sidebar-links">
      <button className="Sidebar-button">
        <div className="Sidebar-button-title" style={{ color: theme.syntax }} onClick={listShowHide}>
          <span>{title}</span>
          {!showList ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </div>
      </button>
      {showList ? (
        <ul className="Sidebar-lists">
          {list.map((item, index) => (
              <li key={index} className="Sidebar-list">
                <Link
                  style={{ color: theme.syntax }}
                  className="Sidebar-link"
                  to={{
                    pathname:
                      "/duas/" +
                      `${item.surah}`,
                  }}
                >
                  {item.surah} - {item.duas.length}
                </Link>
              </li>
            )
          )}
        </ul>
      ) : null}
    </div>
  );
}

export default MenuListDuas;
