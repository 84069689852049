import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IoIosHome, IoIosSettings } from "react-icons/io";
import { ThemeContext } from "../contexts/ThemeContext";
import ThemeToggle from "../components/ThemeToggle";
import { useTranslation } from "react-i18next";

function Navbar() {
  const { t, i18n } = useTranslation();
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  const [lang, setLang] = useState("en");
  const handleChange = (event) => {
    const selectLang = event.target.value;
    console.log(selectLang);
    setLang(selectLang);
    i18n.changeLanguage(selectLang);
  };
  return (
    <header className="Header" style={{ background: theme.bg }}>
      <Link to="/" className="Header-link">
        <img
          className="Navbar-logo"
          src="/images/bismillah.png"
          alt="The Noble Quran"
        />
      </Link>
      <ul className="Header-lists">
        <li>
          <select
            className="Language"
            style={{ background: theme.bg }}
            value={i18n.language}
            onChange={handleChange}
          >
            <option value="en">{t("English")}</option>
            <option value="bs">{t("Bosnian")}</option>
            <option value="de">{t("German")}</option>
          </select>
        </li>
        <li className="Header-list">
          <Link to="/" className="Header-link">
            <IoIosHome className="Header-link-icon" />
          </Link>
        </li>
        <li className="Header-list">
          <Link to="/settings" className="Header-link">
            <IoIosSettings className="Header-link-icon" />
          </Link>
        </li>
        <li>
          <ThemeToggle />
        </li>
      </ul>
    </header>
  );
}

export default Navbar;
