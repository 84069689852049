import Enn from "../data/en";
import Juzen from "../data/juzen";
import Surahen from "../data/suraen";

let en = {
  translation: {
    "Home title": "The Noble Quran",
    "English": "English",
    "Bosnian": "Bosnian",
    "German": "German",
    "Home search": "Search the Nooble Quran by arabian text, translation text or number of page",
    "Search text": "Type text or number of page...",
    "Quick links": "Quick links for some surahs and ayahs",
    "Recent page": "Recent page",
    "Al-Faatiha": "Al-Faatiha",
    "Last two ayahs Al-Baqara": "Last two ayahs Al-Baqara",
    "Ayatul Kursi": "Ayatul Kursi",
    "Al-Kehf": "Al-Kehf",
    "Surah Yaseen": "Surah Yaseen",
    "Surah Ar-Rahmaan": "Surah Ar-Rahmaan",
    "Al-Mulk": "Al-Mulk",
    "Three surahs": " Al-Ikhlaas, Al-Falaq, An-Naas",
    "Mobile Apps": "Mobile Apps",
    "Contact": "Contact",
    "Surah": "Surah",
    "Juz": "Juz",
    "Duas": "Duas",
    "The Noble Quran": "The Noble Quran",
    "Duas from the Quran": "Duas from the Quran",
    "Download": "Download",
    "Set the settings": "Set the settings of the Noble Quran",
    "Select Translation": "Select translation",
    "Select Audio": "Select audio reciters",
    "DuasData": Enn,
    "JuzData": Juzen,
    "SuraData": Surahen
  },
};

export default en;
