export default [
    {
      "604": [
        {
          "text": "SAY: \"He is the One God:",
          "englishName": "Al-Ikhlaas",
          "suraNumber": 112,
          "numberInSurah": 1,
          "number": 6222,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"God the Eternal, the Uncaused Cause of All Being.",
          "englishName": "Al-Ikhlaas",
          "suraNumber": 112,
          "numberInSurah": 2,
          "number": 6223,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"He begets not, and neither is He begotten;",
          "englishName": "Al-Ikhlaas",
          "suraNumber": 112,
          "numberInSurah": 3,
          "number": 6224,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"and there is nothing that could be compared with Him.",
          "englishName": "Al-Ikhlaas",
          "suraNumber": 112,
          "numberInSurah": 4,
          "number": 6225,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "SAY: \"I seek refuge with the Sustainer of the rising dawn,",
          "englishName": "Al-Falaq",
          "suraNumber": 113,
          "numberInSurah": 1,
          "number": 6226,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"from the evil of aught that He has created,",
          "englishName": "Al-Falaq",
          "suraNumber": 113,
          "numberInSurah": 2,
          "number": 6227,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"and from the evil of the black darkness whenever it descends,",
          "englishName": "Al-Falaq",
          "suraNumber": 113,
          "numberInSurah": 3,
          "number": 6228,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"and from the evil of all human beings bent on occult endeavours,",
          "englishName": "Al-Falaq",
          "suraNumber": 113,
          "numberInSurah": 4,
          "number": 6229,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"and from the evil of the envious when he envies.\"",
          "englishName": "Al-Falaq",
          "suraNumber": 113,
          "numberInSurah": 5,
          "number": 6230,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "SAY: \"I seek refuge with the Sustainer of men,",
          "englishName": "An-Naas",
          "suraNumber": 114,
          "numberInSurah": 1,
          "number": 6231,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"the Sovereign of men,",
          "englishName": "An-Naas",
          "suraNumber": 114,
          "numberInSurah": 2,
          "number": 6232,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"the God of men,",
          "englishName": "An-Naas",
          "suraNumber": 114,
          "numberInSurah": 3,
          "number": 6233,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"from the evil of the whispering, elusive tempter",
          "englishName": "An-Naas",
          "suraNumber": 114,
          "numberInSurah": 4,
          "number": 6234,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"who whispers in the hearts of men",
          "englishName": "An-Naas",
          "suraNumber": 114,
          "numberInSurah": 5,
          "number": 6235,
          "juz": 30,
          "numpage": 604
        },
        {
          "text": "\"from all [temptation to evil by] invisible forces as well as men,\"",
          "englishName": "An-Naas",
          "suraNumber": 114,
          "numberInSurah": 6,
          "number": 6236,
          "juz": 30,
          "numpage": 604
        }
      ]
    },
    {
      "603": [
        {
          "text": "SAY: \"O you who deny the truth!",
          "englishName": "Al-Kaafiroon",
          "suraNumber": 109,
          "numberInSurah": 1,
          "number": 6208,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "\"I do not worship that which you worship,",
          "englishName": "Al-Kaafiroon",
          "suraNumber": 109,
          "numberInSurah": 2,
          "number": 6209,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "and neither do you worship that which I worship!",
          "englishName": "Al-Kaafiroon",
          "suraNumber": 109,
          "numberInSurah": 3,
          "number": 6210,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "\"And I will not worship ~hat which you have [ever] worshipped,",
          "englishName": "Al-Kaafiroon",
          "suraNumber": 109,
          "numberInSurah": 4,
          "number": 6211,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "and neither will you [ever] worship that which I worship.",
          "englishName": "Al-Kaafiroon",
          "suraNumber": 109,
          "numberInSurah": 5,
          "number": 6212,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "Unto you, your moral law, and unto me, mine!\"",
          "englishName": "Al-Kaafiroon",
          "suraNumber": 109,
          "numberInSurah": 6,
          "number": 6213,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "WHEN GOD'S SUCCOUR comes, and victory,",
          "englishName": "An-Nasr",
          "suraNumber": 110,
          "numberInSurah": 1,
          "number": 6214,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "and thou seest people enter God's religion in hosts,",
          "englishName": "An-Nasr",
          "suraNumber": 110,
          "numberInSurah": 2,
          "number": 6215,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "extol thy Sustainer's limitless glory, and praise Him, and seek His forgiveness: for, behold, He is ever an acceptor of repentance.",
          "englishName": "An-Nasr",
          "suraNumber": 110,
          "numberInSurah": 3,
          "number": 6216,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "DOOMED are the hands of him of the glowing countence: and doomed is he!",
          "englishName": "Al-Masad",
          "suraNumber": 111,
          "numberInSurah": 1,
          "number": 6217,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "What will his wealth avail him, and all that he has gained?",
          "englishName": "Al-Masad",
          "suraNumber": 111,
          "numberInSurah": 2,
          "number": 6218,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "[In the life to come] he shall have to endure a fire fiercely glowing;",
          "englishName": "Al-Masad",
          "suraNumber": 111,
          "numberInSurah": 3,
          "number": 6219,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "together with his wife, that carrier of evil tales,",
          "englishName": "Al-Masad",
          "suraNumber": 111,
          "numberInSurah": 4,
          "number": 6220,
          "juz": 30,
          "numpage": 603
        },
        {
          "text": "[who bears] around her neck a rope of twisted strands!",
          "englishName": "Al-Masad",
          "suraNumber": 111,
          "numberInSurah": 5,
          "number": 6221,
          "juz": 30,
          "numpage": 603
        }
      ]
    },
    {
      "602": [
        {
          "text": "SO THAT the Quraysh might remain secure,",
          "englishName": "Quraish",
          "suraNumber": 106,
          "numberInSurah": 1,
          "number": 6194,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "secure in their winter and summer journeys,",
          "englishName": "Quraish",
          "suraNumber": 106,
          "numberInSurah": 2,
          "number": 6195,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "Let them, therefore, worship the Sustainer of this Temple;",
          "englishName": "Quraish",
          "suraNumber": 106,
          "numberInSurah": 3,
          "number": 6196,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "who has given them food against hunger, and made them safe from danger.",
          "englishName": "Quraish",
          "suraNumber": 106,
          "numberInSurah": 4,
          "number": 6197,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "HAST THOU ever considered [the kind of man] who gives the lie to all moral law?",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 1,
          "number": 6198,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "Behold, it is this [kind of man] that thrusts the orphan away,",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 2,
          "number": 6199,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "and feels no urge to feed the needy.",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 3,
          "number": 6200,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "Woe, then, unto those praying ones",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 4,
          "number": 6201,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "whose hearts from their prayer are remote",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 5,
          "number": 6202,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "those who want only to be seen and praised,",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 6,
          "number": 6203,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "and, withal, deny all assistance [to their fellow-men]!",
          "englishName": "Al-Maa'un",
          "suraNumber": 107,
          "numberInSurah": 7,
          "number": 6204,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "BEHOLD, We have bestowed upon thee good in abundance:",
          "englishName": "Al-Kawthar",
          "suraNumber": 108,
          "numberInSurah": 1,
          "number": 6205,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "hence, pray unto thy Sustainer [alone], and sacrifice [unto Him alone].",
          "englishName": "Al-Kawthar",
          "suraNumber": 108,
          "numberInSurah": 2,
          "number": 6206,
          "juz": 30,
          "numpage": 602
        },
        {
          "text": "Verily, he that hates thee has indeed been cut off [from all that is good]!",
          "englishName": "Al-Kawthar",
          "suraNumber": 108,
          "numberInSurah": 3,
          "number": 6207,
          "juz": 30,
          "numpage": 602
        }
      ]
    },
    {
      "601": [
        {
          "text": "CONSIDER the flight of time!",
          "englishName": "Al-Asr",
          "suraNumber": 103,
          "numberInSurah": 1,
          "number": 6177,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "Verily, man is bound to lose himself",
          "englishName": "Al-Asr",
          "suraNumber": 103,
          "numberInSurah": 2,
          "number": 6178,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "unless he be of those who attain to faith, and do good works, and enjoin upon one another the keeping to truth, and enjoin upon one another patience in adversity.",
          "englishName": "Al-Asr",
          "suraNumber": 103,
          "numberInSurah": 3,
          "number": 6179,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "WOE unto every slanderer, fault-finder!",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 1,
          "number": 6180,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "[Woe unto him who amasses wealth and counts it a safeguard,",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 2,
          "number": 6181,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "thinking that his wealth will make him live forever!",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 3,
          "number": 6182,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "Nay, but [in the life to come such as] he shall indeed be abandoned to crushing torment!",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 4,
          "number": 6183,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "And what could make thee conceive what that crushing torment will be?",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 5,
          "number": 6184,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "A fire kindled by God,",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 6,
          "number": 6185,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "which will rise over the [guilty] hearts:",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 7,
          "number": 6186,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "verily, it will close in upon them",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 8,
          "number": 6187,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "in endless columns!",
          "englishName": "Al-Humaza",
          "suraNumber": 104,
          "numberInSurah": 9,
          "number": 6188,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "ART THOU NOT aware of how thy Sustainer dealt with the Army of the Elephant?",
          "englishName": "Al-Fil",
          "suraNumber": 105,
          "numberInSurah": 1,
          "number": 6189,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "Did He not utterly confound their artful planning?",
          "englishName": "Al-Fil",
          "suraNumber": 105,
          "numberInSurah": 2,
          "number": 6190,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "Thus, He let loose upon them great swarms of flying creatures",
          "englishName": "Al-Fil",
          "suraNumber": 105,
          "numberInSurah": 3,
          "number": 6191,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "which smote them with stone-hard blows of chastisement pre-ordained,",
          "englishName": "Al-Fil",
          "suraNumber": 105,
          "numberInSurah": 4,
          "number": 6192,
          "juz": 30,
          "numpage": 601
        },
        {
          "text": "and caused them to become like a field of grain that has been eaten down to stubble",
          "englishName": "Al-Fil",
          "suraNumber": 105,
          "numberInSurah": 5,
          "number": 6193,
          "juz": 30,
          "numpage": 601
        }
      ]
    },
    {
      "600": [
        {
          "text": "and all that is [hidden] in men's hearts is bared",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 10,
          "number": 6156,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "that on that Day their Sustainer [will show that He] has always been fully aware of them?",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 11,
          "number": 6157,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "OH, the sudden calamity!",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 1,
          "number": 6158,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "How awesome the sudden calamity!",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 2,
          "number": 6159,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "And what could make thee conceive what that sudden calamity will be?",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 3,
          "number": 6160,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "[It will occur] on the Day when men will be like moths swarming in confusion,",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 4,
          "number": 6161,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "and the mountains will be like fluffy tufts of wool....",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 5,
          "number": 6162,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "And then, he whose weight [of good deeds] is heavy in the balance",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 6,
          "number": 6163,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "shall find himself in a happy' state of life;",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 7,
          "number": 6164,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "whereas he whose weight is light in the balance",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 8,
          "number": 6165,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "shall be engulfed by an abyss.",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 9,
          "number": 6166,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "And what could make thee conceive what that [abyss] will be?",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 10,
          "number": 6167,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "A fire hotly burning!",
          "englishName": "Al-Qaari'a",
          "suraNumber": 101,
          "numberInSurah": 11,
          "number": 6168,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "YOU ARE OBSESSED by greed for more and more",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 1,
          "number": 6169,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "until you go down to your graves.",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 2,
          "number": 6170,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "Nay, in time you will come to understand!",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 3,
          "number": 6171,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "And once again: Nay, in time you will come to understand!",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 4,
          "number": 6172,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "Nay, if you could but understand [it] with an understanding [born] of certainty,",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 5,
          "number": 6173,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "you would indeed, most surely, behold the blazing fire [of hell]!",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 6,
          "number": 6174,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "In the end you will indeed, most surely, behold it with the eye of certainty:",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 7,
          "number": 6175,
          "juz": 30,
          "numpage": 600
        },
        {
          "text": "and on that Day you will most surely be called to account for [what you did with] the boon of life!",
          "englishName": "At-Takaathur",
          "suraNumber": 102,
          "numberInSurah": 8,
          "number": 6176,
          "juz": 30,
          "numpage": 600
        }
      ]
    },
    {
      "599": [
        {
          "text": "Their reward [awaits them] with God: gardens of perpetual bliss, through which running waters flow, therein to abide beyond the count of time; well-pleased is God with them, and well-pleased are they with Him: all this awaits him who of his Sustainer stands in awe!.",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 8,
          "number": 6138,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "WHEN THE EARTH quakes with her [last] mighty quaking,",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 1,
          "number": 6139,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "and [when] the earth yields up her burdens,",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 2,
          "number": 6140,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "and man cries out, \"What has happened to her?\" -",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 3,
          "number": 6141,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "on that Day will she recount all her tidings,",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 4,
          "number": 6142,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "as thy Sustainer will have inspired her to do!",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 5,
          "number": 6143,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "On that Day will all men come forward, cut off from one another, to be shown their [past] deeds.",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 6,
          "number": 6144,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "And so, he who shall have done an atom's weight of good, shall behold it;",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 7,
          "number": 6145,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "and he who shall have done an atom's weight of evil, shall behold it.",
          "englishName": "Az-Zalzala",
          "suraNumber": 99,
          "numberInSurah": 8,
          "number": 6146,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "Oh, the chargers that run panting,",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 1,
          "number": 6147,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "sparks of fire striking,",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 2,
          "number": 6148,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "rushing to assault at morn,",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 3,
          "number": 6149,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "thereby raising clouds of dust,",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 4,
          "number": 6150,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "thereby storming [blindly] into any host!",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 5,
          "number": 6151,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "VERILY, towards his Sustainer man is most ungrateful",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 6,
          "number": 6152,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "and to this, behold, he [himself] bears witness indeed:",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 7,
          "number": 6153,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "for, verily, to the love of wealth is he most ardently devoted.",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 8,
          "number": 6154,
          "juz": 30,
          "numpage": 599
        },
        {
          "text": "But does he not know that [on the Last Day,] when all that is in the graves is raised and brought out,",
          "englishName": "Al-Aadiyaat",
          "suraNumber": 100,
          "numberInSurah": 9,
          "number": 6155,
          "juz": 30,
          "numpage": 599
        }
      ]
    },
    {
      "598": [
        {
          "text": "BEHOLD, from on high have We bestowed this [divine writ] on Night of Destiny.",
          "englishName": "Al-Qadr",
          "suraNumber": 97,
          "numberInSurah": 1,
          "number": 6126,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "And what could make thee conceive what it is, that Night of Destiny?",
          "englishName": "Al-Qadr",
          "suraNumber": 97,
          "numberInSurah": 2,
          "number": 6127,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "The Night of Destiny is better than a thousand months:",
          "englishName": "Al-Qadr",
          "suraNumber": 97,
          "numberInSurah": 3,
          "number": 6128,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "in hosts descend in it the angels, bearing divine inspiration by their Sustainer's leave; from all [evil] that may happen",
          "englishName": "Al-Qadr",
          "suraNumber": 97,
          "numberInSurah": 4,
          "number": 6129,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "does it make secure, until the rise of dawn.",
          "englishName": "Al-Qadr",
          "suraNumber": 97,
          "numberInSurah": 5,
          "number": 6130,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "IT IS NOT [conceivable] that such as are bent on denying the truth - [be they] from among the followers of earlier revelation or from among those who ascribe divinity to aught beside God should ever be abandoned [by Him] ere there comes unto them the [full] evidence of the truth:",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 1,
          "number": 6131,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "an apostle from God, conveying [unto them] revelations blest with purity,",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 2,
          "number": 6132,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "wherein there are ordinances of ever-true soundness and clarity.",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 3,
          "number": 6133,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "Now those who have been vouchsafed revelation aforetime did break up their unity [of faith] after such an evidence of the truth had come to them.",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 4,
          "number": 6134,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "And withal, they were not enjoined aught but that they should worship God, sincere in their faith in Him alone, turning away from all that is false; and that they should be constant in prayer; and that they should spend in charity: for this is a moral law endowed with ever-true soundness and clarity.",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 5,
          "number": 6135,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "Verily, those who [despite all evidence] are bent on denying the truth - [be they] from among the followers of earlier revelation or from among those who ascribe divinity to aught beside God - will find themselves in the fire of hell, therein to abide: they are the worst of all creatures.",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 6,
          "number": 6136,
          "juz": 30,
          "numpage": 598
        },
        {
          "text": "[And,] verily, those who have attained to faith, and do righteous deeds - it is they, they who are the best of all creatures.",
          "englishName": "Al-Bayyina",
          "suraNumber": 98,
          "numberInSurah": 7,
          "number": 6137,
          "juz": 30,
          "numpage": 598
        }
      ]
    },
    {
      "597": [
        {
          "text": "CONSIDER the fig and the olive,",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 1,
          "number": 6099,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "and Mount Sinai,",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 2,
          "number": 6100,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "and this land secure!",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 3,
          "number": 6101,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Verily, We create man in the best conformation;",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 4,
          "number": 6102,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "and thereafter We reduce him to the lowest of low",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 5,
          "number": 6103,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "excepting only such as attain to faith and do good works: and theirs shall be a reward unending!",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 6,
          "number": 6104,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "What, then, [O man,] could henceforth cause thee to give the lie to this moral law?",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 7,
          "number": 6105,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Is not God the most just of judges?",
          "englishName": "At-Tin",
          "suraNumber": 95,
          "numberInSurah": 8,
          "number": 6106,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "READ in the name of thy Sustainer, who has-",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 1,
          "number": 6107,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "created man out of a germ-cell",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 2,
          "number": 6108,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Read - for thy Sustainer is the Most Bountiful One",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 3,
          "number": 6109,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "who has taught [man] the use of the pen –",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 4,
          "number": 6110,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "taught man what he did not know!",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 5,
          "number": 6111,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Nay, verily, man becomes grossly overweening",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 6,
          "number": 6112,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "whenever he believes himself to be self-sufficient:",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 7,
          "number": 6113,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "for, behold, unto thy Sustainer all must return.",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 8,
          "number": 6114,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "HAST THOU ever considered him who tries to prevent",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 9,
          "number": 6115,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "a servant [of God] from praying?",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 10,
          "number": 6116,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Hast thou considered whether he is on the right way,",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 11,
          "number": 6117,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "or is concerned with God-consciousness?",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 12,
          "number": 6118,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Hast thou considered whether he may [not] be giving the lie to the truth and turning his back [upon it]?",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 13,
          "number": 6119,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Does he, then, not know that God sees [all]?",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 14,
          "number": 6120,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Nay, if he desist not, We shall most surely drag him down upon his forehead",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 15,
          "number": 6121,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "the lying, rebellious forehead! –",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 16,
          "number": 6122,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "and then let him summon [to his aid] the counsels of his own [spurious] wisdom,",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 17,
          "number": 6123,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "[the while] We shall summon the forces of heavenly chastisement!",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 18,
          "number": 6124,
          "juz": 30,
          "numpage": 597
        },
        {
          "text": "Nay, pay thou no heed to him, but prostrate thyself [before God] and draw close [unto Him]!",
          "englishName": "Al-Alaq",
          "suraNumber": 96,
          "numberInSurah": 19,
          "number": 6125,
          "juz": 30,
          "numpage": 597
        }
      ]
    },
    {
      "596": [
        {
          "text": "[the fire] which none shall have to endure but that most hapless wretch",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 15,
          "number": 6073,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "who gives the lie to the truth and turns away [from it].",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 16,
          "number": 6074,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "For, distant from it shall remain he who is truly conscious of God:",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 17,
          "number": 6075,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "he that spends his possessions [on others] so that he might grow in purity –",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 18,
          "number": 6076,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "not as payment for favours received,",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 19,
          "number": 6077,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "but only out of a longing for the countenance of his Sustainer, the All-Highest:",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 20,
          "number": 6078,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "and such, indeed, shall in time be well-pleased.",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 21,
          "number": 6079,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "CONSIDER the bright morning hours,",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 1,
          "number": 6080,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "and the night when it grows still and dark.",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 2,
          "number": 6081,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "Thy Sustainer has not forsaken thee, nor does He scorn thee:",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 3,
          "number": 6082,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "for, indeed, the life to come will be better for thee than this earlier part [of thy life]!",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 4,
          "number": 6083,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "And, indeed, in time will thy Sustainer grant thee [what thy heart desires], and thou shalt be well-pleased.",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 5,
          "number": 6084,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "Has He not found thee an orphan, and given thee shelter?",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 6,
          "number": 6085,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "And found thee lost on thy way, and guided thee?",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 7,
          "number": 6086,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "And found thee in want, and given thee sufficiency?",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 8,
          "number": 6087,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "Therefore, the orphan shalt thou never wrong,",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 9,
          "number": 6088,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "and him that seeks [thy] help shalt thou never chide,",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 10,
          "number": 6089,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "and of thy Sustainer's blessings shalt thou [ever] speak.",
          "englishName": "Ad-Dhuhaa",
          "suraNumber": 93,
          "numberInSurah": 11,
          "number": 6090,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "HAVE WE NOT opened up thy heart,",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 1,
          "number": 6091,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "and lifted from thee the burden",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 2,
          "number": 6092,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "that had weighed so heavily on thy back?",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 3,
          "number": 6093,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "And [have We not] raised thee high in dignity?",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 4,
          "number": 6094,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "And, behold, with every hardship comes ease:",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 5,
          "number": 6095,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "verily, with every hardship comes ease!",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 6,
          "number": 6096,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "Hence, when thou art freed [from distress], remain steadfast,",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 7,
          "number": 6097,
          "juz": 30,
          "numpage": 596
        },
        {
          "text": "and unto thy Sustainer turn with love.",
          "englishName": "Ash-Sharh",
          "suraNumber": 94,
          "numberInSurah": 8,
          "number": 6098,
          "juz": 30,
          "numpage": 596
        }
      ]
    },
    {
      "595": [
        {
          "text": "CONSIDER the sun and its radiant brightness,",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 1,
          "number": 6044,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and the moon as it reflects the sun!",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 2,
          "number": 6045,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "Consider the day as it reveals the world,",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 3,
          "number": 6046,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and the night as it veils it darkly!",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 4,
          "number": 6047,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "Consider the sky and its wondrous make,",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 5,
          "number": 6048,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and the earth and all its expanse!",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 6,
          "number": 6049,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "Consider the human self, and how it is formed in accordance with what it is meant to be,",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 7,
          "number": 6050,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and how it is imbued with moral failings as well as with consciousness of God!",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 8,
          "number": 6051,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "To a happy state shall indeed attain he who causes this [self] to grow in purity,",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 9,
          "number": 6052,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and truly lost is he who buries it [in darkness].",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 10,
          "number": 6053,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "TO [THIS] TRUTH gave the lie, in their overweening arrogance, [the tribe of] Thamud,",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 11,
          "number": 6054,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "when that most hapless wretch from among them rushed forward [to commit his evil deed],",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 12,
          "number": 6055,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "although God's apostle had told them, \"It is a she-camel belonging to God, so let her drink [and do her no harm]!\"",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 13,
          "number": 6056,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "But they gave him the lie, and cruelly slaughtered her - whereupon their Sustainer visited them with utter destruction for this their sin, destroying them all alike:",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 14,
          "number": 6057,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "for none [of them] had any fear of what might befall them.",
          "englishName": "Ash-Shams",
          "suraNumber": 91,
          "numberInSurah": 15,
          "number": 6058,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "CONSIDER the night as it veils [the earth] in darkness,",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 1,
          "number": 6059,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and the day as it rises bright!",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 2,
          "number": 6060,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "Consider the creation of the male and the female!",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 3,
          "number": 6061,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "Verily, [O men,] you aim at most divergent ends!",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 4,
          "number": 6062,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "Thus, as for him who gives [to others] and is conscious of God,",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 5,
          "number": 6063,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and believes in the truth of the ultimate good",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 6,
          "number": 6064,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "for him shall We make easy the path towards [ultimate] ease.",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 7,
          "number": 6065,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "But as for him who is niggardly, and thinks that he is self-sufficient,",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 8,
          "number": 6066,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and calls the ultimate good a lie –",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 9,
          "number": 6067,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "for him shall We make easy the path towards hardship:",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 10,
          "number": 6068,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and what will his wealth avail him when he goes down [to his grave]?",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 11,
          "number": 6069,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "BEHOLD, it is indeed for Us to grace [you] with guidance;",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 12,
          "number": 6070,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and, behold, Ours is [the dominion over] the life to come as well as [over] this earlier part [of your life]:",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 13,
          "number": 6071,
          "juz": 30,
          "numpage": 595
        },
        {
          "text": "and so I warn you of the raging fire -",
          "englishName": "Al-Lail",
          "suraNumber": 92,
          "numberInSurah": 14,
          "number": 6072,
          "juz": 30,
          "numpage": 595
        }
      ]
    },
    {
      "594": [
        {
          "text": "He will say, \"Oh, would that I had. provided beforehand for my life [to come]!\"",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 24,
          "number": 6017,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "For, none can make suffer as He will make suffer [the sinners] on that Day,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 25,
          "number": 6018,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "and none can bind with bonds like His.",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 26,
          "number": 6019,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "[But unto the righteous God will say,] \"O thou human being that hast attained to inner peace!",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 27,
          "number": 6020,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "Return thou unto thy Sustainer, well-pleased [and] pleasing [Him]:",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 28,
          "number": 6021,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "enter, then, together with My [other true] servants –",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 29,
          "number": 6022,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "yea, enter thou My paradise!\"",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 30,
          "number": 6023,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "NAY! I call to witness this land –",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 1,
          "number": 6024,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "this land in which thou art free to dwell -",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 2,
          "number": 6025,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "and [I call to witness] parent and offspring:",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 3,
          "number": 6026,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "Verily, We have created man into [a life of] pain, toil and trial.",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 4,
          "number": 6027,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "Does he, then, think that no one has power over him?",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 5,
          "number": 6028,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "He boasts, \"I have spent wealth abundant!\"",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 6,
          "number": 6029,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "Does he, then, think that no one sees him?",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 7,
          "number": 6030,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "Have We not given him two eyes,",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 8,
          "number": 6031,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "and a tongue, and a pair of lips,",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 9,
          "number": 6032,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "and shown him the two highways [of good and evil]?",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 10,
          "number": 6033,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "But he would not try to ascend the steep uphill road...",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 11,
          "number": 6034,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "And what could make thee conceive what it is, that steep uphill road?",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 12,
          "number": 6035,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "[It is] the freeing of one's neck [from the burden of sin],",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 13,
          "number": 6036,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "or the feeding, upon a day of [one's own] hunger,",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 14,
          "number": 6037,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "of an orphan near of kin,",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 15,
          "number": 6038,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "or of a needy [stranger] lying in the dust –",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 16,
          "number": 6039,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "and being, withal, of those who have attained to faith, and who enjoin upon one another patience in adversity, and enjoin upon one another compassion.",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 17,
          "number": 6040,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "Such are they that have attained to righteousness;",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 18,
          "number": 6041,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "whereas those who are bent on denying the truth of Our messages – they are such as have lost themselves in evil,",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 19,
          "number": 6042,
          "juz": 30,
          "numpage": 594
        },
        {
          "text": "[with] fire closing in upon them.",
          "englishName": "Al-Balad",
          "suraNumber": 90,
          "numberInSurah": 20,
          "number": 6043,
          "juz": 30,
          "numpage": 594
        }
      ]
    },
    {
      "593": [
        {
          "text": "CONSIDER the daybreak",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 1,
          "number": 5994,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and the ten nights!",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 2,
          "number": 5995,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "Consider the multiple and the One!",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 3,
          "number": 5996,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "Consider the night as it runs its course!",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 4,
          "number": 5997,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "Considering all this - could there be, to anyone endowed with reason, a [more] solemn evidence of the truth?",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 5,
          "number": 5998,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "ART THOU NOT aware of how thy Sustainer has dealt with [the tribe of] ‘Ad,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 6,
          "number": 5999,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "[the people of] Iram the many-pillared,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 7,
          "number": 6000,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "the like of whom has never been reared in all the land? –",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 8,
          "number": 6001,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and with [the tribe of] Thamud, who hollowed out rocks in the valley? –",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 9,
          "number": 6002,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and with Pharaoh of the [many] tent-poles?",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 10,
          "number": 6003,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "[It was they] who transgressed all bounds of equity all over their lands,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 11,
          "number": 6004,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and brought about great corruption therein:",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 12,
          "number": 6005,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and therefore thy Sustainer let loose upon them a scourge of suffering:",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 13,
          "number": 6006,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "for, verily, thy Sustainer is ever on the watch!",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 14,
          "number": 6007,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "BUT AS FOR man, whenever his Sustainer tries him by His generosity and by letting him enjoy a life of ease, he says, \"My Sustainer has been [justly] generous towards me\";",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 15,
          "number": 6008,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "whereas, whenever He tries him by straitening his means of livelihood, he says, \"My Sustainer has disgraced me!\"",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 16,
          "number": 6009,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "But nay, nay, [O men, consider all that you do and fail to do:] you are not generous towards the orphan,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 17,
          "number": 6010,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and you do not urge one another to feed the needy,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 18,
          "number": 6011,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and you devour the inheritance [of others] with devouring greed,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 19,
          "number": 6012,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and you love wealth with boundless love!",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 20,
          "number": 6013,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "Nay, but [how will you fare on Judgment Day,] when the earth is crushed with crushing upon crushing,",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 21,
          "number": 6014,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "and [the majesty of] thy Sustainer stands revealed, as well as [the true nature of] the angels; rank upon rank?",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 22,
          "number": 6015,
          "juz": 30,
          "numpage": 593
        },
        {
          "text": "And on that Day hell will be brought [within sight]; on that Day man will remember [all that he did and failed to do]: but what will that remembrance avail him?",
          "englishName": "Al-Fajr",
          "suraNumber": 89,
          "numberInSurah": 23,
          "number": 6016,
          "juz": 30,
          "numpage": 593
        }
      ]
    },
    {
      "592": [
        {
          "text": "But nay, [O men,] you prefer the life of this world,",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 16,
          "number": 5964,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "although the life to come is better and more enduring.",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 17,
          "number": 5965,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "Verily, [all] this has indeed been [said] in the earlier revelations –",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 18,
          "number": 5966,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "the revelations of Abraham and Moses.",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 19,
          "number": 5967,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "HAS THERE COME unto thee the tiding of the Overshadowing Event?'",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 1,
          "number": 5968,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "Some faces will on that Day be downcast,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 2,
          "number": 5969,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "toiling [under burdens of sin], worn out [by fear],",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 3,
          "number": 5970,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "about to enter a glowing fire,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 4,
          "number": 5971,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "given to drink from a boiling spring.",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 5,
          "number": 5972,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "No food for them save the bitterness of dry thorns,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 6,
          "number": 5973,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "which gives no strength and neither stills hunger.",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 7,
          "number": 5974,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "[And] some faces will on that Day shine with bliss,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 8,
          "number": 5975,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "well-pleased with [the fruit of] their striving,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 9,
          "number": 5976,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "in a garden sublime,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 10,
          "number": 5977,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "wherein thou wilt hear no empty talk.",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 11,
          "number": 5978,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "Countless springs will flow therein,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 12,
          "number": 5979,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "[and] there will be thrones [of happiness] raised high,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 13,
          "number": 5980,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "and goblets placed ready,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 14,
          "number": 5981,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "and cushions ranged,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 15,
          "number": 5982,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "and carpets spread out…",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 16,
          "number": 5983,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "DO, THEN, they [who deny resurrection] never gaze at the clouds pregnant with water, [and observe] how they are created?",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 17,
          "number": 5984,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "And at the sky, how it is raised aloft?",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 18,
          "number": 5985,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "And at the mountains, how firmly they are reared?",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 19,
          "number": 5986,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "And at the earth, how it is spread out?",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 20,
          "number": 5987,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "And so, [O Prophet,] exhort them; thy task is only to exhort:",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 21,
          "number": 5988,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "thou canst not compel them [to believe].",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 22,
          "number": 5989,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "However, as for him who turns away, being bent on denying the truth,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 23,
          "number": 5990,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "him will God cause to suffer the greatest suffering [in the life to come]:",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 24,
          "number": 5991,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "for behold, unto Us will be their return,",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 25,
          "number": 5992,
          "juz": 30,
          "numpage": 592
        },
        {
          "text": "and verily, It is for Us to call them to account.",
          "englishName": "Al-Ghaashiya",
          "suraNumber": 88,
          "numberInSurah": 26,
          "number": 5993,
          "juz": 30,
          "numpage": 592
        }
      ]
    },
    {
      "591": [
        {
          "text": "CONSIDER the heavens and that which comes in the night!",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 1,
          "number": 5932,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "And what could make thee conceive what it is that comes in the night?",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 2,
          "number": 5933,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "It is the star that pierces through [life's] darkness:",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 3,
          "number": 5934,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "[for] no human being has ever been left unguarded.",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 4,
          "number": 5935,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "LET MAN, then, observe out of what he has been created:",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 5,
          "number": 5936,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "he has been created out of a seminal fluid",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 6,
          "number": 5937,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "issuing from between the loins [of man] and the pelvic arch [of woman].",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 7,
          "number": 5938,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "Now, verily, He [who thus creates man in the first instance] is well able to bring him back [to life]",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 8,
          "number": 5939,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "on the Day when all secrets will be laid bare,",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 9,
          "number": 5940,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and [man] will have neither strength nor helper!",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 10,
          "number": 5941,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "Consider the heavens, ever-revolving,",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 11,
          "number": 5942,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and the earth, bursting forth with plants!",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 12,
          "number": 5943,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "BEHOLD, this [divine writ] is indeed a word that between truth and falsehood,",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 13,
          "number": 5944,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and is no idle tale.",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 14,
          "number": 5945,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "Behold, they [who refuse to accept it] devise many a false argument [to disprove its truth];",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 15,
          "number": 5946,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "but I shall bring all their scheming to nought.",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 16,
          "number": 5947,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "Let, then, the deniers of the truth have their will: let them have their will for a little while.",
          "englishName": "At-Taariq",
          "suraNumber": 86,
          "numberInSurah": 17,
          "number": 5948,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "EXTOL the limitless glory of thy Sustainer's name: [the glory of] the Al-Highest,",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 1,
          "number": 5949,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "who creates [every thing], and thereupon forms it in accordance with what it is meant to be,",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 2,
          "number": 5950,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and who determines the nature [of all that exists], and thereupon guides it [towards its fulfilment],",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 3,
          "number": 5951,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and who brings forth herbage,",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 4,
          "number": 5952,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and thereupon causes it to decay into rust-brown stubble!",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 5,
          "number": 5953,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "WE SHALL teach thee, and thou wilt not forget [aught of what thou art taught],",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 6,
          "number": 5954,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "save what God may will [thee to forget] - for, verily, He [alone] knows all that is open to [man's] perception as well as all that is hidden [from it]",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 7,
          "number": 5955,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and [thus] shall We make easy for thee the path towards [ultimate] ease.",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 8,
          "number": 5956,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "REMIND, THEN, [others of the truth, regardless of] whether this reminding [would seem to] be of use [or not]:",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 9,
          "number": 5957,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "in mind will keep it he who stands in awe [of God],",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 10,
          "number": 5958,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "but aloof from it will remain that most hapless wretch –",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 11,
          "number": 5959,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "he who [in the life to come] shall have to endure the great fire",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 12,
          "number": 5960,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "wherein he will neither die nor remain alive.",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 13,
          "number": 5961,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "To happiness [in the life to come] will indeed attain he who attains to purity [in this world],",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 14,
          "number": 5962,
          "juz": 30,
          "numpage": 591
        },
        {
          "text": "and remembers his Sustainer's name, and prays [unto Him].",
          "englishName": "Al-A'laa",
          "suraNumber": 87,
          "numberInSurah": 15,
          "number": 5963,
          "juz": 30,
          "numpage": 591
        }
      ]
    },
    {
      "590": [
        {
          "text": "CONSIDER the sky full of great constellations,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 1,
          "number": 5910,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "and [then bethink thyself of] the promised Day,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 2,
          "number": 5911,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "and [of] Him who witnesses [all], and [of] that unto which witness is borne [by Him]!",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 3,
          "number": 5912,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "THEY DESTROY [but] themselves, they who would ready a pit",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 4,
          "number": 5913,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "of fire fiercely burning [for all who have attained to faith]!",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 5,
          "number": 5914,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "Lo! [With glee do] they contemplate that [fire],",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 6,
          "number": 5915,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "fully conscious of what they are doing to the believers,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 7,
          "number": 5916,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "whom they hate for no other reason than that they believe in God, the Almighty, the One to whom all praise is due,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 8,
          "number": 5917,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "[and] to whom the dominion of the heavens and the earth belongs. But God is witness unto everything!",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 9,
          "number": 5918,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "Verily, as for those who persecute believing men and believing women, and thereafter do not repent, hell's suffering awaits them: yea, suffering through fire awaits them!",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 10,
          "number": 5919,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "[But,] verily, they who attain to faith and do righteous deeds shall [in the life to come] have gardens through which running waters flow - that triumph most great!",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 11,
          "number": 5920,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "VERILY, thy Sustainer's grip is exceedingly strong!",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 12,
          "number": 5921,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "Behold, it is He who creates [man] in the first instance, and He [it is who] will bring him forth anew.",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 13,
          "number": 5922,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "And He alone is truly-forgiving, all-embracing in His love,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 14,
          "number": 5923,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "in sublime almightiness enthroned,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 15,
          "number": 5924,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "a sovereign doer of whatever He wills.",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 16,
          "number": 5925,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "HAS IT ever come within thy ken, the story of the [sinful] hosts",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 17,
          "number": 5926,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "of Pharaoh, and of [the tribe of] Thamud?",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 18,
          "number": 5927,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "And yet, they who are bent on denying the truth persist in giving it the lie:",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 19,
          "number": 5928,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "but all the while God encompasses them [with His knowledge and might] without their being aware of it.",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 20,
          "number": 5929,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "Nay, but this [divine writ which they reject] is a discourse sublime,",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 21,
          "number": 5930,
          "juz": 30,
          "numpage": 590
        },
        {
          "text": "upon an imperishable tablet [inscribed].",
          "englishName": "Al-Burooj",
          "suraNumber": 85,
          "numberInSurah": 22,
          "number": 5931,
          "juz": 30,
          "numpage": 590
        }
      ]
    },
    {
      "589": [
        {
          "text": "[for, resting in paradise] on couches, they will look on [and say to themselves]:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 35,
          "number": 5883,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "\"Are these deniers of the truth being [thus] requited for [aught but] what they were wont to do?\"",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 36,
          "number": 5884,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "WHEN THE SKY is split asunder,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 1,
          "number": 5885,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "obeying its Sustainer, as in truth it must;",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 2,
          "number": 5886,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "and when the earth is leveled,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 3,
          "number": 5887,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "and casts forth whatever is in it, and becomes utterly void,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 4,
          "number": 5888,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "obeying its Sustainer, as in truth it must -:",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 5,
          "number": 5889,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "[then,] O man - thou [that] hast, verily, been toiling towards thy Sustainer in painful toil - then shalt thou meet Him!",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 6,
          "number": 5890,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "And as for him whose record shall be placed in his right hand,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 7,
          "number": 5891,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "he will in time be called to account with an easy accounting,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 8,
          "number": 5892,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "and will [be able to] turn joyfully to those of his own kind.",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 9,
          "number": 5893,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "But as for him whose record shall be given to him behind his back;",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 10,
          "number": 5894,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "he will in time pray for utter destruction:",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 11,
          "number": 5895,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "but he will enter the blazing flame.",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 12,
          "number": 5896,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "Behold, [in his earthly life] he lived joyfully among people of his own kind",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 13,
          "number": 5897,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "for, behold, he never thought that he would have to return [to God].",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 14,
          "number": 5898,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "Yea indeed! His Sustainer did see all that was in him!",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 15,
          "number": 5899,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "BUT NAY! I call to witness the sunset's [fleeting] afterglow,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 16,
          "number": 5900,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "and the night, and what it [step by step] unfolds,",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 17,
          "number": 5901,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "and the moon, as it grows to its fullness:",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 18,
          "number": 5902,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "[even thus, O men,] are you bound to move; onward from stage to stage.",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 19,
          "number": 5903,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "What, then, is amiss with them that they will not believe [in a life to come]?",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 20,
          "number": 5904,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "and [that], when the Qur’an is read unto them, they do not fall down in prostration?",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 21,
          "number": 5905,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "Nay, but they who are bent on denying the truth give the lie [to this divine writ]!",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 22,
          "number": 5906,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "Yet God has full knowledge of what they conceal [in their hearts].",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 23,
          "number": 5907,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "Hence, give them the tiding of grievous suffering [in the life to come] –",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 24,
          "number": 5908,
          "juz": 30,
          "numpage": 589
        },
        {
          "text": "unless it be such [of them] as [repent, and] attain to faith, and do good works: for theirs shall be a reward unending!.",
          "englishName": "Al-Inshiqaaq",
          "suraNumber": 84,
          "numberInSurah": 25,
          "number": 5909,
          "juz": 30,
          "numpage": 589
        }
      ]
    },
    {
      "588": [
        {
          "text": "NAY, VERILY, the record of the wicked is indeed [set down] in a mode inescapable!",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 7,
          "number": 5855,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "And what could make thee conceive what that that mode inescapable will be?",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 8,
          "number": 5856,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "A record [indelibly] inscribed!",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 9,
          "number": 5857,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth –",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 10,
          "number": 5858,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "those who give the lie to the [coming of] Judgment Day:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 11,
          "number": 5859,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "for, none gives the lie to it but such as are wont to transgress against all that is [and are] immersed in sin:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 12,
          "number": 5860,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "[and so,] whenever Our messages are conveyed to them, they but say, \"Fables of ancient times!\"",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 13,
          "number": 5861,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "Nay, but their hearts are corroded by all [the evil] that they were wont to do!",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 14,
          "number": 5862,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "Nay, verily, from [the grace of] their Sustainer shall they on that Day be debarred;",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 15,
          "number": 5863,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "and then, behold, they shall enter the blazing fire",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 16,
          "number": 5864,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "and be told: \"This is the [very thing] to which you were wont to give the lie!\"",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 17,
          "number": 5865,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "NAY, VERILY - the record of the truly virtuous is [set down] in a mode most lofty!",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 18,
          "number": 5866,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "And what could make thee conceive what that mode most lofty will be?",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 19,
          "number": 5867,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "A record [indelibly] inscribed,",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 20,
          "number": 5868,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "witnessed~ by all who have [ever] been drawn close unto God.",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 21,
          "number": 5869,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "Behold, [in the life to come] the truly virtuous will indeed be in bliss:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 22,
          "number": 5870,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "[resting on couches, they will look up [to God]:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 23,
          "number": 5871,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "upon their faces thou wilt see the brightness of bliss.",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 24,
          "number": 5872,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "They will be given a drink of pure wine whereon the seal [of God] will have been set,",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 25,
          "number": 5873,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "pouring forth with a fragrance of musk. To that [wine of paradise], then, let all such aspire as [are willing to] aspire to things of high account:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 26,
          "number": 5874,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "for it is composed of all that is most exalting",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 27,
          "number": 5875,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "a source [of bliss] whereof those who are drawn close unto God shall drink.",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 28,
          "number": 5876,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "BEHOLD, those who have abandoned themselves to sin are wont to laugh at such as have attained to faith",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 29,
          "number": 5877,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "and whenever they pass by them, they wink at one another [derisively];",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 30,
          "number": 5878,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "and whenever they return to people of their own kind, they return full of jests;",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 31,
          "number": 5879,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "and whenever they see those [who believe,] they say, \"Behold, these [people] have indeed gone astray!\"",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 32,
          "number": 5880,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "And, withal, they have no call to watch over [the beliefs of] others...",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 33,
          "number": 5881,
          "juz": 30,
          "numpage": 588
        },
        {
          "text": "But on the Day [of Judgment], they who had attained to faith will [be able to] laugh at the [erstwhile] deniers of the truth:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 34,
          "number": 5882,
          "juz": 30,
          "numpage": 588
        }
      ]
    },
    {
      "587": [
        {
          "text": "WHEN THE SKY is cleft asunder,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 1,
          "number": 5830,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "and when the stars are scattered,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 2,
          "number": 5831,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "and when the seas burst beyond their bounds,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 3,
          "number": 5832,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "and when the graves are overturned –",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 4,
          "number": 5833,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "every human being will [at last] comprehend, what he has sent ahead and what he has held back [in this world].",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 5,
          "number": 5834,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "O MAN! What is it that lures thee away from thy bountiful Sustainer,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 6,
          "number": 5835,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "who has created thee, and formed thee in accordance with what thou art meant' to be, and shaped thy nature in just proportions,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 7,
          "number": 5836,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "having put thee together in whatever form He willed [thee to have]?",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 8,
          "number": 5837,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "Nay, [O men,] but you [are lured away from God whenever you are tempted to] give the lie to [God’s] Judgment!",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 9,
          "number": 5838,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "And yet, verily, there are ever-watchful forces over you,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 10,
          "number": 5839,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "noble, recording,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 11,
          "number": 5840,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "aware of whatever you do!",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 12,
          "number": 5841,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "Behold, [in the life to come] the truly virtuous: will indeed be in bliss,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 13,
          "number": 5842,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "whereas, behold, the wicked will indeed be in a blazing fire –",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 14,
          "number": 5843,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "[a fire] which they shall enter on Judgment Day,",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 15,
          "number": 5844,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "and which they shall not [be able to] evade.",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 16,
          "number": 5845,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "And what could make thee conceive what that Judgment Day will be?",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 17,
          "number": 5846,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "And once again: What could make thee conceive what that Judgment Day will be?",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 18,
          "number": 5847,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "[It will be] a Day when no human being shall be of the least avail to another human being: for on that Day [it will become manifest that] all sovereignty is God's alone.",
          "englishName": "Al-Infitaar",
          "suraNumber": 82,
          "numberInSurah": 19,
          "number": 5848,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "WOE UNTO THOSE who give short measure:",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 1,
          "number": 5849,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "those who, when they are to receive their due from [other] people, demand that it be given in full –",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 2,
          "number": 5850,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "but when they have to measure or weigh whatever they owe to others, give less than what is due!",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 3,
          "number": 5851,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "Do they not know that they are bound to be raised from the dead",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 4,
          "number": 5852,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "[and called to account] on an awesome Day –",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 5,
          "number": 5853,
          "juz": 30,
          "numpage": 587
        },
        {
          "text": "the Day when all men shall stand before the Sustainer of all the worlds?",
          "englishName": "Al-Mutaffifin",
          "suraNumber": 83,
          "numberInSurah": 6,
          "number": 5854,
          "juz": 30,
          "numpage": 587
        }
      ]
    },
    {
      "586": [
        {
          "text": "WHEN THE SUN is shrouded in darkness,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 1,
          "number": 5801,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when the stars lose their light,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 2,
          "number": 5802,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when the mountains are made to vanish,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 3,
          "number": 5803,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when she-camels big with young, about to give birth, are left untended,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 4,
          "number": 5804,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when all beasts are gathered together,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 5,
          "number": 5805,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when the seas boil over,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 6,
          "number": 5806,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when all human beings are coupled [with their deeds],",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 7,
          "number": 5807,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when the girl-child that was buried alive is made to ask",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 8,
          "number": 5808,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "for what crime she had been slain,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 9,
          "number": 5809,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when the scrolls [of men's deeds] are unfolded,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 10,
          "number": 5810,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when heaven is laid bare,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 11,
          "number": 5811,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when the blazing fire [of hell] is kindled bright,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 12,
          "number": 5812,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and when paradise is brought into view:",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 13,
          "number": 5813,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "[on that Day] every human being will come to know what he has prepared [for himself].",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 14,
          "number": 5814,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "BUT NAY! I call to witness the revolving stars,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 15,
          "number": 5815,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "the planets that run their course and set,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 16,
          "number": 5816,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and the night as it darkly falls,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 17,
          "number": 5817,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and the morn as it softly breathes:",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 18,
          "number": 5818,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "behold, this [divine writ] is indeed the [inspired] word of a noble apostle,",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 19,
          "number": 5819,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "with strength endowed, secure with Him who in almightiness is enthroned",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 20,
          "number": 5820,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "[the word] of one to be heeded, and worthy of trust!",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 21,
          "number": 5821,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "For, this fellow-man of yours is not a madman:",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 22,
          "number": 5822,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "he truly beheld [the angel - beheld] him on the clear horizon;",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 23,
          "number": 5823,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "and he is not one to begrudge others the knowledge [of whatever has been revealed to him] out of that which is beyond the reach of human Perception.",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 24,
          "number": 5824,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "Nor is this [message] the word of any satanic force accursed.",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 25,
          "number": 5825,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "Whither, then, will you go?",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 26,
          "number": 5826,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "This [message] is no less than a reminder to all mankind –",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 27,
          "number": 5827,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "to everyone of you who wills to walk a straight way.",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 28,
          "number": 5828,
          "juz": 30,
          "numpage": 586
        },
        {
          "text": "But you cannot will it unless God, the Sustainer of all the worlds, wills [to show you that way].",
          "englishName": "At-Takwir",
          "suraNumber": 81,
          "numberInSurah": 29,
          "number": 5829,
          "juz": 30,
          "numpage": 586
        }
      ]
    },
    {
      "585": [
        {
          "text": "HE FROWNED and turned away",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 1,
          "number": 5759,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "because the blind man approached him!",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 2,
          "number": 5760,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "Yet for all thou didst know, [O Muhammad,] he might perhaps have grown in purity,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 3,
          "number": 5761,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "or have been reminded [of the truth], and helped by this reminder.",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 4,
          "number": 5762,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "Now as for him who believes himself to be self-sufficient",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 5,
          "number": 5763,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "to him didst thou give thy whole attention,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 6,
          "number": 5764,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "although thou art not accountable for his failure to attain to purity;",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 7,
          "number": 5765,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "but as for him who came unto thee full of eagerness",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 8,
          "number": 5766,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and in awe [of God]",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 9,
          "number": 5767,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "him didst thou disregard!",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 10,
          "number": 5768,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "NAY, VERILY, these [messages] are but a reminder:",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 11,
          "number": 5769,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and so, whoever is willing may remember Him",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 12,
          "number": 5770,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "in [the light of His] revelations blest with dignity,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 13,
          "number": 5771,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "lofty and pure,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 14,
          "number": 5772,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "[borne] by the hands of messengers",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 15,
          "number": 5773,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "noble and most virtuous.",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 16,
          "number": 5774,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "[But only too often] man destroys himself: how stubbornly does he deny the truth!",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 17,
          "number": 5775,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "[Does man ever consider] out of what substance [God] creates him?",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 18,
          "number": 5776,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "Out of a drop of sperm He creates him, and thereupon determines his nature,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 19,
          "number": 5777,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and then makes it easy for him to go through life;",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 20,
          "number": 5778,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and in the end He causes him to die and brings him to the grave;",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 21,
          "number": 5779,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and then, if it be His will, He shall raise him again to life,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 22,
          "number": 5780,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "Nay, but [man] has never yet fulfilled what He has enjoined upon him!",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 23,
          "number": 5781,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "Let man, then, consider [the sources of] his food:",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 24,
          "number": 5782,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "[how it is] that We pour down water, pouring it down abundantly;",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 25,
          "number": 5783,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and then We cleave the earth [with new growth], cleaving it asunder,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 26,
          "number": 5784,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and thereupon We cause grain to grow out of it,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 27,
          "number": 5785,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and vines and edible plants,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 28,
          "number": 5786,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and olive trees and date-palms,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 29,
          "number": 5787,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and gardens dense with foliage,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 30,
          "number": 5788,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and fruits and herbage,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 31,
          "number": 5789,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "for you and for your animals to enjoy.",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 32,
          "number": 5790,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "AND SO, when the piercing call [of resurrection] is heard",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 33,
          "number": 5791,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "on a Day when everyone will [want to] flee from his brother,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 34,
          "number": 5792,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and from his mother and father,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 35,
          "number": 5793,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "and from his spouse and his children:",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 36,
          "number": 5794,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "on that Day, to every one of them will his own state be of sufficient concern.",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 37,
          "number": 5795,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "Some faces will on that Day be bright with happiness,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 38,
          "number": 5796,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "laughing, rejoicing at glad tidings.",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 39,
          "number": 5797,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "And some faces will on that Day with dust be covered,",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 40,
          "number": 5798,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "with darkness overspread:",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 41,
          "number": 5799,
          "juz": 30,
          "numpage": 585
        },
        {
          "text": "these, these will be the ones who denied the truth and were immersed in iniquity!",
          "englishName": "Abasa",
          "suraNumber": 80,
          "numberInSurah": 42,
          "number": 5800,
          "juz": 30,
          "numpage": 585
        }
      ]
    },
    {
      "584": [
        {
          "text": "Lo! His Sustainer called out to him in the twice-hallowed valley:",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 16,
          "number": 5728,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "\"Go unto Pharaoh - for, verily, he has transgressed all bounds of what is right -",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 17,
          "number": 5729,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and say [unto him], 'Art thou desirous of attaining to purity?",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 18,
          "number": 5730,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "[If so,] then I shall guide thee towards [a cognition of] thy Sustainer, so that [henceforth] thou wilt stand in awe [of Him].’\"",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 19,
          "number": 5731,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "And thereupon he [went to Pharaoh and] made him aware of the great wonder [of God's grace].",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 20,
          "number": 5732,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "But [Pharaoh] gave him the lie and rebelliously rejected [all guidance],",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 21,
          "number": 5733,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and brusquely turned his back [on Moses];",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 22,
          "number": 5734,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and then he gathered [his great ones], and called [unto his people],",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 23,
          "number": 5735,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and said, \"I am your Lord All-Highest!\"",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 24,
          "number": 5736,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "And thereupon God took him to task, [and made him] a warning example in the life to come as well as in this world.",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 25,
          "number": 5737,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "In this, behold, there is a lesson indeed for all who stand in awe [of God].",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 26,
          "number": 5738,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "[O MEN!] Are you more difficult to create than the heaven which He has built?",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 27,
          "number": 5739,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "High has He reared its vault and formed it in accordance with what it was meant to be;",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 28,
          "number": 5740,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and He has made dark its night and brought forth its light of day.",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 29,
          "number": 5741,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "And after that, the earth: wide has He spread its expanse,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 30,
          "number": 5742,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and has caused its waters to come out of it, and its pastures,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 31,
          "number": 5743,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and has made the mountains firm:",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 32,
          "number": 5744,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "[all this] as a means of livelihood for you and your animals.",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 33,
          "number": 5745,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "AND SO, when the great overwhelming event [of, resurrection] comes to pass –",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 34,
          "number": 5746,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "on that Day man will [clearly] remember all that he has ever wrought;",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 35,
          "number": 5747,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and the blazing fire [of hell] will be lad open before all who [are destined to] see it.",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 36,
          "number": 5748,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "For, unto him who shall have transgressed the bounds of what is right,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 37,
          "number": 5749,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "and preferred the life of this world [to the good of his soul],",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 38,
          "number": 5750,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "that blazing fire will truly be the goal!",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 39,
          "number": 5751,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "But unto him who shall have stood in fear of his Sustainer's Presence, and held back his inner self from base desires,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 40,
          "number": 5752,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "paradise will truly be the goal!",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 41,
          "number": 5753,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "THEY WILL ASK thee [O Prophet] about the Last Hour: \"When will it come to pass?\"",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 42,
          "number": 5754,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "[But] how couldst thou tell anything about it,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 43,
          "number": 5755,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "[seeing that] with thy Sustainer alone rests the beginning and the end [of all knowledge] thereof?",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 44,
          "number": 5756,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "Thou art but [sent] to warn those who stand in awe of it.",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 45,
          "number": 5757,
          "juz": 30,
          "numpage": 584
        },
        {
          "text": "On the Day when they behold it, [it will seem to them] as if they had tarried [in this world] no longer than one evening or [one night, ending with] its morn!",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 46,
          "number": 5758,
          "juz": 30,
          "numpage": 584
        }
      ]
    },
    {
      "583": [
        {
          "text": "[But,] verily for the God-conscious there is supreme fulfilment in store:",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 31,
          "number": 5703,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "luxuriant gardens and vinyards,",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 32,
          "number": 5704,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and splendid companions well matched,",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 33,
          "number": 5705,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and a cup [of happiness] overflowing.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 34,
          "number": 5706,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "No empty talk will they hear in that [paradise], nor any lie.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 35,
          "number": 5707,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[All this will be] a reward from thy Sustainer, a gift in accordance with [His Own] reckoning –",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 36,
          "number": 5708,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[a reward from] the Sustainer of the heavens and the earth and all that is between them, the Most Gracious! [And] none shall have it in their power to raise their voices unto Him",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 37,
          "number": 5709,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "on the Day when all [human] souls and all the angels will stand up in ranks: none will speak but he to whom the Most Gracious will have given leave; and [everyone] will say [only] what is right.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 38,
          "number": 5710,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "That will be the Day of Ultimate Truth: whoever wills, then, let him take the path that leads towards his Sustainer!",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 39,
          "number": 5711,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "Verily, We have warned you of suffering near at hand - [suffering] on the Day when man shall [clearly] see what his hands have sent ahead, and when he who has denied the truth shall say, \"Oh, would that I were mere dust...!\"",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 40,
          "number": 5712,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "CONSIDER those [stars] that rise only to set,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 1,
          "number": 5713,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and move [in their orbits] with steady motion,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 2,
          "number": 5714,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and float [through space] with floating serene,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 3,
          "number": 5715,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and yet overtake [one another] with swift overtaking:",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 4,
          "number": 5716,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and thus they fulfil the [Creator's] behest!",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 5,
          "number": 5717,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[HENCE, think of] the Day when a violent convulsion will convulse [the world],",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 6,
          "number": 5718,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "to be followed by further [convulsions]!",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 7,
          "number": 5719,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "On that Day will [men's] hearts be throbbing,",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 8,
          "number": 5720,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[and] their eyes downcast...",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 9,
          "number": 5721,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[And yet,] some say, \"What! Are we indeed to be restored to our former state –",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 10,
          "number": 5722,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "even though we may have become [a heap of] crumbling bones?\"",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 11,
          "number": 5723,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[And] they add, \"That, then, would be a return with loss!\"",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 12,
          "number": 5724,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "[But,] then, that [Last Hour] will be [upon them of a sudden, as if it were] but a single accusing cry -",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 13,
          "number": 5725,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "and then, lo, they will be fully awakened [to the truth]!",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 14,
          "number": 5726,
          "juz": 30,
          "numpage": 583
        },
        {
          "text": "HAS THE STORY of Moses ever come within thy ken.",
          "englishName": "An-Naazi'aat",
          "suraNumber": 79,
          "numberInSurah": 15,
          "number": 5727,
          "juz": 30,
          "numpage": 583
        }
      ]
    },
    {
      "582": [
        {
          "text": "ABOUT WHAT do they [most often] ask one another?",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 1,
          "number": 5673,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "About the awesome tiding [of resurrection],",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 2,
          "number": 5674,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "on which they [so utterly] disagree.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 3,
          "number": 5675,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "Nay, but in time they will come to understand [it]!",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 4,
          "number": 5676,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "And once again: Nay, but in time they will come to understand!",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 5,
          "number": 5677,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "HAVE WE NOT made the earth a resting-place [for you],",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 6,
          "number": 5678,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and the mountains [its] pegs?",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 7,
          "number": 5679,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "And We have created you in pairs;",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 8,
          "number": 5680,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and We have made your sleep [a symbol of] death",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 9,
          "number": 5681,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and made the night [its] cloak",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 10,
          "number": 5682,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and made the day [a symbol of] life.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 11,
          "number": 5683,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "And We have built above you seven firmaments,",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 12,
          "number": 5684,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and have placed [therein the sun,] a lamp full of blazing splendour.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 13,
          "number": 5685,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "And from the wind-driven clouds We send down waters pouring in abundance,",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 14,
          "number": 5686,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "so that We might bring forth thereby grain, and herbs,",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 15,
          "number": 5687,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and gardens dense with follage.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 16,
          "number": 5688,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "VERILY, the Day of Distinction [between the true and the false] has indeed its appointed time:",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 17,
          "number": 5689,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "the Day when the trumpet [of resurrection] is sounded and you all come forward in multitudes;",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 18,
          "number": 5690,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and when the skies are opened and become [as wide-flung] gates;",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 19,
          "number": 5691,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "and when the mountains are made to vanish as if they had been a mirage.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 20,
          "number": 5692,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "[On that Day,] verily, hell will lie in wait [for those who deny the truth] –",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 21,
          "number": 5693,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "a goal for all who are wont to transgress the bounds of what is right!",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 22,
          "number": 5694,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "In it shall they remain for a long time.",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 23,
          "number": 5695,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "Neither coolness shall they taste therein nor any [thirst-quenching] drink –",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 24,
          "number": 5696,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "only burning despair and ice-cold darkness:",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 25,
          "number": 5697,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "a meet requital [for their sins]!",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 26,
          "number": 5698,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "Behold, they were not expecting to be called to account,",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 27,
          "number": 5699,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "having given the lie to Our messages one and all:",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 28,
          "number": 5700,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "but We have placed on record every single thing [of what they did].",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 29,
          "number": 5701,
          "juz": 30,
          "numpage": 582
        },
        {
          "text": "[And so We shall say:] \"Taste, then, [the fruit of your evil doings,] for now We shall bestow on you nothing but more and more suffering!\"",
          "englishName": "An-Naba",
          "suraNumber": 78,
          "numberInSurah": 30,
          "number": 5702,
          "juz": 30,
          "numpage": 582
        }
      ]
    },
    {
      "581": [
        {
          "text": "Did We not create you out of a humble fluid",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 20,
          "number": 5642,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "which We then let remain in [the womb's] firm keeping",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 21,
          "number": 5643,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "for a term pre-ordained?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 22,
          "number": 5644,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Thus have We determined [the nature of man's creation]: and excellent indeed is Our power to determine [what is to be]!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 23,
          "number": 5645,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Woe, on that Day unto those who give the lie to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 24,
          "number": 5646,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Have We not caused the earth to hold within itself",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 25,
          "number": 5647,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "the living and the dead?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 26,
          "number": 5648,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "and have We not set on it proud, firm mountains, and given you sweet water to drink?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 27,
          "number": 5649,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 28,
          "number": 5650,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "GO ON towards that [resurrection] which you were wont to call a lie!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 29,
          "number": 5651,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Go on towards the threefold shadow",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 30,
          "number": 5652,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "that will offer no [cooling] shade and will be of no avail against the flame",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 31,
          "number": 5653,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "which – behold!- will throw up sparks like [burning] logs,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 32,
          "number": 5654,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "like giant fiery ropes!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 33,
          "number": 5655,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth –",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 34,
          "number": 5656,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "that Day on which they will not [be able to] utter a word,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 35,
          "number": 5657,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "nor be allowed to proffer excuses!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 36,
          "number": 5658,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth –",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 37,
          "number": 5659,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "that Day of Distinction [between the true and the false, when they will be told]: \"We have brought you together with those [sinners] of olden times;",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 38,
          "number": 5660,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "and if you [think that you] have a subterfuge left, try to outwit Me!\"",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 39,
          "number": 5661,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 40,
          "number": 5662,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "[AS AGAINST this,] behold, the God-conscious shall dwell amidst [cooling] shades and springs,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 41,
          "number": 5663,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "and [partake of] whatever fruit they may desire;",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 42,
          "number": 5664,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "[and they will be told:] \"Eat and drink in good cheer in return for what you did [in life]!\"",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 43,
          "number": 5665,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "Thus, behold, do We reward the doers of good;",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 44,
          "number": 5666,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "[but] woe on that Day unto those who give the lie to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 45,
          "number": 5667,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "EAT [your fill] and enjoy your life for a little while, O you who are lost in sin!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 46,
          "number": 5668,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "[But] woe on that Day unto those who give the lie to the truth,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 47,
          "number": 5669,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "and when they are told, \"Bow down [before God]\", do not bow down:",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 48,
          "number": 5670,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "woe on that Day unto those who give the to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 49,
          "number": 5671,
          "juz": 29,
          "numpage": 581
        },
        {
          "text": "In what other tiding, then, will they, after this, believe?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 50,
          "number": 5672,
          "juz": 29,
          "numpage": 581
        }
      ]
    },
    {
      "580": [
        {
          "text": "and during some of the night, and prostrate thyself before Him, and extol His limitless glory throughout the long night.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 26,
          "number": 5617,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "Behold, they [who are unmindful of God] love this fleeting life, and leave behind them [all thought of] a grief-laden Day.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 27,
          "number": 5618,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "[They will not admit to themselves that] it We who have created them and strengthened their make - and [that] if it be Our will We can replace them entirely with others of their kind.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 28,
          "number": 5619,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "VERILY, all this is an admonition: whoever, then, so wills, may unto his Sustainer find a way.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 29,
          "number": 5620,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "But you cannot will it unless God wills [to show you that way]: for, behold, God is indeed all-seeing, wise.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 30,
          "number": 5621,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "He admits unto His grace everyone who wills [to be admitted]; but as for the evildoers - for them has He readied grievous suffering [in the life to come].",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 31,
          "number": 5622,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "CONSIDER these [messages,] sent forth in waves",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 1,
          "number": 5623,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "and then storming on with a tempest's force!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 2,
          "number": 5624,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "Consider these [messages] that spread [the truth] far and wide,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 3,
          "number": 5625,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "thus separating [right and wrong] with all clarity,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 4,
          "number": 5626,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "and then giving forth a reminder,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 5,
          "number": 5627,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "[promising] freedom from blame or [offering] a warning!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 6,
          "number": 5628,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "BEHOLD, all that you are told to expect will surely come to pass.",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 7,
          "number": 5629,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "Thus, [it will come to pass] when the stars are effaced,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 8,
          "number": 5630,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "and when the sky is rent asunder,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 9,
          "number": 5631,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "and when the mountains are scattered like dust,",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 10,
          "number": 5632,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "and when all the apostles are called together at a time appointed...",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 11,
          "number": 5633,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "For what day has the term [of all this] been set?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 12,
          "number": 5634,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "For the Day of Distinction [between the true and the false]!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 13,
          "number": 5635,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "And what could make thee conceive what that Day of Distinction will be?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 14,
          "number": 5636,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 15,
          "number": 5637,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "Did We not destroy [so many of] those [sinners] of olden days?",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 16,
          "number": 5638,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "And We shall let them be followed by those of later times:",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 17,
          "number": 5639,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "[for] thus do We deal with such as are lost in sin.",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 18,
          "number": 5640,
          "juz": 29,
          "numpage": 580
        },
        {
          "text": "Woe on that Day unto those who give the lie to the truth!",
          "englishName": "Al-Mursalaat",
          "suraNumber": 77,
          "numberInSurah": 19,
          "number": 5641,
          "juz": 29,
          "numpage": 580
        }
      ]
    },
    {
      "579": [
        {
          "text": "a source [of bliss] whereof God's servants shall drink, seeing it flow in a flow abundant.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 6,
          "number": 5597,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "[The truly virtuous are] they [who] fulfil their vows, and stand in awe of a Day the woe of which is bound to spread far and wide,",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 7,
          "number": 5598,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "and who give food - however great be their own want of it - unto the needy, and the orphan, and the captive,",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 8,
          "number": 5599,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "[saying, in their hearts,] \"We feed you for the sake of God alone: we desire no recompense from you, nor thanks:",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 9,
          "number": 5600,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "behold, we stand in awe of our Sustainer's judgment on a distressful, fateful Day!\"",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 10,
          "number": 5601,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "And so, God will preserve them from the woes of that Day, and will bestow on them brightness and joy,",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 11,
          "number": 5602,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "and will reward them for all their patience in adversity with a garden [of bliss] and with [garments of] silk.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 12,
          "number": 5603,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "In that [garden] they will on couches recline, and will know therein neither [burning] sun nor cold severe,",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 13,
          "number": 5604,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "since its [blissful] shades will come down low over them, and low will hang down its clusters of fruit, most easy to reach.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 14,
          "number": 5605,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "And they will be waited upon with vessels of silver and goblets that will [seem to] be crystal –",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 15,
          "number": 5606,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "crystal-like, [but] of silver - the measure whereof they alone will determine.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 16,
          "number": 5607,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "And in that [paradise] they will be given to drink of a cup flavoured with ginger,",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 17,
          "number": 5608,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "[derived from] a source [to be found] therein, whose name is \"Seek Thy Way\".",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 18,
          "number": 5609,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "And immortal youths will wait upon them: when thou seest them, thou wouldst deem them to be scattered pearls;",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 19,
          "number": 5610,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "and when thou seest [anything that is] there thou wilt see [only] bliss and a realm transcendent",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 20,
          "number": 5611,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "Upon those [blest] will be garments of green silk and brocade; and they will be adorned with bracelets of silver. And their Sustainer will them to drink of a drink most pure.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 21,
          "number": 5612,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "[And they will be told:] \"Verily, all this is your reward since Your endeavour [in life] has met [God's] goodly acceptance!\"",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 22,
          "number": 5613,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "VERILY, [O believer,] it is We who have bestowed from on high this Qur’an upon thee, step by step - truly a bestowal from on high!",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 23,
          "number": 5614,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "Await, then, in all patience thy Sustainer's judgment, and pay no heed to any of them, who is a wilful sinner or an ingrate;",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 24,
          "number": 5615,
          "juz": 29,
          "numpage": 579
        },
        {
          "text": "and bear in mind thy Sustainer's name at morn and evening",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 25,
          "number": 5616,
          "juz": 29,
          "numpage": 579
        }
      ]
    },
    {
      "578": [
        {
          "text": "NAY, but [most of] you love this fleeting life,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 20,
          "number": 5571,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and give no thought to the life to come [and to Judgment Day]!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 21,
          "number": 5572,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "Some faces will on that Day be bright with happiness,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 22,
          "number": 5573,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "looking up to their Sustainer;",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 23,
          "number": 5574,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and some faces will on that Day be overcast with despair,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 24,
          "number": 5575,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "knowing that a crushing calamity is about to befall them.",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 25,
          "number": 5576,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "NAY, but when [the last breath] comes up to the throat [of a dying man],",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 26,
          "number": 5577,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and people ask, \"Is there any wizard [that could save him]?\"",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 27,
          "number": 5578,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "the while he [himself] knows that this is the parting,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 28,
          "number": 5579,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and is enwrapped in the pangs of death",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 29,
          "number": 5580,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "at that time towards thy Sustainer does he feel impelled to turn!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 30,
          "number": 5581,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "[Useless, though, will be his repentance: for [as long as he was alive] he did not accept the truth, nor did he pray [for enlightenment],",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 31,
          "number": 5582,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "but, on the contrary, he gave the lie to the truth and turned away [from it],",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 32,
          "number": 5583,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and then went arrogantly back to what he had come from.",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 33,
          "number": 5584,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "[And yet, O man, thine end comes hourly] nearer unto thee, and nearer –",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 34,
          "number": 5585,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and ever nearer unto thee, and nearer!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 35,
          "number": 5586,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "DOES MAN, then, think that he is to be left to himself to go about at will?",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 36,
          "number": 5587,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "Was he not once a [mere] drop of sperm that had been spilt,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 37,
          "number": 5588,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and thereafter became a germ-cell - whereupon He created and formed [it] in accordance with what [it] was meant to be,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 38,
          "number": 5589,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "and fashioned out of it the two sexes, the male and the female?",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 39,
          "number": 5590,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "Is not He, then; able to bring the dead back to life?",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 40,
          "number": 5591,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "HAS THERE [not] been an endless span of time before man [appeared - a time] when he was not yet a thing to be thought of?",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 1,
          "number": 5592,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "Verily, it is We who have created man out of a drop of sperm intermingled, so that We might try him [in his later life]: and therefore We made him a being endowed with hearing and sight.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 2,
          "number": 5593,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "Verily, We have shown him the way: [and it rests with him to prove himself] either grateful or ungrateful.",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 3,
          "number": 5594,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "[Now,] behold, for those who deny the truth We have readied chains and shackles, and a blazing flame",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 4,
          "number": 5595,
          "juz": 29,
          "numpage": 578
        },
        {
          "text": "[whereas,] behold, the truly virtuous shall drink from a cup flavoured with the calyx of sweet-smelling flowers:",
          "englishName": "Al-Insaan",
          "suraNumber": 76,
          "numberInSurah": 5,
          "number": 5596,
          "juz": 29,
          "numpage": 578
        }
      ]
    },
    {
      "577": [
        {
          "text": "And so, of no benefit to them could be the intercession of any that would intercede for them.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 48,
          "number": 5543,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "WHAT, THEN, is amiss with them that they turn away from all admonition",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 49,
          "number": 5544,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "as though they were terrified asses",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 50,
          "number": 5545,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "fleeing from a lion?",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 51,
          "number": 5546,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Yea, everyone of them claims that he [himself] ought to have been given revelations unfolded!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 52,
          "number": 5547,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Nay, but they do not [believe in and, hence, do not] fear the life to come.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 53,
          "number": 5548,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Nay, verily, this is an admonition –",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 54,
          "number": 5549,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "and whoever wills may take it to heart.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 55,
          "number": 5550,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "But they [who do not believe in the life to come] will not take it to heart unless God so wills: [for] He is the Fount of all God-consciousness, and the Fount of all forgiveness.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 56,
          "number": 5551,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "NAY! I call to witness the Day of Resurrection!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 1,
          "number": 5552,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "But nay! I call to witness the accusing voice of man's own conscience!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 2,
          "number": 5553,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Does man think that We cannot [resurrect him and] bring his bones together again?",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 3,
          "number": 5554,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Yea indeed, We are able to make whole his very finger-tips!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 4,
          "number": 5555,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "None the less man chooses to deny what lies ahead of him,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 5,
          "number": 5556,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "asking [derisively], \"When is that Resurrection Day to be?\"",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 6,
          "number": 5557,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "But [on that Day,] when the eyesight is by fear confounded,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 7,
          "number": 5558,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "and the moon is darkened,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 8,
          "number": 5559,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "and the sun and the moon are brought together",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 9,
          "number": 5560,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "on that Day will man exclaim \"Whither to flee?\"",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 10,
          "number": 5561,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "But nay: no refuge [for thee, O man]!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 11,
          "number": 5562,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "With thy Sustainer, on that Day, the journey's end will be!",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 12,
          "number": 5563,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Man will be apprised, on that Day, of what he has done and what he has left undone:",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 13,
          "number": 5564,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "nay, but man shall against himself be an eye-witness,",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 14,
          "number": 5565,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "even though he may veil himself in excuses.",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 15,
          "number": 5566,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "MOVE NOT thy tongue in haste, [repeating the words of the revelation:]",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 16,
          "number": 5567,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "for, behold, it is for Us to gather it [in thy heart,] and to cause it to be read [as it ought to be read].",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 17,
          "number": 5568,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "Thus, when We recite it, follow thou its wording [with all thy mind]:",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 18,
          "number": 5569,
          "juz": 29,
          "numpage": 577
        },
        {
          "text": "and then, behold, it will be for Us to make its meaning clear.",
          "englishName": "Al-Qiyaama",
          "suraNumber": 75,
          "numberInSurah": 19,
          "number": 5570,
          "juz": 29,
          "numpage": 577
        }
      ]
    },
    {
      "576": [
        {
          "text": "Behold, [when Our messages are conveyed to one who is bent on denying the truth,] he reflects and meditates [as to how to disprove them] –",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 18,
          "number": 5513,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and thus he destroys himself, the way he meditates:",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 19,
          "number": 5514,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "yea, he destroys himself, the way he meditates!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 20,
          "number": 5515,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and then he looks [around for new arguments],",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 21,
          "number": 5516,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and then he frowns and glares,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 22,
          "number": 5517,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and in the end he turns his back [on Our message], and glories in his arrogance,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 23,
          "number": 5518,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and says, \"All this is mere spellbinding eloquence handed down [from olden times]!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 24,
          "number": 5519,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "This is nothing but the word of mortal man!\"",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 25,
          "number": 5520,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "[Hence,] I shall cause him to endure hell-fire [in the life to come]!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 26,
          "number": 5521,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "And what could make thee conceive what hell-fire is?",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 27,
          "number": 5522,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "It does not allow to live, and neither leaves [to die],",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 28,
          "number": 5523,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "making [all truth] visible to mortal man.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 29,
          "number": 5524,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "Over it are nineteen [powers].",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 30,
          "number": 5525,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "For We have caused none but angelic powers to lord over the fire [of hell]; and We have not caused their number to be aught but a trial for those who are bent on denying the truth - to the end that they who have been granted revelation aforetime might be convinced [of the truth of this divine writ]; and that they who have attained to faith [in it] might grow yet more firm in their faith; and that [both] they who have been granted the earlier revelation and they who believe [in this one] might be freed of all doubt; and that they in whose hearts is disease and the who deny the truth outright might ask, \"What does [your] God mean by this parable?\" In this way God lets go astray him that wills [to go astray], and guides aright him that wills [to be guided]. And none can comprehend thy Sustainers forces save Him alone: and all this is but a reminder to mortal man.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 31,
          "number": 5526,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "NAY, but consider the moon!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 32,
          "number": 5527,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "Consider the night when it departs,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 33,
          "number": 5528,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and the morn when it dawns!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 34,
          "number": 5529,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "Verily, that [hell-fire) is Indeed one of the great [forewarnings] –",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 35,
          "number": 5530,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "a warning to mortal man –",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 36,
          "number": 5531,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "to everyone of you, whether he chooses to come forward or to hang back!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 37,
          "number": 5532,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "[On the Day of Judgment,] every human being will be held in pledge for whatever [evil] he has wrought –",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 38,
          "number": 5533,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "save those who shall have attained to righteousness:",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 39,
          "number": 5534,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "[dwelling] In gardens [of paradise], they will inquire",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 40,
          "number": 5535,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "of those who were lost in sin:",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 41,
          "number": 5536,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "\"What has brought you into hell-fire?\"",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 42,
          "number": 5537,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "They will answer: \"We were not among those who prayed;",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 43,
          "number": 5538,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and neither did we feed the needy;",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 44,
          "number": 5539,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and we were wont to indulge in sinning together with all [the others] who indulged in it;",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 45,
          "number": 5540,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "and the Day of Judgment we were wont to call a lie –",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 46,
          "number": 5541,
          "juz": 29,
          "numpage": 576
        },
        {
          "text": "until certainty came upon us [in death].\"",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 47,
          "number": 5542,
          "juz": 29,
          "numpage": 576
        }
      ]
    },
    {
      "575": [
        {
          "text": "BEHOLD, [O Prophet,] thy Sustainer knows that thou keepest awake [in prayer] nearly two-thirds of the night, or one-half of it, or a third of it, together with some of those who follow thee. And God who determines the measure of night and day, is aware that you would never grudge it: and therefore He turns towards you in His grace. Recite, then, as much of the Qur’an as you may do with ease. He knows that in time there will be among you sick people, and others who will go about the land in search of God's bounty, and others who will fight in God’s cause. Recite, then, [only] as much of it as you may do with ease, and be constant in prayer, and spend in charity, and [thus] lend unto God a goodly loan: for whatever good deed you may offer up in your own behalf, you shall truly find it with God - yea, better, and richer in reward. And [always] seek God's forgiveness: behold, God is much-forgiving, a dispenser of grace!",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 20,
          "number": 5495,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "O THOU [in thy solitude] enfolded!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 1,
          "number": 5496,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "Arise and warn!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 2,
          "number": 5497,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "And thy Sustainer's greatness glorify!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 3,
          "number": 5498,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "And thine inner self purify!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 4,
          "number": 5499,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "And all defilement shun!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 5,
          "number": 5500,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "And do not through giving seek thyself to gain,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 6,
          "number": 5501,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "but unto thy Sustainer turn in patience.",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 7,
          "number": 5502,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "And [warn all men that] when the trumpet-call [of resurrection] is sounded,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 8,
          "number": 5503,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "that very Day shall be a day of anguish,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 9,
          "number": 5504,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "not of ease, for all who [now] deny the truth!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 10,
          "number": 5505,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "LEAVE Me alone [to deal] with him whom I have created alone,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 11,
          "number": 5506,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "and to whom I have granted resources vast,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 12,
          "number": 5507,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "and children as [love's] witnesses,",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 13,
          "number": 5508,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "and to whose life I gave so wide a scope:",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 14,
          "number": 5509,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "and yet, he greedily desires that I give yet more!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 15,
          "number": 5510,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "Nay, verily, it is against Our messages that he knowingly, stubbornly sets himself",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 16,
          "number": 5511,
          "juz": 29,
          "numpage": 575
        },
        {
          "text": "[and so] I shall constrain him to endure a painful uphill climb!",
          "englishName": "Al-Muddaththir",
          "suraNumber": 74,
          "numberInSurah": 17,
          "number": 5512,
          "juz": 29,
          "numpage": 575
        }
      ]
    },
    {
      "574": [
        {
          "text": "O THOU enwrapped one!",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 1,
          "number": 5476,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "Keep awake [in prayer] at night, all but a small part",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 2,
          "number": 5477,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "of one-half thereof - or make it a little less than that,",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 3,
          "number": 5478,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "or add to it [at will]; and [during that time] recite the Qur’an calmly and distinctly, with thy mind attuned to its meaning.",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 4,
          "number": 5479,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "Behold, We shall bestow upon thee a weighty message –",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 5,
          "number": 5480,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "[and,] verily, the hours of night the mind most strongly and speak with the clearest voice,",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 6,
          "number": 5481,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "whereas by day a long chain of doings is thy portion.",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 7,
          "number": 5482,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "But [whether by night or by day,] remember thy Sustainer's name, and devote thyself unto Him with utter devotion.",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 8,
          "number": 5483,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "The Sustainer of the east and the west [is He]: there is no deity save Him: hence, ascribe to Him alone the power to determine thy fate,",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 9,
          "number": 5484,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "and endure with patience whatever people may say [against thee], and avoid them with a comely avoidance.",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 10,
          "number": 5485,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "And leave Me alone [to deal] with those who give the lie to the truth - those who enjoy the blessings of life [without any thought of God] - and bear thou with them for a little while:",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 11,
          "number": 5486,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "for, behold, heavy fetters [await them] with Us, and a blazing fire,",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 12,
          "number": 5487,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "and food that chokes, and grievous suffering",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 13,
          "number": 5488,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "on the Day when the earth and the mountains will be convulsed and the mountains will [crumble and] become like a sand-dune on the move!",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 14,
          "number": 5489,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "BEHOLD, [O men,] We have sent unto you an apostle who shall bear witness to the truth before you, even as We sent an apostle unto Pharaoh:",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 15,
          "number": 5490,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "and Pharaoh rebelled against the apostle, whereupon We took him to task with a crushing grip.",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 16,
          "number": 5491,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "How, then, if you refuse to acknowledge the truth, will you protect yourselves on that Day which shall turn the hair of children grey,",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 17,
          "number": 5492,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "[the Day] on which the skies shall be rent asunder, [and] His promise [of resurrection] fulfilled?",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 18,
          "number": 5493,
          "juz": 29,
          "numpage": 574
        },
        {
          "text": "This, verily, is a reminder: let him who wills, then set out on a way to his Sustainer!",
          "englishName": "Al-Muzzammil",
          "suraNumber": 73,
          "numberInSurah": 19,
          "number": 5494,
          "juz": 29,
          "numpage": 574
        }
      ]
    },
    {
      "573": [
        {
          "text": "\"'Yet [it is true] that among us are such as have surrendered themselves to God - just as there are among us such as have abandoned themselves to wrongdoing. Now as for those who surrender themselves to Him - it is they that have attained to consciousness of what is right;",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 14,
          "number": 5461,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "but as for those who abandon themselves to wrongdoing - they are indeed but fuel for [the fires of] hell!’\"",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 15,
          "number": 5462,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "[KNOW,] THEN, that if they [who have heard Our call] keep firmly to the [right] path, We shall certainly shower them with blessings abundant,",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 16,
          "number": 5463,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "so as to test them by this means: for he who shall turn away from the remembrance of his Sustainer, him will He cause to undergo suffering most grievous.",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 17,
          "number": 5464,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "And [know] that all worship is due to God [alone]: hence, do not invoke anyone side by side with God!",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 18,
          "number": 5465,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "Yet [thus it is] that whenever a servant of God stands up in prayer to Him, they [who are bent on denying the truth] would gladly overwhelm him with their crowds.",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 19,
          "number": 5466,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "Say: \"I invoke my Sustainer alone, for I do not ascribe divinity to anyone beside Him.\"",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 20,
          "number": 5467,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "Say: \"Verily, it is not in my power to cause you harm or to endow you with consciousness of what is right.\"",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 21,
          "number": 5468,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "Say: \"Verily, no one could ever protect me from God, nor could I ever find a place to hide from Him",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 22,
          "number": 5469,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "if I should fail to convey [to the world whatever illumination comes to me] from God and His messages.\" Now as for him who rebels against God and His Apostle - verily, the fire of hell awaits him, therein to abide beyond the count of time.",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 23,
          "number": 5470,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "[Let them, then, wait] until the time when they behold that [doom] of which they were forewarned: for then they will come to understand which [kind of man] is more helpless and counts for less!",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 24,
          "number": 5471,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "Say: \"I do not know whether that [doom] of which you were forewarned is near, or whether my Sustainer has set for it a distant term.\"",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 25,
          "number": 5472,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "He [alone] knows that which is beyond the reach of a created being's perception, and to none does He disclose aught of the mysteries of His Own unfathomable knowledge,",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 26,
          "number": 5473,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "unless it be to an apostle whom He has been pleased to elect [there for]: and then He sends forth [the forces of heaven] to watch over him in whatever lies open before him and in what is beyond his ken –",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 27,
          "number": 5474,
          "juz": 29,
          "numpage": 573
        },
        {
          "text": "so as to make manifest that it is indeed [but] their Sustainer's messages that these [apostles] deliver: for it is He who encompasses [with His knowledge] all that they have [to say], just as He takes count, one by one, of everything [that exists].",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 28,
          "number": 5475,
          "juz": 29,
          "numpage": 573
        }
      ]
    },
    {
      "572": [
        {
          "text": "SAY: \"It has been revealed to me that some of the unseen beings gave ear [to this divine writ], and thereupon said [unto their fellow-beings]: \"'Verily, we have heard a wondrous discourse,",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 1,
          "number": 5448,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "guiding towards consciousness of what is right; and so We have come to believe in it. And we shall never ascribe divinity to anyone beside our Sustainer,",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 2,
          "number": 5449,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "for [we know] that sublimely exalted is our Sustainer's majesty: no consort has He ever taken unto Himself, nor a son!",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 3,
          "number": 5450,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "\"'And [now we know] that the foolish among us were wont to say outrageous things about God,",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 4,
          "number": 5451,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "and that [we were mistaken when] we thought that neither man nor [any of] the invisible forces would ever tell a lie about God.",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 5,
          "number": 5452,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "Yet [it has always happened] that certain kinds of humans would seek refuge with certain kinds of [such] invisible forces: but these only increased their confusion –",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 6,
          "number": 5453,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "so much so that they came to think, as you [once] thought, that God would never [again] send forth anyone [as His apostle].",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 7,
          "number": 5454,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "\"'And [so it happened] that we reached out towards heaven: but we found it filled with mighty guards and flames,",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 8,
          "number": 5455,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "notwithstanding that we were established in positions [which we had thought well-suited] to listening to [whatever secrets might be in] it: and anyone who now [or ever] tries to listen will [likewise] find a flame lying in wait for him!",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 9,
          "number": 5456,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "\"'And [now we have become aware] that we [created beings] may not know whether evil fortune is intended for [any of] those who live on earth, or whether it is their Sustainer’s will to endow them with consciousness of what is right:",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 10,
          "number": 5457,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "Just as [we do not know how it happens] that some from among us are righteous, while some of us are [far] below that: we have always followed widely divergent paths.",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 11,
          "number": 5458,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "\"'And, withal, we have come to know that we can never elude God [while we live] on earth, and that we can never elude Him by escaping [from life].",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 12,
          "number": 5459,
          "juz": 29,
          "numpage": 572
        },
        {
          "text": "Hence, as soon as we heard this [call to His] guidance, we came to believe in it: and he who believes in his Sustainer need never have fear of loss or injustice.",
          "englishName": "Al-Jinn",
          "suraNumber": 72,
          "numberInSurah": 13,
          "number": 5460,
          "juz": 29,
          "numpage": 572
        }
      ]
    },
    {
      "571": [
        {
          "text": "He will shower upon you heavenly blessings abundant,",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 11,
          "number": 5430,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "and will aid you with worldly goods and children, and will bestow upon you gardens, and bestow upon you running waters.",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 12,
          "number": 5431,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "\"'What is amiss with you that you cannot look forward to God's majesty,",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 13,
          "number": 5432,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "seeing that He has created [every one of] you in successive stages?",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 14,
          "number": 5433,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "\"'Do you not see how God has created seven heavens in full harmony with one another,",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 15,
          "number": 5434,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "and has set up within them the moon as a light [reflected] and set up the sun as a [radiant] lamp?",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 16,
          "number": 5435,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "\"'And God has caused you to grow out of the earth in [gradual] growth; and thereafter He will return you to it [in death]:",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 17,
          "number": 5436,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "and [then] He will bring you forth [from It] in resurrection.",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 18,
          "number": 5437,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "\"'And God has made the earth a wide expanse for you,",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 19,
          "number": 5438,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "so that you might walk thereon on spacious paths.’\"",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 20,
          "number": 5439,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "[And] Noah continued: \"O my Sustainer! Behold, they have opposed me [throughout], for they follow people whose wealth and children lead them increasingly into ruin,",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 21,
          "number": 5440,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "and who have devised a most awesome blasphemy [against Thee],",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 22,
          "number": 5441,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "inasmuch as they said [to their followers], 'Do not ever abandon your gods: abandon neither Wadd nor Suwa', and neither Yaghuth nor Ya’uq nor Nasr!'",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 23,
          "number": 5442,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "\"And so they have led many a one astray: hence, ordain Thou that these evildoers stray but farther and farther away [from all that they may desire]!\"",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 24,
          "number": 5443,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "And so, because of their sins, they were drowned [in the great flood], and were doomed to suffer the fire [of the hereafter]; and they found none who could succour them against God.",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 25,
          "number": 5444,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "And Noah prayed: \"O my Sustainer! Leave not on earth any of those who deny the truth:",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 26,
          "number": 5445,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "for, behold, If Thou dost leave them, they will [always try to] lead astray those who worship Thee, and will give birth to nothing but wickedness and stubborn ingratitude.",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 27,
          "number": 5446,
          "juz": 29,
          "numpage": 571
        },
        {
          "text": "\"O my Sustainer! Grant Thy forgiveness unto me and unto my parents, and unto everyone who enters my house as a believer, and unto all believing men and believing women [of later times]; and grant Thou that the doers of evil shall increasingly meet with destruction!\"'",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 28,
          "number": 5447,
          "juz": 29,
          "numpage": 571
        }
      ]
    },
    {
      "570": [
        {
          "text": "But nay! I call to witness [Our being] the Sustainer of all the points of sunrise and sunset: verily, well able are We",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 40,
          "number": 5415,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "to replace them with [people] better than they are; for there is nothing to prevent Us [from doing what We will].",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 41,
          "number": 5416,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "Hence, leave them to indulge in idle talk and play [with words] until they face that [Judgment] Day of theirs which they have been promised –",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 42,
          "number": 5417,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "the Day when they shall come forth in haste from their graves, as if racing towards a goal-post,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 43,
          "number": 5418,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "with downcast eyes, with ignominy overwhelming them: that Day which they were promised again and…",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 44,
          "number": 5419,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "BEHOLD. We sent Noah unto his people. [saying:] \"Warn thy people ere grievous suffering befall them!",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 1,
          "number": 5420,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "[And Noah] said: \"O my people! I am but a plain warner to you, [sent to tell you]",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 2,
          "number": 5421,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "that you should worship God [alone] and be conscious of Him. \"Now do pay heed unto me,",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 3,
          "number": 5422,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "so that He may forgive you some of your sins, and grant you respite until a term known [to Him alone]: but, behold, when the term appointed by God does come, it can never be put back - if you but knew it!\"",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 4,
          "number": 5423,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "[And after a time, Noah] said: \"O my Sustainer! Verily, I have been calling unto my people night and day",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 5,
          "number": 5424,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "but my call has only caused them to flee farther and farther away [from Thee].",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 6,
          "number": 5425,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "And behold, whenever I called unto them with a view to Thy granting them forgiveness, they put their fingers into their ears, and wrapped themselves up in their garments [of sin]; and grew obstinate, and became [yet more] arrogant in their false pride.",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 7,
          "number": 5426,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "\"And behold I called unto them openly;",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 8,
          "number": 5427,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "and, behold I preached to them in public; and I spoke to them secretly, in private;",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 9,
          "number": 5428,
          "juz": 29,
          "numpage": 570
        },
        {
          "text": "and I said: \"'Ask your Sustainer to forgive you your sins - for, verily, He is all-forgiving!",
          "englishName": "Nooh",
          "suraNumber": 71,
          "numberInSurah": 10,
          "number": 5429,
          "juz": 29,
          "numpage": 570
        }
      ]
    },
    {
      "569": [
        {
          "text": "though they may be in one another's sight: [for,] everyone who was lost in sin will on that Day but desire to ransom himself from suffering at the price of his own children,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 11,
          "number": 5386,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and of his spouse, and of his brother,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 12,
          "number": 5387,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and of all the kinsfolk who ever sheltered him,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 13,
          "number": 5388,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and of whoever [else] lives on earth, all of them - so that he could but save himself.",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 14,
          "number": 5389,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "But nay! Verily, all [that awaits him] is a raging flame,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 15,
          "number": 5390,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "tearing away his skin!",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 16,
          "number": 5391,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "It will claim all such as turn their backs [on what is right], and turn away [from the truth],",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 17,
          "number": 5392,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and amass [wealth] and thereupon withhold [it from their fellow-men].",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 18,
          "number": 5393,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "VERILY, man is born with a restless disposition.",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 19,
          "number": 5394,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "[As a rule,] whenever misfortune touches him, he is filled with self-pity;",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 20,
          "number": 5395,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and whenever good fortune comes to him, he selfishly withholds it [from others].",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 21,
          "number": 5396,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "Not so, however, those who consciously turn towards God in prayer.",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 22,
          "number": 5397,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "[and] who incessantly persevere in their prayer",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 23,
          "number": 5398,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and in whose possessions there is a due share, acknowledged [by them],",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 24,
          "number": 5399,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "for such as ask [for help] and such as are deprived [of what is good in life];",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 25,
          "number": 5400,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and who accept as true the [coming of the] Day of Judgment;",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 26,
          "number": 5401,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and who stand in dread of their Sustainer's chastisement –",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 27,
          "number": 5402,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "for, behold, of their Sustainer's chastisement none may ever feel [wholly] secure;",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 28,
          "number": 5403,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and who are mindful of their chastity,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 29,
          "number": 5404,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "[not giving way to their desires] with any but their spouses - that is, those whom they rightfully possess [through wedlock] -: for then, behold, they are free of all blame,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 30,
          "number": 5405,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "whereas such as seek to go beyond that [limit] are truly transgressors;",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 31,
          "number": 5406,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and who are faithful to their trusts and to their pledges:",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 32,
          "number": 5407,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and who stand firm whenever they bear witness;",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 33,
          "number": 5408,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "and who guard their prayers [from all worldly intent].",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 34,
          "number": 5409,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "These it is who in the gardens [of paradise] shall be honoured!",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 35,
          "number": 5410,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "WHAT, THEN, is amiss with such as are bent on denying the truth, that they run about confusedly to and fro before thee,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 36,
          "number": 5411,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "[coming upon thee] from the right and from the left, in crowds?",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 37,
          "number": 5412,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "Does every one of them hope to enter [by this means] a garden of bliss?",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 38,
          "number": 5413,
          "juz": 29,
          "numpage": 569
        },
        {
          "text": "Never! For, behold, We have created them out of something that they know [only too well]!",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 39,
          "number": 5414,
          "juz": 29,
          "numpage": 569
        }
      ]
    },
    {
      "568": [
        {
          "text": "and so, no friend has here today,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 35,
          "number": 5358,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "nor any food save the filth",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 36,
          "number": 5359,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "which none but the sinners eat!\"",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 37,
          "number": 5360,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "BUT NAY! I call to witness an that you can see,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 38,
          "number": 5361,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "as well as all that you cannot see!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 39,
          "number": 5362,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "Behold, this [Qur’an] is indeed the [inspired] word of a noble apostle,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 40,
          "number": 5363,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "and is not – however little you may [be prepared to] believe it - the word of a poet;",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 41,
          "number": 5364,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "and neither is it - however little you may [be prepared to] take it to heart - the word of a soothsayer:",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 42,
          "number": 5365,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "[it is] a revelation from the Sustainer of all the worlds.",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 43,
          "number": 5366,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "Now if he [whom We have entrusted with it] had dared to attribute some [of his own] sayings unto Us,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 44,
          "number": 5367,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "We would indeed have seized him by his right hand,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 45,
          "number": 5368,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "and would indeed have cut his life-vein.",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 46,
          "number": 5369,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "and none of you could have saved him!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 47,
          "number": 5370,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "And, verily, this [Qur’an] is a reminder to all the God-conscious!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 48,
          "number": 5371,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "And, behold, well do We know that among you are such as will give the lie to it:",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 49,
          "number": 5372,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "yet, behold, this [rejection] will indeed become a source of bitter regret for all who deny the truth [of God's revelation] –",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 50,
          "number": 5373,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "for, verily, it is truth absolute!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 51,
          "number": 5374,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "Extol, then the limitless glory of thy Sustainer's mighty name!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 52,
          "number": 5375,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "ONE who is minded to ask might ask about the suffering which [in the hereafter] is bound to befall",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 1,
          "number": 5376,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "those who deny the truth. [Know, then, that] nothing can ward it off,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 2,
          "number": 5377,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "[since it will come] from God, unto whom there are many ways of ascent:",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 3,
          "number": 5378,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "all the angels and all the inspiration [ever granted to man] ascend unto Him [daily,] in a day the length whereof is [like] fifty thousand years…",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 4,
          "number": 5379,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "Therefore, [O believer,] endure all adversity with goodly patience:",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 5,
          "number": 5380,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "behold, men look upon that [reckoning] as something far away –",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 6,
          "number": 5381,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "but, We see it as near!",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 7,
          "number": 5382,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "[It will take place] on a Day when the sky will be like molten lead,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 8,
          "number": 5383,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "and the mountains will be like tufts of wool,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 9,
          "number": 5384,
          "juz": 29,
          "numpage": 568
        },
        {
          "text": "and [when] no friend will ask about his friend,",
          "englishName": "Al-Ma'aarij",
          "suraNumber": 70,
          "numberInSurah": 10,
          "number": 5385,
          "juz": 29,
          "numpage": 568
        }
      ]
    },
    {
      "567": [
        {
          "text": "And there was Pharaoh, too, and [many of] those who lived before him, and the cities that were overthrown - [all of them] indulged in sin upon sin",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 9,
          "number": 5332,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and rebelled against their Sustainer's apostles: and so He took them to task with a punishing grasp exceedingly severe!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 10,
          "number": 5333,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "[And] behold: when the waters [of Noah's flood] burst beyond all limits, it was We who caused you to be borne [to safety] in that floating ark,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 11,
          "number": 5334,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "so that We might make all this a [lasting] reminder to you all, and that every wide-awake ear might consciously take it in.",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 12,
          "number": 5335,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "Hence, [bethink yourselves of the Last Hour,] when the trumpet [of judgment] shall be sounded with a single blast,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 13,
          "number": 5336,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and the earth and the mountains shall be lifted up and crushed with a single stroke!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 14,
          "number": 5337,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "And so, that which must come to pass will on that day have come to pass;",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 15,
          "number": 5338,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and the sky will be rent asunder - for, frail will it have become on that Day -;",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 16,
          "number": 5339,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and the angels [will appear] at its ends, and, above them, eight will bear aloft on that Day the throne of thy Sustainer’s almightiness…",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 17,
          "number": 5340,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "On that Day you shall be brought to judgment: not [even] the most hidden of your deeds will remain hidden.",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 18,
          "number": 5341,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "Now as for him whose record shall be placed in his right hand, he will exclaim: \"Come you all!\" Read this my record!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 19,
          "number": 5342,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "Behold, I did know that [one day] I would have to face my account!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 20,
          "number": 5343,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "And so he will find himself in a happy state of life,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 21,
          "number": 5344,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "in a lofty paradise,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 22,
          "number": 5345,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "with its fruits within easy reach.",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 23,
          "number": 5346,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "[And all who are thus blest will be told:] \"Eat and drink with good cheer in return for all [the good deeds] that you have sent ahead in days gone by!\"",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 24,
          "number": 5347,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "But as for him whose record shall be placed in his left hand, he will exclaim: \"Oh, would that I had never been shown this my record,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 25,
          "number": 5348,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and neither known this my account!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 26,
          "number": 5349,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "Oh, would that this [death of mine] had been the end of me!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 27,
          "number": 5350,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "Of no avail to me is all that I have [ever] possessed,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 28,
          "number": 5351,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "[and] all my power of argument has died away from me!\"",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 29,
          "number": 5352,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "Thereupon the command will go forth:] \"Lay hold of him, and shackle him,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 30,
          "number": 5353,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and then let him enter hell,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 31,
          "number": 5354,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and then thrust him into a chain [of other sinners like him - a chain] the length whereof is seventy cubits:",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 32,
          "number": 5355,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "for, behold, he did not believe in God, the Tremendous,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 33,
          "number": 5356,
          "juz": 29,
          "numpage": 567
        },
        {
          "text": "and did not feel any urge to feed the needy:",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 34,
          "number": 5357,
          "juz": 29,
          "numpage": 567
        }
      ]
    },
    {
      "566": [
        {
          "text": "downcast will be their eyes, with ignominy overwhelming them - seeing that they had been called upon [in vain] to prostrate themselves [before Him] while they were yet sound [and alive].",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 43,
          "number": 5314,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "Hence, leave Me alone with such as give the lie to this tiding. We shall bring them low, step by step, without their perceiving how it has come about:",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 44,
          "number": 5315,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "for, behold, though I may give them rein for a while, My subtle scheme is exceedingly firm!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 45,
          "number": 5316,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "Or is it that [they fear lest] thou ask them for a reward, [O Prophet,] so that they would be burdened with debt [if they listened to thee]?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 46,
          "number": 5317,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "Or [do they think] that the hidden reality [of all that exists] is within their grasp, so that [in time] they can write it down?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 47,
          "number": 5318,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "BEAR THEN with patience thy Sustainer's will and be not like him of the great fish, who cried out [in distress] after having given in to anger.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 48,
          "number": 5319,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "[And remember:] had not grace from his Sustainer reached him, he would indeed have been cast forth upon that barren shore in a state of disgrace:",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 49,
          "number": 5320,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "but [as it was,] his Sustainer had elected him and placed him among the righteous.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 50,
          "number": 5321,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "Hence, [be patient,] even though they who are bent on denying the truth would all but kill thee with their eyes whenever they hear this reminder, and [though] they say, \"[As for Muhammad,] behold, most surely he is a madman!\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 51,
          "number": 5322,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "[Be patient:] for this is nought else but a reminder [from God] to all mankind.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 52,
          "number": 5323,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "OH, THE LAYING-BARE of the truth!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 1,
          "number": 5324,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "How awesome that laying-bare of the truth!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 2,
          "number": 5325,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "And what could make thee conceive what that laying-bare of the truth will be?",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 3,
          "number": 5326,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "THE LIE gave [the tribes of] Thamud and ‘Ad to [all tidings of] that sudden calamity!",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 4,
          "number": 5327,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "Now as for the Thamud - they were destroyed by a violent upheaval [of the earth];",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 5,
          "number": 5328,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "and as for the ‘Ad - they were destroyed by a storm wind furiously raging,",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 6,
          "number": 5329,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "which He willed against them for seven nights and eight days without cease, so that in the end thou couldst see those people laid low [in death], as though they were so many [uprooted] trunks of hollow palm trees:",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 7,
          "number": 5330,
          "juz": 29,
          "numpage": 566
        },
        {
          "text": "and dost thou now see any remnant of them?",
          "englishName": "Al-Haaqqa",
          "suraNumber": 69,
          "numberInSurah": 8,
          "number": 5331,
          "juz": 29,
          "numpage": 566
        }
      ]
    },
    {
      "565": [
        {
          "text": "[For this] We shall brand him with indelible disgrace!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 16,
          "number": 5287,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "[As for such sinners,] behold, We [but] try them as We tried the owners of a certain garden who vowed that they would surely harvest its fruit on the morrow,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 17,
          "number": 5288,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "and made no allowance [for the will of God]:",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 18,
          "number": 5289,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "whereupon a visitation for thy Sustainer came upon that [garden] while they were asleep,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 19,
          "number": 5290,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "so that by the morrow it became barren and bleak.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 20,
          "number": 5291,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Now when they rose at early morn, they called unto one another,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 21,
          "number": 5292,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "\"Go early to your tilth if you want to harvest the fruit!\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 22,
          "number": 5293,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Thus they launched forth, whispering unto one another,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 23,
          "number": 5294,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "\"Indeed, no needy person shall enter it today [and come] upon you [unawares],\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 24,
          "number": 5295,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "– and early they went, strongly bent upon their purpose.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 25,
          "number": 5296,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "But as soon as they beheld [the garden and could not recognize] it, they exclaimed, \"Surely we have lost our way!\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 26,
          "number": 5297,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "- [and then,] \"Nay, but we have been rendered destitute!\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 27,
          "number": 5298,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Said the most right-minded among them: \"Did I not tell you, 'Will you not extol God's limitless glory?’\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 28,
          "number": 5299,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "They answered: \"Limitless in His glory is our Sustainer! Verily, we were doing wrong!\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 29,
          "number": 5300,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "- and then they turned upon one another with mutual reproaches.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 30,
          "number": 5301,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "[In the end] they said: \"Oh, woe unto us! Verily, we did behave outrageously!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 31,
          "number": 5302,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "[But] it may be that our Sustainer will grant us something better instead: for, verily, unto our Sustainer do we turn with hope!\"",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 32,
          "number": 5303,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "SUCH is the suffering [with which We try some people in this world]; but greater by far will be the suffering [which sinners shall have to bear] in the life to come - if they but knew it!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 33,
          "number": 5304,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "For, behold, it is the God-conscious [alone] whom gardens of bliss await with their Sustainer:",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 34,
          "number": 5305,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "or should We, perchance, treat those who surrender themselves unto Us as [We would treat] those who remain lost in sin?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 35,
          "number": 5306,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "What is amiss with you? On what do you base your judgment [of right and wrong]?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 36,
          "number": 5307,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Or have you, perchance, a [special] divine writ which you study,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 37,
          "number": 5308,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "and in which you find all that you may wish to find?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 38,
          "number": 5309,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Or have you received a solemn promise, binding on Us till Resurrection Day, that yours will assuredly be whatever you judge [to be your rightful due]?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 39,
          "number": 5310,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Ask them which of them is able to vouch for this!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 40,
          "number": 5311,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "Or have they, perchance, any sages to support their views? Well, then, if they are sincere in this their claim, let them produce those supporters of theirs",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 41,
          "number": 5312,
          "juz": 29,
          "numpage": 565
        },
        {
          "text": "on the Day when man's very being shall be bared to the bone, and when they [who now deny the truth] shall be called upon to prostrate themselves [before God], and shall be unable to do so:",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 42,
          "number": 5313,
          "juz": 29,
          "numpage": 565
        }
      ]
    },
    {
      "564": [
        {
          "text": "Yet in the end, when they shall see that [fulfilment] close at hand, the faces of those who were bent on denying the truth will be stricken with grief; and they will be told, \"This it is that you were [so derisively] calling for!\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 27,
          "number": 5268,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "SAY [O Prophet]: \"What do you think? Whether God destroys me and those who follow me, or graces us with His mercy - is there anyone that could protect [you] deniers of the truth from grievous suffering [in the life to come]?\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 28,
          "number": 5269,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Say: \"He is the Most Gracious: we have attained to faith in Him, and in Him have we placed our trust; and in time you will come to know which of us was lost in manifest error.\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 29,
          "number": 5270,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Say [unto those who deny the truth]: \"What do you think? If of a sudden all your water were to vanish underground, who [but God] could provide you with water from [new] unsullied springs?\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 30,
          "number": 5271,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Nun. CONSIDER the pen, and all that they write [therewith]!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 1,
          "number": 5272,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Thou art not, by thy Sustainer's grace, a madman!",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 2,
          "number": 5273,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "And, verily, thine shall be a reward neverending –",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 3,
          "number": 5274,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "for, behold, thou keepest indeed to a sublime way of life;",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 4,
          "number": 5275,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "and [one day] thou shalt see, and they [who now deride thee] shall see,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 5,
          "number": 5276,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "which of you was bereft of reason.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 6,
          "number": 5277,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Verily, thy Sustainer alone is fully aware as to who has strayed from His path, Just as He alone is fully aware of those who have found the right way.",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 7,
          "number": 5278,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Hence, defer not to [the likes and dislikes of] those who give the lie to the truth:",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 8,
          "number": 5279,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "they would like thee to be soft [with them], so that they might be soft [with thee].",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 9,
          "number": 5280,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Furthermore, defer not to the contemptible swearer of oaths,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 10,
          "number": 5281,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "[or to] the slanderer that goes about with defaming tales,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 11,
          "number": 5282,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "[or] the withholder of good, [or] the sinful aggressor,",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 12,
          "number": 5283,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "[or] one who is cruel, by greed possessed, and, in addition to all this, utterly useless [to his fellow-men].",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 13,
          "number": 5284,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "Is it because he is possessed of worldly goods and children",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 14,
          "number": 5285,
          "juz": 29,
          "numpage": 564
        },
        {
          "text": "that, whenever Our messages are conveyed to him, such a one says, \"Fables of ancient times\"?",
          "englishName": "Al-Qalam",
          "suraNumber": 68,
          "numberInSurah": 15,
          "number": 5286,
          "juz": 29,
          "numpage": 564
        }
      ]
    },
    {
      "563": [
        {
          "text": "AND [know, O men, that] whether you keep your beliefs secret or state them openly, He has full knowledge indeed of all that is in [your] hearts.",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 13,
          "number": 5254,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "How could it be that He who has created [all] should not know [all]? Yea, He alone is unfathomable [in His wisdom], aware!",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 14,
          "number": 5255,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "He it is who has made the earth easy to live upon: go about, then, in all its regions, and partake the sustenance which He provides: but [always bear in mind that] unto Him you shall be resurrected.",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 15,
          "number": 5256,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "Can you ever feel secure that He who is in heaven will not cause the earth to swallow you up when, lo and behold, it begins to quake?",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 16,
          "number": 5257,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "Or can you ever feel secure that He who is in heaven will not let loose against you a deadly stormwind, whereupon you would come to know how [true] My warning was?",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 17,
          "number": 5258,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "And, indeed, [many of] those who lived aforetime did give the lie [to My warnings]: and how awesome was My rejection [of them]!",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 18,
          "number": 5259,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "Have they, then, never beheld the birds above them, spreading their wings and drawing them in? None but the Most Gracious upholds them: for, verily, He keeps all things in His sight.",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 19,
          "number": 5260,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "[And] is there any, besides the Most Gracious, that could be a shield for you, and could succour you [against danger]? They who deny this truth are but lost in self- delusion!",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 20,
          "number": 5261,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "Or is there any that could provide you with sustenance if He should withhold His provision [from you]? Nay, but they [who are bent on denying the truth] stubbornly persist in their disdain [of God's messages] and in their headlong flight [from Him]!",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 21,
          "number": 5262,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "But then, is he that goes along with his face close to the ground better guided than he that walks upright on a straight way?",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 22,
          "number": 5263,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "SAY: \"[God is] He who has brought you [all] into being, and has endowed you with hearing, and sight, and hearts: [yet] how seldom are you grateful!\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 23,
          "number": 5264,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "Say: \"It is He who has multiplied you on earth; and it is unto Him that you shall be gathered [on resurrection].\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 24,
          "number": 5265,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "But they [only] ask, \"When is this promise to be fulfilled? [Answer this, O you who believe in it,] if you are men of truth!\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 25,
          "number": 5266,
          "juz": 29,
          "numpage": 563
        },
        {
          "text": "Say thou, [O Prophet:] \"Knowledge thereof rests with God alone; and I am only a plain warner.\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 26,
          "number": 5267,
          "juz": 29,
          "numpage": 563
        }
      ]
    },
    {
      "562": [
        {
          "text": "HALLOWED be He in whose hand all dominion rests, since He has the power to will anything:",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 1,
          "number": 5242,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "He who has created death as well as life, so that He might put you to a test [and thus show] which of you is best in conduct, and [make you realize that] He alone is almighty, truly forgiving.",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 2,
          "number": 5243,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "[Hallowed be] He who has created seven heavens in full harmony with one another: no fault will thou see in the creation of the Most Gracious. And turn thy vision [upon it] once more: canst thou see any flaw?",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 3,
          "number": 5244,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "Yea, turn thy vision [upon it] again and yet again: [and every time] thy vision will fall back upon thee, dazzled and truly defeated….",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 4,
          "number": 5245,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "And, indeed, We have adorned the skies nearest to the earth with lights, and have made them the object of futile guesses for the evil ones [from among men]: and for them have We readied suffering through a blazing flame –",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 5,
          "number": 5246,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "for, suffering in hell awaits all who are [thus] bent on blaspheming against their Sustainer: and how vile a journey's end!",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 6,
          "number": 5247,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "When they are cast into that [hell], they will hear its breath indrawing as it boils up,",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 7,
          "number": 5248,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "well-nigh bursting with fury; [and] every time a host [of such sinners] is flung into it, its keepers will ask them, \"Has no warner ever come to you?\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 8,
          "number": 5249,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "They will reply: \"Yea, a warner did indeed come unto us, but we gave him the lie and said, 'Never has God sent down anything [by way of revelation]! You [self-styled warners] are but lost in a great delusion!\"'",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 9,
          "number": 5250,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "And they will add: \"Had we but listened [to those warnings], or [at least] used our own reason, we would not [now] be among those who are destined for the blazing flame!\"",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 10,
          "number": 5251,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "Thus will they come to realize their sins: but [by that time,] remote will have become all good from those who are destined for the blazing flame.",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 11,
          "number": 5252,
          "juz": 29,
          "numpage": 562
        },
        {
          "text": "[As against this,] behold, for those who stand in awe of God although He is beyond the reach of their perception, there is forgiveness in store and a great reward.",
          "englishName": "Al-Mulk",
          "suraNumber": 67,
          "numberInSurah": 12,
          "number": 5253,
          "juz": 29,
          "numpage": 562
        }
      ]
    },
    {
      "561": [
        {
          "text": "O you who have attained to faith! Turn unto God in sincere repentance: it may well be that your Sustainer will efface from you your bad deeds, and will admit you into gardens through which running waters flow, on a Day on which God will not shame the Prophet and those who share his faith: their light will spread rapidly before them, and on their right; [and] they will pray: \"O our Sustainer! Cause this our light to shine for us forever, and forgive us our sins: for, verily, Thou hast the power to will anything!\"",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 8,
          "number": 5237,
          "juz": 28,
          "numpage": 561
        },
        {
          "text": "O PROPHET! Strive hard against the deniers of the truth and the hypocrites, and be adamant with them. And [if they do not repent,] their goal shall be hell - and how vile a journey’s end!",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 9,
          "number": 5238,
          "juz": 28,
          "numpage": 561
        },
        {
          "text": "For those who are bent on denying the truth God has propounded a parable in [the stories of] Noah's wife and Lot's wife: they were wedded to two of Our righteous servants, and each one betrayed her husband; and neither of the two [husbands] will be of any avail to these two women when they are told [on Judgment Day], \"Enter the fire with all those [other sinners] who enter it!\"",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 10,
          "number": 5239,
          "juz": 28,
          "numpage": 561
        },
        {
          "text": "And for those who have attained to faith God has propounded a parable in [the story of] Pharaoh's wife as she prayed \"O my Sustainer! Build Thou for me a mansion in the paradise [that is] with Thee, and save me from Pharaoh and his doings, and save me, from all evildoing folk!",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 11,
          "number": 5240,
          "juz": 28,
          "numpage": 561
        },
        {
          "text": "And [We have propounded yet another parable of God-consciousness in the story of] Mary, the daughter of Imran, who guarded her chastity, whereupon We breathed of Our spirit into that [which was in her womb], and who accepted the truth of her Sustainer’s words - and [thus,] of His revelations - and was one of the truly devout.",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 12,
          "number": 5241,
          "juz": 28,
          "numpage": 561
        }
      ]
    },
    {
      "560": [
        {
          "text": "O PROPHET! Why dost thou, out of a desire to please [one or another of] thy wives, impose [on thyself] a prohibition of something that God has made lawful to thee? But God is much-forgiving, a dispenser of grace:",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 1,
          "number": 5230,
          "juz": 28,
          "numpage": 560
        },
        {
          "text": "God has already enjoined upon you [O believers] the breaking and expiation of [such of] your oaths [as may run counter to what is right and just]: for, God is your Lord Supreme, and He alone is all-knowing, truly wise.",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 2,
          "number": 5231,
          "juz": 28,
          "numpage": 560
        },
        {
          "text": "And lo! [It so happened that] the Prophet told something in confidence to one of his wives; and when she thereupon divulged it, and God made this known to him, he acquainted [others] with some of it and passed over some of it. And as soon as he let her know it, she asked, \"Who has told thee this?\" - [to which] he replied, \"The All-Knowing, the All-Aware has told me.\"",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 3,
          "number": 5232,
          "juz": 28,
          "numpage": 560
        },
        {
          "text": "[Say, O Prophet:] \"Would that you two turn unto God in repentance, for the hearts of both of you have swerved [from what is right]! And if you uphold each other against him [who is God's message-bearer, know that] God Himself is his Protector, and [that,] therefore, Gabriel, and all the righteous among the believers and all the [other] angels will come to his aid.\"",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 4,
          "number": 5233,
          "juz": 28,
          "numpage": 560
        },
        {
          "text": "[O wives of the Prophet!] Were he to divorce [any of] you, God might well give him in your stead spouses better than you - women who surrender themselves unto God, who truly believe, devoutly obey His will, turn [unto Him] in repentance [whenever they have sinned] worship [Him alone] and go on and on [seeking His goodly acceptance] - be they women previously married or virgins.",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 5,
          "number": 5234,
          "juz": 28,
          "numpage": 560
        },
        {
          "text": "O YOU who have attained to faith! Ward off from yourselves and those who are close to you that fire [of the hereafter] whose fuel is human beings and stones: [lording] over it are angelic powers awesome [and] severe, who do not disobey God in whatever He has commanded them, but [always] do what they are bidden to do.",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 6,
          "number": 5235,
          "juz": 28,
          "numpage": 560
        },
        {
          "text": "[Hence,] O you who are bent on denying the truth, make no [empty] excuses today: [in the life to come] you shall be but recompensed for what you were doing [in this world].",
          "englishName": "At-Tahrim",
          "suraNumber": 66,
          "numberInSurah": 7,
          "number": 5236,
          "juz": 28,
          "numpage": 560
        }
      ]
    },
    {
      "559": [
        {
          "text": "[Hence,] let the women [who are undergoing a waiting-period] live in the same manner as you live yourselves, In accordance with your means; and do not harass them with a view to making their lives a misery. And if they happen to be with child, spend freely on them until they deliver their burden; and if they nurse your offspring [after the divorce has become final], give them their [due] recompense; and take counsel with one another in a fair manner [about the child's future]. And if both of you find it difficult [that the mother should nurse the child], let another woman nurse it on behalf of him [who has begotten it].",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 6,
          "number": 5223,
          "juz": 28,
          "numpage": 559
        },
        {
          "text": "[In all these respects,] let him who has ample means spend in accordance with his amplitude; and let him whose means of subsistence are scanty spend in accordance with what God has given him: God does not burden any human being with more than He has given him - [and it may well be that] God will grant, after hardship, ease.",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 7,
          "number": 5224,
          "juz": 28,
          "numpage": 559
        },
        {
          "text": "AND HOW MANY a community has turned with disdain from the commandment of its Sustainer and His apostles! -whereupon We callled them all to account with an accounting severe, and caused them to suffer with a suffering unnameable:",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 8,
          "number": 5225,
          "juz": 28,
          "numpage": 559
        },
        {
          "text": "and thus they had to taste the evil outcome of their own doing: for, [in this world,] the end of their doings was ruin,",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 9,
          "number": 5226,
          "juz": 28,
          "numpage": 559
        },
        {
          "text": "[the while] God has readied for them [yet more] suffering severe [in the life to come] Hence, remain conscious of God, O you who are endowed with insight - [you] who have attained to faith! God has indeed bestowed on you a reminder from on high:",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 10,
          "number": 5227,
          "juz": 28,
          "numpage": 559
        },
        {
          "text": "[He has sent] an apostle who conveys unto you God's clear messages, so that He might those who have attained to faith and do righteous deeds out of the depths of darkness into the light. And whoever believes in God and does what is right and just, him will He admit into gardens through which running waters flow, therein to abide beyond the count of time: indeed, a most goodly provision will God have granted him!",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 11,
          "number": 5228,
          "juz": 28,
          "numpage": 559
        },
        {
          "text": "GOD is He who has created seven heavens, and, like them, [the many aspects] of the earth. Through all of them flows down from on high, unceasingly, His [creative] will, so that you might come to know that God has the power to will anything, and that God encompasses all things with His knowledge.",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 12,
          "number": 5229,
          "juz": 28,
          "numpage": 559
        }
      ]
    },
    {
      "558": [
        {
          "text": "O PROPHET! When you [intend to divorce women, divorce them with a view to the waiting period appointed for them, and reckon the period [carefully], and be conscious of God, your Sustainer. Do not expel them from their homes; and neither shall they [be made to] leave unless they become openly guilty of immoral conduct. These, then, are the bounds set by God - and he who transgresses the bounds set by God does indeed sin against himself: [for, O man, although] thou knowest it not, after that [first breach] God may well cause something new to come about.",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 1,
          "number": 5218,
          "juz": 28,
          "numpage": 558
        },
        {
          "text": "And so, when they are about to reach the end of their waiting-term, either retain them in a fair manner or part with them in a fair manner. And let two persons of [known] probity from among your own community witness [what you have decided]; and do yourselves bear true witness before God: thus are admonished all who believe in God and the Last Day. And unto everyone who is conscious of God, He [always] grants a way out [of unhappiness],",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 2,
          "number": 5219,
          "juz": 28,
          "numpage": 558
        },
        {
          "text": "and provides for him in a manner beyond all expectation; and for everyone who places his trust in God He [alone] is enough. Verily, God always attains to His purpose: [and] indeed, unto everything has God appointed its [term and] measure.",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 3,
          "number": 5220,
          "juz": 28,
          "numpage": 558
        },
        {
          "text": "Now as for such of your women as are beyond, the age of monthly courses, as well as for such as do not have any courses, their waiting-period - if you have any doubt [about it] - shall be three [calendar] months; and as for those who are with child, the end of their waiting-term shall come when they deliver their burden. And for everyone who is conscious of God, He makes it easy to obey His commandment:",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 4,
          "number": 5221,
          "juz": 28,
          "numpage": 558
        },
        {
          "text": "[for] all this is God's commandment, which He has bestowed upon you from on high. And unto everyone who is conscious of God will He pardon [some of] his bad deeds, and will grant him a vast reward.",
          "englishName": "At-Talaaq",
          "suraNumber": 65,
          "numberInSurah": 5,
          "number": 5222,
          "juz": 28,
          "numpage": 558
        }
      ]
    },
    {
      "557": [
        {
          "text": "But as for those who are bent on denying the truth and on giving the lie to Our messages - they are destined for the fire, therein to abide: and how vile a journey’s end!",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 10,
          "number": 5209,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "NO CALAMITY can ever befall [man] unless it be by God's leave: hence, whoever believes in God guides his [own] heart [towards this truth]; and God has full knowledge of everything.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 11,
          "number": 5210,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "Pay heed, then, unto God, and pay heed unto the Apostle; and if you turn away, [know that] Our Apostle's only duty is a clear delivery of this message:",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 12,
          "number": 5211,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "God - there is no deity save Him! In God then let the believers place their trust.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 13,
          "number": 5212,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "O YOU who have attained to faith! Behold, some of your spouses and your children are enemies unto you: so beware of them! But if you pardon [their faults] and forbear, and forgive-then, behold, God will be much-forgiving, a dispenser of grace.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 14,
          "number": 5213,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "Your worldly goods and your children are but a trial and a temptation, whereas with God there is a tremendous reward.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 15,
          "number": 5214,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "Remain, then, conscious of God as best you can, and listen [to Him], and pay heed. And spend in charity for the good of your own selves: for, such as from their own covetousness are saved – it is they, they that shall attain to a happy state!",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 16,
          "number": 5215,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "If you offer up to God a goodly loan, He will amply repay you for it, and will forgive you your sins: for God is ever responsive to gratitude, forbearing,",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 17,
          "number": 5216,
          "juz": 28,
          "numpage": 557
        },
        {
          "text": "knowing all that is beyond the reach of a created being's perception as well as all that can be witnessed by a creature's senses or mind - the Almighty, the Wise!",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 18,
          "number": 5217,
          "juz": 28,
          "numpage": 557
        }
      ]
    },
    {
      "556": [
        {
          "text": "ALL THAT IS in the heavens and all that is on earth extols God's limitless glory: His is all dominion, and to Him all praise is due; and He has the power to will anything.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 1,
          "number": 5200,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "He it is who has created you: and among you are such as deny this truth, and among you are such as believe [in it]. And God sees all that you do.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 2,
          "number": 5201,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "He has created the heavens and the earth in accordance with [an inner] truth, and has formed you - and formed you so well; and with Him is your journey's end.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 3,
          "number": 5202,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "He knows all that is in the heavens and on earth; and He knows all that you keep secret as well as all that you bring into the open: for God has full knowledge of what is in the hearts [of men].",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 4,
          "number": 5203,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "HAVE THE STORIES of those who, in earlier times, refused to acknowledge the truth never yet come within your ken? [They denied it -] and so they had to taste the evil outcome of their own doings, with [more] grievous suffering awaiting them [in the life to come]:",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 5,
          "number": 5204,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "this, because time and again there came unto them their apostles with all evidence of the truth, but they [always] replied, \"Shall mere mortal men be our guides?\" And so they. denied the truth and turned away. But God was not in need [of them]: for God is self-sufficient, ever to be praised.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 6,
          "number": 5205,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "They who are bent on denying the truth claim that they will never be raised from the dead! Say: \"Yea, by my Sustainer! Most surely will you be raised from the dead, and then, most surely, will you be made to understand what you did [in life]! For, easy is this for God!\"",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 7,
          "number": 5206,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "Believe then, [O men,] in God and His Apostle, and in the light [of revelation] which We have bestowed [on you] from on high! And God is fully aware of all that you do.",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 8,
          "number": 5207,
          "juz": 28,
          "numpage": 556
        },
        {
          "text": "[Think of] the time when He shall gather you all together unto the Day of the [Last] Gathering - that Day of Loss and Gain! For, as for him who shall have believed in God and done what is just and right, He will [on that Day] efface his bad deeds, and will admit him into gardens through which running waters flow, therein to abide beyond the count of time: that will be a triumph supreme!",
          "englishName": "At-Taghaabun",
          "suraNumber": 64,
          "numberInSurah": 9,
          "number": 5208,
          "juz": 28,
          "numpage": 556
        }
      ]
    },
    {
      "555": [
        {
          "text": "for, when they are told, \"Come, the Apostle of God will pray [unto God] that you be forgiven\", they turn their heads away, and thou canst see how they draw back in their false pride.",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 5,
          "number": 5193,
          "juz": 28,
          "numpage": 555
        },
        {
          "text": "As for them it is all the same whether thou dost pray that they be forgiven or dost not pray for them: God will nor forgive them - for, behold, God does not bestow His guidance upon such iniquitous folk.",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 6,
          "number": 5194,
          "juz": 28,
          "numpage": 555
        },
        {
          "text": "It is they who say [to their compatriots], \"Do not spend anything on those who are with God's Apostle, so that they [may be forced to] leave.\" However, unto God belong the treasures of the heavens and the earth: but this truth the hypocrites cannot grasp.",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 7,
          "number": 5195,
          "juz": 28,
          "numpage": 555
        },
        {
          "text": "[And] they say, \"Indeed, when we return to the City [we,] the ones most worthy of honour will surely drive out therefrom those most contemptible ones!\" However, all honour belongs to God, and [thus] to His Apostle and those who believe [in God]: but of this the hypocrites are not aware.",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 8,
          "number": 5196,
          "juz": 28,
          "numpage": 555
        },
        {
          "text": "O YOU who have attained to faith! Let not your worldly goods or your children make you oblivious of the remembrance of God: for If any behave thus - it is they, they who are the losers!",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 9,
          "number": 5197,
          "juz": 28,
          "numpage": 555
        },
        {
          "text": "And spend on others out of what We have provided for you as sustenance, ere there come a time when death approaches any of you, and he then says, \"O my Sustainer! If only Thou wouldst grant me a delay for a short while, so that I could give in charity and be among the righteous!\"",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 10,
          "number": 5198,
          "juz": 28,
          "numpage": 555
        },
        {
          "text": "But never does God grant a delay to a human being when his term has come; and God is fully aware of all that you do.",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 11,
          "number": 5199,
          "juz": 28,
          "numpage": 555
        }
      ]
    },
    {
      "554": [
        {
          "text": "O YOU who have attained to faith! When the call to prayer is sounded on the day of congregation, hasten to the remembrance of God, and leave all worldly commerce: this is for your own good, if you but knew it.",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 9,
          "number": 5186,
          "juz": 28,
          "numpage": 554
        },
        {
          "text": "And when the prayer is ended, disperse freely on earth and seek to obtain [something] of God's bounty; but remember God often, so that you might attain to a happy state!",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 10,
          "number": 5187,
          "juz": 28,
          "numpage": 554
        },
        {
          "text": "Yet [it does happen that] when people become aware of [an occasion for] worldly gain or a passing delight, they rush headlong towards it, and leave thee standing [and preaching]. Say: \"That which is with God is far better than all passing delight and all gain! And God is the best of providers!\"",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 11,
          "number": 5188,
          "juz": 28,
          "numpage": 554
        },
        {
          "text": "WHEN THE HYPOCRITES come unto thee, they say, \"We bear witness that thou art indeed God's Apostle!\" But God knows that thou art truly His Apostle; and He bears witness that the hypocrites are indeed false [in their declaration of faith].",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 1,
          "number": 5189,
          "juz": 28,
          "numpage": 554
        },
        {
          "text": "They have made their oaths a cover [for their falseness], and thus they turn others away from the Path of God. Evil indeed is all that they are wont to do:",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 2,
          "number": 5190,
          "juz": 28,
          "numpage": 554
        },
        {
          "text": "this, because [they profess that] they have attained to faith, whereas [inwardly] they deny the truth - and so, a seal has been set on their hearts so that they can no longer understand [what is true and what false].",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 3,
          "number": 5191,
          "juz": 28,
          "numpage": 554
        },
        {
          "text": "Now when thou seest them, their outward appearance may please thee; and when they speak, thou art inclined to lend ear to what they say. [But though they may seem as sure of themselves] as if they were timbers [firmly] propped up, they think that every shout is [directed] against them. They are the [real] enemies [of all faith], so beware of them. [They deserve the imprecation,] \"May God destroy them!\" How perverted are their minds!",
          "englishName": "Al-Munaafiqoon",
          "suraNumber": 63,
          "numberInSurah": 4,
          "number": 5192,
          "juz": 28,
          "numpage": 554
        }
      ]
    },
    {
      "553": [
        {
          "text": "ALL THAT IS in the heavens and all that is on earth extols the limitless glory of God, the Sovereign Supreme, the Holy, the Almighty, the Wise!",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 1,
          "number": 5178,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "He it is who has sent unto the unlettered people an apostle from among themselves, to convey unto them His messages, and to cause them to grow in purity, and to impart unto them the divine writ as well as wisdom - whereas before that they were indeed, most obviously, lost in error",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 2,
          "number": 5179,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "and [to cause this message to spread] from them unto other people as soon as they come into contact with them: for He alone is almighty, truly wise!",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 3,
          "number": 5180,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "Such is God's bounty: He grants it to anyone who is willing [to receive it]: for God is limitless in His great bounty.",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 4,
          "number": 5181,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "THE PARABLE of those who were graced with the burden of the Torah, and thereafter failed to bear this burden, is that of an ass that carries a load of books [but cannot benefit from them]. Calamitous is the parable of people who are bent on giving the lie to God's messages - for God does not bestow His guidance upon such evildoing folk!",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 5,
          "number": 5182,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "Say: \"O you who follow the Jewish faith! If you claim that you [alone] are close to God, to the exclusion of all other people, then you should be longing for death - if what you say is true!\"",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 6,
          "number": 5183,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "But never will they long for it, because [they are aware] of what their hands have wrought in this world; and God has full knowledge of evildoers.",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 7,
          "number": 5184,
          "juz": 28,
          "numpage": 553
        },
        {
          "text": "Say: \"Behold, the death from which you are fleeing is bound to overtake you - and then you will be brought back unto Him who knows all that is beyond the reach of a created being's perception as well as all that can be witnessed by a creature's senses or mind, whereupon He will make you truly understand all that you were doing [in life].",
          "englishName": "Al-Jumu'a",
          "suraNumber": 62,
          "numberInSurah": 8,
          "number": 5185,
          "juz": 28,
          "numpage": 553
        }
      ]
    },
    {
      "552": [
        {
          "text": "And [this happened, too,] when Jesus, the son of Mary, said: \"O children of Israel! Behold, I am an apostle of God unto you, [sent] to confirm the truth of whatever there still remains of the Torah, and to give [you] the glad tiding of an apostle who shall come after me, whose name shall be Ahmad.\" But when he [whose coming Jesus had foretold] came unto them with all evidence of the truth, they said: \"This [alleged message of his] is [nothing but] spellbinding eloquence!\"",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 6,
          "number": 5169,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "And who could be more wicked than one who invents [such] a lie about [a message from] God, seeing that he is [but] being called to self-surrender unto Him? But God does not bestow His guidance upon evil-doing folk.",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 7,
          "number": 5170,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "They aim to extinguish God's light with their utterances: but God has willed to spread His light in all its fullness, however hateful this may be to all who deny the truth",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 8,
          "number": 5171,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "He it is who has sent forth His Apostle with [the task of] spreading guidance and the religion of truth, to the end that He make it prevail over all [false] religion, however hateful this may be to those who ascribe divinity to aught but God.",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 9,
          "number": 5172,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "O YOU who have attained to faith! Shall I point out to you a bargain that will save you from grievous suffering [in this world and in the life to come]?",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 10,
          "number": 5173,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "You are to believe in God and His Apostle, and to strive hard in God's cause with your possessions and your lives: this is for your own good - if you but knew it!",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 11,
          "number": 5174,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "[If you do so,] He will forgive you your sins, and [in the life to come] will admit you into gardens through which running waters flow, and into goodly mansions in [those] gardens of perpetual bliss: that [will be] the triumph supreme!",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 12,
          "number": 5175,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "And [withal, He will grant you] yet another thing that you dearly love: succour from God [in this world], and a victory soon to come: and [thereof, O Prophet,] give thou a glad tiding to all who believe.",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 13,
          "number": 5176,
          "juz": 28,
          "numpage": 552
        },
        {
          "text": "O YOU who have attained to faith! Be helpers [in the cause of God - even as Jesus, the son of Mary, said unto the white-garbed ones, \"Who will be my helpers in God's cause?\" - whereupon the white-garbed [disciples] replied, \"We shall be [thy] helpers [in the cause] of God!\" And so [it happened that] some of the children of Israel came to believe [in the apostleship of Jesus], whereas others denied the truth. But [now] We have given strength against their foes unto those who have [truly] attained to faith: and they have become the ones that shall prevail.",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 14,
          "number": 5177,
          "juz": 28,
          "numpage": 552
        }
      ]
    },
    {
      "551": [
        {
          "text": "O Prophet! Whenever believing women come unto thee to pledge their allegiance to thee, [pledging] that [henceforth] they would not ascribe divinity, in any way, to aught but God, and would not steal, and would not commit adultery, and would not kill their children, and would not indulge in slander, falsely devising it out of nothingness: and would not disobey thee in anything [that thou declarest to be] right - then accept their pledge of allegiance, and pray to God to forgive them their [past] sins: for, behold, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 12,
          "number": 5162,
          "juz": 28,
          "numpage": 551
        },
        {
          "text": "O YOU who have attained to faith! Be not friends with people whom God has condemned! They [who would befriend them] are indeed bereft of all hope of a life to come - just as those deniers of the truth are bereft of all hope of [ever again seeing] those who are [now] in their graves.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 13,
          "number": 5163,
          "juz": 28,
          "numpage": 551
        },
        {
          "text": "ALL THAT IS in the heavens and all that is on earth extols God's limitless glory: for He alone is almighty, truly wise!",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 1,
          "number": 5164,
          "juz": 28,
          "numpage": 551
        },
        {
          "text": "O YOU who have attained to faith! Why do you say one thing and do another?",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 2,
          "number": 5165,
          "juz": 28,
          "numpage": 551
        },
        {
          "text": "Most loathsome is it in the sight of God that you say what you do not do!",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 3,
          "number": 5166,
          "juz": 28,
          "numpage": 551
        },
        {
          "text": "Verily, God loves [only] those who fight in His cause in [solid] ranks, as though they were a building firm and compact.",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 4,
          "number": 5167,
          "juz": 28,
          "numpage": 551
        },
        {
          "text": "Now when Moses spoke to his people, [it was this same truth that he had in mind:] \"O my people! Why do you cause me grief, the while you know that I am an apostle of God sent unto you?\" And so, when they swerved from the right way, God let their hearts swerve from the truth: for God does not bestow His guidance upon iniquitous folk.",
          "englishName": "As-Saff",
          "suraNumber": 61,
          "numberInSurah": 5,
          "number": 5168,
          "juz": 28,
          "numpage": 551
        }
      ]
    },
    {
      "550": [
        {
          "text": "In them, indeed, you have a good example for everyone who looks forward [with hope and awe] to God and the Last Day. And if any turns away, [let him know that] God is truly self-sufficient, the One to whom all praise is due.\"",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 6,
          "number": 5156,
          "juz": 28,
          "numpage": 550
        },
        {
          "text": "[But] it may well be that God will bring about [mutual] affection between you [O believers] and some of those whom you [now] face as enemies: for, God is all-powerful - and God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 7,
          "number": 5157,
          "juz": 28,
          "numpage": 550
        },
        {
          "text": "As for such [of the unbelievers] as do not fight against you on account of [your] faith, and neither drive you forth from your homelands, God does not forbid you to show them kindness and to behave towards them with full equity: for, verily, God loves those who act equitably.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 8,
          "number": 5158,
          "juz": 28,
          "numpage": 550
        },
        {
          "text": "God only forbids you to turn in friendship towards such as fight against you because of [your] faith, and drive you forth from your homelands, or aid [others] in driving you forth: and as for those [from among you] who turn towards them in friendship; it is they, they who are truly wrongdoers!",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 9,
          "number": 5159,
          "juz": 28,
          "numpage": 550
        },
        {
          "text": "O YOU who have attained to faith! Whenever believing women come unto you, forsaking the domain of evil, examine them, [although only] God is fully aware of their faith; and if you have thus ascertained that they are believers, do not send them back to the deniers of the truth, [since] they are [no longer] lawful to their erstwhile husbands, and these are [no longer] lawful to them. None the less, you shall return to them whatever they have spent [on their wives by way of dower]; and [then, O believers,] you will be committing no sin if you marry them after giving them their dowers. On the other hand, hold not to the marriage-tie with women who [continue to] deny the truth, and ask but for [the return of] whatever you have spent [by way of dower] -just as they [whose wives have gone over to you] have the right to demand [the return of] whatever they have spent. Such is God's judgment: He judges between you [in equity] - for God is all-knowing, wise.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 10,
          "number": 5160,
          "juz": 28,
          "numpage": 550
        },
        {
          "text": "And if any of your wives should go over to the deniers of the truth, and you are thus afflicted in turn, then give unto those whose wives have gone away the equivalent of what they had spent [on their wives by way of dower], and remain conscious of God, in whom you believe!",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 11,
          "number": 5161,
          "juz": 28,
          "numpage": 550
        }
      ]
    },
    {
      "549": [
        {
          "text": "O YOU who have attained to faith! Do not take My enemies - who are your enemies as well - for your friends, showing them affection even though they are bent on denying whatever truth has come unto you, [and even though] they have driven the Apostle and yourselves away, [only] because you believe in God, your Sustainer! If [it be true that] you have gone forth [from your homes] to strive in My cause, and out of a longing for My goodly acceptance, [do not take them for your friends,] inclining towards them in secret affection: for I am fully aware of all that you may conceal as well as of all that you do openly. And any of you who does this has already strayed from the right path.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 1,
          "number": 5151,
          "juz": 28,
          "numpage": 549
        },
        {
          "text": "If they could but overcome you, they would [still] remain your foes, and would stretch forth their hands and tongues against you with evil intent: for they desire that you [too] should deny the truth.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 2,
          "number": 5152,
          "juz": 28,
          "numpage": 549
        },
        {
          "text": "But [bear in mind that] neither your kinsfolk nor [even] your own children will be of any benefit to you on Resurrection Day, [for then] He will decide between you [on your merit alone]: and God sees all that you do.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 3,
          "number": 5153,
          "juz": 28,
          "numpage": 549
        },
        {
          "text": "Indeed, you have had a good example in Abraham and those who followed him, when they said unto their [idolatrous] people: \"Verily, we are quit of you and of all that you worship instead of God: we deny the truth of whatever you believe; and between us and you there has arisen enmity and hatred, to last until such a time as you come to believe in the One God!\" The only exception was Abraham's saying to his father \"I shall indeed pray for [God's] forgiveness for thee, although I have it not in my power to obtain anything from God in thy behalf.\" [And Abraham and his followers prayed:] \"O our Sustainer! In Thee have we placed our trust, and unto Thee do we turn: for unto Thee is all journeys' end.",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 4,
          "number": 5154,
          "juz": 28,
          "numpage": 549
        },
        {
          "text": "O our Sustainer! Make us not a plaything for those who are bent on denying the truth! And forgive us our sins, O our sustainer: for Thou alone art, almighty, truly wise!\"",
          "englishName": "Al-Mumtahana",
          "suraNumber": 60,
          "numberInSurah": 5,
          "number": 5155,
          "juz": 28,
          "numpage": 549
        }
      ]
    },
    {
      "548": [
        {
          "text": "Thus, in the end, both [the deniers of the truth and the hypocrites] will find themselves in the fire, therein to abide: for such is the recompense of evil­doers.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 17,
          "number": 5143,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "O YOU who have attained to faith! Remain conscious of God; and let every human being look to what he sends ahead for the morrow! And [once again]: Remain conscious of God, for God is fully aware of all that you do;",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 18,
          "number": 5144,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "and be not like those who are oblivious of God, and whom He therefore causes to be oblivious of [what is good for] their own selves: [for] it is they, they who are truly depraved!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 19,
          "number": 5145,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "Not equal are those who are destined for the fire and those who are destined for paradise: those who are destined for paradise - it is they, they [alone] who shall triumph [on Judgment Day]!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 20,
          "number": 5146,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "HAD WE bestowed this Qur’an from on high upon a mountain, thou wouldst indeed see it humbling itself, breaking asunder for awe of God. And [all] such parables We propound unto men, so that they might [learn to] think.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 21,
          "number": 5147,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "GOD IS HE save whom there is no deity: the One who knows all that is beyond the reach of a created being’s perception, as well as all that can be wit­nessed by a creature’s senses or mind: He, the Most Gracious, the Dispenser of Grace.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 22,
          "number": 5148,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "God is He save whom there is no deity: the Sovereign Supreme, the Holy, the One with whom all salvation rests, the Giver of Faith, the One who determines what is true and false, the Almighty, the One who subdues wrong and restores right, the One to whom all greatness belongs! Utterly remote is God, in His limitless glory, from anything to which men may ascribe a share in His divinity!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 23,
          "number": 5149,
          "juz": 28,
          "numpage": 548
        },
        {
          "text": "He is God, the Creator, the Maker who shapes all forms and appearances! His [alone] are the attributes of perfection. All that is in the heavens and on earth extols His limitless glory: for He alone is almighty, truly wise!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 24,
          "number": 5150,
          "juz": 28,
          "numpage": 548
        }
      ]
    },
    {
      "547": [
        {
          "text": "And so, they who come after them pray: “O our Sustainer! Forgive us our sins, as well as those of our brethren who preceded us in faith, and let not our hearts entertain any unworthy thoughts or feelings against [any of] those who have attained to faith. O our Sustainer! Verily, Thou art compassionate, a dispenser of grace!”",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 10,
          "number": 5136,
          "juz": 28,
          "numpage": 547
        },
        {
          "text": "ART THOU NOT aware of how those who would always dissemble [their real feelings] speak to their truth-denying brethren from among the followers of earlier revelation: “If you are driven away, we shall most certainly go forth with you, and shall never pay heed to anyone against you; and if war is waged against you, we shall most certainly come to your succour.” But God bears witness that they are most flagrantly lying:",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 11,
          "number": 5137,
          "juz": 28,
          "numpage": 547
        },
        {
          "text": "[for] if those [to whom they have pledged themselves] are indeed driven away, they will not go forth with them; and if war is waged against them, they will not come to their succour; and even if they [try to] succour them, they will most certainly turn their backs [in flight], and in the end will [themselves] find no succour.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 12,
          "number": 5138,
          "juz": 28,
          "numpage": 547
        },
        {
          "text": "Nay, [O believers,] you arouse in their bosoms a fear more intense than [even their fear of] God: this, because they are people who fail to grasp the truth.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 13,
          "number": 5139,
          "juz": 28,
          "numpage": 547
        },
        {
          "text": "Never will they fight you, [even] in unison, otherwise than from within fortified strongholds or from behind walls. Severe is their warlike discord among themselves: thou wouldst think that they are united, whereas [in fact] their hearts are at odds [with one another]: this, because they are people who will not use their reason.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 14,
          "number": 5140,
          "juz": 28,
          "numpage": 547
        },
        {
          "text": "[To both kinds of your enemies, O believers, is bound to happen] the like of [what happened to] those who, a short while before them, had to taste the evil that came from their own doings, with [yet more] grievous suffering awaiting them [in the life to come]:",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 15,
          "number": 5141,
          "juz": 28,
          "numpage": 547
        },
        {
          "text": "the like of [what happens] when Satan says unto man, “Deny the truth!” - but as soon as [man] has denied the truth, [Satan] says, “Behold, I am not responsible for thee: behold, I fear God, the Sustainer of all the worlds!”",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 16,
          "number": 5142,
          "juz": 28,
          "numpage": 547
        }
      ]
    },
    {
      "546": [
        {
          "text": "this, because they cut themselves off from God and His Apostle: and as for him who cuts himself off from God and His Apostle - verily, God is severe in retribution!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 4,
          "number": 5130,
          "juz": 28,
          "numpage": 546
        },
        {
          "text": "Whatever [of their] palm trees you may have cut down, [O believers,] or left standing on their roots, was [done] by God’s leave, and in order that He might confound the iniquitous.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 5,
          "number": 5131,
          "juz": 28,
          "numpage": 546
        },
        {
          "text": "Yet [remember:] whatever [spoils taken] from the enemy God has turned over to His Apostle, you did not have to spur horse or riding-camel for its sake: but God gives His apostles mastery over whomever He wills - for God has the power to will anything.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 6,
          "number": 5132,
          "juz": 28,
          "numpage": 546
        },
        {
          "text": "Whatever [spoils taken] from the people of those villages God has turned over to His Apostle - [all of it] belongs to God and the Apostle, and the near of kin [of deceased believers], and the orphans, and the needy, and the wayfarer, so that it may not be [a benefit] going round and round among such of you as may [already] be rich. Hence, accept [willing­ly] whatever the Apostle* gives you [thereof], and refrain from [demanding] anything that he withholds from you; and remain conscious of God: for, verily, God is severe in retribution.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 7,
          "number": 5133,
          "juz": 28,
          "numpage": 546
        },
        {
          "text": "[Thus, part of such war-gains shall be given] to the poor among those who have forsaken the domain of evil: those who have been driven from their homelands and their possessions, seeking favour with God and [His] goodly acceptance, and who aid [the cause of] God and His Apostle: it is they, they who are true to their word!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 8,
          "number": 5134,
          "juz": 28,
          "numpage": 546
        },
        {
          "text": "And [it shall be offered, too, unto the poor from among] those who, before them, had their abode in this realm and in faith - [those] who love all that come to them in search of refuge, and who harbour in their hearts no grudge for whatever the others may have been given, but rather give them preference over themselves, even though poverty be their own lot: for, such as from their own covetousness are saved - it is they, they that shall attain to a happy state!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 9,
          "number": 5135,
          "juz": 28,
          "numpage": 546
        }
      ]
    },
    {
      "545": [
        {
          "text": "Thou canst not find people who [truly] believe in God and the Last Day and [at the same time] love anyone who contends against God and His Apostle - even though they be their fathers, or their sons, or their brothers, or [others of] their kindred. [As for the true believers,] it is they in whose hearts He has inscribed faith, and whom He has strength­ened with inspiration from Himself, and whom [in time] He will admit into gardens through which running waters flow, therein to abide. Well-pleased is God with them, and well-pleased are they with Him. They are God’s partisans: oh, verily, it is they, the partisans of God, who shall attain to a happy state!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 22,
          "number": 5126,
          "juz": 28,
          "numpage": 545
        },
        {
          "text": "ALL THAT IS in the heavens and all that is on earth extols God’s limitless glory: for He alone is almighty, truly wise.",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 1,
          "number": 5127,
          "juz": 28,
          "numpage": 545
        },
        {
          "text": "He it is who turned out of their homes, at the time of [their] first gathering [for war], such of the followers of earlier revelation as were bent on denying the truth. You did not think [O believers] that they would depart [without resistance] - just as they thought that their strongholds would protect them against God: but God came upon them in a manner which they had not expected, and cast terror into their hearts; [and thus] they destroyed their homes by their own hands as well as the hands of the believers. Learn a lesson, then, O you who are endowed with insight!",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 2,
          "number": 5128,
          "juz": 28,
          "numpage": 545
        },
        {
          "text": "And had it not been for God’s having ordained banishment for them, He would indeed have imposed [yet greater] suffering on them in this world: still, in the life to come there awaits them suffering through fire:",
          "englishName": "Al-Hashr",
          "suraNumber": 59,
          "numberInSurah": 3,
          "number": 5129,
          "juz": 28,
          "numpage": 545
        }
      ]
    },
    {
      "544": [
        {
          "text": "O YOU who have attained to faith! Whenever you [intend to] consult the Apostle, offer up something in charity on the occasion of your consultation: this will be for your own good, and more conducive to your [inner] purity. Yet if you are unable to do so, [know that,] verily, God is much-forgiving, a dispen­ser of grace.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 12,
          "number": 5116,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "Do you, perchance, fear lest [you may be sinning if] you cannot offer up anything in charity on the occasion of your consultation [with the Apostle]? But if you fail to do it [for lack of opportunity], and God turns unto you in His mercy, remain but con­stant in prayer and render [no more than] the purify­ing dues, and [thus] pay heed unto God and His Apostle: for God is fully aware of all that you do.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 13,
          "number": 5117,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "ART THOU NOT aware of those who would be friends with people whom God has condemned? They are neither of you [O believers] nor of those [who utterly reject the truth]: and so they swear to a falsehood the while they know [it to be false].",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 14,
          "number": 5118,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "God has readied for them suffering severe [in the life to come]. Behold, evil indeed is what they are wont to do:",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 15,
          "number": 5119,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "they have made their oaths a cover [for their falseness], and thus they turn others away from the path of God: hence, shameful suffering awaits them.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 16,
          "number": 5120,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "Neither their worldly possessions nor their offspring will be of the least avail to them against God: it is they who are destined for the fire, therein to abide!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 17,
          "number": 5121,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "On the Day when God will raise them all from the dead, they will swear before Him as they [now] swear before you, thinking that they are on firm ground [in their assumptions]. Oh, verily, it is they, they who are the [greatest] liars!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 18,
          "number": 5122,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "Satan has gained mastery over them, and has caused them to remain oblivious of the remem­brance of God. Such as these are Satan’s partisans: oh, verily, it is they, the partisans of Satan, who will truly be the losers!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 19,
          "number": 5123,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "Verily, those who contend against God and His Apostle - it is they who [on Judgment Day] shall find themselves among the most abject.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 20,
          "number": 5124,
          "juz": 28,
          "numpage": 544
        },
        {
          "text": "[For] God has thus ordained: “I shall most certainly prevail, I and My apostles!” Verily, God is powerful, almighty!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 21,
          "number": 5125,
          "juz": 28,
          "numpage": 544
        }
      ]
    },
    {
      "543": [
        {
          "text": "ART THOU NOT aware that God knows all that is in the heavens and all that is on earth? Never can there be a secret confabulation between three persons without His being the fourth of them, nor between five without His being the sixth of them; and neither between less than that, or more, without His being with them wherever they may he. But in the end, on Resurrection Day, He will make them truly understand what they did: for, verily, God has full knowledge of everything.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 7,
          "number": 5111,
          "juz": 28,
          "numpage": 543
        },
        {
          "text": "Art thou not aware of such as have been for­bidden [to intrigue through] secret confabulations, and yet [always] revert to that which they have been forbidden, and conspire with one another with a view to sinful doings, and aggressive conduct, and dis­obedience to the Apostle? Now whenever such [people] approach thee, [O Muhammad,] they salute thee with a greeting which God has never countenanced; and they say to themselves, “Why does not God chastise us for what we are saying?” Hell shall be their allotted portion: they shall [in­deed] enter it - and how vile a journey’s end!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 8,
          "number": 5112,
          "juz": 28,
          "numpage": 543
        },
        {
          "text": "[Hence,] O you who have attained to faith, when you do hold secret confabulations, do not conspire with one another with a view to sinful doings, and aggressive conduct, and disobedience to the Apostle, but [rather] hold counsel in the cause of virtue and God-consciousness: and [always] remain conscious of God, unto whom you all shall be gathered.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 9,
          "number": 5113,
          "juz": 28,
          "numpage": 543
        },
        {
          "text": "[All other kinds of] secret confabulations are but of Satan’s doing, so that he might cause grief to those who have attained to faith; yet he cannot harm them in the least, unless it be by God’s leave: in God, then, let the believers place their trust!",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 10,
          "number": 5114,
          "juz": 28,
          "numpage": 543
        },
        {
          "text": "O YOU who have attained to faith! When you are told, “Make room for one another in your collective life”, do make room: [and in return,] God will make room for you [in His grace]. And whenever you are told, “Rise up [for a good deed]”, do rise up; [and] God will exalt by [many] degrees those of you who have attained to faith and, [above all,] such as have been vouchsafed [true] knowledge: for God is fully aware of all that you do.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 11,
          "number": 5115,
          "juz": 28,
          "numpage": 543
        }
      ]
    },
    {
      "542": [
        {
          "text": "GOD has indeed heard the words of her who pleads with thee concerning her husband, and complains Unto God. And God does hear what you both have to say: verily, God is all-hearing, all-seeing.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 1,
          "number": 5105,
          "juz": 28,
          "numpage": 542
        },
        {
          "text": "As for those of you who [henceforth] separate themselves from their wives by saying, “Thou art as unlawful to me as my mother”, [let them bear in mind that] they can never be [as] their mothers: none are their mothers save those who gave them birth: and so, behold, they but utter a saying that runs counter to reason, and is [therefore] false. But, behold, God is indeed an absolver of sins, much-forgiving:",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 2,
          "number": 5106,
          "juz": 28,
          "numpage": 542
        },
        {
          "text": "hence, as for those who would separate themselves from their wives by saying, “Thou art as unlawful to me as my mother”, and thereafter would go back on what they have said, [their atonement] shall be the freeing of a human being from bondage before the couple may touch one another again: this you are [hereby] exhorted to do - for God is fully aware of all that you do.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 3,
          "number": 5107,
          "juz": 28,
          "numpage": 542
        },
        {
          "text": "However, he who does not have the where­withal shall fast [instead] for two consecutive months before the couple may touch one another again; and he who is unable to do it shall feed sixty needy ones: this, so that you might prove your faith in God and His Apostle. Now these are the bounds set by God; and grievous suffering [in the life to come] awaits all who deny the truth.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 4,
          "number": 5108,
          "juz": 28,
          "numpage": 542
        },
        {
          "text": "Verily, those who contend against God and His Apostle shall be brought low even as those [evildoers] who lived before them were brought low after We had bestowed [on them] clear messages from on high. And [so,] for those who deny the truth there will be shameful suffering in store",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 5,
          "number": 5109,
          "juz": 28,
          "numpage": 542
        },
        {
          "text": "on the Day when God will raise them all from the dead and will make them truly understand all that they did [in life]: God will have taken [all of] it into account, even though they [themselves] may have forgotten it - for God is wit­ness unto everything.",
          "englishName": "Al-Mujaadila",
          "suraNumber": 58,
          "numberInSurah": 6,
          "number": 5110,
          "juz": 28,
          "numpage": 542
        }
      ]
    },
    {
      "541": [
        {
          "text": "Indeed, [even aforetime] did We send forth Our apostles with all evidence of [this] truth; and through them We bestowed revelation from on high, and [thus gave you] a balance [wherewith to weigh right and wrong], so that men might behave with equity; and We bestowed [upon you] from on high [the ability to make use of] iron, in which there is awesome power as well as [a source of] benefits for man: and [all this was given to you] so that God might mark out those who would stand up for him and His Apostle, even though He [Himself] is beyond the reach of human perception. Verily, God is powerful, almighty!",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 25,
          "number": 5100,
          "juz": 27,
          "numpage": 541
        },
        {
          "text": "And, indeed, [to the same end] We sent forth Noah and Abraham [as Our message-bearers], and established prophethood and revelation among their descendants; and some of them were on the right way, but many were iniquitous.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 26,
          "number": 5101,
          "juz": 27,
          "numpage": 541
        },
        {
          "text": "And thereupon We caused [other of] Our apostles to follow in their footsteps; and [in the course of time] We caused them to be followed by Jesus, the son of Mary, upon whom We bestowed the Gospel; and in the hearts of those who [truly] followed him We engendered compassion and mercy. But as for monastic asceticism - We did not enjoin it upon them: they invented it themselves out of a desire for God’s goodly acceptance. But then, they did not [always] observe it as it ought to have been observed: and so We granted their recompense unto such of them as had [truly] attained to faith, whereas many of them became iniquitous.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 27,
          "number": 5102,
          "juz": 27,
          "numpage": 541
        },
        {
          "text": "O YOU who have attained to faith! Remain con­scious of God, and believe in His Apostle, [and] He will grant you doubly of His grace, and will light for you a light wherein you shall walk, and will forgive you [your past sins]: for God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 28,
          "number": 5103,
          "juz": 27,
          "numpage": 541
        },
        {
          "text": "And the followers of earlier revelation should know that they have no power whatever over any of God’s bounty, seeing that all bounty is in God’s hand [alone]: He grants it unto whomever He wills - for God is limitless in His great bounty.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 29,
          "number": 5104,
          "juz": 27,
          "numpage": 541
        }
      ]
    },
    {
      "540": [
        {
          "text": "For, they who have attained to faith in God and His Apostle - it is they, they who uphold the truth, and they who bear witness [thereto] before God: [and so] they shall have their reward and their light! But as for those who are bent on denying the truth and on giving the lie to Our messages - it is they who are destined for the blazing fire!",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 19,
          "number": 5094,
          "juz": 27,
          "numpage": 540
        },
        {
          "text": "KNOW [O men] that the life of this world is but a play and a passing delight, and a beautiful show, and [the cause of] your boastful vying with one another, and [of your] greed for more and more riches and children. Its parable is that of [life-giving] rain: the herbage which it causes to grow delights the tillers of the soil; but then it withers, and thou canst see it turn yellow; and in the end it crumbles into dust. But [the abiding truth of man’s condition will become fully apparent] in the life to come: [either] suffering severe, or God’s forgiveness and His goodly acceptance: for the life of this world is nothing but an enjoyment of self-delusion.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 20,
          "number": 5095,
          "juz": 27,
          "numpage": 540
        },
        {
          "text": "[Hence,] vie with one another in seeking to attain to your Sustainer’s forgiveness, and [thus] to a paradise as vast as the heavens and the earth, which has been readied for those who have attained to faith in God and His Apostle: such is the bounty of God which He grants unto whomever He wills - for God is limitless in His great bounty.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 21,
          "number": 5096,
          "juz": 27,
          "numpage": 540
        },
        {
          "text": "NO CALAMITY can ever befall the earth, and nei­ther your own selves, unless it be [laid down] in Our decree before We bring it into being: verily, all this is easy for God.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 22,
          "number": 5097,
          "juz": 27,
          "numpage": 540
        },
        {
          "text": "[Know this,] so that you may not despair over whatever [good] has escaped you nor exult [unduly] over whatever [good] has come to you: for, God does not love any of those who, out of self-conceit, act in a boastful manner –",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 23,
          "number": 5098,
          "juz": 27,
          "numpage": 540
        },
        {
          "text": "those who are niggardly [with God’s bounty] and bid others to be niggardly! And he who turns his back [on this truth ought to know that], verily, God alone is self-sufficient, the One to whom all praise is due!",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 24,
          "number": 5099,
          "juz": 27,
          "numpage": 540
        }
      ]
    },
    {
      "539": [
        {
          "text": "on the Day when thou shalt see all believing men and believing women, with their light spreading rapidly before them and on their right, [and with this wel­come awaiting them:] “A glad tiding for you today: gardens through which running waters flow, therein to abide! This, this is the triumph supreme!”",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 12,
          "number": 5087,
          "juz": 27,
          "numpage": 539
        },
        {
          "text": "On that Day shall the hypocrites, both men and women, speak [thus] unto those who have attained to faith: “Wait for us! Let us have a [ray of] light from your light!” [But] they will be told: “Turn back, and seek a light [of your own]!” And thereupon a wall will be raised between them [and the believers], with a gate in it: within it will be grace and mercy, and against the outside thereof, suffering.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 13,
          "number": 5088,
          "juz": 27,
          "numpage": 539
        },
        {
          "text": "They [who will remain without] will call out to those [within], “Were we not with you?” - [to which] the others will answer: “So it was! But you allowed yourselves to succumb to temptation, and you were hesitant [in your faith], and you were doubtful [of resurrection]; and your wishful thinking beguiled you until God’s command came to pass: for, [indeed, your own] deceptive thoughts about God deluded you",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 14,
          "number": 5089,
          "juz": 27,
          "numpage": 539
        },
        {
          "text": "“And so, no ransom shall be accepted today from you, and neither from those who were [openly] bent on denying the truth. Your goal is the fire: it is your [only] refuge - and how evil a journey’s end!”",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 15,
          "number": 5090,
          "juz": 27,
          "numpage": 539
        },
        {
          "text": "IS IT NOT time that the hearts of all who have attained to faith should feel humble at the remem­brance of God and of all the truth that has been bestowed [on them] from on high, lest they become like those who were granted revelation aforetime, and whose hearts have hardened with the passing of time so that many of them are [now] depraved?",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 16,
          "number": 5091,
          "juz": 27,
          "numpage": 539
        },
        {
          "text": "[But] know that God gives life to the earth after it has been lifeless! We have indeed made Our messages clear unto you, so that you might use your reason.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 17,
          "number": 5092,
          "juz": 27,
          "numpage": 539
        },
        {
          "text": "Verily, as for the men and women who accept the truth as true and who [thus] offer up unto God a goodly loan, they will be amply repaid, and shall have a noble reward [in the life to come].",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 18,
          "number": 5093,
          "juz": 27,
          "numpage": 539
        }
      ]
    },
    {
      "538": [
        {
          "text": "He it is who has created the heavens and the earth in six aeons, and is established on the throne of His almightiness. He knows all that enters the earth, and all that comes out of it, as well as all that descends from the skies, and all that ascends to them. And He is with you wherever you may be; and God sees all that you do.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 4,
          "number": 5079,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "His is the dominion over the heavens and the earth; and all things go back unto God [as their source].",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 5,
          "number": 5080,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "He makes the night grow longer by shortening the day, and makes the day grow longer by shortening the night; and He has full knowledge of what is in the hearts [of men].",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 6,
          "number": 5081,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "BELIEVE in God and His Apostle, and spend on others out of that of which He has made you trus­tees: for, those of you who have attained to faith and who spend freely [in God’s cause] shall have a great reward.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 7,
          "number": 5082,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "And why should you not believe in God, seeing that the Apostle calls you to believe in [Him who is] your Sustainer, and [seeing that] He has taken a pledge from you? [Why should you not believe in Him] if you are able to believe [in anything]?",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 8,
          "number": 5083,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "It is He who bestows from on high clear mes­sages unto [this] His servant, to lead you out of the deep darkness into the light: for, behold, God is most compassionate towards you, a dispenser of grace.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 9,
          "number": 5084,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "And why should you not spend freely in the cause of God, seeing that God’s [alone] is the heritage of the heavens and the earth? Not equal are those of you who spent and fought [in God’s cause] before the Victory [and those who did not do so]: they are of a higher rank than those who would spend and fight [only] after it - although God has promised the ultimate good to all [who strive in His cause]. and god is aware of that you do.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 10,
          "number": 5085,
          "juz": 27,
          "numpage": 538
        },
        {
          "text": "WHO IS IT that will offer up unto God a goodly loan, which He will amply repay For, such [as do so] shall have a noble reward",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 11,
          "number": 5086,
          "juz": 27,
          "numpage": 538
        }
      ]
    },
    {
      "537": [
        {
          "text": "Behold, it is a truly noble discourse,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 77,
          "number": 5056,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "[conveyed unto man] in a well-guarded divine writ",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 78,
          "number": 5057,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "which none but the pure [of heart] can touch:",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 79,
          "number": 5058,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "a revelation from the Sustainer of all the worlds!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 80,
          "number": 5059,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "Would you, now, look down with disdain on a tiding like this,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 81,
          "number": 5060,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "and make it your daily bread [as it were] to call the truth a lie?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 82,
          "number": 5061,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "Why, then, when [the last breath] comes up to the throat [of a dying man],",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 83,
          "number": 5062,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "the while you are [helplessly] looking on -",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 84,
          "number": 5063,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "and while We are closer to him than you, although you see [Us] not -:",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 85,
          "number": 5064,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "why, then, if [you think that] you are not truly dependent [on Us],",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 86,
          "number": 5065,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "can you not cause that [ebbing life] to return - if what you claim is true?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 87,
          "number": 5066,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "[ALL OF YOU are destined to die.] Now if one happens to be of those who are drawn close unto God,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 88,
          "number": 5067,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "happiness [awaits him in the life to come], and inner fulfillment, and a garden of bliss.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 89,
          "number": 5068,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "And if one happens to be of those who have attained to righteousness,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 90,
          "number": 5069,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "[he, too, will be wel­comed into paradise with the words,] “Peace be unto thee [that art] of those who have attained to right­eousness!”",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 91,
          "number": 5070,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "But if one happens to be of those who are wont to call the truth a lie, and [thus] go astray,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 92,
          "number": 5071,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "a welcome of burning despair [awaits him in the life to come,]",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 93,
          "number": 5072,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "and the heat of a blazing fire!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 94,
          "number": 5073,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "Verily, this is indeed the truth of truths!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 95,
          "number": 5074,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "Extol, then, the limitless glory of thy Sus­tainer’s mighty name!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 96,
          "number": 5075,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "ALL THAT IS in the heavens and on earth extols God’s limitless glory: for He alone is almighty, truly wise!",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 1,
          "number": 5076,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "His is the dominion over the heavens and the earth; He grants life and deals death; and He has the power to will anything.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 2,
          "number": 5077,
          "juz": 27,
          "numpage": 537
        },
        {
          "text": "He is the First and the Last, and the Outward as well as the Inward: and He has full knowledge of everything.",
          "englishName": "Al-Hadid",
          "suraNumber": 57,
          "numberInSurah": 3,
          "number": 5078,
          "juz": 27,
          "numpage": 537
        }
      ]
    },
    {
      "536": [
        {
          "text": "and then, verily, O you who have gone astray and called the truth a lie,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 51,
          "number": 5030,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "you will indeed have to taste of the tree of deadly fruit,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 52,
          "number": 5031,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "and will have to fill your bellies therewith,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 53,
          "number": 5032,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "and will thereupon have to drink [many a draught] of burning despair -",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 54,
          "number": 5033,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "drink it as the most insatiably thirsty camels drink!”",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 55,
          "number": 5034,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Such will be their welcome on Judgment Day!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 56,
          "number": 5035,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "WE who have created you, [O men:] why, then, do you not accept the truth?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 57,
          "number": 5036,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Have you ever considered that [seed] which you emit?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 58,
          "number": 5037,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Is it you who create it - or are We the source of its creation?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 59,
          "number": 5038,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "We have [indeed] decreed that death shall be [ever-present] among you: but there is nothing to prevent Us",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 60,
          "number": 5039,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "from changing the nature of your existence and bringing you into being [anew] in a manner [as yet] unknown to you.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 61,
          "number": 5040,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "And [since] you are indeed aware of the [miracle of your] coming into being in the first instance - why, then, do you not bethink yourselves [of Us]?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 62,
          "number": 5041,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Have you ever considered the seed which you cast upon the soil?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 63,
          "number": 5042,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Is it you who cause it to grow - or are We the cause of its growth?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 64,
          "number": 5043,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "[For,] were it Our will, We could indeed turn it into chaff, and you would be left to wonder [and to lament],",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 65,
          "number": 5044,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "“Verily, we are ruined!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 66,
          "number": 5045,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Nay, but we have been deprived [of our livelihood]!”",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 67,
          "number": 5046,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Have you ever considered the water which you drink?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 68,
          "number": 5047,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Is it you who cause it to come down from the clouds - or are We the cause of its coming down?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 69,
          "number": 5048,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "[It comes down sweet - but] were it Our will, We could make it burningly salty and bitter: why, then, do you not give thanks [unto Us]?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 70,
          "number": 5049,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Have you ever considered the fire which you kindle?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 71,
          "number": 5050,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Is it you who have brought into being the tree that serves as its fuel - or are We the cause of its coming into being?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 72,
          "number": 5051,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "It is We who have made it a means to remind [you of Us], and a comfort for all who are lost and hungry in the wilderness [of their lives].",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 73,
          "number": 5052,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "Extol, then, the limitless glory of thy Sus­tainer’s mighty name!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 74,
          "number": 5053,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "NAY, I call to witness the coming-down in parts [of this Qur’an]",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 75,
          "number": 5054,
          "juz": 27,
          "numpage": 536
        },
        {
          "text": "and, behold, this is indeed a most solemn affirmation, if you but knew it!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 76,
          "number": 5055,
          "juz": 27,
          "numpage": 536
        }
      ]
    },
    {
      "535": [
        {
          "text": "Immortal youths will wait upon them",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 17,
          "number": 4996,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "with goblets, and ewers, and cups filled with water from unsullied springs",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 18,
          "number": 4997,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "by which their minds will not be clouded and which will not make them drunk;",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 19,
          "number": 4998,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and with fruit of any kind that they may choose,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 20,
          "number": 4999,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and with the flesh of any fowl that they may desire.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 21,
          "number": 5000,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "And [with them will be their] companions pure, most beautiful of eye,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 22,
          "number": 5001,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "like unto pearls [still] hidden in their shells.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 23,
          "number": 5002,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "[And this will be] a reward for what they did [in life].",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 24,
          "number": 5003,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "No empty talk will they hear there, nor any call to sin,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 25,
          "number": 5004,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "but only the tiding of inner soundness and peace.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 26,
          "number": 5005,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "NOW AS FOR those who have attained to righteous­ness - what of those who have attained to righteous­ness?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 27,
          "number": 5006,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "[They, too, will find themselves] amidst fruit- laden lote-trees,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 28,
          "number": 5007,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and acacias flower-clad,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 29,
          "number": 5008,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and shade extended,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 30,
          "number": 5009,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and waters gushing,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 31,
          "number": 5010,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and fruit abounding,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 32,
          "number": 5011,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "never-failing and never out of reach.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 33,
          "number": 5012,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "And [with them will be their] spouses, raised high:",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 34,
          "number": 5013,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "for, behold, We shall have brought them into being in a life renewed,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 35,
          "number": 5014,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "having resurrected them as virgins,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 36,
          "number": 5015,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "full of love, well-matched",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 37,
          "number": 5016,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "with those who have attained to righteousness:",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 38,
          "number": 5017,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "a good many of olden times,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 39,
          "number": 5018,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and a good many of later times.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 40,
          "number": 5019,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "BUT AS FOR those who have persevered in evil - what of those who have persevered in evil?",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 41,
          "number": 5020,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "[They will find themselves] in the midst of scorching winds, and burning despair,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 42,
          "number": 5021,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and the shadows of black smoke -",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 43,
          "number": 5022,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "[shadows] neither cooling nor soothing.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 44,
          "number": 5023,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "For, behold, in times gone by they were wont to abandon themselves wholly to the pursuit of pleasures,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 45,
          "number": 5024,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and would persist in heinous sinning,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 46,
          "number": 5025,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and would say, “What! After we have died and become mere dust and bones, shall we, forsooth, be raised from the dead? -",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 47,
          "number": 5026,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "and perhaps, too, our forebears of old?”",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 48,
          "number": 5027,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "Say: “Verily, those of olden times and those of later times",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 49,
          "number": 5028,
          "juz": 27,
          "numpage": 535
        },
        {
          "text": "will indeed be gathered together at an appointed time on a Day known [only to God]:",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 50,
          "number": 5029,
          "juz": 27,
          "numpage": 535
        }
      ]
    },
    {
      "534": [
        {
          "text": "In both of them will be [all kinds of] fruit, and date-palms and pomegranates.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 68,
          "number": 4969,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 69,
          "number": 4970,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "In these [gardens] will be [all] things most excellent and beautiful.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 70,
          "number": 4971,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 71,
          "number": 4972,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "[There the blest will live with their] companions pure and modest, in pavilions [splendid] -",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 72,
          "number": 4973,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "which, then, of your Sustainer’s powers can you disavow? –",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 73,
          "number": 4974,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "[companions] whom neither man nor invisible being will have touched ere then.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 74,
          "number": 4975,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 75,
          "number": 4976,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "[In such a paradise will they dwell,] reclining upon meadows green and carpets rich in beauty.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 76,
          "number": 4977,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 77,
          "number": 4978,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "HALLOWED be thy Sustainer’s name, full of majesty and glory!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 78,
          "number": 4979,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "WHEN THAT which must come to pass [at last] comes to pass,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 1,
          "number": 4980,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "there will be nought that could give the lie to its having come to pass,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 2,
          "number": 4981,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "abasing [some], exalting [others]!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 3,
          "number": 4982,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "When the earth is shaken with a shaking [severe],",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 4,
          "number": 4983,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "and the mountains are shattered into [countless] shards,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 5,
          "number": 4984,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "so that they become as scat­tered dust –",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 6,
          "number": 4985,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "[on that Day,] then, shall you be [divided into] three kinds.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 7,
          "number": 4986,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "Thus, there shall be such as will have attained to what is right: oh, how [happy] will be they who have attained to what is right!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 8,
          "number": 4987,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "And there shall be such as will have lost them­selves in evil: oh, how [unhappy] will be they who have lost themselves in evil!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 9,
          "number": 4988,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "But the foremost shall be [they who in life were] the foremost [in faith and good works]:",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 10,
          "number": 4989,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "they who were [always] drawn close unto God!",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 11,
          "number": 4990,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "In gardens of bliss [will they dwell] -",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 12,
          "number": 4991,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "a good many of those of olden times,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 13,
          "number": 4992,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "but [only] a few of later times.",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 14,
          "number": 4993,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "[They will be seated] on gold-encrusted thrones of happiness,",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 15,
          "number": 4994,
          "juz": 27,
          "numpage": 534
        },
        {
          "text": "reclining upon them, facing one another [in love].",
          "englishName": "Al-Waaqia",
          "suraNumber": 56,
          "numberInSurah": 16,
          "number": 4995,
          "juz": 27,
          "numpage": 534
        }
      ]
    },
    {
      "533": [
        {
          "text": "All who were lost in sin shall by their marks be known, and shall by their forelocks and their feet be seized!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 41,
          "number": 4942,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 42,
          "number": 4943,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "This will be the hell which those who are lost in sin [now] call a lie:",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 43,
          "number": 4944,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "between it and [their own] burning-hot despair will they wander to and fro!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 44,
          "number": 4945,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 45,
          "number": 4946,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "BUT FOR THOSE who of their Sustainer’s Presence stand in fear, two gardens [of paradise are readied] -",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 46,
          "number": 4947,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "which, then, of your Sustainer’s powers can you disavow? –",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 47,
          "number": 4948,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "[two gardens] of many wondrous hues.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 48,
          "number": 4949,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 49,
          "number": 4950,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "In [each of] these two [gardens] two springs will flow.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 50,
          "number": 4951,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 51,
          "number": 4952,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "In [each of] these two will two kinds of every fruit be [found].",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 52,
          "number": 4953,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 53,
          "number": 4954,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "[In such a paradise the blest will dwell,] reclin­ing upon carpets lined with rich brocade; and the fruit of both these gardens will be within easy reach.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 54,
          "number": 4955,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 55,
          "number": 4956,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "In these [gardens] will be mates of modest gaze, whom neither man nor invisible being will have touched ere then.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 56,
          "number": 4957,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sus­tainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 57,
          "number": 4958,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "[When you are promised splendours] as though [of] rubies and [of] pearls –",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 58,
          "number": 4959,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 59,
          "number": 4960,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Could the reward of good be aught but good?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 60,
          "number": 4961,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 61,
          "number": 4962,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "And besides those two will be yet two [other] gardens -",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 62,
          "number": 4963,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "which, then, of your Sustainer’s powers can you disavow? –",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 63,
          "number": 4964,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "two [gardens] of the deepest green.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 64,
          "number": 4965,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 65,
          "number": 4966,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "In [each of] these two [gardens] will two springs gush forth.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 66,
          "number": 4967,
          "juz": 27,
          "numpage": 533
        },
        {
          "text": "Which, then, of your Sus­tainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 67,
          "number": 4968,
          "juz": 27,
          "numpage": 533
        }
      ]
    },
    {
      "532": [
        {
          "text": "[He is] the Sustainer of the two farthest points of sunrise, and the Sustainer of the two farthest points of sunset.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 17,
          "number": 4918,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustain­er’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 18,
          "number": 4919,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "He has given freedom to the two great bodies of water, so that they might meet:",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 19,
          "number": 4920,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "[yet] between them is a barrier which they may not transgress.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 20,
          "number": 4921,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 21,
          "number": 4922,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Out of these two [bodies of water] come forth pearls, both great and small.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 22,
          "number": 4923,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 23,
          "number": 4924,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "And His are the lofty ships that sail like [floating] mountains through the seas.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 24,
          "number": 4925,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 25,
          "number": 4926,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "All that lives on earth or in the heavens is bound to pass away:",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 26,
          "number": 4927,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "but forever will abide thy Sustainer’s Self, full of majesty and glory.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 27,
          "number": 4928,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 28,
          "number": 4929,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "On Him depends all creatures in the heavens and on earth; [and] every day He manifests Himself in yet another [wondrous] way.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 29,
          "number": 4930,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 30,
          "number": 4931,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "[ONE DAY] We shall take you to task, O you sin-laden two!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 31,
          "number": 4932,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 32,
          "number": 4933,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "O you who live in close communion with [evil] invisible beings and humans! If you [think that you] can pass beyond the regions of the heavens and the earth, pass beyond them! [But] you cannot pass beyond them, save by a sanction [from God]!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 33,
          "number": 4934,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 34,
          "number": 4935,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "A flash of fire will be let loose upon you, and smoke, and you will be left without succour!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 35,
          "number": 4936,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 36,
          "number": 4937,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "And when the sky is rent asunder and becomes red like [burning] oil",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 37,
          "number": 4938,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 38,
          "number": 4939,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "For on that Day neither man nor invisible being will be asked about his sins.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 39,
          "number": 4940,
          "juz": 27,
          "numpage": 532
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 40,
          "number": 4941,
          "juz": 27,
          "numpage": 532
        }
      ]
    },
    {
      "531": [
        {
          "text": "and Our ordaining [a thing and its coming into being] is but one [act], like the twinkling of an eye.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 50,
          "number": 4896,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "Thus, indeed, did We destroy people like you [in the past]: who, then, is willing to take it to heart?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 51,
          "number": 4897,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "[They were truly guilty] because all [the evil] that they ever did had been [revealed to them as such] in the [ancient] books of [divine] wisdom;",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 52,
          "number": 4898,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "and everything [that man does], be it small or great, is recorded [with God].",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 53,
          "number": 4899,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "[Hence, too,] behold, the God-conscious will find themselves in [a paradise of] gardens and running waters,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 54,
          "number": 4900,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "in a seat of truth, in the presence of a Sovereign who determines all things.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 55,
          "number": 4901,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "THE MOST GRACIOUS",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 1,
          "number": 4902,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "has imparted this Qur’an [unto man].",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 2,
          "number": 4903,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "He has created man:",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 3,
          "number": 4904,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "He has imparted unto him articulate thought and speech.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 4,
          "number": 4905,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "[At His behest] the sun and the moon run their appointed courses;",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 5,
          "number": 4906,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "[before Him] prostrate them­selves the stars and the trees.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 6,
          "number": 4907,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "And the skies has He raised high, and has devised [for all things] a measure,",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 7,
          "number": 4908,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "so that you [too, O men,] might never transgress the measure [of what is right]:",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 8,
          "number": 4909,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "weigh, therefore, [your deeds] with equity, and cut not the measure short!",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 9,
          "number": 4910,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "And the earth has He spread out for all living beings,",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 10,
          "number": 4911,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "with fruit thereon, and palm trees with sheathed clusters [of dates],",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 11,
          "number": 4912,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "and grain growing tall on its stalks, and sweet-smelling plants.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 12,
          "number": 4913,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 13,
          "number": 4914,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "He has created man out of sounding clay, like pottery,",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 14,
          "number": 4915,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "whereas the invisible beings He has created out of a confusing flame of fire.",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 15,
          "number": 4916,
          "juz": 27,
          "numpage": 531
        },
        {
          "text": "Which, then, of your Sustainer’s powers can you disavow?",
          "englishName": "Ar-Rahmaan",
          "suraNumber": 55,
          "numberInSurah": 16,
          "number": 4917,
          "juz": 27,
          "numpage": 531
        }
      ]
    },
    {
      "530": [
        {
          "text": "And let them know that the water [of their wells] is to be divided between them, with each share of water equitably apportioned.”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 28,
          "number": 4874,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "But they summoned their [boldest] companion, and he ventured [upon the evil deed], and cruelly slaughtered [the animal]:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 29,
          "number": 4875,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "and how severe was the suffering which I inflicted when My warnings were disregarded!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 30,
          "number": 4876,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "Behold, We let loose upon them one single blast [of Our punishment], and they became like the dried-up, crumbling twigs of a sheepfold.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 31,
          "number": 4877,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "Hence, indeed, We made this Qur’an easy to bear in mind: who, then, is willing to take it to heart?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 32,
          "number": 4878,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "LOT ’S PEOPLE [too] gave the lie to all [Our] warn­ings:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 33,
          "number": 4879,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "[and so,] behold, We let loose upon them a deadly tempest; and only Lot ’s kinsfolk did We save at the break of dawn,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 34,
          "number": 4880,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "as a blessing from Us: thus do We reward all who are grateful.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 35,
          "number": 4881,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "For he had truly warned them of Our punishing might; but they stubbornly cast doubt on these warn­ings,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 36,
          "number": 4882,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "and even demanded that he give up his guests [to them]: whereupon We deprived them of their sight [and thus told them, as it were]: “Taste, then, the suffering which I inflict when My warnings are disregarded!”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 37,
          "number": 4883,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "And, indeed, abiding suffering did befall them early on the morrow:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 38,
          "number": 4884,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "“Taste, then, the suffering which I inflict when My warnings are disregarded!”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 39,
          "number": 4885,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "Hence, indeed, We made this Qur’an easy to bear in mind: who, then, is willing to take it to heart?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 40,
          "number": 4886,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "Now surely, unto Pharaoh’s folk [too] came such warnings;",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 41,
          "number": 4887,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "they, too, gave the lie to all Our messages: and thereupon We took them to task as only the Almighty, who determines all things, can take to task.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 42,
          "number": 4888,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "ARE, THEN, those of you who [now] deny the truth better than those others - or have you, per­chance, [been promised] immunity in the [ancient] books of [divine] wisdom?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 43,
          "number": 4889,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "Or do they say, “We are a group united, [and therefore] bound to prevail”?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 44,
          "number": 4890,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "[Yet] the hosts [of those who deny the truth] shall be routed, and they shall turn their backs [in flight]!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 45,
          "number": 4891,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "But nay - the Last Hour is the time when they shall truly meet their fate; and that Last Hour will be most calamitous, and most bitter:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 46,
          "number": 4892,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "for, behold, those who are lost in sin [will at that time come to know that it is they who] were sunk in error and folly!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 47,
          "number": 4893,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "On the Day when they shall be dragged into the fire on their faces, [they will be told:] “Taste now the touch of hell-fire!”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 48,
          "number": 4894,
          "juz": 27,
          "numpage": 530
        },
        {
          "text": "BEHOLD, everything have We created in due measure and proportion;",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 49,
          "number": 4895,
          "juz": 27,
          "numpage": 530
        }
      ]
    },
    {
      "529": [
        {
          "text": "they will come forth from their graves, with their eyes downcast, [swarming about] like locusts scattered [by the wind],",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 7,
          "number": 4853,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "running in con­fusion towards the Summoning Voice; [and] those who [now] deny the truth will exclaim, “Calamitous is this Day!”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 8,
          "number": 4854,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "[LONG] BEFORE those [who now deny resurrec­tion] did Noah’s people call it a lie; and they gave the lie to Our servant and said, “Mad is he!” - and he was repulsed.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 9,
          "number": 4855,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "Thereupon he called out to his Sustainer, “Verily, I am defeated; come Thou, then, to my succour!”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 10,
          "number": 4856,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "And so We caused the gates of heaven to open with water pouring down in torrents,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 11,
          "number": 4857,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "and caused the earth to burst forth with springs, so that the waters met for a purpose pre-ordained:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 12,
          "number": 4858,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "but him We bore on that [vessel] made of [mere] planks and nails,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 13,
          "number": 4859,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "and it floated under Our eyes: a recom­pense for him who had been rejected with ingratitude.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 14,
          "number": 4860,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "And, indeed, We have caused such [floating vessels] to remain forever a sign [of Our grace unto man]: who, then, is willing to take it to heart?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 15,
          "number": 4861,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "And how severe is the suffering which I inflict when My warnings are disregarded!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 16,
          "number": 4862,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "Hence, indeed, We made this Qur’an easy to bear in mind: who, then, is willing to take it to heart?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 17,
          "number": 4863,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "TO THE TRUTH gave the lie [the tribe of] Ad: and how severe was the suffering which I inflicted when My warnings were disregarded!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 18,
          "number": 4864,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "Behold, We let loose upon them a raging storm wind on a day of bitter misfortune:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 19,
          "number": 4865,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "it swept the people away as though they were palm-trunks uprooted:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 20,
          "number": 4866,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "for, how severe is the suffering which I inflict when My warnings are disregarded!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 21,
          "number": 4867,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "Hence, indeed, We made this Qur’an easy to bear in mind: who, then, is willing to take it to heart?",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 22,
          "number": 4868,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "[AND the tribe of] Thamud gave the lie to all [Our] warnings;",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 23,
          "number": 4869,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "and they said: “Are we to follow one single mortal, one from among ourselves? In that case, behold, we would certainly sink into error and folly!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 24,
          "number": 4870,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "Why - on him alone from among all of us should a [divine] reminder have been bestowed? Nay, but he is a boastful liar!”",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 25,
          "number": 4871,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "[And God said:] “On the morrow they will come to know who the boastful liar is!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 26,
          "number": 4872,
          "juz": 27,
          "numpage": 529
        },
        {
          "text": "Behold, [O Salih] We are letting loose this she-camel as a test for them; and thou but watch them, and contain thyself in patience.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 27,
          "number": 4873,
          "juz": 27,
          "numpage": 529
        }
      ]
    },
    {
      "528": [
        {
          "text": "and that it is He who creates the two kinds - the male and the female –",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 45,
          "number": 4829,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "out of a [mere] drop of sperm as it is poured forth,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 46,
          "number": 4830,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and that [therefore] it is within His power to bring about a second life;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 47,
          "number": 4831,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and that it is He alone who frees from want and causes to possess;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 48,
          "number": 4832,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and that it is He alone who sustains the bright­est star;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 49,
          "number": 4833,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and that it is He who destroyed the ancient [tribes of] Ad",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 50,
          "number": 4834,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and Thamud, leaving no trace [of them],",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 51,
          "number": 4835,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "as well as the people of Noah before them - [since,] verily, they all had been most willful in their evildoing and most overweening –",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 52,
          "number": 4836,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "just as He thrust into perdition those cities that were overthrown",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 53,
          "number": 4837,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and then covered them from sight forever.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 54,
          "number": 4838,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "About which, then, of thy Sustainer’s powers canst thou [still] remain in doubt?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 55,
          "number": 4839,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "THIS IS a warning like those warnings of old:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 56,
          "number": 4840,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "that [Last Hour] which is so near draws ever nearer,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 57,
          "number": 4841,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "[although] none but God can unveil it....",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 58,
          "number": 4842,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "Do you, perchance, find this tiding strange?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 59,
          "number": 4843,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "And do you laugh instead of weeping,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 60,
          "number": 4844,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "and divert yourselves all the while?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 61,
          "number": 4845,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "[Nay,] but prostrate yourselves before God, and worship [Him alone]!",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 62,
          "number": 4846,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "THE LAST HOUR draws near, and the moon is split asunder!",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 1,
          "number": 4847,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "But if they [who reject all thought of the Last Hour] were to see a sign [of its approach], they would turn aside and say, “An ever-recurring delusion!” –",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 2,
          "number": 4848,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "for they are bent on giving it the lie, being always wont to follow their own desires. Yet everything reveals its truth in the end.",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 3,
          "number": 4849,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "And withal, there has come unto them many a tiding that should have restrained [their arrogance]:",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 4,
          "number": 4850,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "far-reaching wisdom [was held out to them]: but [since] all warnings have been of no avail,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 5,
          "number": 4851,
          "juz": 27,
          "numpage": 528
        },
        {
          "text": "turn thou away from them. On the Day when the Summoning Voice will sum­mon [man] unto something that the mind cannot conceive,",
          "englishName": "Al-Qamar",
          "suraNumber": 54,
          "numberInSurah": 6,
          "number": 4852,
          "juz": 27,
          "numpage": 528
        }
      ]
    },
    {
      "527": [
        {
          "text": "Behold, it is [only] such as do not [really] believe in the life to come that regard the angels as female beings;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 27,
          "number": 4811,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and [since] they have no knowledge whatever thereof, they follow nothing but surmise: yet, behold, never can surmise take the place of truth.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 28,
          "number": 4812,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "Avoid thou, therefore, those who turn away from all remembrance of Us and care for no more than the life of this world,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 29,
          "number": 4813,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "which, to them, is the only thing worth knowing. Behold, thy Sustainer is fully aware as to who has strayed from His path, and fully aware is He as to who follows His guidance.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 30,
          "number": 4814,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "Indeed, unto God belongs all that is in the heavens and all that is on earth: and so He will reward those who do evil in accordance with what they did, and will reward those who do good with ultimate good.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 31,
          "number": 4815,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "As for those who avoid the [truly] grave sins and shameful deeds - even though they may some­times stumble behold, thy Sustainer is abounding in forgiveness. He is fully aware of you when He brings you into being out of dust, and when you are still hidden in your mothers’ wombs: do not, then, consider your­selves pure - [for] He knows best as to who is conscious of Him.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 32,
          "number": 4816,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "HAST THOU, then, ever considered him who turns away [from remembering Us, and cares for no more than the life of this world],",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 33,
          "number": 4817,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and gives so little [of himself for the good of his soul], and so grudgingly?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 34,
          "number": 4818,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "Does he [claim to] have knowledge of some­thing that is beyond the reach of human perception, so that he can see [it clearly]?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 35,
          "number": 4819,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "Or has he never yet been told of what was [said] in the revelations of Moses,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 36,
          "number": 4820,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and of Abra­ham, who to his trust was true:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 37,
          "number": 4821,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "that no bearer of burdens shall be made to bear another’s burden;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 38,
          "number": 4822,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and that nought shall be accounted unto man but what he is striving for;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 39,
          "number": 4823,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and that in time [the nature of] all his striving will be shown [to him in its true light],",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 40,
          "number": 4824,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "where­upon he shall be requited for it with the fullest requital;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 41,
          "number": 4825,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and that with thy Sustainer is the beginning and the end [of all that exists];",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 42,
          "number": 4826,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and that it is He alone who causes [you] to laugh and to weep;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 43,
          "number": 4827,
          "juz": 27,
          "numpage": 527
        },
        {
          "text": "and that it is He alone who deals death and grants life;",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 44,
          "number": 4828,
          "juz": 27,
          "numpage": 527
        }
      ]
    },
    {
      "526": [
        {
          "text": "CONSIDER this unfolding [of God’s message], as it comes down from on high!",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 1,
          "number": 4785,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "This fellow-man of yours has not gone astray, nor is he deluded,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 2,
          "number": 4786,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "and neither does he speak out of his own desire:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 3,
          "number": 4787,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "that [which he conveys to you] is but [a divine] inspiration with which he is being inspired –",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 4,
          "number": 4788,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "something that a very mighty one has imparted to him:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 5,
          "number": 4789,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "[an angel] endowed with sur­passing power, who in time manifested himself in his true shape and nature,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 6,
          "number": 4790,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "appearing in the horizon’s loftiest part,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 7,
          "number": 4791,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "and then drew near, and came close,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 8,
          "number": 4792,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "until he was but two bow-lengths away, or even nearer.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 9,
          "number": 4793,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "And thus did [God] reveal unto His servant whatever He deemed right to reveal.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 10,
          "number": 4794,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "The [servant’s] heart did not give the lie to what he saw:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 11,
          "number": 4795,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "will you, then, contend with him as to what he saw?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 12,
          "number": 4796,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "And, indeed, he saw him a second time",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 13,
          "number": 4797,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "by the lote-tree of the farthest limit,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 14,
          "number": 4798,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "near unto the garden of promise.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 15,
          "number": 4799,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "with the lote-tree veiled in a veil of nameless splendour….",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 16,
          "number": 4800,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "[And withal,] the eye did not waver, nor yet did it stray:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 17,
          "number": 4801,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "truly did he see some of the most profound of his Sustainer’s symbols.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 18,
          "number": 4802,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "HAVE YOU, then, ever considered [what you are worshipping in] Al-Lat and Al-Uzza,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 19,
          "number": 4803,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "as well as [in] Manat, the third and last [of this triad]?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 20,
          "number": 4804,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "Why - for yourselves [you would choose only] male offspring, whereas to Him [you assign] female:",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 21,
          "number": 4805,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "that, lo and behold, is an unfair division!",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 22,
          "number": 4806,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "These [allegedly divine beings] are nothing but empty names which you have invented - you and your forefathers - [and] for which God has bestowed no warrant from on high. They [who worship them] follow nothing but surmise and their own wishful thinking - although right guidance has now indeed come unto them from their Sustainer.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 23,
          "number": 4807,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "Does man imagine that it is his due to have all that he might wish for,",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 24,
          "number": 4808,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "despite the fact that [both] the life to come and this present [one] belong to God [alone]?",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 25,
          "number": 4809,
          "juz": 27,
          "numpage": 526
        },
        {
          "text": "For, however many angels there be in the heavens, their intercession can be of no least avail [to anyone] - except after God has given leave [to inter­cede] for whomever He wills and with whom He is well-pleased.",
          "englishName": "An-Najm",
          "suraNumber": 53,
          "numberInSurah": 26,
          "number": 4810,
          "juz": 27,
          "numpage": 526
        }
      ]
    },
    {
      "525": [
        {
          "text": "Is it their minds that bid them [to take] this [attitude] - or are they [simply] people filled with overweening arrogance?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 32,
          "number": 4767,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Or do they say, “He himself has composed this [message]”? Nay, but they are not willing to believe!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 33,
          "number": 4768,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "But then, [if they deem it the work of a mere mortal,] let them produce another discourse like it - if what they say be true!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 34,
          "number": 4769,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "[Or do they deny the existence of God?] Have they themselves been created without anything [that might have caused their creation]? or were they, perchance, their own creators?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 35,
          "number": 4770,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "[And] have they created the heavens and the earth? Nay, but they have no certainty of anything!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 36,
          "number": 4771,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "[How could they?] Are thy Sustainer’s treasures with them? Or are they in charge [of destiny]?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 37,
          "number": 4772,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Or have they a ladder by which they could [ascend to ultimate truths and] listen [to what is beyond the reach of human perception]? Let, then, any of them who have listened [to it] produce a manifest proof [of his knowledge]!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 38,
          "number": 4773,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Or, [if you believe in God, how can you believe that] He has [chosen to have] daughters, whereas you yourselves would have [only] sons?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 39,
          "number": 4774,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Or is it that [they who reject thy message, O Muhammad, fear lest] thou ask of them a reward, so that they would be burdened with debt [if they should listen to thee]?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 40,
          "number": 4775,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Or [do they think] that the hidden reality [of all that exists] is almost within their grasp, so that [in time] they can write it down?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 41,
          "number": 4776,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Or do they want to entrap [thee in contradic­tions]? But they who are bent on denying the truth - it is they who are truly entrapped!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 42,
          "number": 4777,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Have they, then, any deity other than God? Utterly remote is God, in His limitless glory, from anything to which men may ascribe a share in His divinity!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 43,
          "number": 4778,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "AND YET, if they [who refuse to see the truth] were to see part of the sky falling down, they would [only] say, “[It is but] a mass of clouds!”",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 44,
          "number": 4779,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "Hence, leave them alone until they face that [Judgment] Day of theirs, when they will be stricken with terror:",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 45,
          "number": 4780,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "the Day when none of their scheming will be of the least avail to them, and they will receive no succour.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 46,
          "number": 4781,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "But, verily, for those who are bent on doing evil, there is suffering in store [even] closer at hand than that [supreme suffering in the hereafter]: but most of them are not aware of it.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 47,
          "number": 4782,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "And so, await in patience thy Sustainer’s judgment, for thou art well within Our sight. And extol thy Sustainer’s limitless glory and praise whenever thou risest up,",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 48,
          "number": 4783,
          "juz": 27,
          "numpage": 525
        },
        {
          "text": "and extol His glory at night, and at the time when the stars retreat.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 49,
          "number": 4784,
          "juz": 27,
          "numpage": 525
        }
      ]
    },
    {
      "524": [
        {
          "text": "Was it, then, a delusion or is it that you failed to see [its truth]?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 15,
          "number": 4750,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "Endure it [now]! But [whether you] bear yourselves with patience or without patience, it will be the same to you: you are but being requited for what you were wont to do.”",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 16,
          "number": 4751,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "[But,] verily, the God-conscious will find themselves [on that Day] in gardens and in bliss,",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 17,
          "number": 4752,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "rejoicing in all that their Sustainer will have granted them: for their Sustainer will have warded off from them all suffering through the blazing fire.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 18,
          "number": 4753,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "[And they will be told:] “Eat and drink with good cheer as an outcome of what you were wont to do,",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 19,
          "number": 4754,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "reclining on couches [of happiness] ranged in rows!” And [in that paradise] We shall mate them with companions pure, most beautiful of eye.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 20,
          "number": 4755,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "And as for those who have attained to faith and whose offspring will have followed them in faith, We shall unite them with their offspring; and We shall not let aught of their deeds go to waste: [but] every human being will be held in pledge for whatever he has earned.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 21,
          "number": 4756,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "And We shall bestow on them fruit and meat in abundance - whatever they may desire:",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 22,
          "number": 4757,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "and in that [paradise] they shall pass on to one another a cup which will not give rise to empty talk, and neither incite to sin.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 23,
          "number": 4758,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "And they will be waited upon by [immortal] youths, [as if they were children] of their own, [as pure] as if they were pearls hidden in their shells.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 24,
          "number": 4759,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "And they [who are thus blest] will turn to one another, asking each other [about their past lives].",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 25,
          "number": 4760,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "They will say: “Behold, aforetime - when we were [still living] in the midst of our kith and kin - we were full of fear [at the thought of God’s dis­pleasure]:",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 26,
          "number": 4761,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "and so God has graced us with His favour, and has warded off from us all suffering through the scorching winds [of frustration].",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 27,
          "number": 4762,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "Verily, we did invoke Him [alone] ere this: [and now He has shown us] that He alone is truly benign, a true dispenser of grace!”",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 28,
          "number": 4763,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "EXHORT, then, [O Prophet, all men:] for, by thy Sustainer’s grace, thou art neither a soothsayer nor a madman.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 29,
          "number": 4764,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "Or do they say, “[He is but] a poet - let us wait what time will do unto him”?",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 30,
          "number": 4765,
          "juz": 27,
          "numpage": 524
        },
        {
          "text": "Say thou: “Wait, [then,] hopefully; behold, I, too, shall hopefully wait with you!”",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 31,
          "number": 4766,
          "juz": 27,
          "numpage": 524
        }
      ]
    },
    {
      "523": [
        {
          "text": "[But] thus it is: never yet came any apostle to those who lived before their time but they said, “A spellbinder [Lit., \"sorcerer\".] [is he], or a madman!”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 52,
          "number": 4727,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Have they, perchance, handed down this [way of thinking] as a legacy unto one another? Nay, they are people filled with overweening ar­rogance!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 53,
          "number": 4728,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Turn, then, away from them, and thou shalt incur no blame;",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 54,
          "number": 4729,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "yet go on reminding [all who would listen]: for, verily, such a reminder will profit the believers.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 55,
          "number": 4730,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "And [tell them that] I have not created the invisible beings and men to any end other than that they may [know and] worship Me.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 56,
          "number": 4731,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "[But withal,] no sustenance do I ever demand of them, nor do I demand that they feed Me:",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 57,
          "number": 4732,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "for, verily, God Himself is the Provider of all sustenance, the Lord of all might, the Eternal!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 58,
          "number": 4733,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "And, verily, they who are bent on doing evil shall have their share [of evil] like unto the share of their fellows [of old]: so let them not ask Me to hasten [their doom]!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 59,
          "number": 4734,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "For, woe unto those who are bent on denying the truth - [woe] on the Day which they have been promised",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 60,
          "number": 4735,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "CONSIDER Mount Sinai!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 1,
          "number": 4736,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Consider [God’s] revelation, inscribed",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 2,
          "number": 4737,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "on wide-open scrolls.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 3,
          "number": 4738,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Consider the long-enduring house [of worship]!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 4,
          "number": 4739,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Consider the vault [of heaven] raised high!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 5,
          "number": 4740,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Consider the surf-swollen sea!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 6,
          "number": 4741,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "VERILY, [O man,] the suffering decreed by thy Sustainer [for the sinners] will indeed come to pass:",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 7,
          "number": 4742,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "there is none who could avert it.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 8,
          "number": 4743,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "[It will come to pass] on the Day when the skies will be convulsed in [a great] convulsion,",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 9,
          "number": 4744,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "and the mountains will move with [an awesome] movement.",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 10,
          "number": 4745,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "Woe, then, on that Day to all who give the lie to the truth –",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 11,
          "number": 4746,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "all those who [throughout their lives] but idly played with things vain –",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 12,
          "number": 4747,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "on the Day when they shall be thrust into the fire with [an irresistible] thrust, [and will be told:]",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 13,
          "number": 4748,
          "juz": 27,
          "numpage": 523
        },
        {
          "text": "“This is the fire which you were wont to call a lie!",
          "englishName": "At-Tur",
          "suraNumber": 52,
          "numberInSurah": 14,
          "number": 4749,
          "juz": 27,
          "numpage": 523
        }
      ]
    },
    {
      "522": [
        {
          "text": "Said [Abraham]: “And what [else] may you have in view, O you [heavenly] messengers?”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 31,
          "number": 4706,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "They answered: “Behold, we have been sent unto a people lost in sin,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 32,
          "number": 4707,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "to let loose upon them stone-hard blows of chastisement,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 33,
          "number": 4708,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "marked out in thy Sustainer’s sight for [the punishment of] such as have wasted their own selves.”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 34,
          "number": 4709,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And in the course of time We brought out [of Lot ’s city] such [few] believers as were there:",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 35,
          "number": 4710,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "for apart from one [single] house We did not find there any who had surrendered themselves to Us.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 36,
          "number": 4711,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And so We left therein a message for those who fear the grievous suffering [which awaits all evildoers].",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 37,
          "number": 4712,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "AND IN [the story of Pharaoh and] Moses, too, [We left the same message: for] when We sent him unto Pharaoh with [Our] manifest authority,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 38,
          "number": 4713,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "and he turned away in [the pride of] his power and said, “A sorcerer [is this Moses], or a madman!” –",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 39,
          "number": 4714,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "We seized him and his hosts, and cast them all into the sea: and [none but Pharaoh] himself was to blame [for what happened].",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 40,
          "number": 4715,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And [you have the same message] in [what happened to the tribe of] Ad, when We let loose against them that life-destroying wind",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 41,
          "number": 4716,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "which spared nothing of what it came upon, but caused [all of] it to become like bones dead and decayed.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 42,
          "number": 4717,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And in [the story of the tribe of] Thamud, too, when they were told, “You shall enjoy your life for [but] a little while,”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 43,
          "number": 4718,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "after they had turned with disdain from their Sustainer’s commandment - whereupon the thunderbolt of punishment overtook them while they were [helplessly] looking on:",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 44,
          "number": 4719,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "for they were unable even to rise, and could not defend themselves.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 45,
          "number": 4720,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And [thus, too, We destroyed] Noah’s people aforetime: for they were iniquitous folk.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 46,
          "number": 4721,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "AND IT IS We who have built the universe with [Our creative] power; and, verily, it is We who are steadily expanding it.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 47,
          "number": 4722,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And the earth have We spread out wide - and how well have We ordered it!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 48,
          "number": 4723,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And in everything have We created oppos­ites, so that you might bear in mind [that God alone is One].",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 49,
          "number": 4724,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And so, [O Muhammad, say unto them:] “Flee unto God [from all that is false and evil]! Verily, I am a plain warner to you from Him!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 50,
          "number": 4725,
          "juz": 27,
          "numpage": 522
        },
        {
          "text": "And do not ascribe divinity to aught side by side with God: verily, I am a plain warner to you from Him!”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 51,
          "number": 4726,
          "juz": 27,
          "numpage": 522
        }
      ]
    },
    {
      "521": [
        {
          "text": "CONSIDER the firmament full of starry paths!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 7,
          "number": 4682,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "Verily, [O men,] you are deeply at variance as to what to believe:",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 8,
          "number": 4683,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "perverted in his views thereon is he who would deceive himself!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 9,
          "number": 4684,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "They but destroy themselves, they who are given to guessing at what they cannot ascertain –",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 10,
          "number": 4685,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "they who blunder along, in ignorance lost –",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 11,
          "number": 4686,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "they who [mockingly] ask, “When is that Day of Judgment to be?”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 12,
          "number": 4687,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "[It will be] a Day when they will be sorely tried by the fire,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 13,
          "number": 4688,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "[and will be told:] “Taste this your trial! It is this that you were so hastily asking for!”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 14,
          "number": 4689,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "[But,] behold, the God-conscious will find themselves amid gardens and springs,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 15,
          "number": 4690,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "enjoying all that their Sustainer will have granted them [because], verily, they were doers of good in the past:",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 16,
          "number": 4691,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "they would lie asleep during but a small part of the night,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 17,
          "number": 4692,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "and would pray for forgiveness from their innermost hearts;",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 18,
          "number": 4693,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "and [would assign] in all that they possessed a due share unto such as might ask [for help] and such as might suffer priva­tion.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 19,
          "number": 4694,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "AND ON EARTH there are signs [of God’s exis­tence, visible] to all who are endowed with inner certainty,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 20,
          "number": 4695,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "just as [there are signs thereof] within your own selves: can you not, then, see?",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 21,
          "number": 4696,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "And in heaven is [the source of] your sus­tenance [on earth] and [of] all that you are promised [for your life after death]:",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 22,
          "number": 4697,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "for, by the Sustainer of heaven and earth, this [life after death] is the very truth - as true as that you are endowed with speech!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 23,
          "number": 4698,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "AND HAS the story of Abraham’s honoured guests ever come within thy ken?",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 24,
          "number": 4699,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "When those [heavenly messengers] came unto him and bade him peace, he answered, “[And upon you be] peace!” - [saying to himself,] “They are strangers.”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 25,
          "number": 4700,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "Then he turned quietly to his household, and brought forth a fat [roasted] calf,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 26,
          "number": 4701,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "and placed it before them, saying, “Will you not eat?”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 27,
          "number": 4702,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "[And when he saw that the guests would not eat,] he became apprehensive of them; [but] they said, “Fear not” - and gave him the glad tiding of [the birth of] a son who would be endowed with deep knowledge.",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 28,
          "number": 4703,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "Thereupon his wife approached [the guests] with a loud cry, and struck her face [in astonishment] and exclaimed: “A barren old woman [like me]!”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 29,
          "number": 4704,
          "juz": 26,
          "numpage": 521
        },
        {
          "text": "They answered: “Thus has thy Sustainer decreed; and, verily, He alone is truly wise, all- knowing!”",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 30,
          "number": 4705,
          "juz": 26,
          "numpage": 521
        }
      ]
    },
    {
      "520": [
        {
          "text": "AND HOW MANY a generation have We destroyed before those [who now deny the truth] people of greater might than theirs; but [when Our chastise­ment befell them,] they became wanderers on the face of the earth, seeking no more than a place of refuge",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 36,
          "number": 4666,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "In this, behold, there is indeed a reminder for everyone whose heart is wide-awake -that is, [every­one who] lends ear with a conscious mind -",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 37,
          "number": 4667,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "and [who knows that] We have indeed created the heavens and the earth and all that is between them in six aeons, and [that] no weariness could ever touch Us.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 38,
          "number": 4668,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "HENCE, [O believer,] bear thou with patience what­ever they may say, and extol thy Sustainer’s limit­less glory and praise before the rising of the sun and before its setting;",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 39,
          "number": 4669,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "and in the night, too, extol His glory, and at every prayer’s end.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 40,
          "number": 4670,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "And [always] listen for the day when He who issues the call [of death] shall call [thee] from close-by;",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 41,
          "number": 4671,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "[and bethink thyself, too, of] the Day on which all [human beings] will in truth hear the final blast - that Day of [their] coming-forth [from death].",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 42,
          "number": 4672,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "Verily, it is We who grant life and deal death; and with Us will be all journeys’ end",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 43,
          "number": 4673,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "on the Day when the earth is riven asunder all around them as they hasten forth [towards God’s judgment]: that gathering will be easy for Us [to encompass].",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 44,
          "number": 4674,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "Fully aware are We of what they [who deny resurrection] do say; and thou canst by no means force them [to believe in it]. Yet none the less, remind, through this Qur’an, all such as may fear My warning.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 45,
          "number": 4675,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "CONSIDER the winds that scatter the dust far and wide,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 1,
          "number": 4676,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "and those that carry the burden [of heavy clouds],",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 2,
          "number": 4677,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "and those that speed along with gentle ease,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 3,
          "number": 4678,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "and those that apportion [the gift of life] at [God’s] behest!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 4,
          "number": 4679,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "Verily, that which you are promised is true indeed,",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 5,
          "number": 4680,
          "juz": 26,
          "numpage": 520
        },
        {
          "text": "and, verily, judgment is bound to come!",
          "englishName": "Adh-Dhaariyat",
          "suraNumber": 51,
          "numberInSurah": 6,
          "number": 4681,
          "juz": 26,
          "numpage": 520
        }
      ]
    },
    {
      "519": [
        {
          "text": "NOW, VERILY, it is We who have created man, and We know what his innermost self whispers within him: for We are closer to him than his neck-vein.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 16,
          "number": 4646,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "[And so,] whenever the two demands [of his nature] come face to face, contending from the right and from the left,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 17,
          "number": 4647,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "not even a word can he utter but there is a watcher with him, ever-present.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 18,
          "number": 4648,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "And [then,] the twilight of death brings with it the [full] truth that [very thing, O man,] from which thou wouldst always look away! –",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 19,
          "number": 4649,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "and [in the end] the trumpet [of resurrection] will be blown: that will be the Day of a warning fulfilled.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 20,
          "number": 4650,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "And every human being will come forward with [his erstwhile] inner urges and [his] conscious mind,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 21,
          "number": 4651,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "[and will be told:] “Indeed, unmindful hast thou been of this [Day of Judgment]; but now We have lifted from thee thy veil, and sharp is thy sight today!”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 22,
          "number": 4652,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "And one part of him will say: “This it is that has been ever-present with me!”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 23,
          "number": 4653,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "[Whereupon God will command:] “Cast, cast into hell every [such] stubborn enemy of the truth,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 24,
          "number": 4654,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "[every] withholder of good [and] sinful aggressor [and] fomentor of distrust [between man and man – everyone]",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 25,
          "number": 4655,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "who has set up another deity beside God: cast him, then, cast him into suffering severe!”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 26,
          "number": 4656,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "Man’s other self’ will say: “O our Sustainer! It was not I that led his conscious mind into evil [nay,] but it had gone far astray [of its own accord]!",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 27,
          "number": 4657,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "[And] He will say: “Contend not before Me, [O you sinners,] for I gave you a forewarning [of this Day of Reckoning].",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 28,
          "number": 4658,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "The judgment passed by Me shall not be altered; but never do I do the least wrong unto My creatures!”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 29,
          "number": 4659,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "On that Day We will ask hell, “Art thou filled?”- and it will answer, “[Nay,] is there yet more [for me]?”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 30,
          "number": 4660,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "And [on that Day] paradise will be brought within the sight of the God-conscious, and will no longer be far away; [and they will be told:]",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 31,
          "number": 4661,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "“This is what you were promised - [promised] unto every­one who was wont to turn unto God and to keep Him always in mind –",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 32,
          "number": 4662,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "[everyone] who stood in awe of the Most Gracious although He is beyond the reach of human perception, and who has come [unto Him] with a heart full of contrition.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 33,
          "number": 4663,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "Enter this [paradise] in peace; this is the Day on which life abiding begins!”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 34,
          "number": 4664,
          "juz": 26,
          "numpage": 519
        },
        {
          "text": "In that [paradise] they shall have whatever they may desire - but there is yet more with Us.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 35,
          "number": 4665,
          "juz": 26,
          "numpage": 519
        }
      ]
    },
    {
      "518": [
        {
          "text": "Qaf. CONSIDER this sublime Qur’an!",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 1,
          "number": 4631,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "But nay - they deem it strange that a warner should have come unto them from their own midst; and so these deniers of the truth are saying, “A strange thing is this!",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 2,
          "number": 4632,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "Why - [how could we be resurrected] after we have died and become mere dust? Such a return seems far-fetched indeed!”",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 3,
          "number": 4633,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "Well do We know how the earth consumes their bodies, for with Us is a record unfailing.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 4,
          "number": 4634,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "Nay, but they [who refuse to believe in resurrection] have been wont to give the lie to this truth whenever it was proffered to them; and so they are in a state of confusion.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 5,
          "number": 4635,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "Do they not look at the sky above them - how We have built it and made it beautiful and free of all faults?",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 6,
          "number": 4636,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "And the earth - We have spread it wide, and set upon it mountains firm, and caused it to bring forth plants of all beauteous kinds,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 7,
          "number": 4637,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "thus offering an insight and a reminder unto every human being who willingly turns unto God.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 8,
          "number": 4638,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "And We send down from the skies water rich in blessings, and cause thereby gardens to grow, and fields of grain,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 9,
          "number": 4639,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "and tall palm-trees with their thickly-clustered dates,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 10,
          "number": 4640,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "as sustenance appor­tioned to men; and by [all] this We bring dead land to life: [and] even so will be [man’s] coming-forth from death.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 11,
          "number": 4641,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "[Long] before those [who now deny resur­rection] did Noah’s people give the lie to this truth, and [so did] the folk of Ar-Rass, and [the tribes of] Thamud",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 12,
          "number": 4642,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "and Ad, and Pharaoh, and Lot’s brethren,",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 13,
          "number": 4643,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "and the dwellers of the wooded dales [of Madyan], and the people of Tubba: they all gave the lie to the apostles - and thereupon that whereof I had warned [them] came true.",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 14,
          "number": 4644,
          "juz": 26,
          "numpage": 518
        },
        {
          "text": "Could We, then, be [thought of as being] worn out by the first creation? Nay - but some people are [still] lost in doubt about [the possibility of] a new creation!",
          "englishName": "Qaaf",
          "suraNumber": 50,
          "numberInSurah": 15,
          "number": 4645,
          "juz": 26,
          "numpage": 518
        }
      ]
    },
    {
      "517": [
        {
          "text": "O you who have attained to faith! Avoid most guesswork [about one another] for, behold, some of [such] guesswork is [in itself] a sin; and do not spy upon one another, and neither allow your­selves to speak ill of one another behind your backs. Would any of you like to eat the flesh of his dead brother? Nay, you would loathe it! And be conscious of God. Verily, God is an accep­tor of repentance, a dispenser of grace!",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 12,
          "number": 4624,
          "juz": 26,
          "numpage": 517
        },
        {
          "text": "O men! Behold, We have created you all out of a male and a female, and have made you into nations and tribes, so that you might come to know one another. Verily, the noblest of you in the sight of God is the one who is most deeply conscious of Him. Behold, God is all-knowing, all-aware.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 13,
          "number": 4625,
          "juz": 26,
          "numpage": 517
        },
        {
          "text": "THE BEDOUIN say, “We have attained to faith.” Say [unto them, O Muhammad]: “You have not [yet] attained to faith; you should [rather] say, ‘We have [outwardly] surrendered’ - for [true] faith has not yet entered your hearts. But if you [truly] pay heed unto God and His Apostle, He will not let the least of your deeds go to waste: for, behold, God is much-forgiving, a dispenser of grace.”",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 14,
          "number": 4626,
          "juz": 26,
          "numpage": 517
        },
        {
          "text": "[Know that true] believers are only those who have attained to faith in God and His Apostle and have left all doubt behind, and who strive hard in God’s cause with their possessions and their lives: it is they, they who are true to their word!",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 15,
          "number": 4627,
          "juz": 26,
          "numpage": 517
        },
        {
          "text": "Say: “Do you, perchance, [want to] inform God of [the nature of] your faith although God knows all that is in the heavens and all that is on earth? Indeed, God has full knowledge of every­thing!”",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 16,
          "number": 4628,
          "juz": 26,
          "numpage": 517
        },
        {
          "text": "Many people think that they have bestowed a favour upon thee [O Prophet] by having surrendered [to thee]. Say thou: “Deem not your surrender a favour unto me: nay, but it is God who bestows a favour upon you by showing you the way to faith - if you are true to your word!”",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 17,
          "number": 4629,
          "juz": 26,
          "numpage": 517
        },
        {
          "text": "Verily, God knows the hidden reality of the heavens and the earth; and God sees all that you do.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 18,
          "number": 4630,
          "juz": 26,
          "numpage": 517
        }
      ]
    },
    {
      "516": [
        {
          "text": "for, if they had the patience [to wait] until thou come forth to them [of thine own accord], it would be for their own good. Still, God is much forgiving, a dispenser of grace.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 5,
          "number": 4617,
          "juz": 26,
          "numpage": 516
        },
        {
          "text": "O YOU who have attained to faith! If any iniquitous person comes to you with a [slanderous] tale, use your discernment, lest you hurt people unwittingly and afterwards be filled with remorse for what you have done.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 6,
          "number": 4618,
          "juz": 26,
          "numpage": 516
        },
        {
          "text": "And know that God’s Apostle is among you: were he to comply with your inclinations in each and every case, you would be bound to come to harm [as a community]. But as it is, God has caused [your] faith to be dear to you, and has given it beauty in your hearts, and has made hateful to you all denial of the truth, and all iniquity, and all rebellion [against what is good]. Such indeed are they who follow the right course",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 7,
          "number": 4619,
          "juz": 26,
          "numpage": 516
        },
        {
          "text": "through God’s bounty and favour; and God is all-knowing, truly wise.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 8,
          "number": 4620,
          "juz": 26,
          "numpage": 516
        },
        {
          "text": "Hence, if two groups of believers fall to fighting, make peace between them; but then, if one of the two [groups] goes on acting wrongfully towards the other, fight against the one that acts wrongfully until it reverts to God’s commandment; and if they revert, make peace between them with justice, and deal equitably [with them]: for verily, God loves those who act equitably!",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 9,
          "number": 4621,
          "juz": 26,
          "numpage": 516
        },
        {
          "text": "All believers are but brethren. Hence, [when­ever they are at odds,] make peace between your two brethren, and remain conscious of God, so that you might be graced with His mercy.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 10,
          "number": 4622,
          "juz": 26,
          "numpage": 516
        },
        {
          "text": "O YOU who have attained to faith! No men shall deride [other] men: it may well be that those [whom they deride] are better than themselves; and no women [shall deride other] women: it may well be that those [whom they deride] are better than them­selves. And neither shall you defame one another, nor insult one another by [opprobrious] epithets: evil is all imputation of iniquity after [one has attained to] faith; and they who [become guilty thereof and] do not repent - it is they, they who are evildoers!",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 11,
          "number": 4623,
          "juz": 26,
          "numpage": 516
        }
      ]
    },
    {
      "515": [
        {
          "text": "MUHAMMAD is God’s Apostle; and those who are [truly] with him are firm and unyielding towards all deniers of the truth, [yet] full of mercy towards one another. Thou canst see them bowing down, pros­trating themselves [in prayer], seeking favour with God and [His] goodly acceptance: their marks are on their faces, traced by prostration. This is their parable in the Torah as well as their parable in the Gospel: [they are] like a seed that brings forth its shoot, and then He strengthens it, so that it grows stout, and [in the end] stands firm upon its stem, delighting the sowers. [Thus will God cause the believers to grow in strength,] so that through them He might confound the deniers of the truth. [But] unto such of them as may [yet] attain to faith and do righteous deeds, God has promised forgiveness and a reward supreme.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 29,
          "number": 4612,
          "juz": 26,
          "numpage": 515
        },
        {
          "text": "O YOU who have attained to faith! Do not put yourselves forward in the presence of [what] God and His Apostle [may have ordained], but remain conscious of God: for, verily, God is all-hearing, all-knowing!",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 1,
          "number": 4613,
          "juz": 26,
          "numpage": 515
        },
        {
          "text": "O you who have attained to faith! Do not raise your voices above the voice of the Prophet, and neither speak loudly to him, as you would speak loudly to one another, lest all your [good] deeds come to nought without your perceiving it.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 2,
          "number": 4614,
          "juz": 26,
          "numpage": 515
        },
        {
          "text": "Behold, they who lower their voices in the presence of God’s Apostle - it is they whose hearts God has tested [and opened] to consciousness of Himself; [and] theirs shall be forgiveness and a reward supreme.",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 3,
          "number": 4615,
          "juz": 26,
          "numpage": 515
        },
        {
          "text": "Verily, [O Prophet,] as for those who call thee from without thy private apartments - most of them do not use their reason:",
          "englishName": "Al-Hujuraat",
          "suraNumber": 49,
          "numberInSurah": 4,
          "number": 4616,
          "juz": 26,
          "numpage": 515
        }
      ]
    },
    {
      "514": [
        {
          "text": "And He it is who, in the valley of Mecca, stayed their hands from you, and your hands from them, after He had enabled you to vanquish them; and God saw indeed what you were doing.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 24,
          "number": 4607,
          "juz": 26,
          "numpage": 514
        },
        {
          "text": "[It was not for your enemies sake that He stayed your hands from them: for] it was they who were bent on denying the truth, and who debarred you from the Inviolable House of Worship and prevented your offering from reaching its destina­tion. And had it not been for the believing men and believing women [in Mecca], whom you might have unwittingly trampled underfoot, and on whose account you might have become guilty, without knowing it, of a grievous wrong-: [had it not been for this, you would have been allowed to fight your way into the city: but you were forbidden to fight] so that [in time] God might admit to His grace whomever He wills. Had they [who deserve Our mercy and they whom We have condemned] been clearly discernible [to you], We would indeed have imposed grievous suffering [at your hands] on such of them as were bent on denying the truth.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 25,
          "number": 4608,
          "juz": 26,
          "numpage": 514
        },
        {
          "text": "Whereas they who are bent on denying the truth harboured a stubborn disdain in their hearts - the stubborn disdain [born] of ignorance God bestowed from on high His [gift of] inner peace upon His Apostle and the believers, and bound them to the spirit of God-consciousness: for they were most worthy of this [divine gift], and deserved it well. And God has full knowledge of all things.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 26,
          "number": 4609,
          "juz": 26,
          "numpage": 514
        },
        {
          "text": "Indeed, God has shown the truth in His Apostle’s true vision: most certainly shall you enter the Inviolable House of Worship, if God so wills, in full security, with your heads shaved or your hair cut short, without any fear: for He has [always] known that which you yourselves could not know. And He has ordained [for you], besides this, a victory soon to come.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 27,
          "number": 4610,
          "juz": 26,
          "numpage": 514
        },
        {
          "text": "He it is who has sent forth His Apostle with the [task of spreading] guidance and the religion of truth, to the end that He make it prevail over every [false] religion; and none can bear witness [to the truth] as God does.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 28,
          "number": 4611,
          "juz": 26,
          "numpage": 514
        }
      ]
    },
    {
      "513": [
        {
          "text": "Say unto those bedouin who stayed behind: “In time you will be called upon [to fight] against people of great prowess in war: you will have to fight against them [until you die] or they surrender. And then, if you heed [that call], God will bestow on you a goodly reward; but if you turn away as you turned away this time, He will chastise you with grievous chastisement.”",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 16,
          "number": 4599,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "No blame attaches to the blind, nor does blame attach to the lame, nor does blame attach to the sick [for staying away from a war in God’s cause]; but whoever heeds [the call of] God and His Apostle [in deed or in heart], him will He admit into gardens through which running waters flow; whereas him who turns away will He chastise with grievous chastise­ment.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 17,
          "number": 4600,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "INDEED, well-pleased was God with the believers when they pledged their allegiance unto thee [O Muhammad] under that tree, for He knew what was in their hearts; and so He bestowed inner peace upon them from on high, and rewarded them with [the glad tiding of] a victory soon to come",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 18,
          "number": 4601,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "and [of] many war-gains which they would achieve: for God is indeed almighty, wise.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 19,
          "number": 4602,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "[O you who believe!] God has promised you many war-gains which you shall yet achieve; and He has vouchsafed you these [worldly gains] well in advance, and has stayed from you the hands of [hostile] people, so that this [your inner strength] may become a symbol to the believers [who will come after you], and that He may guide you all on a straight way.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 20,
          "number": 4603,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "And there are yet other [gains] which are still beyond your grasp, [but] which God has already encompassed [for you]: for God has the power to will anything.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 21,
          "number": 4604,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "And [now,] if they who are bent on denying the truth should fight against you, they will indeed turn their backs [in flight], and will find none to protect them and none to bring them succour:",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 22,
          "number": 4605,
          "juz": 26,
          "numpage": 513
        },
        {
          "text": "such being God’s way which has ever obtained in the past - and never wilt thou find any change in God’s way!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 23,
          "number": 4606,
          "juz": 26,
          "numpage": 513
        }
      ]
    },
    {
      "512": [
        {
          "text": "Behold, all who pledge their allegiance to thee pledge their allegiance to God: the hand of God is over their hands. Hence, he who breaks his oath, breaks it only to his own hurt; whereas he who remains true to what he has pledged unto God, on him will He bestow a reward supreme.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 10,
          "number": 4593,
          "juz": 26,
          "numpage": 512
        },
        {
          "text": "Those of the bedouin who stayed behind will say unto thee: “[The need to take care of] our chat­tels and our families kept us busy: do then, [O Prophet,] ask God to forgive us!” [Thus,] they will utter with their tongues something that is not in their hearts. Say: “Who, then, has it in his power to avert from you aught that God may have willed, whether it be His will to harm you or to confer a benefit on you? Nay, but God is fully aware of what you do!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 11,
          "number": 4594,
          "juz": 26,
          "numpage": 512
        },
        {
          "text": "Nay, you thought that the Apostle and the believers would never return to their kith and kin: and this seemed goodly to your hearts. And you entertained [such] evil thoughts because you have always been people devoid of all good!”",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 12,
          "number": 4595,
          "juz": 26,
          "numpage": 512
        },
        {
          "text": "Now as for those who will not believe in God and His Apostle - verily, We have readied a blazing flame for all [such] deniers of the truth!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 13,
          "number": 4596,
          "juz": 26,
          "numpage": 512
        },
        {
          "text": "But God’s is the dominion over the heavens and the earth: He forgives whomever He wills, and imposes suffering on whomever He wills - and [withal,] He is indeed much-forgiving, a dispenser of grace.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 14,
          "number": 4597,
          "juz": 26,
          "numpage": 512
        },
        {
          "text": "As soon as you [O believers] are about to set forth on a war that promises booty, those who stayed behind [aforetime] will surely say, “Allow us to go with you” - [thus showing that] they would like to alter the Word of God. Say: “By no means shall you go with us: God has declared aforetime [to whom all spoils shall belong].” Thereupon they will [surely] answer, “Nay, but you begrudge us [our share of booty]!” Nay, they can grasp but so little of the truth!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 15,
          "number": 4598,
          "juz": 26,
          "numpage": 512
        }
      ]
    },
    {
      "511": [
        {
          "text": "VERILY, [O Muhammad,] We have laid open before thee a manifest victory,",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 1,
          "number": 4584,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "so that God might show His forgiveness of all thy faults, past as well as future, and [thus] bestow upon thee the full measure of His blessings, and guide thee on a straight way,",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 2,
          "number": 4585,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "and [show] that God will succour thee with [His] mighty succour.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 3,
          "number": 4586,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "It is He who from on high has bestowed inner peace upon the hearts of the believers, so that - seeing that God’s are all the forces of the heavens and the earth, and that God is all-knowing, truly wise - they might grow yet more firm in their faith;",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 4,
          "number": 4587,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "[and] that He might admit the believers, both men and women, into gardens through which running waters flow, therein to abide, and that He might efface their [past bad] deeds: and that is, in the sight of God, indeed a triumph supreme!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 5,
          "number": 4588,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "And [God has willed] to impose suffering [in the life to come] on the hypocrites, both men and women, and on those who ascribe divinity to aught beside Him, both men and women: all who entertain evil thoughts about God. Evil encompasses them from all sides, and God’s condemnation rests upon them; and He has rejected them [from His grace], and has readied hell for them: and how evil a journey’s end!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 6,
          "number": 4589,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "For, God’s are all the forces of the heavens and the earth; and God is indeed almighty, truly wise!",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 7,
          "number": 4590,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "VERILY, [O Muhammad,] We have sent thee as a witness [to the truth], and as a herald of glad tidings and a warner –",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 8,
          "number": 4591,
          "juz": 26,
          "numpage": 511
        },
        {
          "text": "so that you [O men] might believe in God and His Apostle, and might honour Him, and revere Him, and extol His limitless glory from morn to evening.",
          "englishName": "Al-Fath",
          "suraNumber": 48,
          "numberInSurah": 9,
          "number": 4592,
          "juz": 26,
          "numpage": 511
        }
      ]
    },
    {
      "510": [
        {
          "text": "Now had We so willed, We could have shown them clearly to thee, so that thou wouldst know them for sure as by a visible mark: but [even so,] thou wilt most certainly recognize them by the tone of their voice. And God knows all that you do, [O men;]",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 30,
          "number": 4575,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "and most certainly We shall try you all, so that We might mark out those of you who strive hard [in Our cause] and are patient in adversity: for We shall put to a test [the truth of] all your assertions.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 31,
          "number": 4576,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "Verily, they who are bent on denying the truth and on barring [others] from the path of God, and [who thus] cut themselves off from the Apostle after guidance has been vouchsafed to them, can in no wise harm God; but He will cause all their deeds to come to nought.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 32,
          "number": 4577,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "O you who have attained to faith! Pay heed unto God, and pay heed unto the Apostle, and let not your [good] deeds come to nought!",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 33,
          "number": 4578,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "Verily, as for those who are bent on denying the truth and on barring [others] from the path of God, and then die as deniers of the truth - indeed, God will not grant them forgiveness!",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 34,
          "number": 4579,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "AND SO, [when you fight in a just cause,] do not lose heart and [never] beg for peace: for, seeing that God is with you, you are bound to rise high [in the end]; and never will He let your [good] deeds go to waste.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 35,
          "number": 4580,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "The life of this world is but a play and a passing delight: but if you believe [in God] and are conscious of Him, He will grant you your deserts. And withal, He does not demand of you [to sacrifice in His cause all of] your possessions:",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 36,
          "number": 4581,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "[for,] if He were to demand of you all of them, and urge you, you would niggardly cling [to them], and so He would [but] bring out your moral failings.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 37,
          "number": 4582,
          "juz": 26,
          "numpage": 510
        },
        {
          "text": "Behold, [O believers,] it is you who are called upon to spend freely in God’s cause: but [even] among you are such as turn out to be niggardly! And yet, he who acts niggardly [in God’s cause] is but niggardly towards his own self: for God is indeed self-sufficient, whereas you stand in need [of Him]; and if you turn away [from Him], He will cause other people to take your place, and they will not be the likes of you!",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 38,
          "number": 4583,
          "juz": 26,
          "numpage": 510
        }
      ]
    },
    {
      "509": [
        {
          "text": "NOW THOSE who have attained to faith say, “Would that a revelation [allowing us to fight] was bestowed from on high!” But now that a revelation clear in and by itself, mentioning war, has been bestowed from on high, thou canst see those in whose hearts is disease look­ing at thee, [O Muhammad,] with the look of one who is about to faint for fear of death! And yet, far better for them would be",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 20,
          "number": 4565,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "obedience [to God’s call] and a word that could win [His] approval: for, since the matter has been resolved [by His revelation], it would be but for their own good to remain true to God.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 21,
          "number": 4566,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "[Ask them:] “Would you, perchance, after having turned away [from God’s commandment, pre­fer to revert to your old ways, and] spread corruption on earth, and [once again] cut asunder your ties of kinship?”",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 22,
          "number": 4567,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "It is such as these whom God rejects, and whom He makes deaf [to the voice of truth], and whose eyes He blinds [to its sight]!",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 23,
          "number": 4568,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "Will they not, then, ponder over this Qur’an? - or are there locks upon their hearts?",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 24,
          "number": 4569,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "VERILY, those who turn their backs [on this mes­sage] after guidance has been vouchsafed to them, [do it because] Satan has embellished their fancies and filled them with false hopes:",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 25,
          "number": 4570,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "[they do turn their backs on it] inasmuch as they are wont to say unto those who abhor all that God has revealed, “We will comply with your views on some points.” But God knows their secret thoughts:",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 26,
          "number": 4571,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "hence, how [will they fare] when the angels gather them in death, striking their faces and their backs?",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 27,
          "number": 4572,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "This, because they were wont to pursue what God con­demns and to hate [whatever would meet with] His goodly acceptance: and so He has caused all their [good] deeds to come to nought.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 28,
          "number": 4573,
          "juz": 26,
          "numpage": 509
        },
        {
          "text": "Or do they in whose hearts is disease think, perchance, that God would never bring their moral failings to light?",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 29,
          "number": 4574,
          "juz": 26,
          "numpage": 509
        }
      ]
    },
    {
      "508": [
        {
          "text": "Verily, God will admit all who attain to faith and do righteous deeds into gardens through which running waters flow, whereas they who are bent on denying the truth shall have - even though they may enjoy their life [in this world] and eat as cattle eat - the fire [of the hereafter] for their abode.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 12,
          "number": 4557,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "And how many a community of greater power than this thy community which has driven thee out, [O Muhammad,] have We destroyed, with none to succour them!",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 13,
          "number": 4558,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "CAN, THEN, he who takes his stand on a clear evidence from his Sustainer be likened Unto one to whom the evil of his own doings [always] seems goodly, and unto such as would follow but their own lusts?",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 14,
          "number": 4559,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "[And can] the parable of the paradise which the God-conscious are promised [a paradise] wherein there are rivers of water which time does not corrupt, and rivers of milk the taste whereof never alters, and rivers of wine delightful to those who drink it, and rivers of honey of all impurity cleansed, and the enjoyment of all the fruits [of their good deeds] and of forgiveness from their Sustainer -: can this [parable of paradise] be likened unto [the parable of the recompense of] such as are to abide in the fire and be given waters of burning despair to drink, so that it will tear their bowels asunder?",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 15,
          "number": 4560,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "Now among those [hapless sinners] are such as [pretend to] listen to thee, [O Muhammad,] and then, as soon as they leave thy presence, speak [with scorn] unto those who have understood [thy mes­sage]: “What is it that he has said just now?” It is such as these whose hearts God has sealed because they [always] followed but their own lusts",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 16,
          "number": 4561,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "just as for those who are [willing to be] guided, He increases their [ability to follow His] guidance and causes them to grow in God-consciousness.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 17,
          "number": 4562,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "Are, then, they [whose hearts are sealed] wait­ing for the Last Hour - [waiting] that it come upon them of a sudden? But it has already been foretold! And what will their remembrance [of their past sins] avail them, once it has come upon them?",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 18,
          "number": 4563,
          "juz": 26,
          "numpage": 508
        },
        {
          "text": "Know, then, [O man,] that there is no deity save God, and [while there is yet time,] ask forgive­ness for thy sins and for [the sins of] all other believing men and women: for God knows all your comings and goings as well as your abiding [at rest].",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 19,
          "number": 4564,
          "juz": 26,
          "numpage": 508
        }
      ]
    },
    {
      "507": [
        {
          "text": "AS FOR THOSE who are bent on denying the truth and on barring [others] from the path of God - all their [good] deeds will He let go to waste;",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 1,
          "number": 4546,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "whereas those who have attained to faith and do righteous deeds, and have come to believe in what has been bestowed from on high on Muhammad - for it is the truth from their Sustainer - [shall attain to God’s grace:] He will efface their [past] bad deeds, and will set their hearts at rest.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 2,
          "number": 4547,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "This, because they who are bent on denying the truth pursue falsehood, whereas they who have attained to faith pursue [but] the truth [that flows] from their Sustainer. In this way does God set forth unto man the parables of their true state.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 3,
          "number": 4548,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "NOW WHEN you meet [in war] those who are bent on denying the truth, smite their necks until you overcome them fully, and then tighten their bonds; but thereafter [set them free,] either by an act of grace or against ransom, so that the burden of war may be lifted: thus [shall it be]. And [know that] had God so willed, He could indeed punish them [Himself]; but [He wills you to struggle] so as to test you [all] by means of one another. And as for those who are slain in God’s cause, never will He let their deeds go to waste:",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 4,
          "number": 4549,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "He will guide them [in the hereafter as well], and will set their hearts at rest,",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 5,
          "number": 4550,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "and will admit them to the paradise which He has promised them.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 6,
          "number": 4551,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "O you who have attained to faith! If you help [the cause of] God, He will help you, and will make firm your steps;",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 7,
          "number": 4552,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "but as for those who are bent on denying the truth, ill fortune awaits them, since He will let all their [good] deeds go to waste:",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 8,
          "number": 4553,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "this, because they hate [the very thought of] what God has bestowed from on high and thus He causes all their deeds to come to nought!",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 9,
          "number": 4554,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "Have they, then, never journeyed about the earth and beheld what happened in the end to those [willful sinners] who lived before their time? God destroyed them utterly: and the like thereof awaits all who deny the truth.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 10,
          "number": 4555,
          "juz": 26,
          "numpage": 507
        },
        {
          "text": "This, because God is the Protector of all who have attained to faith, whereas they who deny the truth have no protector.",
          "englishName": "Muhammad",
          "suraNumber": 47,
          "numberInSurah": 11,
          "number": 4556,
          "juz": 26,
          "numpage": 507
        }
      ]
    },
    {
      "506": [
        {
          "text": "AND LO! We caused a group of unseen beings to incline towards thee, [O Muhammad,] so that they might give ear to the Qur’an; and so, as soon as they became aware of it, they said [unto one another], “Listen in silence!” And when [the recitation] was ended, they returned to their people as warners.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 29,
          "number": 4539,
          "juz": 26,
          "numpage": 506
        },
        {
          "text": "They said: “O our people! Behold, we have been listening to a revelation bestowed from on high after [that of] Moses, confirming the truth of what­ever there still remains [of the Torah]: it guides towards the truth, and onto a straight way.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 30,
          "number": 4540,
          "juz": 26,
          "numpage": 506
        },
        {
          "text": "“O our people! Respond to God’s call, and have faith in Him: He will forgive you [whatever is past] of your sins, and deliver you from grievous suffering [in the life to come].",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 31,
          "number": 4541,
          "juz": 26,
          "numpage": 506
        },
        {
          "text": "But he who does not respond to God’s call can never elude [Him] on earth, nor can he has any protector against Him [in the life to come]: all such are most obviously lost in error.”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 32,
          "number": 4542,
          "juz": 26,
          "numpage": 506
        },
        {
          "text": "ARE, THEN, they [who deny the life to come] not aware that God, who has created the heavens and the earth and never been wearied by their creation, has [also] the power to bring the dead back to life? Yea, verily, He has the power to will anything!",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 33,
          "number": 4543,
          "juz": 26,
          "numpage": 506
        },
        {
          "text": "And so, on the Day when those who were bent on denying the truth will be brought within sight of the fire [and will be asked], “Is not this the truth?” - they will answer, “Yea, by Our Sustainer!” [And] He will say: “Taste, then, this suffering as an outcome of your denial of the truth!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 34,
          "number": 4544,
          "juz": 26,
          "numpage": 506
        },
        {
          "text": "REMAIN, then, [O believer,] patient in adversity, just as all of the apostles, endowed with firmness of heart, bore themselves with patience. And do not ask for a speedy doom of those [who still deny the truth]: on the Day when they see [the fulfillment of] what they were promised, [it will seem to them] as though they had dwelt [on earth] no longer than one hour of [an earthly] day! [This is Our] message. Will, then, any be [really] destroyed save iniquitous folk?”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 35,
          "number": 4545,
          "juz": 26,
          "numpage": 506
        }
      ]
    },
    {
      "505": [
        {
          "text": "AND REMEMBER that brother of [the tribe of] Ad, how - seeing that [other] warnings had already come and gone within his own knowledge as well as in times beyond his ken - he warned his people [who lived] among those sand-dunes: “Worship none but God! Verily, I fear lest suffering befall you on an awesome day!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 21,
          "number": 4531,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "They answered: “Hast thou come to seduce us away from our gods? Bring, then, upon us that [doom] with which thou threaten us, if thou art a man of truth!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 22,
          "number": 4532,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "Said he: “Knowledge [of when it is to befall you] rests with God alone: I but convey unto you the message with which I have been entrusted; but I see that you are people ignorant [of right and wrong]!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 23,
          "number": 4533,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "And so, when they beheld it in the shape of a dense cloud approaching their valleys, they exclaimed, “This is but a heavy cloud which will bring us [wel­come] rain!” [But Hud said:] “Nay, but it is the very thing which you [so contemptuously] sought to hasten - a wind bearing grievous suffering,",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 24,
          "number": 4534,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "bound to destroy everything at its Sustainer’s behest!” And then they were so utterly wiped out that nothing could be seen save their [empty] dwellings: thus do We requite people lost in sin.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 25,
          "number": 4535,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "And yet, We had established them securely in a manner in which We have never established you, [O people of later times;] and We had endowed them with hearing, and sight, and [knowledgeable] hearts: but neither their hearing, nor their sight, nor their hearts were of the least avail to them, seeing that they went on rejecting God’s messages; and [in the end] they were overwhelmed by the very thing which they had been wont to deride.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 26,
          "number": 4536,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "Thus have We destroyed many a [sinful] community living round about you; and yet, [before destroying them,] We had given many facets to [Our warning] messages, so that they might turn back [from their evil ways].",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 27,
          "number": 4537,
          "juz": 26,
          "numpage": 505
        },
        {
          "text": "But, then, did those [beings] whom they had chosen to worship as deities beside God, hoping that they would bring them nearer [to Him], help them [in the end]? Nay, they forsook them: for that [alleged divinity] was but an outcome of their self-delusion and all their false imagery.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 28,
          "number": 4538,
          "juz": 26,
          "numpage": 505
        }
      ]
    },
    {
      "504": [
        {
          "text": "NOW [among the best of the deeds which] We have enjoined upon man is goodness towards his parents. In pain did his mother bear him, and in pain did she give him birth; and her bearing him and his utter dependence on her took thirty months. and so, when he attains to full maturity and reaches forty years, he [that is righteous] prays: “O my Sustainer! Inspire me so that I may forever be grateful for those blessings of Thine with which Thou hast graced me and my parents, and that I may do what is right [in a manner] that will meet with Thy goodly acceptance; and grant me righteousness in my offspring [as well]. Verily, unto Thee have I turned in repentance: for, verily, I am of those who have surrendered themselves unto Thee!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 15,
          "number": 4525,
          "juz": 26,
          "numpage": 504
        },
        {
          "text": "It is [such as] these from whom We shall accept the best that they ever did, and whose bad deeds We shall overlook: [they will find themselves] among those who are destined for paradise, in fulfillment of the true promise which they were given [in this world].",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 16,
          "number": 4526,
          "juz": 26,
          "numpage": 504
        },
        {
          "text": "But [there is many a one] who says to his parents [whenever they try to imbue him with faith in God]: “Fie upon both of you! Do you promise me that I shall be brought forth [from the dead], although [so many] generations have passed away before me?” And [while] they both pray for God’s help [and say], “Alas for thee! For, behold, God’s promise always comes true!” - he but answers, “All this is nothing but fables of ancient times!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 17,
          "number": 4527,
          "juz": 26,
          "numpage": 504
        },
        {
          "text": "It is [such as] these upon whom the sentence [of doom] will fall due, together with the [other sinful] communities of invisible beings and humans that have passed away before their time. Verily, they will be lost:",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 18,
          "number": 4528,
          "juz": 26,
          "numpage": 504
        },
        {
          "text": "for, [in the life to come,] all shall have their degrees in accordance with whatever [good or evil] they did: and so, He will repay them in full for their doings, and none shall be wronged.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 19,
          "number": 4529,
          "juz": 26,
          "numpage": 504
        },
        {
          "text": "And on the Day when those who were bent on denying the truth will be brought within sight of the fire, [they will be told:] “You have exhausted your [share of] good things in your worldly life, having enjoyed them [without any thought of the hereafter]: and so today you shall be requited with the suffering of humiliation for having gloried on earth in your arrogance, offending against all that is right, and for all your iniquitous doings!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 20,
          "number": 4530,
          "juz": 26,
          "numpage": 504
        }
      ]
    },
    {
      "503": [
        {
          "text": "such as, when all mankind is gathered [for judgment], will be enemies unto those [who worshipped them], and will utterly reject their worship?",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 6,
          "number": 4516,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "But whenever Our messages are conveyed to them in all their clarity, they who are bent on denying the truth speak thus of the truth as soon as it is brought to them: “This is clearly nothing but spell­binding eloquence!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 7,
          "number": 4517,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "Or do they say, “He has invented all this”? Say [O Muhammad]: “Had I invented it, you would not be of the least help to me against God. He is fully aware of that [slander] into which you so reck­lessly plunge: enough is He as a witness between me and you! And [withal,] He alone is truly-forgiving, a true dispenser of grace.”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 8,
          "number": 4518,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "Say: “I am not the first of [God’s] apostles; and [like all of them,] I do not know what will be done with me or with you: for I am nothing but a plain warner.”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 9,
          "number": 4519,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "Say: “Have you given thought [to how you will fare] if this be truly [a revelation] from God and yet you deny its truth? - even though a witness from among the children of Israel has already borne wit­ness to [the advent of] one like himself, and has believed [in him], the while you glory in your arro­gance [and reject his message]? Verily, God does not grace [such] evildoing folk with His guidance!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 10,
          "number": 4520,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "But they who are bent on denying the truth speak thus of those who have attained to faith: “If this [message] were any good, these [people] would not have preceded us in accepting it!” And since they refuse to be guided by it, they will always say, “This is [but] an ancient falsehood!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 11,
          "number": 4521,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "And yet, before this there was the revelation of Moses, a guide and a [sign of God’s] grace; and this [Qur’an] is a divine writ confirming the truth [of the Torah] in the Arabic tongue, to warn those who are bent on evildoing, and [to bring] a glad tiding to the doers of good:",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 12,
          "number": 4522,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "for, behold, all who say, “Our Sustainer is God”, and thereafter stand firm [in their faith] - no fear need they have, and neither shall they grieve:",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 13,
          "number": 4523,
          "juz": 26,
          "numpage": 503
        },
        {
          "text": "it is they who are destined for paradise, therein to abide as a reward for all that they have done.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 14,
          "number": 4524,
          "juz": 26,
          "numpage": 503
        }
      ]
    },
    {
      "502": [
        {
          "text": "And [on that Day,] the evil of their doings will become obvious to them, and they will be over­whelmed by the very thing which they were wont to deride.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 33,
          "number": 4506,
          "juz": 25,
          "numpage": 502
        },
        {
          "text": "And [the word] will be spoken: “Today We shall be oblivious of you as you were oblivious of the coming of this your Day [of Judgment]; and so your goal is the fire, and you shall have none to succour you:",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 34,
          "number": 4507,
          "juz": 25,
          "numpage": 502
        },
        {
          "text": "this, because you made God’s messages the target of your mockery, having allowed the life of this world to beguile you!” On that Day, therefore, they will not be brought out of the fire, nor will they be allowed to make amends.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 35,
          "number": 4508,
          "juz": 25,
          "numpage": 502
        },
        {
          "text": "AND THUS, all praise is due to God, Sustainer of the heavens and Sustainer of the earth: the Sustainer of all the worlds!",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 36,
          "number": 4509,
          "juz": 25,
          "numpage": 502
        },
        {
          "text": "And His alone is all majesty in the heavens and on earth; and He alone is almighty, truly wise!",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 37,
          "number": 4510,
          "juz": 25,
          "numpage": 502
        },
        {
          "text": "Ha. Mim.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 1,
          "number": 4511,
          "juz": 26,
          "numpage": 502
        },
        {
          "text": "THE BESTOWAL from on high of this divine writ I issues from God, the Almighty, the Wise.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 2,
          "number": 4512,
          "juz": 26,
          "numpage": 502
        },
        {
          "text": "We have not created the heavens and the earth and all that is between them otherwise than in accord­ance with [an inner] truth, and for a term set [by Us]: and yet, they who are bent on denying the truth turn aside from the warning which has been conveyed unto them.",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 3,
          "number": 4513,
          "juz": 26,
          "numpage": 502
        },
        {
          "text": "Say: “Have you [really] given thought to what it is that you invoke instead of God? Show me what these [beings or forces] have created anywhere on earth! Or had they, perchance, a share in [creating] the heavens? [If so,] bring me any divine writ pre­ceding this one, or any [other] vestige of knowledge - if what you claim is true!”",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 4,
          "number": 4514,
          "juz": 26,
          "numpage": 502
        },
        {
          "text": "And who could be more astray than one who invokes, instead of God, such as will not respond to him either now or on the Day of Resurrection, and are not even conscious of being invoked?",
          "englishName": "Al-Ahqaf",
          "suraNumber": 46,
          "numberInSurah": 5,
          "number": 4515,
          "juz": 26,
          "numpage": 502
        }
      ]
    },
    {
      "501": [
        {
          "text": "HAST THOU ever considered [the kind of man] who makes his own desires his deity, and whom God has [thereupon] let go astray, knowing [that his mind is closed to all guidance], and whose hearing and heart He has sealed, and upon whose sight He has placed a veil? Who, then, could guide him after God [has abandoned him]? Will you not, then, bethink your­selves?",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 23,
          "number": 4496,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "And yet they say: “There is nothing beyond our life in this world. We die as we come to life, and nothing but time destroys us.” But of this they have no knowledge whatever: they do nothing but guess.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 24,
          "number": 4497,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "And [so,] whenever Our messages are conveyed to them in all their clarity, their only argument is this: “Bring forth our fore­fathers [as witnesses], if what you claim is true!”",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 25,
          "number": 4498,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "Say: “It is God who gives you life, and then causes you to die; and in the end He will gather you together on Resurrection Day, [the coming of] which is beyond all doubt - but most human beings under­stand it not.”",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 26,
          "number": 4499,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "For, God’s is the dominion over the heavens and the earth; and on the Day when the Last Hour dawns - on that Day will be lost all who [in their lifetime] tried to reduce to nothing [whatever they could not understand].",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 27,
          "number": 4500,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "And [on that Day] thou wilt see all people kneeling down [in humility]: all people will be called upon to [face] their record: “Today you shall be requited for all that you ever did!",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 28,
          "number": 4501,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "This Our record speaks of you in all truth: for, verily, We have caused to be recorded all that you ever did!”",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 29,
          "number": 4502,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "Now as for those who have attained to faith and done righteous deeds, their Sustainer will admit them to His grace: that will be [their] manifest triumph!",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 30,
          "number": 4503,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "But as for those who were bent on denying the truth, [they will be told:] “Were not My messages conveyed to you? And withal, you gloried in your arrogance, and so you became people lost in sin:",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 31,
          "number": 4504,
          "juz": 25,
          "numpage": 501
        },
        {
          "text": "for when it was said, ‘Behold, God’s promise always comes true, and there can be no doubt about [the coming of] the Last Hour’ - you would answer, ‘We do not know what that Last Hour may be: we think it is no more than an empty guess, and [so] we are by no means convinced!’”",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 32,
          "number": 4505,
          "juz": 25,
          "numpage": 501
        }
      ]
    },
    {
      "500": [
        {
          "text": "Tell all who have attained to faith that they should forgive those who do not believe in the com­ing of the Days of God, [since it is] for Him [alone] to requite people for whatever they may have earned.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 14,
          "number": 4487,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "Whoever does what is just and right, does so for his own good; and whoever does evil, does so to his own hurt; and in the end unto your Sustainer you all will be brought back.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 15,
          "number": 4488,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "AND, INDEED, [already] unto the children of Israel did We vouchsafe revelation, and wisdom, and prophethood; and We provided for them sustenance out of the good things of life, and favoured them above all other people [of their time].",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 16,
          "number": 4489,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "And We gave them clear indications of the purpose [of faith]; and it was only after all this knowledge had been vouchsafed to them that they began, out of mutual jealousy, to hold divergent views: [but,] verily, thy Sustainer will judge be­tween them on Resurrection Day regarding all whereon they were wont to differ.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 17,
          "number": 4490,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "And, finally, [O Muhammad,] We have set thee on a way by which the purpose [of faith] may be fulfilled: so follow thou this [way], and follow not the likes and dislikes of those who do not know [the truth].",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 18,
          "number": 4491,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "Behold, they could never be of any avail to thee if thou wert to defy the will of God for, verily, such evildoers are but friends and protectors of one another, whereas God is the Protector of all who are conscious of Him.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 19,
          "number": 4492,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "This [revelation, then,] is a means of insight for mankind, and a guidance and grace unto people who are endowed with inner certainty.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 20,
          "number": 4493,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "Now as for those who indulge in sinful doings - do they think that We place them, both in their life and their death, on an equal footing with those who have attained to faith and do righteous deeds? Bad, indeed, is their judgment:",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 21,
          "number": 4494,
          "juz": 25,
          "numpage": 500
        },
        {
          "text": "for, God has created the heavens and the earth in accordance with [an inner] truth, and [has therefore willed] that every human being shall be recompensed for what he has earned and none shall be wronged.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 22,
          "number": 4495,
          "juz": 25,
          "numpage": 500
        }
      ]
    },
    {
      "499": [
        {
          "text": "Ha. Mim.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 1,
          "number": 4474,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "THE BESTOWAL from on high of this divine writ issues from God, the Almighty, the Wise.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 2,
          "number": 4475,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "Behold, in the heavens as well as on earth there are indeed messages for all who [are willing to] believe.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 3,
          "number": 4476,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "And in your own nature, and in [that of] all the animals which He scatters [over the earth] there are messages for people who are endowed with inner certainty.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 4,
          "number": 4477,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "And in the succession of night and day, and in the means of subsistence which God sends down from the skies, giving life thereby to the earth after it had been lifeless, and in the change of the winds: [in all this] there are messages for people who use their reason.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 5,
          "number": 4478,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "These messages of God do We convey unto thee, setting forth the truth. In what other tiding, if not in God’s messages, will they, then, believe?",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 6,
          "number": 4479,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "Woe unto every sinful self-deceiver",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 7,
          "number": 4480,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "who hears God’s messages when they are conveyed to him, and yet, as though he had not heard them, persists in his haughty disdain! Hence, announce unto him grievous suffering –",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 8,
          "number": 4481,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "for when he does become aware of any of Our messages, he makes them a target of his mockery! For all such there is shameful suffering in store.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 9,
          "number": 4482,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "Hell is ahead of them; and all that they may have gained [in this world] shall be of no avail whatever to them, and neither shall any of those things which, instead of God, they have come to regard as their protectors: for, awesome suffering awaits them.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 10,
          "number": 4483,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "[To pay heed to God’s signs and messages:] this is [the meaning of] guidance; on the other hand, for those who are bent on denying the truth of their Sustainer’s messages there is grievous suffering in store as an outcome of [their] vileness.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 11,
          "number": 4484,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "IT IS GOD who has made the sea subservient [to His laws, so that it be of use] to you so that ships might sail through it at His behest, and that you might seek to obtain [what you need] of His bounty, and that you might have cause to be grateful.",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 12,
          "number": 4485,
          "juz": 25,
          "numpage": 499
        },
        {
          "text": "And He has made subservient to you, [as a gift] from Himself, all that is in the heavens and on earth: in this, behold, there are messages indeed for people who think!",
          "englishName": "Al-Jaathiya",
          "suraNumber": 45,
          "numberInSurah": 13,
          "number": 4486,
          "juz": 25,
          "numpage": 499
        }
      ]
    },
    {
      "498": [
        {
          "text": "VERILY, the Day of Distinction [between the true and the false] is the term appointed for all of them:",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 40,
          "number": 4454,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "the Day when no friend shall be of the least avail to his friend, and when none shall be succoured",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 41,
          "number": 4455,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "save those upon whom God will have bestowed His grace and mercy: for, verily, He alone is almighty, a dispenser of grace.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 42,
          "number": 4456,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "Verily, [in the life to come] the tree of deadly fruit",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 43,
          "number": 4457,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "will be the food of the sinful:",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 44,
          "number": 4458,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "like molten lead will it boil in the belly,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 45,
          "number": 4459,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "like the boiling of burning despair.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 46,
          "number": 4460,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "[And the word will be spoken:] “Seize him, [O you forces of hell,] and drag him into the midst of the blazing fire:",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 47,
          "number": 4461,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "then pour over his head the anguish of burning despair!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 48,
          "number": 4462,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "Taste it - thou who [on earth] hast considered thyself so mighty, so noble!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 49,
          "number": 4463,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "This is the very thing which you [deniers of the truth] were wont to call in question!”",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 50,
          "number": 4464,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "[As against this -] verily, the God-conscious will find themselves in a state secure,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 51,
          "number": 4465,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "amid gardens and springs,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 52,
          "number": 4466,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "wearing [garments] of silk and brocade, facing one another [in love].",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 53,
          "number": 4467,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "Thus shall it be. And We shall pair them with companions pure, most beautiful of eye.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 54,
          "number": 4468,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "In that [paradise] they shall [rightfully] claim all the fruits [of their past deeds], resting in security;",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 55,
          "number": 4469,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "and neither shall they taste death there after having passed through their erstwhile death. Thus will He have preserved them from all suffer­ing through the blazing fire-",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 56,
          "number": 4470,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "an act of thy Sustainer’s favour: and that, that will be the triumph supreme!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 57,
          "number": 4471,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "THUS, THEN, [O Prophet,] have We made this [divine writ] easy to understand, in thine own [human] tongue, so that men might take it to heart.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 58,
          "number": 4472,
          "juz": 25,
          "numpage": 498
        },
        {
          "text": "So wait thou [for what the future will bring]: behold, they, too, are waiting.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 59,
          "number": 4473,
          "juz": 25,
          "numpage": 498
        }
      ]
    },
    {
      "497": [
        {
          "text": "“And exalt not yourselves against God: for, verily, I come unto you with a manifest authority [from Him];",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 19,
          "number": 4433,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and, behold, it is with my Sustainer - and your Sustainer - that I seek refuge against all your endeavours to revile me.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 20,
          "number": 4434,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "And if you do not believe me, [at least] stand away from me!”",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 21,
          "number": 4435,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "But then, [when they beset him with their enmity,] he called out to his Sustainer, “These are [indeed] people lost in sin!”",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 22,
          "number": 4436,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "And [God said]: “Go thou forth with My servants by night, for you will surely be pursued;",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 23,
          "number": 4437,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and leave the sea becalmed [between thee and Pharaoh’s men]: for, verily, they are a host destined to be drowned!”",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 24,
          "number": 4438,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "[And so they perished: and] how many gardens did they leave behind, and water-runnels,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 25,
          "number": 4439,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and fields of grain, and noble dwellings,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 26,
          "number": 4440,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and [all that] life of ease in which they used to delight!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 27,
          "number": 4441,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "Thus it was. And [then] We made another people heirs [to what they had left],",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 28,
          "number": 4442,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and neither sky nor earth shed tears over them, nor were they allowed a respite.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 29,
          "number": 4443,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "And, indeed, We delivered the children of Israel from the shameful suffering",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 30,
          "number": 4444,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "[inflicted on them] by Pharaoh, seeing that he was truly outstand­ing among those who waste their own selves;",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 31,
          "number": 4445,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and indeed, We chose them knowingly above all other people,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 32,
          "number": 4446,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "and gave them such signs [of Our grace] as would clearly presage a test.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 33,
          "number": 4447,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "[Now,] behold, these [people] say indeed:",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 34,
          "number": 4448,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "“That [which is ahead of us] is but our first [and only] death, and we shall not be raised to life again.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 35,
          "number": 4449,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "So then, bring forth our forefathers [as witnesses], if what you claim is true!”",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 36,
          "number": 4450,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "Are they, then, better than the people of Tubba and those before them, whom We destroyed because they were truly lost in [the same] sin?",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 37,
          "number": 4451,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "For [thus it is:] We have not created the heavens and the earth and all that is between them in mere idle play:",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 38,
          "number": 4452,
          "juz": 25,
          "numpage": 497
        },
        {
          "text": "none of this have We created without [an inner] truth: but most of them under­stand it not.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 39,
          "number": 4453,
          "juz": 25,
          "numpage": 497
        }
      ]
    },
    {
      "496": [
        {
          "text": "Ha. Mim.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 1,
          "number": 4415,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "CONSIDER this divine writ, clear in itself and clearly showing the truth!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 2,
          "number": 4416,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "Behold, from on high have We bestowed it on a blessed night: for, verily, We have always been warning [man].",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 3,
          "number": 4417,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "On that [night] was made clear, in wisdom, the distinction between all things [good and evil]",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 4,
          "number": 4418,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "at a behest from Ourselves: for, verily, We have always been sending [Our messages of guidance]",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 5,
          "number": 4419,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "in pur­suance of thy Sustainer’s grace [unto man]. Verily, He alone is all-hearing, all-knowing,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 6,
          "number": 4420,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "the Sustainer of the heavens and the earth and all that is between them - if you could but grasp it with inner certainty!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 7,
          "number": 4421,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "There is no deity save Him: He grants life and deals death: He is your Sustainer as well as the Sustainer of your forebears of old.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 8,
          "number": 4422,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "Nay, but they [who lack inner certainty] are but Dying with their doubts.",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 9,
          "number": 4423,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "WAIT, THEN, for the Day when the skies shall bring forth a pall of smoke which will make obvious [the approach of the Last Hour],",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 10,
          "number": 4424,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "enveloping all mankind, [and causing the sinners to exclaim:] “Grievous is this suffering!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 11,
          "number": 4425,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "O our Sustainer, relieve us of suffering, for, verily, we [now] believe [in Thee]!”",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 12,
          "number": 4426,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "[But] how shall this remembrance avail them [at the Last Hour], seeing that an apostle had pre­viously come unto them, clearly expounding the truth,",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 13,
          "number": 4427,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "whereupon they turned their backs on him and said, “Taught [by others] is he, a madman”?",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 14,
          "number": 4428,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "[Still,] behold, We shall postpone this suffer­ing for a little while, although you are bound to revert [to your evil ways: but]",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 15,
          "number": 4429,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "on the Day when We shall seize [all sinners] with a most mighty onslaught, We shall, verily, inflict Our retribution [on you as well]!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 16,
          "number": 4430,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "AND, INDEED, [long] before their time did We try Pharaoh’s people [in the same way]: for there came unto them a noble apostle, [who said:]",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 17,
          "number": 4431,
          "juz": 25,
          "numpage": 496
        },
        {
          "text": "“Give in unto me, O God’s bondmen! Verily, I am an apostle [sent] unto you, worthy of trust!",
          "englishName": "Ad-Dukhaan",
          "suraNumber": 44,
          "numberInSurah": 18,
          "number": 4432,
          "juz": 25,
          "numpage": 496
        }
      ]
    },
    {
      "495": [
        {
          "text": "[But,] behold, they who are lost in sin shall abide in the suffering of hell:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 74,
          "number": 4399,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "it will not be lightened for them; and therein they will be lost in hopeless despair.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 75,
          "number": 4400,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "And it is not We who will be doing wrong unto them, but it is they who will have wronged them­selves.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 76,
          "number": 4401,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "And they will cry: “O thou [angel] who rulest [over hell]! Let thy Sustainer put an end to us!” - whereupon] he will reply: “Verily, you must live on [in this state]",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 77,
          "number": 4402,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "INDEED, [O you sinners,] We have conveyed the truth unto you; but most of you abhor the truth.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 78,
          "number": 4403,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "Why - can they [who deny the truth ever] determine what [the truth] should be?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 79,
          "number": 4404,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "Or do they, perchance, think that We do not hear their hidden thoughts and their secret confabulations? Yea, indeed, [We do,] and Our heavenly forces are with them, recording [all].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 80,
          "number": 4405,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "Say [O Prophet]: “If the Most Gracious [truly] had a son, I would be the first to worship him!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 81,
          "number": 4406,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "Utterly remote, in His glory, is the Sustainer of the heavens and the earth - the Sustainer, in almigh­tiness enthroned from all that they may attribute to Him by way of definition!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 82,
          "number": 4407,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "But leave them to indulge in idle talk and play [with words] until they face that [Judgment] Day of theirs which they have been promised:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 83,
          "number": 4408,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "for [then they will come to know that] it is He [alone] who is God in heaven and God on earth, and [that] He alone is truly wise, all-knowing.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 84,
          "number": 4409,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "And hallowed be He unto whom the dominion over the heavens and the earth and all that is between them belongs, and with whom the knowledge of the Last Hour rests, and unto whom you all shall be brought back!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 85,
          "number": 4410,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "And those [beings] whom some invoke beside God have it not in their power to intercede [on Judgment Day] for any but such as have [in their lifetime] borne witness to the truth, and have been aware [that God is one and unique].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 86,
          "number": 4411,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "Now if thou ask those [who worship any being other than God] as to who it is that has created them, they are sure to answer, “God.” How perverted, then, are their minds!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 87,
          "number": 4412,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "[But God has full knowledge of the true be­liever] and of his [despairing] cry: “O my Sustainer! Verily, these are people who will not believe!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 88,
          "number": 4413,
          "juz": 25,
          "numpage": 495
        },
        {
          "text": "Yet bear thou with them, and say, “Peace [be upon you]!” - for in time they will come to know [the truth].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 89,
          "number": 4414,
          "juz": 25,
          "numpage": 495
        }
      ]
    },
    {
      "494": [
        {
          "text": "AND, BEHOLD, this [divine writ] is indeed a means to know [that] the Last Hour [is bound to come]; hence, have no doubt whatever about it, but follow Me: this [alone] is a straight way.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 61,
          "number": 4386,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "And let not Satan bars you [from it] - for, verily, he is your open foe!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 62,
          "number": 4387,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "NOW WHEN Jesus came [to his people] with all evidence of the truth, he said: “I have now come unto you with wisdom, and to make clear Unto you some of that on which you are at variance: hence, be conscious of God, and pay heed unto me.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 63,
          "number": 4388,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "“Verily, God is my Sustainer as well as your Sustainer; so worship [none but] Him: this [alone] is a straight way!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 64,
          "number": 4389,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "But factions from among those [who came after Jesus] began to hold divergent views: woe, then, unto those who are bent on evildoing - [woe] for the suffering [that will befall them] on a grie­vous Day!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 65,
          "number": 4390,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "ARE THEY [who are lost in sin] but waiting for the Last Hour - [waiting] that it come upon them of a sudden, without their being aware [of its approach]?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 66,
          "number": 4391,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "On that Day, [erstwhile] friends will be foes unto one another - [all] save the God-conscious.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 67,
          "number": 4392,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "[And God will say:] “O you servants of Mine! No fear need you have today, and neither shall you grieve –",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 68,
          "number": 4393,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "[O you] who have attained to faith in Our messages and have surrendered your own selves unto Us!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 69,
          "number": 4394,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "Enter paradise, you and your spouses, with happiness blest!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 70,
          "number": 4395,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "[And there] they will be waited upon with trays and goblets of gold; and there will be found all that the souls might desire, and [all that] the eyes might delight in. And therein shall you abide, [O you who believe:]",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 71,
          "number": 4396,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "for such will be the paradise which you shall have inherited by virtue of your past deeds:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 72,
          "number": 4397,
          "juz": 25,
          "numpage": 494
        },
        {
          "text": "fruits [of those deeds] shall you have in abundance, [and] thereof shall you partake!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 73,
          "number": 4398,
          "juz": 25,
          "numpage": 494
        }
      ]
    },
    {
      "493": [
        {
          "text": "although each sign that We showed them was weigh­tier than the preceding one: and [each time] We took them to task through suffering, so that they might return [to Us].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 48,
          "number": 4373,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "And [every time] they exclaimed: “O thou sorcerer! Pray for us to thy Sustainer on the strength of the covenant [of prophethood] which He has made with thee: for, verily, we shall now follow the right way!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 49,
          "number": 4374,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "But whenever We removed the suffering from them, lo! they would break their word.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 50,
          "number": 4375,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "And Pharaoh issued a call to his people, say­ing: “O my people! Does not the dominion over Egypt belong to me, since all these running waters flow at my feet? Can you not, then, see [that I am your lord supreme]?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 51,
          "number": 4376,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "Am I not better than this contemptible man who can hardly make his meaning clear?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 52,
          "number": 4377,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "“And then - why have no golden armlets been bestowed on him? or why have no angels come together with him?”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 53,
          "number": 4378,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "Thus he incited his people to levity, and they obeyed him: for, behold, they were people depraved!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 54,
          "number": 4379,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "But when they continued to challenge Us, We inflicted Our retribution on them, and drowned them all:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 55,
          "number": 4380,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "and so We made them a thing of the past, and an example to those who would come after them.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 56,
          "number": 4381,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "NOW WHENEVER [the nature of] the son of Mary is set forth as an example, [O Muhammad,] lo! thy people raise an outcry on this score,",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 57,
          "number": 4382,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "and say, “Which is better - our deities or he?” [But] it is only in the spirit of dispute that they put this comparison before thee: yea, they are conten­tious folk!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 58,
          "number": 4383,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "[As for Jesus,] he was nothing but [a human being -] a servant [of Ours] whom We had graced [with prophethood], and whom We made an example for the children of Israel.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 59,
          "number": 4384,
          "juz": 25,
          "numpage": 493
        },
        {
          "text": "And had We so willed, [O you who worship angels,] We could indeed have made you into angels succeeding one another on earth!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 60,
          "number": 4385,
          "juz": 25,
          "numpage": 493
        }
      ]
    },
    {
      "492": [
        {
          "text": "and [silver] doors for their houses, and [silver] couches whereon to recline,",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 34,
          "number": 4359,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "and gold [beyond count]. Yet all this would have been nothing but a [brief] enjoyment of life in this world - whereas [happiness in] the life to come awaits the God-conscious with thy Sustainer.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 35,
          "number": 4360,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "But as for anyone who chooses to remain blind to the remembrance of the Most Gracious, to him We assign an [enduring] evil impulse, to become his other self:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 36,
          "number": 4361,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "whereupon, behold, these [evil impulses] bar all such from the path [of truth], making them think that they are guided aright!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 37,
          "number": 4362,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "But in the end, when he [who has thus sinned] appears before us [on Judgment Day], he will say [to his other self], “Would that between me and thee there had been the distance of east and west!” for, evil indeed [has proved] that other self!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 38,
          "number": 4363,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "On that Day it will not profit you in the least [to know] that, since you have sinned [together], you are now to share your suffering [as well].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 39,
          "number": 4364,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "CANST THOU, perchance, [O Muhammad,] make the deaf hear, or show the right way to the blind or to such as are obviously lost in error?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 40,
          "number": 4365,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "But whether We do [or do not] take thee away [ere thy message prevails] - verily, We shall inflict Our retribution on them;",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 41,
          "number": 4366,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "and whether We show thee [or do not show thee in this world] the fulfillment of what We have promised them - verily, We have full power over them!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 42,
          "number": 4367,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "So hold fast to all that has been revealed to thee: for, behold, thou art on a straight way;",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 43,
          "number": 4368,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "and, verily, this [revelation] shall indeed become [a source of] eminence for thee and thy people: but in time you all will be called to account [for what you have done with it].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 44,
          "number": 4369,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "Yet [above all else,] ask any of Our apostles whom We sent forth before thy time whether We have ever allowed that deities other than the Most Gracious be worshipped!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 45,
          "number": 4370,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "THUS, INDEED, have We sent Moses with Our messages unto Pharaoh and his great ones; and he said: “Behold, I am an apostle of the Sustainer of all the worlds!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 46,
          "number": 4371,
          "juz": 25,
          "numpage": 492
        },
        {
          "text": "But as soon as he came before them with Our [miraculous] signs, lo! they derided them,",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 47,
          "number": 4372,
          "juz": 25,
          "numpage": 492
        }
      ]
    },
    {
      "491": [
        {
          "text": "And thus it is: whenever We sent, before thy time, a warner to any community, those of its people who had lost themselves entirely in the pursuit of pleasures would always say, “Behold, we found our forefathers agreed on what to believe - and, verily, it is but in their footsteps that we follow!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 23,
          "number": 4348,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "[Whereupon each prophet] would say, “Why, even though I bring you guidance better than that which you found your forefathers believing in?” - [to which] the others would reply, “Behold, we deny that there is any truth in [what you claim to be] your messages!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 24,
          "number": 4349,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "And so We inflicted Our retribution on them: and behold what happened in the end to those who gave the lie to the truth!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 25,
          "number": 4350,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "AND WHEN Abraham spoke to his father and his people, [he had this very truth in mind:] “Verily, far be it from me to worship what you worship!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 26,
          "number": 4351,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "None [do I worship] but Him who has brought me into being: and, behold, it is He who will guide me!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 27,
          "number": 4352,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "And he uttered this as a word destined to endure among those who would come after him, so that they might [always] return [to it].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 28,
          "number": 4353,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "Now [as for those who did come after him,] I allowed them - as [I had allowed] their forebears - to enjoy their lives freely until the truth should come unto them through an apostle who would make all things clear:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 29,
          "number": 4354,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "but now that the truth has come to them, they say, “All this is mere spellbinding eloquence” - and, behold, we deny that there is any truth in it!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 30,
          "number": 4355,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "And they say, too, “Why was not this Qur’an bestowed from on high on some great man of the two cities?”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 31,
          "number": 4356,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "But is it they who distribute thy Sustainer’s grace? [Nay, as] it is We who distribute their means of livelihood among them in the life of this world, and raise some of them by degrees above others, to the end that they might avail themselves of one another’s help - [so, too, it is We who bestow gifts of the spirit upon whomever We will]: and this thy Sustainer’s grace is better than all [the worldly wealth] that they may amass.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 32,
          "number": 4357,
          "juz": 25,
          "numpage": 491
        },
        {
          "text": "And were it not that [with the prospect of boundless riches before them] all people would become one [evil] community, We might indeed have provided for those who [now] deny the Most Gracious roofs of silver for their houses, and [silver] stairways whereon to ascend,",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 33,
          "number": 4358,
          "juz": 25,
          "numpage": 491
        }
      ]
    },
    {
      "490": [
        {
          "text": "And He it is who sends down, again and again, waters from the sky in due measure: and [as] We raise therewith dead land to life, even thus will you be brought forth [from the dead].",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 11,
          "number": 4336,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "And He it is who has created all opposites. And He [it is who] has provided for you all those ships and animals whereon you ride,",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 12,
          "number": 4337,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "in order that you might gain mastery over them, and that, whenever you have mastered them, you might remember your Sustainer’s blessings and say: “Limitless in His glory is He who has made [all] this subservient to our use - since [but for Him,] we would not have been able to attain to it.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 13,
          "number": 4338,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "Hence, verily, it is unto Him that we must always turn.”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 14,
          "number": 4339,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "AND YET, they attribute to Him offspring from among some of the beings created by Him! Verily, most obviously bereft of all gratitude is man!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 15,
          "number": 4340,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "Or [do you think], perchance, that out of all His creation He has chosen for Himself daughters, and favoured you with sons?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 16,
          "number": 4341,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "For [thus it is:] if any of them is given the glad tiding of [the birth of] what he so readily attributes to the Most Gracious, his face darkens, and he is filled with suppressed anger:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 17,
          "number": 4342,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "“What! [Am I to have a daughter -] one who is to be reared [only] for the sake of ornament?” and thereupon he finds himself torn by a vague inner conflict.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 18,
          "number": 4343,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "And [yet] they claim that the angels - who in themselves are but beings created by the Most Gracious - are females: [but] did they witness their creation? This false claim of theirs will be recorded, and they will be called to account [for it on Judgment Day]!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 19,
          "number": 4344,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "Yet they say, “Had [not] the Most Gracious so willed, we would never have worshipped them!” [But] they cannot have any knowledge of [His having willed] such a thing: they do nothing but guess.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 20,
          "number": 4345,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "Or have We, perchance, vouchsafed them, before this one, a revelation [to the contrary,] to which they are still holding fast?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 21,
          "number": 4346,
          "juz": 25,
          "numpage": 490
        },
        {
          "text": "Nay, but they say, “Behold, We found our forefathers agreed on what to believe - and, verily, it is in their footsteps that we find our guidance!”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 22,
          "number": 4347,
          "juz": 25,
          "numpage": 490
        }
      ]
    },
    {
      "489": [
        {
          "text": "And thus, too, [O Muhammad,] have We revealed unto thee a life-giving message, [coming] at Our behest. [Ere this message came unto thee,] thou didst not know what revelation is, nor what faith [implies]: but [now] We have caused this [message] to be a light, whereby We guide whom We will of Our ser­vants: and, verily, [on the strength thereof] thou, too, shalt guide [men] onto the straight way –",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 52,
          "number": 4324,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "the way that leads to God, to whom all that is in the heavens and all that is on earth belongs. Oh, verily, with God is the beginning and the end of all things!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 53,
          "number": 4325,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "Ha. Mim.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 1,
          "number": 4326,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "CONSIDER this divine writ, clear in itself and clearly showing the truth:",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 2,
          "number": 4327,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "behold, We have caused it to be a discourse in the Arabic tongue, so that you might encompass it with your reason.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 3,
          "number": 4328,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "And, verily, [originating as it does] in the source, with Us, of all revelation, it is indeed sublime, full of wisdom.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 4,
          "number": 4329,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "[O YOU who deny the truth!] Should We, perchance, withdraw this reminder from you altogether, seeing that you are people bent on wasting your own selves?",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 5,
          "number": 4330,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "And how many a prophet did We send to people of olden times!",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 6,
          "number": 4331,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "But never yet came a prophet to them without their deriding him –",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 7,
          "number": 4332,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "and so, [in the end,] We destroyed them [even though they were] of greater might than these: and the [very] image of those people of old became a thing of the past.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 8,
          "number": 4333,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "Yet thus it is [with most people]: if thou ask them, “Who is it that has created the heavens and the earth?” - they will surely answer, “The Almighty, the All-Knowing has created them.”",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 9,
          "number": 4334,
          "juz": 25,
          "numpage": 489
        },
        {
          "text": "He it is who has made the earth a cradle for you, and has provided for you ways [of livelihood] thereon, so that you might follow the right path.",
          "englishName": "Az-Zukhruf",
          "suraNumber": 43,
          "numberInSurah": 10,
          "number": 4335,
          "juz": 25,
          "numpage": 489
        }
      ]
    },
    {
      "488": [
        {
          "text": "And thou wilt see them exposed to that [doom], humbling themselves in abasement, looking [around] with a furtive glance - the while those who had attained to faith will say, “Verily, lost on [this] Day of Resurrection are they who have squandered their own and their followers’ selves!” Oh, verily, the evildoers will fall into long-lasting suffering,",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 45,
          "number": 4317,
          "juz": 25,
          "numpage": 488
        },
        {
          "text": "and will have no protector whatever to succour them against God: for he whom God lets go astray shall find no way [of escape].",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 46,
          "number": 4318,
          "juz": 25,
          "numpage": 488
        },
        {
          "text": "[Hence, O men,] respond to your Sustainer before there comes, at God’s behest, a Day on which there will be no turning back: [for] on that Day you will have no place of refuge, and neither will you be able to deny aught [of the wrong that you have done].",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 47,
          "number": 4319,
          "juz": 25,
          "numpage": 488
        },
        {
          "text": "BUT IF they turn away [from thee, O Prophet, know that] We have not sent thee to be their keeper: thou art not bound to do more than deliver the message [entrusted to thee]. And, behold, [such as turn away from Our mes­sages are but impelled by the weakness and incon­stancy of human nature: thus,] when We give man a taste of Our grace, he is prone to exult in it; but if misfortune befalls [any of] them in result of what their own hands have sent forth, then, behold, man shows how bereft he is of all gratitude!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 48,
          "number": 4320,
          "juz": 25,
          "numpage": 488
        },
        {
          "text": "God’s alone is the dominion over the heavens and the earth. He creates whatever He wills: He bestows the gift of female offspring on whomever He wills, and the gift of male offspring on whomever He wills;",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 49,
          "number": 4321,
          "juz": 25,
          "numpage": 488
        },
        {
          "text": "or He gives both male and female [to whomever He wills], and causes to be barren whomever He wills: for, verily, He is all-knowing, infinite in His power.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 50,
          "number": 4322,
          "juz": 25,
          "numpage": 488
        },
        {
          "text": "And it is not given to mortal man that God should speak unto him otherwise than through sudden inspiration, or [by a voice, as it were,] from behind a veil, or by sending an apostle to reveal, by His leave, whatever He wills [to reveal]: for, verily, He is exalted, wise.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 51,
          "number": 4323,
          "juz": 25,
          "numpage": 488
        }
      ]
    },
    {
      "487": [
        {
          "text": "And among His signs are the ships that sail like [floating] mountains through the seas:",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 32,
          "number": 4304,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "if He so wills, He stills the wind, and then they lie motion­less on the sea’s surface - [and] herein, behold, there are messages indeed for all who are wholly patient in adversity and deeply grateful [to God];",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 33,
          "number": 4305,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "or else He may cause them to perish because of what they have wrought; and [withal,] He pardons much.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 34,
          "number": 4306,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "And let them know, those who call Our mes­sages in question, that for them there is no escape.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 35,
          "number": 4307,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "AND [remember that] whatever you are given [now] is but for the [passing] enjoyment of life in this world - whereas that which is with God is far better and more enduring. [It shall be given] to all who attain to faith and in their Sustainer place their trust;",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 36,
          "number": 4308,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "and who shun the more heinous sins and abominations; and who, whenever they are moved to anger, readily forgive;",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 37,
          "number": 4309,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "and who respond to [the call of] their Sustainer and are constant in prayer; and whose rule [in all matters of common concern] is consultation among themselves; and who spend on others out of what We provide for them as sustenance;",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 38,
          "number": 4310,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "and who, whenever tyranny afflicts them, defend themselves.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 39,
          "number": 4311,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "But [remember that an attempt at] requiting evil may, too, become an evil: hence, whoever par­dons [his foe] and makes peace, his reward rests with God - for, verily, He does not love evildoers.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 40,
          "number": 4312,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "Yet indeed, as for any who defend themselves after having been wronged - no blame whatever attaches to them:",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 41,
          "number": 4313,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "blame attaches but to those who oppress [other] people and behave outrageously on earth, offending against all right: for them there is grievous suffering in store!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 42,
          "number": 4314,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "But withal, if one is patient in adversity and forgives - this, behold, is indeed something to set one’s heart upon!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 43,
          "number": 4315,
          "juz": 25,
          "numpage": 487
        },
        {
          "text": "AND [thus it is:] he whom God lets go astray has henceforth no protector whatever: and so thou wilt see such evildoers [on Judgment Day, and wilt hear how] they exclaim as soon as they behold the suffering [that awaits them], “Is there any way of return?”",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 44,
          "number": 4316,
          "juz": 25,
          "numpage": 487
        }
      ]
    },
    {
      "486": [
        {
          "text": "that [bounty] whereof God gives the glad tiding to such of His servants as attain to faith and do righteous deeds. Say [O Prophet]: “No reward do I ask of you for this [message] other than [that you should] love your fellow-men.” For, if anyone gains [the merit of] a good deed, We shall grant him through it an increase of good: and, verily, God is much-forgiving, ever responsive to gratitude.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 23,
          "number": 4295,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "DO THEY, perchance, say, “[Muhammad] has attri­buted his own lying inventions to God”? But then, had God so willed, He could have sealed thy heart [forever]: for God blots out all falsehood, and by His words proves the truth to be true. Verily, He has full knowledge of what is in the hearts [of men];",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 24,
          "number": 4296,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "and it is He who accepts repen­tance from His servants, and pardons bad deeds, and knows all that you do,",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 25,
          "number": 4297,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "and responds unto all who attain to faith and do righteous deeds; and [it is He who, in the life to come,] will give them, out of His bounty, far more [than they will have deserved], whereas for the deniers of the truth there is [but] suffering severe in store.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 26,
          "number": 4298,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "For, if God were to grant [in this world] abundant sustenance to [all of] His servants, they would behave on earth with wanton insolence: but as it is, He bestows [His grace] from on high in due measure, as He wills: for, verily, He is fully aware of [the needs of] His creatures, and sees them all.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 27,
          "number": 4299,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "And it is He who sends down rain after [men] have lost all hope, and unfolds His grace [thereby]: for He alone is [their] Protector, the One to whom all praise is due.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 28,
          "number": 4300,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "And among His signs is the [very] creation of the heavens and the earth, and of all the living creatures which He has caused to multiply throughout them: and [since He has created them,] He has [also] the power to gather them [unto Him­self] whenever He wills.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 29,
          "number": 4301,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "Now whatever calamity may befall you [on Judgment Day] will be an outcome of what your own hands have wrought, although He pardons much;",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 30,
          "number": 4302,
          "juz": 25,
          "numpage": 486
        },
        {
          "text": "and you cannot elude Him on earth, and you will have none to protect you from God [in the life to come], and none to bring you succour.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 31,
          "number": 4303,
          "juz": 25,
          "numpage": 486
        }
      ]
    },
    {
      "485": [
        {
          "text": "And as for those who would [still] argue about God after He has been acknowledged [by them] all their arguments are null and void in their Sustainer’s sight, and upon them will fall [His] condemnation, and for them is suffering severe in store:",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 16,
          "number": 4288,
          "juz": 25,
          "numpage": 485
        },
        {
          "text": "[for] it is God [Himself] who has bestowed revelation from on high, setting forth the truth, and [thus given man] a balance [wherewith to weigh right and wrong]. And for all thou knowest, the Last Hour may well be near!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 17,
          "number": 4289,
          "juz": 25,
          "numpage": 485
        },
        {
          "text": "Those who do not believe in it [mockingly] ask for its speedy advent whereas those who have attained to faith stand in awe of it, and know it to be the truth. Oh, verily, they who call the Last Hour in question have indeed gone far astray!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 18,
          "number": 4290,
          "juz": 25,
          "numpage": 485
        },
        {
          "text": "GOD is most kind unto His creatures: He provides sustenance for whomever He wills - for He alone is powerful, almighty!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 19,
          "number": 4291,
          "juz": 25,
          "numpage": 485
        },
        {
          "text": "To him who desires a harvest in the life to come, We shall grant an increase in his harvest; whereas to him who desires [but] a harvest in this world, We [may] give something thereof - but he will have no share in [the blessings of] the life to come.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 20,
          "number": 4292,
          "juz": 25,
          "numpage": 485
        },
        {
          "text": "Is it that they [who care for no more than this world] believe in forces supposed to have a share in God’s divinity, which enjoin upon them as a moral law something that God has never allowed? Now were it not for [God’s] decree on the final judgment, all would indeed have been decided be­tween them [in this world]: but, verily, grievous suffering awaits the evildoers [in the life to come].",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 21,
          "number": 4293,
          "juz": 25,
          "numpage": 485
        },
        {
          "text": "[In that life to come,] thou wilt see the evil­doers full of fear at [the thought of] what they have earned: for [now] it is bound to fall back upon them. And in the flowering meadows of the gardens [of paradise thou wilt see] those who have attained to faith and done righteous deeds: all that they might desire shall they have with their Sustainer: [and] this, this is the great bounty –",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 22,
          "number": 4294,
          "juz": 25,
          "numpage": 485
        }
      ]
    },
    {
      "484": [
        {
          "text": "The Originator [is He] of the heavens and the earth. He has given you mates of your own kind just as [He has willed that] among the beasts [there be] mates - to multiply you thereby: [but] there is nothing like unto Him, and He alone is all-hearing, all-seeing.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 11,
          "number": 4283,
          "juz": 25,
          "numpage": 484
        },
        {
          "text": "His are the keys of the heavens and the earth: He grants abundant sustenance, or gives it in scant measure, unto whomever He wills: for, behold, He has full knowledge of everything.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 12,
          "number": 4284,
          "juz": 25,
          "numpage": 484
        },
        {
          "text": "In matters of faith, He has ordained for you that which He had enjoined upon Noah - and into which We gave thee [O Muhammad] insight through revelation as well as that which We had enjoined upon Abraham, and Moses, and Jesus: Steadfastly uphold the [true] faith, and do not break up your unity therein. [And even though] that [unity of faith] to which thou callest them appears oppressive to those who are wont to ascribe to other beings or forces a share in His divinity, God draws unto Himself everyone who is willing, and guides unto Himself everyone who turns unto Him.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 13,
          "number": 4285,
          "juz": 25,
          "numpage": 484
        },
        {
          "text": "And [as for the followers of earlier revelation,] they broke up their unity, out of mutual jealousy, only after they had come to know [the truth]. And had it not been for a decree that had already gone forth from thy Sustainer, [postponing all decision] until a term set [by Him], all would indeed have been decided between them [from the outset]. As it is, behold, they who have inherited their divine writ from those who preceded them are [now] in grave doubt, amounting to suspicion, about what it por­tends.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 14,
          "number": 4286,
          "juz": 25,
          "numpage": 484
        },
        {
          "text": "Because of this, then, summon [all mankind], and pursue the right course, as thou hast been bidden [by God]; and do not follow their likes and dislikes, but say: “I believe in whatever revelation God has be­stowed from on high; and I am bidden to bring about equity in your mutual views. God is our Sustainer as well as your Sustainer. To us shall be accounted our deeds, and to you, your deeds. Let there be no contention between us and you: God will bring us all together - for with Him is all journeys’ end.”",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 15,
          "number": 4287,
          "juz": 25,
          "numpage": 484
        }
      ]
    },
    {
      "483": [
        {
          "text": "Ha. Mim.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 1,
          "number": 4273,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "Ayn. Sin. Qaf.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 2,
          "number": 4274,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "THUS has God, the Almighty, the Wise, revealed [the truth] unto thee, [O Muhammad,] and unto those who preceded thee:",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 3,
          "number": 4275,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "His is all that is in the heavens and all that is on earth; and most exalted, tremendous is He.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 4,
          "number": 4276,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "The uppermost heavens are well-nigh rent asunder [for awe of Him]; and the angels extol their Sustainer’s limitless glory and praise, and ask for­giveness for all who are on earth. Oh, verily, God alone is truly-forgiving, a dispenser of grace!",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 5,
          "number": 4277,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "NOW AS FOR those who take aught beside Him for their protectors - God watches them, and thou art not responsible for their conduct.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 6,
          "number": 4278,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "[Thou art but entrusted with Our message:] and so We have revealed unto thee a discourse in the Arabic tongue in order that thou mayest warn the foremost of all cities and all who dwell around it to wit, warn [them] of the Day of the Gathering, [the coming of] which is beyond all doubt: [the Day when] some shall find themselves in paradise, and some in the blazing flame.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 7,
          "number": 4279,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "Now had God so willed, He could surely have made them all one single community: none the less, He admits unto His grace him that wills [to be admitted] whereas the evildoers shall have none to protect them and none to succour them [on Judgment Day].",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 8,
          "number": 4280,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "Did they, perchance, [think that they could] choose protectors other than Him? But God alone is the Protector [of all that exists], since it is He alone who brings the dead to life, and He alone who has the power to will anything.",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 9,
          "number": 4281,
          "juz": 25,
          "numpage": 483
        },
        {
          "text": "AND ON WHATEVER you may differ, [O believers,] the verdict thereon rests with God. [Say, therefore:] “Such is God, my Sustainer: in Him have I placed my trust, and unto Him do I always turn!”",
          "englishName": "Ash-Shura",
          "suraNumber": 42,
          "numberInSurah": 10,
          "number": 4282,
          "juz": 25,
          "numpage": 483
        }
      ]
    },
    {
      "482": [
        {
          "text": "In Him alone is vested the knowledge of when the Last Hour will come. And no fruit bursts forth from its calyx, and no female ever conceives, nor ever gives birth, save with His knowledge. And so, on the Day when He shall call out to them, “Where, now, are those [alleged] partners of Mine?” - they will [surely] answer, “We confess unto Thee that none of us can bear witness [to anyone’s having a share in Thy divinity]!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 47,
          "number": 4265,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "And so, all that they were wont to invoke aforetime will have forsaken them; and they shall know for certain that there is no escape for them.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 48,
          "number": 4266,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "MAN NEVER TIRES of asking for the good [things of life]; and if evil fortune touches him, he abandons all hope, giving himself up to despair.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 49,
          "number": 4267,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "Yet whenever We let him taste some of Our grace after hardship has visited him, he is sure to say, “This is but my due!” - and, “I do not think that the Last Hour will ever come: but if [it should come, and] I should indeed be brought back unto my Sus­tainer, then, behold, the ultimate good awaits me with Him! But [on the Day of Judgment] We shall most cer­tainly give those who were bent on denying the truth full understanding of all that they ever did, and shall most certainly give them [thereby] a taste of suffering severe.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 50,
          "number": 4268,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "And, too, when We bestow Our blessings upon man, he tends to turn aside and keep aloof [from remembering Us]; but as soon as evil fortune touches him, he is full of wordy prayers!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 51,
          "number": 4269,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "HAVE YOU given thought [to how you will fare] if this be truly [a revelation] from God, the while you deny its truth? Who could be more astray than one who places himself [so] deeply in the wrong?",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 52,
          "number": 4270,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "In time We shall make them fully understand Our messages [through what they perceive] in the utmost horizons [of the universe] and within them­selves, so that it will become clear unto them that this [revelation] is indeed the truth. [Still,] is it not enough [for them to know] that thy Sustainer is witness unto everything?",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 53,
          "number": 4271,
          "juz": 25,
          "numpage": 482
        },
        {
          "text": "Oh, verily, they are in doubt as to whether they will meet their Sustainer [on Judgment Day]! Oh, verily, He encompasses everything!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 54,
          "number": 4272,
          "juz": 25,
          "numpage": 482
        }
      ]
    },
    {
      "481": [
        {
          "text": "For among His signs is this: thou seest the earth lying desolate - and lo! when We send down water upon it, it stirs and swells [with life]! Verily, He who brings it to life can surely give life to the dead [of heart as well]: for, behold, He has the power to will anything.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 39,
          "number": 4257,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "VERILY, they who distort the meaning of Our mes­sages are not hidden from Us: hence, which [of the two] will be in a better state - he that is [destined to be] cast into the fire, or he that shall come secure [before Us] on Resurrection Day? Do what you will: verily, He sees all that you do.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 40,
          "number": 4258,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "Verily, they who are bent on denying the truth of this reminder as soon as it comes to them - [they are the losers]: for, behold, it is a sublime divine writ:",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 41,
          "number": 4259,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "no falsehood can ever attain to it openly, and neither in a stealthy manner, [since it is] bestowed from on high by One who is truly wise, ever to be praised.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 42,
          "number": 4260,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "[And as for thee, O Prophet,] nothing is being said to thee but what was said to all [of God’s] apostles before thy time. Behold, thy Sustainer is indeed full of forgiveness - but He has also the power to requite most grie­vously!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 43,
          "number": 4261,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "Now if We had willed this [divine writ] to be a discourse in a non-Arabic tongue, they [who now reject it] would surely have said, “Why is it that its messages have not been spelled out clearly? Why - [a message in] a non-Arabic tongue, and [its bearer] an Arab?” Say: “Unto all who have attained to faith, this [divine writ] is a guidance and a source of health; but as for those who will not believe - in their ears is deafness, and so it remains obscure to them: they are [like people who are] being called from too far away.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 44,
          "number": 4262,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "Thus, too, have We vouchsafed revelation unto Moses aforetime, and thereupon disputes arose about it. And [then, as now,] had it not been for a decree that had already gone forth from thy Sustainer, all would indeed have been decided between them [from the outset]. As it is, behold, they [who will not believe in this divine writ] are in grave doubt, amounting to suspicion, about what it portends.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 45,
          "number": 4263,
          "juz": 24,
          "numpage": 481
        },
        {
          "text": "WHOEVER does what is just and right, does so for his own good; and whoever does evil, does so to his own hurt: and never does God do the least wrong to His creatures.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 46,
          "number": 4264,
          "juz": 24,
          "numpage": 481
        }
      ]
    },
    {
      "480": [
        {
          "text": "[But,] behold, as for those who say, “Our Sustainer is God,” and then steadfastly pursue the right way - upon them do angels often descend, [say­ing:] “Fear not and grieve not, but receive the glad tiding of that paradise which has been promised to you!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 30,
          "number": 4248,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "We are close unto you in the life of this world and [will be so] in the life to come; and in that [life to come] you shall have all that your souls may desire, and in it you shall have all that you ever prayed for,",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 31,
          "number": 4249,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "as a ready welcome from Him who is much-forgiving, a dispenser of grace!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 32,
          "number": 4250,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "And who could be better of speech than he who calls [his fellow-men] unto God, and does what is just and right, and says, “Verily, I am of those who have surrendered themselves to God”?",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 33,
          "number": 4251,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "But [since] good and evil cannot be equal, repel thou [evil] with something that is better and lo! he between whom and thyself was enmity [may then become] as though he had [always] been close [unto thee], a true friend!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 34,
          "number": 4252,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "Yet [to achieve] this is not given to any but those who are wont to be patient in adversity: it is not given to any but those endowed with the greatest good fortune!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 35,
          "number": 4253,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "Hence, if it should happen that a prompting from Satan stirs thee up [to blind anger], seek refuge with God: behold, He alone is all-hearing, all-knowing!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 36,
          "number": 4254,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "Now among His signs are the night and the day, as well as the sun and the moon: [hence,] adore not the sun or the moon, but prostrate yourselves in adoration before God, who has created them - if it is Him whom you [really] worship.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 37,
          "number": 4255,
          "juz": 24,
          "numpage": 480
        },
        {
          "text": "And though some be too proud [to listen to this call], they who [in their hearts] are with thy Sustainer extol His limitless glory by night and by day, and never grow weary [thereof].",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 38,
          "number": 4256,
          "juz": 24,
          "numpage": 480
        }
      ]
    },
    {
      "479": [
        {
          "text": "And they will ask their skins, “Why did you bear witness against us?” - [and] these will reply: “God, who gives speech to all things, has given speech to us [as well]: for He [it is who] has created you in the first instance - and unto Him you are [now] brought back.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 21,
          "number": 4239,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "And you did not try to hide [your sins] lest your hearing or your sight or your skins bear witness against you: nay, but you thought that God did not know much of what you were doing –",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 22,
          "number": 4240,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "and that very thought which you thought about your Sustainer has brought you to perdition, and so now you find yourselves among the lost!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 23,
          "number": 4241,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "And then, [even] if they endure [their lot] in patience, the fire will still be their abode; and if they pray to be allowed to make amends, they will not be allowed to do so:",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 24,
          "number": 4242,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "for [when they became obli­vious of Us,] We assigned to them [their own evil impulses as their] other selves, and these made appear goodly to them whatever lay open before them and whatever was beyond their ken. And so, the sentence [of doom] will have fallen due upon them together with the [other sinful] com­munities of invisible beings and humans that passed away before their time: verily, they [all] will indeed be lost!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 25,
          "number": 4243,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "NOW THOSE who are bent on denying the truth say [unto one another]: “Do not listen to this Qur’an, but rather talk frivolously about it, so that you might gain the upper hand!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 26,
          "number": 4244,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "But We shall most certainly give those who are [thus] bent on denying the truth a taste of suffering severe, and We shall most certainly requite them according to the worst of their deeds!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 27,
          "number": 4245,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "That requital of God’s enemies will be the fire [of the hereafter]: in it will they have an abode of immeasurable duration as an outcome of their having knowingly rejected Our messages.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 28,
          "number": 4246,
          "juz": 24,
          "numpage": 479
        },
        {
          "text": "And they who [in their life on earth] were bent on denying the truth will [thereupon] exclaim: “O our Sustainer! Show us those of the invisible beings and humans that have led us astray: we shall trample them underfoot, so that they shall be the lowest of all!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 29,
          "number": 4247,
          "juz": 24,
          "numpage": 479
        }
      ]
    },
    {
      "478": [
        {
          "text": "And He [it is who] decreed that they become seven heavens in two aeons, and imparted unto each heaven its function. And We adorned the skies nearest to the earth with lights, and made them secure: such is the ordaining of the Almighty, the All-Knowing.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 12,
          "number": 4230,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "BUT IF they turn away, say: “I warn you of [the coming of] a thunderbolt of punishment like the thunderbolt [that fell upon the tribes] of Ad and Thamud!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 13,
          "number": 4231,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "Lo! There came unto them [God’s] apostles, speaking of what lay open before them and what was [still] beyond their ken, [and calling unto them,] “Worship none but God!” They answered: “If our Sustainer had willed [us to believe in what you say], He would have sent down angels [as His message-bearers]. As it is, behold, we deny that there is any truth in what you [claim to] have been sent with!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 14,
          "number": 4232,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "Now as for [the tribe of] Ad, they walked arrogantly on earth, [offending] against all right, and saying, “Who could have a power greater than ours?” Why - were they, then, not aware that God, who created them, had a power greater than theirs? But they went on rejecting Our messages;",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 15,
          "number": 4233,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "and thereupon We let loose upon them a storm wind raging through days of misfortune, so as to give them, in the life of this world, a foretaste of suffering through humiliation: but [their] suffering in the life to come will be far more humiliating, and they will have none to succour them.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 16,
          "number": 4234,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "And as for [the tribe of] Thamud, We offered them guidance, but they chose blindness in pre­ference to guidance: and so the thunderbolt of shame­ful suffering fell upon them as an outcome of all [the evil] that they had wrought;",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 17,
          "number": 4235,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "and We saved [only] those who had attained to faith and were conscious of Us.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 18,
          "number": 4236,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "Hence, [warn all men of] the Day when the enemies of God shall be gathered together before the fire, and then shall be driven onward,",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 19,
          "number": 4237,
          "juz": 24,
          "numpage": 478
        },
        {
          "text": "till, when they come close to it, their hearing and their sight and their [very] skins will bear witness against them, speaking of what they were doing [on earth].",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 20,
          "number": 4238,
          "juz": 24,
          "numpage": 478
        }
      ]
    },
    {
      "477": [
        {
          "text": "Ha. Mim.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 1,
          "number": 4219,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "THE BESTOWAL from on high [of this revelation] issues from the Most Gracious, the Dis­penser of Grace:",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 2,
          "number": 4220,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "a divine writ, the messages whereof have been clearly spelled out as a discourse in the Arabic tongue for people of [innate] knowl­edge,",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 3,
          "number": 4221,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "to be a herald of glad tidings as well as a warning. And yet, [whenever this divine writ is offered to men,] most of them turn away, so that they cannot hear [its message];",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 4,
          "number": 4222,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "and so they say, [as it were:] “Our hearts are veiled from whatever thou callest us to, [O Muhammad,] and in our ears is deafness, and between us and thee is a barrier. Do, then, [whatever thou wilt, whereas,] behold, we shall do [as we have always done]!”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 5,
          "number": 4223,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "Say thou, [O Prophet:] “I am but a mortal like you. It has been revealed to me that your God is the One God: go, then, straight towards Him and seek His forgiveness!” And woe unto those who ascribe divinity to aught beside Him,",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 6,
          "number": 4224,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "[and] those who do not spend in charity: for it is they, they who [thus] deny the truth of the life to come!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 7,
          "number": 4225,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "[But,] verily, they who have attained to faith and do good works shall have a reward unending!",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 8,
          "number": 4226,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "SAY: “Would you indeed deny Him who has created the earth in two aeons? And do you claim that there is any power that could rival Him, the Sustainer of all the worlds?”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 9,
          "number": 4227,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "For He [it is who, after creating the earth,] placed firm mountains on it, [towering] above its surface, and bestowed [so many] blessings on it, and equitably apportioned its means of subsistence to all who would seek it: [and all this He created] in four aeons.",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 10,
          "number": 4228,
          "juz": 24,
          "numpage": 477
        },
        {
          "text": "And He [it is who] applied His design to the skies, which were [yet but] smoke; and He [it is who] said to them and to the earth, “Come [into being], both of you, willingly or unwillingly!” - to which both responded, “We do come in obedience.”",
          "englishName": "Fussilat",
          "suraNumber": 41,
          "numberInSurah": 11,
          "number": 4229,
          "juz": 24,
          "numpage": 477
        }
      ]
    },
    {
      "476": [
        {
          "text": "And, indeed, [O Muhammad,] We sent forth apostles before thy time; some of them We have mentioned to thee, and some of them We have not mentioned to thee. And it was not given to any apostle to bring forth a miracle other than by God’s leave. Yet when God’s will becomes manifest, judgment will [already] have been passed in all justice, and lost will be, then and there, all who tried to reduce to nothing [whatever they could not understand].",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 78,
          "number": 4211,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "It is God who [at all times works wonders for you: thus, He] provides for you [all manner of] livestock, so that on some of them you may ride, and from some derive your food,",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 79,
          "number": 4212,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "and find [yet other] benefits in them; and that through them you may attain to the fulfillment of [many] a heartfelt need: for on them, as on ships, you are borne [through life].",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 80,
          "number": 4213,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "And [thus] He displays His wonders before you: which, then, of God’s wonders can you still deny?",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 81,
          "number": 4214,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "HAVE THEY, then, never journeyed about the earth and beheld what happened in the end to those [deniers of the truth] who lived before their time? More numerous were they, and greater in power than they are, and in the impact which they left on earth: but all that they ever achieved was of no avail to them –",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 82,
          "number": 4215,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "for when their apostles came to them with all evidence of the truth, they arrogantly exulted in whatever knowledge they [already] possessed: and [so, in the end,] they were overwhelmed by the very thing which they were wont to deride.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 83,
          "number": 4216,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "And then, when they [clearly] beheld Our punishment, they said: “We have come to believe in the One God, and we have renounced all belief in that to which we were wont to ascribe a share in His divinity!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 84,
          "number": 4217,
          "juz": 24,
          "numpage": 476
        },
        {
          "text": "But their attaining to faith after they had beheld Our punishment could not possibly benefit them such being the way of God that has always obtained for His creatures -: and so, then and there, lost were they who had denied the truth.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 85,
          "number": 4218,
          "juz": 24,
          "numpage": 476
        }
      ]
    },
    {
      "475": [
        {
          "text": "It is He who creates you out of dust, and then out of a drop of sperm, and then out of a germ-cell; and then He brings you forth as children; and then [He ordains] that you reach maturity, and then, that you grow old - though some of you [He causes to] die earlier -: and [all this He ordains] so that you might reach a term set [by Him], and that you might [learn to] use your reason.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 67,
          "number": 4200,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "It is He who grants life and deals death; and when He wills a thing to be, He but says unto it, “Be” - and it is.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 68,
          "number": 4201,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "ART THOU NOT aware of how far they who call God’s messages in question have lost sight of the truth?",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 69,
          "number": 4202,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "they who give the lie to this divine writ and [thus] to all [the messages] with which We sent forth Our apostles [of old]? But in time they will come to know [how blind they have been: they will know it on Judgment Day],",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 70,
          "number": 4203,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "when they shall have to carry the shackles and chains [of their own making] around their necks,",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 71,
          "number": 4204,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "and are dragged into burning despair, and in the end become fuel for the fire [of hell].",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 72,
          "number": 4205,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "And then they will be asked: “Where now are those [powers] to which you were wont to ascribe divinity",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 73,
          "number": 4206,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "side by side with God?” They will answer: “They have forsaken us - or, rather, what we were wont to invoke aforetime did not exist at all!” And they will be told:] “It is thus that God lets the deniers of the truth go astray:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 74,
          "number": 4207,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "this is an out­come of your having arrogantly exulted on earth without any [concern for what is] right, and of your having been so full of self-conceit!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 75,
          "number": 4208,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "Enter [now] the gates of hell, therein to abide: and how vile an abode for all who are given to false pride!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 76,
          "number": 4209,
          "juz": 24,
          "numpage": 475
        },
        {
          "text": "HENCE, remain thou patient in adversity - for, verily, God’s promise always comes true. And whether We show thee [in this world] something of what We hold in store for those [deniers of the truth], or whether We cause thee to die [ere that retribution takes place - know that, in the end], it is unto Us that they will be brought back.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 77,
          "number": 4210,
          "juz": 24,
          "numpage": 475
        }
      ]
    },
    {
      "474": [
        {
          "text": "Verily, the Last Hour is sure to come: of this there is no doubt; yet most men will not believe it.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 59,
          "number": 4192,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "But your Sustainer says: “Call unto Me, [and] I shall respond to you! Verily, they who are too proud to worship Me will enter hell, abased!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 60,
          "number": 4193,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "IT IS GOD who has made the night for you, so that you might rest therein, and the day, to make [you] see. Behold, God is indeed limitless in His bounty unto man - but most men are ungrateful.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 61,
          "number": 4194,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "Such is God, your Sustainer, the Creator of all that exists: there is no deity save Him. How perverted, then, are your minds!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 62,
          "number": 4195,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "[For] thus it is: perverted are the minds of those who knowingly reject God’s messages.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 63,
          "number": 4196,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "It is God who has made the earth a resting- place for you and the sky a canopy, and has formed you - and formed you so well and provided for you sustenance out of the good things of life. Such is God, your Sustainer: hallowed, then, is God, the Sustainer of all the worlds!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 64,
          "number": 4197,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "He is the Ever-Living; there is no deity save Him: call, then, unto Him [alone], sincere in your faith in Him. All praise is due to God, the Sustainer of all the worlds!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 65,
          "number": 4198,
          "juz": 24,
          "numpage": 474
        },
        {
          "text": "Say: “Since all evidence of the truth has come to me from my Sustainer, I am forbidden to worship [any of] those beings whom you invoke instead of God; and I am bidden to surrender myself to the Sustainer of all the worlds.”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 66,
          "number": 4199,
          "juz": 24,
          "numpage": 474
        }
      ]
    },
    {
      "473": [
        {
          "text": "[But the keepers of hell] will ask, “Is it not [true] that your apostles came unto you with all evidence of the truth?” Those [in the fire] will reply, “Yea, indeed.” [And the keepers of hell] will say, “Pray, then!” - for the prayer of those who deny the truth cannot lead to aught but delusion.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 50,
          "number": 4183,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "BEHOLD, We shall indeed succour Our apostles and those who have attained to faith, [both] in this world’s life and on the Day when all the witnesses shall stand up",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 51,
          "number": 4184,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "the Day when their excuses will be of no avail to the evildoers, seeing that their lot will be rejection from all that is good, and a woeful hereafter.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 52,
          "number": 4185,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "And, indeed, We bestowed aforetime [Our] guidance on Moses, and [thus] made the children of Israel heirs to the divine writ [revealed to him]",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 53,
          "number": 4186,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "as a [means of] guidance and a reminder for those who were endowed with insight:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 54,
          "number": 4187,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "hence, remain thou patient in adversity - for, verily, God’s promise always comes true - and ask forgiveness for thy sins, and extol thy Sustainer’s glory and praise by night and by day.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 55,
          "number": 4188,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "Behold, as for those who call God’s messages in question without having any evidence therefore in their hearts is nothing but overweening self-con­ceit, which they will never be able to satisfy: seek thou, then, refuge with God - for, verily, He alone is all-hearing, all-seeing!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 56,
          "number": 4189,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "Greater indeed than the creation of man is the creation of the heavens and the earth: yet most men do not understand [what this implies].",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 57,
          "number": 4190,
          "juz": 24,
          "numpage": 473
        },
        {
          "text": "But [then,] the blind and the seeing are not equal; and neither [can] they who have attained to faith and do good works and the doers of evil [be deemed equal]. How seldom do you keep this in mind!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 58,
          "number": 4191,
          "juz": 24,
          "numpage": 473
        }
      ]
    },
    {
      "472": [
        {
          "text": "“And, O my people, how is it that I summon you to salvation, the while you summon me to the fire?",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 41,
          "number": 4174,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "- [for] you call upon me to deny [the one­ness of] God and to ascribe a share in His divinity to aught of which I cannot [possibly] have any knowl­edge the while I summon you to [a cognition of] the Almighty, the All-Forgiving!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 42,
          "number": 4175,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "“There is no doubt that what you summon me to is something that has no claim to being invoked either in this world or in the life to come - as [there is no doubt] that unto God is our return, and that they who have wasted their own selves shall find them­selves in the fire:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 43,
          "number": 4176,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "and at that time you will [have cause to] remember what I am telling you [now]. “But [as for me,] I commit myself unto God: for, verily, God sees all that is in [the hearts of] His servants.”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 44,
          "number": 4177,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "And God preserved him from the evil of their scheming, whereas suffering vile was to encompass Pharaoh’s folk:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 45,
          "number": 4178,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "the fire [of the hereafter - that fire] which they had been made to contemplate [in vain], morning and evening: for on the Day when the Last Hour dawns [God will say], “Make Phar­aoh’s folk enter upon suffering most severe!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 46,
          "number": 4179,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "AND LO! They [who in life were wont to deny the truth] will contend with one another in the fire [of the hereafter]; and then the weak will say unto those who had gloried in their arrogance, “Behold, we were but your followers: can you, then, relieve us of some [of our] share of this fire?”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 47,
          "number": 4180,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "[to which] they who had [once] been arrogant will reply, “Behold, we are all in it [together]! Verily, God has judged between His creatures!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 48,
          "number": 4181,
          "juz": 24,
          "numpage": 472
        },
        {
          "text": "And they who are in the fire will say to the keepers of hell, “Pray unto your Sustainer that He lighten, [though it be] for one day [only], this suffering of ours!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 49,
          "number": 4182,
          "juz": 24,
          "numpage": 472
        }
      ]
    },
    {
      "471": [
        {
          "text": "“And [remember:] it was to you that Joseph came aforetime with all evidence of the truth; but you never ceased to throw doubt on all [the messages] that he brought you - so much so that when he died, you said, ‘Never will God send any apostle [even] after him!’ “In this way God lets go astray such as waste their own selves by throwing suspicion [on His revela­tions] –",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 34,
          "number": 4167,
          "juz": 24,
          "numpage": 471
        },
        {
          "text": "such as would call God’s messages in question without having any evidence therefor: [a sin] exceedingly loathsome in the sight of God and of those who have attained to faith. It is in this way that God sets a seal on every arrogant, self-exalting heart.”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 35,
          "number": 4168,
          "juz": 24,
          "numpage": 471
        },
        {
          "text": "But Pharaoh said: “O Haman! Build me a lofty tower, that haply I may attain to the [right] means",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 36,
          "number": 4169,
          "juz": 24,
          "numpage": 471
        },
        {
          "text": "the means of approach to the heavens - and that [thus] I may have a look at the god of Moses: for, behold, I am indeed certain that he is a liar!” And thus, goodly seemed unto Pharaoh the evil of his own doings, and so he was barred from the path [of truth]: and Pharaoh’s guile did not lead to aught but ruin.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 37,
          "number": 4170,
          "juz": 24,
          "numpage": 471
        },
        {
          "text": "Still, the man who had attained to faith went on: “O my people! Follow me: I shall guide you onto the path of rectitude!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 38,
          "number": 4171,
          "juz": 24,
          "numpage": 471
        },
        {
          "text": "“O my people! This worldly life is but a brief enjoyment, whereas, behold, the life to come is the home abiding.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 39,
          "number": 4172,
          "juz": 24,
          "numpage": 471
        },
        {
          "text": "[There,] anyone who has done a bad deed will be requited with no more than the like thereof, whereas anyone, be it man or woman, who has done righteous deeds and is a believer withal - all such will enter paradise, wherein they shall be blest with good beyond all reckoning!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 40,
          "number": 4173,
          "juz": 24,
          "numpage": 471
        }
      ]
    },
    {
      "470": [
        {
          "text": "And Pharaoh said: “Leave it to me to slay Moses - and let him invoke his [alleged] sustainer! Behold, I fear lest he cause you to change your religion, or lest he cause corruption to prevail in the land!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 26,
          "number": 4159,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "But Moses said: “With [Him who is] my Sus­tainer as well as your Sustainer have I indeed found refuge from everyone who, immersed in false pride, will not believe in [the coming of] the Day of Reckon­ing!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 27,
          "number": 4160,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "At that, a believing man of Pharaoh’s family, who [until then] had concealed his faith, exclaimed: “Would you slay a man because he says, ‘God is my Sustainer’ - seeing, withal, that he has brought you all evidence of this truth from your Sustainer? Now if he be a liar, his lie will fall back on him; but if he is a man of truth, something [of the punishment] whereof he warns you is bound to befall you: for, verily, God would not grace with His guidance one who has wasted his own self by lying [about Him].",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 28,
          "number": 4161,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "“O my people! Yours is the dominion today, [and] most eminent are you on earth: but who will rescue us from God’s punishment, once it befalls us?” Said Pharaoh: “I but want to make you see what I see myself; and I would never make you follow any path but that of rectitude!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 29,
          "number": 4162,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "Thereupon exclaimed he who had attained to faith: “O my people! Verily, I fear for you the like of what one day befell those others who were leagued together [against God’s truth] –",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 30,
          "number": 4163,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "the like of what happened to Noah’s people, and to [the tribes of] Ad and Thamud and those who came after them! And, withal, God does not will any wrong for His crea­tures.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 31,
          "number": 4164,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "“And, O my people, I fear for you [the coming of] that Day of [Judgment - the Day when you will be] calling unto one another [in distress] –",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 32,
          "number": 4165,
          "juz": 24,
          "numpage": 470
        },
        {
          "text": "the Day when you will [wish to] turn your backs and flee, having none to defend you against God: for he whom God lets go astray can never find any guide.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 33,
          "number": 4166,
          "juz": 24,
          "numpage": 470
        }
      ]
    },
    {
      "469": [
        {
          "text": "On that Day will every human being be requited for what he has earned: no wrong [will be done] on that Day: verily, swift in reckoning is God!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 17,
          "number": 4150,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "Hence, warn them of that Day which draws ever nearer, when the hearts will chokingly come up to the throats: no loving friend will the evildoers have, nor any intercessor who would be heeded:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 18,
          "number": 4151,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "[for] He is aware of the [most] stealthy glance, and of all that the hearts would conceal.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 19,
          "number": 4152,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "And God will judge in accordance with truth and justice, whereas those [beings] whom they invoke beside Him cannot judge at all: for, verily, God alone is all-hearing, all-seeing.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 20,
          "number": 4153,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "Have they, then, never journeyed about the earth and beheld what happened in the end to those [deniers of the truth] who lived before their time? Greater were they in power than they are, and in the impact which they left on earth: but God took them to task for their sins, and they had none to defend them against God:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 21,
          "number": 4154,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "this, because their apostles had come to them with all evidence of the truth, and yet they rejected it: and so God took them to task - for, verily, He is powerful, severe in retribution!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 22,
          "number": 4155,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "THUS, INDEED, did We send Moses with Our mes­sages and a manifest authority [from Us]",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 23,
          "number": 4156,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "unto Pharaoh, and Haman, and Qarun; but they [only] said, “A spellbinder is he, a liar!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 24,
          "number": 4157,
          "juz": 24,
          "numpage": 469
        },
        {
          "text": "Now [as for Pharaoh and his followers,] when he came to them, setting forth the truth from Us, they said, “Slay the sons of those who share his beliefs, and spare [only] their women!” - but the guile of those deniers of the truth could not lead to aught but failure.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 25,
          "number": 4158,
          "juz": 24,
          "numpage": 469
        }
      ]
    },
    {
      "468": [
        {
          "text": "“And, O our Sustainer, bring them into the gardens of perpetual bliss which Thou hast promised them, together with the righteous from among their forebears, and their spouses, and their offspring - for, verily, Thou alone art almighty, truly wise –",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 8,
          "number": 4141,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "and preserve them from [doing] evil deeds: for anyone whom on that Day [of Judgment] Thou wilt have preserved from [the taint of] evil deeds, him wilt Thou have graced with Thy mercy: and that, that will be the triumph supreme!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 9,
          "number": 4142,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "[But,] behold, as for those who are bent on denying the truth - [on that same Day] a voice will call out unto them: “Indeed, greater than your [present] loathing of yourselves was God’s loathing of you [at the time] when you were called unto faith but went on denying the truth!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 10,
          "number": 4143,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "[Whereupon] they will exclaim: “O our Sus­tainer! Twice hast Thou caused us to die, just as twice Thou hast brought us to life!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 11,
          "number": 4144,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "[And they will be told:] “This [has befallen you] because, whenever the One God was invoked, you denied this truth; whereas, when divinity was ascribed to aught beside Him, you believed [in it]! But all judgment rests with God, the Exalted, the Great!”",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 12,
          "number": 4145,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "HE IT IS who shows you His wonders [in all nature], and sends down sustenance for you from the sky: but none bethinks himself [thereof] save those who are wont to turn to God.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 13,
          "number": 4146,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "Invoke, then, God, sincere in your faith in Him alone, however hateful this may be to those who deny the truth!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 14,
          "number": 4147,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "High above all orders [of being] is He, in almightiness enthroned. By His Own will does He bestow inspiration upon whomever He wills of His servants, so as to warn [all human beings of the coming] of the Day when they shall meet Him",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 15,
          "number": 4148,
          "juz": 24,
          "numpage": 468
        },
        {
          "text": "the Day when they shall come forth [from death], with nothing of themselves hidden from God. With whom will sovereignty rest on that Day? With God, the One who holds absolute sway over all that exists!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 16,
          "number": 4149,
          "juz": 24,
          "numpage": 468
        }
      ]
    },
    {
      "467": [
        {
          "text": "And thou wilt see the angels surrounding the throne of [God’s] almightiness, extolling their Sus­tainer’s glory and praise. And judgment will have been passed in justice on all [who had lived and died], and the word will be spoken: “All praise is due to God, the Sustainer of all the worlds!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 75,
          "number": 4133,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "Ha. Mim.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 1,
          "number": 4134,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "THE BESTOWAL from on high of this divine writ issues from God, the Almighty, the All-Know­ing,",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 2,
          "number": 4135,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "forgiving sins and accepting repentance, severe in retribution, limitless in His bounty. There is no deity save Him: with Him is all jour­neys’ end.",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 3,
          "number": 4136,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "NONE BUT THOSE who are bent on denying the truth would call God’s messages in question. But let it not deceive thee that they seem to be able to do as they please on earth:",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 4,
          "number": 4137,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "to the truth gave the lie, before their time, the people of Noah and, after them, all those [others] who were leagued together [against God’s message-bearers]; and each of those com­munities schemed against the apostle sent unto them, aiming to lay hands on him; and they contended [against his message] with fallacious arguments, so as to render void the truth thereby: but then I took them to task - and how awesome was My retribution!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 5,
          "number": 4138,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "And thus shall thy Sustainer’s word come true against all who are bent on denying the truth: they shall find themselves in the fire [of hell].",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 6,
          "number": 4139,
          "juz": 24,
          "numpage": 467
        },
        {
          "text": "THEY WHO BEAR [within themselves the knowl­edge of] the throne of [God’s] almightiness, as well as all who are near it, extol their Sustainer’s limitless glory and praise, and have faith in Him, and ask forgiveness for all [others] who have attained to faith: “O our Sustainer! Thou embracest all things within [Thy] grace and knowledge: forgive, then, their sins unto those who repent and follow Thy path, and preserve them from suffering through the blazing fire!",
          "englishName": "Ghafir",
          "suraNumber": 40,
          "numberInSurah": 7,
          "number": 4140,
          "juz": 24,
          "numpage": 467
        }
      ]
    },
    {
      "466": [
        {
          "text": "And [on that Day,] the trumpet [of judgment] will be sounded, and all [creatures] that are in the heavens and all that are on earth will fall down senseless, unless they be such as God wills [to exempt]. And then it will sound again - and lo! standing [before the Seat of Judgment], they will begin to see [the truth]!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 68,
          "number": 4126,
          "juz": 24,
          "numpage": 466
        },
        {
          "text": "And the earth will shine bright with her Sus­tainer’s light. And the record [of everyone’s deeds] will be laid bare, and all the prophets will be brought forward, and all [other] witnesses; and judgment will be passed on them all in justice. And they will not be wronged,",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 69,
          "number": 4127,
          "juz": 24,
          "numpage": 466
        },
        {
          "text": "for every human being will be repaid in full for whatever [good or evil] he has done: and He is fully aware of all that they do.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 70,
          "number": 4128,
          "juz": 24,
          "numpage": 466
        },
        {
          "text": "And those who were bent on denying the truth will be urged on in throngs towards hell till, when they reach it, its gates will be opened, and its keepers will ask them, “Have there not come to you apostles from among yourselves, who conveyed to you your Sustainer’s messages and warned you of the coming of this your Day [of Judgment]?” They will answer: “Yea, indeed!” But the sentence of suffering will [already] have fallen due upon the deniers of the truth;",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 71,
          "number": 4129,
          "juz": 24,
          "numpage": 466
        },
        {
          "text": "[and] they will be told, “Enter the gates of hell, therein to abide!” And how vile an abode for those who were given to false pride!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 72,
          "number": 4130,
          "juz": 24,
          "numpage": 466
        },
        {
          "text": "But those who were conscious of their Sus­tainer will be urged on in throngs towards paradise till, when they reach it, they shall find its gates wide- open; and its keepers will say unto them, “Peace be upon you! Well have you done: enter, then, this [paradise], herein to abide!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 73,
          "number": 4131,
          "juz": 24,
          "numpage": 466
        },
        {
          "text": "And they will exclaim: “All praise is due to God, who has made His promise to us come true, and has bestowed upon us this expanse [of bliss] as our portion, so that we may dwell in paradise as we please!” And how excellent a reward will it be for those who laboured [in God’s way]!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 74,
          "number": 4132,
          "juz": 24,
          "numpage": 466
        }
      ]
    },
    {
      "465": [
        {
          "text": "or lest he should say, “If God had but guided me, I would surely have been among those who are conscious of Him!”-",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 57,
          "number": 4115,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "or lest he should say, when he becomes aware of the suffering [that awaits him], “Would that I had a second chance [in life], so that I could be among the doers of good!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 58,
          "number": 4116,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "[But God will reply:] “Yea, indeed! My mes­sages did come unto thee; but thou gavest them the lie, and wert filled with false pride, and wert among those who deny the truth!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 59,
          "number": 4117,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "And [so,] on the Day of Resurrection thou wilt see all who invented lies about God [with] their faces darkened [by grief and ignominy]. Is not hell the [proper] abode for all who are given to false pride?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 60,
          "number": 4118,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "But God will safeguard all who were conscious of Him, [and will grant them happiness] by virtue of their [inner] triumphs; no evil shall ever touch them, and neither shall they grieve.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 61,
          "number": 4119,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "GOD is the Creator of all things, and He alone has the power to determine the fate of all things.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 62,
          "number": 4120,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "His are the keys [to the mysteries] of the heavens and the earth: and they who are bent on denying the truth of God’s messages - it is they, they, who are the losers!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 63,
          "number": 4121,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "Say: “Is it, then, something other than God that you bid me to worship, O you who are unaware [of right and wrong]?”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 64,
          "number": 4122,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "And yet, it has already been revealed to thee [O man,] as well as to those who lived before thee, that if thou ever ascribe divine powers to aught but God, all thy works shall most certainly have been in vain: for [in the life to come] thou shalt most certainly be among the lost.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 65,
          "number": 4123,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "Nay, but thou shalt worship God [alone], and be among those who are grateful [to Him]!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 66,
          "number": 4124,
          "juz": 24,
          "numpage": 465
        },
        {
          "text": "And no true understanding of God have they [who worship aught beside Him], inasmuch as the whole of the earth will be as a [mere] handful to Him on Resurrection Day, and the heavens will be rolled up in His right hand: limitless is He in His glory, and sublimely exalted above anything to which they may ascribe a share in His divinity!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 67,
          "number": 4125,
          "juz": 24,
          "numpage": 465
        }
      ]
    },
    {
      "464": [
        {
          "text": "and obvious to them will have become the evil that they had wrought [in life]: and thus shall they be overwhelmed by the very truth which they were wont to deride.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 48,
          "number": 4106,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "NOW [thus it is:] when affliction befalls man, he cries out unto Us for help; but when We bestow upon him a boon by Our grace, he says [to himself], “I have been given [all] this by virtue of [my own] wisdom!” Nay, this [bestowal of grace] is a trial: but most of them understand it not!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 49,
          "number": 4107,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "The same did say [to themselves many of] those who lived before their time; but of no avail to them was all that they had ever achieved:",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 50,
          "number": 4108,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "for all the evil deeds that they had wrought fell [back] upon them. And [the same will happen to] people of the present time who are bent on wrongdoing: all the evil deeds that they have ever wrought will fall [back] upon them, and never will they be able to elude [God]!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 51,
          "number": 4109,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "Are they, then, not aware that it is God who grants abundant sustenance, or gives it in scant measure, unto whomever He wills? In this, behold, there are messages indeed for peo­ple who will believe!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 52,
          "number": 4110,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "SAY: “[Thus speaks God:] ‘O you servants of Mine who have transgressed against your own selves! Despair not of God’s mercy: behold, God forgives all sins - for, verily, He alone is much-forgiving, a dis­penser of grace!’”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 53,
          "number": 4111,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "Hence, turn towards your Sustainer [alone] and surrender yourselves unto Him ere the suffering [of death and resurrection] comes upon you, for then you will not be succoured.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 54,
          "number": 4112,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "And ere that suffering comes upon you of a sudden, without your being aware [of its approach], follow the most goodly [teaching] that has been revealed unto you by your Sustainer,",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 55,
          "number": 4113,
          "juz": 24,
          "numpage": 464
        },
        {
          "text": "lest any human being should say [on Judgment Day], “Alas for me for having been remiss in what is due to God, and for having been indeed one of those who scoffed [at the truth]!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 56,
          "number": 4114,
          "juz": 24,
          "numpage": 464
        }
      ]
    },
    {
      "463": [
        {
          "text": "BEHOLD, from on high have We bestowed upon thee this divine writ, setting forth the truth for [the benefit of all] mankind. And whoever chooses to be guided [thereby], does so for his own good, and whoever chooses to go astray, goes but astray to his own hurt; and thou hast not the power to determine their fate.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 41,
          "number": 4099,
          "juz": 24,
          "numpage": 463
        },
        {
          "text": "It is God [alone that has this power - He] who causes all human beings to die at the time of their [bodily] death, and [causes to be as dead], during their sleep, those that have not yet died: thus, He withholds [from life] those upon whom He has decreed death, and lets the others go free for a term set [by Him]. In [all] this, behold, there are messages indeed for people who think!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 42,
          "number": 4100,
          "juz": 24,
          "numpage": 463
        },
        {
          "text": "And yet, they choose [to worship], side by side with God, [imaginary] intercessors!” Say: “Why - even though they have no power over anything, and no understanding?”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 43,
          "number": 4101,
          "juz": 24,
          "numpage": 463
        },
        {
          "text": "Say: “God’s alone is [the power to bestow the right of] intercession: His [alone] is the dominion over the heavens and the earth; and, in the end, Unto Him you will all be brought back.”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 44,
          "number": 4102,
          "juz": 24,
          "numpage": 463
        },
        {
          "text": "And yet, whenever God alone is mentioned, the hearts of those who will not believe in the life to come contract with bitter aversion - whereas, when those [imaginary powers] are mentioned side by side with Him, lo, they rejoice!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 45,
          "number": 4103,
          "juz": 24,
          "numpage": 463
        },
        {
          "text": "Say: “O God! Originator of the heavens and the earth! Knower of all that is beyond the reach of a created being’s perception, as well as of all that can be witnessed by a creature’s senses or mind! It is Thou who wilt judge between Thy servants [on Resurrection Day] with regard to all on which they were wont to differ!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 46,
          "number": 4104,
          "juz": 24,
          "numpage": 463
        },
        {
          "text": "But if those who are bent on evildoing posses­sed all that is on earth, and twice as much, they would surely offer it as ransom from the awful suffering [that will befall them] on the Day of Resur­rection: for, something with which they had not reckoned before will [by then] have been made obvious to them by God;",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 47,
          "number": 4105,
          "juz": 24,
          "numpage": 463
        }
      ]
    },
    {
      "462": [
        {
          "text": "And who could be more wicked than he who invents lies about God? and gives the lie to the truth as soon as it has been placed before him? Is not hell the [proper] abode for all who deny the truth?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 32,
          "number": 4090,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "But he who brings the truth, and he who wholeheartedly accepts it as true - it is they, they, who are [truly] conscious of Him!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 33,
          "number": 4091,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "All that they have ever yearned for awaits them with their Sustainer: such will be the reward of the doers of good.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 34,
          "number": 4092,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "And to this end, God will efface from their record the worst that they ever did, and give them their reward in accordance with the best that they were doing [in life].",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 35,
          "number": 4093,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "IS NOT God enough for His servant? And yet, they would frighten thee with those [imaginary divine powers which they worship] beside Him! But he whom God lets go astray can never find any guide,",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 36,
          "number": 4094,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "whereas he whom God guides aright can never be led astray. Is not God almighty, an avenger of evil?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 37,
          "number": 4095,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "And thus it is [with most people]: if thou ask them, “Who is it that has created the heavens and the earth?” - they will surely answer, “God.” Say: “Have you, then, ever considered what it is that you invoke instead of God? If God wills that harm should befall me, could those [imaginary powers] remove the harm inflicted by Him? Or, if He wills that grace should alight on me, could they withhold His grace [from me]?” Say: “God is enough for me! In Him [alone] place their trust all who have trust [in His existence].”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 38,
          "number": 4096,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "Say: “O my [truth-denying] people! Do yet all that may be within your power, [whereas] I, behold, shall labour [in God’s way]: in time you will come to know",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 39,
          "number": 4097,
          "juz": 24,
          "numpage": 462
        },
        {
          "text": "who it is that shall be visited [in this world] by suffering which will cover him with ignominy, and upon whom long-lasting suffering shall alight [in the life to come]!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 40,
          "number": 4098,
          "juz": 24,
          "numpage": 462
        }
      ]
    },
    {
      "461": [
        {
          "text": "Could, then, one whose bosom God has opened wide with willingness towards self-surrender unto Him, so that he is illumined by a light [that flows] from his Sustainer, [be likened to the blind and deaf of heart]? Woe, then, unto those whose hearts are hardened against all remembrance of God! They are most obviously lost in error!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 22,
          "number": 4080,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "God bestows from on high the best of all teachings in the shape of a divine writ fully consistent within itself, repeating each statement [of the truth] in manifold forms [a divine writ] whereat shiver the skins of all who of their Sustainer stand in awe: [but] in the end their skins and their hearts do soften at the remembrance of [the grace of] God. Such is God’s guidance: He guides therewith him that wills [to be guided] whereas he whom God lets go astray can never find any guide",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 23,
          "number": 4081,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "Could, then, one who shall have nothing but is [bare] face to protect him from the awful suffering [that will befall him] on Resurrection Day [be likened to the God-conscious]? [On that Day,] the evildoers will be told: “Taste [now] what you have earned [in life]!”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 24,
          "number": 4082,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "Those who lived before them did [too] give the lie to the truth - whereupon suffering befell them without their having perceived whence it came:",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 25,
          "number": 4083,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "and thus God let them taste ignominy [even] in the life of this world. Yet [how] much greater will be the [sinners’] suffering in the life to come - if they [who now deny the truth] but knew it!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 26,
          "number": 4084,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "THUS, INDEED, have We propounded unto men all kinds of parables in this Qur’an, so that they might bethink themselves; [and We have revealed it]",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 27,
          "number": 4085,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "as a discourse in the Arabic tongue, free of all devious­ness, so that they might become conscious of God.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 28,
          "number": 4086,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "[To this end,] God sets forth a parable: A man who has for his masters several partners, [all of them] at variance with one another, and a man depending wholly on one person: can these two be deemed equal as regards their condition? [Nay,] all praise is due to God [alone]: but most of them do not understand this.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 29,
          "number": 4087,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "Yet, verily, thou art bound to die, [O Muhammad,] and, verily, they, too, are bound to die:",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 30,
          "number": 4088,
          "juz": 23,
          "numpage": 461
        },
        {
          "text": "and then, behold, on the Day of Resurrection you all shall place your dispute before your Sustainer.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 31,
          "number": 4089,
          "juz": 23,
          "numpage": 461
        }
      ]
    },
    {
      "460": [
        {
          "text": "Say [O Muhammad]: “Behold, I am bidden to worship God, sincere in my faith in Him alone;",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 11,
          "number": 4069,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "and I am bidden to be foremost among those who surrender themselves unto God.”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 12,
          "number": 4070,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "Say: “Behold, I would dread, were I to rebel against my Sustainer, the suffering [which would befall me] on that awesome Day [of Judgment].”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 13,
          "number": 4071,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "Say: “God alone do I worship, sincere in my faith in Him alone -",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 14,
          "number": 4072,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "and [it is up to you, O sinners, to] worship whatever you please instead of Him!” Say: “Behold, the [true] losers will be they who shall have lost their own selves and their kith and kin on Resurrection Day: for is not this, this, the [most] obvious loss?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 15,
          "number": 4073,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "Clouds of fire will they have above them, and [similar] clouds beneath them…” In this way does God imbue His servants with fear. O you servants of Mine! Be, then, conscious of Me -",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 16,
          "number": 4074,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "seeing that for those who shun the powers of evil lest they [be tempted to] worship them, and turn unto God instead, there is the glad tiding [of happiness in the life to come]. Give, then, this glad tiding to [those of] My ser­vants",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 17,
          "number": 4075,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "who listen [closely] to all that is said, and follow the best of it: [for] it is they whom God has graced with His guidance, and it is they who are [truly] endowed with insight!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 18,
          "number": 4076,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "On the other hand, could one on whom [God’s] sentence of suffering has been passed [be rescued by man]? Couldst thou, perchance, save one who is [already, as it were,] in the fire?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 19,
          "number": 4077,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "As against this they who of their Sustainer are conscious shall [in the life to come] have man­sions raised upon mansions high, beneath which run­ning waters flow: [this is] God’s promise - [and] never does God fail to fulfill His promise.",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 20,
          "number": 4078,
          "juz": 23,
          "numpage": 460
        },
        {
          "text": "ART THOU NOT aware that it is God who sends down water from the skies, and then causes it to travel through the earth in the shape of springs? And then He brings forth thereby herbage of various hues; and then it withers, and thou canst see it turn yellow; and in the end He causes it to crumble to dust. Verily, in [all] this there is indeed a reminder to those who are endowed with insight!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 21,
          "number": 4079,
          "juz": 23,
          "numpage": 460
        }
      ]
    },
    {
      "459": [
        {
          "text": "He has created you [all] out of one living entity, and out of it fashioned its mate; and he has be­stowed upon you four kinds of cattle of either sex; [and] He creates you in your mothers’ wombs, one act of creation after another, in threefold depths of darkness. Thus is God, your Sustainer: unto Him belongs all dominion: there is no deity save Him: how, then, can you lose sight of the truth?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 6,
          "number": 4064,
          "juz": 23,
          "numpage": 459
        },
        {
          "text": "If you are ingrate behold, God has no need of you; none the less, He does not approve of in­gratitude in His servants: whereas, if you show grati­tude, He approves it in you. And no bearer of burdens shall be made to bear another’s burden. In time, unto your Sustainer you all must return, and then He will make you [truly] understand all that you were doing [in life]: for, verily, He has full knowledge of what is in the hearts [of men].",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 7,
          "number": 4065,
          "juz": 23,
          "numpage": 459
        },
        {
          "text": "NOW [thus it is:] when affliction befalls man, he is likely to cry out to his Sustainer, turning unto Him [for help]; but as soon as He has bestowed upon him a boon by His grace, he forgets Him whom he in­voked before, and claims that there are other powers that could rival God - and thus leads [others] astray from His path. Say [unto him who sins in this way]: “Enjoy thyself for a while in this thy denial of the truth; [yet,] verily, thou art of those who are destined for the fire!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 8,
          "number": 4066,
          "juz": 23,
          "numpage": 459
        },
        {
          "text": "Or [dost thou deem thyself equal to] one who devoutly worships [God] throughout the night, prostrating himself or standing [in prayer], ever- mindful of the life to come, and hoping for his Sustainer’s grace?” Say: “Can they who know and they who do not know be deemed equal?” [But] only they who are endowed with insight keep this in mind!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 9,
          "number": 4067,
          "juz": 23,
          "numpage": 459
        },
        {
          "text": "Say: “[Thus speaks God:] ‘O you servants of Mine who have attained to faith! Be conscious of your Sustainer! Ultimate good awaits those who per­severe in doing good in this world. And [remember:] wide is God’s earth, [and,] verily, they who are patient in adversity will be given their reward in full, beyond all reckoning!’”",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 10,
          "number": 4068,
          "juz": 23,
          "numpage": 459
        }
      ]
    },
    {
      "458": [
        {
          "text": "[And God] said: “This, then, is the truth! And this truth do I state:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 84,
          "number": 4054,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "Most certainly will I fill hell with thee and such of them as shall follow thee, all together!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 85,
          "number": 4055,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "SAY [O Prophet]: “No reward whatever do I ask of you for this [message]; and I am not one of those who claim to be what they are not.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 86,
          "number": 4056,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "This [divine writ], behold, is no less than a reminder to all the worlds —",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 87,
          "number": 4057,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "and you will most certainly grasp its purport after a lapse of time!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 88,
          "number": 4058,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "THE BESTOWAL from on high of this divine writ issues from God, the Almighty, the Wise:",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 1,
          "number": 4059,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "for, behold, it is We who have bestowed this revela­tion upon thee from on high, setting forth the truth: so worship Him, sincere in thy faith in Him alone!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 2,
          "number": 4060,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "Is it not to God alone that all sincere faith is due? And yet, they who take for their protectors aught beside Him [are wont to say], “We worship them for no other reason than that they bring us nearer to God.” Behold, God will judge between them [on Resur­rection Day] with regard to all wherein they differ [from the truth]: for, verily, God does not grace with His guidance anyone who is bent on lying [to himself and is] stubbornly ingrate!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 3,
          "number": 4061,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "Had God willed to take Unto Himself a son, He could have chosen anyone that He wanted out of whatever He has created - [but] limitless is He in His glory! He is the One God, the One who holds absolute sway over all that exists!",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 4,
          "number": 4062,
          "juz": 23,
          "numpage": 458
        },
        {
          "text": "He it is who has created the heavens and the earth in accordance with [an inner] truth. He causes the night to flow into the day, and causes the day to flow into the night; and He has made the sun and the moon subservient [to His laws], each running its course for a term set [by Him]. Is not He the Almighty, the All-Forgiving?",
          "englishName": "Az-Zumar",
          "suraNumber": 39,
          "numberInSurah": 5,
          "number": 4063,
          "juz": 23,
          "numpage": 458
        }
      ]
    },
    {
      "457": [
        {
          "text": "And they will add: “How is it that we do not see [here any of the] men whom we were wont to count among the wicked,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 62,
          "number": 4032,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "[and] whom we made the target of our derision? Or is it that [they are here, and] our eyes have missed them?”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 63,
          "number": 4033,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Such, behold, will in truth be the [confusion and] mutual wrangling of the people of the fire!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 64,
          "number": 4034,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "SAY [O Muhammad]: “I am only a warner; and there is no deity whatever save God, the One, who holds absolute sway over all that exists,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 65,
          "number": 4035,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "the Sustainer of the heavens and the earth and all that is between them, the Almighty, the All-Forgiving!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 66,
          "number": 4036,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Say: “This is a message tremendous:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 67,
          "number": 4037,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "[how can] you turn away from it?”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 68,
          "number": 4038,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "[Say, O Muhammad:] “No knowledge would I have had of [what passed among] the host on high when they argued [against the creation of man],",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 69,
          "number": 4039,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "had it not been revealed unto me [by God] - to no other end than that I might convey [unto you] a plain warning.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 70,
          "number": 4040,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "[For,] lo, thy Sustainer said unto the angels: “Behold, I am about to create a human being out of clay;",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 71,
          "number": 4041,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "and when I have formed him fully and breathed into him of My spirit, fall you down before him in prostration!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 72,
          "number": 4042,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Thereupon the angels prostrated themselves, all of them together,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 73,
          "number": 4043,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "save Iblis: he gloried in his arrogance, and [thus] became one of those who deny the truth.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 74,
          "number": 4044,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Said He: “O Iblis! What has kept thee from prostrating thyself before that [being] which I have created with My hands? Art thou too proud [to bow down before another created being], or art thou of those who think [only] of themselves as high?”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 75,
          "number": 4045,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Answered [Iblis]: “I am better than he: Thou hast created me out of fire, whereas him Thou hast created out of clay.”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 76,
          "number": 4046,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Said He: “Go forth, then, from this [angelic state] - for, behold, thou art henceforth accursed,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 77,
          "number": 4047,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "and My rejection shall be thy due until the Day of Judgment!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 78,
          "number": 4048,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Said [Iblis]: “Then, O my Sustainer, grant me a respite till the Day when all shall be raised from the dead!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 79,
          "number": 4049,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "Answered He: “Verily, so [be it:] thou shalt be among those who are granted respite",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 80,
          "number": 4050,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "till the Day the time whereof is known [only to Me].”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 81,
          "number": 4051,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "[Whereupon Iblis] said: “Then [I swear] by Thy very might: I shall most certainly beguile them all into grievous error –",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 82,
          "number": 4052,
          "juz": 23,
          "numpage": 457
        },
        {
          "text": "[all] save such of them as are truly Thy servants!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 83,
          "number": 4053,
          "juz": 23,
          "numpage": 457
        }
      ]
    },
    {
      "456": [
        {
          "text": "And We bestowed upon him new offspring, doubling their number as an act of grace from Us, and as a reminder unto all who are endowed with insight.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 43,
          "number": 4013,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "[And finally We told him:] “Now take in thy hand a small bunch of grass, and strike therewith, and thou wilt not break thine oath!” for, verily, We found him full of patience in adversity: how excellent a servant [of Ours], who, behold, would always turn unto Us!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 44,
          "number": 4014,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "AND CALL to mind Our servants Abraham and Isaac and Jacob, [all of them] endowed with inner strength and vision:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 45,
          "number": 4015,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "for, verily, We purified them by means of a thought most pure: the remembrance of the life to come.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 46,
          "number": 4016,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "And, behold, in Our sight they were indeed among the elect, the truly good!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 47,
          "number": 4017,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "And call to mind Ishmael and Elisha, and every one who [like them] has pledged himself [unto Us]: for, each of them was of the truly good!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 48,
          "number": 4018,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "LET [all] this be a reminder [to those who believe in God] - for, verily, the most beauteous of all goals awaits the God-conscious:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 49,
          "number": 4019,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "gardens of perpetual bliss, with gates wide-open to them,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 50,
          "number": 4020,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "wherein they will recline, [and] wherein they may [freely] call for many a fruit and drink,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 51,
          "number": 4021,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "having beside them well-matched mates of modest gaze.”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 52,
          "number": 4022,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "This is what you are promised for the Day of Reckoning:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 53,
          "number": 4023,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "this, verily, shall be Our provision [for you], with no end to it!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 54,
          "number": 4024,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "All this [for the righteous]: but, verily, the most evil of all goals awaits those who are wont to transgress the bounds of what is right:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 55,
          "number": 4025,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "hell will they have to endure - and how vile a resting-place!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 56,
          "number": 4026,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "This, [then, for them -] so let them taste it: burning despair and ice-cold darkness",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 57,
          "number": 4027,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "and, coupled with it, further [suffering] of a similar nature.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 58,
          "number": 4028,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "[And they will say to one another: “Do you see] this crowd of people who rushed headlong [into sin] with you? No welcome to them! Verily, they [too] shall have to endure the fire!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 59,
          "number": 4029,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "[And] they [who had been seduced] will exclaim: “Nay, but it is you! No welcome to you! It is you who have prepared this for us: and how vile a state to abide in!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 60,
          "number": 4030,
          "juz": 23,
          "numpage": 456
        },
        {
          "text": "[And] they will pray: “O our Sustainer! Who­ever has prepared this for us, double Thou his suffering in the fire!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 61,
          "number": 4031,
          "juz": 23,
          "numpage": 456
        }
      ]
    },
    {
      "455": [
        {
          "text": "AND [thus it is:] We have not created heaven and earth and all that is between them without meaning and purpose, as is the surmise of those who are bent on denying the truth: but then, woe from the fire [of hell] unto all who are bent on denying the truth!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 27,
          "number": 3997,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "[For,] would We treat those who have attained to faith and do righteous deeds in the same manner as [We shall treat] those who spread corruption on earth? Would We treat the God-conscious in the same manner as the wicked?",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 28,
          "number": 3998,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "[All this have We expounded in this] blessed divine writ which We have revealed unto thee, [O Muhammad,] so that men may ponder over its mes­sages, and that those who are endowed with insight may take them to heart.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 29,
          "number": 3999,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "AND UNTO DAVID We granted Solomon [as a son - and] how excellent a servant [of Ours he grew up to be]! Behold, he would always turn unto Us -",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 30,
          "number": 4000,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "[and even] when, towards the close of day, nobly-bred, swift-footed steeds were brought before him,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 31,
          "number": 4001,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "he would say, “Verily, I have come to love the love of all that is good because I bear my Sustainer in mind!” [repeating these words as the steeds raced away,] until they were hidden by the veil [of distance - whereupon he would command],",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 32,
          "number": 4002,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "“Bring them back unto me!”- and would [lovingly] stroke their legs and their necks.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 33,
          "number": 4003,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "But [ere this], indeed, We had tried Solomon by placing upon his throne a [lifeless] body; and thereupon he turned [towards Us; and]",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 34,
          "number": 4004,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "he prayed: “O my Sustainer! Forgive me my sins, and bestow upon me the gift of a kingdom which may not suit anyone after me: verily, Thou alone art a giver of gifts!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 35,
          "number": 4005,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "And so We made subservient to him the wind, so that it gently sped at his behest whithersoever he willed,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 36,
          "number": 4006,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "as well as all the rebellious forces [that We made to work for him] - every kind of builder and diver -",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 37,
          "number": 4007,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "and others linked together in fetters.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 38,
          "number": 4008,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "[And We told him:] “This is Our gift, for thee to bestow freely on others, or to withhold, without [having to render] account!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 39,
          "number": 4009,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "And, verily, nearness to Us awaits him [in the life to come], and the most beauteous of all goals!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 40,
          "number": 4010,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "AND CALL to mind Our servant Job, [how it was] when he cried out to his Sustainer, “Behold, Satan has afflicted me with [utter] weariness and suffering!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 41,
          "number": 4011,
          "juz": 23,
          "numpage": 455
        },
        {
          "text": "[and thereupon was told:] “Strike [the ground] with thy foot: here is cool water to wash with and to drink!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 42,
          "number": 4012,
          "juz": 23,
          "numpage": 455
        }
      ]
    },
    {
      "454": [
        {
          "text": "[But] bear thou with patience whatever they may say, and remember Our servant David, him who was endowed with [so much] inner strength! He, verily, would always turn unto Us:",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 17,
          "number": 3987,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "[and for this,] behold, We caused the mountains to join him in extolling Our limitless glory at eventide and at sun­rise,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 18,
          "number": 3988,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "and [likewise] the birds in their assemblies: [together] they all Would turn again and again unto Him [who had created them].",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 19,
          "number": 3989,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "And We streng­thened his dominion, and bestowed upon him wisdom and sagacity in judgment.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 20,
          "number": 3990,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "AND YET, has the story of the litigants come within thy ken - [the story of the two] who surmounted the walls of the sanctuary [in which David prayed]?",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 21,
          "number": 3991,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "As they came upon David, and he shrank back in fear from them, they said: “Fear not! [We are but] two litigants. One of us has wronged the other: so judge thou between us with justice, and deviate not from what is right, and show [both of] us the way to rectitude.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 22,
          "number": 3992,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "“Behold, this is my brother: he has ninety-nine ewes, whereas I have [only] one ewe - and yet he said, ‘Make her over to me,’ and forcibly prevailed against me in this [our] dispute.”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 23,
          "number": 3993,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "Said [David]: “He has certainly wronged thee by demanding that thy ewe be added to his ewes! Thus, behold, do many kinsmen wrong one another [all] save those who believe [in God] and do righteous deeds: but how few are they!” And [suddenly] David understood that We had tried him: and so he asked his Sustainer to forgive him his sin, and fell down in prostration, and turned unto Him in repentance.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 24,
          "number": 3994,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "And thereupon We forgave him that [sin]: and, verily, nearness to Us awaits him [in the life to come], and the most beauteous of all goals!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 25,
          "number": 3995,
          "juz": 23,
          "numpage": 454
        },
        {
          "text": "[And We said:] “O David! Behold, We have made thee a [prophet and, thus, Our] vicegerent on earth: judge, then, between men with justice, and do not follow vain desire, lest it lead thee astray from the path of God: verily, for those who go astray from the path of God there is suffering severe in store for having forgotten the Day of Reckoning!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 26,
          "number": 3996,
          "juz": 23,
          "numpage": 454
        }
      ]
    },
    {
      "453": [
        {
          "text": "Sad. CONSIDER this Qur’an, endowed with all that one ought to remember!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 1,
          "number": 3971,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "But nay - they who are bent on denying the truth are lost in [false] pride, and [hence] deeply in the wrong.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 2,
          "number": 3972,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "How many a generation have We destroyed before their time [for this very sin]! And [how] they called [unto Us] when it was too late to escape!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 3,
          "number": 3973,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "Now these [people] deem it strange that a warner should have come unto them from their own midst - and [so] the deniers of the truth are saying: “A [mere] spellbinder is he, a liar!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 4,
          "number": 3974,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "Does he claim that all the deities are [but] one God? Verily, a most strange thing is this!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 5,
          "number": 3975,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "And their leaders launch forth [thus]: “Go ahead, and hold steadfastly onto your deities: this, behold, is the only thing to do!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 6,
          "number": 3976,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "Never did we hear of [a claim like] this in any faith of latter days! It is nothing but [a mortal man’s] invention!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 7,
          "number": 3977,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "What! Upon him alone from among all of us should a [divine] reminder have been bestowed from on high?” Nay, but it is My Own reminder that they distrust! Nay, they have not yet tasted the suffering which I do impose!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 8,
          "number": 3978,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "Or do they [think that they] own the treasures of thy Sustainer’s grace - [the grace] of the Almighty, the Giver of Gifts?",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 9,
          "number": 3979,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "Or [that] the dominion over the heavens and the earth and all that is between them is theirs? Why, then, let them try to ascend [to God-like power] by all [conceivable] means!",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 10,
          "number": 3980,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "[But] there it is: any and all human beings, however [strongly] leagued together, are bound to suffer defeat [whenever they refuse to accept the truth].",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 11,
          "number": 3981,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "To the truth gave the lie aforetime Noah’s people, and [the tribe of] Ad, and Pharaoh of the [many] tent-poles,",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 12,
          "number": 3982,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "and [the tribe of] Thamud, and the people of Lot, and the dwellers of the wooded dales [of Madyan]: they all were leagued together, [as it were, in their unbelief:]",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 13,
          "number": 3983,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "not one [was there] but gave the lie to the apostles - and thereupon My retribution fell due.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 14,
          "number": 3984,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "And they [who now deny the truth - they, too,] have but to wait for one single blast [of punishment to overtake them]: it shall not be delayed a whit.",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 15,
          "number": 3985,
          "juz": 23,
          "numpage": 453
        },
        {
          "text": "As it is, they say [mockingly]: “O our Sus­tainer! Hasten on to us our share [of punishment even] before the Day of Reckoning!”",
          "englishName": "Saad",
          "suraNumber": 38,
          "numberInSurah": 16,
          "number": 3986,
          "juz": 23,
          "numpage": 453
        }
      ]
    },
    {
      "452": [
        {
          "text": "What is amiss with you and your judgment?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 154,
          "number": 3942,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Will you not, then, bethink yourselves?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 155,
          "number": 3943,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Or have you, perchance, a clear evidence [for your assertions]?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 156,
          "number": 3944,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Produce, then, that divine writ of yours, if you are speaking the truth!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 157,
          "number": 3945,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "And some people have invented a kinship between Him and all manner of invisible forces although [even] these invisible forces know well that, verily, they [who thus blaspheme against God] shall indeed be arraigned [before Him on Judgment Day: for]",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 158,
          "number": 3946,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "limitless is God in His glory, above anything that men may devise by way of definition!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 159,
          "number": 3947,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Not thus, however, [behave] God’s true ser­vants:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 160,
          "number": 3948,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "for, verily, neither you [blasphemers] nor the objects of your worship",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 161,
          "number": 3949,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "can cause anyone to fall prey to your temptation",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 162,
          "number": 3950,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "unless it be such as rushes towards the blazing fire [of his own accord]!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 163,
          "number": 3951,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "[All forces of nature praise God and say:] “Among us, too, there is none but has a place assigned to it [by Him];",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 164,
          "number": 3952,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "and, verily, we too are ranged [before Him in worship];",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 165,
          "number": 3953,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "and, verily, we too extol His limitless glory!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 166,
          "number": 3954,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "AND, INDEED, they [who deny the truth] have always been wont to say,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 167,
          "number": 3955,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "“If only we had a tradition [to this effect] from our forebears,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 168,
          "number": 3956,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "we would certainly be true servants of God.”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 169,
          "number": 3957,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "And yet, [now that this divine writ has been placed before them,] they refuse to acknowledge it as true! In time, however, they will come to know [what it was that they had rejected]:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 170,
          "number": 3958,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "for, long ago has Our word gone forth unto Our servants, the message- bearers,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 171,
          "number": 3959,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "that, verily, they - they indeed - would be succoured,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 172,
          "number": 3960,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "and that, verily, Our hosts - they indeed - would [in the end] be victorious!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 173,
          "number": 3961,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Hence, turn thou aside for a while from those [who deny the truth],",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 174,
          "number": 3962,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "and see them [for what they are]; and in time they [too] will come to see [what they do not see now].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 175,
          "number": 3963,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Do they, then, [really] wish that Our chas­tisement be hastened on?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 176,
          "number": 3964,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "But then, once it alights upon them, hapless will be the awakening of those who were warned [to no avail]!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 177,
          "number": 3965,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "Hence, turn thou aside for a while from them,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 178,
          "number": 3966,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "and see [them for what they are]; and n time they [too] will come to see [what they do not see now].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 179,
          "number": 3967,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "LIMITLESS in His glory is thy Sustainer, the Lord of almightiness, [exalted] above anything that men may devise by way of definition!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 180,
          "number": 3968,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "And peace be upon all His message-bearers!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 181,
          "number": 3969,
          "juz": 23,
          "numpage": 452
        },
        {
          "text": "And all praise is due to God alone, the Sus­tainer of all the worlds!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 182,
          "number": 3970,
          "juz": 23,
          "numpage": 452
        }
      ]
    },
    {
      "451": [
        {
          "text": "But they gave him the lie: and therefore they will most surely be arraigned [on Judgment Day],",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 127,
          "number": 3915,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "excepting only [those who were] God’s true servants;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 128,
          "number": 3916,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "and him We left thus to be remem­bered among later generations:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 129,
          "number": 3917,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "“Peace be upon Elijah and his followers!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 130,
          "number": 3918,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "Verily, thus do We reward the doers of good –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 131,
          "number": 3919,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "for he was truly one of Our believing servants!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 132,
          "number": 3920,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "AND, BEHOLD, Lot was indeed one of Our mes­sage-bearers;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 133,
          "number": 3921,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "[and so,] when [We decreed the doom of his sinful town,] We saved him and his household,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 134,
          "number": 3922,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "except an old woman who was among those that stayed behind;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 135,
          "number": 3923,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "and then We utterly destroyed the others:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 136,
          "number": 3924,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "and, verily, [to this day] you pass by the remnants of their dwellings at morning-time",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 137,
          "number": 3925,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "and by night. Will you not, then, use your reason?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 138,
          "number": 3926,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "AND, BEHOLD, Jonah was indeed one of Our mes­sage-bearers",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 139,
          "number": 3927,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "when he fled like a runaway slave onto a laden ship.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 140,
          "number": 3928,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "And then they cast lots, and he was the one who lost;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 141,
          "number": 3929,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "[and they cast him into the sea,] whereupon the great fish swallowed him, for he had been blameworthy.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 142,
          "number": 3930,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "And had he not been of those who [even in the deep darkness of their distress are able to] extol God’s limitless glory,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 143,
          "number": 3931,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "he would indeed have remained in its belly till the Day when all shall be raised from the dead:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 144,
          "number": 3932,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "but We caused him to be cast forth on a desert shore, sick [at heart] as he was,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 145,
          "number": 3933,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "and caused a creeping plant to grow over him [out of the barren soil].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 146,
          "number": 3934,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "And [then] We sent him [once again] to [his people,] a hundred thousand [souls] or more:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 147,
          "number": 3935,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "and [this time] they believed [in him] and so We allowed them to enjoy their life during the time allotted to them?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 148,
          "number": 3936,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "AND NOW ask them to enlighten thee: Has thy Sustainer daughters, whereas they would have [only] sons?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 149,
          "number": 3937,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "- or is it that We have created the angels female, and they [who believe them to be divine] have witnessed [that act of creation]?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 150,
          "number": 3938,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "Oh, verily, it is out of their own [inclination to] falsehood that some people assert,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 151,
          "number": 3939,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "“God has begotten [a son]”; and, verily, they are lying [too, when they say],",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 152,
          "number": 3940,
          "juz": 23,
          "numpage": 451
        },
        {
          "text": "“He has chosen daughters in preference to sons”!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 153,
          "number": 3941,
          "juz": 23,
          "numpage": 451
        }
      ]
    },
    {
      "450": [
        {
          "text": "But as soon as the two had surrendered themselves to [what they thought to be] the will of God, and [Abraham] had laid him down on his face,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 103,
          "number": 3891,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "We called out to him: “O Abraham,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 104,
          "number": 3892,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "thou hast already fulfilled [the purpose of] that dream- vision!” Thus, verily, do We reward the doers of good:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 105,
          "number": 3893,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "for, behold, all this was indeed a trial, clear in itself.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 106,
          "number": 3894,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "And We ransomed him with a tremendous sacrifice,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 107,
          "number": 3895,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "and left him thus to be remembered among later generations:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 108,
          "number": 3896,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "“Peace be upon Abraham!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 109,
          "number": 3897,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "Thus do We reward the doers of good –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 110,
          "number": 3898,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "for he was truly one of our believing servants.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 111,
          "number": 3899,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "And [in time] We gave him the glad tiding of Isaac, [who, too, would be] a prophet, one of the righteous;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 112,
          "number": 3900,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "and We blessed him and Isaac: but among the offspring of these two there were [des­tined] to be both doers of good and such as would glaringly sin against themselves.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 113,
          "number": 3901,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "THUS, INDEED, did We bestow Our favour upon Moses and Aaron;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 114,
          "number": 3902,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "and We saved them and their people from the awesome calamity [of bondage],",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 115,
          "number": 3903,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "and succoured them, so that [in the end] it was they who achieved victory.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 116,
          "number": 3904,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "And We gave them the divine writ that made [right and wrong] distinct,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 117,
          "number": 3905,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "and guided them the straight way,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 118,
          "number": 3906,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "and left them thus to be remem­bered among later generations:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 119,
          "number": 3907,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "“Peace be upon Moses and Aaron!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 120,
          "number": 3908,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "Thus do We reward the doers of good –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 121,
          "number": 3909,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "for those two were truly among Our believing ser­vants.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 122,
          "number": 3910,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "AND, BEHOLD, Elijah [too] was indeed one of Our message-bearers",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 123,
          "number": 3911,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "when he spoke [thus] to his people: “Will you not remain conscious of God?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 124,
          "number": 3912,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "Will you invoke Baal and forsake [God,] the best of artisans -",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 125,
          "number": 3913,
          "juz": 23,
          "numpage": 450
        },
        {
          "text": "God, your Sustainer and the Sustainer of your forebears of old?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 126,
          "number": 3914,
          "juz": 23,
          "numpage": 450
        }
      ]
    },
    {
      "449": [
        {
          "text": "and caused his offspring to endure [on earth];",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 77,
          "number": 3865,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "and We left him thus to be remembered among later generations:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 78,
          "number": 3866,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "“Peace be upon Noah throughout all the worlds!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 79,
          "number": 3867,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "Verily, thus do We reward the doers of good –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 80,
          "number": 3868,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "for he was truly one of our believing servants:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 81,
          "number": 3869,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "[and so We saved him and those who followed him] and then We caused the others to drown.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 82,
          "number": 3870,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "AND, BEHOLD, of his persuasion was Abraham, too,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 83,
          "number": 3871,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "when he turned to his Sustainer with a heart free of evil,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 84,
          "number": 3872,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "and [thus] spoke to his father and his people: “What is it that you worship?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 85,
          "number": 3873,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "Do you want [to bow down before] a lie - [before] deities other than God?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 86,
          "number": 3874,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "What, then, do you think of the Sustainer of all the worlds?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 87,
          "number": 3875,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "Then he cast a glance at the stars,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 88,
          "number": 3876,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "and said, “Verily, I am sick [at heart]!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 89,
          "number": 3877,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "and at that they turned their backs on him and went away.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 90,
          "number": 3878,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "Thereupon he approached their gods stealthily and said, “What! You do not eat [of the offerings placed before you]?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 91,
          "number": 3879,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "What is amiss with you that you do not speak?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 92,
          "number": 3880,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "And then he fell upon them, smiting them with his right hand.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 93,
          "number": 3881,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "[But] then the others came towards him hur­riedly [and accused him of his deed].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 94,
          "number": 3882,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "He answered: “Do you worship something that you [yourselves] have carved,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 95,
          "number": 3883,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "the while it is God who has created you and all your handiwork?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 96,
          "number": 3884,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "They exclaimed: “Build a pyre for him, and cast him into the blazing fire!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 97,
          "number": 3885,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "But whereas they sought to do evil unto him, We [frustrated their designs, and thus] brought them low?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 98,
          "number": 3886,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "And [Abraham] said: “Verily, I shall [leave this land and] go wherever my Sustainer will guide me!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 99,
          "number": 3887,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "[And he prayed:] “O my Sustainer! Bestow upon me the gift of [a son who shall be] one of the righteous!” –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 100,
          "number": 3888,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "whereupon We gave him the glad tiding of a boy-child gentle [like himself]?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 101,
          "number": 3889,
          "juz": 23,
          "numpage": 449
        },
        {
          "text": "And [one day,] when [the child] had become old enough to share in his [father’s] endeavours, the latter said: “O my dear son! I have seen in a dream that I should sacrifice thee: consider, then, what would be thy view!” [Ishmael] answered: “O my father! Do as thou art bidden: thou wilt find me, if God so wills, among those who are patient in adversity!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 102,
          "number": 3890,
          "juz": 23,
          "numpage": 449
        }
      ]
    },
    {
      "448": [
        {
          "text": "who was wont to ask [me], ‘Why - art thou really one of those who believe it to be true",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 52,
          "number": 3840,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "[that] after we have died and become mere dust and bones we shall, forsooth, be brought to judgment?”’",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 53,
          "number": 3841,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "[And] he adds: “Would you like to look [and see him]?” –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 54,
          "number": 3842,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "and then he looks and sees that [companion of his] in the midst of the blazing fire,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 55,
          "number": 3843,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "and says: “By God! Verily, thou hast almost destroyed me [too, O my erstwhile companion]",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 56,
          "number": 3844,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "for had it not been for my Sustainer’s favour, I would surely be [now] among those who are given over [to suffering]!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 57,
          "number": 3845,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "But then, [O my friends in paradise,] is it [really] so that we are not to die",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 58,
          "number": 3846,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "[again,] beyond our previous death, and that we shall never [again] be made to suffer?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 59,
          "number": 3847,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "Verily, this - this indeed - is the triumph supreme!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 60,
          "number": 3848,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "For the like of this, then, let them labour, those who labour [in God’s way]!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 61,
          "number": 3849,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "Is such [a paradise] the better welcome - or the [hellish] tree of deadly fruit?",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 62,
          "number": 3850,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "Verily, We have caused it to be a trial for evildoers:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 63,
          "number": 3851,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "for, behold, it is a tree that grows in the very heart of the blazing fire [of hell],",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 64,
          "number": 3852,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "its fruit [as repulsive] as satans’ heads;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 65,
          "number": 3853,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "and they [who are lost in evil] are indeed bound to eat thereof, and to fill their bellies therewith.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 66,
          "number": 3854,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "And, behold, above all this they will be confounded with burning des­pair!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 67,
          "number": 3855,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "And once again: Verily, the blazing fire is their ultimate goal –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 68,
          "number": 3856,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "for, behold, they found their forebears on a wrong way,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 69,
          "number": 3857,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "and [now] they make haste to follow in their footsteps!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 70,
          "number": 3858,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "Thus, indeed, most of the people of old went astray before them,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 71,
          "number": 3859,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "although, verily, We had sent warners unto them:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 72,
          "number": 3860,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "and behold what happened in the end to those that had been warned [to no avail]!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 73,
          "number": 3861,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "EXCEPT for God’s true servants, [most people are apt to go astray.]",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 74,
          "number": 3862,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "And, indeed, [it was for this reason that] Noah cried unto Us - and how excellent was Our response:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 75,
          "number": 3863,
          "juz": 23,
          "numpage": 448
        },
        {
          "text": "for We saved him and his household from that awesome calamity,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 76,
          "number": 3864,
          "juz": 23,
          "numpage": 448
        }
      ]
    },
    {
      "447": [
        {
          "text": "“How is it that [now] you cannot succour one another?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 25,
          "number": 3813,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Nay, but on that Day they would willingly surrender [to God];",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 26,
          "number": 3814,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "but [since it will be too late,] they will turn upon one another, demanding of each other [to relieve them of the burden of their past sins].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 27,
          "number": 3815,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Some [of them] will say: “Behold, you were wont to approach us [deceptively] from the right!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 28,
          "number": 3816,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "[To which] the others will reply: “Nay, you yourselves were bereft of all faith!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 29,
          "number": 3817,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Moreover, we had no power at all over you: nay, you were people filled with overweening arrogance!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 30,
          "number": 3818,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "But now our Sustainer’s word has come true against us [as well]: verily, we are bound to taste [the fruit of our sins].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 31,
          "number": 3819,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "So then, [if it be true that] we have caused you to err grievously - behold, we ourselves had been lost in grievous error!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 32,
          "number": 3820,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "And, verily, on that Day they all will share in their common suffering.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 33,
          "number": 3821,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Verily, thus shall We deal with all who were lost in sin:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 34,
          "number": 3822,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "for, behold, whenever they were told, “There is no deity save God,” they would glory in their arrogance",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 35,
          "number": 3823,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "and would say, “Shall we, then, give up our deities at the bidding of a mad poet?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 36,
          "number": 3824,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Nay, but he [whom you call a mad poet] has brought the truth; and he confirms the truth of [what the earlier of God’s] message-bearers [have taught].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 37,
          "number": 3825,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Behold, you will indeed taste grievous suffering [in the life to come],",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 38,
          "number": 3826,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "although you shall not be requited for aught but what you were wont to do.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 39,
          "number": 3827,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "Not so, however, God’s true servants:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 40,
          "number": 3828,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "[in the hereafter,] theirs shall be a sustenance which they will recognize",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 41,
          "number": 3829,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "as the fruits [of their life on earth]; and honoured shall they be",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 42,
          "number": 3830,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "in gardens of bliss,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 43,
          "number": 3831,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "facing one another [in love] upon thrones of happiness.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 44,
          "number": 3832,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "A cup will be passed round among them [with a drink] from unsullied springs,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 45,
          "number": 3833,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "clear, delightful to those who drink it:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 46,
          "number": 3834,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "no headiness will be in it, and they will not get drunk thereon.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 47,
          "number": 3835,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "And with them will be mates of modest gaze, most beautiful of eye,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 48,
          "number": 3836,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "[as free of faults] as if they were hidden [ostrich] eggs.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 49,
          "number": 3837,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "And they will all turn to one another, asking each other [about their past lives].",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 50,
          "number": 3838,
          "juz": 23,
          "numpage": 447
        },
        {
          "text": "One of them speaks thus: “Behold, I had [on earth] a close companion",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 51,
          "number": 3839,
          "juz": 23,
          "numpage": 447
        }
      ]
    },
    {
      "446": [
        {
          "text": "CONSIDER these [messages] ranged in serried ranks,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 1,
          "number": 3789,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and restraining [from evil] by a call to restraint,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 2,
          "number": 3790,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and conveying [to all the world] a reminder:",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 3,
          "number": 3791,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "Verily, most surely, your God is One –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 4,
          "number": 3792,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "the Sustainer of the heavens and the earth and of all that is between them, and the Sustainer of all the points of sunrise!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 5,
          "number": 3793,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "Behold, We have adorned the skies nearest to the earth with the beauty of stars,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 6,
          "number": 3794,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and have made them secure against every rebellious, satanic force,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 7,
          "number": 3795,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "[so that] they [who seek to learn the unknowable] should not be able to overhear the host on high, but shall be repelled from all sides,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 8,
          "number": 3796,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "cast out [from all grace], with lasting suffering in store for them [in the life to come];",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 9,
          "number": 3797,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "but if anyone does succeed in snatching a glimpse [of such knowledge], he is [henceforth] pursued by a piercing flame.",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 10,
          "number": 3798,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "AND NOW ask those [who deny the truth] to enlight­en thee: Were they more difficult to create than all those [untold marvels] that We have created? - for, behold, them have We created out of [mere] clay commingled with water!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 11,
          "number": 3799,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "Nay, but whereas thou dost marvel, they [only] scoff;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 12,
          "number": 3800,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and when they are reminded [of the truth], they refuse to take it to heart;",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 13,
          "number": 3801,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and when they become aware of a [divine] message, they turn it to ridicule",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 14,
          "number": 3802,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and say: “This is clearly nothing but [a mortal’s] spellbinding eloquence!",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 15,
          "number": 3803,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "Why - after we have died and become mere dust and bones, shall we, forsooth, be raised from the dead? –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 16,
          "number": 3804,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and perhaps also our forebears of old?”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 17,
          "number": 3805,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "Say: “Yea, indeed - and most abject will you then be!” –",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 18,
          "number": 3806,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "for that [resurrection which they deride] will be [upon them of a sudden, as if it were] but a single accusing cry - and then, lo! they will begin to see [the truth]",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 19,
          "number": 3807,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and will say: “Oh, woe unto us! This is the Day of Judgment!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 20,
          "number": 3808,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "[And they will be told:] “This is the Day of Distinction [between the true and the false - the Day] which you were wont to call a lie!”",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 21,
          "number": 3809,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "[And God will thus command:] “Assemble all those who were bent on evildoing, together with others of their ilk and [with] all that they were wont to worship",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 22,
          "number": 3810,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "instead of God, and lead them all onto the way to the blazing fire,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 23,
          "number": 3811,
          "juz": 23,
          "numpage": 446
        },
        {
          "text": "and halt them [there]!” [And then,] behold, they shall be asked,",
          "englishName": "As-Saaffaat",
          "suraNumber": 37,
          "numberInSurah": 24,
          "number": 3812,
          "juz": 23,
          "numpage": 446
        }
      ]
    },
    {
      "445": [
        {
          "text": "Are they, then, not aware that it is for them that We have created, among all the things which Our hands have wrought, the domestic animals of which they are [now] masters? –",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 71,
          "number": 3776,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "and that We have subjected them to men’s will, so that some of them they may use for riding and of some they may eat,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 72,
          "number": 3777,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "and may have [yet other] benefits from them, and [milk] to drink? Will they not, then, be grateful? (",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 73,
          "number": 3778,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "But [nay,] they take to worshipping deities other than God, [hoping] to be succoured [by them, and not knowing that]",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 74,
          "number": 3779,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "they are unable to succour their devotees, even though to them they may [appear to] be hosts drawn up [for succour].",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 75,
          "number": 3780,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "However, be not grieved by the sayings of those [who deny the truth]: verily, We know all that they keep secret as well as all that they bring into the open.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 76,
          "number": 3781,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "IS MAN, then, not aware that it is We who create him out of a [mere] drop of sperm - whereupon, lo! he shows himself endowed with the power to think and to argue?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 77,
          "number": 3782,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "And [now] he [argues about Us, and] thinks of Us in terms of comparison, and is oblivious of how he himself was created! [And so] he says, “Who could give life to bones that have crumbled to dust?”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 78,
          "number": 3783,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "Say: “He who brought them into being in the first instance will give them life [once again], seeing that He has full knowledge of every act of creation:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 79,
          "number": 3784,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "He who produces for you fire out of the green tree, so that, lo! you kindle [your fires] therewith.”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 80,
          "number": 3785,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "Is, then, He who has created the heavens and the earth not able to create [anew] the like of those [who have died]? Yea, indeed - for He alone is the all-knowing Crea­tor:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 81,
          "number": 3786,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "His Being alone is such that when He wills a thing to be, He but says unto it, “Be” — and it is.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 82,
          "number": 3787,
          "juz": 23,
          "numpage": 445
        },
        {
          "text": "Limitless, then, in His glory is He in whose hand rests the mighty dominion over all things; and unto Him you all will be brought back!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 83,
          "number": 3788,
          "juz": 23,
          "numpage": 445
        }
      ]
    },
    {
      "444": [
        {
          "text": "“Behold, those who are destined for paradise shall today have joy in whatever they do:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 55,
          "number": 3760,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "in happiness will they and their spouses on couches recline;",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 56,
          "number": 3761,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "[only] delight will there be for them, and theirs shall be all that they could ask for:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 57,
          "number": 3762,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "peace and fulfillment through the word of a Sustainer who dispenses all grace.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 58,
          "number": 3763,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "“But stand aside today, O you who were lost in sin!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 59,
          "number": 3764,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "Did I not enjoin on you, O you children of Adam, that you should not worship Satan – since, verily, he is your open foe",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 60,
          "number": 3765,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "and that you should worship Me [alone]? This would have been a straight way!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 61,
          "number": 3766,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "And [as for Satan -] he had already led astray a great many of you: could you not, then, use your reason?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 62,
          "number": 3767,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "“This, then, is the hell of which you were warned again and again:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 63,
          "number": 3768,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "endure it today as an outcome of your persistent denial of the truth!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 64,
          "number": 3769,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "On that Day We shall set a seal on their mouths but their hands will speak unto Us, and their feet will bear witness to whatever they have earned [in life].",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 65,
          "number": 3770,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "NOW HAD IT BEEN Our will [that men should not be able to discern between right and wrong], We could surely have deprived them of their sight, so that they would stray forever from the [right] way: for how could they have had insight [into what is true]?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 66,
          "number": 3771,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "And had it been Our will [that they should not be free to choose between right and wrong], We could surely have given them a different nature [and created them as beings rooted] in their places, so that they would not be able to move forward, and could not turn back.]",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 67,
          "number": 3772,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "But [let them always remember that] if We lengthen a human being’s days, We also cause him to decline in his powers [when he grows old]: will they not, then, use their reason?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 68,
          "number": 3773,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "AND [thus it is:] We have not imparted to this [Prophet the gift of] poetry, nor would [poetry] have suited this [message]: it is but a reminder and a [divine] discourse, clear in itself and clearly showing the truth,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 69,
          "number": 3774,
          "juz": 23,
          "numpage": 444
        },
        {
          "text": "to the end that it may warn everyone who is alive [of heart], and that the word [of God] may bear witness against all who deny the truth.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 70,
          "number": 3775,
          "juz": 23,
          "numpage": 444
        }
      ]
    },
    {
      "443": [
        {
          "text": "And [it ought to be] a sign for them that We bear their offspring [over the seas] in laden ships,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 41,
          "number": 3746,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "and [that] We create for them things of a similar kind, on which they may embark [in their travels];",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 42,
          "number": 3747,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "and [that,] if such be Our will, We may cause them to drown, with none to respond to their cry for help: and [then] they cannot be saved,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 43,
          "number": 3748,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "unless it be by an act of mercy from Us and a grant of life for a [further span of] time.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 44,
          "number": 3749,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "And [yet,] when they are told, “Beware of [God’s insight into] all that lies open before you and all that is hidden from you, so that you might be graced with His mercy,” [most men choose to remain deaf;]",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 45,
          "number": 3750,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "and no message of their Sustainer s messages ever reaches them without their turning away from it.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 46,
          "number": 3751,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "Thus, when they are told, “Spend on others out of what God has provided for you as sus­tenance,” those who are bent on denying the truth say unto those who believe, “Shall we feed anyone whom, if [your] God had so willed, He could have fed [Himself]? Clearly, you are but lost in error!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 47,
          "number": 3752,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "And they add, “When is this promise [of resurrection] to be fulfilled? [Answer this] if you are men of truth!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 48,
          "number": 3753,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "[And they are unaware that] nothing awaits them beyond a single blast [of God’s punishment], which will overtake them while they are still arguing - [against resurrection]:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 49,
          "number": 3754,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "and so [sudden will be their end that] no testament will they be able to make, - nor to their own people will they return!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 50,
          "number": 3755,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "And [then] the trumpet [of resurrection] will be blown - and lo! out of their graves towards their Sustainer will they all rush forth!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 51,
          "number": 3756,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "They will say: “Oh, woe unto us! Who has roused us from our sleep [of death]?” [Whereupon they will be told:] “This is what the Most Gracious has promised! And His message-bearers spoke the truth!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 52,
          "number": 3757,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "Nothing will there have been but one single blast - and lo! before Us will all of them be arraigned [and be told]:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 53,
          "number": 3758,
          "juz": 23,
          "numpage": 443
        },
        {
          "text": "“Today, then, no human being shall be wronged in the least, nor shalt you be requited for aught but what you were doing [on earth].",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 54,
          "number": 3759,
          "juz": 23,
          "numpage": 443
        }
      ]
    },
    {
      "442": [
        {
          "text": "And after that, no host out of heaven did We send down against his people, nor did We need to send down any:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 28,
          "number": 3733,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "nothing was [needed] but one single blast [of Our punishment] - and lo! they became as still and silent as ashes.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 29,
          "number": 3734,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "OH, THE REGRETS that [most] human beings will have to bear! Never has an apostle come to them without their deriding him!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 30,
          "number": 3735,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "Are they not aware of how many a generation We have destroyed before their time, [and] that those [that have perished] will never return to them,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 31,
          "number": 3736,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "and [that] all of them, all together, will [in the end] before Us be arraigned?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 32,
          "number": 3737,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "And [yet,] they have a sign [of Our power to create and to resurrect] in the lifeless earth which We make alive, and out of which We bring forth grain, whereof they may eat;",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 33,
          "number": 3738,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "and [how] We make gardens of date-palms and vines [grow] thereon, and cause springs to gush [forth] within it,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 34,
          "number": 3739,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "so that they may eat of the fruit thereof, though it was not their hands that made it. Will they not, then, be grateful?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 35,
          "number": 3740,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "Limitless in His glory is He who has created opposites in whatever the earth produces, and in men’s own selves, and in that of which [as yet] they have no knowledge.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 36,
          "number": 3741,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "And [of Our sway over all that exists] they have a sign in the night: We withdraw from it the [light of] day - and lo! they are in darkness.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 37,
          "number": 3742,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "And [they have a sign in] the sun: it runs in an orbit of its own [and] that is laid down by the will of the Almighty, the All-Knowing;",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 38,
          "number": 3743,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "and [in] the moon, for which We have determined phases [which it must traverse] till it becomes like an old date-stalk, dried-up and curved:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 39,
          "number": 3744,
          "juz": 23,
          "numpage": 442
        },
        {
          "text": "[and] neither may the sun overtake the moon, nor can the night usurp the time of day, since all of them float through space [in accordance with Our laws].",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 40,
          "number": 3745,
          "juz": 23,
          "numpage": 442
        }
      ]
    },
    {
      "441": [
        {
          "text": "AND SET FORTH unto them a parable - [the story of how] the people of a township [behaved] when [Our] message-bearers came unto them.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 13,
          "number": 3718,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "Lo! We sent unto them two [apostles], and. they gave the lie to both; and so We strengthened [the two] with a third; and thereupon they said: “Behold, we have been sent unto you [by God]!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 14,
          "number": 3719,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "[The others] answered: “You are nothing but mortal men like ourselves; moreover, the Most Gracious has never bestowed aught [of revelation] from on high. You do nothing but lie!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 15,
          "number": 3720,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "Said [the apostles]: “Our Sustainer knows that we have indeed been sent unto you;",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 16,
          "number": 3721,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "but we are not bound to do more than clearly deliver the mes­sage [entrusted to us].”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 17,
          "number": 3722,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "Said [the others]: “Truly, we augur evil from you! Indeed, if you desist not, we will surely stone you, and grievous suffering is bound to befall you at our hands!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 18,
          "number": 3723,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "[The apostles] replied: “Your destiny, good or evil, is [bound up] with yourselves! [Does it seem evil to you] if you are told to take [the truth] to heart? Nay, but you are people who have wasted their own selves!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 19,
          "number": 3724,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "At that, a man came running from the farthest end of the city, [and] exclaimed: “O my people! Follow these message-bearers!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 20,
          "number": 3725,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "Follow those who ask no reward of you, and themselves are rightly guided!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 21,
          "number": 3726,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "“[As for me,] why should I not worship Him who has brought me into being, and to whom you all will be brought back?",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 22,
          "number": 3727,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "Should I take to worship­ping [other] deities beside Him? [But then,] if the Most Gracious should will that harm befall me, their intercession could not in the least avail me, nor could they save me:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 23,
          "number": 3728,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "and so, behold, I would have indeed, most obviously, lost myself in error!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 24,
          "number": 3729,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "“Verily, [O my people,] in the Sustainer of you all have I come to believe: listen, then, to me!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 25,
          "number": 3730,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "[And] he was told, “[Thou shalt] enter paradise!” - [whereupon] he exclaimed: “Would that my people knew",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 26,
          "number": 3731,
          "juz": 22,
          "numpage": 441
        },
        {
          "text": "how my Sustainer has forgiven me [the sins of my past], and has placed me among the honoured ones!”",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 27,
          "number": 3732,
          "juz": 22,
          "numpage": 441
        }
      ]
    },
    {
      "440": [
        {
          "text": "Now if God were to take men [at once] to task for whatever [wrong] they commit [on earth], He would not leave a single living creature upon its surface. However, He grants them respite for a term set [by Him]: but when their term comes to an end - then, verily, [they come to know that] God sees all that is in [the hearts of] His servants.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 45,
          "number": 3705,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "O THOU human being!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 1,
          "number": 3706,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "Consider this Qur’an full of wisdom:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 2,
          "number": 3707,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "verily, thou art indeed one of God’s message-bearers,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 3,
          "number": 3708,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "pursuing a straight way",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 4,
          "number": 3709,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "by [virtue of] what is being bestowed from on high by the Almighty, the Dispenser of Grace,",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 5,
          "number": 3710,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "[bestowed upon thee] so that thou mayest warn people whose forefathers had not been warned, and who therefore are unaware [of the meaning of right and wrong].",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 6,
          "number": 3711,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "Indeed, the word [of God’s condemnation] is bound to come true against most of them: for they will not believe.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 7,
          "number": 3712,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "Behold, around their necks We have put shack­les, reaching up to their chins, so that their heads are forced up;",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 8,
          "number": 3713,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "and We have set a barrier before them and a barrier behind them, and We have enshrouded them in veils so that they cannot see:",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 9,
          "number": 3714,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "thus, it is all one to them whether thou warnest them or dost not warn them: they will not believe.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 10,
          "number": 3715,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "Thou canst [truly] warn only him who is willing to take the reminder to heart, and who stands in awe of the Most Gracious although He is beyond the reach of human perception: unto such, then, give the glad tiding of [God’s] forgiveness and of a most excellent reward!",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 11,
          "number": 3716,
          "juz": 22,
          "numpage": 440
        },
        {
          "text": "Verily, We shall indeed bring the dead back to life; and We shall record whatever [deeds] they have sent ahead, and the traces [of good and evil] which they have left behind: for of all things do We take account in a record clear.",
          "englishName": "Yaseen",
          "suraNumber": 36,
          "numberInSurah": 12,
          "number": 3717,
          "juz": 22,
          "numpage": 440
        }
      ]
    },
    {
      "439": [
        {
          "text": "He it is who has made you inherit the earth. Hence, he who is bent on denying the truth [of God’s oneness and uniqueness ought to know that] this denial of his will fall back upon him: for their [per­sistent] denial of this truth does but add to the deniers’ loathsomeness in their Sustainer’s sight and, thus, their denial of this truth does but add to the deniers’ loss.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 39,
          "number": 3699,
          "juz": 22,
          "numpage": 439
        },
        {
          "text": "Say: “Have you ever [really] considered those beings and forces to whom you ascribe a share in God’s divinity, [and] whom you invoke beside God? Show me what it is that they have created on earth - or do [you claim that] they have a share in [govern­ing] the heavens?” Have We ever vouchsafed them a divine writ on which they could rely as evidence [in support of their views]? Nay, [the hope which] the evildoers hold out to one another [is] nothing but a delusion.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 40,
          "number": 3700,
          "juz": 22,
          "numpage": 439
        },
        {
          "text": "Verily, it is God [alone] who upholds the celestial bodies and the earth, lest they deviate [from their orbits] - for if they should ever deviate, there is none that could uphold them after He will have ceased to do so. [But,] verily, He is ever-forbearing, much-for­giving!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 41,
          "number": 3701,
          "juz": 22,
          "numpage": 439
        },
        {
          "text": "As it is, they [who are averse to the truth often] swear by God with their most solemn oaths that if a warner should ever come to them, they would follow his guidance better than any of the communities [of old had followed the warner sent to them]: but now that a warner has come unto them, [his call] but increases their aversion,",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 42,
          "number": 3702,
          "juz": 22,
          "numpage": 439
        },
        {
          "text": "their arro­gant behaviour on earth, and their devising of evil [arguments against God’s messages]. Yet [in the end,] such evil scheming will engulf none but its authors: and can they expect anything but [to be made to go] the way of those [sinners] of olden times? Thus [it is]: no change wilt thou ever find in God’s way; yea, no deviation wilt thou ever find in God’s way!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 43,
          "number": 3703,
          "juz": 22,
          "numpage": 439
        },
        {
          "text": "Have they never journeyed about the earth and beheld what happened in the end to those [deniers of the truth] who lived before their time and were [so much] greater than they in power? And [do they not see that the will of] God can never be foiled by anything whatever in the heavens or on earth, since, verily, He is all-knowing, infinite in His power?",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 44,
          "number": 3704,
          "juz": 22,
          "numpage": 439
        }
      ]
    },
    {
      "438": [
        {
          "text": "And [know that] all of the divine writ with which We have inspired thee is the very truth, confirming the truth of whatever there still remains of earlier revelations for, behold, of [the needs of] His servants God is fully aware, all-seeing.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 31,
          "number": 3691,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "And so, We have bestowed this divine writ as a heritage unto such of Our servants as We chose: and among them are some who sin against themselves; and some who keep half-way [between right and wrong]; and some who, by God’s leave, are fore­most in deeds of goodness: [and] this, indeed, is a merit most high!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 32,
          "number": 3692,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "[Hence,] gardens of perpetual bliss will they enter, therein to be adorned with bracelets of gold and pearls, and therein to be clad in raiments of silk;",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 33,
          "number": 3693,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "and they will say: “All praise is due to God, who has caused all sorrow to leave us: for, verily, our Sustainer is indeed much-forgiving, ever-responsive to gratitude –",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 34,
          "number": 3694,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "He who, out of His bounty, has made us alight in this abode of life enduring, wherein no struggle can assail us, and wherein no weariness can touch us!”",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 35,
          "number": 3695,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "But as for those who are bent on denying the truth - the fire of hell awaits them: no end shall be put to their lives so that they could die, nor shall aught of the suffering caused by that [fire] be lightened for them: thus shall We requite all who are bereft of gratitude.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 36,
          "number": 3696,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "And in that [hell] they will cry aloud: “O our Sustainer! Cause us to come out [of this suffering]! We shall [henceforth] do good deeds, not such as we were wont to do [aforetime]!” [But We shall answer:] “Did We not grant you a life long enough so that whoever was willing to take thought could bethink himself? And [withal,] a war­ner had come unto you! Taste, then, [the fruit of your evil deeds]: for evildoers shall have none to succour them!”",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 37,
          "number": 3697,
          "juz": 22,
          "numpage": 438
        },
        {
          "text": "Verily, God knows the hidden reality of the heavens and the earth: [and,] behold, He has full knowledge of what is in the hearts [of men].",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 38,
          "number": 3698,
          "juz": 22,
          "numpage": 438
        }
      ]
    },
    {
      "437": [
        {
          "text": "For [thus it is:] the blind and the seeing are not equal;",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 19,
          "number": 3679,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "nor are the depths of darkness and the light;",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 20,
          "number": 3680,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "nor the [cooling] shade and the scorching heat:",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 21,
          "number": 3681,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "and neither are equal the living and the dead [of heart]. Behold, [O Muhammad,] God can make hear whomever He wills, whereas thou canst not make hear such as are [dead of heart like the dead] in their graves:",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 22,
          "number": 3682,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "thou art nothing but a warner.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 23,
          "number": 3683,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "Verily, We have sent thee with the truth, as a bearer of glad tidings and a warner: for there never was any community but a warner has [lived and] passed away in its midst.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 24,
          "number": 3684,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "And if they give thee the lie - even so gave the lie to the truth [many of] those who lived before their time, [when] there came unto them their apostles with all evidence of the truth, and with books of divine wisdom, and with light-giving revelation;",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 25,
          "number": 3685,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "[but] in the end I took to task all those who were bent on denying the truth: and how awesome was My rejection!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 26,
          "number": 3686,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "ART THOU NOT aware that God sends down water from the skies, whereby We bring forth fruits of many hues - just as in the mountains there are streaks of white and red of various shades, as well as (others) raven-black,",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 27,
          "number": 3687,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "and (as) there are in men, and in crawling beasts, and in cattle, too, many hues? Of all His servants, only such as are endowed with [innate] knowledge stand [truly] in awe of God: [for they alone comprehend that,] verily, God is almighty, much-forgiving.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 28,
          "number": 3688,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "(It is) they who [truly] follow God’s revelation, and are constant in prayer, and spend on others, secretly and openly, out of what We provide for them as sustenance - it is they who may look forward to a bargain that can never fail,",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 29,
          "number": 3689,
          "juz": 22,
          "numpage": 437
        },
        {
          "text": "since He will grant them their just rewards, and give them yet more out of His bounty: for, verily, He is much-forgiving, ever-responsive to gratitude.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 30,
          "number": 3690,
          "juz": 22,
          "numpage": 437
        }
      ]
    },
    {
      "436": [
        {
          "text": "[Easy is it for Him to create likeness and variety:] thus, the two great bodies of water [on earth] are not alike the one sweet, thirst-allaying, pleasant to drink, and the other salty and bitter: and yet, from either of them do you eat fresh meat, and [from either] you take gems which you may wear; and on either thou canst see ships ploughing through the waves, so that you might [be able to] go forth in quest of some of His bounty, and thus have cause to be grateful.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 12,
          "number": 3672,
          "juz": 22,
          "numpage": 436
        },
        {
          "text": "He makes the night grow longer by shortening the day, and He makes the day grow longer by shortening the night; and He has made the sun and the moon subservient [to His laws], each running its course for a term set [by Him]. Thus is God, your Sustainer: unto Him belongs all dominion - whereas those whom you invoke instead of Him do not own so much as the husk of a date-stone!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 13,
          "number": 3673,
          "juz": 22,
          "numpage": 436
        },
        {
          "text": "If you invoke them, they do not hear your call; and even if they could hear, they would not [be able to] respond to you. And [withal,] on the Day of Resurrection they will utterly disown your having associated them with God. And none can make thee understand [the truth] like the One who is all-aware.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 14,
          "number": 3674,
          "juz": 22,
          "numpage": 436
        },
        {
          "text": "O men! It is you, who stand in need of God, whereas He alone is self-sufficient, the One to whom all praise is due.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 15,
          "number": 3675,
          "juz": 22,
          "numpage": 436
        },
        {
          "text": "If He so wills, He can do away with you and bring forth a new mankind [in your stead]:",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 16,
          "number": 3676,
          "juz": 22,
          "numpage": 436
        },
        {
          "text": "nor is this difficult for God.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 17,
          "number": 3677,
          "juz": 22,
          "numpage": 436
        },
        {
          "text": "AND NO BEARER of burdens shall be made to bear another’s burden; and if one weighed down by his load calls upon [another] to help him carry it, nothing thereof may be carried [by that other], even if it be one’s near of kin. Hence, thou canst [truly] warn only those who stand in awe of their Sustainer although He is beyond the reach of their perception, and are constant in prayer, and [know that] whoever grows in purity, attains to purity but for the good of his own self, and [that] with God is all journeys’ end.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 18,
          "number": 3678,
          "juz": 22,
          "numpage": 436
        }
      ]
    },
    {
      "435": [
        {
          "text": "But if they [whose minds are perverted] give thee the lie, [O Prophet, remember that] even so, before thy time, have [other] apostles been given the lie: for [the unbelievers always refuse to admit that] all things go back to God [as their source].",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 4,
          "number": 3664,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "O men! Verily, God’s promise [of resurrection] is true indeed: let not, then, the life of this world delude you, and let not [your own] deceptive thoughts about God delude you!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 5,
          "number": 3665,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "Behold, Satan is a foe unto you: so treat him as a foe. He but calls on his followers to the end that they might find themselves among such as are des­tined for the blazing flame –",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 6,
          "number": 3666,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "[seeing that] for those who are bent on denying the truth there is suffering severe in store, just as for those who have attained to faith and do righteous deeds there is forgiveness of sins, and a great reward.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 7,
          "number": 3667,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "Is, then, he to whom the evil of his own doings is [so] alluring that [in the end] he regards it as good [anything but a follower of Satan]? For, verily, God lets go astray him that wills [to go astray], just as He guides him that wills [to be guided]. Hence, [O believer,] do not waste thyself in sorrowing over them: verily, God has full knowledge of all that they do!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 8,
          "number": 3668,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "AND [remember:] it is God who sends forth the winds, so that they raise a cloud, whereupon We drive it towards dead land and thereby give life to the earth after it had been lifeless: even thus shall resur­rection be!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 9,
          "number": 3669,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "He who desires might and glory [ought to know that] all might and glory belong to God [alone]. Unto Him ascend all good words, and the righteous deed does He exalt. But as for those who cunningly devise evil deeds - suffering severe awaits them; and all their devising is bound to come to nought.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 10,
          "number": 3670,
          "juz": 22,
          "numpage": 435
        },
        {
          "text": "And [remember:] God creates [every one of] you out of dust, then out of a drop of sperm; and then He fashions you into either of the two sexes. And no female conceives or gives birth unless it be with His knowledge; and none that is long-lived has his days lengthened - and neither is aught lessened of his days - unless it be thus laid down in [God’s] decree: for, behold, all this is easy for God.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 11,
          "number": 3671,
          "juz": 22,
          "numpage": 435
        }
      ]
    },
    {
      "434": [
        {
          "text": "Say: “The truth has now come [to light, and falsehood is bound to wither away]: for, falsehood cannot bring forth anything new, nor can it bring back [what has passed away].”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 49,
          "number": 3655,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "Say: “Were I to go astray, I would but go astray [due to my own self, and] to the hurt of myself; but if I am on the right path, it is but by virtue of what my Sustainer reveals unto me: for, verily, He is all-hearing, ever-near!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 50,
          "number": 3656,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "IF THOU couldst but see [how the deniers of the truth will fare on Resurrection Day,] when they will shrink in terror, with nowhere to escape - since they will have been seized from so close nearby",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 51,
          "number": 3657,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "and will cry, “We do [now] believe in it!” But how can they [hope to] attain [to salvation] from so far away,",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 52,
          "number": 3658,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "seeing that aforetime they had been bent on denying the truth, and had been wont to cast scorn, from far away, on something that was beyond the reach of human perception?",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 53,
          "number": 3659,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "And so, a barrier will be set between them and all that they had [ever] desired, as will be done to such of their kind as lived before their time: for, behold, they [too] were lost in doubt amounting to suspicion.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 54,
          "number": 3660,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "ALL PRAISE is due to God, Originator of the heavens and the earth, who causes the angels to be (His) message-bearers, endowed with wings, two, or three, or four. [Unceasingly] He adds to His creation whatever He wills: for, verily, God has the power to will anything.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 1,
          "number": 3661,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "Whatever grace God opens up to man, none can withhold it; and whatever He withholds, none can henceforth release: for He alone is almighty, truly wise.",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 2,
          "number": 3662,
          "juz": 22,
          "numpage": 434
        },
        {
          "text": "O men! Call to mind the blessings which God has bestowed upon you! Is there any creator, other than God, that could provide for you sustenance out of heaven and earth? There is no deity save Him: and yet, how perverted are your minds!",
          "englishName": "Faatir",
          "suraNumber": 35,
          "numberInSurah": 3,
          "number": 3663,
          "juz": 22,
          "numpage": 434
        }
      ]
    },
    {
      "433": [
        {
          "text": "And [as for those who now deny the truth,] one Day He will gather them all together, and will ask the angels, “Was it you that they were wont to worship?”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 40,
          "number": 3646,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "They will answer: “Limitless art Thou in Thy glory! Thou [alone] art close unto us, not they! Nay, [when they thought that they were worshipping us,] they were but [blindly] worshipping forces concealed from their senses; most of them believed in them.”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 41,
          "number": 3647,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "And [on that Day God will say]: “None of you [created beings] has today any power to benefit or to harm another!” And [then] We shall say unto those who had been bent on evildoing: “Taste [now] that suffering through fire which you were wont to call a lie!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 42,
          "number": 3648,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "For [thus it is:] whenever Our messages are conveyed unto them in all their clarity, they [who are bent on denying the truth] say [to one another], “This [Muhammad] is nothing but a man who wants to turn you away from what your forefathers were wont to worship!” And they say, “This [Qur’an] is nothing but a falsehood invented [by man]!” And [finally,] they who are bent on denying the truth speak thus of the truth when it comes to them: “This is clearly nothing but spellbinding eloquence!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 43,
          "number": 3649,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "And yet, [O Muhammad,] never have We vouchsafed them any revelations which they could quote, and neither have We sent unto them any warner before thee.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 44,
          "number": 3650,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "Thus, too, gave the lie to the truth [many of] those who lived before them; and although those [earlier people] had not attained to even a tenth of [the evidence of the truth] which We have vouch­safed unto these [late successors of theirs], yet when they gave the lie to My apostles, how awesome was My rejection!",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 45,
          "number": 3651,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "Say: “I counsel you one thing only: Be [ever­ conscious of] standing before God, whether you are in the company of others or alone; and then bethink yourselves [that] there is no madness in [this pro­phet,] your fellow-man: he is only a warner to you of suffering severe to come.”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 46,
          "number": 3652,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "Say: “No reward have I ever asked of you [out of anything] that is yours: my reward rests with none but God, and He is witness unto everything!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 47,
          "number": 3653,
          "juz": 22,
          "numpage": 433
        },
        {
          "text": "Say: “Verily, my Sustainer hurls the truth [against all that is false] He who fully knows all the things that are beyond the reach of a created being’s perception!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 48,
          "number": 3654,
          "juz": 22,
          "numpage": 433
        }
      ]
    },
    {
      "432": [
        {
          "text": "[And] those who were wont to glory in their arrogance will say unto those who had been weak: “Why - did we keep you [forcibly] from following the right path after it had become obvious to you? Nay, it was but you [yourselves] who were guilty!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 32,
          "number": 3638,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "But those who had been weak will say unto those who had gloried in their arrogance: “Nay, [what kept us away was your] devising of false arguments, night and day, [against God’s messages- as you did] when you persuaded us to blaspheme against God and to claim that there are powers that could rival Him!” And when they see the suffering [that awaits them], they will [all] be unable to express [the full depth of] their remorse: for We shall have put shackles around the necks of those who had been bent on denying the truth: [and] will this be aught but a [just] requital for what they were doing?",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 33,
          "number": 3639,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "For [thus it is:] whenever We sent a warner to any community, those of its people who had lost themselves entirely in the pursuit of pleasures would declare, “Behold, we deny that there is any truth in [what you claim to be] your message!” –",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 34,
          "number": 3640,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "and they would add, “Richer [than you] are we in wealth and in children, and [so] we are not going to be made to suffer!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 35,
          "number": 3641,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "Say: “Behold, my Sustainer grants abundant sustenance, or gives it in scant measure, unto whomever He wills: but most men do not understand [God’s ways].”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 36,
          "number": 3642,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "For, it is neither your riches nor your children that can bring you nearer to Us: only he who attains to faith and does what is right and just [comes near unto Us]; and it is [such as] these whom multiple recompense awaits for all that they have done; and it is they who shall dwell secure in the mansions [of paradise] –",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 37,
          "number": 3643,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "whereas all who strive against Our messages, seeking to defeat their purpose, shall be given over to suffering.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 38,
          "number": 3644,
          "juz": 22,
          "numpage": 432
        },
        {
          "text": "Say: “Behold, my Sustainer grants abundant sustenance, or gives it in scant measure, unto whomever He wills of His servants; and whatever it be that you spend on others, He [always] replaces it: for He is the best of providers.”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 39,
          "number": 3645,
          "juz": 22,
          "numpage": 432
        }
      ]
    },
    {
      "431": [
        {
          "text": "And, before Him, intercession can be of no avail [to any] save one in whose case He may have granted leave [there for]: so much so that when the terror [of the Last Hour] is lifted from their hearts, they [who have been resurrected] will ask [one an­other], “What has your Sustainer decreed [for you]?” - [to which] the others will answer, “Whatever is true and deserved - for He alone is exalted, great!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 23,
          "number": 3629,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "Say: “Who is it that provides for you sus­tenance out of the heavens and the earth?” Say: “It is God. And, behold, either we [who believe in Him] or you [who deny His oneness] are on the right path, or have clearly gone astray!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 24,
          "number": 3630,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "Say: “Neither shall you be called to account for whatever we may have become guilty of, nor shall we be called to account for whatever you are doing.”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 25,
          "number": 3631,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "Say: “Our Sustainer will bring us all together [on Judgment Day], and then He will lay open the truth between us, in justice - for He alone is the One who opens all truth, the All-Knowing!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 26,
          "number": 3632,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "Say: “Point out to me those [beings] that you have joined with Him [in your minds] as partners [in His divinity]! Nay - nay, but He [alone] is God, the Almighty, the Wise!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 27,
          "number": 3633,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "NOW [as for thee, O Muhammad,] We have not sent thee otherwise than to mankind at large, to be a herald of glad tidings and a warner; but most people do not understand [this],",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 28,
          "number": 3634,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "and so they ask, “When is this promise [of resurrection and judgment] to be fulfilled? [Answer this, O you who believe in it,] if you are men of truth!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 29,
          "number": 3635,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "Say: “There has been appointed for you a Day, which you can neither delay nor advance by a single moment.”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 30,
          "number": 3636,
          "juz": 22,
          "numpage": 431
        },
        {
          "text": "And [yet,] those who are bent on denying the truth do say, “We shall never believe in this Qur’an, and neither in whatever there still remains of earlier revelations!” But if thou couldst only see [how it will be on Judgment Day,] when these evildoers shall be made to stand before their Sustainer, hurling reproaches back and forth at one another! Those [of them] who had been weak [on earth] will say unto those who had gloried in their arrogance: “Had it not been for you, we would certainly have been believers!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 31,
          "number": 3637,
          "juz": 22,
          "numpage": 431
        }
      ]
    },
    {
      "430": [
        {
          "text": "INDEED, in [the luxuriant beauty of] their homeland, the people of Sheba had an evidence [of God’s grace] two [vast expanses of] gardens, to the right and to the left, [calling out to them, as it were:] “Eat of what your Sustainer has provided for you, and render thanks unto Him: a land most goodly, and a Sustainer much-forgiving!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 15,
          "number": 3621,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "But they turned away [from Us], and so We let loose upon them a flood that overwhelmed the dams, and changed their two [expanses of luxuriant] gardens into a couple of gardens yielding bitter fruit, and tamarisks, and some few [wild] lote-trees:",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 16,
          "number": 3622,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "thus We requited them for their having denied the truth. But do We ever requite [thus] any but the utterly ingrate?",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 17,
          "number": 3623,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "Now [before their downfall,] We had placed between them and the cities which We had blessed [many] towns within sight of one another; and thus We had made traveling easy [for them, as if to say]: “Travel safely in this [land], by night or by day!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 18,
          "number": 3624,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "But now they would say, “Long has our Sus­tainer made the distance between our journey- stages!” - for they had sinned against themselves. And in the end We caused them to become [one of those] tales [of things long past], and scattered them in countless fragments. Herein, behold, there are messages indeed for all who are wholly patient in adversity and deeply grate­ful [to God].",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 19,
          "number": 3625,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "Now, indeed, Iblis did prove that his opinion of them had been right: for [when he called them,] they followed him - all but some of the believers [among them].",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 20,
          "number": 3626,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "And yet, he had no power at all over them: [for if We allow him to tempt man,] it is only to the end that We might make a clear distinction between those who [truly] believe in the life to come and those who are in doubt thereof: for thy Sustainer watches over all things.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 21,
          "number": 3627,
          "juz": 22,
          "numpage": 430
        },
        {
          "text": "SAY: “Call upon those [beings] whom you imagine [to be endowed with divine powers] beside God: they have not an atom’s weight of power either in the heavens or on earth, nor have they any share in [governing] either, nor does He [choose to] have any helper from among them.”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 22,
          "number": 3628,
          "juz": 22,
          "numpage": 430
        }
      ]
    },
    {
      "429": [
        {
          "text": "Does he [knowingly] attribute his own lying inventions to God - or is he a madman?” Nay, [there is no madness in this Prophet -] but they who will not believe in the life to come are [bound to lose themselves] in suffering and in a profound aberration.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 8,
          "number": 3614,
          "juz": 22,
          "numpage": 429
        },
        {
          "text": "Are they, then, not aware of how little of the sky and the earth lies open before them, and how much is hidden from them? [or that,] if We so willed, We could cause the earth to swallow them, or cause fragments of the sky to fall down upon them? In all this, behold, there is a message indeed for every servant [of God] who is wont to turn unto Him [in repentance].",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 9,
          "number": 3615,
          "juz": 22,
          "numpage": 429
        },
        {
          "text": "AND [thus], indeed, did We grace David with Our favour: “O you mountains! Sing with him the praise of God! And [likewise] you birds!” And We softened all sharpness in him,",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 10,
          "number": 3616,
          "juz": 22,
          "numpage": 429
        },
        {
          "text": "[and inspired him thus:] “Do good deeds lavishly, without stint, and give deep thought to their steady flow.” And [thus should you all, O believers,] do righteous deeds: for, verily, I see all that you do!",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 11,
          "number": 3617,
          "juz": 22,
          "numpage": 429
        },
        {
          "text": "AND UNTO Solomon [We made subservient] the wind: its morning course [covered the distance of] a month’s journey, and its evening course, a month’s journey. And We caused a fountain of molten copper to flow at his behest; and [even] among the invisible beings there were some that had [been constrained] to labour for him by his Sustainer’s leave and whichever of them deviated from Our command, him would We let taste suffering through a blazing flame -:",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 12,
          "number": 3618,
          "juz": 22,
          "numpage": 429
        },
        {
          "text": "they made for him whatever he wished of sanctuaries, and statues, and basins as [large as] great watering - troughs, and cauldrons firmly anchored. [And We said:] “Labour, O David’s people, in gratitude [towards Me] and [remember that] few are the truly grateful [even] among My servants!”",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 13,
          "number": 3619,
          "juz": 22,
          "numpage": 429
        },
        {
          "text": "Yet [even Solomon had to die; but] when We decreed that he should die, nothing showed them that he was dead except an earthworm that gnawed away his staff. And when he fell to the ground, those invisible beings [subservient to him] saw clearly that, had they but understood the reality which was be­yond the reach of their perception, they would not have continued [to toil] in the shameful suffering [of servitude]",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 14,
          "number": 3620,
          "juz": 22,
          "numpage": 429
        }
      ]
    },
    {
      "428": [
        {
          "text": "ALL PRAISE is due to God, to whom all that is in the heavens and all that is on earth belongs; and to Him will be due all praise in the life to come. For He alone is truly wise, all-aware:",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 1,
          "number": 3607,
          "juz": 22,
          "numpage": 428
        },
        {
          "text": "He knows all that enters the earth, and all that comes out of it, as well as all that descends from the skies, and all that ascends to them. And He alone is a dispenser of grace, truly-forgiving.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 2,
          "number": 3608,
          "juz": 22,
          "numpage": 428
        },
        {
          "text": "And yet, they who are bent on denying the truth assert, “Never will the Last Hour come upon us!” Say: “Nay, by my Sustainer! By Him who knows all that is beyond the reach of a created being’s perception: it will most certainly come upon you!” Not an atom’s weight [of whatever there is] in the heavens or on earth escapes His knowledge; and neither is there anything smaller than that, or larger, but is recorded in [His] clear decree,",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 3,
          "number": 3609,
          "juz": 22,
          "numpage": 428
        },
        {
          "text": "to the end that He may reward those who believe and do right­eous deeds: [for] it is they whom forgiveness of sins awaits, and a most excellent sustenance",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 4,
          "number": 3610,
          "juz": 22,
          "numpage": 428
        },
        {
          "text": "whereas for those who strive against Our messages, seeking to defeat their purpose, there is grievous suffering in store as an outcome of [their] vileness.",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 5,
          "number": 3611,
          "juz": 22,
          "numpage": 428
        },
        {
          "text": "NOW THEY who are endowed with [innate] knowl­edge are well aware that whatever has been be­stowed upon thee from on high by thy Sustainer is indeed the truth, and that it guides onto the way that leads to the Almighty, the One to whom all praise is due!",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 6,
          "number": 3612,
          "juz": 22,
          "numpage": 428
        },
        {
          "text": "As against this, they who are bent on denying the truth say [unto all who are of like mind]: “Shall we point out to you a man who will tell you that [after your death,] when you will have been scattered in countless fragments, you shall - lo and behold! - be [restored to life] in a new act of creation?",
          "englishName": "Saba",
          "suraNumber": 34,
          "numberInSurah": 7,
          "number": 3613,
          "juz": 22,
          "numpage": 428
        }
      ]
    },
    {
      "427": [
        {
          "text": "PEOPLE will ask thee about the Last Hour. Say: “Knowledge thereof rests with God alone; yet for all thou knowest, the Last Hour may well be near!”",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 63,
          "number": 3596,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "Verily, God has rejected the deniers of the truth, and has readied for them a blazing fire,",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 64,
          "number": 3597,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "therein to abide beyond the count of time: no protec­tor will they find, and none to bring them succour.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 65,
          "number": 3598,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "On the Day when their faces shall be tossed about in the fire, they will exclaim, “Oh, would that we had paid heed unto God, and paid heed unto the Apostle!”",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 66,
          "number": 3599,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "And they will say: “O our Sustainer! Behold, we paid heed unto our leaders and our great men, and it is they who have led us astray from the right path!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 67,
          "number": 3600,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "O our Sustainer! Give them double suffering, and banish them utterly from Thy grace!”",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 68,
          "number": 3601,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "O YOU who have attained to faith! Be not like those [children of Israel] who gave offence to Moses, and [remember that] God showed him to be innocent of whatever they alleged [against him or demanded of him]: for of great honour was he in the sight of God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 69,
          "number": 3602,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "O you who have attained to faith! Remain conscious of God, and [always] speak with a will to bring out [only] what is just and true -",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 70,
          "number": 3603,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "[whereupon] He will cause your deeds to be virtuous, and will forgive you your sins. And [know that] whoever pays heed unto God and His Apostle has already attained to a mighty triumph.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 71,
          "number": 3604,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "Verily, We did offer the trust [of reason and volition] to the heavens, and the earth, and the mountains: but they refused to bear it because they were afraid of it. Yet man took it up - for, verily, he has always been prone to be most wicked, most foolish.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 72,
          "number": 3605,
          "juz": 22,
          "numpage": 427
        },
        {
          "text": "[And so it is] that God imposes suffering on the hypocrites, both men and women, as well as on the men and women who ascribe divinity to aught beside Him. And [so, too, it is] that God turns in His mercy unto the believing men and believing women: for God is indeed much-forgiving, a dispenser of grace!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 73,
          "number": 3606,
          "juz": 22,
          "numpage": 427
        }
      ]
    },
    {
      "426": [
        {
          "text": "[However,] it is no sin for them [to appear freely] before their fathers, or their sons, or their brothers, or their brothers’ sons, or their sisters’ sons, or their womenfolk, or such [male slaves] as their right hands may possess. But [always, O wives of the Prophet,] remain con­scious of God - for, behold, God is witness unto everything.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 55,
          "number": 3588,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "Verily, God and His angels bless the Prophet: [hence,] O you who have attained to faith, bless him and give yourselves up [to his guidance] in utter self-surrender!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 56,
          "number": 3589,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "Verily, as for those who [knowingly] affront God and His Apostle - God will reject them in this world and in the life to come; and shameful suffering will He ready for them.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 57,
          "number": 3590,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "And as for those who malign believing men and believing women without their having done any wrong - they surely burden themselves with the guilt of calumny, and [thus] with a flagrant sin!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 58,
          "number": 3591,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "O Prophet! Tell thy wives and thy daughters, as well as all [other] believing women, that they should draw over themselves some of their outer garments [when in public]: this will be more con­ducive to their being recognized [as decent women] and not annoyed. But [withal,] God is indeed much- forgiving, a dispenser of grace!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 59,
          "number": 3592,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "THUS IT IS: if the hypocrites, and they in whose hearts is disease, and they who, by spreading false rumours, would cause disturbances in the City [of the Prophet] desist not [from their hostile doings]. We shall indeed give thee mastery over them, [O Muhammad] - and then they will not remain thy neighbours in this [city] for more than a little while:",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 60,
          "number": 3593,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "bereft of God’s grace, they shall be seized wherever they may be found, and slain one and all.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 61,
          "number": 3594,
          "juz": 22,
          "numpage": 426
        },
        {
          "text": "Such has been God’s way with those who [sinned in like manner and] passed away aforetime - and never wilt thou find any change in God’s way!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 62,
          "number": 3595,
          "juz": 22,
          "numpage": 426
        }
      ]
    },
    {
      "425": [
        {
          "text": "[know that] thou mayest put off for a time whichever of them thou pleasest, and mayest take unto thee whichever thou pleasest; and [that,] if thou seek out any from whom thou hast kept away [for a time], thou wilt incur no sin [thereby]: this will make it more likely that their eyes are gladdened [whenever they see thee], and that they do not grieve [whenever they are overlooked], and that all of them may find contentment in whatever thou hast to give them: for God [alone] knows what is in your hearts - and God is indeed all-knowing, for­bearing.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 51,
          "number": 3584,
          "juz": 22,
          "numpage": 425
        },
        {
          "text": "No [other] women shall henceforth be lawful to thee nor art thou [allowed] to supplant [any of] them by other wives, even though their beauty should please thee greatly -: [none shall be lawful to thee] beyond those whom thou [already] hast come to possess. And God keeps watch over everything.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 52,
          "number": 3585,
          "juz": 22,
          "numpage": 425
        },
        {
          "text": "O YOU who have attained to faith! Do not enter the Prophet’s dwellings unless you are given leave; [and when invited] to a meal, do not come [so early as] to wait for it to be readied: but whenever you are invited, enter [at the proper time]; and when you have partaken of the meal, disperse without lingering for the sake of mere talk: that, behold, might give offence to the Prophet, and yet he might feel shy of [asking] you [to leave]: but God is not shy of [teaching you] what is right. And [as for the Prophet’s wives,] whenever you ask them for anything that you need, ask them from behind a screen: this will but deepen the purity of your hearts and theirs. Moreover, it does not behove you to give offence to God’s Apostle - just as it would not behove you ever to marry his widows after he has passed away: that, verily, would be an enormity in the sight of God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 53,
          "number": 3586,
          "juz": 22,
          "numpage": 425
        },
        {
          "text": "Whether you do anything openly or in secret, [remember that,] verily, God has full knowledge of everything.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 54,
          "number": 3587,
          "juz": 22,
          "numpage": 425
        }
      ]
    },
    {
      "424": [
        {
          "text": "On the Day when they meet Him, they will be welcomed with the greeting, “Peace”; and He will have readied for them a most excellent reward.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 44,
          "number": 3577,
          "juz": 22,
          "numpage": 424
        },
        {
          "text": "[And as for thee,] O Prophet - behold, We have sent thee as a witness [to the truth], and as a herald of glad tidings and a warner,",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 45,
          "number": 3578,
          "juz": 22,
          "numpage": 424
        },
        {
          "text": "and as one who summons [all men] to God by His leave, and as a light-giving beacon.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 46,
          "number": 3579,
          "juz": 22,
          "numpage": 424
        },
        {
          "text": "And [so,] convey to the believers the glad tiding that a great bounty from God awaits them;",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 47,
          "number": 3580,
          "juz": 22,
          "numpage": 424
        },
        {
          "text": "and defer not to [the likes and dislikes of] the deniers of the truth and the hypocrites, and disregard their hurtful talk, and place thy trust in God: for none is as worthy of trust as God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 48,
          "number": 3581,
          "juz": 22,
          "numpage": 424
        },
        {
          "text": "O YOU who have attained to faith! If you marry believing women and then divorce them ere you have touched them, you have no reason to expect, and to calculate, any waiting period on their part: hence, make [at once] provision for them, and release them in a becoming manner.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 49,
          "number": 3582,
          "juz": 22,
          "numpage": 424
        },
        {
          "text": "O PROPHET! Behold, We have made lawful to thee thy wives unto whom thou hast paid their dowers, as well as those whom thy right hand has come to possess from among the captives of war whom God has bestowed upon thee. And [We have made lawful to thee] the daughters of thy paternal uncles and aunts, and the daughters of thy maternal uncles and aunts, who have migrated with thee [to Yathrib]; and any believing woman who offers herself freely to the Prophet and whom the Prophet might be willing to wed: [this latter being but] a privilege for thee, and not for other believers - [seeing that] We have already made known what We have enjoined upon them with regard to their wives and those whom their right hands may possess. [And] in order that thou be not burdened with [undue] anxiety - for God is indeed much-forgiving, a dispenser of grace –",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 50,
          "number": 3583,
          "juz": 22,
          "numpage": 424
        }
      ]
    },
    {
      "423": [
        {
          "text": "Now whenever God and His Apostle have decided a matter, it is not for a believing man or a believing woman to claim freedom of choice insofar as they themselves are concerned: for he who [thus] rebels against God and His Apostle has already, most obviously, gone astray.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 36,
          "number": 3569,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "AND LO, [O Muhammad,] thou didst say unto the one to whom God had shown favour and to whom thou hadst shown favour, “Hold on to thy wife, and remain conscious of God!” And [thus] wouldst thou hide within thyself something that God was about to bring to light for thou didst stand in awe of [what] people [might think], whereas it was God alone of whom thou shouldst have stood in awe! [But] then, when Zayd had come to the end of his union with her, We gave her to thee in marriage, so that [in future] no blame should attach to the be­lievers for [marrying] the spouses of their adopted children when the latter have come to the end of their union with them. And [thus] God’s will was done.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 37,
          "number": 3570,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "[Hence,] no blame whatever attaches to the Prophet for [having done] what God has ordained for him. [Indeed, such was] God’s way with those that have passed away aforetime and [remember that] God’s will is always destiny absolute-;",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 38,
          "number": 3571,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "[and such will always be His way with] those who convey God’s messages [to the world], and stand in awe of Him, and hold none but God in awe: for none can take count [of man’s doings] as God does!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 39,
          "number": 3572,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "[And know, O believers, that] Muhammad is not the father of any one of your men, but is God’s Apostle and the Seal of all Prophets. And God has indeed full knowledge of everything.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 40,
          "number": 3573,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "O YOU who have attained to faith! Remember God with unceasing remembrance,",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 41,
          "number": 3574,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "and extol His limitless glory from morn to evening.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 42,
          "number": 3575,
          "juz": 22,
          "numpage": 423
        },
        {
          "text": "He it is who bestows His blessings upon you, with His angels [echoing Him], so that He might take you out of the depths of darkness into the light. And, indeed, a dispenser of grace is He unto the believers.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 43,
          "number": 3576,
          "juz": 22,
          "numpage": 423
        }
      ]
    },
    {
      "422": [
        {
          "text": "But if any of you devoutly obeys God and His Apostle and does good deeds, on her shall We bestow her reward twice-over: for We shall have readied for her a most excellent sustenance [in the life to come].",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 31,
          "number": 3564,
          "juz": 22,
          "numpage": 422
        },
        {
          "text": "O wives of the Prophet! You are not like any of the [other] women, provided that you remain [truly] conscious of God. Hence, be not over-soft in your speech, lest any whose heart is diseased should be moved to desire [you]: but, withal, speak in a kindly way.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 32,
          "number": 3565,
          "juz": 22,
          "numpage": 422
        },
        {
          "text": "And abide quietly in your homes, and do not flaunt your charms as they used to flaunt them in the old days of pagan ignorance; and be constant in prayer, and render the purifying dues, and pay heed unto God and His Apostle: for God only wants to remove from you all that might be loathsome, O you members of the [Prophet’s] household, and to purify you to utmost purity.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 33,
          "number": 3566,
          "juz": 22,
          "numpage": 422
        },
        {
          "text": "And bear in mind all that is recited in your homes of God’s messages and [His] wisdom: for God is unfathomable [in His wisdom], all-aware.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 34,
          "number": 3567,
          "juz": 22,
          "numpage": 422
        },
        {
          "text": "VERILY, for all men and women who have sur­rendered themselves unto God, and all believing men and believing women, and all truly devout men and truly devout women, and all men and women who are true to their word, and all men and women who are patient in adversity, and all men and women who humble themselves [before God], and all men and women who give in charity, and all self-denying men and self-denying women, and all men and women who are mindful of their chastity, and all men and women who remember God unceasingly: for [all of] them has God readied forgiveness of sins and a mighty reward.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 35,
          "number": 3568,
          "juz": 22,
          "numpage": 422
        }
      ]
    },
    {
      "421": [
        {
          "text": "Among the believers are men who have [always] been true to what they have vowed before God; and among them are such as have [already] redeemed their pledge by death, and such as yet await [its fulfillment] without having changed [their resolve] in the least.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 23,
          "number": 3556,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "[Such trials are imposed upon man] so that God may reward the truthful for having been true to their word, and cause the hypocrites to suffer - if that be His will - or [if they repent,] accept their repen­tance: for, verily, God is indeed much-forgiving, a dispenser of grace!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 24,
          "number": 3557,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "Thus, for all their fury, God repulsed those who were bent on denying the truth; no advantage did they gain, since God was enough to [protect] the believers in battle - seeing that God is most powerful, almighty;",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 25,
          "number": 3558,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "and He brought down from their strongholds those of the followers of earlier reve­lation who had aided the aggressors, and cast terror into their hearts: some you slew, and some you made captive;",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 26,
          "number": 3559,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "and He made you heirs to their lands, and their houses, and their goods - and [promised you] lands on which you had never yet set foot: for God has indeed the power to will anything.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 27,
          "number": 3560,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "O PROPHET! Say unto thy wives: “If you desire [but] the life of this world and its charms - well, then, I shall provide for you and release you in a becoming manner;",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 28,
          "number": 3561,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "but if you desire God and His Apostle, and [thus the good of] the life in the hereafter, then [know that], verily, for the doers of good among you God has readied a mighty reward!”",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 29,
          "number": 3562,
          "juz": 21,
          "numpage": 421
        },
        {
          "text": "O wives of the Prophet! If any of you were to become guilty of manifestly immoral conduct, double [that of other sinners] would be her suffering [in the hereafter]: for that is indeed easy for God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 30,
          "number": 3563,
          "juz": 21,
          "numpage": 421
        }
      ]
    },
    {
      "420": [
        {
          "text": "Say: “Whether you flee from [natural] death or from being slain [in battle], flight will not profit you - for, however you fare, you are not [allowed] to enjoy life for more than a little while!”",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 16,
          "number": 3549,
          "juz": 21,
          "numpage": 420
        },
        {
          "text": "Say: “Who is there that could keep you away from God if it be His will to harm you, or if it be His will to show you mercy?” For, [do they not know that] besides God they can find none to protect them, and none to bring them succour?",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 17,
          "number": 3550,
          "juz": 21,
          "numpage": 420
        },
        {
          "text": "God is indeed aware of those of you who would divert others [from fighting in His cause], as well as of those who say to their brethren, “Come hither to us [and face the enemy]!” — The while they [themselves] join battle but seldom,",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 18,
          "number": 3551,
          "juz": 21,
          "numpage": 420
        },
        {
          "text": "begrudging you all help. But then, when danger threatens, thou canst see them looking to thee [for help, O Prophet], their eyes rolling [in terror] like [the eyes of] one who is overshadowed by death: yet as soon as the danger has passed, they will assail you [believers] with sharp tongues, begrudging you all that is good! [People like] these have never known faith - and therefore God will cause all their works to come to nought: for this is indeed easy for God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 19,
          "number": 3552,
          "juz": 21,
          "numpage": 420
        },
        {
          "text": "They think that the Confederates have not [really] withdrawn; and should the Confederates return, these [hypocrites] would prefer to be in the desert, among the bedouin, asking for news about you, [O believers, from far away;] and even were they to find themselves in your midst, they would but make a pretence at fighting [by your side].",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 20,
          "number": 3553,
          "juz": 21,
          "numpage": 420
        },
        {
          "text": "VERILY, in the Apostle of God you have a good example for everyone who looks forward [with hope and awe] to God and the Last Day, and remembers God unceasingly.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 21,
          "number": 3554,
          "juz": 21,
          "numpage": 420
        },
        {
          "text": "And [so,] when the believers saw the Con­federates [advancing against them], they said, “This is what God and His Apostle have promised us!”- and, “Truly spoke God and His Apostle!” and all this but increased their faith and their readiness to surrender themselves unto God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 22,
          "number": 3555,
          "juz": 21,
          "numpage": 420
        }
      ]
    },
    {
      "419": [
        {
          "text": "AND LO! We did accept a solemn pledge from all the prophets from thee, [O Muhammad,] as well as from Noah, and Abraham, and Moses, and Jesus the son of Mary -: for We accepted a most weighty, solemn pledge from [all of] them,",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 7,
          "number": 3540,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "so that [at the end of time] He might ask those men of truth as to [what response] their truthfulness [had received on earth]. And grievous suffering has He readied for all who deny the truth!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 8,
          "number": 3541,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "O YOU who have attained to faith! Call to mind the blessings which God bestowed on you (at the time) when [enemy] hosts came down upon you, where­upon We let loose against them a storm wind and [heavenly] hosts that you could not see: yet God saw all that you did.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 9,
          "number": 3542,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "[Remember what you felt] when they came upon you from above you and from below you, and when [your] eyes became dim and [your] hearts came up to [your] throats, and [when] most conflicting thoughts about God passed through your minds:",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 10,
          "number": 3543,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "[for] there and then were the believers tried, and shaken with a shock severe.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 11,
          "number": 3544,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "And [remember how it was] when the hypo­crites and those with hearts diseased said [to one another], “God and His Apostle have promised us nothing but delusions!”",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 12,
          "number": 3545,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "and when some of them said, “O you people of Yathrib! You cannot withstand [the enemy] here: hence, go back [to your homes]!” — Whereupon a party from among them asked leave of the Prophet, saying, “Behold, our houses are exposed [to attack]!” — The while they were not [really] exposed: they wanted nothing but to flee.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 13,
          "number": 3546,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "Now if their town had been stormed, and they had been asked [by the enemy] to commit apos­tasy, [the hypocrites] would have done so without much delay",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 14,
          "number": 3547,
          "juz": 21,
          "numpage": 419
        },
        {
          "text": "although ere that they had vowed before God that they would never turn their backs [on His message]: and a vow made to God must surely be answered for!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 15,
          "number": 3548,
          "juz": 21,
          "numpage": 419
        }
      ]
    },
    {
      "418": [
        {
          "text": "PROPHET! Remain conscious of God, and defer not to the deniers of the truth and the hypo­crites: for God is truly all-knowing, wise.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 1,
          "number": 3534,
          "juz": 21,
          "numpage": 418
        },
        {
          "text": "And follow [but] that which comes unto thee through revelation from thy Sustainer: for God is truly aware of all that you do, [O men].",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 2,
          "number": 3535,
          "juz": 21,
          "numpage": 418
        },
        {
          "text": "And place thy trust in God [alone]: for none is as worthy of trust as God.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 3,
          "number": 3536,
          "juz": 21,
          "numpage": 418
        },
        {
          "text": "NEVER has God endowed any man with two hearts in one body: and [just as] He has never made your wives whom you may have declared to be “as unlawful to you as your mothers’ bodies” [truly] your mothers, so, too, has He never made your adopted sons [truly] your sons: these are but [figures of] speech uttered by your mouths - whereas God speaks the [absolute] truth: and it is He alone who can show [you] the right path.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 4,
          "number": 3537,
          "juz": 21,
          "numpage": 418
        },
        {
          "text": "[As for your adopted children,] call them by their [real] fathers’ names: this is more equitable in the sight of God; and if you know not who their fathers were, [call them] your brethren in faith and your friends. However, you will incur no sin if you err in this respect: [what really matters is] but what your hearts intend - for God is indeed much-forgiv­ing, a dispenser of grace!",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 5,
          "number": 3538,
          "juz": 21,
          "numpage": 418
        },
        {
          "text": "The Prophet has a higher claim on the believers than [they have on] their own selves, [seeing that he is as a father to them] and his wives are their mothers: and they who are [thus] closely related have, in accordance with God’s decree, a higher claim upon one another than [was even the case between] the believers [of Yathrib] and those who had migrated [there for the sake of God]. None the less, you are to act with utmost goodness towards your [other] close friends as well: this [too] is written down in God’s decree.",
          "englishName": "Al-Ahzaab",
          "suraNumber": 33,
          "numberInSurah": 6,
          "number": 3539,
          "juz": 21,
          "numpage": 418
        }
      ]
    },
    {
      "417": [
        {
          "text": "However, ere [We condemn them to] that supreme suffering, We shall most certainly let them taste of a suffering closer at hand, so that they might [repent and] mend their ways.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 21,
          "number": 3524,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "And who could be more wicked than he to whom his Sustainer’s messages are conveyed and who thereupon turns away from them? Verily, We shall inflict Our retribution on those who are [thus] lost in sin!",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 22,
          "number": 3525,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "AND, INDEED, [O Muhammad,] We did vouchsafe revelation unto Moses [as well]: so be not in doubt of [thy] having met with the same [truth in the revelation vouchsafed to thee]. And [just as] We caused that [earlier revelation] to be a guidance for the children of Israel,",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 23,
          "number": 3526,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "and [as] We raised among them leaders who, so long as they bore themselves with patience and had sure faith in Our messages, guided [their people] in accordance with Our behest [so, too, shall it be with the divine writ revealed unto thee, O Muhammad.]",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 24,
          "number": 3527,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "VERILY, it is God alone who will decide between men on Resurrection Day with regard to all on which they were wont to differ.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 25,
          "number": 3528,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "[But] can, then, they [who deny the truth] learn no lesson by recalling how many a generation We have destroyed before their time? [people] in whose dwelling-places they [themselves now] walk about? In this, behold, there are messages indeed: will they not, then, listen?",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 26,
          "number": 3529,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "Are they not aware that it is We who drive the rain onto dry land devoid of herbage, and thereby bring forth herbage of which their cattle and they themselves do eat? Can they not, then, see [the truth of resurrection]?",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 27,
          "number": 3530,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "But they answer: “When will that final decision take place, if what you [believers] say is true?”",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 28,
          "number": 3531,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "Say: “On the Day of the Final Decision, their [newly-found] faith will be of no use to those who [in their lifetime] were bent on denying the truth, nor will they be granted respite!” –",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 29,
          "number": 3532,
          "juz": 21,
          "numpage": 417
        },
        {
          "text": "and then leave them alone, and wait [for the truth to unfold as] they, behold, are waiting.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 30,
          "number": 3533,
          "juz": 21,
          "numpage": 417
        }
      ]
    },
    {
      "416": [
        {
          "text": "If thou couldst but see [how it will be on Judgment Day], when those who are lost in sin will hang their heads before their Sustainer, [saying:] “O our Sustainer! [Now] we have seen, and we have heard! Return us, then, [to our earthly life] that we may do good deeds: for [now], behold, we are certain [of the truth]!”",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 12,
          "number": 3515,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "Yet had We so willed, We could indeed have imposed Our guidance upon every human being: but [We have not willed it thus - and so] that word of Mine has come true: “Most certainly will I fill hell with invisible beings as well as with humans, all together!”",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 13,
          "number": 3516,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "[And He will say unto the sinners:] “Taste, then, [the recompense] for your having been obli­vious of the coming of this your Day [of Judgment] - for, verily, We are [now] oblivious of you: taste, then, [this] abiding suffering for all [the evil] that you were wont to do!”",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 14,
          "number": 3517,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "ONLY THEY [truly] believe in Our messages who, whenever they are conveyed to them, fall down, prostrating themselves in adoration, and extol their Sustainer’s limitless glory and praise; and who are never filled with false pride;",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 15,
          "number": 3518,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "[and] who are im­pelled to rise from their beds [at night] to call out to their Sustainer in fear and hope; and who spend on others out of what We provide for them as sus­tenance.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 16,
          "number": 3519,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "And [as for all such believers,] no human being can imagine what blissful delights, as yet hidden, await them [in the life to come] as a reward for all that they did.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 17,
          "number": 3520,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "Is, then, he who [in his earthly life] was a believer to be compared with one who was in­iquitous? [Nay,] these two are not equal!",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 18,
          "number": 3521,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "As for those who attain to faith and do right­eous deeds - gardens of rest await them, as a wel­come [from God], in result of what they did;",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 19,
          "number": 3522,
          "juz": 21,
          "numpage": 416
        },
        {
          "text": "but as for those who are lost in iniquity - their goal is the fire: as oft as they will try to come out of it, they will be thrown back into it; and they will be told, “Taste [now] this suffering through fire which you were wont to call a lie!”",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 20,
          "number": 3523,
          "juz": 21,
          "numpage": 416
        }
      ]
    },
    {
      "415": [
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 1,
          "number": 3504,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "The bestowal from on high of this divine writ issues, beyond any doubt, from the Sustainer of all the worlds:",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 2,
          "number": 3505,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "and yet, they [who are bent on denying the truth] assert, “[Muhammad] has invented it!” Nay, but it is the truth from thy Sustainer, enabling thee to warn [this] people to whom no warner has come before thee, so that they might follow the right path.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 3,
          "number": 3506,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "IT IS GOD who has created the heavens and the earth and all that is between them in six aeons, and is established on the throne of His almightiness. You have none to protect you from God, and none to intercede for you [on Judgment Day]: will you not, then, bethink yourselves?",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 4,
          "number": 3507,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "He governs all that exists, from the celestial space to the earth; and in the end all shall ascend unto Him [for judgment] on a Day the length whereof will be [like] a thousand years of your reckoning.",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 5,
          "number": 3508,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "Such is He who knows all that is beyond the reach of a created being’s perception, as well as all that can be witnessed by a creature’s senses or mind: the Almighty, the Dispenser of Grace,",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 6,
          "number": 3509,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "who makes most excellent everything that He creates. Thus, He begins the creation of man out of clay;",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 7,
          "number": 3510,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "then He causes him to be begotten out of the essence of a humble fluid;",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 8,
          "number": 3511,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "and then He forms him in accordance with what he is meant to be, and breathes into him of His spirit: and [thus, O men,] He endows you with hearing, and sight, and feelings as well as minds: [yet] how seldom are you grateful!",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 9,
          "number": 3512,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "For, [many are] they [who] say, “What! After we have been [dead and] lost in the earth, shall we indeed be [restored to life] in a new act of crea­tion?” Nay, but [by saying this] they deny the truth that they are destined to meet their Sustainer!",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 10,
          "number": 3513,
          "juz": 21,
          "numpage": 415
        },
        {
          "text": "Say: “[One day,] the angel of death who has been given charge of you will gather you, and then unto your Sustainer you will be brought back.”",
          "englishName": "As-Sajda",
          "suraNumber": 32,
          "numberInSurah": 11,
          "number": 3514,
          "juz": 21,
          "numpage": 415
        }
      ]
    },
    {
      "414": [
        {
          "text": "Art thou not aware that it is God who makes the night grow longer by shortening the day, and makes the day grow longer by shortening the night, and that He has made the sun and the moon subservient [to His laws], each running its course for a term set [by Him] and that God is fully aware of all that you do?",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 29,
          "number": 3498,
          "juz": 21,
          "numpage": 414
        },
        {
          "text": "Thus it is, because God alone is the Ultimate Truth, so that all that men invoke instead of Him is sheer falsehood; and because God alone is exalted, truly great!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 30,
          "number": 3499,
          "juz": 21,
          "numpage": 414
        },
        {
          "text": "Art thou not aware how the ships speed through the sea by God’s favour, so that He might show you some of His wonders? Herein, behold, there are messages indeed for all who are wholly patient in adversity and deeply grate­ful [to God].",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 31,
          "number": 3500,
          "juz": 21,
          "numpage": 414
        },
        {
          "text": "For [thus it is with most men:] when the waves engulf them like shadows [of death], they call unto God, sincere [at that moment] in their faith in Him alone: but as soon as He has brought them safe ashore, some of them stop half-way [between belief and unbelief] Yet none could knowingly reject Our messages unless he be utterly perfidious, ingrate.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 32,
          "number": 3501,
          "juz": 21,
          "numpage": 414
        },
        {
          "text": "O MEN! Be conscious of your Sustainer, and stand in awe of the Day on which no parent will be of any avail to his child, nor a child will in the least avail his parent! Verily, God’s promise [of resurrection] is true in­deed: let not, then, the life of this world deludes you, and let not [your own] deceptive thoughts about God delude you!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 33,
          "number": 3502,
          "juz": 21,
          "numpage": 414
        },
        {
          "text": "Verily, with God alone rests the knowledge of when the Last Hour will come: and He [it is who] sends down rain; and He [alone] knows what is in the wombs: whereas no one knows what he will reap tomorrow, and no one knows in what land he will die, Verily. God [alone] is all-knowing, all-aware.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 34,
          "number": 3503,
          "juz": 21,
          "numpage": 414
        }
      ]
    },
    {
      "413": [
        {
          "text": "ARE YOU NOT aware that God has made subservient to you all that is in the heavens and all that is on earth, and has lavished upon you His blessings, both outward and inward? And yet, among men there is many a one that argues about God without having any knowledge [of Him], without any guidance, and without any light-giving revelation;",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 20,
          "number": 3489,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "and when such [people] are told to follow that which God has bestowed from on high, they answer, “Nay, we shall follow that which we found our forefathers believing in and doing!” Why - [would you follow your forefathers] even if Satan had invited them unto the suffering of the blazing flame?",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 21,
          "number": 3490,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "Now whoever surrenders his whole being unto God, and is a doer of good withal, has indeed taken hold of a support most unfailing: for with God rests the final outcome of all events.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 22,
          "number": 3491,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "But as for him who is bent on denying the truth - let not his denial grieve thee: unto Us they must return, and then We shall make them [truly] understand all that they were doing [in life]: for, verily, God has full knowledge of what is in the hearts [of men].",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 23,
          "number": 3492,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "We will let them enjoy them­selves for a short while - but in the end We shall drive them into suffering severe.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 24,
          "number": 3493,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "AND THUS it is [with most people]: if thou ask them, “Who is it that has created the heavens and the earth?” - they will surely answer, “God.” Say: “[Then you ought to know that] all praise is due to God!”- for most of them do not know [what this implies].",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 25,
          "number": 3494,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "Unto God belongs all that is in the heavens and on earth. Verily, God alone is self-sufficient, the One to whom all praise is due!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 26,
          "number": 3495,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "And if all the trees on earth were pens, and the sea [were] ink, with seven [morel seas yet added to it, the words of God would not be exhausted: for, verily, God is almighty, wise.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 27,
          "number": 3496,
          "juz": 21,
          "numpage": 413
        },
        {
          "text": "[For Him,] the creation of you all and the resurrection of you all is but like [the creation and resurrection of] a single soul: for, verily, God is all-hearing, all-seeing.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 28,
          "number": 3497,
          "juz": 21,
          "numpage": 413
        }
      ]
    },
    {
      "412": [
        {
          "text": "and, indeed, we granted this wisdom unto Luq­man: “Be grateful unto God - for he who is grateful [unto Him] is but grateful for the good of his own self; whereas he who chooses to be ungrateful [ought to know that], verily, God is self-sufficient, ever to be praised!”",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 12,
          "number": 3481,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "And, lo, Luqman spoke thus unto his son, admonishing him: “O my dear son! Do not ascribe divine powers to aught beside God: for, behold, such [a false] ascribing of divinity is indeed an awesome wrong!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 13,
          "number": 3482,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "“And [God says:] ‘We have enjoined upon man goodness towards his parents: his mother bore him by bearing strain upon strain, and his utter dependence on her lasted two years: [hence, O man,] be grateful towards Me and towards thy parents, [and remember that] with Me is all journeys’ end.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 14,
          "number": 3483,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "“‘[Revere thy parents;] yet should they endeavour to make thee ascribe divinity, side by side with Me, to something which thy mind cannot accept [as divine], obey them not; but [even then] bear them company in this world’s life with kindness, and follow the path of those who turn towards Me. In the end, unto Me you all must return; and thereupon I shall make you [truly] understand all that you were doing [in life].’",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 15,
          "number": 3484,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "“O my dear son,” [continued Luqman,] “verily, if there be but the weight of a mustard-seed, and though it be [hidden] in a rock, or in the skies, or in the earth, God will bring it to light: for, behold, God is unfathomable [in His wisdom], all-aware",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 16,
          "number": 3485,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "“O my dear son! Be constant in prayer, and enjoin the doing of what is right and forbid the doing of what is wrong, and bear in patience whatever [ill] may befall thee: this, behold, is something to set one’s heart upon!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 17,
          "number": 3486,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "“And turn not thy cheek away from people in [false] pride, and walk not haughtily on earth: for, behold, God does not love anyone who, out of self- conceit, acts in a boastful manner.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 18,
          "number": 3487,
          "juz": 21,
          "numpage": 412
        },
        {
          "text": "“Hence, be modest in thy bearing, and lower thy voice: for, behold, the ugliest of all voices is the [loud] voice of asses…”",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 19,
          "number": 3488,
          "juz": 21,
          "numpage": 412
        }
      ]
    },
    {
      "411": [
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 1,
          "number": 3470,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "THESE ARE MESSAGES of the divine writ, full of wisdom,",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 2,
          "number": 3471,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "providing guidance and grace unto the doers of good",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 3,
          "number": 3472,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "who are constant in prayer and dispense charity: for it is they, they who in their innermost are certain of the life to come!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 4,
          "number": 3473,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "It is they who follow the guidance [that comes to them] from their Sustainer; and it is they, they who shall attain to a happy state!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 5,
          "number": 3474,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "But among men there is many a one that prefers a mere play with words [to divine guidance], so as to lead [those] without knowledge astray from the path of God, and to turn it to ridicule: for such there is shameful suffering in store.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 6,
          "number": 3475,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "For, whenever Our messages are conveyed to such a one, he turns away in his arrogance as though he had not heard them - as though there were deafness in his ears. Give him, then, the tiding of grievous suffering [in the life to come].",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 7,
          "number": 3476,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "[As against this,] verily, those who attain to faith and do righteous deeds shall have gardens of bliss,",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 8,
          "number": 3477,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "to abide therein in accordance with God’s true promise: for He alone is almighty, truly wise.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 9,
          "number": 3478,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "He [it is who] has created the skies without any supports that you could see, and has placed firm mountains upon the earth, lest it sway with you, and has caused all manner of living creatures to multiply thereon. And We send down water from the skies, and thus We cause every noble kind [of life] to grow on earth.",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 10,
          "number": 3479,
          "juz": 21,
          "numpage": 411
        },
        {
          "text": "[All] this is God’s creation: show Me, then, what others than He may have created! Nay, but the evildoers are obviously lost in error!",
          "englishName": "Luqman",
          "suraNumber": 31,
          "numberInSurah": 11,
          "number": 3480,
          "juz": 21,
          "numpage": 411
        }
      ]
    },
    {
      "410": [
        {
          "text": "But thus it is: if We send a wind [that scorches their land], and they see it turn yellow, they begin, after that [erstwhile joy], to deny the truth [of Our almightiness and grace]!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 51,
          "number": 3460,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "And, verily, thou canst not make the dead hear: and [so, too,] thou canst not make the deaf [of heart] hear this call when they turn their backs [on thee] and go away,",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 52,
          "number": 3461,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "just as thou canst not lead the blind [of heart] out of their error: none canst thou make hear [thy call] save such as [are willing to] believe in Our messages, and thus surrender them­selves unto Us.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 53,
          "number": 3462,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "IT IS GOD who creates you [all in a states of weak­ness, and then, after weakness, ordains strength [for you], and then, after [a period of] strength, ordains [old-age] weakness and grey hair. He creates what He wills; and He alone is all-knowing, infinite in His power.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 54,
          "number": 3463,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "[He it is who will cause you to die, and in time will resurrect you.] And when the Last Hour dawns, those who had been lost in sin will swear that they had not tarried [on earth] longer than an hour: thus were they wont to delude themselves [all their lives]!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 55,
          "number": 3464,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "But those who [in their lifetime] were endowed with knowledge and faith will say: “Indeed, you have been tardy in [accepting as true] what God has revealed, [and you have waited] until the Day of Resurrection: this, then, is the Day of Resurrection: but you - you were determined not to know it!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 56,
          "number": 3465,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "And so, on that Day their excuse will be of no avail to those who were bent on evildoing, nor will they be allowed to make amends.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 57,
          "number": 3466,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "AND, INDEED, We have propounded unto men all kinds of parables in this Qur’an. But thus it is: if thou approach them with any [such] message, those who are bent on denying the truth are sure to say, “You are but making false claims!”",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 58,
          "number": 3467,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "In this way does God seal the hearts of those who do not [want to] know [the truth].",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 59,
          "number": 3468,
          "juz": 21,
          "numpage": 410
        },
        {
          "text": "Remain, then, patient in adversity: verily, Gods promise [of resurrection] is true indeed - so let not those who are devoid of all inner certainty disquiet thy mind!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 60,
          "number": 3469,
          "juz": 21,
          "numpage": 410
        }
      ]
    },
    {
      "409": [
        {
          "text": "Say: “Go all over the earth, and behold what happened in the end to those [sinners] who lived before [you]: most of them were wont to ascribe divine qualities to things or beings other than God.”",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 42,
          "number": 3451,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "Set, then, thy face steadfastly towards the one ever-true faith, ere there come from God a Day [of reckoning - the Day] which cannot be averted. On that Day all will be sundered:",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 43,
          "number": 3452,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "he who has denied the truth will have to bear [the burden of] his denial, whereas all who did what is right and just will have made goodly provision for themselves,",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 44,
          "number": 3453,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "so that He might reward, out of His bounty, those who have attained to faith and done righteous deeds. Verily, He does not love those who refuse to acknowledge the truth –",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 45,
          "number": 3454,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "for among His wonders is this: He sends forth [His messages as He sends forth] the winds that bear glad tidings, so that He might give you a taste of His grace [through life-giving rains], and that ships might sail at His behest, and that you might go about in quest of some of His bounties, and that you might have cause to be grateful.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 46,
          "number": 3455,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "And indeed, [O Muhammad, even] before thee did We send forth apostles - each one unto his own people and they brought them all evidence of the truth: and then, [by causing the believers to triumph,] We inflicted Our retribution upon those who [de­liberately] did evil: for We had willed it upon Our­selves to succour the believers.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 47,
          "number": 3456,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "It is God who sends forth the winds [of hope], so that they raise a cloud - whereupon He spreads it over the skies as He wills, and causes it to break up so that thou seest rain issue from within it: and as soon as He causes it to fall upon whomever He wills of His servants - lo! they rejoice,",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 48,
          "number": 3457,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "even though a short while ago, [just] before it was sent down upon them, they had abandoned all hope!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 49,
          "number": 3458,
          "juz": 21,
          "numpage": 409
        },
        {
          "text": "Behold, then, [O man,] these signs of God’s grace - how He gives life to the earth after it had been lifeless! Verily, this Selfsame [God] is indeed the One that can bring the dead back to life: for He has the power to will anything!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 50,
          "number": 3459,
          "juz": 21,
          "numpage": 409
        }
      ]
    },
    {
      "408": [
        {
          "text": "NOW [thus it is:] when affliction befalls men, they cry out unto their Sustainer, turning unto Him [for help]; but as soon as He lets them taste of His grace, lo! some of them [begin to] ascribe to other powers a share in their Sustainer’s divinity,",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 33,
          "number": 3442,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "[as if] to prove their ingratitude for all that We have granted them! Enjoy, then, your [brief] life: but in time you will come to know [the truth]!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 34,
          "number": 3443,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "Have We ever bestowed upon them from on high a divine writ which would speak [with appro­val] of their worshipping aught beside Us?",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 35,
          "number": 3444,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "And [thus it is:] when we let men taste [Our] grace, they rejoice in it; but if evil befalls them as an outcome of what their own hands have wrought lo! they lose all hope!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 36,
          "number": 3445,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "Are they, then, not aware that it is God who grants abundant sustenance, or gives it in scant measure, unto whomever He wills? In this, behold, there are messages indeed for peo­ple who will believe!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 37,
          "number": 3446,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "Hence, give his due to the near of kin, as well as to the needy and the wayfarer; this is best for all who seek God’s countenance: for it is they, they that shall attain to a happy state!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 38,
          "number": 3447,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "And [remember:] whatever you may give out in usury so that it might increase through [other] people’s possessions will bring [you] no increase in the sight of God whereas all that you give out in charity, seeking God’s countenance, [will be blessed by Him:] for it is they, they [who thus seek His countenance] that shall have their recompense mul­tiplied!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 39,
          "number": 3448,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "IT IS GOD who has created you, and then has provided you with sustenance, and then will cause you to die, and then will bring you to life again. Can any of those beings or powers to whom you ascribe a share in His divinity do any of these things? Limitless is He in His glory, and sublimely exalted above anything to which men may ascribe a share in His divinity!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 40,
          "number": 3449,
          "juz": 21,
          "numpage": 408
        },
        {
          "text": "[Since they have become oblivious of God,] corruption has appeared on land and in the sea as an outcome of what men’s hands have wrought: and so He will let them taste [the evil of] some of their doings, so that they might return [to the right path].",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 41,
          "number": 3450,
          "juz": 21,
          "numpage": 408
        }
      ]
    },
    {
      "407": [
        {
          "text": "And among His wonders is this: the skies and the earth stand firm at His behest. [Remember all this: for] in the end, when He will call you forth from the earth with a single call - lo! you will [all] emerge [for judgment].",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 25,
          "number": 3434,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "For, unto Him belongs every being that is in the heavens and on earth; all things devoutly obey His will.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 26,
          "number": 3435,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "And He it is who creates [all life] in the first instance, and then brings it forth anew: and most easy is this for Him, since His is the essence of all that is most sublime in the heavens and on earth, and He alone is almighty, truly wise.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 27,
          "number": 3436,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "He propounds unto you a parable drawn from your own life: Would you [agree to] have some of those whom your right hands possess as [full-fledged] partners in whatever We may have bestowed upon you as sustenance, so that you [and they] would have equal shares in it, and you would fear [to make use of it without consulting] them, just as you might fear [the more powerful of] your equals? Thus clearly do We spell out these messages unto people who use their reason.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 28,
          "number": 3437,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "But nay - they who are bent on evildoing fol­low but their own desires, without having any knowl­edge (of the truth). And who could guide those whom God has [thus] let go astray, and who (thereupon) have none to succour them?",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 29,
          "number": 3438,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "AND SO, set thy face steadfastly towards the [one ever-true] faith, turning away from all that is false, in accordance with the natural disposition which God has instilled into man: [for,] not to allow any change to corrupt what God has thus created this is the [purpose of the one] ever-true faith; but most people know it not.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 30,
          "number": 3439,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "[Turn, then, away from all that is false,] turn­ing unto Him [alone]; and remain conscious of Him, and be constant in prayer, and be not among those who ascribe divinity to aught beside Him,",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 31,
          "number": 3440,
          "juz": 21,
          "numpage": 407
        },
        {
          "text": "[or] among those who have broken the unity of their faith and have become sects, each group delighting in but what they themselves hold [by way of tenets].",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 32,
          "number": 3441,
          "juz": 21,
          "numpage": 407
        }
      ]
    },
    {
      "406": [
        {
          "text": "but as for those who refused to acknowledge the truth and gave the lie to Our messages - and (thus) to the announce­ment of a life to come - they will be given over to suffering.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 16,
          "number": 3425,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "EXTOL, then, God’s limitless glory when you enter upon the evening hours, and when you rise at morn;",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 17,
          "number": 3426,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "and [seeing that] unto Him is due all praise in the heavens and on earth, [glorify Him] in the afternoon as well, and when you enter upon the hour of noon.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 18,
          "number": 3427,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "He [it is who] brings forth the living out of that which is dead, and brings forth the dead out of that which is alive, and gives life to the earth after it had been lifeless: and even thus will you be brought forth [from death to life]",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 19,
          "number": 3428,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "And among His wonders is this: He creates you out of dust – and then, lo! you become human beings ranging far and wide!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 20,
          "number": 3429,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "And among His wonders is this: He creates for you mates out of your own kind. so that you might incline towards them, and He engenders love and tenderness between you: in this, behold, there are messages indeed for people who think!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 21,
          "number": 3430,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "And among his wonders is the creation of the heavens and the earth, and the diversity of your tongues and colours: for in this, behold, there are messages indeed for all who are possessed of [innate] knowledge!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 22,
          "number": 3431,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "And among His wonders is your sleep, at night or in daytime, as well as your [ability to go about in] quest of some of His bounties: in this, behold, there are messages indeed for people who [are willing to] listen!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 23,
          "number": 3432,
          "juz": 21,
          "numpage": 406
        },
        {
          "text": "And among His wonders is this: He displays before you the lightning, giving rise to [both] fear and hope, and sends down water from the skies, giving life thereby to the earth after it had been lifeless: in this, behold, there are messages indeed for people who use their reason!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 24,
          "number": 3433,
          "juz": 21,
          "numpage": 406
        }
      ]
    },
    {
      "405": [
        {
          "text": "[This is] God’s promise. Never does God fail to fulfill His promise - but most people know [it] not:",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 6,
          "number": 3415,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "they know but the outer surface of this world’s life, whereas of the ultimate things they are utterly un­aware.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 7,
          "number": 3416,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "Have they never learned to think for them­selves? God has not created the heavens and the earth and all that is between them without [an inner] truth and a term set [by Him]: and yet, behold, there are many people who stubbornly deny the truth that they are destined to meet their Sustainer!",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 8,
          "number": 3417,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "Have they, then, never journeyed about the earth and beheld what happened in the end to those [deniers of the truth] who lived before their time? Greater were they in power than they are; and they left a stronger impact on the earth, and built it up even better than these [are doing]; and to them [too] came their apostles with all evidence of the truth: and so, [when they rejected the truth and thereupon perished,] it was not God who wronged them, but it was they who had wronged themselves.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 9,
          "number": 3418,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "And once again: evil is bound to be the end of those who do evil by giving the lie to God’s messages and deriding them.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 10,
          "number": 3419,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "GOD CREATES [man] in the first instance, and then brings him forth anew: and, in the end, unto Him you all will be brought back.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 11,
          "number": 3420,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "And when the last Hour dawns, those who were lost in sin will be broken in spirit",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 12,
          "number": 3421,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "for they will have no intercessors in the beings to whom they were wont to ascribe a share in God’s divinity, seeing that [by then] they themselves will have ceased to believe in their erstwhile blasphemous fancies.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 13,
          "number": 3422,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "And when the Last Hour dawns - on that Day will all [men] be sorted out:",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 14,
          "number": 3423,
          "juz": 21,
          "numpage": 405
        },
        {
          "text": "as for those who attained to faith and did righteous deeds, they shall be made happy in a garden of delight;",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 15,
          "number": 3424,
          "juz": 21,
          "numpage": 405
        }
      ]
    },
    {
      "404": [
        {
          "text": "for, [if they did, they would know that] the life of this world is nothing but a passing delight and a play - whereas, behold, the life in the hereafter is indeed the only [true] life: if they but knew this!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 64,
          "number": 3404,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "And so, when they embark on a ship [and find themselves in danger], they call unto God, [at that moment] sincere in their faith in Him alone; but as soon as He has brought them safe ashore, they [begin to] ascribe to imaginary powers a share in His divinity:",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 65,
          "number": 3405,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "and thus they show utter ingratitude for all that We have vouchsafed them, and go on [thoughtlessly] enjoying their worldly life.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 66,
          "number": 3406,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "Are they, then, not aware that We have set up a sanctuary secure [for those who believe in Us], the while all around them men are being carried away [by fear and despair]? Will they, then, [continue to] believe in things false and vain, and thus deny God’s blessings.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 67,
          "number": 3407,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "And who could be more wicked than he who attributes his own lying inventions to God, or gives the lie to the truth when it comes unto him [through revelation]? Is not hell the [proper] abode for all who [thus] deny the truth?",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 68,
          "number": 3408,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "But as for those who strive hard in Our cause -We shall most certainly guide them onto paths that lead unto Us: for, behold, God is indeed with the doers of good.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 69,
          "number": 3409,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 1,
          "number": 3410,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "Defeated have been the Byzantines",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 2,
          "number": 3411,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "in the lands close-by; yet it is they who, notwithstanding this their defeat, shall be victorious",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 3,
          "number": 3412,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "within a few years: [for] with God rests all power of decision, first and last. And on that day will the believers [too, have cause to] rejoice",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 4,
          "number": 3413,
          "juz": 21,
          "numpage": 404
        },
        {
          "text": "in God’s succour: [for] He gives suc­cour to whomever He wills, since He alone is almighty, a dispenser of grace.",
          "englishName": "Ar-Room",
          "suraNumber": 30,
          "numberInSurah": 5,
          "number": 3414,
          "juz": 21,
          "numpage": 404
        }
      ]
    },
    {
      "403": [
        {
          "text": "Now they challenge thee to hasten the coming upon them of [God’s] chastisement: and indeed, had not a term been set [for it by God], that suffering would already have come upon them! But indeed, it will most certainly come upon them of a sudden, and they will be taken unawares.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 53,
          "number": 3393,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "They challenge thee to hasten the coming upon them of [God’s] chastisement: but, verily, hell is bound to encompass all who deny the truth –",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 54,
          "number": 3394,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "[encompass them] on the Day when suffering will overwhelm them from above them and from beneath their feet, whereupon He shall say: “Taste [now the fruit of] your own doings!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 55,
          "number": 3395,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "O YOU servants of Mine who have attained to faith! Behold, wide is Mine earth: worship Me, then, Me alone!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 56,
          "number": 3396,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "Every human being is bound to taste death, [and] in the end unto Us shall all be brought back:",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 57,
          "number": 3397,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "whereupon unto those who have attained to faith and wrought good works We shall most certainly assign mansions in that paradise through which run­ning waters flow, therein to abide: how excellent a reward for those who labour –",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 58,
          "number": 3398,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "those who are patient in adversity and in their Sustainer place their trust!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 59,
          "number": 3399,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "And how many a living creature is there that takes no thought of its own sustenance; [the while] God provides for it as [He provides] for you - since He alone is all-hearing, all-knowing.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 60,
          "number": 3400,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "And thus it is [with most people]: if thou ask them, “Who is it that has created the heavens and the earth, and made the sun and the moon subservi­ent [to His laws]?” - they will surely answer, “God.” How perverted, then, are their minds!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 61,
          "number": 3401,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "God grants abundant sustenance, or gives it in scant measure, to whichever He wills of His crea­tures: for, behold, God has full knowledge of every­thing.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 62,
          "number": 3402,
          "juz": 21,
          "numpage": 403
        },
        {
          "text": "And thus it is: if thou ask them, “Who is it that sends down water from the skies, giving life thereby to the earth after it had been lifeless?” - they will surely answer, “God.” Say thou: “[Since this is so,] all praise is due to God [alone]!” But most of them will not use their reason:",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 63,
          "number": 3403,
          "juz": 21,
          "numpage": 403
        }
      ]
    },
    {
      "402": [
        {
          "text": "And do not argue with the followers of earlier revelation otherwise than in a most kindly manner - unless it be such of them as are bent on evildoing and say: “We believe in that which has been be­stowed from on high upon us, as well as that which has been bestowed upon you: or our God and your God is one and the same, and it is unto Him that We [all] surrender ourselves.”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 46,
          "number": 3386,
          "juz": 21,
          "numpage": 402
        },
        {
          "text": "For it is thus that We have bestowed this divine writ from on high upon thee [O Muhammad]. And they to whom we have vouchsafed this divine writ believe in it just as among those [followers of earlier revelation] there are some who believe in it. And none could knowingly reject Our messages unless it be such as would deny [an obvious] truth:",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 47,
          "number": 3387,
          "juz": 21,
          "numpage": 402
        },
        {
          "text": "for, [O Muhammad,] thou hast never been able to recite any divine writ ere this one [was revealed], nor didst thou ever transcribe one with your own hand or else, they who try to disprove the truth [of thy revelation] might indeed have had cause to doubt [it].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 48,
          "number": 3388,
          "juz": 21,
          "numpage": 402
        },
        {
          "text": "Nay, but this [divine writ] consists of messages clear to the hearts of all who are gifted with [innate] knowledge and none could knowingly reject Our messages unless it be such as would do wrong [to themselves].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 49,
          "number": 3389,
          "juz": 21,
          "numpage": 402
        },
        {
          "text": "And yet they say, “Why have no miraculous signs ever been bestowed upon him from on high by his Sustainer?” Say: “Miracles are in the power of God alone; and as for me - I am but a plain warner.”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 50,
          "number": 3390,
          "juz": 21,
          "numpage": 402
        },
        {
          "text": "Why - is it not enough for them that We have bestowed this divine writ on thee from on high, to be conveyed [by thee] to them? For, verily, in it is [manifested Our] grace, and a reminder to people who will believe.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 51,
          "number": 3391,
          "juz": 21,
          "numpage": 402
        },
        {
          "text": "Say [unto those who will not believe]: “God is witness enough between me and you! He knows all that is in the heavens and on earth; and they who are bent on believing in what is false and vain, and thus on denying God - it is they, they who shall be the losers!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 52,
          "number": 3392,
          "juz": 21,
          "numpage": 402
        }
      ]
    },
    {
      "401": [
        {
          "text": "And [thus, too, did We deal with] Qarun and Pharaoh, and Haman: to them had come Moses with all evidence of the truth, but they behaved arrogantly on earth [and rejected him]; and withal, they could not escape [Us].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 39,
          "number": 3379,
          "juz": 20,
          "numpage": 401
        },
        {
          "text": "For, every one of them, did We take to task for his sin: and so, upon some of them We let loose a deadly storm wind; and some of them were overtaken by a [sudden] blast; and some of them We caused to be swallowed by the earth: and some of them We caused to drown. And it was not God who wronged them, but it was they who had wronged themselves.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 40,
          "number": 3380,
          "juz": 20,
          "numpage": 401
        },
        {
          "text": "The parable of those who take [beings or forces] other than God for their protectors is that of the spider which makes for itself a house: for, behold, the frailest of all houses is the spiders house. Could they but understand this!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 41,
          "number": 3381,
          "juz": 20,
          "numpage": 401
        },
        {
          "text": "Verily, God knows whatever it is that men invoke instead of Him for He alone is almighty, truly wise.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 42,
          "number": 3382,
          "juz": 20,
          "numpage": 401
        },
        {
          "text": "And so We propound these parables unto man: but none can grasp their innermost meaning save those who [of Us] are aware",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 43,
          "number": 3383,
          "juz": 20,
          "numpage": 401
        },
        {
          "text": "[and hence are certain that] God has created the heavens and the earth in accordance with [an inner] truth: for, behold, in this [very creation] there is a message indeed for all who believe [in Him].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 44,
          "number": 3384,
          "juz": 20,
          "numpage": 401
        },
        {
          "text": "CONVEY [unto others] whatever of this divine writ has been revealed unto thee, and be constant in prayer: for, behold, prayer restrains [man] from loathsome deeds and from all that runs counter to reason; and remembrance of God is indeed the greatest [good]. And God knows all that you do.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 45,
          "number": 3385,
          "juz": 20,
          "numpage": 401
        }
      ]
    },
    {
      "400": [
        {
          "text": "And so, when Our [heavenly] messengers came to Abraham with the glad tiding [of the birth of Isaac], they [also] said, “Behold, we are about to destroy the people of that land, for its people are truly evildoers!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 31,
          "number": 3371,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "[And when Abraham] exclaimed, “But Lot lives there!” - they answered: We know fully well who is there; most certainly we shall save him and his household - all but his wife: she will indeed be among those that stay behind.”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 32,
          "number": 3372,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "And when Our messengers came unto Lot, he was sorely grieved on their account, seeing that it was beyond his power to shield them; but they said: “Fear not, and grieve not! Behold, we shall save thee and thy household - all but thy wife: she will indeed be among those that stay behind.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 33,
          "number": 3373,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "Verily, we shall bring down upon the people of this land a horror from heaven in requital of all their iniquitous doings!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 34,
          "number": 3374,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "And [so it happened; and] thereof, indeed, We have left a clear sign for people who use their reason.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 35,
          "number": 3375,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "AND UNTO [the people of] Madyan [We sent] their brother Shu’ayb who thereupon said: “O my peo­ple! Worship God [alone], and look forward to the Last Day, and do not act wickedly on earth by spreading corruption!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 36,
          "number": 3376,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "But they gave him the lie. Thereupon an earthquake overtook them: and then they lay lifeless, in their very homes, on the ground.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 37,
          "number": 3377,
          "juz": 20,
          "numpage": 400
        },
        {
          "text": "AND [the tribes of] Ad and Thamud [too, did We destroy -] as should have become obvious to you from [whatever there remains of] their dwellings. [They perished] because Satan had made their [sinful] doings seem goodly to them, and thus had barred them from the path [of God] despite their having been endowed with the ability to perceive the truth.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 38,
          "number": 3378,
          "juz": 20,
          "numpage": 400
        }
      ]
    },
    {
      "399": [
        {
          "text": "Now [as for Abraham,] his people’s only answer was, “Slay him, or burn him!” – but God saved him from the fire. Behold, in this [story] there are messages indeed for people who will believe!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 24,
          "number": 3364,
          "juz": 20,
          "numpage": 399
        },
        {
          "text": "And [Abraham] said: “You have chosen to worship idols instead of God for no other reason than to have a bond of love in the life of this world, between yourselves [and your forebears]: but then, on Resurrection Day, you shall disown one another and curse one another - for the goal of you all will be the fire, and you will have none to succour you.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 25,
          "number": 3365,
          "juz": 20,
          "numpage": 399
        },
        {
          "text": "Thereupon [his brother’s son] Lot came to believe in him and said: “Verily, I [too] shall forsake the domain of evil [and turn] to my Sustainer: for, verily, He alone is almighty, truly wise!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 26,
          "number": 3366,
          "juz": 20,
          "numpage": 399
        },
        {
          "text": "And [as for Abraham,] We bestowed upon him Isaac and [Isaac’s son] Jacob, and caused pro­phethood and revelation to continue among his offspring. And We vouchsafed him his reward in this world; and, verily, in the life to come [too] he shall find himself among the righteous.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 27,
          "number": 3367,
          "juz": 20,
          "numpage": 399
        },
        {
          "text": "And Lot, [too, was inspired by Us] when he said unto his people: “Verily, you commit abominations such as none in all the world has ever committed before you!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 28,
          "number": 3368,
          "juz": 20,
          "numpage": 399
        },
        {
          "text": "Must you indeed approach men [with lust], and thus cut across the way [of nature]? and must you commit these shameful deeds in your open] assemblies?” But his people’s only answer was, “Bring down upon us God’s chastisement, if thou art a man of truth!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 29,
          "number": 3369,
          "juz": 20,
          "numpage": 399
        },
        {
          "text": "[And] he prayed: “O my Sustainer! Succour Thou me against these people who spread corrup­tion!”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 30,
          "number": 3370,
          "juz": 20,
          "numpage": 399
        }
      ]
    },
    {
      "398": [
        {
          "text": "but We saved him, together with all who were in the ark, which We then set up as a symbol [of Our grace] for all people [to remember].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 15,
          "number": 3355,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "And Abraham, [too, was inspired by Us] when he said unto his people: “Worship God, and be conscious of Him: this is the best for you, if you but knew it!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 16,
          "number": 3356,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "You worship only [lifeless] idols instead of God, and [thus] you give visible shape to a lie! Behold, those [things and beings] that you worship instead of God have it not in their power to provide sustenance for you: seek, then, all [your] sustenance from God, and worship Him [alone] and be grateful to Him: [for] unto Him you shall be brought back!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 17,
          "number": 3357,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "“And if you give [me] the lie - well, [other] communities have given the lie [to Gods prophets] before your time: but no more is an apostle bound to do than clearly deliver the message [entrusted to him].”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 18,
          "number": 3358,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "Are then they [who deny the truth] not aware of how God creates [life] in the first instance, and then brings it forth anew? This, verily, is easy for God!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 19,
          "number": 3359,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "Say: “Go all over the earth and behold how [wondrously] He has created [man] in the first in­stance: and thus, too, will God bring into being your second life for, verily, God has the power to will anything!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 20,
          "number": 3360,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "He causes to suffer whomever He wills, and bestows His mercy on whomever He wills; and unto Him you shall be made to return:",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 21,
          "number": 3361,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "and never - not on earth and not in the skies - can you [hope to] elude Him: and you have none to protect you from God, and none to bring you succour.”",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 22,
          "number": 3362,
          "juz": 20,
          "numpage": 398
        },
        {
          "text": "And [thus it is:] they who are bent on denying the truth of God’s messages and of their [ultimate] meeting with Him - it is they who abandon all hope of My grace and mercy: and it is they whom grievous suffering awaits [in the life to come].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 23,
          "number": 3363,
          "juz": 20,
          "numpage": 398
        }
      ]
    },
    {
      "397": [
        {
          "text": "And as for those who attain to faith and do righteous deeds, We shall most certainly efface their [previous] bad deeds, and shall most certainly reward them in accordance with the best that they ever did.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 7,
          "number": 3347,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "Now [among the best of righteous deeds which] We have enjoined upon man [is] goodness towards his parents; yet [even so,] should they endeavour to make thee ascribe divinity, side by side with Me, to something which thy mind cannot accept [as divine], obey then, not: [for] it is unto Me that you all must return, whereupon I shall make you [truly] understand [the right and wrong of] all that you were doing [in life].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 8,
          "number": 3348,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "But as for those who have attained to faith and have done righteous deeds, We shall most certainly cause them to join the righteous [in the hereafter as well].",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 9,
          "number": 3349,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "Now there is among men many a one who says [of himself and of others like him], “We do believe in God” - but whenever he is made to suffer in God’s cause, he thinks that persecution at the hands of man is as [much to be feared, or even more than,] God’s chastisement; whereas, if succour from thy Sustainer comes [to those who truly believe] he is sure to say. “Behold, we have always been with you!” Is not God fully aware of what is in the hearts of all creatures?",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 10,
          "number": 3350,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "[Yea-] and most certainly will God mark out those who have [truly] attained to faith, and most certainly will He mark out the hypocrites.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 11,
          "number": 3351,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "And [He is aware, too, that] they who are bent on denying the truth speak [thus, as it were,] to those who have attained to faith: “Follow our way [of life], and we shall indeed take your sins upon ourselves!” But never could they take upon themselves aught of the sins of those [whom they would thus mislead]: behold, they are liars indeed!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 12,
          "number": 3352,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "Yet most certainly will they have to bear their own burdens, and other burdens besides their own; and most certainly will they be called to account on Resurrection Day for all their false assertions!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 13,
          "number": 3353,
          "juz": 20,
          "numpage": 397
        },
        {
          "text": "And, indeed, [in times long past] We sent forth Noah unto his people, and he dwelt among them a thousand years bar fifty; and then the floods over­whelmed them while they were still lost in evildoing:",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 14,
          "number": 3354,
          "juz": 20,
          "numpage": 397
        }
      ]
    },
    {
      "396": [
        {
          "text": "VERILY, [O believer,] He who has laid down this Qur’an in plain terms, making it binding on thee, will assuredly bring thee back [from death] to a life renewed. Say [unto those who reject the truth]: My Sus­tainer knows best, as to who is right-guided and who is obviously lost in error!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 85,
          "number": 3337,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "Now [as for thyself, O believer,] thou couldst never foresee that this divine writ would [one day] be offered to thee: but [it did come to thee] by thy Sustainer’s grace. Hence, never uphold those who deny the truth [of divine guidance],",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 86,
          "number": 3338,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "and never let them turn thee away from God’s messages after they have been bestowed upon thee from on high: instead summon [all men] to thy Sustainer. And never be of those who ascribe divinity to aught but Him,",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 87,
          "number": 3339,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "and never call upon any other deity side by side with God. There is no deity save Him. Everything is bound to perish, save His [eternal] self. With Him rests all judgment; and unto Him shall you all be brought back.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 88,
          "number": 3340,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 1,
          "number": 3341,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "DO MEN THINK that on their [mere] saying, “We have attained to faith”, they will be left to themselves, and will not be put to a test?",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 2,
          "number": 3342,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "Yea, indeed, We did test those who lived before them; and so, [too, shall be tested the people now living: and] most certainly will God mark out those who prove themselves true, and most certainly will He mark out those who are lying.",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 3,
          "number": 3343,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "Or do they think - they who do evil deeds [while claiming to have attained to faith] - that they can escape Us? Bad, indeed, is their judgment!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 4,
          "number": 3344,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "Whoever looks forward [with hope and awe] to meeting God [on Resurrection Day, let him be ready for it]: for, behold, the end set by God [for every­one’s life] is bound to come - and He alone is all-hearing, all-knowing!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 5,
          "number": 3345,
          "juz": 20,
          "numpage": 396
        },
        {
          "text": "Hence, whoever strives hard [in God’s cause] does so only for his own good: for, verily, God does not stand in need of anything in all the worlds!",
          "englishName": "Al-Ankaboot",
          "suraNumber": 29,
          "numberInSurah": 6,
          "number": 3346,
          "juz": 20,
          "numpage": 396
        }
      ]
    },
    {
      "395": [
        {
          "text": "Answered he: “This [wealth] has been given to me only by virtue of the knowledge that is in me!” Did he not know that God had destroyed [the arrogant of] many a generation that preceded him - people who were greater than he in power, and richer in what they had amassed? But such as are lost in sin may not be asked about, their sins.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 78,
          "number": 3330,
          "juz": 20,
          "numpage": 395
        },
        {
          "text": "And so he went forth before his people in all his pomp; [and] those who cared only for the life of this world would say, “Oh, if we but had the like of what Qarun has been given! Verily, with tremendous good fortune is he endowed!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 79,
          "number": 3331,
          "juz": 20,
          "numpage": 395
        },
        {
          "text": "But those who had been granted true knowl­edge said: “Woe unto you! Merit in the sight of God is by far the best for any who attains to faith and does what is right: but none save the patient in adversity can ever achieve this [blessing].”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 80,
          "number": 3332,
          "juz": 20,
          "numpage": 395
        },
        {
          "text": "And thereupon We caused the earth to swallow him and his dwelling; and he had none and nothing to succour him against God, nor was he of those who could succour themselves.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 81,
          "number": 3333,
          "juz": 20,
          "numpage": 395
        },
        {
          "text": "And on the morrow, those who but yesterday had longed to be in his place exclaimed: “Alas [for our not having been aware] that it is indeed God [alone] who grants abundant sustenance, or gives it in scant measure, unto whichever He wills of His crea­tures! Had not God been gracious to us, He might have caused [the earth] to swallow us, too! Alas [for our having forgotten] that those who deny the truth can never attain to a happy state!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 82,
          "number": 3334,
          "juz": 20,
          "numpage": 395
        },
        {
          "text": "As for that [happy] life in the hereafter, We grant it [only] to those who do not seek to exalt themselves on earth, nor yet to spread corruption: for the future belongs to the God-conscious.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 83,
          "number": 3335,
          "juz": 20,
          "numpage": 395
        },
        {
          "text": "Whosoever shall come [before God] with a good deed will gain [further] good there from; but as for any who shall come with an evil deed - [know that] they who do evil deeds will not be requited with more than [the like of] what they have done.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 84,
          "number": 3336,
          "juz": 20,
          "numpage": 395
        }
      ]
    },
    {
      "394": [
        {
          "text": "Say: “Have you ever considered [this]: If God had willed that there should always be night about you, without break, until the Day of Resurrection - is there any deity other than God that could bring you light? Will you not, then, listen [to the truth]?”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 71,
          "number": 3323,
          "juz": 20,
          "numpage": 394
        },
        {
          "text": "Say: “Have you ever considered [this]: If God had willed that there should always be daylight about you, without break, until the Day of Resurrection - is there any deity other than God that could bring you [the darkness of] night, wherein you might rest? Will you not, then, see [the truth]?”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 72,
          "number": 3324,
          "juz": 20,
          "numpage": 394
        },
        {
          "text": "For it is out of His grace that He has made for you the night and the day, so that you might rest therein as well as seek to obtain [what you need] of His bounty: and [He gave you all this] so that you might have cause to be grateful.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 73,
          "number": 3325,
          "juz": 20,
          "numpage": 394
        },
        {
          "text": "AND ON THAT DAY He will call unto those [that shall have been arraigned before His judgment seat], and will ask: “Where, now, are those [beings or powers] whom you imagined to have a share in My divinity?”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 74,
          "number": 3326,
          "juz": 20,
          "numpage": 394
        },
        {
          "text": "And [they will remain silent: for by then] We will have called forth witnesses from within every community, and will have said [unto the sinners]: “Produce an evidence for what you have been claim­ing!” And so they will come to understand that all truth is God’s [alone]; and all their false imagery will have forsaken them.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 75,
          "number": 3327,
          "juz": 20,
          "numpage": 394
        },
        {
          "text": "[NOW,] BEHOLD, Qarun was one of the people of Moses; but he arrogantly exalted himself above them - simply because We had granted him such riches that his treasure-chests alone would surely have been too heavy a burden for a troop of ten men or even more. When [they perceived his arrogance,] his people said unto him: “Exult not [in thy wealth], for, verily, God does not love those who exult [in things vain]!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 76,
          "number": 3328,
          "juz": 20,
          "numpage": 394
        },
        {
          "text": "Seek instead, by means of what God has granted thee, [the good of] the life to come, without forget­ting, withal, thine own [rightful] share in this world; and do good [unto others] as God has done good unto thee; and seek not to spread corruption on earth: for, verily, God does not love the spreaders of corruption!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 77,
          "number": 3329,
          "juz": 20,
          "numpage": 394
        }
      ]
    },
    {
      "393": [
        {
          "text": "And [remember:] whatever you are given [now] is but for the [passing] enjoyment of life in this world, and for its embellishment - whereas that which is with God is [so much] better and more enduring. Will you not, then, use your reason?",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 60,
          "number": 3312,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "Is, then, he to whom We have given that goodly promise which he shall see fulfilled [on his resurrection] comparable to one on whom We have bestowed [all] the enjoyments of this worldly life but who, on Resurrection Day, will find himself among those that shall be arraigned [before Us]?",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 61,
          "number": 3313,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "For, on that Day He will call unto them, and will ask: “Where, now, are those [beings or powers] whom you imagined to have a share in My divinity?”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 62,
          "number": 3314,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "[whereupon] they against whom the word [of truth] shall thus stand revealed will exclaim: O our Sustainer! Those whom we caused to err so grievously, we but caused to err as we our­selves had been erring. We [now] disavow them before Thee: it was not us that they worshipped!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 63,
          "number": 3315,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "And [they] will be told: “Call [now] unto those [beings or powers] to whom you were wont to ascribe a share in God’s divinity!” and they will call unto them [for help], but those [fake objects of worship] will not respond to them: whereupon they will see the suffering [that awaits them - the suffering which could have been avoided] if only they had allowed them­selves to be guided!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 64,
          "number": 3316,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "And on that Day He will call unto them, and will ask: “How did you respond to My message­ bearers?”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 65,
          "number": 3317,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "but all arguments and excuses will by then have been erased from their minds, and they will not [be able to] obtain any [helpful] answer from one another.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 66,
          "number": 3318,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "But as against this - anyone who repents and attains to faith and does righteous deeds may well [hope to] find himself among those who achieve a happy state [in the life to come].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 67,
          "number": 3319,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "AND [thus it is:] thy Sustainer creates whatever He wills; and He chooses [for mankind] whatever is best for them. Limitless is God in His glory, and sub­limely exalted above anything to which they may ascribe a share in His divinity!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 68,
          "number": 3320,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "And thy Sustainer knows all that their hearts conceal as well as all that they bring into the open:",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 69,
          "number": 3321,
          "juz": 20,
          "numpage": 393
        },
        {
          "text": "for He is God, save whom there is no deity. Unto Him all praise is due, at the beginning and at the end [of time]; and with Him rests all judgment; and unto Him shall you all be brought back.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 70,
          "number": 3322,
          "juz": 20,
          "numpage": 393
        }
      ]
    },
    {
      "392": [
        {
          "text": "NOW, INDEED, We have caused this word [of Ours] to reach mankind step by step, so that they might [learn to] keep it in mind.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 51,
          "number": 3303,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "As for those unto whom We have vouchsafed revelation aforetime - they [are bound to] believe in this one [as well];",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 52,
          "number": 3304,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "and whenever it is [clearly] conveyed unto them, they [are bound to] profess, “We have come to believe in it, for, behold, it is the truth from our Sustainer - and, verily, even before this have we surrendered ourselves unto Him!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 53,
          "number": 3305,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "These it is that shall receive a twofold reward for having been patient in adversity, and having repelled evil with good, and having spent on others out of what We provided for them as sus­tenance,",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 54,
          "number": 3306,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "and, whenever they heard frivolous talk, having turned away from it and said: “Unto us shall be accounted Our deeds, and unto you, your deeds. Peace be upon you - [but] we do not seek out such as are ignorant [of the meaning of right and wrong].”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 55,
          "number": 3307,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "VERILY, thou canst not guide aright everyone whom thou lovest: but it is God who guides him that wills [to be guided]; and He is fully aware of all who would let themselves be guided.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 56,
          "number": 3308,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "Now some say, “If we were to follow the guidance to which thou invitest us, we would be torn away from our very soil!” Why - have We not established for them a sanc­tuary secure, to which, as a provision from Us, shall be gathered the fruits of all (good) things? But most of them are unaware [of this truth].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 57,
          "number": 3309,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "And how many a community that [once] exult­ed in its wanton wealth and ease of life have We destroyed, so that those dwelling-places of theirs – all but a few - have never been dwelt-in after them: for it is indeed We alone who shall remain when all else will have passed away!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 58,
          "number": 3310,
          "juz": 20,
          "numpage": 392
        },
        {
          "text": "Yet, withal, thy Sustainer would never destroy a community without having [first] raised in its midst an apostle who would convey unto them Our mes­sages; and never would We destroy a community unless its people are wont to do wrong [to one another].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 59,
          "number": 3311,
          "juz": 20,
          "numpage": 392
        }
      ]
    },
    {
      "391": [
        {
          "text": "NOW [as for thee, O Muhammad,] thou wert not present on the sunset slope [of Mount Sinai] when We imposed the Law upon Moses, nor wert thou among those who witnessed [his times]:",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 44,
          "number": 3296,
          "juz": 20,
          "numpage": 391
        },
        {
          "text": "nay, but [between them and thee] We brought into being [many] generations, and long was their span of life. And neither didst thou dwell among the people of Madyan, conveying Our messages unto them: nay, but We have [always] been sending [Our message-bearers unto man].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 45,
          "number": 3297,
          "juz": 20,
          "numpage": 391
        },
        {
          "text": "And neither wert thou present on the slope of Mount Sinai when We called out [to Moses]: but [thou, too, art sent] as an act of thy Sustainer’s grace, to warn people to whom no warner has come before thee, so that they might bethink themselves [of Us];",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 46,
          "number": 3298,
          "juz": 20,
          "numpage": 391
        },
        {
          "text": "and [We have sent thee] lest they say [on Judg­ment Day], when disaster befalls them as an outcome of what their own hands have wrought, “O our Sus­tainer, if only Thou had sent an apostle unto us, we would have followed Thy messages, and would have been among those who believe!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 47,
          "number": 3299,
          "juz": 20,
          "numpage": 391
        },
        {
          "text": "And yet, now that the truth has come unto them from Us, they say, “Why has he not been vouch­safed the like of what Moses was vouchsafed?” But did they not also, before this, deny the truth of what Moses was vouchsafed? [For] they do say, “Two examples of delusion, [seemingly] supporting each other!” And they add, “Behold, we refuse to accept either of them as true!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 48,
          "number": 3300,
          "juz": 20,
          "numpage": 391
        },
        {
          "text": "Say: “Produce, then, [another] revelation from God which would offer better guidance than either of these two [and] I shall follow it, if you speak the truth!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 49,
          "number": 3301,
          "juz": 20,
          "numpage": 391
        },
        {
          "text": "And since they cannot respond to this thy challenge, know that they are following only their own likes and dislikes: and who could be more astray than he who follows [but] his own likes and dislikes without any guidance from God? Verily, God does not grace with His guidance peo­ple who are given to evildoing!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 50,
          "number": 3302,
          "juz": 20,
          "numpage": 391
        }
      ]
    },
    {
      "390": [
        {
          "text": "But as soon as Moses came unto them with Our clear messages [Pharaoh and his great ones] said: “All this is nothing but spellbinding eloquence devised [by man]: and never did we hear [the like of] this, [nor has it ever been heard of] in the time of our forebears of old!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 36,
          "number": 3288,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "And Moses replied: “My Sustainer knows best as to who comes with guidance from Him, and to whom the future belongs! Verily, never will evil­doers attain to a happy state!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 37,
          "number": 3289,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "Whereupon Pharaoh said: “O you nobles! I did not know that you could have any deity other than myself Well, then, O Haman, kindle me a fire for [baking bricks of] clay, and then build me a lofty tower, that haply I may have a look at the god of Moses although, behold, I am convinced that he is of those who [always] tell lies!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 38,
          "number": 3290,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "Thus arrogantly, without the least good sense, did he and his hosts behave on earth - just as if they thought that they would never have to appear before Us [for judgment]!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 39,
          "number": 3291,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "And so We seized him and his hosts and cast them into the sea: and behold what happened in the end to those evildoers:",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 40,
          "number": 3292,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "[We destroyed them,] and We set them up as archetypes [of evil] that show the way to the fire [of hell]; and [whereas] no succour will come to them on Resurrection Day,",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 41,
          "number": 3293,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "We have caused a curse to follow them in this world as well; and on Resurrection Day they will find themselves among those who are bereft of all good.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 42,
          "number": 3294,
          "juz": 20,
          "numpage": 390
        },
        {
          "text": "And [then,] indeed, after We had destroyed those earlier generations [of sinners], We vouchsafed unto Moses [Our] revelation as a means of insight for men, and as a guidance and grace, so that they might bethink themselves [of Us].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 43,
          "number": 3295,
          "juz": 20,
          "numpage": 390
        }
      ]
    },
    {
      "389": [
        {
          "text": "And when Moses had fulfilled his term and was wandering with his family [in the desert], he per­ceived a fire on the slope of Mount Sinai; [and so] he said to his family: “Wait here. Behold, I perceive a fire [far away]; perhaps I may bring you from there some tiding, or [at least] a burning brand from the fire, so that you might warm yourselves.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 29,
          "number": 3281,
          "juz": 20,
          "numpage": 389
        },
        {
          "text": "But when he came close to it, a call was sounded from the right-side bank of the valley, out of the tree [burning] on blessed ground: “O Moses! Verily, I am God, the Sustainer of all the worlds!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 30,
          "number": 3282,
          "juz": 20,
          "numpage": 389
        },
        {
          "text": "And [then He said]: “Throw down thy staff!” But as soon as [Moses] saw it move rapidly, as if it were a snake, he drew back [in terror], and did not [dare to] return. [And God spoke to him again:] “O Moses! Draw near, and have no fear - for, behold, thou art of those who are secure [in this world and in the next]!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 31,
          "number": 3283,
          "juz": 20,
          "numpage": 389
        },
        {
          "text": "“[And now] put thy hand into thy bosom: it will come forth [shining] white, without blemish. And [henceforth] hold thine arm close to thyself, free of all fear. “These, then, shall be the two signs [of thy bearing a message] from thy Sustainer unto Pharaoh and his great ones - for, behold, they are people depraved!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 32,
          "number": 3284,
          "juz": 20,
          "numpage": 389
        },
        {
          "text": "Said [Moses] “O my Sustainer! I have slain one of them, and so I fear that they will slay me.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 33,
          "number": 3285,
          "juz": 20,
          "numpage": 389
        },
        {
          "text": "And my brother Aaron - he is far better in speech than I am. Send him, therefore, as a helper, so that he might [more eloquently] bear witness to my speaking the truth: for I fear indeed that they will give me the lie.”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 34,
          "number": 3286,
          "juz": 20,
          "numpage": 389
        },
        {
          "text": "Said He: “We shall strengthen thine arm through thy brother, and endow both of you with power, so that they will not be able to touch you: by virtue of Our messages shall you two, and all who follow you, prevail!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 35,
          "number": 3287,
          "juz": 20,
          "numpage": 389
        }
      ]
    },
    {
      "388": [
        {
          "text": "And as he turned his face towards Madyan, he said [to himself]: “It may well be that my Sustainer will [thus] guide me onto the right path”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 22,
          "number": 3274,
          "juz": 20,
          "numpage": 388
        },
        {
          "text": "NOW WHEN he arrived at the wells of Madyan, he found there a large group of men who were watering [their herds and flocks]; and at some distance from them he came upon two women who were keeping back their flock. He asked [them]: “What is the matter with you?” They answered: “We cannot water [our animals] until the herdsmen drive [theirs] home - for [we are weak and] our father is a very old man.”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 23,
          "number": 3275,
          "juz": 20,
          "numpage": 388
        },
        {
          "text": "So he watered [their flock] for them: and when he withdrew into the shade and prayed: “O my Sus­tainer! Verily, in dire need am I of any good which Thou mayest bestow upon me!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 24,
          "number": 3276,
          "juz": 20,
          "numpage": 388
        },
        {
          "text": "[Shortly] afterwards, one of the two [maidens] approached him, walking shyly, and said: “Behold, my father invites thee, so that he might duly reward thee for thy having watered [our flock] for us.” And as soon as [Moses] came unto him and told him the story [of his life], he said: “Have no fear! Thou art now safe from those evildoing folk!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 25,
          "number": 3277,
          "juz": 20,
          "numpage": 388
        },
        {
          "text": "Said one of the two [daughters]: “O my father! Hire him: for, behold, the best [man] that thou couldst hire is one who is [as] strong and worthy of trust [as he]!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 26,
          "number": 3278,
          "juz": 20,
          "numpage": 388
        },
        {
          "text": "[After some time, the father] said: “Behold, I am willing to let thee wed one of these two daughters of mine on the understanding that thou wilt remain eight years in my service; and if thou shouldst com­plete ten [years], that would be [an act of grace] from thee, for I do not want to impose any hardship on thee: [on the contrary,] thou wilt find me, if God so wills, righteous in all my dealings.”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 27,
          "number": 3279,
          "juz": 20,
          "numpage": 388
        },
        {
          "text": "Answered [Moses]: “Thus shall it be between me and thee! Whichever of the two terms I fulfill, let there be no ill will against me. And God be witness to all that we say!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 28,
          "number": 3280,
          "juz": 20,
          "numpage": 388
        }
      ]
    },
    {
      "387": [
        {
          "text": "Now when [Moses] reached full manhood and had become mature [of mind]. We bestowed upon him the ability to judge [between right and wrong] as well as [innate] knowledge: for thus do We reward the doers of good.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 14,
          "number": 3266,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "And [one day] he entered the city at a time when [most of] its people were [resting in their houses,] unaware of what was going on [in the streets]; and there he encountered two men fighting with one another - one of his own people, and the other of his enemies. And the one who belonged to his own people cried out to him for help against him who was of his enemies - whereupon Moses struck him down with his fist, and [thus] brought about his end. [But then] he said [to himself]: “This is of Satan’s doing! Verily, he is an open foe, leading [man] astray!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 15,
          "number": 3267,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "[And] he prayed: O my Sustainer! Verily, I have sinned against myself! Grant me, then, Thy forgiveness!” And He forgave him - for, verily, He alone is truly forgiving, a dispenser of grace.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 16,
          "number": 3268,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "Said he: “O my Sustainer! [I vow] by all the blessings which Thou hast bestowed on me: Never­more shall I aid such as are lost in sin!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 17,
          "number": 3269,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "And next morning he found himself in the city, looking fearfully about him, when lo! the one who had sought his help the day before [once again] cried out to him [for help whereupon] Moses said unto him: “Behold, thou art indeed, most clearly, deeply in the wrong!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 18,
          "number": 3270,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "But then as soon as he was about to lay violent hands on the man who was their [common] enemy, the latter exclaimed: “O Moses, dost thou intend to slay me as thou didst slay another man yesterday? Thy sole aim is to become a tyrant in this land, for thou dost not care to be of those who would set things to rights!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 19,
          "number": 3271,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "And [then and there] a man came running from the farthermost end of the city, and said: “O Moses! Behold, the great ones [of the kingdom] are deli­berating upon thy case with a view to killing thee! Begone, then: verily, I am of those who wish thee well!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 20,
          "number": 3272,
          "juz": 20,
          "numpage": 387
        },
        {
          "text": "So he went forth from thence, looking fearfully about him, and prayed: “O my Sustainer! Save me from all evildoing folk!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 21,
          "number": 3273,
          "juz": 20,
          "numpage": 387
        }
      ]
    },
    {
      "386": [
        {
          "text": "and to establish them securely on earth, and to let Pharaoh and Haman and their hosts experience through those [children of Israel] the very thing against which they sought to protect themselves.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 6,
          "number": 3258,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "And so, [when he was born,] We inspired [thus] the mother of Moses: Suckle him [for a time], and then, when thou hast cause to fear for him, cast him into the river, and have no fear and do not grieve - for We shall restore him to thee, and shall make him one of Our message-bearers!”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 7,
          "number": 3259,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "And [some of] Pharaoh’s household found [and spared] him: for [We had willed] that he becomes an enemy unto them and [a source of] grief, seeing that Pharaoh and Haman and their hosts were sinners indeed!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 8,
          "number": 3260,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "Now the wife of Pharaoh said: “A joy to the eye [could this child be] for me and thee! Slay him not: he may well be of use to us, or we may adopt him as a son!” And they had no presentiment [of what he was to become].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 9,
          "number": 3261,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "On the morrow, however, an aching void grew up in the heart of the mother of Moses, and she would indeed have disclosed all about him had We not endowed her heart with enough strength to keep alive her faith [in Our promise].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 10,
          "number": 3262,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "And so she said to his sister, “Follow him” - and [the girl] watched him from afar, while they [who had taken him in] were not aware of it,",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 11,
          "number": 3263,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "Now from the very beginning We caused him to refuse the breast of [Egyptian] nurses; and [when his sister came to know this,] she said: “Shall I guide you to a family that might rear him for you, and look after him with good will?”",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 12,
          "number": 3264,
          "juz": 20,
          "numpage": 386
        },
        {
          "text": "And thus We restored him to his mother, so that her eye might he gladdened, and that she might grieve no longer, and that she might know that God’s promise always comes true - even though most of them know it not!",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 13,
          "number": 3265,
          "juz": 20,
          "numpage": 386
        }
      ]
    },
    {
      "385": [
        {
          "text": "Whoever shall come [before Him] with a good deed will gain [further] good there from; and they will be secure from the terror of that Day.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 89,
          "number": 3248,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "But they who shall come with evil deeds – their faces will be thrust into the fire, [and they will be asked:] “Is this aught but a just requital for what you were doing [in life]?”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 90,
          "number": 3249,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "[SAY, O Muhammad:] “I have been bidden to worship the Sustainer of this City – Him who has made it sacred, and unto whom all things belong: and I have been bidden to be of those who surrender themselves to Him,",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 91,
          "number": 3250,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "and to convey this Qur’an [to the world].” Whoever, therefore, chooses to follow the right path, follows it but for his own good; and if any wills to go astray, say [unto him]: “I am only a warner.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 92,
          "number": 3251,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "And say: “All praise is due to God! In time He will make you see [the truth of] His messages, and then you shall know them [for what they are].” And thy Sustainer is not unmindful of whatever you all may do.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 93,
          "number": 3252,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "Ta. Sin. Mim.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 1,
          "number": 3253,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "These are messages of a divine writ clear in itself and clearly showing the truth.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 2,
          "number": 3254,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "We [now] convey unto thee some of the story of Moses and Pharaoh, setting forth the truth for [the benefit of] people who will believe.",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 3,
          "number": 3255,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "Behold, Pharaoh exalted himself in the land and divided its people into castes. One group of them he deemed utterly low; he would slaughter their sons and spare (only) their women: for, behold, he was one of those who spread corruption [on earth].",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 4,
          "number": 3256,
          "juz": 20,
          "numpage": 385
        },
        {
          "text": "But it was Our will to bestow Our favour upon those [very people] who were deemed [so] utterly low in the land, and to make them forerunners in faith. and to make them heirs [to Pharaoh’s glory],",
          "englishName": "Al-Qasas",
          "suraNumber": 28,
          "numberInSurah": 5,
          "number": 3257,
          "juz": 20,
          "numpage": 385
        }
      ]
    },
    {
      "384": [
        {
          "text": "and, verily, it is a guidance and a grace unto all who believe [in it].",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 77,
          "number": 3236,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "Verily, [O believer,] thy Sustainer will judge between them in His wisdom - for He alone is almighty, all-knowing.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 78,
          "number": 3237,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "Hence, place thy trust in God [alone] - for, behold, that in which thou believest is truth self-evident.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 79,
          "number": 3238,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "[But,] verily, thou canst not make the dead hear: and [so, too,] thou canst not make the deaf [of heart] hear this call when they turn their backs [on thee] and go away,",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 80,
          "number": 3239,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "just as thou canst not lead the blind [of heart] out of their error; none canst thou make hear save such as [are willing to] believe in Our messages, and thus surrender themselves unto Us.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 81,
          "number": 3240,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "Now, [as for the deaf and blind of heart –] when the word [of truth] stands revealed against them, We shall bring forth unto them out of the earth a creature, which will tell them that mankind had no real faith in Our messages.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 82,
          "number": 3241,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "And on that Day We shall gather from within every community a host of those who gave the lie to Our messages; and they will be grouped [according to the gravity of their sins]",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 83,
          "number": 3242,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "until such a time as they shall come [to be judged. And] He will say: “Did you give the lie to My messages even though you failed to encompass them with [your] knowledge? Or what was it that [you thought] you were doing?”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 84,
          "number": 3243,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "And the word [of truth] will stand revealed against them in the face of all the wrong which they had committed, and they will not [be able to] utter a single word [of excuse]:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 85,
          "number": 3244,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "for, were they not aware that it is We who had made the night for them, so that they might rest therein, and the day, to make [them] see? In this, behold, there are messages indeed for peo­ple who will believe!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 86,
          "number": 3245,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "And on that Day the trumpet [of judgment] will be sounded, and all [creatures] that are in the heavens and all that are on earth will be stricken with terror, except such as God wills [to exempt]: and in utter lowliness all will come unto Him.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 87,
          "number": 3246,
          "juz": 20,
          "numpage": 384
        },
        {
          "text": "And thou wilt see the mountains, which [now] thou deemest so firm, pass away as clouds pass away: a work of God, who has ordered all things to per­fection! Verily, He is fully aware of all that you do!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 88,
          "number": 3247,
          "juz": 20,
          "numpage": 384
        }
      ]
    },
    {
      "383": [
        {
          "text": "Nay - who is it that creates [all life] in the first instance, and then brings it forth anew? And who is it that provides you with sustenance out of heaven and earth? Could there be any divine power besides God? Say: “[If you think so,] produce your evidence - if you truly believe in your claim!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 64,
          "number": 3223,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "Say: “None in the heavens or on earth knows the hidden reality [of anything that exists: none knows it] save God.” And neither can they [who are living] perceive when they shall be raised from the dead:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 65,
          "number": 3224,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "nay, their knowledge of the life to come stops short of the truth: nay, they are [often] in doubt as to its reality: nay, they are blind to it.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 66,
          "number": 3225,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "And so, they who are bent on denying the truth are saying: “What! After we have become dust - we and our forefathers - shall we [all], forsooth, be brought forth [from the dead]?",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 67,
          "number": 3226,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "Indeed, we were promised this - we and our forefathers - in the past as well; it is nothing but fables of ancient times!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 68,
          "number": 3227,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "Say: “Go all over the earth and behold what happened in the end to those [who were thus] lost in sin!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 69,
          "number": 3228,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "But do not grieve over them, and neither be distressed by the false arguments which they devise [against Gods messages].",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 70,
          "number": 3229,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "And [when] they ask, “When is this promise [of resurrection] to be fulfilled? [Answer this, O you who believe in it,] if you are men of truth!” –",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 71,
          "number": 3230,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "say thou: “It may well be that something of that which [in your ignorance] you so hastily demands has already drawn close unto you.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 72,
          "number": 3231,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "Now, verily, thy Sustainer is indeed limitless in His bounty unto men - but most of them are bereft of gratitude.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 73,
          "number": 3232,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "But, verily, thy Sustainer knows all that their hearts conceal as well as all that they bring into the open:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 74,
          "number": 3233,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "for there is nothing [so deeply] hidden in the heavens or on earth but is recorded in [His] clear decree.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 75,
          "number": 3234,
          "juz": 20,
          "numpage": 383
        },
        {
          "text": "BEHOLD, this Qur’an explains to the children of Israel most [of that] whereon they hold divergent views;",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 76,
          "number": 3235,
          "juz": 20,
          "numpage": 383
        }
      ]
    },
    {
      "382": [
        {
          "text": "But his people’s only answer was this: “Expel [Lot and] Lot’s followers from your township! Verily, they are folk who make themselves out to be pure!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 56,
          "number": 3215,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "Thereupon We saved him and his household - all but his wife, whom We willed to be among those that stayed behind -",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 57,
          "number": 3216,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "the while We rained a rain [of destruction] upon the others: and dire is such rain upon all who let themselves be warned [to no avail]",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 58,
          "number": 3217,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "SAY: “All praise is due to God, and peace be upon those servants of His whom He chose [to be His message-bearers]!” Is not God far better than anything to which men [falsely] ascribe a share in His divinity?",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 59,
          "number": 3218,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "Nay - who is it that has created the heavens and the earth, and sends down for you [life-giving] water from the skies? For it is by this means that We cause gardens of shining beauty to grow - [whereas] it is not in your power to cause [even one single of] its trees to grow! Could there be any divine power besides God? Nay, they [who think so] are people who swerve [from the path of reason]",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 60,
          "number": 3219,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "Nay - who is it that has made the earth a fitting abode [for living things], and has caused running waters [to flow] in its midst, and has set upon it moun­tains firm, and has placed a barrier between the two great bodies of water? Could there be any divine power besides God? Nay, most of those [who think so] do not know [what they are saying]!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 61,
          "number": 3220,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "Nay - who is it that responds to the distressed when he calls out to Him, and who removes the ill [that caused the distress], and has made you inherit the earth? Could there be any divine power besides God? How seldom do you keep this in mind!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 62,
          "number": 3221,
          "juz": 20,
          "numpage": 382
        },
        {
          "text": "Nay - who is it that guides you in the midst of the deep darkness of land and sea, and sends forth the winds as a glad tiding of His coming grace? Could there be any divine power besides God? Sublimely exalted is God above anything to which men may ascribe a share in His divinity!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 63,
          "number": 3222,
          "juz": 20,
          "numpage": 382
        }
      ]
    },
    {
      "381": [
        {
          "text": "AND [likewise], indeed, We sent unto [the tribe of] Thamud their brother Salih [with this message]: “Worship God alone!” and, behold, they were [split into] two factions contending with one another.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 45,
          "number": 3204,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "Said [Salih to the erring ones]: Why do you seek to hasten the coming upon you of evil instead of hoping for the good?” Why do you not, rather, ask God to forgive you your sins, so that you might be graced with His mercy?”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 46,
          "number": 3205,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "They answered: “We augur evil from thee and those that follow thee!” Said he: “Your destiny, good or evil, rests with God yea, you are people undergoing a test!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 47,
          "number": 3206,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "Now there were in the city nine men who were wont to commit deeds of depravity all over the land, and would not reform;",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 48,
          "number": 3207,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "[and] after having bound one another by an oath in God’s name, they said: “Indeed, we shall suddenly fall upon him and his household by night [and slay them all]; and then we shall boldly say to his next of kin, ‘We did not witness the destruction of his household - and, behold, we are indeed men of truth!’”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 49,
          "number": 3208,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "And so they devised an evil scheme; but We devised a subtle scheme (of Our own), and they perceived it not.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 50,
          "number": 3209,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "Behold, then, what all their scheming came to in the end: We utterly destroyed them and their people, all of them;",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 51,
          "number": 3210,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "and [now] those dwellings of theirs are empty, [ruined] as an outcome of their evil deeds. In this, behold, there is a message indeed for peo­ple of [innate] knowledge –",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 52,
          "number": 3211,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "seeing that We saved those who had attained to faith and were conscious of Us,",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 53,
          "number": 3212,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "AND [thus, too, did We save] Lot, when he said unto his people: “Would you commit this abomination with your eyes open (to its being against all nature)?",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 54,
          "number": 3213,
          "juz": 19,
          "numpage": 381
        },
        {
          "text": "Must you really approach men with lust instead of women? Nay, but you are people without any awareness (of right and wrong)!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 55,
          "number": 3214,
          "juz": 19,
          "numpage": 381
        }
      ]
    },
    {
      "380": [
        {
          "text": "Now when [the Queen’s messenger] came unto Solomon, he said: “Do you people mean to add to my wealth? But that which God has given me is [so much] better than all that He has given you! Nay, it is [only such as] you that would rejoice in this gift of yours!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 36,
          "number": 3195,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "“Go thou back unto them [that have sent thee]! For, [God says:] ‘We shall most certainly come upon them with forces which they will never be able to withstand, and shall most certainly cause them to be driven from that [land of theirs], despicable and humbled!’”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 37,
          "number": 3196,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "[When Solomon learned that the Queen of Sheba was coming,] he said [to his council]: “O you nobles! Which of you can bring me her throne ere she and her followers come unto me in willing surrender to God?”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 38,
          "number": 3197,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "Said a bold one of the invisible beings [subject to Solomon]: “I shall bring it to thee ere thou rise from thy council-seat - for, behold, I am powerful enough to do it, [and] worthy of trust!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 39,
          "number": 3198,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "Answered he who was illumined by revela­tion: “[Nay,] as for me - I shall bring it to thee ere the twinkling of thy eye ceases!” And when he saw it truly before him, he exclaimed: “This is [an outcome] of my Sustainer’s bounty, to test me as to whether I am grateful or ungrateful! However, he who is grateful [to God] is but grateful for his own good; and he who is ungrate­ful [should know that], verily, my Sustainer is self-sufficient, most generous in giving!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 40,
          "number": 3199,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "[And] he continued: Alter her throne so that she may not know it as hers: let us see whether she allows herself to be guided [to the truth] or remains one of those who will not be guided.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 41,
          "number": 3200,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "And so, as soon as she arrived, she was asked: “Is thy throne like this?” She answered: “It is as though it were the same!” [And Solomon said to his nobles: “She has arrived at the truth without any help from us,] although it is we who have been given [divine] knowledge before her, and have [long ago] surrendered ourselves unto God!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 42,
          "number": 3201,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "[And she has recognized the truth] although that which she has been wont to worship instead of God had kept her away [from the right path]: for, behold, she is descended of people who deny the truth!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 43,
          "number": 3202,
          "juz": 19,
          "numpage": 380
        },
        {
          "text": "[After a while] she was told: “Enter this court!” - but when she saw it, she thought that it was a fathomless expanse of water, and she bared her legs. Said he: “Behold, it is [but] a court smoothly paved with glass!” Cried she: “O my Sustainer! I have been sinning against myself thy worshipping aught but Thee]: but [now] I have surrendered myself, with Solomon, unto the Sustainer of all the worlds!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 44,
          "number": 3203,
          "juz": 19,
          "numpage": 380
        }
      ]
    },
    {
      "379": [
        {
          "text": "“Behold, I found there a woman ruling over them; and she has been given [abundance] of all [good] things, and hers is a mighty throne,",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 23,
          "number": 3182,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "And I found her and her people adoring the sun instead of God; and Satan has made these doings of theirs seem goodly to them, and [thus] has barred them from the path [of God], so that they cannot find the right way:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 24,
          "number": 3183,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "[for they have come to believe] that they ought not to adore God [although it is He] who brings forth all that is hidden in the heavens and on earth, and knows all that you would conceal as well as all that you bring into the open:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 25,
          "number": 3184,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "God, save whom there is no deity - the Sustainer, in awesome almightiness enthroned!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 26,
          "number": 3185,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "Said [Solomon]: “We shall see whether thou hast told the truth or art one of the liars!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 27,
          "number": 3186,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "Go with this my letter and convey it to them; and thereafter withdraw from them and see what [answer] they return.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 28,
          "number": 3187,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "[When the Queen had read Solomon’s letter,] she said: “O you nobles! A truly distinguished letter has been conveyed unto me.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 29,
          "number": 3188,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "Behold, it is from Solomon, and it says, ‘In the name of God, the Most Gracious, the Dispenser of Grace:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 30,
          "number": 3189,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "[God says:] Exalt not yourselves against Me, but come unto Me in willing surrender!’”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 31,
          "number": 3190,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "She added: “O you nobles! Give me your opinion on the problem with which I am now faced; I would never make a [weighty] decision unless you are present with me.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 32,
          "number": 3191,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "They answered: “We are endowed with power and with mighty prowess in war - but the command is thine; consider, then, what thou wouldst command.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 33,
          "number": 3192,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "Said she: “Verily, whenever kings enter a country they corrupt it, and turn the noblest of its people into the most abject. And this is the way they [always] behave?",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 34,
          "number": 3193,
          "juz": 19,
          "numpage": 379
        },
        {
          "text": "Hence, behold, I am going to send a gift to those [people], and await whatever [answer] the envoys bring back.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 35,
          "number": 3194,
          "juz": 19,
          "numpage": 379
        }
      ]
    },
    {
      "378": [
        {
          "text": "- and in their wickedness and self-exaltation they rejected them, although their minds were convinced of their truth: and behold what hap­pened in the end to those spreaders of corruption!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 14,
          "number": 3173,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "AND, INDEED, We granted [true] knowledge unto David and Solomon [as well]; and both were wont to say: All praise is due to God, who has [thus] favoured us above many of His believing servants!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 15,
          "number": 3174,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "And [in this insight] Solomon was [truly] David’s heir; and he would say: “O you people! We have been taught the speech of birds, and have been given [in abundance] of all [good] things: this, behold, is indeed a manifest favour [from God]!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 16,
          "number": 3175,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "And [one day] there were assembled before Solomon his hosts of invisible beings, and of men, and of birds; and then they were led forth in orderly ranks,",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 17,
          "number": 3176,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "till, when they came upon a valley [full] of ants, an ant exclaimed: “O you ants! Get into your dwellings, lest Solomon and his hosts crush you without [even] being aware [of you]!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 18,
          "number": 3177,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "Thereupon [Solomon] smiled joyously at her words, and said: “O my Sustainer! Inspire me so that I may forever be grateful for those blessings of Thine with which Thou hast graced me and my parents, and that I may do what is right [in a manner] that will please Thee; and include me, by Thy grace, among Thy righteous servants!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 19,
          "number": 3178,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "And [one day] he looked in vain for [a parti­cular one of] the birds; and so he said: How is it that I do not see the hoopoe? Or could he be among the absent?",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 20,
          "number": 3179,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "[If so,] I will punish him most severely or will kill him unless he bring me a convincing excuse!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 21,
          "number": 3180,
          "juz": 19,
          "numpage": 378
        },
        {
          "text": "But [the hoopoe] tarried but a short while; and [when it came] it said: “I have encompassed [with my knowledge] something that thou hast never yet encompassed [with thine] - for I have come to thee from Sheba with a tiding sure!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 22,
          "number": 3181,
          "juz": 19,
          "numpage": 378
        }
      ]
    },
    {
      "377": [
        {
          "text": "Ta. Sin. THESE ARE MESSAGES of the Qur’an - a di­vine writ clear in itself and clearly showing the truth:",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 1,
          "number": 3160,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "a guidance and a glad tiding to the believers",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 2,
          "number": 3161,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "who are constant in prayer and spend in charity: for it is they, they who in their innermost are certain of the life to come!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 3,
          "number": 3162,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "As for those who will not believe in the life to come - behold, goodly have We made their own doings appear unto them, and so they stumble blindly to and fro",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 4,
          "number": 3163,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "It is they whom the worst of suffering awaits: for it is they, they who in the life to come shall be the greatest losers!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 5,
          "number": 3164,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "But [as for thee, O believer,] verily, thou hast received this Qur’an out of the grace of One who is wise, all-knowing.",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 6,
          "number": 3165,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "Lo! [While lost in the desert,] Moses said to his family: “Behold, I perceive a fire [far away]; I may bring you from there some tiding [as to which way we arc to pursue], or bring you [at least] a burning brand so that you might warm yourselves.”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 7,
          "number": 3166,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "But when he came close to it, a call was sounded: “Blessed are all who are within [reach of] this fire, and all who are near it! And limitless in His glory is God, the Sustainer of all the worlds!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 8,
          "number": 3167,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "[And God spoke thus:] “O Moses! Verily I alone am God, the Almighty, the Wise!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 9,
          "number": 3168,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "[And then He said:] “Now throw down thy staff!” But when he saw it move rapidly, as if it were a serpent, he drew back [in terror], and did not [dare to] return. [And God spoke to him again:] O Moses! Fear not - for, behold, no fear need the message-bearers have in My Presence,",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 10,
          "number": 3169,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "and neither need anyone who has done wrong and then has replaced the wrong with good: for, verily, I am much-forgiving, a dis­penser of grace!",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 11,
          "number": 3170,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "“Now place thy hand into thy bosom: it will come forth [shining] white, without blemish!” [And thou shalt go] with nine [of My] messages unto Pharaoh and his people for, verily, they are people depraved!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 12,
          "number": 3171,
          "juz": 19,
          "numpage": 377
        },
        {
          "text": "But when Our light-giving messages came unto them, they said, “This is clearly [but] spellbinding deception!”",
          "englishName": "An-Naml",
          "suraNumber": 27,
          "numberInSurah": 13,
          "number": 3172,
          "juz": 19,
          "numpage": 377
        }
      ]
    },
    {
      "376": [
        {
          "text": "of what avail to them will be all their past enjoyments?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 207,
          "number": 3139,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "And withal, never have We destroyed any community unless it had been warned",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 208,
          "number": 3140,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "and reminded: for, never do We wrong [anyone].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 209,
          "number": 3141,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "And [this divine writ is such a reminder:] no evil spirits have brought it down:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 210,
          "number": 3142,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "for, neither does it suit their ends, nor is it in their power [to impart it to man]:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 211,
          "number": 3143,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "verily, [even] from hearing it are they utterly debarred!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 212,
          "number": 3144,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "Hence, [O man,] do not invoke any other deity side by side with God, lest thou find thyself among those who are made to suffer [on Judgment Day].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 213,
          "number": 3145,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "And warn [whomever thou canst reach, beginning with] thy kinsfolk,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 214,
          "number": 3146,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "and spread the wings of thy tenderness over all of the believers who may follow thee;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 215,
          "number": 3147,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "but if they disobey thee, say, “I am free of responsibility for aught that you may do!” –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 216,
          "number": 3148,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "and place thy trust in the Almighty, the Dispenser of Grace,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 217,
          "number": 3149,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "who sees thee when thou standest [alone],",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 218,
          "number": 3150,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "and [sees] thy behaviour among those who prostrate themselves [before Him]:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 219,
          "number": 3151,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "for, verily, He alone is all-hearing, all-knowing!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 220,
          "number": 3152,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "[And] shall I tell you upon whom it is that those evil spirits descend?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 221,
          "number": 3153,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "They descend upon all sinful self-deceivers",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 222,
          "number": 3154,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "who readily lend ear [to every falsehood], and most of whom lie to others as well.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 223,
          "number": 3155,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "And as for the poets [they, too, are prone to deceive themselves: and so, only] those who are lost in grievous error would follow them.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 224,
          "number": 3156,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "Art thou not aware that they roam confusedly through all the valleys [of words and thoughts],",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 225,
          "number": 3157,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "and that they [so often] say what they do not do [or feel]?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 226,
          "number": 3158,
          "juz": 19,
          "numpage": 376
        },
        {
          "text": "[Most of them are of this kind] save those who have attained to faith, and do righteous deeds, and remember God unceasingly, and defend them­selves [only] after having been wronged, and [trust in God’s promise that] those who are bent on wrong­doing will in time come to know how evil a turn their destinies are bound to take!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 227,
          "number": 3159,
          "juz": 19,
          "numpage": 376
        }
      ]
    },
    {
      "375": [
        {
          "text": "but be conscious of Him who has created you, just as [He created] those countless generations of old!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 184,
          "number": 3116,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Said they: “Thou art but one of the be­witched,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 185,
          "number": 3117,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "for thou art nothing but a mortal like ourselves! And, behold, we think that thou art a consummate liar!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 186,
          "number": 3118,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Cause, then, fragments of the sky to fall down upon us, if thou art a man of truth!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 187,
          "number": 3119,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Answered [Shu’ayb]: “My Sustainer knows fully well what you are doing.”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 188,
          "number": 3120,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "But they gave him the lie. And thereupon suffering overtook them on a day dark with shadows: and, verily, it was the suffering of an awesome day!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 189,
          "number": 3121,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "In this [story], behold, there is a message [unto men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 190,
          "number": 3122,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "But, verily, thy Sustainer - He alone - is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 191,
          "number": 3123,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Now, behold, this [divine writ] has indeed been bestowed from on high by the Sustainer of all the worlds:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 192,
          "number": 3124,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "trustworthy divine inspiration has alighted with it from on high",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 193,
          "number": 3125,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "upon thy heart, [O Muhammad] so that thou mayest be among those who preach",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 194,
          "number": 3126,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "in the clear Arabic tongue.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 195,
          "number": 3127,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "And, verily, [the essence of] this [revelation] is indeed found in the ancient books of divine wisdom [as well].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 196,
          "number": 3128,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Is it not evidence enough for them that [so many] learned men from among the children of Israel have recognized this [as true]?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 197,
          "number": 3129,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "But [even] had We bestowed it from on high upon any of the non-Arabs,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 198,
          "number": 3130,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "and had he recited it unto them [in his own tongue], they would not have believed in it.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 199,
          "number": 3131,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Thus have We caused this [message] to pass [unheeded] through the hearts of those who are lost in sin:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 200,
          "number": 3132,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "they will not believe in it till they behold the grievous suffering",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 201,
          "number": 3133,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "that will come upon them [on resurrection,] all of a sudden, without their being aware [of its approach];",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 202,
          "number": 3134,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "and then they will exclaim, “Could we have a respite?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 203,
          "number": 3135,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "Do they, then, [really] wish that Our chas­tisement be hastened on?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 204,
          "number": 3136,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "But hast thou ever considered [this]: If We do allow them to enjoy [this life] for some years,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 205,
          "number": 3137,
          "juz": 19,
          "numpage": 375
        },
        {
          "text": "and thereupon that [chastisement] which they were promised befalls them –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 206,
          "number": 3138,
          "juz": 19,
          "numpage": 375
        }
      ]
    },
    {
      "374": [
        {
          "text": "[AND] the people of Lot gave the lie to [one of God’s] message-bearers",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 160,
          "number": 3092,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "when their brother Lot said unto them: “Will you not be conscious of God?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 161,
          "number": 3093,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "Behold, I am an apostle [sent by Him] to you, [and therefore] worthy of your trust:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 162,
          "number": 3094,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "be, then, conscious of God, and pay heed unto me!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 163,
          "number": 3095,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "“And no reward whatever do I ask of you for it: my reward rests with none but the Sustainer of all the worlds.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 164,
          "number": 3096,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "“Must you, of all people, [lustfully] approach men,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 165,
          "number": 3097,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "keeping yourselves aloof from all the [lawful] spouses whom your Sustainer has created for you? Nay, but you are people who transgress all bounds of what is right!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 166,
          "number": 3098,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "Said they: “Indeed, If thou desist not, O Lot, thou wilt most certainly be expelled [from this town­ship]!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 167,
          "number": 3099,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "[Lot] exclaimed: “Behold, I am one of those who utterly abhor your doings!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 168,
          "number": 3100,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "[And then he prayed:] “O my Sustainer! Save me and my household from all that they are doing!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 169,
          "number": 3101,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "Thereupon We saved him and all his household –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 170,
          "number": 3102,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "all but an old woman, who was among those that stayed behind;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 171,
          "number": 3103,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "and then We utterly destroyed the others,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 172,
          "number": 3104,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "and rained down upon them a rain [of destruction]: and dire is such rain upon all who let themselves be warned [to no avail]!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 173,
          "number": 3105,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "In this [story], behold, there is a message [unto men], even though most of them will not be­lieve [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 174,
          "number": 3106,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "But, verily, thy Sustainer - He alone - is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 175,
          "number": 3107,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "[AND] the dwellers of the wooded dales [of Madyan] gave the lie to [one of God’s] message-bearers",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 176,
          "number": 3108,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "when their brother Shu’ayb said unto them: “Will you not be conscious of God?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 177,
          "number": 3109,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "Behold, I am an apostle [sent by Him] to you, [and therefore] worthy of your trust:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 178,
          "number": 3110,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "be, then, conscious of God, and pay heed unto me!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 179,
          "number": 3111,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "“And no reward whatever do I ask of you for it: my reward rests with none but the Sustainer of all the worlds.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 180,
          "number": 3112,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "“[Always] give full measure, and be not among those who [unjustly] cause loss [to others];",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 181,
          "number": 3113,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "and [in all your dealings] weigh with a true balance,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 182,
          "number": 3114,
          "juz": 19,
          "numpage": 374
        },
        {
          "text": "and do not deprive people of what is rightfully theirs; and do not act wickedly on earth by spreading corruption,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 183,
          "number": 3115,
          "juz": 19,
          "numpage": 374
        }
      ]
    },
    {
      "373": [
        {
          "text": "This [religion of ours] is none other than that to which our forebears clung,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 137,
          "number": 3069,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "and we are not going to be chastised [for adhering to it]!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 138,
          "number": 3070,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "And so they gave him the lie: and thereupon We destroyed them. In this [story], behold, there is a message [unto men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 139,
          "number": 3071,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "But, verily, thy Sustainer - He alone - is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 140,
          "number": 3072,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "[AND the tribe of] Thamud gave the lie to [one of God’s] message-hearers",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 141,
          "number": 3073,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "when their brother Salih said unto them: “Will you not be conscious of God?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 142,
          "number": 3074,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "Behold, I am an apostle [sent by Him] to you, [and therefore] worthy of your trust:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 143,
          "number": 3075,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "be, then, conscious of God, and pay heed unto me!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 144,
          "number": 3076,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "“And no reward whatever do I ask of you for it: my reward rests with none but the Sustainer of all the worlds.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 145,
          "number": 3077,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "“Do you think that you will be left secure [forever] in the midst of what you have here and now?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 146,
          "number": 3078,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "amidst [these] gardens and springs",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 147,
          "number": 3079,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "and fields, and [these] palm-trees with slender spathes? –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 148,
          "number": 3080,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "and that you will [always be able to] hew dwellings out of the mountains with [the same] great skill?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 149,
          "number": 3081,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "“Be, then, conscious of God, and pay heed unto me,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 150,
          "number": 3082,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "and pay no heed to the counsel of those who are given to excesses –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 151,
          "number": 3083,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "those who spread corruption on earth instead of setting things to rights!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 152,
          "number": 3084,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "Said they: “Thou art but one of the be­witched!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 153,
          "number": 3085,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "Thou art nothing but a mortal like our­selves! Come, then, forward with a token [of thy mission] if thou art a man of truth!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 154,
          "number": 3086,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "Replied he: “This she-camel shall have a share of water, and you shall have a share of water, on the days appointed [therefor];",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 155,
          "number": 3087,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "and do her no harm, lest suffering befall you on an awesome day!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 156,
          "number": 3088,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "But they cruelly slaughtered her - and then they had cause to regret it:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 157,
          "number": 3089,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "for the suffering [predicted by Salih,] befell them [then and there]. In this [story], behold, there is a message [unto men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 158,
          "number": 3090,
          "juz": 19,
          "numpage": 373
        },
        {
          "text": "But, verily, thy Sustainer - He alone – is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 159,
          "number": 3091,
          "juz": 19,
          "numpage": 373
        }
      ]
    },
    {
      "372": [
        {
          "text": "Said he: “And what knowledge could I have as to what they were doing [before they came to me]?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 112,
          "number": 3044,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "Their reckoning rests with none but my Sus­tainer: if you could but understand [this]!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 113,
          "number": 3045,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "Hence, I shall not drive away [any of] those [who profess to be] believers;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 114,
          "number": 3046,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "I am nothing but a plain warner.”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 115,
          "number": 3047,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "Said they: “Indeed, if thou desist not, O Noah, thou wilt surely be stoned to death!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 116,
          "number": 3048,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "[Whereupon] he prayed: “O my Sustainer! Behold, my people have given me the lie:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 117,
          "number": 3049,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "hence, lay Thou wide open the truth between me and them, and save me and those of the believers who are with me!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 118,
          "number": 3050,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "And so We saved him and those [who were] with him in the fully-laden ark,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 119,
          "number": 3051,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "and then We caused those who stayed behind to drown.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 120,
          "number": 3052,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "In this [story], behold, there is a message [unto men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 121,
          "number": 3053,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "But, verily, thy Sustainer - He alone - is almighty, a dispenser of grace",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 122,
          "number": 3054,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "[AND the tribe of] Ad gave the lie to [one of God’s] message-bearers",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 123,
          "number": 3055,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "when their brother Hud said unto them: “Will you not be conscious of God?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 124,
          "number": 3056,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "Behold, I am an apostle [sent by Him] to you, [and therefore] worthy of your trust:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 125,
          "number": 3057,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "be, then, con­scious of God, and pay heed unto me!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 126,
          "number": 3058,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "“And no reward whatever do I ask of you for it: my reward rests with none but the Sustainer of all the worlds.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 127,
          "number": 3059,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "“Will you, in your wanton folly, build [idola­trous] altars on every height,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 128,
          "number": 3060,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "and make for yourselves mighty castles, [hoping] that you might become immortal?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 129,
          "number": 3061,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "And will you [always], whenever you lay hand [on others], lay hand [on them] cruelly, without any restraint?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 130,
          "number": 3062,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "“Be, then, conscious of God and pay heed unto me:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 131,
          "number": 3063,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "and [thus] be conscious of Him who has [so] amply provided you with all [the good] that you might think of",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 132,
          "number": 3064,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "amply provided you with flocks, and children,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 133,
          "number": 3065,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "and gardens, and springs -:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 134,
          "number": 3066,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "for, verily, I fear lest suffering befall you on an awesome day!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 135,
          "number": 3067,
          "juz": 19,
          "numpage": 372
        },
        {
          "text": "[But] they answered. “It is all one to us whether thou preachest [something new] or art not of those who [like to] preach.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 136,
          "number": 3068,
          "juz": 19,
          "numpage": 372
        }
      ]
    },
    {
      "371": [
        {
          "text": "and grant me the power to convey the truth unto those who will come after me,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 84,
          "number": 3016,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "and place me among those who shall inherit the garden of bliss!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 85,
          "number": 3017,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "“And forgive my father - for, verily, he is among those who have gone astray –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 86,
          "number": 3018,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "and do not put me to shame on the Day when all shall be raised from the dead:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 87,
          "number": 3019,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "the Day on which neither wealth will be of any use, nor children,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 88,
          "number": 3020,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "[and when] only he [will be happy] who comes before God with a heart free of evil!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 89,
          "number": 3021,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "For, [on that Day,] paradise will be brought within sight of the God-conscious,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 90,
          "number": 3022,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "whereas the blazing fire will be laid open before those who had been lost in grievous error;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 91,
          "number": 3023,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "and they will be asked: “Where now is all that you were wont to worship",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 92,
          "number": 3024,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "instead of God? Can these [things and beings] be of any help to you or to themselves?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 93,
          "number": 3025,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "Thereupon they will be hurled into hell – they, as well as all [others] who had been lost in grievous error,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 94,
          "number": 3026,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "and the hosts of Iblis - all together.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 95,
          "number": 3027,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "And there and then, blaming one another, they [who had grievously sinned in life] will exclaim:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 96,
          "number": 3028,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "“By God, we were most obviously astray",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 97,
          "number": 3029,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "when we deemed you [false deities] equal to the Sustainer of all the worlds –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 98,
          "number": 3030,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "yet they who have seduced us [into believing in you] are the truly guilty ones!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 99,
          "number": 3031,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "And now we have none to intercede for us,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 100,
          "number": 3032,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "nor any loving friend.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 101,
          "number": 3033,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "Would that we had a second chance [in life], so that we could be among the believers!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 102,
          "number": 3034,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "In all this, behold, there is a message [unto men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 103,
          "number": 3035,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "But, verily, thy Sustainer - He alone - is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 104,
          "number": 3036,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "The people of Noah [too] gave the lie to [one of God’s] message-bearers",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 105,
          "number": 3037,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "when their brother Noah said unto them: “Will you not be conscious of God?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 106,
          "number": 3038,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "Behold, I am an apostle [sent by Him] to you, [and therefore] worthy of your trust:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 107,
          "number": 3039,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "be, then, conscious of God, and pay heed unto me!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 108,
          "number": 3040,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "“And no reward whatever do I ask of you for it: my reward rests with none but the Sustainer of all the worlds.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 109,
          "number": 3041,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "Hence, remain conscious of God, and pay heed unto me!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 110,
          "number": 3042,
          "juz": 19,
          "numpage": 371
        },
        {
          "text": "They answered: “Shall we place our faith in thee, even though [only] the most abject [of people] follow thee?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 111,
          "number": 3043,
          "juz": 19,
          "numpage": 371
        }
      ]
    },
    {
      "370": [
        {
          "text": "and as soon as the two hosts came in sight of one another, the followers of Moses exclaimed: “Behold, we shall certainly be overtaken [and defeated]!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 61,
          "number": 2993,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "He replied: Nay indeed! My Sustainer is with me, [and] He will guide me!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 62,
          "number": 2994,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "Thereupon We inspired Moses thus: Strike the sea with thy staff!”- whereupon it parted, and each part appeared like a mountain vast.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 63,
          "number": 2995,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "And We caused the pursuers to draw near unto that place:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 64,
          "number": 2996,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "and We saved Moses and all who were with him,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 65,
          "number": 2997,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "and then We caused the others to drown.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 66,
          "number": 2998,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "In this [story] behold, there is a message [unto all men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 67,
          "number": 2999,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "And yet, verily, thy Sustainer - He alone - is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 68,
          "number": 3000,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "And convey unto them the story of Abraham –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 69,
          "number": 3001,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "[how it was] when he asked his father and his people, “What is it that you worship?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 70,
          "number": 3002,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "They answered: We worship idols, and we remain ever devoted to them.”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 71,
          "number": 3003,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "Said he: “Do [you really think that] they hear you when you invoke them,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 72,
          "number": 3004,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "or benefit you or do you harm?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 73,
          "number": 3005,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "They exclaimed: But we found our fore­fathers doing the same!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 74,
          "number": 3006,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "Said [Abraham]: “Have you, then, ever con­sidered what it is that you have been worshipping",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 75,
          "number": 3007,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "you and those ancient forebears of yours?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 76,
          "number": 3008,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "“Now [as for me, I know that,] verily, these [false deities] are my enemies, [and that none is my helper] save the Sustainer of all the worlds,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 77,
          "number": 3009,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "who has created me and is the One who guides me,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 78,
          "number": 3010,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "and is the One who gives me to eat and to drink,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 79,
          "number": 3011,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "and when I fall ill, is the One who restores me to health,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 80,
          "number": 3012,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "and who will cause me to die and then will bring me back to life –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 81,
          "number": 3013,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "and who, [hope, will forgive me my faults on Judgment Day!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 82,
          "number": 3014,
          "juz": 19,
          "numpage": 370
        },
        {
          "text": "“O my Sustainer! Endow me with the ability to judge [between right and wrong], and make me one with the righteous,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 83,
          "number": 3015,
          "juz": 19,
          "numpage": 370
        }
      ]
    },
    {
      "369": [
        {
          "text": "so that we might follow [in the footsteps of] the sorcerers if it is they who prevail?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 40,
          "number": 2972,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "Now when the sorcerers came, they said unto Pharaoh: “Verily, we ought to have a great reward if it is we who prevail.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 41,
          "number": 2973,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "Answered he: “Yea - and, verily, in that case you shall be among those who are near unto me.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 42,
          "number": 2974,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "[And] Moses said unto them: “Throw whatever you are going to throw!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 43,
          "number": 2975,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "Thereupon they threw their [magic ropes and their staffs, and said: “By Pharaoh’s might, behold, it is we indeed who have prevailed!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 44,
          "number": 2976,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "[But] then Moses threw his staff - and lo! it swallowed up all their deceptions.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 45,
          "number": 2977,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "And down fell the sorcerers, prostrating them­selves in adoration,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 46,
          "number": 2978,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "[and] exclaimed: “We have come to believe in the Sustainer of all the worlds,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 47,
          "number": 2979,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "the Sustainer of Moses and Aaron!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 48,
          "number": 2980,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "Said [Pharaoh]: “Have you come to believe in him ere I have given you permission? Verily, he must be your master who has taught you magic! But in time you shall come to know [my revenge]: most certainly shall I cut off your hands and your feet in great numbers, because of [your] perverseness, and shall most certainly crucify you in great numbers, all together!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 49,
          "number": 2981,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "They answered: “No harm [canst thou do to us]: verily, unto our Sustainer do we turn!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 50,
          "number": 2982,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "Behold, we [but] ardently desire that our Sustainer forgive us our faults in return for our having been foremost among the believers!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 51,
          "number": 2983,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "AND [there came a time when] We inspired Moses thus: “Go forth with My servants by night: for, behold, you will he pursued!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 52,
          "number": 2984,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "And Pharaoh sent heralds unto all cities,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 53,
          "number": 2985,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "[bidding them to call out his troops and to proclaim:] “Behold, these [children of Israel] are but a contemptible band;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 54,
          "number": 2986,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "but they are indeed filled with hatred of us",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 55,
          "number": 2987,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "seeing that we are, verily, a nation united, fully prepared against danger –",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 56,
          "number": 2988,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "and so we have [rightly] driven them out of [their] gardens and springs,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 57,
          "number": 2989,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "and [deprived them of their erstwhile] station of honour!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 58,
          "number": 2990,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "Thus it was: but [in the course of time] We were to bestow all these [things] as a heritage on the children of Israel.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 59,
          "number": 2991,
          "juz": 19,
          "numpage": 369
        },
        {
          "text": "And so [the Egyptians] caught up with them at sunrise;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 60,
          "number": 2992,
          "juz": 19,
          "numpage": 369
        }
      ]
    },
    {
      "368": [
        {
          "text": "Replied [Moses]: “I committed it while I was still going astray;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 20,
          "number": 2952,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "and I fled from you because I feared you. But [since] then my Sustainer has endowed me with the ability to judge [between right and wrong], and has made me one of [His] message-­bearers.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 21,
          "number": 2953,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "And [as for] that favour of which thou so tauntingly remindest me - [was it not] due to thy having enslaved the children of Israel?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 22,
          "number": 2954,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "Said Pharaoh: “And what [and who] is that ‘Sustainer of all the worlds’?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 23,
          "number": 2955,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "[Moses] answered: “[He is] the Sustainer of the heavens and the earth and all that is between them: if you would but [allow yourselves to] be convinced!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 24,
          "number": 2956,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "Said [Pharaoh] unto those around him: “Did you hear [what he said]?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 25,
          "number": 2957,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "[And Moses] continued: “[He is] your Sus­tainer, [too,] as well as the Sustainer of your fore­fathers of old!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 26,
          "number": 2958,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "[Pharaoh] exclaimed: “Behold, [this] your ‘apostle’ who [claims that he] has been sent unto you is mad indeed!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 27,
          "number": 2959,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "[But Moses] went on: “[He of whom I speak is] the Sustainer of the east and the west and of all that is between the two”- as you would know] if you would but use your reason!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 28,
          "number": 2960,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "Said [Pharaoh]: “Indeed, if thou choose to worship any deity other than me, I shall most cer­tainly throw thee into prison!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 29,
          "number": 2961,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "Said he: “Even if I should bring about before thee something that clearly shows the truth?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 30,
          "number": 2962,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "[Pharaoh] answered: “Produce it, then, if thou art a man of truth!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 31,
          "number": 2963,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "Thereupon [Moses] threw down his staff - and lo! it was a serpent, plainly visible;",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 32,
          "number": 2964,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "and he drew forth his hand - and lo! it appeared [shining] white to the beholders.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 33,
          "number": 2965,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "Said [pharaoh] unto the great ones around him: “Verily, this is indeed a sorcerer of great knowledge",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 34,
          "number": 2966,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "who wants to drive you out of your land by his sorcery. What, then, do you advise?”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 35,
          "number": 2967,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "They answered: “Let him and his brother wait a while, and send unto all cities heralds",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 36,
          "number": 2968,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "who shall assemble before thee all sorcerers of great knowl­edge”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 37,
          "number": 2969,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "And so the sorcerers were assembled at a set time on a certain day,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 38,
          "number": 2970,
          "juz": 19,
          "numpage": 368
        },
        {
          "text": "and the people were asked: Are you all present,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 39,
          "number": 2971,
          "juz": 19,
          "numpage": 368
        }
      ]
    },
    {
      "367": [
        {
          "text": "Ta. Sin, Mim.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 1,
          "number": 2933,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "THESE ARE MESSAGES of the divine writ, clear in itself and clearly showing the truth!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 2,
          "number": 2934,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "Wouldst thou, perhaps, torment thyself to death [with grief] because they [who live around thee] refuse to believe [in it]?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 3,
          "number": 2935,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "Had We so willed, We could have sent down unto them a message from the skies, so that their necks would [be forced to] bow down before it in humility.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 4,
          "number": 2936,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "[But We have not willed it:] and so, whenever there comes unto them any fresh reminder from the Most Gracious, they [who are blind of heart] always - turn their backs upon it:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 5,
          "number": 2937,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "thus, indeed, have they given the lie (to this message as well). But [in time] they will come to understand what it was that they were wont to deride!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 6,
          "number": 2938,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "Have they, then, never considered the earth - how much of every noble kind [of life] We have caused to grow thereon?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 7,
          "number": 2939,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "In this, behold, there is a message [unto men], even though most of them will not believe [in it].",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 8,
          "number": 2940,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "But, verily, thy Sustainer - He alone - is almighty, a dispenser of grace!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 9,
          "number": 2941,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "HENCE, [remember how it was] when thy Sustainer summoned Moses: “Go unto those evil doing people.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 10,
          "number": 2942,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "the people of Pharaoh, who refuse to be con­scious of Me!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 11,
          "number": 2943,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "He answered: “O my Sustainer! Behold, I fear that they will give me the lie,",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 12,
          "number": 2944,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "and then my breast will be straitened and my tongue will not be free: send, then, [this Thy command] to Aaron.",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 13,
          "number": 2945,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "Moreover, they keep a grave charge [pending] against me, and I fear that they will slay me.”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 14,
          "number": 2946,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "Said He: “Not so, indeed! Go forth, then, both of you, with Our messages: verify, We shall be with you, listening [to your call]!",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 15,
          "number": 2947,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "And go, both of you, unto Pharaoh and say, ‘Behold, we bear a message from the Sustainer of all the worlds:",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 16,
          "number": 2948,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "Let the children of Israel go with us!’”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 17,
          "number": 2949,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "[But when Moses had delivered his message, Pharaoh] said: “Did we not bring thee up among us when thou wert a child? And didst thou not spend among us years of thy [later] life?",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 18,
          "number": 2950,
          "juz": 19,
          "numpage": 367
        },
        {
          "text": "And yet thou didst commit that [heinous] deed of thine, and [hast thus shown that] thou art one of the ingrate!”",
          "englishName": "Ash-Shu'araa",
          "suraNumber": 26,
          "numberInSurah": 19,
          "number": 2951,
          "juz": 19,
          "numpage": 367
        }
      ]
    },
    {
      "366": [
        {
          "text": "and who never invoke any [imaginary] deity side by side with God, and do not take any human beings life - [the life] which God has willed to be sacred - otherwise than in [the pursuit of] justice, and do not commit adultery. And [know that] he who commits aught thereof shall [not only] meet with a full requital",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 68,
          "number": 2923,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "[but] shall have his suffering doubled on Resurrection Day: for on that [Day] he shall abide in ignominy.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 69,
          "number": 2924,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "Excepted, however, shall be they who repent and attain to faith and do righteous deeds: for it is they whose [erstwhile] bad deeds God will transform into good ones - seeing that God is indeed much-forgiving, a dispenser of grace,",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 70,
          "number": 2925,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "and seeing that he who repents and [thenceforth] does what is right has truly turned unto God by [this very act of] repent­ance.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 71,
          "number": 2926,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "And [know that true servants of God are only] those who never bear witness to what is false, and [who], whenever they pass by [people engaged in] frivolity, pass on with dignity;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 72,
          "number": 2927,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "and who, whenever they are reminded of their Sustainer’s messages, do not throw themselves upon them [as if] deaf and blind;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 73,
          "number": 2928,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "and who pray “O our Sustainer! Grant that our spouses and our offspring be a joy to our eyes, and cause us to be foremost among those who are conscious of Thee!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 74,
          "number": 2929,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "[Such as] these will be rewarded for all their patient endurance [in life] with a high station [in paradise], and will be met therein with a greeting of welcome and peace,",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 75,
          "number": 2930,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "therein to abide: [and] how goodly an abode and [how high] a station!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 76,
          "number": 2931,
          "juz": 19,
          "numpage": 366
        },
        {
          "text": "SAY [unto those who believe]: “No weight or value would my Sustainer attach to you were it not for your faith [in Him]!” And say unto those who deny the truth:] “You have indeed given the lie [to God’s message], and in time this [sin] will cleave unto you!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 77,
          "number": 2932,
          "juz": 19,
          "numpage": 366
        }
      ]
    },
    {
      "365": [
        {
          "text": "Yet [withal, O Prophet,] We have sent thee only as a herald of glad tidings and a warner.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 56,
          "number": 2911,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "Say: “For this, no reward do I ask of you [- no reward] other than that he who so wills may unto his Sustainer find a way!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 57,
          "number": 2912,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "Hence, place thy trust in the Living One who dies not, and extol His limitless glory and praise: for none is as aware as His creatures’ sins as He –",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 58,
          "number": 2913,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "He who has created the heavens and the earth and all that is between them in six aeons, and is established on the throne of His almightiness: the Most Gracious! Ask, then, about Him, [the] One who is [truly] aware.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 59,
          "number": 2914,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "Yet when they [who are bent on denying the truth! are told, “Prostrate yourselves before the Most Gracious.” they are wont to ask, “And [who and] what is the Most Gracious? Are we to prostrate ourselves before whatever thou biddest us [to worship]?” - and so [thy call] but increases their aversion,",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 60,
          "number": 2915,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "HALLOWED is He who has set up in the skies great constellations, and has placed among them a [radiant] lamp and a light-giving moon.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 61,
          "number": 2916,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "And He it is who causes the night and the day to succeed one another, [revealing Himself in His works] unto him who has the will to take thought - that is has the will to be grateful.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 62,
          "number": 2917,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "For, [true] servants of the Most Gracious are [only] they who walk gently on earth, and who, whenever the foolish address them, reply with [words of] peace;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 63,
          "number": 2918,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "and who remember their Sustainer far into the night, prostrating themselves and standing;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 64,
          "number": 2919,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "and who pray: “O our Sustainer, avert from us the suffering of hell - for, verily, the suffering caused by it is bound to be a torment dire:",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 65,
          "number": 2920,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "verily, how evil an abode and a station!” -;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 66,
          "number": 2921,
          "juz": 19,
          "numpage": 365
        },
        {
          "text": "and who, whenever they spend on others, are neither wasteful nor niggardly but [remember that] there is always a just mean between those [two extremes];",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 67,
          "number": 2922,
          "juz": 19,
          "numpage": 365
        }
      ]
    },
    {
      "364": [
        {
          "text": "Or dost thou think that most of them listen [to thy message] and use their reason? Nay, they are but like cattle - nay, they are even less conscious of the right way!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 44,
          "number": 2899,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "Art thou not aware of thy Sustainer [through His works]? - how He causes the shadow to lengthen [towards the night] when, had He so willed, He could indeed have made it stand still: but then, We have made the sun its guide;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 45,
          "number": 2900,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "and then, [after having caused it to lengthen,] We draw it in towards Our­selves with a gradual drawing-in.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 46,
          "number": 2901,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "And He it is who makes the night a garment for you, and [your] sleep a rest, and causes every [new] day to be a resurrection.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 47,
          "number": 2902,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "And He it is who sends forth the winds as a glad tiding of His coming grace; and [thus, too,] We cause pure water to descend from the skies,",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 48,
          "number": 2903,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "so that We may bring dead land to life thereby, and give to drink thereof to many [beings] of Our creation, beasts as well as humans.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 49,
          "number": 2904,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "And, indeed, many times have We repeated [all] this unto men, so that they might take it to heart: but most men refuse to be aught but ingrate.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 50,
          "number": 2905,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "Now had We so willed. We could have [con­tinued as before and] raised up a [separate] warner in every single community:",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 51,
          "number": 2906,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "hence, do not defer to [the likes and dislikes of] those who deny the truth, but strive hard against them, by means of this [divine writ], with utmost striving.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 52,
          "number": 2907,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "AND HE it is who has given freedom of movement to the two great bodies of water – the one sweet and thirst-allaying, and the other salty and bitter - and yet has wrought between them a barrier and a forbidding ban.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 53,
          "number": 2908,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "And He it is who out of this [very] water has created man, and has endowed him with [the con­sciousness of] descent and marriage-tie: for thy Sustainer is ever infinite in His power.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 54,
          "number": 2909,
          "juz": 19,
          "numpage": 364
        },
        {
          "text": "And yet, some people worship, instead of God, things that can neither benefit them nor harm them: thus, he who denies the truth does indeed turn his back on his Sustainer!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 55,
          "number": 2910,
          "juz": 19,
          "numpage": 364
        }
      ]
    },
    {
      "363": [
        {
          "text": "and [that] they [who deny the truth] might never taunt thee with any deceptive half-truth without Our conveying to thee the [full] truth and [providing thee] with the best explanation.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 33,
          "number": 2888,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "[And so, tell those who are bent on denying the truth that] they who shall be gathered unto hell upon their faces – it is they who [in the life to come] will be worst in station and still farther astray from the path [of truth]!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 34,
          "number": 2889,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "AND, INDEED, [long before Muhammad] We vouchsafed revelation unto Moses, and appointed his brother Aaron to help him to bear his burden;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 35,
          "number": 2890,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "and We said, “Go you both unto the people who have given the lie to Our messages!” - and thereupon We broke those [sinners] to smithereens.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 36,
          "number": 2891,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "And [think of] the people of Noah: when they gave the lie to [one of] the apostles, We caused them to drown, and made them a symbol for all mankind: for, grievous suffering have We readied for all who [knowingly] do wrong!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 37,
          "number": 2892,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "And [remember how We punished the tribes of] Ad and Thamud and the people of Ar-Rass, and many generations [of sinners] in-between:",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 38,
          "number": 2893,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "and unto each of them did We proffer lessons and each of them did We destroy with utter destruction.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 39,
          "number": 2894,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "And they [who now deny Our messages] must surely have come across that town which was rained upon by a rain of evil: have they, then, never beheld it [with their minds eye]? But nay, they would not believe in resurrection!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 40,
          "number": 2895,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "Hence, whenever they consider thee, [O Muhammad,] they but make thee a target of their mockery, [saying:] “Is this the one whom God has sent as an apostle?",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 41,
          "number": 2896,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "Indeed, he would well-nigh have led us astray from our deities, had we not been [so] steadfastly attached to them!” But in time, when they see the suffering [that awaits them], they will come to know who it was that went farthest astray from the path [of truth]!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 42,
          "number": 2897,
          "juz": 19,
          "numpage": 363
        },
        {
          "text": "Hast thou ever considered [the kind of man] who makes his own desires his deity? Couldst thou, then, [O Prophet,] be held responsible for him?",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 43,
          "number": 2898,
          "juz": 19,
          "numpage": 363
        }
      ]
    },
    {
      "362": [
        {
          "text": "But those who do not believe that they are destined to meet Us are wont to say, “Why have no angels been sent down to us?” – or, “Why do we not see our Sustainer?” Indeed, they are far too proud of themselves, hav­ing rebelled [against God’s truth] with utter disdain!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 21,
          "number": 2876,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "[Yet] on that Day - the Day on which they shall see the angels there will be no glad tiding for those who were lost in sin; and they will exclaim, “By a forbidding ban [are we from God’s grace debarred]!” –",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 22,
          "number": 2877,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "for We shall have turned towards all the [supposedly good] deeds they ever wrought, and shall have transformed them into scattered dust –",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 23,
          "number": 2878,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "[whereas] on that same Day those who are destined for paradise will be graced with the best of abodes and the fairest place of repose.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 24,
          "number": 2879,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "And on the Day on which the skies, together with the clouds, shall burst asunder, and the angels are made to descend in a mighty descent –",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 25,
          "number": 2880,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "on that Day [it will become obvious to all that] true sover­eignty belongs to the Most Gracious [alone]: hence, a Day of distress will it be for all who deny the truth,",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 26,
          "number": 2881,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "and a Day on which the evildoer will bite his hands [in despair], exclaiming: “Oh, would that I had followed the path shown to me by the apostle!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 27,
          "number": 2882,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "Oh, woe is me! Would that I had not taken so-and-so for a friend!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 28,
          "number": 2883,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "Indeed, he led me astray from the remembrance [of God] after it had come unto me!” For [thus it is:] Satan is ever a betrayer of man.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 29,
          "number": 2884,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "AND [on that Day] the Apostle will say: “O my Sustainer! Behold, [some of] my people have come to regard this Qur’an as something [that ought to be] discarded!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 30,
          "number": 2885,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "For so it is that against every prophet We have set up enemies from among those who are lost in sin: yet none can guide and give succour as thy Sustainer does!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 31,
          "number": 2886,
          "juz": 19,
          "numpage": 362
        },
        {
          "text": "Now they who are bent on denying the truth are wont to ask. “Why has not the Qur’an been bestowed on him from on high in one single re­velation?” [it has been revealed] in this manner so that We might strengthen thy heart thereby - for We have so arranged its component parts that they form one consistent whole -",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 32,
          "number": 2887,
          "juz": 19,
          "numpage": 362
        }
      ]
    },
    {
      "361": [
        {
          "text": "when it shall face them from afar, they will hear its angry roar and its hiss;",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 12,
          "number": 2867,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "and when they are flung, linked [all] together, into a tight space within, they will pray for extinction there and then!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 13,
          "number": 2868,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "[But they will be told:] “Pray not today for one single extinction, but pray for many extinctions!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 14,
          "number": 2869,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "Say: “Which is better - that, or the paradise of life abiding which has been promised to the God-conscious as their reward and their journey’s end –",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 15,
          "number": 2870,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "a promise given by thy Sustainer, [always] to be prayed for?”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 16,
          "number": 2871,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "BUT [as for people who are oblivious of thy Sus­tainer’s oneness -] one Day He will gather them together with all that they [now] worship instead of God, and will ask [those to whom divinity was falsely ascribed]: “Was it you who led these My creatures astray, or did they by themselves stray from the right path?”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 17,
          "number": 2872,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "They will answer: “Limitless art Thou in Thy glory! It was inconceivable for us to take for our masters anyone but Thyself! But [as for them -] Thou didst allow them and their forefathers to enjoy [the pleasures of] life to such an extent that they forgot all remembrance [of Thee]: for they were people devoid of all good.”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 18,
          "number": 2873,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "[Thereupon God will say:] “And now, they [whom you regarded as divine] have given the lie to all your [past] assertions, and you can neither ward off [your punishment] nor obtain any succour! For, whoever of you has committed [such] evil, him shall We cause to taste great suffering!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 19,
          "number": 2874,
          "juz": 18,
          "numpage": 361
        },
        {
          "text": "AND [even] before thee, [O Muhammad,] We never sent as Our message-bearers any but [mortal men] who indeed ate food [like other human beings] and went about in the market places: for [it is thus that] We cause you [human beings] to be a means of testing one another. Are you able to endure [this test] with patience? For [remember, O man,] thy Sustainer is truly all-seeing!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 20,
          "number": 2875,
          "juz": 18,
          "numpage": 361
        }
      ]
    },
    {
      "360": [
        {
          "text": "And yet, some choose to worship, instead of Him, imaginary deities that cannot create anything but are themselves created, and have it not within their power to avert harm from, or bring benefit to, themselves, and have no power over death, nor over life, nor over resurrection!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 3,
          "number": 2858,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "Moreover, those who are bent on denying the truth are wont to say, “This [Qur’an] is nothing but a lie, which he [himself] has devised with the help of other people, who thereupon have perverted the truth and brought a falsehood into being.”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 4,
          "number": 2859,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "And they say, “Fables of ancient times which he has caused to be written down, so that they might be read out to him at morn and evening!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 5,
          "number": 2860,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "Say [O Muhammad]: “He who knows all the mysteries of the heavens and the earth has bestowed from on high this [Qur’an upon me]! Verily, He is much-forgiving, a dispenser of grace!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 6,
          "number": 2861,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "Yet they say: What sort of apostle is this [man] who eats food [like all other mortals] and goes about in the market places? Why has not an angel [visibly] been sent down unto him, to act as a warner together with him?”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 7,
          "number": 2862,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "Or: “[Why has not] a treasure been granted to him [by God]?” Or: “He should [at least] have a [bountiful] garden, so that he could eat thereof [without effort]!” And so these evildoers say [unto one another], “If you were to follow [Muhammad, you would follow] but a man bewitched!”",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 8,
          "number": 2863,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "See to what they liken thee, [O Prophet, simply] because they have gone astray and are now unable to find a way to the truth]!",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 9,
          "number": 2864,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "Hallowed is He who, if it be His will, shall give thee something better than that [whereof they speak] - gardens through which running waters flow - and shall assign to thee mansions [of bliss in the life to come].",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 10,
          "number": 2865,
          "juz": 18,
          "numpage": 360
        },
        {
          "text": "But nay! It is [the very coming of] the Last Hour to which they give the lie! However, for such as give the lie to [the announce­ment of] the Last Hour We have readied a blazing flame:",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 11,
          "number": 2866,
          "juz": 18,
          "numpage": 360
        }
      ]
    },
    {
      "359": [
        {
          "text": "[TRUE BELIEVERS are only they who have attained to faith in God and His Apostle, and who, whenever they are [engaged] with him upon a matter of concern to the whole community do not depart [from whatever has been decided upon] unless they have sought [and obtained] his leave. Verily, those who [do not abstain from the agreed ­upon action unless they] ask leave of thee - it is [only] they who [truly] believe in God and His Apostle! Hence, when they ask leave of thee for some [valid] reason of their own, grant thou this leave to whomsoever of them thou choose [to grant it], and ask God to forgive them: for, behold, God is much-forgiving, a dispenser of grace!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 62,
          "number": 2853,
          "juz": 18,
          "numpage": 359
        },
        {
          "text": "DO NOT regard the Apostles summons to you [in the same light] as a summons of one of you to another: God is indeed aware of those of you who would withdraw surreptitiously: so let those who would go against His bidding beware, lest a [bitter] trial befall them [in this world] or grievous suffering befall them [in the life to come].",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 63,
          "number": 2854,
          "juz": 18,
          "numpage": 359
        },
        {
          "text": "Oh, verily, unto God belongs all that is in the heavens and on earth: well does He know where you stand and at what you aim! And one Day, all [who have ever lived] will be brought back unto Him, and then He will make them [truly] understand all that they were doing [in life]: for, God has full knowledge of everything.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 64,
          "number": 2855,
          "juz": 18,
          "numpage": 359
        },
        {
          "text": "Hallowed is He who from on high, step by step, has bestowed upon His servant the standard by which to discern the true from the false, so that to all the world it might be a warning:",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 1,
          "number": 2856,
          "juz": 18,
          "numpage": 359
        },
        {
          "text": "He to whom the dominion over the heavens and the earth belongs, and who begets no offspring, and has no partner in His dominion: for it is He who creates every thing and determines its nature in accordance with [His own] design.",
          "englishName": "Al-Furqaan",
          "suraNumber": 25,
          "numberInSurah": 2,
          "number": 2857,
          "juz": 18,
          "numpage": 359
        }
      ]
    },
    {
      "358": [
        {
          "text": "Yet when the children among you attain to puberty, let them ask leave of you [at all times], even as those [who have reached maturity] before them have been enjoined to ask it. In this way God makes clear unto you His mes­sages: for God is all-knowing, wise!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 59,
          "number": 2850,
          "juz": 18,
          "numpage": 358
        },
        {
          "text": "AND [know that] women advanced in years, who no longer feel any sexual desire, incur no sin if they discard their [outer] garments, provided they do not aim at a showy display of [their] charms. But [even so,] it is better for them to abstain [from this]: and God is all-hearing, all-knowing.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 60,
          "number": 2851,
          "juz": 18,
          "numpage": 358
        },
        {
          "text": "[ALL OF YOU, O believers, are brethren: hence.] no blame attaches to the blind, nor does blame attach to the lame, nor does blame attach to the sick [for accepting charity from the hale], and neither to your­selves for eating [whatever is offered to you by others, whether it be food obtained] from your [chil­dren’s] houses, or your fathers’ houses, or your mothers’ houses, or your brothers’ houses, or your sisters’ houses, or your paternal uncles’ houses, or your paternal aunts’ houses, or your maternal uncles’ houses, or your maternal aunts’ houses, or [houses] the keys whereof are in your charge! or [the house] of any of your friends; nor will you incur any sin by eating in company or separately. But whenever you enter [any of these] houses, greet one another with a blessed, goodly greeting, as enjoined by God. In this way God makes clear unto you His mes­sages, so that you might [learn to] use your reason.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 61,
          "number": 2852,
          "juz": 18,
          "numpage": 358
        }
      ]
    },
    {
      "357": [
        {
          "text": "Say: “Pay heed unto God, and pay heed unto the Apostle.” And if you turn away [from the Apostle, know that] he will have to answer only for whatever he has been charged with, and you, for what you have been charged with; but if you pay heed unto him, you will be on the right way. Withal, the Apostle is not bound to do more than clearly deliver the message [entrust­ed to him].",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 54,
          "number": 2845,
          "juz": 18,
          "numpage": 357
        },
        {
          "text": "God has promised those of you who have attained to faith and do righteous deeds that, of a certainty, He will cause them to accede to power on earth, even as He caused [some of] those who lived before them to accede to it; and that, of a certainty, He will firmly establish for them the religion which He has been pleased to bestow on them; and that, of a certainty, He will cause their erstwhile state of fear to be replaced by a sense of security [seeing that] they worship Me [alone], not ascribing divine powers to aught beside Me. But all who, after [having understood] this, choose to deny the truth - it is they, they who are truly iniquitous!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 55,
          "number": 2846,
          "juz": 18,
          "numpage": 357
        },
        {
          "text": "Hence, [O believers,] be constant in prayer, and render the purifying dues, and pay heed unto the Apostle, so that you might be graced with God’s mercy.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 56,
          "number": 2847,
          "juz": 18,
          "numpage": 357
        },
        {
          "text": "[And] think not that those who are bent on denying the truth can elude [their final reckoning even if they remain unscathed] on earth: the fire is their goal [in the life to come] - and vile indeed is such a journey’s end!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 57,
          "number": 2848,
          "juz": 18,
          "numpage": 357
        },
        {
          "text": "O YOU who have attained to faith! At three times [of day], let [even] those whom you rightfully pos­sess, as well as those from among you who have not yet attained to puberty, ask leave of you [before intruding upon your privacy]: before the prayer of daybreak, and whenever you lay aside your garments in the middle of the day, and after the prayer of nightfall: the three occasions on which your nakedness is likely to be bared. Beyond these [occasions], neither you nor they will incur any sin if they move [freely] about you, attending to [the needs of] one another. In this way God makes clear unto you His mes­sages: for God is all-knowing, wise!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 58,
          "number": 2849,
          "juz": 18,
          "numpage": 357
        }
      ]
    },
    {
      "356": [
        {
          "text": "It is God who causes night and day to alter­nate: in this [too], behold, there is surely a lesson for all who have eyes to see!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 44,
          "number": 2835,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "And it is God who has created all animals out of water; and [He has willed that] among them are such as crawl on their bellies, and such as walk on two legs, and such as walk on four. God creates what He will: for, verily, God has the power to will anything.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 45,
          "number": 2836,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "INDEED, from on high have We bestowed messages clearly showing the truth; but God guides onto a straight way [only] him that wills [to be guided].",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 46,
          "number": 2837,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "For, [many are] they [who] say, “We believe in God and in the Apostle, and we pay heed!” - but then, some of them turn away after this [assertion]: and these are by no means [true] believers.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 47,
          "number": 2838,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "And [so it is that] whenever they are summoned unto God and His Apostle in order that [the divine writ] might judge between them, lo! some of them turn away;",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 48,
          "number": 2839,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "but if the truth happens to be to their liking, they are quite willing to accept it!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 49,
          "number": 2840,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "Is there disease in their hearts? Or have they begun to doubt [that this is a divine writ]? Or do they fear that God and His Apostle might deal unjustly with them? Nay, it is [but] they, they who are doing wrong [to themselves]!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 50,
          "number": 2841,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "The only response of believers, whenever they are summoned unto God and His Apostle in order that [the divine writ] might judge between them, can be no other than, “We have heard, and we pay heed!”- and it is they, they who shall attain to a happy state:",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 51,
          "number": 2842,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "for, they who pay heed unto God and His Apostle, and stand in awe of God and are conscious of Him, it is they, they who shall triumph [in the end]!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 52,
          "number": 2843,
          "juz": 18,
          "numpage": 356
        },
        {
          "text": "Now [as for those half-hearted ones,] they do swear by God with their most solemn oaths that if thou [O Apostle] shouldst ever bid them to do so, they would most certainly go forth [and sacrifice themselves]. Say: “Swear not! Reasonable compliance [with God’s message is all that is required of you]. Verily, God is aware of all that you do!”",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 53,
          "number": 2844,
          "juz": 18,
          "numpage": 356
        }
      ]
    },
    {
      "355": [
        {
          "text": "people whom neither [worldly] commerce nor striving after gain can divert from the remembrance of God, and from con­stancy in prayer, and from charity: [people] who are filled with fear [at the thought] of the Day On which all hearts and eyes will be convulsed,",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 37,
          "number": 2828,
          "juz": 18,
          "numpage": 355
        },
        {
          "text": "[and who only hope] that God may reward them in accordance with the best that they ever did, and give them, out of His bounty, more [than they deserve]: for, God grants sustenance unto whom He wills, beyond all reckon­ing.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 38,
          "number": 2829,
          "juz": 18,
          "numpage": 355
        },
        {
          "text": "But as for those who are bent on denying the truth, their [good] deeds are like a mirage in the desert, which the thirsty supposes to be water – until, when he approaches it, he finds that it was nothing: instead, he finds [that] God [has always been present] with him, and [that] He will pay him his account in full - for God is swift in reckoning!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 39,
          "number": 2830,
          "juz": 18,
          "numpage": 355
        },
        {
          "text": "Or [else, their deeds are] like the depths of darkness upon an abysmal sea, made yet more dark by wave billowing over wave, with [black] clouds above it all: depths of darkness, layer upon layer, [so that] when one holds up his hand, he can hardly see it: for he to whom God gives no light, no light whatever has he!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 40,
          "number": 2831,
          "juz": 18,
          "numpage": 355
        },
        {
          "text": "ART THOU NOT aware that it is God whose limit­less glory all [creatures] that are in the heavens and on earth extol, even the birds as they spread out their wings? Each [of them] knows indeed how to pray unto Him and to glorify Him; and God has full knowledge of all that they do:",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 41,
          "number": 2832,
          "juz": 18,
          "numpage": 355
        },
        {
          "text": "for, God’s is the dominion over the heavens and the earth, and with God is all journeys’ end.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 42,
          "number": 2833,
          "juz": 18,
          "numpage": 355
        },
        {
          "text": "Art thou not aware that it is God who causes the clouds to move onward, then joins them together, then piles them up in masses, until thou can see rain come forth from their midst? And He it is who sends down from the skies, by degrees, mountainous masses [of clouds] charged with hail, striking therewith whomever He wills and averting it from whomever He wills, [the while] the flash of His lightning well-nigh deprives [men of their] sight!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 43,
          "number": 2834,
          "juz": 18,
          "numpage": 355
        }
      ]
    },
    {
      "354": [
        {
          "text": "AND [you ought to] marry the single from among you as well as such of your male and female slaves as are fit [for marriage]. If they [whom you intend to marry] are poor, [let this not deter you;] God will grant them sufficiency out of His bounty - for God is infinite [in His mercy], all-knowing.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 32,
          "number": 2823,
          "juz": 18,
          "numpage": 354
        },
        {
          "text": "And as for those who are unable to marry, let them live in continence until God grants them sufficiency out of His bounty, And if any of those whom you rightfully possess desire [to obtain] a deed of freedom, write it out for them if you are aware of any good in them: and give them [their share of the wealth of God which He has given you. And do not, in order to gain some of the fleeting pleasures of this worldly life, coerce your [slave] maidens into whoredom if they happen to be desirous of marriage; and if anyone should coerce them, then, verily, after they have been compelled [to submit in their helplessness], God will be much-forgiving, a dispenser of grace!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 33,
          "number": 2824,
          "juz": 18,
          "numpage": 354
        },
        {
          "text": "AND, INDEED, from on high have We bestowed upon you messages clearly showing the truth, and [many] a lesson from [the stories of] those who have passed away before you, and [many] an admonition to the God-conscious.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 34,
          "number": 2825,
          "juz": 18,
          "numpage": 354
        },
        {
          "text": "God is the Light of the heavens and the earth. The parable of His light is, as it were, that of a niche containing a lamp; the lamp is [enclosed] in glass, the glass [shining] like a radiant star: [a lamp] lit from a blessed tree - an olive-tree that is neither of the east nor of the west the oil whereof [is so bright that it] would well-nigh give light [of itself] even though fire had not touched it: light upon light! God guides unto His light him that wills [to be guided]; and [to this end] God propounds parables unto men, since God [alone] has full knowledge of all things.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 35,
          "number": 2826,
          "juz": 18,
          "numpage": 354
        },
        {
          "text": "IN THE HOUSES [of worship] which God has allowed to be raised so that His name be remembered in them, there [are such as] extol His limitless glory at morn and evening –",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 36,
          "number": 2827,
          "juz": 18,
          "numpage": 354
        }
      ]
    },
    {
      "353": [
        {
          "text": "Hence, [even] if you find no one within [the house], do not enter it until you are given leave; and if you are told, “Turn back,” then turn back. This will be most conducive to your purity; and God has full knowledge of all that you do.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 28,
          "number": 2819,
          "juz": 18,
          "numpage": 353
        },
        {
          "text": "[On the other hand,] you will incur no sin if you [freely] enter houses not intended for living in but serving a purpose useful to you: but [always remember that] God knows all that you do openly, and all that you would conceal.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 29,
          "number": 2820,
          "juz": 18,
          "numpage": 353
        },
        {
          "text": "Tell the believing men to lower their gaze and to be mindful of their chastity: this will be most con­ducive to their purity – [and,] verily, God is aware of all that they do.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 30,
          "number": 2821,
          "juz": 18,
          "numpage": 353
        },
        {
          "text": "And tell the believing women to lower their gaze and to be mindful of their chastity, and not to display their charms [in public] beyond what may [decently] be apparent thereof; hence, let them draw their head-coverings over their bosoms. And let them not display [more of] their charms to any but their husbands, or their fathers, or their husbands’ fathers, or their sons, or their husbands’ Sons, or their brothers, or their brothers’ sons, or their sisters’ sons, or their womenfolk, or those whom they rightfully possess, or such male attendants as are beyond all sexual desire, or children that are as yet unaware of women’s nakedness; and let them not swing their legs [in walking] so as to draw attention to their hidden charms And [always], O you believers - all of you - turn unto God in repentance, so that you might attain to a happy state!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 31,
          "number": 2822,
          "juz": 18,
          "numpage": 353
        }
      ]
    },
    {
      "352": [
        {
          "text": "O You who have attained to faith! Follow not Satan’s footsteps: for he who follows Satan’s foot­steps [will find that], behold, he enjoins but deeds of abomination and all that runs counter to reason. And were it not for God’s favour upon you and His grace, not one of you would ever have remained pure. For [thus it is:] God who causes whomever He wills to grow in purity: for God is all-hearing, all-­knowing.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 21,
          "number": 2812,
          "juz": 18,
          "numpage": 352
        },
        {
          "text": "Hence, [even if they have been wronged by slander,] let not those of you who have been graced with God’s favour and ease of life ever become remiss in helping [the erring ones among] their near of kin, and the needy, and those who have forsaken the domain of evil for the sake of God, but let them pardon and forbear. [For,] do you not desire that God should forgive you your sins, seeing that God is much-forgiving, a dispenser of grace?",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 22,
          "number": 2813,
          "juz": 18,
          "numpage": 352
        },
        {
          "text": "[But,] verily, those who [falsely, and without repentance,] accuse chaste women who may have been unthinkingly careless but have remained true to their faith, shall be rejected [from God’s grace] in this world as well as in the life to come: and awesome suffering awaits them",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 23,
          "number": 2814,
          "juz": 18,
          "numpage": 352
        },
        {
          "text": "on the Day when their own tongues and hands and feet will bear witness against them by [recalling] all that they did!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 24,
          "number": 2815,
          "juz": 18,
          "numpage": 352
        },
        {
          "text": "On that day God will pay them in full their just due, and they will come to know that God alone is the Ultimate Truth, manifest, and manifesting [the true nature of all that has ever been done].",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 25,
          "number": 2816,
          "juz": 18,
          "numpage": 352
        },
        {
          "text": "[In the nature of things,] corrupt women are for corrupt men, and corrupt men, for corrupt women - just as good women are for good men, and good men, for good women. [Since God is aware that] these are innocent of all that evil tongues may impute to them, forgiveness of sins shall be theirs, and a most excellent sustenance!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 26,
          "number": 2817,
          "juz": 18,
          "numpage": 352
        },
        {
          "text": "O YOU who have attained to faith! Do not enter houses other than your own unless you have obtained permission and greeted their inmates. This is [enjoined upon you] for your own good, so that you might bear [your mutual rights] in mind.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 27,
          "number": 2818,
          "juz": 18,
          "numpage": 352
        }
      ]
    },
    {
      "351": [
        {
          "text": "Verily, numerous among you are those who would falsely accuse others of unchastity: [but, O you who are thus wronged,] deem it not a bad thing for you: nay, it is good for you! [As for the slanderers,] unto every one of them [will be accounted] all that he has earned by [thus] sinning; and awesome suffering awaits any of them who takes it upon himself to enhance this [sin]!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 11,
          "number": 2802,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "Why do not the believing men and women, whenever such [a rumour] is heard, think the best of one another and say, “This is an obvious falsehood”?",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 12,
          "number": 2803,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "why do they not [demand of the accusers that they] their allegation? for, if they do not produce such wit­nesses, it is those [accusers] who, in the sight of God, are liars indeed!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 13,
          "number": 2804,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "And were it not for God’s favour upon you, [O men,] and His grace in this world and in the life to come, awesome suffering would indeed have afflicted you in result of all [the calumny] in which you indulge",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 14,
          "number": 2805,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "when you take it up with your tongues, uttering with your mouths something of which you have no knowledge, and deeming it a light matter whereas in the sight of God it is an awful thing!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 15,
          "number": 2806,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "And [once again]: Why do you not say, whenever you hear such [a rumour], “It does not behove us to speak of this, O Thou who art limitless in Thy glory: this is an awesome calumny”?",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 16,
          "number": 2807,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "God admonishes you [hereby] lest you ever revert to the like of this [sin], if you are [truly] believers;",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 17,
          "number": 2808,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "for God makes [His] messages clear unto you - and God is all-knowing, Wise!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 18,
          "number": 2809,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "Verily, as for those who like [to hear] foul slander spread against [any of] those who have attained to faith grievous suffering awaits them in this world and in the life to come: for God knows [the full truth], whereas you know [it] not.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 19,
          "number": 2810,
          "juz": 18,
          "numpage": 351
        },
        {
          "text": "And were it not for God’s favour upon you and His grace, and that God is compassionate, a dispenser of grace...!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 20,
          "number": 2811,
          "juz": 18,
          "numpage": 351
        }
      ]
    },
    {
      "350": [
        {
          "text": "A Surah [is this] which We have bestowed from on high, and which We have laid down in plain terms; and in it have We bestowed from on high messages which are clear [in themselves], so that you might keep [them] in mind.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 1,
          "number": 2792,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "AS FOR the adulteress and the adulterer flog each of them with a hundred stripes, and let not compassion with them keep you from [carrying out] this law of God, if you [truly] believe in God and the Last Day; and let a group of the believers witness their chastisement.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 2,
          "number": 2793,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "[Both are equally guilty:] the adulterer couples with none other than an adulteress - that is, a woman who accords [to her own lust] a place side by side with God; and with the adulteress couples none other than an adulterer - that is, a man who accords [to his own lust] a place side by side with God: and this is forbidden unto the believers.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 3,
          "number": 2794,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "And as for those who accuse chaste women [of adultery], and then are unable to produce four witnesses [in support of their accusation], flog them with eighty stripes and ever after refuse to accept from them any testimony - since it is they, they that are truly depraved!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 4,
          "number": 2795,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "excepting [from this interdict] only those who afterwards repent and made amends: for, behold, God is much forgiving, a dispenser of grace.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 5,
          "number": 2796,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "And as for those who accuse their own wives [of adultery], but have no witnesses except them­selves, let each of these [accusers] call God four times to witness that he is indeed telling the truth,",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 6,
          "number": 2797,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "and the fifth time, that God’s curse be upon him if he is telling a lie.",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 7,
          "number": 2798,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "But [as for the wife, all] chastisement shall be averted from her by her calling God four times to witness that he is indeed telling a lie,",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 8,
          "number": 2799,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "and the fifth [time], that God’s curse be upon her if he is telling the truth,",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 9,
          "number": 2800,
          "juz": 18,
          "numpage": 350
        },
        {
          "text": "AND WERE it not for God’s favour upon you, [O man,] and His grace, and that God is a wise acceptor of repentance...!",
          "englishName": "An-Noor",
          "suraNumber": 24,
          "numberInSurah": 10,
          "number": 2801,
          "juz": 18,
          "numpage": 350
        }
      ]
    },
    {
      "349": [
        {
          "text": "[And God will say:] “Were not My messages conveyed unto you, and were you [not] wont to give them the lie?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 105,
          "number": 2778,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "They will exclaim: “O our Sustainer! Our bad luck has overwhelmed us, and so we went astray!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 106,
          "number": 2779,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "O our Sustainer! Cause us to come out of this [suffering] - and then, if ever We revert [to sinning], may we truly be [deemed] evildoers!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 107,
          "number": 2780,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "[But] He will say: “Away with you into this [ignominy]! And speak no more unto Me!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 108,
          "number": 2781,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "“Behold, there were among My servants such as would pray, ‘O our Sustainer! We have come to believe [in Thee]; forgive, then, our sins and bestow Thy mercy on us: for Thou art the truest bestower of mercy!’",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 109,
          "number": 2782,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "- but you made them a target of your derision to the point where it made you forget all remembrance of Me; and you went on and on laugh­ing at them.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 110,
          "number": 2783,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "[But,] behold, today I have rewarded them for their patience in adversity: verily, it is they, they who have achieved a triumph!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 111,
          "number": 2784,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "[And] He will ask [the doomed]: “What number of years have you spent on earth?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 112,
          "number": 2785,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "They will answer: ‘We have spent there a day, or part of a day; but ask those who [are able to] count [time] …”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 113,
          "number": 2786,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "[Whereupon] He will say: “You have spent there but a short while: had you but known [how short it was to be]!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 114,
          "number": 2787,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "Did you, then, think that We created you in mere idle play, and that you would not have to return to Us?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 115,
          "number": 2788,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "[KNOW,] then, [that] God is sublimely exalted, the Ultimate Sovereign, the Ultimate Truth: there is no deity save Him, the Sustainer, in bountiful almightiness enthroned!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 116,
          "number": 2789,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "Hence, he who invokes, side by side with God, any other deity [- a deity] for whose existence he has no evidence - shall but find his reckoning with his Sustainer: [and,] verily, such deniers of the truth will never attain to a happy state!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 117,
          "number": 2790,
          "juz": 18,
          "numpage": 349
        },
        {
          "text": "Hence, [O believer,] say: “O my Sustainer! Grant [me] forgiveness and bestow Thy mercy [upon me]: for Thou art the truest bestower of mercy!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 118,
          "number": 2791,
          "juz": 18,
          "numpage": 349
        }
      ]
    },
    {
      "348": [
        {
          "text": "Nay, We have conveyed unto them the truth: and yet, behold, they are intent on lying [to them­selves]!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 90,
          "number": 2763,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "Never did God take unto Himself any offspring, nor has there ever been any deity side by side with Him: [for, had there been any,] lo! each deity would surely have stood apart [from the others] in whatever it had created, and they would surely have [tried to] overcome one another! Limitless in His glory is God, [far] above anything that men may devise by way of definition,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 91,
          "number": 2764,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "knowing all that is beyond the reach of a created being’s perception as well as all that can be witnessed by a creature’s senses or mind and, hence, sublimely exalted is He above anything to which they may ascribe a share in His divinity!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 92,
          "number": 2765,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "SAY: “O my Sustainer! If it be Thy will to let me witness [the fulfillment of] whatever they [who blaspheme against Thee] have been promised [to suffer] –",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 93,
          "number": 2766,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "do not, O my Sustainer, let me be one of those evildoing folk!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 94,
          "number": 2767,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "[Pray thus] for, behold, We are most cer­tainly able to let thee witness [the fulfillment, even in this world, of] whatever We promise them!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 95,
          "number": 2768,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "[But whatever they may say or do,] repel the evil [which they commit] with something that is bet­ter: We are fully aware of what they attribute [to Us].",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 96,
          "number": 2769,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "And say: “O my Sustainer! I seek refuge with Thee from the promptings of all evil impulses;",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 97,
          "number": 2770,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "and I seek refuge with Thee, O my Sustainer, lest they come near unto me!’",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 98,
          "number": 2771,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "[AS FOR THOSE who will not believe in the life to come, they go on lying to themselves] until, when death approaches any of them, he prays: “O my Sustainer! Let me return, let me return [to life],",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 99,
          "number": 2772,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "so that I might act righteously in whatever I have failed [aforetime]!” Nay, it is indeed but a [meaningless] word that he utters: for behind those [who leave the world] there is a barrier [of death] until the Day when all will be raised from the dead!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 100,
          "number": 2773,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "Then, when the trumpet [of resurrection] is blown, no ties of kinship will on that Day prevail among them, and neither will they ask about one another.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 101,
          "number": 2774,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "And they whose weight [of righteousness] is heavy in the balance - it is they, they who will have attained to a happy state;",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 102,
          "number": 2775,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "whereas they whose weight is light in the balance - it is they who will have squandered their own selves, [destined] to abide in hell:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 103,
          "number": 2776,
          "juz": 18,
          "numpage": 348
        },
        {
          "text": "the fire will scorch their faces, and they will abide therein with their lips distorted in pain.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 104,
          "number": 2777,
          "juz": 18,
          "numpage": 348
        }
      ]
    },
    {
      "347": [
        {
          "text": "And even were We to show them mercy and remove whatever distress might befall them [in this life], they would still persist in their overweening arrogance, blindly stumbling to and fro.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 75,
          "number": 2748,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "And, indeed, We tested them through suffer­ing, but they did not abase themselves before their Sustainer; and they will never humble them­selves",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 76,
          "number": 2749,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "until We open before them a gate of [truly] severe suffering [in the life to come]: and then, lo! they will be broken in spirit.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 77,
          "number": 2750,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "[O MEN! Pay heed to God’s messages,] for it is He who has endowed you with hearing, and sight, and minds: [yet] how seldom are you grateful!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 78,
          "number": 2751,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "And He it is who has caused you to multiply on earth; and unto Him you shall be gathered.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 79,
          "number": 2752,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "And He it is who grants life and deals death; and to Him is due the alternation of night and day. Will you not, then, use your reason?",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 80,
          "number": 2753,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "But nay, they speak as the people of olden times did speak:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 81,
          "number": 2754,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "they say: “What! After we have died and become mere dust and bones, shall we, forsooth, be raised from the dead?",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 82,
          "number": 2755,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "Indeed, this [very thing] we have been promised - we and our forefathers - long ago! This is nothing but fables of ancient times!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 83,
          "number": 2756,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "Say: “Unto whom belongs the earth and all that lives there on? [Tell me this] if you happen to know [the answer]!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 84,
          "number": 2757,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "[And they will reply: “Unto God.” Say: “Will you not, then, bethink yourselves [of Him]?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 85,
          "number": 2758,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "Say: “Who is it that sustains the seven heavens and is enthroned in His awesome almightiness?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 86,
          "number": 2759,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "[And] they will reply: “[All this power belongs] to God.” Say: “Will you not, then, remain conscious of Him?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 87,
          "number": 2760,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "Say: “In whose hand rests the mighty dominion over all things, and who is it that protects, the while there is no protection against Him? [Tell me this] if you happen to know [the answer]!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 88,
          "number": 2761,
          "juz": 18,
          "numpage": 347
        },
        {
          "text": "[And] they will reply: “[All this power belongs] to God.” Say: ‘How, then, can you be so deluded?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 89,
          "number": 2762,
          "juz": 18,
          "numpage": 347
        }
      ]
    },
    {
      "346": [
        {
          "text": "and who give whatever they [have to] give with their hearts trembling at the thought that unto their Sustainer they must return:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 60,
          "number": 2733,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "it is they who vie with one another in doing good works, and it is they who outrun [all others] in attaining to them!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 61,
          "number": 2734,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "And [withal.] We do not burden any human being with more than he is well able to bear: for with Us is a record that speaks the truth [about what men do and can do]; and none shall be wronged.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 62,
          "number": 2735,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "NAY, [as for those who have torn asunder the unity of faith] - their hearts are lost in ignorance of all this! But apart from that [breach of unity] they have [on their conscience even worse] deeds; and they will [continue to] commit them",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 63,
          "number": 2736,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "until - after We shall have taken to task, through suffering, those from among them who [now] are lost in the pursuit of pleasures - they cry out in [belated] supplication.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 64,
          "number": 2737,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "[But they will be told:] “Cry not in supplication today: for, behold, you shall not he succoured by Us!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 65,
          "number": 2738,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "Time and again were My messages conveyed unto you, but [every time] you would turn about on your heels",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 66,
          "number": 2739,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "[and,] impelled by your arrogance, you would talk senselessly far into the night.”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 67,
          "number": 2740,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "Have they, then, never tried to understand this word [of God]? Or has there [now] come to them something that never came to their forefathers of old?",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 68,
          "number": 2741,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "Or is it, perchance, that they have not recog­nized their Apostle, and so they disavow him?",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 69,
          "number": 2742,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "Or do they say. “There is madness in him”? Nay, he has brought them the truth - and the truth do most of them detest!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 70,
          "number": 2743,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "But if the truth were in accord with their own likes and dislikes, the heavens and the earth would surely have fallen into ruin, and all that lives in them [would long ago have perished]! Nay, [in this divine writ] We have conveyed unto them all that they ought to bear in mind: and from this their reminder they [heedlessly] turn away!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 71,
          "number": 2744,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "Or dost thou [O Muhammad] ask of them any worldly recompense? But [they ought to know that] recompense from thy Sustainer is best, since He is the best of providers!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 72,
          "number": 2745,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "And, verily, thou callest them onto a straight way –",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 73,
          "number": 2746,
          "juz": 18,
          "numpage": 346
        },
        {
          "text": "but, behold, those who will not believe in the life to come are bound to deviate from that way.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 74,
          "number": 2747,
          "juz": 18,
          "numpage": 346
        }
      ]
    },
    {
      "345": [
        {
          "text": "[for,] no community can ever forestall [the end of] its term - and neither can they delay [its coming].",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 43,
          "number": 2716,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "And We sent forth Our apostles, one after another: [and] every time their apostle came to a community, they gave him the lie: and so We caused them to follow one another [into the grave], and let them become [mere] tales: and so - away with the folk who would not believe!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 44,
          "number": 2717,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "AND THEN We sent forth Moses and his brother Aaron with Our messages and a manifest authority [from Us]",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 45,
          "number": 2718,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "unto Pharaoh and his great ones; but these behaved with arrogance, for they were people wont to glorify [only] themselves.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 46,
          "number": 2719,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "And so they said: “Shall we believe [them] two mortals like ourselves - although their people are our slaves?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 47,
          "number": 2720,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "Thus, they gave the lie to those two, and earned (thereby) their place among the doomed:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 48,
          "number": 2721,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "for, indeed, We had vouchsafed revelation unto Moses in order that they might find the right way.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 49,
          "number": 2722,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "And [as We exalted Moses, so, too,] We made the son of Mary and his mother a symbol [of Our grace], and provided for both an abode in a lofty place of lasting restfulness and unsullied springs.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 50,
          "number": 2723,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "O YOU APOSTLES! Partake of the good things of life, and do righteous deeds: verily, I have full knowledge of all that you do.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 51,
          "number": 2724,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "And, verily, this community of yours is one single community, since I am the Sustainer of you all: remain, then, conscious of Me!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 52,
          "number": 2725,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "But they (who claim to follow you) have torn their unity wide asunder, piece by piece, each group delighting in [but] what they themselves possess [by way of tenets].",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 53,
          "number": 2726,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "But leave them alone, lost in their ignorance, until a [future] time.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 54,
          "number": 2727,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "Do they think that by all the wealth and offspring with which We provide them",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 55,
          "number": 2728,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "We [but want to] make them vie with one another in doing [what they consider] good works? Nay, but they do not perceive [their error]!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 56,
          "number": 2729,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "Verily, [only] they who stand in reverent awe of their Sustainer,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 57,
          "number": 2730,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "and who believe in their Sus­tainer’s messages,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 58,
          "number": 2731,
          "juz": 18,
          "numpage": 345
        },
        {
          "text": "and who do not ascribe divinity to aught but their Sustainer,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 59,
          "number": 2732,
          "juz": 18,
          "numpage": 345
        }
      ]
    },
    {
      "344": [
        {
          "text": "“And as soon as thou and those who are with thee are settled in the ark, say: ‘All praise is due to God, who has saved us from those evildoing folk!’",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 28,
          "number": 2701,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "“And say: ‘O my Sustainer! Cause me to reach a destination blessed [by Thee] for Thou art the best to show man how to reach his [true] destination!’”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 29,
          "number": 2702,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "In this [story], behold, there are messages indeed [for those who think]: for, verily, We always put [man] to a test.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 30,
          "number": 2703,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "AND AFTER those [people of old] We gave rise to new generations;",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 31,
          "number": 2704,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "and [every time] We sent unto them an apostle from among themselves, [he told them:] “Worship God [alone]: you have no deity other than Him. Will you not, then, become conscious of Him?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 32,
          "number": 2705,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "And [every time] the great ones among his people, who refused to acknowledge the truth and gave the lie to the announcement of a life to come - [simply] because We had granted them ease and plenty in [their] worldly life, and they had become corrupted by it [every time] they would say: “This [man] is nothing hut a mortal like yourselves, eating of what you eat, and drinking of what you drink:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 33,
          "number": 2706,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "and, indeed, if you pay heed to a mortal like your­selves, you will surely be the losers!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 34,
          "number": 2707,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "Does he promise you that, after you have died and become [mere] dust and bones, you shall be brought forth [to a new life]?",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 35,
          "number": 2708,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "Far-fetched, far-fetched indeed is what you are promised!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 36,
          "number": 2709,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "There is no life beyond our life in this world: we die and we live [but once], and we shall never be raised from the dead!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 37,
          "number": 2710,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "He is nothing but a man who attributes his own lying in­ventions to God, and we are not going to believe him!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 38,
          "number": 2711,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "[Whereupon the prophet] would say: “O my Sustainer! Succour me against their accusation of lying!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 39,
          "number": 2712,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "[And God] would say: “After a little while they will surely be smitten with remorse!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 40,
          "number": 2713,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "And then the blast [of Our punishment] over­took them, justly and unavoidably, and We caused them to become as the flotsam of dead leaves and the scum borne on the surface of a torrent: and so - away with those evildoing folk!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 41,
          "number": 2714,
          "juz": 18,
          "numpage": 344
        },
        {
          "text": "AND AFTER them We gave rise to new genera­tions:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 42,
          "number": 2715,
          "juz": 18,
          "numpage": 344
        }
      ]
    },
    {
      "343": [
        {
          "text": "And We send down water from the skies in accordance with a measure [set by Us], and then We cause it to lodge in the earth: but, behold, We are most certainly able to withdraw this [blessing]!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 18,
          "number": 2691,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "And by means of this [water] We bring forth for you gardens of date-palms and vines, wherein you have fruit abundant and whereof you eat,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 19,
          "number": 2692,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "as well as a tree that issues from [the lands adjoining] Mount Sinai, yielding oil and relish for all to eat.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 20,
          "number": 2693,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "And, behold, in the cattle [too] there is indeed a lesson for you: We give you to drink of that [milk] which is within their bellies; and you derive many [other] uses from them: for, you eat of their flesh,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 21,
          "number": 2694,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "and by them - as by the ships [over the sea] - you are borne [overland].",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 22,
          "number": 2695,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "AND, INDEED, We sent forth Noah unto his people and he said: “O my people! Worship God alone]: you have no deity other than Him. Will you not, then, become conscious of Him?”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 23,
          "number": 2696,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "But the great ones among his people, who refused to acknowledge the truth, replied: “This [man] is nothing but a mortal like yourselves who wants to make himself superior to you! For, if God had willed [to convey a message unto us], He would surely have sent down angels; [moreover,] we have never heard [any­thing like] this from our forebears of old!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 24,
          "number": 2697,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "He is nothing but a madman: so bear with him for a while.”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 25,
          "number": 2698,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "Said [Noah]: “O my Sustainer! Succour me against their accusation of lying!”",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 26,
          "number": 2699,
          "juz": 18,
          "numpage": 343
        },
        {
          "text": "Thereupon We inspired him thus: “Build, un­der Our eyes and according to Our inspiration, the ark [that shall save thee and those who follow thee]. And when Our judgment comes to pass, and waters gush forth in torrents over the face of the earth, place on board of this [ark] one pair of each [kind of animal] of either sex, as well as thy family - excepting those on whom sentence has already been passed; and do not appeal to Me [any more] in behalf of those who are bent on evildoing - for, behold, they are destined to be drowned!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 27,
          "number": 2700,
          "juz": 18,
          "numpage": 343
        }
      ]
    },
    {
      "342": [
        {
          "text": "TRULY, to a happy state shall attain the believers:",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 1,
          "number": 2674,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "those who humble themselves in their prayer,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 2,
          "number": 2675,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and who turn away from all that is frivolous,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 3,
          "number": 2676,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and who are intent on inner purity;",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 4,
          "number": 2677,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and who are mindful of their chastity,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 5,
          "number": 2678,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "[not giving way to their desires] with any but their spouses - that is, those whom they rightfully possess [through wedlock]: for then, behold, they are free of all blame,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 6,
          "number": 2679,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "whereas such as seek to go beyond that [limit] are truly transgressors;",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 7,
          "number": 2680,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and who are faithful to their trusts and to their pledges,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 8,
          "number": 2681,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and who guard their prayers [from all worldly intent].",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 9,
          "number": 2682,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "It is they, they who shall be the inheritors",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 10,
          "number": 2683,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "that will inherit the paradise; [and] therein shall they abide.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 11,
          "number": 2684,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "NOW, INDEED, We create man out of the essence of clay,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 12,
          "number": 2685,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and then We cause him to remain as a drop of sperm in [the wombs] firm keeping,",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 13,
          "number": 2686,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and then We create out of the drop of sperm a germ-cell, and then We create out of the germ-cell an embryonic lump, and then We create within the embryonic lump bones, and then We clothe the bones with flesh - and then We bring [all] this into being as a new creation: hallowed, therefore, is God, the best of artisans!",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 14,
          "number": 2687,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "And then, behold! after all this, you are destined to die;",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 15,
          "number": 2688,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "and then, behold! you shall be raised from the dead on Resurrection Day.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 16,
          "number": 2689,
          "juz": 18,
          "numpage": 342
        },
        {
          "text": "And, indeed, We have created above you seven [celestial] orbits: and never are We unmindful of [any aspect of Our] creation.",
          "englishName": "Al-Muminoon",
          "suraNumber": 23,
          "numberInSurah": 17,
          "number": 2690,
          "juz": 18,
          "numpage": 342
        }
      ]
    },
    {
      "341": [
        {
          "text": "O MEN! A parable is set forth [herewith]; hearken, then, to it! Behold, those beings whom you invoke instead of God cannot create [as much as] a fly, even were they to join all their forces to that end! And if a fly robs them of anything, they cannot [even] rescue it from him! Weak indeed is the seeker, and [weak] the sought!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 73,
          "number": 2668,
          "juz": 17,
          "numpage": 341
        },
        {
          "text": "No true understanding of God have they [who err in this way]: for, verily, God is most powerful, almighty!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 74,
          "number": 2669,
          "juz": 17,
          "numpage": 341
        },
        {
          "text": "[In His almightiness,] God chooses message-bearers from among the angels as well as from among men. But, behold, God [alone] is all-hearing, all-see­ing:",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 75,
          "number": 2670,
          "juz": 17,
          "numpage": 341
        },
        {
          "text": "[whereas their knowledge is limited,] He knows all that lies open before them and all that is hidden from them for all things go back to God [as their source].",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 76,
          "number": 2671,
          "juz": 17,
          "numpage": 341
        },
        {
          "text": "O YOU who have attained to faith! Bow down and prostrate yourselves, and worship your Sustainer [alone], and do good, so that you might attain to a happy state!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 77,
          "number": 2672,
          "juz": 17,
          "numpage": 341
        },
        {
          "text": "And strive hard in God’s cause with all the striving that is due to Him: it is He who has elected you [to carry His message], and has laid no hardship on you in [anything that pertains to religion, [and made you follow] the creed of your forefather Abraham. It is He who has named you in bygone times as well as in this [divine writ] – “those who have surrendered themselves to God”, so that the Apostle might bear witness to the truth before you, and that you might bear witness to it before all mankind. Thus, be constant in prayer, and render the purifying dues, and hold fast unto God. He is your Lord Supreme: and how excellent is this Lord Supreme, and how excellent this Giver of Succour!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 78,
          "number": 2673,
          "juz": 17,
          "numpage": 341
        }
      ]
    },
    {
      "340": [
        {
          "text": "Art thou not aware that it is God who has made subservient to you all that is on earth, and the ships that sail through the sea at His behest - and [that it is He who] holds the celestial bodies [in their orbits], so that they may not fall upon the earth otherwise than by His leave? Verily, God is most compassionate towards men, a dispenser of grace –",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 65,
          "number": 2660,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "seeing that it is He who gave you life, and then will cause you to die, and then will bring you back to life: [but,] verily, bereft of all gratitude is man!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 66,
          "number": 2661,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "UNTO every community have We appointed [different] ways of worship, which they ought to observe. Hence, [O believer,] do not let those [who follow ways other than thine] draw thee into disputes on this score, but summon [them all] unto thy Sustainer: for, behold, thou art indeed on the right way.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 67,
          "number": 2662,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "And if they [try to] argue with thee, say [only]: “God knows best what you are doing.”",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 68,
          "number": 2663,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "[For, indeed,] God will judge between you [all] on Resurrection Day with regard to all on which you were wont to differ.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 69,
          "number": 2664,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "Dost thou not know that God knows all that occurs in heaven as well as on earth? All this, behold, is in [God’s] record: verily, [to know] all this is easy for God.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 70,
          "number": 2665,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "And yet they [who claim to believe in Him often] worship [other beings or forces] beside God - something for which He has never bestoweed any warrant from on high, and [of the reality] whereof they cannot have any knowledge: and such evildoers shall have none to succour them [on Judgment Day].",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 71,
          "number": 2666,
          "juz": 17,
          "numpage": 340
        },
        {
          "text": "As it is, whenever Our messages are conveyed unto them in all their clarity, thou canst perceive utter repugnance on the faces of those who are bent on denying the truth: they would almost assault those who convey Our messages unto them! Say: “Shall I, then, tell you of something worse than what you feel at present? It is the fire [of the hereafter] that God has promised to those who are bent on denying the truth: and how vile a journey’s end!”",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 72,
          "number": 2667,
          "juz": 17,
          "numpage": 340
        }
      ]
    },
    {
      "339": [
        {
          "text": "On that Day, all dominion shall [visibly] belong to God, He shall judge [all men and make a distinction] between them: thus, all who had attained to faith and did righteous deeds shall find themselves in gardens of bliss,",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 56,
          "number": 2651,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "whereas for those who were bent on denying the truth and gave the lie to Our messages, there shall be shameful suffering in store.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 57,
          "number": 2652,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "AND AS FOR those who forsake the domain of evil (and strive) in God’s cause, and then are slain or die - God will most certainly provide for them a goodly sustenance [in the life to come] for, verily, God - He alone - is the best of providers;",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 58,
          "number": 2653,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "[and] He will most certainly cause them to enter upon a state (of being) that shall please them well: for, verily, God is all-knowing, most forbearing.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 59,
          "number": 2654,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "Thus shall it be. And as for him who responds to aggression only to the extent of the attack levelled against him, and is thereupon [again] treacherously attacked - God will most certainly succour him: for, behold, God is indeed an absolver of sins, much-forgiving.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 60,
          "number": 2655,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "Thus it is, because God [is almighty the One who] makes the night grow longer by shortening the day, and makes the day grow longer by shortening the night; and because God is all-hearing, all-seeing.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 61,
          "number": 2656,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "Thus it is, because God alone is the Ultimate Truth, so that all that men invoke beside Him is sheer falsehood, and because God alone is exalted, great!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 62,
          "number": 2657,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "Art thou not aware that it’s God who sends down water from the skies, whereupon the earth becomes green? Verily, God is unfathomable [in His wisdom], all-aware.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 63,
          "number": 2658,
          "juz": 17,
          "numpage": 339
        },
        {
          "text": "Unto Him belongs all that is in the heavens and all that is on earth; and, verily, God - He alone - is self-sufficient, the One to whom all praise is due.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 64,
          "number": 2659,
          "juz": 17,
          "numpage": 339
        }
      ]
    },
    {
      "338": [
        {
          "text": "And [so, O Muhammad,] they challenge thee to hasten the coming upon them of [God’s] chastisement: but God never fails to fulfill His promise - and, behold, in thy Sustainer’s sight a day is like a thousand years of your reckoning.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 47,
          "number": 2642,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "And to how many a community that was im­mersed in evildoing have I given rein for a while! But then I took it to task: for with Me is all journeys’ end!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 48,
          "number": 2643,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "SAY [O Muhammad]: “O men! I am but a plain warner [sent by God] unto you!”",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 49,
          "number": 2644,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "And [know that] those who attain to faith and do righteous deeds shall be granted forgiveness of sins and a most excellent sustenance;",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 50,
          "number": 2645,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "whereas those who strive against Our messages, seeking to defeat their purpose - they are destined for the blazing fire.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 51,
          "number": 2646,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "Yet whenever We sent forth any apostle or prophet before thee, and he was hoping [that his warnings would be heeded], Satan would cast an asper­sion on his innermost aims: but God renders null and void whatever aspersion Satan may cast; and God makes His messages clear in and by themselves for God is all-knowing, wise.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 52,
          "number": 2647,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "[And He allows doubts to arise] so that He might cause whatever aspersion Satan may cast [against His prophets] to become a trial for all in whose hearts is disease and all whose hearts are hardened: for, verily, all who are [thus] sinning [against themselves] are most deeply in the wrong.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 53,
          "number": 2648,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "And [God renders Satan’s aspersions null and void] so that they who are endowed with [innate] knowledge might know that this [divine writ] is the truth from thy Sustainer, and that they aught believe in it, and that their hearts might humbly submit unto Him. For, behold, God does guide onto a straight way those who have attained to faith –",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 54,
          "number": 2649,
          "juz": 17,
          "numpage": 338
        },
        {
          "text": "whereas those who are bent on denying the truth will not cease to be in doubt about Him until the Last Hour comes suddenly upon them and [supreme] suffering befalls them on a Day void of all hope.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 55,
          "number": 2650,
          "juz": 17,
          "numpage": 338
        }
      ]
    },
    {
      "337": [
        {
          "text": "PERMISSION [to fight] is given to those against whom war is being wrongfully waged and, verily, God has indeed the power to succour them -:",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 39,
          "number": 2634,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "those who have been driven from their homelands against all right for no other reason than their saying. “Our Sustainer is God!” For, if God had not enabled people to defend themselves against one another, all] monasteries and churches and synagogues and mosques - in [all of] which Gods name is abundantly extolled - would surely have been destroyed [ere now]. And God will most certainly succour him who suc­cours His cause: for, verily, God is most powerful, almighty,",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 40,
          "number": 2635,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "[well aware of] those who, [even] if We firmly establish them on earth, remain constant in prayer, and give in charity, and enjoin the doing of what is right and forbid the doing of what is wrong; but with God rests the final outcome of all events.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 41,
          "number": 2636,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "AND IF THEY [who are bent on denying the truth] give thee the lie, [O Muhammad, remember that, long] before their time, the people of Noah and [the tribes of] Ad and Thamud gave the lie [to their prophets],",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 42,
          "number": 2637,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "as did the people of Abraham, and the people of Lot,",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 43,
          "number": 2638,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "and the dwellers of Madyan; and [so, too,] Moses was given the lie [by Pharaoh]. And [in every ease] I gave rein, for a while, to the deniers of the truth: but then I took them to task - and how awesome was My denial [of them,]!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 44,
          "number": 2639,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "And how many a township have We destroyed because it had been immersed in evildoing - and now they [all] lie deserted, with their roofs caved in! And how many a well lies abandoned, and how many a castle that [once] stood high!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 45,
          "number": 2640,
          "juz": 17,
          "numpage": 337
        },
        {
          "text": "Have they, then, never journeyed about the earth, letting their hearts gain wisdom, and causing their ears to hear? Yet, verily, it is not their eyes that have become blind - but blind have become the hearts that are in their breasts!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 46,
          "number": 2641,
          "juz": 17,
          "numpage": 337
        }
      ]
    },
    {
      "336": [
        {
          "text": "[inclining] towards God, [and] turning away from all that is false, without ascribing divine qualities to aught beside Him: for he who ascribes divinity to aught but God is like one who is hurtling down from the skies - whereupon the birds carry him off, or the wind blows him away onto a far-off place.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 31,
          "number": 2626,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "This is [to be borne in mind]. And anyone who honours the symbols set up by God [shall know that] verily, these [symbols derive their value] from the God-consciousness in the [believers’] hearts.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 32,
          "number": 2627,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "In that [God-consciousness] you shall find benefits until a term set [by Him is fulfilled], and [you shall know that] its goal and end is the Most Ancient Temple.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 33,
          "number": 2628,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "And [thus it is:] unto every community [that has ever believed in Us] have We appointed [sacrifice as] an act of worship, so that they might extol the name of God over whatever heads of cattle He may have provided for them [to this end]. And (always bear in mind:) your God is the One and Only God: hence, surrender yourselves unto Him. And give thou the glad tiding [of God’s acceptance] unto all who are humble –",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 34,
          "number": 2629,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "all whose hearts tremble with awe whenever God is mentioned, and all who patiently bear whatever ill befalls them, and all who are constant in prayer and spend on others out of what We provide for them as sustenance.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 35,
          "number": 2630,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "And as for the sacrifice of cattle, We have ordained it for you as one of the symbols set up by God, in which there is [much] good for you. Hence, extol the name of God over them when they are lined up [for sacrifice]; and after they have fallen lifeless to the ground, eat of their flesh, and feed the poor who is contented with his lot (and does not beg), as well as him who is forced to beg. It is to this end that We have made them subservient to your needs, so that you might have cause to be grateful.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 36,
          "number": 2631,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "[But bear in mind:] never does their flesh reach God, and neither their blood: it is only your God-consciousness that reaches Him. It is to this end that We have made them subservient to your needs, so that you might glorify God for all the guidance with which He has graced you. And give thou this glad tiding unto the doers of good:",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 37,
          "number": 2632,
          "juz": 17,
          "numpage": 336
        },
        {
          "text": "Verily, God will ward off [all evil] from those who attain to faith; [and,] verily, God does not love anyone who betrays his trust and is bereft of gratitude.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 38,
          "number": 2633,
          "juz": 17,
          "numpage": 336
        }
      ]
    },
    {
      "335": [
        {
          "text": "for they were [willing to be] guided towards the best of all tenets, and so they were guided onto the way that leads to the One unto whom all praise is due.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 24,
          "number": 2619,
          "juz": 17,
          "numpage": 335
        },
        {
          "text": "BEHOLD, as for those who are bent on denying the truth and bar [others] from the path of God and from the Inviolable House of Worship which We have set up for all people alike - [both] those who dwell there and those who come from abroad - and all who seek to profane it by [deliberate] evildoing: [all] such shall We cause to taste grievous suffering in the life to come.]",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 25,
          "number": 2620,
          "juz": 17,
          "numpage": 335
        },
        {
          "text": "For, when We assigned unto Abraham the site of this Temple, [We said unto him:] “Do not ascribe divinity to aught beside Me!” and: “Purify My Temple for those who will walk around it, and those who will stand before it [in meditation], and those who will bow down and prostrate themselves [in prayer].”",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 26,
          "number": 2621,
          "juz": 17,
          "numpage": 335
        },
        {
          "text": "Hence, [O Muhammad,] proclaim thou unto all people the [duty of] pilgrimage: they will come unto thee on foot and on every [kind of] fast mount, coming from every far-away point [on earth],",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 27,
          "number": 2622,
          "juz": 17,
          "numpage": 335
        },
        {
          "text": "so that they might experience much that shall be of benefit to them, and that they might extol the name of God on the days appointed [for sacrifice], over whatever heads of cattle He may have provided for them [to this end]: eat, then, thereof, and feed the unfortunate poor.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 28,
          "number": 2623,
          "juz": 17,
          "numpage": 335
        },
        {
          "text": "Thereafter let them bring to an end their state of self-denial, and let them fulfill the vows which they [may] have made, and let them walk [once again] around the Most Ancient Temple.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 29,
          "number": 2624,
          "juz": 17,
          "numpage": 335
        },
        {
          "text": "All this [is ordained by God]; and if one houours God’s sacred commandments, it will redound to his own good in his Sustainer’s sight. And all [kinds of] cattle have been made lawful to you [for sacrifice and food], save what is mentioned to you [as forbidden]. Shun, then, [all that God has forbidden and, most of all,] the loathsome evil of idolatrous beliefs and practices; and shun every word that is untrue,",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 30,
          "number": 2625,
          "juz": 17,
          "numpage": 335
        }
      ]
    },
    {
      "334": [
        {
          "text": "And thus have We bestowed from on high this [divine writ] in the shape of clear messages: for [thus it is] that God guides him who wills [to be guided].",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 16,
          "number": 2611,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "Verily, as for those who have attained to faith [in this divine writ], and those who follow the Jewish faith, and the Sabians, and the Christians, and the Magians, [on the one hand,] and those who are bent on ascribing divinity to aught but God, [on the other,] verily, God will decide between them on Resurrection Day: for, behold, God is witness unto everything.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 17,
          "number": 2612,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "ART THOU NOT aware that before God prostrate themselves all [things and beings] that are in the heavens and all that are on earth the sun, and the moon, and the stars, and the mountains, and the trees, and the beasts? And many human beings [submit to God con­sciously], whereas many [others, having defied Him,] will inevitably have to suffer [in the life to come]; and he whom God shall scorn [on Resur­rection Day] will have none who could bestow honour on him: for, verily, God does what He wills.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 18,
          "number": 2613,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "These two contrary kinds of man have become engrossed in contention about their Sus­tainer! But [thus it is:] as for those who are bent on denying the truth garments of fire shall be cut out for them [in the life to come]; burning despair will be poured over their heads,",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 19,
          "number": 2614,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "causing all that is within their bodies, as well as the skins, to melt away.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 20,
          "number": 2615,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "And they shall be held [in this state as if] by iron grips;",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 21,
          "number": 2616,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "and every time they try in their anguish to come out of it, they shall be returned thereto and [be told]: “Taste suffering through fire [to the full]!”",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 22,
          "number": 2617,
          "juz": 17,
          "numpage": 334
        },
        {
          "text": "[As against this,] behold, God will admit those who attain to faith and do righteous deeds into gar­dens through which running waters flow, wherein they will be adorned with bracelets of gold and pearls, and where silk will be their raiment:",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 23,
          "number": 2618,
          "juz": 17,
          "numpage": 334
        }
      ]
    },
    {
      "333": [
        {
          "text": "All this [happens] because God alone is the Ultimate Truth, and because He alone brings the dead to life, and because He has the power to will anything.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 6,
          "number": 2601,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "And [know, O man] that the Last Hour is bound to come, beyond any doubt, and that God will [in­deed] resurrect all who are in their graves.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 7,
          "number": 2602,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "And yet, among men there is many a one that argues about God without having any knowledge [of Him], without any guidance, and without any light-giving revelation –",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 8,
          "number": 2603,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "scornfully turning aside [from the truth] so as to lead [others] astray from the path of God. Disgrace [of the spirit] is in store for him in this world; and on the Day of Resurrection We shall make him taste suffering through fire;",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 9,
          "number": 2604,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "[and he shall be told:] “This is an outcome of what thine own hands have wrought - for, never does God do the least wrong to His creatures!”",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 10,
          "number": 2605,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "And there is, too, among men many a one who worships God on the border-line [of faith]: thus, if good befalls him, he is satisfied with Him; but if a trial assails him, he turns away utterly, losing [thereby both] this world and the life to come: [and] this, indeed, is a loss beyond compare!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 11,
          "number": 2606,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "[By behaving thus,] he invokes, instead of God, something that can neither harm nor benefit him: [and] this is indeed the utmost one can go astray.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 12,
          "number": 2607,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "[And sometimes] he invokes [another human being] one that is far more likely to cause harm than benefit: vile, indeed, is such a patron and vile the follower!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 13,
          "number": 2608,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "VERILY, God will admit those who have attained to faith and have done righteous deeds into gardens through which running waters flow: for, behold, God does whatever He wills.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 14,
          "number": 2609,
          "juz": 17,
          "numpage": 333
        },
        {
          "text": "If anyone thinks that God will not succour him in this world and in the life to come, let him reach out unto heaven by any [other] means and [thus try to] make headway: and then let him see whether this scheme of his will indeed do away with the cause of his anguish.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 15,
          "number": 2610,
          "juz": 17,
          "numpage": 333
        }
      ]
    },
    {
      "332": [
        {
          "text": "O men! Be conscious of your Sustainer: for, verily the violent convulsion of the Last Hour will be an awesome thing!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 1,
          "number": 2596,
          "juz": 17,
          "numpage": 332
        },
        {
          "text": "On the Day when you behold it, every woman that feeds a child at her breast will utterly forget her nursling, and every woman heavy with child will bring forth her burden [before her time]; and it will seem to thee that all mankind is drunk, although they will not be drunk - but vehement will be [their dread of] God’s chastisement.",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 2,
          "number": 2597,
          "juz": 17,
          "numpage": 332
        },
        {
          "text": "And yet, among men there is many a one who argues about God without having any knowledge [of Him], and follows every rebellious satanic force",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 3,
          "number": 2598,
          "juz": 17,
          "numpage": 332
        },
        {
          "text": "about which it has been decreed that whoever entrusts himself to it, him will it lead astray and guide towards the suffering of the blazing flame!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 4,
          "number": 2599,
          "juz": 17,
          "numpage": 332
        },
        {
          "text": "O MEN! If you are in doubt as to the [truth of] resurrection, [remember that,] verily, We have created [every one of] you out of dust, then out of a drop of sperm, then out of a germ-cell, then out of an embryonic lump complete [in itself] and yet incom­plete so that We might make [your origin] clear unto you. And whatever We will [to be born] We cause to rest in the [mothers’] wombs for a term set [by Us], and then We bring you forth as infants and [allow you to live] so that [some of] you might attain to maturity: for among you are such as are caused to die [in childhood], just as many a one of you is reduced in old age to a most abject state, ceasing to know anything of what he once knew so well. And [if, O man, thou art still in doubt as to resur­rection, consider this:] thou canst see the earth dry and lifeless - and [suddenly,] when We send down waters upon it, it stirs and swells and puts forth every kind of lovely plant!",
          "englishName": "Al-Hajj",
          "suraNumber": 22,
          "numberInSurah": 5,
          "number": 2600,
          "juz": 17,
          "numpage": 332
        }
      ]
    },
    {
      "331": [
        {
          "text": "no sound thereof will they hear; and they will abide in all that their souls have ever desired.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 102,
          "number": 2585,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "The supreme awesomeness [of the Day of Resurrection] will cause them no grief, since the angels will receive them with the greeting. “This is your Day [of triumph - the Day] which you were promised!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 103,
          "number": 2586,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "On that Day We shall roll up the skies as written scrolls are rolled up; [and] as We brought into being the first creation, so We shall bring it forth anew a promise which We have willed upon Our­selves: for, behold, We are able to do [all things]!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 104,
          "number": 2587,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "AND, INDEED, after having exhorted [man], We laid it down in all the books of divine wisdom that My righteous servants shall inherit the earth:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 105,
          "number": 2588,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "herein, behold, there is a message for people who [truly] worship God.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 106,
          "number": 2589,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "And [thus, O Prophet,] We have sent thee as [an evidence of Our] grace towards all the worlds.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 107,
          "number": 2590,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "Say: “It has but been revealed unto me that your God is the One and Only God: will you, then, surrender yourselves unto Him?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 108,
          "number": 2591,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "But if they turn away, say: “I have proclaimed this in equity unto all of you alike; but I do not know whether that [judgment] which you are promised [by God] is near or far [in time].",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 109,
          "number": 2592,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "“Verily, He knows all that is said openly, just as He [alone] knows all that you would conceal.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 110,
          "number": 2593,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "But [as for me,] I do not know whether, perchance, this [delay in God’s judgment] is but a trial for you, and a [merciful] respite for a while.”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 111,
          "number": 2594,
          "juz": 17,
          "numpage": 331
        },
        {
          "text": "Say: “O my Sustainer! Judge Thou in truth!” - and [say]: “Our Sustainer is the Most Gracious, the One whose aid is ever to be sought against all your [attempts at] defining [Him]!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 112,
          "number": 2595,
          "juz": 17,
          "numpage": 331
        }
      ]
    },
    {
      "330": [
        {
          "text": "AND [remember] her who guarded her chastity, whereupon We breathed into her of Our spirit and caused her, together with her son, to become a symbol [of Our grace] unto all people.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 91,
          "number": 2574,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "VERILY, [O you who believe in Me,] this community of yours is one single community, since I am the Sustainer of you all: worship, then, Me [alone]!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 92,
          "number": 2575,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "But men have torn their unity wide asunder, [forgetting that] unto Us they all are bound to return.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 93,
          "number": 2576,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "And yet, whoever does [the least] of righteous deeds and is a believer withal, his endeavour shall not be disowned: for, behold, We shall record it in his favour.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 94,
          "number": 2577,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "Hence, it has been unfailingly true of any community whom We have ever destroyed that they [were people who] would never turn back [from their sinful ways]",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 95,
          "number": 2578,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "until such a time as Gog and Magog are let loose [upon the world] and swarm down from every corner [of the earth],",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 96,
          "number": 2579,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "the while the true promise [of resurrection] draws close [to its fulfillment]. But then, lo! the eyes of those who [in their lifetime] were bent on denying the truth will stare in horror, [and they will exclaim:] “Oh, woe unto us! We were indeed heedless of this [promise of resur­rection]! - nay, we were [bent on] doing evil!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 97,
          "number": 2580,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "[Then they will be told:] “Verily, you and all that you [were wont to] worship instead of God are but the fuel of hell: that is what you are destined for.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 98,
          "number": 2581,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "If those [false objects of your worship] had truly been divine, they would not have been destined for it: but [as it is, you] all shall abide therein!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 99,
          "number": 2582,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "Moaning will be their lot therein, and nothing [else] will they hear therein.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 100,
          "number": 2583,
          "juz": 17,
          "numpage": 330
        },
        {
          "text": "[But,] behold, as for those for whom [the decree of] ultimate good has already gone forth from Us these will be kept far away from that [hell]:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 101,
          "number": 2584,
          "juz": 17,
          "numpage": 330
        }
      ]
    },
    {
      "329": [
        {
          "text": "And among the rebel­lious forces [which We made subservient to him] there were some that dived for him [into the sea] and performed other works, besides: but it was We who kept watch over them.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 82,
          "number": 2565,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "AND [remember] Job, when he cried out to his Sus­tainer, “Affliction has befallen me: but Thou art the most merciful of the merciful!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 83,
          "number": 2566,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "whereupon We responded unto him and removed all the affliction from which he suffered; and We gave him new offspring, doubling their number as an act of grace from Us, and as a reminder unto all who worship Us.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 84,
          "number": 2567,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "AND [remember] Ishmael and Idris and every one who [like them] has pledged himself [unto God]: they all were among those who are patient in adver­sity,",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 85,
          "number": 2568,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "and so We admitted them unto Our grace: behold, they were among the righteous!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 86,
          "number": 2569,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "AND [remember] him of the great fish when he went off in wrath, thinking that We had no power over him! But then heeded out in the deep darkness [of his distress]: “There is no deity save Thee! Limit­less art Thou in Thy glory! Verily, I have done wrong!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 87,
          "number": 2570,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "And so We responded unto him and delivered him from [his] distress: for thus do We deliver all who have faith.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 88,
          "number": 2571,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "AND [thus did We deliver] Zachariah when he cried out unto his Sustainer: “O my Sustainer! Leave me not childless! But [even if Thou grant me no bodily heir, I know that] Thou wilt remain when all else has ceased to be!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 89,
          "number": 2572,
          "juz": 17,
          "numpage": 329
        },
        {
          "text": "And so We responded unto him, and bestowed upon him the gift of John, having made his wife fit to bear him a child: [and,] verily, these [three] would vie with one another in doing good works, and would call unto Us in yearning and awe; and they were always humble before Us.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 90,
          "number": 2573,
          "juz": 17,
          "numpage": 329
        }
      ]
    },
    {
      "328": [
        {
          "text": "and made them leaders who would guide [others] in accordance with Our behest: for We inspired them [with a will] to do good works, and to be constant in prayer, and to dispense charity: and Us [alone] did they worship.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 73,
          "number": 2556,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "AND UNTO Lot, too, We vouchsafed sound judg­ment and knowledge [of right and wrong], and saved him from that community which was given to deeds of abomination. [We destroyed those people - forr,] verily, they were people lost in evil, depraved –",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 74,
          "number": 2557,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "whereas him We admitted unto Our grace: for, behold, he was among the righteous.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 75,
          "number": 2558,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "AND [remember] Noah - [how,] when He called out [unto Us], long before [the time of Abraham and Lot], We responded to him and saved him and his house­hold from that awesome calamity;",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 76,
          "number": 2559,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "and [how] We succoured him against the people who had given the lie to Our messages: verily, they were people lost in evil - and [so] We caused them all to drown.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 77,
          "number": 2560,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "AND [remember] David and Solomon - [how it was] when both of them gave judgment concerning the field into which some people’s sheep had strayed by night and pastured therein, and [how] We bore witness to their judgment:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 78,
          "number": 2561,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "for, [though] We made Solomon understand the case [more profoundly] yet We vouchsafed unto both of them sound judgment and knowledge [of right and wrong]. And We caused the mountains to join David in extolling Our limitless glory, and likewise the birds: for We are able to do [all things].",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 79,
          "number": 2562,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "And We taught him how to make garments [of God- consciousness] for you, [O men,] so that they might fortify you against all that may cause you fear: but are you grateful [for this boon]?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 80,
          "number": 2563,
          "juz": 17,
          "numpage": 328
        },
        {
          "text": "And unto Solomon [We made subservient] the stormy wind, so that it sped at his behest towards the land which We had blessed: for it is We who have knowledge of everything.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 81,
          "number": 2564,
          "juz": 17,
          "numpage": 328
        }
      ]
    },
    {
      "327": [
        {
          "text": "And then he broke those [idols] to pieces, [all] save the biggest of them, so that they might [be able to] turn to it.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 58,
          "number": 2541,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "[When they saw what had happened,] they said: “Who has done this to our gods? Verily, one of the worst wrongdoers is he!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 59,
          "number": 2542,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "Said some [of them]: “We heard a youth speak of these [gods with scorn]: he is called Abraham.”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 60,
          "number": 2543,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "[The others] said: “Then bring him before the peoples eyes, so that they might bear witness [against him]!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 61,
          "number": 2544,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "[And when he came.] they asked: “Hast thou done this to our gods, O Abraham?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 62,
          "number": 2545,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "He answered: “Nay, it was this one, the big­gest of them, that did it: but ask them [yourselves] - provided they can speak!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 63,
          "number": 2546,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "And so they turned upon one another, saying, “Behold, it is you who are doing wrong.”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 64,
          "number": 2547,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "But then they relapsed into their former way of thinking and said: “Thou knowest very well that these [idols] cannot speak!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 65,
          "number": 2548,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "Said [Abraham]: “Do you then worship, instead of God, something that cannot benefit you in any way, nor harm you?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 66,
          "number": 2549,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "Fie upon you and upon all that you worship instead of God! Will you not, then, use your reason?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 67,
          "number": 2550,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "They exclaimed: “Burn him, and [thereby] succour your gods, if you are going to do [anything]!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 68,
          "number": 2551,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "[But] We said: O fire! Be thou cool, and [a source of] inner peace for Abraham!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 69,
          "number": 2552,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "and whereas they sought to do evil unto him, We caused them to suffer the greatest loss:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 70,
          "number": 2553,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "for We saved him and Lot, [his brother’s son, by guiding them] to the land which We have blessed for all times to come.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 71,
          "number": 2554,
          "juz": 17,
          "numpage": 327
        },
        {
          "text": "And We bestowed upon him Isaac and [Isaacs son] Jacob as an additional gift and caused all of them to be righteous men,",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 72,
          "number": 2555,
          "juz": 17,
          "numpage": 327
        }
      ]
    },
    {
      "326": [
        {
          "text": "SAY [unto all men]: “I but warn you on the strength of divine revelation!” But the deaf [of heart] will not hearken to this call, however often they are warned.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 45,
          "number": 2528,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "And yet, if but a breath of thy Sustainer’s chastisement touches them, they are sure to cry, “Oh, woe unto us! Verily, we were evildoers!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 46,
          "number": 2529,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "But We shall set up just balance-scales on Resurrection Day, and no human being shall be wrong­ed in the least: for though there be [in him but] the weight of a mustard-seed [of good or evil], We shall bring it forth; and none can take count as We do!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 47,
          "number": 2530,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "AND, INDEED, We vouchsafed unto Moses and Aaron [Our revelation as] the standard by which to discern the true from the false, and as a [guiding] light and a reminder for the God-conscious",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 48,
          "number": 2531,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "who stand in awe of their Sustainer although He is beyond the reach of human perception, and who tremble at the thought of the Last Hour.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 49,
          "number": 2532,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "And [like those earlier revelations,] this one, too, is a blessed reminder which We have bestowed from on high: will you, then, disavow it?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 50,
          "number": 2533,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "AND, INDEED, long before [the time of Moses] We vouchsafed unto Abraham his consciousness of what is right; and We were aware of [what moved] him",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 51,
          "number": 2534,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "when he said unto his father and his people, “What are these images to which you are so intensely devoted?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 52,
          "number": 2535,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "They answered: “We found our forefathers worshipping them.”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 53,
          "number": 2536,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "Said he: “Indeed, you and your forefathers have obviously gone astray!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 54,
          "number": 2537,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "They asked: “Hast thou come unto us [with this claim] in all earnest - or art thou one of those jesters?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 55,
          "number": 2538,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "He answered: “Nay, but your [true] Sustainer is the Sustainer of the heavens and the earth - He who has brought them into being: and I am one of those who bear witness to this [truth]!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 56,
          "number": 2539,
          "juz": 17,
          "numpage": 326
        },
        {
          "text": "And [he added to himself.] “By God, I shall most certainly bring about the downfall of your idols as soon as you have turned your backs and gone away!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 57,
          "number": 2540,
          "juz": 17,
          "numpage": 326
        }
      ]
    },
    {
      "325": [
        {
          "text": "But [thus it is:] whenever they who are bent on denying the truth consider thee, they make thee but a target of their mockery, [saying to one another,] “Is this the one who speaks [so contemptuously] of your gods?” and yet, it is they themselves who, at [every] mention of the Most Gracious, are wont to deny the truth!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 36,
          "number": 2519,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "Man is a creature of haste; [but in time] I shall make obvious to you [the truth of] My mes­sages: do not, then, ask Me to hasten [it]!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 37,
          "number": 2520,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "But they [who reject My messages are wont to] ask, “When is that promise [of God’s judgment] to be fulfilled? [Answer this, O you who believe in it,] if you are men of truth!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 38,
          "number": 2521,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "If they but knew - they who are bent on deny­ing the truth - [that there will come] a time when they will not be able to ward off the fire from their faces, nor from their backs, and will not find any succour!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 39,
          "number": 2522,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "Nay, but [the Last Hour] will come upon them of a sudden, and will stupefy them: and they will be unable to avert it, and neither wilt they be allowed any respite.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 40,
          "number": 2523,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "And, indeed, [O Muhammad, even] before thy time have [God’s] apostles been derided - but those who scoffed at them were [in the end] overwhelmed by the very thing which they had been wont to deride.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 41,
          "number": 2524,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "Say: “Who could protect you, by night or by day, from the Most Gracious?” And yet, from a remembrance of their Sustainer do they stubbornly turn away!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 42,
          "number": 2525,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "Do they [really think that they] have deities that could shield them from Us? Those [alleged dei­ties] are not [even] able to succour themselves: hence, neither can they [who worship them hope to] be aided [by them] against Us.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 43,
          "number": 2526,
          "juz": 17,
          "numpage": 325
        },
        {
          "text": "Nay, We have allowed these [sinners] – as [We allowed] their forebears – to enjoy the good things of life for a great length of time: but then – have they never yet seen how We visit the earth [with Our punishment], gradually depriving it of all that is best thereon? Can they, then, [hope to] be the winners?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 44,
          "number": 2527,
          "juz": 17,
          "numpage": 325
        }
      ]
    },
    {
      "324": [
        {
          "text": "and [this despite the fact that even] before thy time We never sent any apostle without having revealed to him that there is no deity save Me, - [and that,] therefore, you shall worship Me [alone]!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 25,
          "number": 2508,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "And [yet,] some say, “The Most Gracious has taken unto Himself a son”! Limitless is He in His glory! Nay, [those whom they regard as God’s “offspring” are but His] honoured servants:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 26,
          "number": 2509,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "they speak not until He has spoken unto them, and [whenever they act,] they act at His behest.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 27,
          "number": 2510,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "He knows all that lies open before them and all that is hidden from them: hence, they cannot intercede for any but those whom He has [already] graced with His goodly acceptance, since they themselves stand in reverent awe of Him.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 28,
          "number": 2511,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "And if any of them were to say, “Behold, I am deity beside Him” - that one We should requite with hell: thus do We requite all [such] evildoers.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 29,
          "number": 2512,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "ARE, THEN, they who are bent on denying the truth not aware that the heavens and the earth were [once] one single entity, which We then parted asunder? – and [that] We made out of water every living thing? Will they not, then, [begin to] believe?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 30,
          "number": 2513,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "And [are they not aware that] We have set up firm mountains on earth, lest it sway with them, and [that] We have appointed thereon broad paths, so that they might find their way,",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 31,
          "number": 2514,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "and [that] We have set up the sky as a canopy well-secured? And yet, they stubbornly turn away from [all] the signs of this [creation],",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 32,
          "number": 2515,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "and [fail to see that] it is He who has created the night and the day and the sun and the moon - all of them floating through space!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 33,
          "number": 2516,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "AND [remind those who deny thee, O Prophet, that] never have We granted life everlasting to any mortal before thee: but do they, perchance, hope that al­though thou must die, they will live forever?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 34,
          "number": 2517,
          "juz": 17,
          "numpage": 324
        },
        {
          "text": "Every human being is bound to taste death; and We test you [all] through the bad and the good [things of life] by way of trial: and unto Us you all must return.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 35,
          "number": 2518,
          "juz": 17,
          "numpage": 324
        }
      ]
    },
    {
      "323": [
        {
          "text": "For, how many a community that persisted in evildoing have We dashed into fragments, and raised another people in its stead!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 11,
          "number": 2494,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "And [every time,] as soon as they began to feel Our punishing might, lo! they tried to flee from it",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 12,
          "number": 2495,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "[and at the same time they seemed to hear a scornful voice]: “Do not try to flee, but return to all that [once] gave you pleasure and corrupted your whole being. and [return] to your homes, so that you might be called to account [for what you have done]!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 13,
          "number": 2496,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "And they could only cry: “Oh, woe unto us! Verily, we were wrongdoers!”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 14,
          "number": 2497,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "And that cry of theirs did not cease until We caused them to become [like] a field mown down, still and silent as ashes.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 15,
          "number": 2498,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "AND [know that] We have not created the heavens and the earth and all that is between them in mere idle play:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 16,
          "number": 2499,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "[for,] had We willed to indulge in a pastime, We would indeed have produced it from within Ourselves - if such had been Our will at all!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 17,
          "number": 2500,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "Nay, but [by the very act of creation] We hurl the truth against falsehood, and it crushes the latter: and lo! it withers away. But woe unto you for all your [attempts at] defining [God] –",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 18,
          "number": 2501,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "for, unto Him belong all [beings] that are in the heavens and on earth; and those that are with Him are never too proud to worship Him and never grow weary [thereof]:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 19,
          "number": 2502,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "they extol His lim­itless glory by night and by day, never flagging [therein].",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 20,
          "number": 2503,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "And yet some people choose to worship certain earthly things or beings as deities that [are supposed to] resurrect [the dead; and they fail to realize that],",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 21,
          "number": 2504,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "had there been in heaven or on earth any deities other than God, both [those realms would surely have fallen into ruin! But limitless in His glory is God, enthroned in His awesome almightiness far] above anything that men may devise by way of definition!",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 22,
          "number": 2505,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "He cannot be called to account for whatever He does, whereas they will be called to account:",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 23,
          "number": 2506,
          "juz": 17,
          "numpage": 323
        },
        {
          "text": "and yet, they choose to worship [imaginary] deities instead of Him! Say [O Prophet]: “Produce an evidence for what you are claiming: this is a reminder [unceasingly voiced] by those who are with me, just as it was a reminder [voiced] by those who came before me.” But nay, most of them do not know the truth, and so they stubbornly turn away [from it]",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 24,
          "number": 2507,
          "juz": 17,
          "numpage": 323
        }
      ]
    },
    {
      "322": [
        {
          "text": "CLOSER DRAWS unto men their reckoning: and yet they remain stubbornly heedless [of its approach].",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 1,
          "number": 2484,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "Whenever there comes unto them any new re­minder from their Sustainer, they but listen to it with playful amusement,",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 2,
          "number": 2485,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "their hearts set on passing ­delights; yet they who are [thus] bent on wrongdoing conceal their innermost thoughts [when they say to one another], “Is this [Muhammad] anything but a mortal like yourselves? Will you, then, yield to [his] spellbinding eloquence with your eyes open?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 3,
          "number": 2486,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "Say: “My Sustainer knows whatever is spoken in heaven and on earth; and He alone is all-hearing, all-knowing.”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 4,
          "number": 2487,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "“Nay,” they say, “[Muhammad propounds] the most involved and confusing of dreams!” “Nay, but he has invented [all] this!” - “Nay, but he is [only] a poet!” - [and,] “Let him, then, come unto us with a miracle, just as those [prophets] of old were sent [with miracles]?”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 5,
          "number": 2488,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "Not one of the communities that We destroyed in bygone times would ever believe [their prophets]: will these, then, [be more willing to] believe?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 6,
          "number": 2489,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "For [even] before thy time, [O Muhammad,] We never sent [as Our apostles] any but [mortal] men, whom We inspired - hence, [tell the deniers of the truth,] “If you do not know this, ask the followers of earlier revelation”",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 7,
          "number": 2490,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "and neither did We endow them with bodies that could dispense with food nor were they immortal.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 8,
          "number": 2491,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "In the end, We made good unto them Our promise, and We saved them and all whom We willed [to save], and We destroyed those who had wasted their own selves.",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 9,
          "number": 2492,
          "juz": 17,
          "numpage": 322
        },
        {
          "text": "[O MEN!] We have now bestowed upon you from on high a divine writ containing all that you ought to bear in mind will you not, then, use your reason?",
          "englishName": "Al-Anbiyaa",
          "suraNumber": 21,
          "numberInSurah": 10,
          "number": 2493,
          "juz": 17,
          "numpage": 322
        }
      ]
    },
    {
      "321": [
        {
          "text": "[God] will reply: \"Thus it is: there came unto thee Our messages, but thou wert oblivious of them; and thus shalt thou be today consigned to oblivion!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 126,
          "number": 2474,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "For, thus shall We recompense him who wastes his own self and does not believe in his Sustainer's messages: and, indeed, the suffering [of such sinners] in the life to come shall be most severe and most enduring!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 127,
          "number": 2475,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "CAN, THEN, they [who reject the truth] learn no lesson by recalling how many a generation We have destroyed before their time? - [people] in whose dwelling-places they [themselves now] walk about? In this, behold, there are messages indeed for those who are endowed with reason!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 128,
          "number": 2476,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "Now were it not for a decree that has already gone forth from thy Sustainer, setting a term [for each sinner's repentance], it would inescapably follow [that all who sin must be doomed at once].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 129,
          "number": 2477,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "Hence, bear with patience whatever they [who deny the truth] may say, and extol thy Sustainer's limitless glory and praise Him before the rising of the sun and before its setting; and extol His glory, too, during some of the hours of the night as well as during the hours of the day, so that thou might attain to happiness.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 130,
          "number": 2478,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "And never turn thine, eyes [with longing] towards whatever splendour of this world's life We may have allowed so many others to enjoy in order that We might test them thereby: for the sustenance which thy Sustainer provides [for thee] is better and more enduring.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 131,
          "number": 2479,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "And bid thy people to pray, and persevere therein. [But remember:] We do not ask thee to provide sustenance [for Us]: it is We who provide sustenance for thee. And the future belongs to the God-conscious.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 132,
          "number": 2480,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "NOW THEY [who are blind to the truth] are wont to say, \"If [Muhammad] would but produce for us a miracle from his Sustainer! [But] has there not come unto them a clear evidence [of the truth of this divine writ] in what is [to be found] in the earlier scriptures?",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 133,
          "number": 2481,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "For [thus it is:] had We destroyed them by means of a chastisement ere this [divine writ was revealed], they would indeed [be justified to] say [on Judgment Day]: \"O our Sustainer! If only Thou hadst sent an apostle unto us, we would have followed Thy messages rather than be humiliated and disgraced [in the hereafter]!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 134,
          "number": 2482,
          "juz": 16,
          "numpage": 321
        },
        {
          "text": "Say: \"Everyone is hopefully waiting [for what the future may bring]: wait, then, [for the Day of Judgment] for then you will come to know as to who has followed the even path, and who has found guidance!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 135,
          "number": 2483,
          "juz": 16,
          "numpage": 321
        }
      ]
    },
    {
      "320": [
        {
          "text": "[Know,] then, [that) God is sublimely exalted. the Ultimate Sovereign, the Ultimate Truth and [knowing this,] do not approach the Qur'an in haste, ere it has been revealed unto thee in full, but [always) say: \"O my Sustainer, cause me to grow in knowledge!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 114,
          "number": 2462,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "AND, INDEED, long ago did We impose Our commandment on Adam; but he forgot it, and We found no firmness of purpose in him.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 115,
          "number": 2463,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "For [thus it was:] when We told the angels, \"Prostrate yourselves before Adam!\"- they all prostrated themselves, save Ibis, who refused [to do it];",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 116,
          "number": 2464,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "and thereupon We said: \"O Adam! Verily, this is a foe unto thee and thy wife: so let him not drive the two of you out of this garden and render thee unhappy.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 117,
          "number": 2465,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "Behold, it is provided for thee that thou shalt not hunger here or feel naked,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 118,
          "number": 2466,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "and that thou shalt not thirst here or suffer from the heat of the sun.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 119,
          "number": 2467,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "But Satan whispered unto him, saying: \"O Adam! Shall I lead thee to the tree of life eternal; and [thus] to a kingdom that will never decay?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 120,
          "number": 2468,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "And so the two ate [of the fruit] thereof: and thereupon they became conscious of their nakedness and began to cover themselves with pieced-together leaves from the garden. And [thus] did Adam disobey his Sustainer, and thus did he fall into grievous error.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 121,
          "number": 2469,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "Thereafter, [however,) his Sustainer elected him [for His grace], and accepted his repentance, and bestowed His guidance upon him,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 122,
          "number": 2470,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "saying: \"Down with you all from this [state of innocence, and be henceforth] enemies unto one another! None the less, there shall most certainly come unto you guidance from Me: and he who follows My guidance will not go astray, and neither will he be unhappy.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 123,
          "number": 2471,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "But as for him who shall turn away from remembering Me - his shall be a life of narrow scope and on the Day of Resurrection We shall raise him up blind.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 124,
          "number": 2472,
          "juz": 16,
          "numpage": 320
        },
        {
          "text": "[And so, on Resurrection Day, the sinner] will ask: \"O my Sustainer! Why hast Thou raised me up blind, whereas [on earth] I was endowed with sight?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 125,
          "number": 2473,
          "juz": 16,
          "numpage": 320
        }
      ]
    },
    {
      "319": [
        {
          "text": "THUS DO WE relate unto thee some of the stories of what happened in the past; and [thus] have We vouchsafed unto thee, out of Our grace, a reminder.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 99,
          "number": 2447,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "All who shall turn away from it will, verily, bear a [heavy] burden on the Day of Resurrection:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 100,
          "number": 2448,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "they will abide in this [state], and grievous for them will be the weight [of that burden] on the Day of Resurrection -",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 101,
          "number": 2449,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "on the Day when the trumpet is blown: for on that Day We will assemble all such as had been lost in sin, their eyes dimmed [by terror],",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 102,
          "number": 2450,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "whispering unto one another, \"You have spent but ten [days on earth]",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 103,
          "number": 2451,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "[But] We know best what they will be saying when the most perceptive of them shall say, \"You have spent [there] but one day!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 104,
          "number": 2452,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "AND THEY WILL ask thee about [what will happen to] the mountains [when this world comes to an end]. Say, then: \"My Sustainer will scatter them far and wide,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 105,
          "number": 2453,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "and leave the earths level and bare,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 106,
          "number": 2454,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "[so that] thou wilt see no curve thereon, and no ruggedness.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 107,
          "number": 2455,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "On that Day, all will. follow the summoning Voice from which there will be no escape; and all sounds will be hushed before the Most Gracious, and thou wilt hear nothing but a faint sough in the air.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 108,
          "number": 2456,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "On that Day, intercession shall be of no avail [to any] save him in whose case the Most Gracious will have granted leave therefor, and whose word [of faith) He will have accepted:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 109,
          "number": 2457,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "[for] He knows all that lies open before men and all that is hidden from them, whereas they cannot encompass Him with their knowledge.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 110,
          "number": 2458,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "And [on that Day] all faces will be humbled before the Ever-Living, the Self-Subsistent Fount of All Being; and undone shall be he who bears [a burden of] evildoing",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 111,
          "number": 2459,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "whereas anyone who will have done [whatever he could] of righteous deeds, and was a believer withal, need have no fear of being wronged or deprived [of aught of his merit].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 112,
          "number": 2460,
          "juz": 16,
          "numpage": 319
        },
        {
          "text": "AND THUS have We bestowed from on high this [divine writ] as a discourse in the Arabic tongue, and have given therein many facets to all manner of warnings, so that men might remain conscious of Us, or that it give rise to a new awareness in them",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 113,
          "number": 2461,
          "juz": 16,
          "numpage": 319
        }
      ]
    },
    {
      "318": [
        {
          "text": "But then, [so they told Moses, the Samaritan] had produced for them [out of the molten gold] the effigy of a calf, which made a lowing sound; and thereupon they said [to one another], \"This is your deity, and the deity of Moses-but he has forgotten [his past]",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 88,
          "number": 2436,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "Why-did they not see that [the thing] could not give them any response, and had no power to harm or to benefit them?",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 89,
          "number": 2437,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "And, indeed, even before [the return of Moses] had Aaron said unto them: \"O my people! You are but being tempted to evil by this [idol] -for, behold, your [only] Sustainer is the Most Gracious! Follow me, then, and obey my bidding!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 90,
          "number": 2438,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "[But] they answered: \"By no means shall we cease to worship it until Moses comes back to us!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 91,
          "number": 2439,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "[And now that he had come back, Moses] said: \"O Aaron! What has prevented thee, when thou didst see that they had gone astray,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 92,
          "number": 2440,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "from [abandoning them and] following me? Hast thou, then, [deliberately] disobeyed my commandment?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 93,
          "number": 2441,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "Answered [Aaron]: \"O my mother's son! Seize me not by my beard, nor by my head! Behold, I was afraid lest [on thy return] thou say, 'Thou hast caused a split among the children of Israel, and hast paid no heed to my bidding!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 94,
          "number": 2442,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "Said [Moses]: \"What, then, didst thou have in view, O Samaritan?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 95,
          "number": 2443,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "He answered: \"I have gained insight into something which they were unable to see: and so I took hold of a handful of the Apostle's teachings and cast it away: for thus has my mind prompted me [to act].\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 96,
          "number": 2444,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "Said [Moses]; \"Begone, then! And, behold, it shall be thy lot to say throughout [thy] life, `Touch me not! But, verily, [in the life to come] thou shalt be faced with a destiny from which there will be no escape! And [now] look at this deity of thine to whose worship thou hast become so devoted: we shall most certainly burn it, and then scatter [whatever remains of] it far and wide over the sea!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 97,
          "number": 2445,
          "juz": 16,
          "numpage": 318
        },
        {
          "text": "Your only deity is God - He save whom there is no deity, [and whho] embraces all things within His knowledge!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 98,
          "number": 2446,
          "juz": 16,
          "numpage": 318
        }
      ]
    },
    {
      "317": [
        {
          "text": "AND, INDEED, [a time came when] We thus inspired Moses: \"Go forth with My servants by night, and strike out for them a dry path through the sea; [and] fear not of being overtaken, and dread not [the sea].\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 77,
          "number": 2425,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "And Pharaoh pursued them with his hosts: and they were overwhelmed by the sea which was destined to overwhelm them.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 78,
          "number": 2426,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "because Pharaoh had led his people astray and had not guided [them] aright.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 79,
          "number": 2427,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "O children of Israel! [Thus] We saved you from your enemy, and [then] We made a covenant with you on the right-hand slope of Mount Sinai, and repeatedly sent down manna and quails unto you, [saying,]",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 80,
          "number": 2428,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "\"Partake of the good things which We have provided for you as sustenance, but do not transgress therein the bounds of equity lest My condemnation fall upon you: for, he upon whom My condemnation falls has indeed thrown himself into utter ruin!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 81,
          "number": 2429,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "Yet withal, behold, I forgive all sins unto any who repents and attains to faith and does righteous deeds, and thereafter keeps to the right path.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 82,
          "number": 2430,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "[AND GOD SAID:] \"Now what has caused thee, O Moses, to leave thy people behind in so great a haste?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 83,
          "number": 2431,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "He answered: \"They are treading in my footsteps while I have hastened unto Thee, O my Sustainer, so that Thou might be well-pleased [with me].\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 84,
          "number": 2432,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "Said He: \"Then [know that], verily, in thy absence We have put thy people to a test, and the Samaritan has led them astray.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 85,
          "number": 2433,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "Thereupon Moses returned to his people full of wrath and sorrow, [and] exclaimed: \"O my people! Did not your Sustainer hold out [many] a goodly promise to you? bid, then, [the fulfilment of] this promise seem to you too long in coming? Or are you, perchance, determined to see your Sustainer's condemnation fall upon you, and so you broke your promise to me?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 86,
          "number": 2434,
          "juz": 16,
          "numpage": 317
        },
        {
          "text": "They answered: \"We did not break our promise to thee of our own free will, but [this is what happened:] we were loaded with the [sinful] burdens of the [Egyptian] people's ornaments, and so we threw them [into the fire], and likewise did this Samaritan cast [his into it].\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 87,
          "number": 2435,
          "juz": 16,
          "numpage": 317
        }
      ]
    },
    {
      "316": [
        {
          "text": "Said [the sorcerers]: \"O Moses! Either thou throw (thy staff first], or we shall be the first to throw.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 65,
          "number": 2413,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "He answered: \"Nay, you throw [first].\" And lo! by virtue of their sorcery, their [magic] ropes and staffs seemed to him to be moving rapidly:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 66,
          "number": 2414,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "and in his heart Moses became apprehensive.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 67,
          "number": 2415,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "[But] We said: \"Fear not! Verily, it is thou who shalt prevail!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 68,
          "number": 2416,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "And [now] throw that [staff] which is in thy right hand -it shall swallow up all that they have wrought: [for] they have wrought only a sorcerer's artifice, and the sorcerer can never come to any good, whatever he may aim at!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 69,
          "number": 2417,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "[And so it happened and down fell the sorcerers, prostrating themselves in adoration, [and] exclaimed: \"We have come to believe in the Sustainer of Moses and Aaron!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 70,
          "number": 2418,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "Said [Pharaoh]: \"Have you come to believe in him ere I have given you permission? Verily, he must be your master who has taught you magic! But I shall most certainly cut off your hands and feet in great numbers, because of [your] perverseness, and I shall most certainly crucify you in great numbers on trunks of palm-trees: and [I shall do this] so that you might come to know for certain as to which of us [two] can inflict a more severe chastisement, and [which] is the more abiding!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 71,
          "number": 2419,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "They answered: \"Never shall we prefer thee to all the evidence of the truth that has come unto us, nor to Him who has brought us into being! Decree, then, whatever thou art going to decree: thou canst decree only [something that pertains to] this worldly life!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 72,
          "number": 2420,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "As for us, behold, we have come to believe in our Sustainer, [hoping] that He may forgive us our faults and all that magic unto which thou hast forced us: for God is the best [to look forward to], and the One who is truly abiding.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 73,
          "number": 2421,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "VERILY, as for him who shall appear before his Sustainer [on Judgment Day] lost in sin - his [portion], behold, shall be hell: he will neither die therein nor live;",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 74,
          "number": 2422,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "whereas he who shall appear before Him as a believer who has done righteous deeds - it is such that shall have lofty stations [in the life to come]:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 75,
          "number": 2423,
          "juz": 16,
          "numpage": 316
        },
        {
          "text": "gardens of perpetual bliss, through which running waters flow, therein to abide: for that shall be the recompense of all who attain to purity.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 76,
          "number": 2424,
          "juz": 16,
          "numpage": 316
        }
      ]
    },
    {
      "315": [
        {
          "text": "[Moses] answered: \"Knowledge thereof rests with my Sustainer [alone, and is laid down] in His decree; my Sustainer does not err, and neither does He forget.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 52,
          "number": 2400,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "HE IT IS who has made the earth a cradle for you, and has traced out for you ways [of livelihood] thereon, and [who] sends down waters from the sky: and by this means We bring forth various kinds of plants.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 53,
          "number": 2401,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "Eat, [then, of this produce of the soil,] and pasture your cattle [thereon]. In all this, behold, there are messages indeed for those who are endowed with reason:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 54,
          "number": 2402,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "out of this [earth] have We created you, and into it shall We return you, and out of it shall We bring you forth once again.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 55,
          "number": 2403,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "AND, INDEED, We made Pharaoh aware of all Our messages-but he gave them the lie and refused [to heed them].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 56,
          "number": 2404,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "He said: \"Hash thou come to drive us out of our land by thy sorcery, O Moses?",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 57,
          "number": 2405,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "In that case, we shall most certainly produce before thee the like thereof! Appoint, then, a tryst between us and thee - which we shall not fail to keep, nor [mayest] thou -at a suitable place!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 58,
          "number": 2406,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "Answered [Moses]: \"Your tryst shall be the day of the Festival; and let the people assemble when the sun is risen high.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 59,
          "number": 2407,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "Thereupon Pharaoh withdrew [with his counsellors] and decided upon the scheme which he would pursue; and then he came [to the tryst].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 60,
          "number": 2408,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "Said Moses to them: \"Woe unto you! Do not invent lies against God, lest He afflict you with most grievous suffering: for He who contrives [such] a lie is already undone!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 61,
          "number": 2409,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "So they debated among themselves as to what to do; but they kept their counsel secret,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 62,
          "number": 2410,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "saying [to one another]: \"These two are surely sorcerers intent on driving you from your land by their sorcery, and on doing away with your time-honoured way of life.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 63,
          "number": 2411,
          "juz": 16,
          "numpage": 315
        },
        {
          "text": "Hence, [O sorcerers of Egypt,] decide upon the scheme which you will pursue, and then come forward in one single body: for, indeed, he who prevails today shall prosper indeed!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 64,
          "number": 2412,
          "juz": 16,
          "numpage": 315
        }
      ]
    },
    {
      "314": [
        {
          "text": "when We inspired thy mother with this inspiration:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 38,
          "number": 2386,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "Place him in a chest and throw it into the river, and thereupon the river will cast him ashore, [and] one who is an enemy unto Me and an enemy unto him will adopt him. \"And [thus early] I spread Mine Own love over thee -and [this] in order that thou might be formed under Mine eye.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 39,
          "number": 2387,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "\"[And thou wert under Mine eye] when thy sister went forth and said [to Pharaoh's people], 'Shall I guide you unto [a woman] who might take charge of him?'' And so We returned thee unto thy mother, so that her eye be gladdened, and that she might not sorrow [any longer]. \"And [when thou camest of age, thou didst slay a man: but We did save thee from all grief, although We tried thee with various trials. \"And then thou didst sojourn for years among the people of Madyan; and now thou hast come [here] as ordained [by Me], O Moses:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 40,
          "number": 2388,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "for I have chosen thee for Mine Own service.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 41,
          "number": 2389,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "\"Go forth, [then,] thou and thy brother, with My messages, and never tire of remembering Me:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 42,
          "number": 2390,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "go forth, both of you, unto Pharaoh: for, verily, he has transgressed all bounds of equity!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 43,
          "number": 2391,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "But speak unto him in a mild manner, so that he might bethink himself or [at least] be filled with apprehension.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 44,
          "number": 2392,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "The two [brothers] said: \"O our Sustainer! Verily, we fear lest he act hastily with regard to us, or lest he [continue to] transgress all bounds of equity.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 45,
          "number": 2393,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "Answered He: \"Fear not! Verily, I shall be with you two, hearing and seeing [all].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 46,
          "number": 2394,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "Go, then; you two unto him and say, `Behold, we are apostles sent by thy Sustainer: let, then, the children of Israel go with us, and cause them not to suffer [any longer]. We have now come unto thee with a message from thy Sustainer; and [know that His] peace shall be [only] on those who follow [His] guidance:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 47,
          "number": 2395,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "for, behold, it has been revealed to us that [in the life to come] suffering shall befall all who give the lie to the truth and turn away [from it]!\"'",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 48,
          "number": 2396,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "[But when God's message was conveyed unto Pharaoh,] he said: \"Who, now, is this Sustainer of you two, O Moses?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 49,
          "number": 2397,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "He replied: \"Our Sustainer is He who gives unto every thing [that exists] its true nature and form. and thereupon guides it [towards its fulfilment].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 50,
          "number": 2398,
          "juz": 16,
          "numpage": 314
        },
        {
          "text": "Said [Pharaoh]: \"And what of all the past generations?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 51,
          "number": 2399,
          "juz": 16,
          "numpage": 314
        }
      ]
    },
    {
      "313": [
        {
          "text": "and I have chosen thee [to be My apostle]: listen, then, to what is being revealed [unto thee].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 13,
          "number": 2361,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "\"Verily, I - I alone - am God; there is no deity save Me. Hence, worship Me alone, and be constant in prayer, so as to remember Me!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 14,
          "number": 2362,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "\"Behold, [although] I have willed to keep it hidden, the Last Hour is bound to come, so that every human being may be recompensed in accordance with what he strove for [in life].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 15,
          "number": 2363,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Hence, let not anyone who does not believe in its coming and follows [but] his own desires divert thee from [belief in] it, lest thou perish!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 16,
          "number": 2364,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "\"Now, what is this in thy right hand, O Moses?\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 17,
          "number": 2365,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "He answered: \"It is my staff; I lean on it; and with it I beat down leaves for my sheep; and [many] other uses have I for it.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 18,
          "number": 2366,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Said He: \"Throw it down, O Moses!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 19,
          "number": 2367,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "So he threw it - and lo! it was a snake, moving rapidly.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 20,
          "number": 2368,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Said He: \"Take hold of it, and fear not: We shall restore it to its former state.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 21,
          "number": 2369,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "\"Now place thy hand within thy armpit: it will come forth [shining] white, without blemish, as another sign [of Our grace],",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 22,
          "number": 2370,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "so that We might make thee aware of some of Our greatest wonders.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 23,
          "number": 2371,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "\"[And now] go thou unto Pharaoh: for, verily, he has transgressed all bounds of equity.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 24,
          "number": 2372,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Said [Moses]: \"O my Sustainer! Open up my heart [to Thy light],",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 25,
          "number": 2373,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "and make my task easy for me,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 26,
          "number": 2374,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "and loosen the knot from my tongue",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 27,
          "number": 2375,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "so that they might fully understand my speech,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 28,
          "number": 2376,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "and appoint for me, out of my kinsfolk, one who will help me to bear my burden:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 29,
          "number": 2377,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Aaron, my brother.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 30,
          "number": 2378,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Add Thou through him to my strength,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 31,
          "number": 2379,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "and let him share my task,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 32,
          "number": 2380,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "so that [together] we might abundantly extol Thy limitless glory",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 33,
          "number": 2381,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "and remember Thee without cease!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 34,
          "number": 2382,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Verily, Thou seest all that is within us!\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 35,
          "number": 2383,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "Said He: \"Thou art granted all that thou hast asked for, O Moses!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 36,
          "number": 2384,
          "juz": 16,
          "numpage": 313
        },
        {
          "text": "\"And, indeed, We bestowed Our favour upon thee at a time long since past,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 37,
          "number": 2385,
          "juz": 16,
          "numpage": 313
        }
      ]
    },
    {
      "312": [
        {
          "text": "VERILY, those who attain to faith and do righteous deeds will the Most Gracious endow with love:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 96,
          "number": 2346,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "and only to this end have We made this [divine writ] easy to understand, in thine own tongue, [O Prophet,] so that thou might convey thereby a glad tiding to the God-conscious, and warn thereby those who are given to [futile] contention:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 97,
          "number": 2347,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "for, how many a generation have We destroyed before their time - [and] canst thou perceive any one of them [now], or hear any whisper of them?",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 98,
          "number": 2348,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "O MAN!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 1,
          "number": 2349,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "We did not bestow the Qur'an on thee from on high to make thee unhappy,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 2,
          "number": 2350,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "but only as an exhortation to all who stand in awe [of God]:",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 3,
          "number": 2351,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "a revelation from Him who has created the earth and the high heavens",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 4,
          "number": 2352,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "the Most Gracious, established on the throne of His almightiness?",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 5,
          "number": 2353,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "Unto Him belongs all that is in the heavens and all that is on earth, as well as all that is between them and all that is beneath the sod.",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 6,
          "number": 2354,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "And if thou say anything aloud, [He hears it] since, behold, He knows [even] the secret [thoughts of man] as well as all that is yet more hidden [within him].",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 7,
          "number": 2355,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "God-there is no deity save Him; His [alone] are the attributes of perfection!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 8,
          "number": 2356,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "AND HAS the story of Moses ever come within thy ken?",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 9,
          "number": 2357,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "Lo! he saw a fire [in the desert]; and so he said to his family: \"Wait here! Behold, I perceive a fire [far away]: perhaps I can bring you a brand there from, or find at the fire some guidance.\"",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 10,
          "number": 2358,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "But when he came close to it, a voice called out: \"O Moses!",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 11,
          "number": 2359,
          "juz": 16,
          "numpage": 312
        },
        {
          "text": "Verily, I am thy Sustainer! Take off, then, thy sandals! Behold, thou art in the twice hallowed valley,",
          "englishName": "Taa-Haa",
          "suraNumber": 20,
          "numberInSurah": 12,
          "number": 2360,
          "juz": 16,
          "numpage": 312
        }
      ]
    },
    {
      "311": [
        {
          "text": "And hast thou ever considered [the kind of man] who is bent on denying the truth of Our messages and says, \"I will surely be given wealth and children\"?",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 77,
          "number": 2327,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "Has he, perchance, attained to a realm which is beyond the reach of a created being's perception? or has he concluded a covenant with the Most Gracious?",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 78,
          "number": 2328,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "Nay! We shall record what he says, and We shall lengthen the length of his suffering [in the hereafter],",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 79,
          "number": 2329,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "and divest him of all that he is [now] speaking of: for [on Judgment Day] he will appear before Us in a lonely state.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 80,
          "number": 2330,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "For [such as] these have taken to worshipping deities other than God, hoping that they would be a [source of] strength for them.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 81,
          "number": 2331,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "But nay! [On Judgment Day] these [very objects of adoration] will disavow the worship that was paid to them, and will turn against those [who had worshipped them]!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 82,
          "number": 2332,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "ART THOU NOT aware that We have let loose all [manner of] satanic forces upon those who deny the truth - [forces] that impel them [towards sin] with strong impulsion?",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 83,
          "number": 2333,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "Hence, be not in haste [to call down God's punishment] upon them: for We but number the number of their days.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 84,
          "number": 2334,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "On the Day when We shall gather the Godconscious unto [Us,] the Most Gracious, as honoured guests,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 85,
          "number": 2335,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "and drive those who were lost in sin unto hell as a thirsty herd is driven to a well –",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 86,
          "number": 2336,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "[on that Day] none will have [the benefit of] intercession unless he has [in his lifetime] entered into a bond with the Most Gracious.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 87,
          "number": 2337,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "As it is, some assert, \"The Most Gracious has taken unto Himself a son„!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 88,
          "number": 2338,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "Indeed, [by this assertion] you have brought forth something monstrous,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 89,
          "number": 2339,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "whereat the heavens might well-nigh be rent into fragments, and the earth be split asunder, and the mountains fall down in ruins!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 90,
          "number": 2340,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "That men. should ascribe a son to the Most Gracious,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 91,
          "number": 2341,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "although it is inconceivable that the Most Gracious should take unto Himself a son!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 92,
          "number": 2342,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "Not one of all [the beings] that are in the heavens or on earth appears before the Most Gracious other than as a servant:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 93,
          "number": 2343,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "indeed, He has full cognizance of them, and has numbered them with [unfailing] numbering;",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 94,
          "number": 2344,
          "juz": 16,
          "numpage": 311
        },
        {
          "text": "and every one of them will appear before Him on Resurrection Day in a lonely state.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 95,
          "number": 2345,
          "juz": 16,
          "numpage": 311
        }
      ]
    },
    {
      "310": [
        {
          "text": "the Sustainer of the heavens and the earth and all that is between them! Worship, then, Him alone, and remain steadfast in His worship! Dost thou know any whose name is worthy to be mentioned side by side with His?\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 65,
          "number": 2315,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "WITH ALL THIS, man [often] says, \"What! Once I am dead, shall I again be brought forth alive?\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 66,
          "number": 2316,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "But does man not bear in mind that We have created him aforetime out of nothing?",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 67,
          "number": 2317,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "And so, by thy Sustainer, [on Judgment Day] We shall most certainly bring them forth together with the satanic forces [which impelled them in life] and then We shall most certainly gather them, on their knees, around hell;",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 68,
          "number": 2318,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "and thereupon We shall, indeed, draw forth from every group [of sinners] the ones that had been most determined in their disdainful rebellion against the Most Gracious:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 69,
          "number": 2319,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "for, indeed, We know best as to which of them is most deserving of the fires of hell.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 70,
          "number": 2320,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "And every one of you will come within sight of it: this is, with thy Sustainer, a decree that must be fulfilled.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 71,
          "number": 2321,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "And once again: We shall save [from hell] those who have been conscious of Us; but We shall leave in it the evildoers, on their knees.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 72,
          "number": 2322,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "AS IT IS, whenever Our messages are conveyed to them in all their clarity, those who are bent on denying the truth are wont to say unto those who have attained to faith: \"Which of the two kinds of man is in a stronger position and superior as a community?\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 73,
          "number": 2323,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "And yet, how many a generation have We destroyed before their time-[people] who surpassed them in material power and in outward show!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 74,
          "number": 2324,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "Say: \"As for him who lives in error, may the Most Gracious lengthen the span of his life! [And let them say whatever they say until the time when they behold that [doom] of which they were forewarned-whether it be suffering [in this world] or [at the coming of] the Last Hour -: for then they will understand which [of the two kinds of man] was worse in station and weaker in resources!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 75,
          "number": 2325,
          "juz": 16,
          "numpage": 310
        },
        {
          "text": "And God endows those who avail themselves of [His] guidance with an ever-deeper consciousness of the right way; and good deeds, the fruit whereof endures forever, are, in thy Sustainer's sight, of far greater merit [than any worldly goods], and yield far better returns.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 76,
          "number": 2326,
          "juz": 16,
          "numpage": 310
        }
      ]
    },
    {
      "309": [
        {
          "text": "And [remember how] We called upon him from the right-hand slope of Mount Sinai and drew him near [unto Us] in mystic communion,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 52,
          "number": 2302,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "and [how], out of Our grace, We granted unto him his brother Aaron, to be a prophet [by his side].",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 53,
          "number": 2303,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "AND CALL to mind, through this divine writ, Ishmael. Behold, he was always true to his promise, and was an apostle [of God], a prophet,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 54,
          "number": 2304,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "who used to enjoin upon his people prayer and charity, and found favour in his Sustainer's sight.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 55,
          "number": 2305,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "AND CALL to mind, through this divine writ, Idris. Behold, he was a man of truth, a prophet,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 56,
          "number": 2306,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "whom We exalted onto a lofty station.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 57,
          "number": 2307,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "THESE WERE some of the prophets upon whom God bestowed His blessings - [prophets] of the seed of Adam and of those whom We caused to be borne [in the ark] with Noah, and of the seed of Abraham and Israel and [all of them were] among those whom We had guided and elected; [and] whenever the messages of the Most Gracious were conveyed unto them, they would fall down [before Him], prostrating themselves and weeping.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 58,
          "number": 2308,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "Yet they were succeeded by generations [of people] who lost all [thought of] prayer and followed [but] their own lusts; and these will, in time, meet with utter disillusion.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 59,
          "number": 2309,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "Excepted, however, shall be those who repent and attain to faith and do righteous deeds: for it is they who will enter paradise and will not be wronged in any way:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 60,
          "number": 2310,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "[theirs will be the] gardens of perpetual bliss which the Most Gracious has promised unto His servants, in a realm which is beyond the reach of human perception: [and,] verily, His promise is ever sure of fulfilment!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 61,
          "number": 2311,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "No empty talk will they hear there-nothing but [tidings of] inner soundness and peace; and there will they have their sustenance by day and by night:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 62,
          "number": 2312,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "this is the paradise which We grant as a heritage unto such of Our servants as are conscious of Us.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 63,
          "number": 2313,
          "juz": 16,
          "numpage": 309
        },
        {
          "text": "AND [the angels say]: \"We do not descend [with revelation], again and again, other than by thy Sustainer's command: unto Him belongs all that lies open before us and all that is hidden-from us and all that is in-between. And never does thy Sustainer forget [anything] –",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 64,
          "number": 2314,
          "juz": 16,
          "numpage": 309
        }
      ]
    },
    {
      "308": [
        {
          "text": "hence, warn them of [the coming of] the Day of Regrets, when everything will have been decided-for as yet they are heedless, and they do not believe [in it].",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 39,
          "number": 2289,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "Behold, We alone shall remain after the earth and all who live on it have passed away, and (when] unto Us all will have been brought back.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 40,
          "number": 2290,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "AND CALL to mind, through this divine writ, Abraham. Behold, he was a man of truth, [already] a prophet",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 41,
          "number": 2291,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "when he spoke [thus] unto his father: \"O my father! Why dost thou worship something that neither hears nor sees and can be of no avail whatever to thee?",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 42,
          "number": 2292,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "\"O my father! Behold, there has indeed come to me [a ray] of knowledge such as has never yet come unto thee: follow me, then; I shall guide thee onto a perfect way.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 43,
          "number": 2293,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "\"O my father! Do not worship Satan-for, verily, Satan is a rebel against the Most Gracious!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 44,
          "number": 2294,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "O my father! I dread lest a chastisement from the Most Gracious befall thee, and then thou wilt become [aware of having been] close unto Satan!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 45,
          "number": 2295,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "He answered: \"Dost thou dislike my gods, O Abraham? Indeed, if thou desist not, I shall most certainly cause thee to be stoned to death! Now begone from me for good!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 46,
          "number": 2296,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "[Abraham] replied: \"Peace be upon thee! I shall ask my Sustainer to forgive thee: for, behold, He has always been kind unto me.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 47,
          "number": 2297,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "But I shall withdraw from you all and from whatever you invoke instead of God, and shall invoke my Sustainer [alone]: it may well be that my prayer [for thee] will not remain unanswered by my Sustainer.\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 48,
          "number": 2298,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "And after he had withdrawn from them and from all that they were worshipping instead of God, We bestowed upon him Isaac and Jacob, and made each of them a prophet;",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 49,
          "number": 2299,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "and We bestowed upon them [manifold] gifts out of Our grace, and granted them a lofty power to convey the truth [unto others].",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 50,
          "number": 2300,
          "juz": 16,
          "numpage": 308
        },
        {
          "text": "AND CALL to mind, through this divine writ, Moses. Behold, he was a chosen one, and was an apostle [of God], a prophet.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 51,
          "number": 2301,
          "juz": 16,
          "numpage": 308
        }
      ]
    },
    {
      "307": [
        {
          "text": "Eat, then, and drink, and let thine eye be gladdened! And if thou shouldst see any human being, convey this unto him: `Behold, abstinence from speech have I vowed unto the Most Gracious; hence, I may not speak today to any mortal.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 26,
          "number": 2276,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "And in time she returned to her people, carrying the child with her. They said: \"O Mary! Thou hast indeed done an amazing thing!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 27,
          "number": 2277,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "O sister of Aaron! Thy father was not a wicked man, nor was thy mother a loose woman!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 28,
          "number": 2278,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "Thereupon she pointed to him. They exclaimed: \"How can we talk to one who [as yet] is a little boy in the cradle?\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 29,
          "number": 2279,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "[But] he said: \"Behold, I am a servant of God. He has vouchsafed unto me revelation and made me a prophet,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 30,
          "number": 2280,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "and made me blessed wherever I may be; and He has enjoined upon me prayer and charity as long as I live,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 31,
          "number": 2281,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "and [has endowed me with] piety towards my mother; and He has not made me haughty or bereft of grace.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 32,
          "number": 2282,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "\"Hence, peace was upon me on the day when I was born, and [will be upon me] on the day of my death, and on the day when I shall be raised to life [again]!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 33,
          "number": 2283,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "SUCH WAS, in the words of truth, Jesus the son of Mary, about whose nature they so deeply disagree.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 34,
          "number": 2284,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "It is not conceivable that God should have taken unto Himself a son: limitless is He in His glory! When He wills a thing to be, He but says unto it \"Be\" -and it is!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 35,
          "number": 2285,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "And [thus it was that Jesus always said]: \"Verily, God is my Sustainer as well as your Sustainer; so worship [none but] Him: this (alone] is a straight way.\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 36,
          "number": 2286,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "And yet, the sects [that follow the Bible] are at variance among themselves [about the nature of Jesus Woe, then, unto all who deny the truth when that awesome Day will appear!",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 37,
          "number": 2287,
          "juz": 16,
          "numpage": 307
        },
        {
          "text": "How well will they hear and see [the truth] on the Day when they come before Us! Today, however, these evildoers are obviously lost in error:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 38,
          "number": 2288,
          "juz": 16,
          "numpage": 307
        }
      ]
    },
    {
      "306": [
        {
          "text": "[And when the son was born and grew up, he was told,] \"O John! Hold fast unto the divine writ with [all thy] strength!\" - for We granted him wisdom \"while he was yet a little boy,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 12,
          "number": 2262,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "as well as, by Our grace, [the gift of] compassion and purity; and he was [always] conscious of Us",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 13,
          "number": 2263,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "and full of piety towards his parents; and never was he haughty or rebellious.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 14,
          "number": 2264,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "Hence, [God's] peace was upon him on the day when he was born, and on the day of his death, and. will be [upon him] on the day when he shall be raised to life [again].",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 15,
          "number": 2265,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "AND CALL to mind, through this divine writ, Mary. Lo! She withdrew from her family to an eastern place",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 16,
          "number": 2266,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "and kept herself in seclusion from them, whereupon We sent unto her Our angel of revelation, who appeared to her in the shape of a well-made human being.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 17,
          "number": 2267,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "She exclaimed: \"Verily, 'I seek refuge from thee with the Most Gracious! [Approach me not] if thou art conscious of Him!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 18,
          "number": 2268,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "[The angel] answered: \"I' am but a messenger of thy Sustainer, [who says,] `I shall bestow upon thee the gift of a son endowed with purity.'\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 19,
          "number": 2269,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "Said she: \"How can I have a son when no man has ever touched me? - for, never have I been a loose woman!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 20,
          "number": 2270,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "[The angel] answered: \"Thus it is; [but] thy Sustainer says, `This is easy for Me; and [thou shalt have a son,] so that We might make him a symbol unto mankind and an act of grace from US. And it was a thing decreed [by God]:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 21,
          "number": 2271,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "and in time she conceived him, and then she withdrew with him to a far-off place.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 22,
          "number": 2272,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "And [when] the throes of childbirth drove her to the trunk of a palm-tree, she exclaimed: \"Oh, would that I had died ere this, and had become a thing forgotten, utterly forgotten!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 23,
          "number": 2273,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "Thereupon [a voice] called out to her from beneath that [palm-tree]: \"Grieve not! Thy Sustainer has provided a rivulet [running] beneath thee;",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 24,
          "number": 2274,
          "juz": 16,
          "numpage": 306
        },
        {
          "text": "and shake the trunk of the palm-tree towards thee: it will drop fresh, ripe dates upon thee.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 25,
          "number": 2275,
          "juz": 16,
          "numpage": 306
        }
      ]
    },
    {
      "305": [
        {
          "text": "Kaf. Ha. Ya. `Ayn. Sad.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 1,
          "number": 2251,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "AN ACCOUNT of the grace which thy Sustainer bestowed upon His servant Zachariah:",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 2,
          "number": 2252,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "When he called out to his Sustainer in the secrecy of his heart,",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 3,
          "number": 2253,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "he prayed: \"O my Sustainer! Feeble have become my bones, and my head glistens with grey hair. But never yet, O my Lord, has my prayer unto Thee remained unanswered.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 4,
          "number": 2254,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "\"Now, behold, I am afraid of [what] my kinsfolk [will do] after I am gone, for my wife has always been barren. Bestow, then, upon me, out of Thy grace, the gift of a successor",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 5,
          "number": 2255,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "who will be my heir as well as an heir [to the dignity] of the House of Jacob; and make him, O my Sustainer, well-pleasing to Thee!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 6,
          "number": 2256,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "[Thereupon the angels called out unto him: \"O Zachariah! We bring thee the glad tiding of [the birth of] a son whose name shall be John. [And God says,] `Never have We given this name to anyone before him.\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 7,
          "number": 2257,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "[Zachariah] exclaimed: \"O my Sustainer! How can I have a son when my wife has always been barren and I have become utterly infirm through old age?\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 8,
          "number": 2258,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "Answered [the angel]: \"Thus. it is; [but] thy 'Sustainer says, `This is easy for Me -even as I have created thee aforetime out of nothing.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 9,
          "number": 2259,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "[Zachariah] prayed: \"O my Sustainer! Appoint a sign for me!\" Said [the angel]: \"Thy sign shall be that for full three nights [and days] thou wilt not speak unto men.",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 10,
          "number": 2260,
          "juz": 16,
          "numpage": 305
        },
        {
          "text": "Thereupon he came out of the sanctuary unto his people and signified to them [by gestures]: \"Extol His limitless glory by day and by night!\"",
          "englishName": "Maryam",
          "suraNumber": 19,
          "numberInSurah": 11,
          "number": 2261,
          "juz": 16,
          "numpage": 305
        }
      ]
    },
    {
      "304": [
        {
          "text": "Said [the King]: \"This is a mercy from my Sustainer! Yet when the time appointed by my Sustainer shall come, He will make this [rampart] level with the ground: and my Sustainer's promise always comes true!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 98,
          "number": 2238,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "AND ON that Day\" We shall [call forth all mankind and] leave them to surge like waves [that dash] against one another; and the trumpet [of judgment] will be blown, and We shall gather them all together.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 99,
          "number": 2239,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "And on that Day We shall place hell, for all to see, before those who denied the truth-",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 100,
          "number": 2240,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "those whose eyes had been veiled against any remembrance of Me because they could not bear to listen [to the voice of truth]!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 101,
          "number": 2241,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "Do they who are bent on denying the truth think, perchance, that they could take [any of] My creatures for protectors against Me? Verily, We have readied hell to welcome all who [thus] deny the truth!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 102,
          "number": 2242,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "Say: \"Shall we tell you who are the greatest losers in whatever they may do?",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 103,
          "number": 2243,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "\"[It is] they whose labour has gone astray in [the pursuit of no more than] this world's life, and who none the less think that they are doing good works:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 104,
          "number": 2244,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "it is they who have chosen to deny their Sustainer's messages and the truth; that they are deseined to meet Him.\" Hence, all their [good] deeds come to nought, and no weight shall We assign to them on Resurrection Day.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 105,
          "number": 2245,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "That will be their recompense - [their] hell -for having denied the truth and made My messages and My apostles a target of their mockery.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 106,
          "number": 2246,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "[But,] verily, as for those who attain to faith and do righteous deeds - the gardens of paradise will be there to welcome them;",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 107,
          "number": 2247,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "therein will they abide, [and] never will they desire any change therefrom.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 108,
          "number": 2248,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "SAY: \"If all the sea were ink for my Sustainer's words, the sea would indeed be exhausted ere my Sustainer's words are exhausted! And [thus it would be] if we were to add to it sea upon sea.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 109,
          "number": 2249,
          "juz": 16,
          "numpage": 304
        },
        {
          "text": "Say [O Prophet]: \"I am but a mortal man like all of you. It has been revealed unto me that your God is the One and Only God. Hence, whoever looks forward [with hope and awe] to meeting his Sustainer [on Judgment Day], let him do righteous deeds, and let him not ascribe unto anyone or anything a share in the worship due to his Sustainer!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 110,
          "number": 2250,
          "juz": 16,
          "numpage": 304
        }
      ]
    },
    {
      "303": [
        {
          "text": "Behold, We established him securely on earth, and endowed him with [the knowledge of] the right means to achieve anything [that he might set out to achieve];",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 84,
          "number": 2224,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "and so he chose the right means [in whatever he did].",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 85,
          "number": 2225,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "[And he marched westwards] till, when he came to the setting of the sun, it appeared to him that it was setting in a dark, turbid sea; and nearby he found a people [given to every kind of wrongdoing]. We said: \"O thou Two-Horned One! Thou mayest either cause [them] to suffer or treat them with kindness!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 86,
          "number": 2226,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "He answered: \"As for him who does wrong [unto others] - him shall we, in time, cause to suffer; and thereupon he shall be referred to his Sustainer, and He will cause him to suffer with unnameable suffering.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 87,
          "number": 2227,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "But as for him who believes and does righteous deeds - he will have the ultimate good [of the life to come] as his reward; and [as for us,] we shall make binding on him [only] that which is easy to fulfill.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 88,
          "number": 2228,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "And once again he chose the right means [to achieve a right end].",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 89,
          "number": 2229,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "[And then he marched eastwards] till, when he came to the rising of the sun he found that it was rising on a people for whom We had provided no coverings against it:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 90,
          "number": 2230,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "thus [We had made them, and thus he left them; and We did encompass with Our knowledge all that he had in mind",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 91,
          "number": 2231,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "And once again he chose the right means (to achieve a right end].",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 92,
          "number": 2232,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "[And he marched on] till, when he reached [a place] between the two mountain-barriers, he found beneath them a people who could scarcely understand a word [of his language].",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 93,
          "number": 2233,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "They said: \"O thou Two-Horned One! Behold, Gog and Magog\" are spoiling this land. May we, then, pay unto thee a tribute on the understanding that thou wilt erect a barrier between us and them?\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 94,
          "number": 2234,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "He answered: \"That wherein my Sustainer has so securely established me is better [than anything that you could give me]; hence, do but help me with [your labour's] strength, [and] I shall erect a rampart between you and them!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 95,
          "number": 2235,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "Bring me ingots of iron!\" Then, after he had [piled up the iron and] filled the gap between the two mountain-sides, he said: \"[Light a fire and] ply your bellows!\" At length, when he had made it [glow like] fire, he commanded: \"Bring me molten copper which I may pour upon it.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 96,
          "number": 2236,
          "juz": 16,
          "numpage": 303
        },
        {
          "text": "And thus [the rampart was built, and] their enemies were unable to scale it, and neither were they able to pierce it.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 97,
          "number": 2237,
          "juz": 16,
          "numpage": 303
        }
      ]
    },
    {
      "302": [
        {
          "text": "He replied: \"Did I not tell thee that thou wilt never be able to have patience with me?\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 75,
          "number": 2215,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "Said [Moses]: \"If, after this, I should ever question thee, keep me not in thy company: [for by] now thou hast heard enough excuses from me.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 76,
          "number": 2216,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "And so the two went on, till, when they came upon some village people, they asked them for food; but those [people] refused them all hospitality. And they saw in that (village] a wall which was on the point of tumbling down, and [the sage] rebuilt it [whereupon Moses] said: \"Hadst thou so wished, surely thou couldst [at least] have obtained some payment for it?\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 77,
          "number": 2217,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "[The sage] replied: \"This is the parting of ways between me and thee. [And now] I shall let thee know the real meaning of all [those events] that thou wert unable to bear with patience:,",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 78,
          "number": 2218,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "\"As for that boat, it belonged to some needy people who toiled upon the sea -and I desired to damage it because (I knew that] behind them was a king who is wont to seize every boat by brute force.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 79,
          "number": 2219,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "\"And as for that young man, his parents were [true] believers - whereas we had every reason to fear that he would bring bitter grief upon them by [his] overweening wickedness and denial of all truth:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 80,
          "number": 2220,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "and so we desired that their Sustainer grant them in his stead [a child] of greater purity than him, and closer [to them] in loving tenderness.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 81,
          "number": 2221,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "\"And as for that wall, it belonged to two orphan boys [living] in the town, and beneath it was [buried] a treasure belonging to them [by right]. Now their father had been a righteous man, and so thy Sustainer willed it that when they come of age they should bring forth their treasure by thy Sustainer's grace. \"And I did not do (any of] this of my own accord: this is the real meaning of all [those events] that thou wert unable to bear with patience.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 82,
          "number": 2222,
          "juz": 16,
          "numpage": 302
        },
        {
          "text": "AND THEY will ask thee about the Two-Horned One. Say: \"I will convey unto you something by which he ought to be remembered.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 83,
          "number": 2223,
          "juz": 16,
          "numpage": 302
        }
      ]
    },
    {
      "301": [
        {
          "text": "And after the two had walked some distance, [Moses] said to his servant: \"Bring us our mid-day meal; we have indeed suffered hardship on this [day of] our journey!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 62,
          "number": 2202,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "Said [the servant]: \"Wouldst thou believe it? When we betook ourselves to that rock for a rest, behold, I forgot about the fish-and none but Satan made me thus forget it - and it took its way into the sea! How strange!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 63,
          "number": 2203,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "[Moses] exclaimed: \"That [was the place] which we were seeking!, And the two turned back, retracing their footsetps,",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 64,
          "number": 2204,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "and found one of Our servants, on whom We had bestowed grace from Ourselves and unto whom We had imparted knowledge [issuing] from Ourselves.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 65,
          "number": 2205,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "Moses said unto him: \"May I follow thee on the understanding that thou wilt impart to me something of that consciousness of what is right which has been imparted to thee?\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 66,
          "number": 2206,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "[The other] answered: \"Behold, thou wilt never be able to have patience with me –",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 67,
          "number": 2207,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "for how couldst thou be patient about something that thou canst not comprehend within the compass of (thy] experience?\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 68,
          "number": 2208,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "Replied [Moses]: \"Thou wilt find me patient, if God so wills; and I shall not disobey thee in anything!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 69,
          "number": 2209,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "Said [the sage]: \"Well, then, if thou art to follow me, do not question me about aught [that I may do] until I myself give thee an account thereof.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 70,
          "number": 2210,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "And so the two went on their way, till (they reached the seashore; and] when they disembarked from the boat [that had ferried them across], the sage made a hole in it-[whereupon Moses] exclaimed: \"Hast thou made a hole in it in order to drown the people who may be [travelling] in it? Indeed, thou hast done a grievous thing!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 71,
          "number": 2211,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "He replied: \"Did I not tell thee that thou wilt never be able to have patience with me?\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 72,
          "number": 2212,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "Said [Moses]: \"Take-me not to task for my having forgotten [myself], and be not hard on me on account of what I have done!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 73,
          "number": 2213,
          "juz": 15,
          "numpage": 301
        },
        {
          "text": "And so the two went on, till, when they met a young man, [the sage] slew him -(whereupon Moses] exclaimed: \"Hast thou slain an innocent human being without [his having taken] another man's life? Indeed, thou hast done a terrible thing!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 74,
          "number": 2214,
          "juz": 15,
          "numpage": 301
        }
      ]
    },
    {
      "300": [
        {
          "text": "THUS, INDEED, have We given in this Qur'an many facets to every kind of lesson [designed] for [the benefit of] mankind. However, man is, above all else, always given to contention:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 54,
          "number": 2194,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "for, what is there to keep people from attaining to faith now that guidance has come unto them, and from asking their Sustainer to forgive them their sins - unless it be [their wish] that the fate of the [sinful] people of ancient times should befall them [as well], or that the [ultimate] suffering should befall them in the hereafter?",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 55,
          "number": 2195,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "But We send [Our] message-bearers only as heralds of glad tidings and as warners - whereas those who are bent on denying the truth contend [against them] with fallacious arguments, so as to render void the truth thereby, and to make My messages and warnings a target of their mockery.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 56,
          "number": 2196,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "And who could be more wicked than he to whom his Sustainer's messages are conveyed and who thereupon turns away from them, forgetting all [the evil] that his hands may have wrought? Behold, over their hearts have We laid veils which prevent them from grasping the truth, and into their ears, deafness; and though thou call them onto the right path, they will never allow themselves to be guided.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 57,
          "number": 2197,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "Yet, [withal,] thy Sustainer is the Truly-Forgiving One, limitless in His grace. Were He to take them [at once] to task for whatever [wrong] they commit, He would indeed bring about their speedy punishment [then and there]: but nay, they have a time-limit beyond which they shall find no redemption",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 58,
          "number": 2198,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "as [was the case with all] those communities that We destroyed when they went on and on doing wrong: for We had set a time-limit for their destruction.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 59,
          "number": 2199,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "AND LO! [In the course of his wanderings,] Moses said to his servant: \"I shall not give up until I reach the junction of the two seas, even if I [have to] spend untold years [in my quest]!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 60,
          "number": 2200,
          "juz": 15,
          "numpage": 300
        },
        {
          "text": "But when they reached the junction between the two [seas], they forgot all about their fish, and it took its way into the sea and disappeared from sight.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 61,
          "number": 2201,
          "juz": 15,
          "numpage": 300
        }
      ]
    },
    {
      "299": [
        {
          "text": "Wealth and children are an adornment of this, world's life: but good deeds, the fruit whereof endures forever, are of far greater merit in thy Sustainer's sight, and a far better source of hope.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 46,
          "number": 2186,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "Hence, [bear in mind] the Day, on which We shall cause the mountains to disappear and thou shalt behold the earth void and bare: for [on that Day] We will [resurrect the dead and] gather them all together, leaving out none of them.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 47,
          "number": 2187,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "And they will be lined up before thy Sustainer, [and He will say:] \"Now, indeed, you have come unto Us [in a lonely state], even as We created you in the first instances - although you were wont to assert that We would never 'appoint for you a meeting [with Us]!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 48,
          "number": 2188,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "And the record [of everyone's deeds] will be laid open; and thou wilt behold the guilty filled with dread at what [they see] therein; and they will exclaim: \"Oh, woe unto us! What a record is this! It leaves out nothing, be it small or great, but takes everything into account!\" For they will find all that they ever wrought [now] facing them, and [will know that] thy Sustainer does not wrong anyone.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 49,
          "number": 2189,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "AND [remember that] when We told the angels, \"Prostrate yourselves before Adam,\" they all prostrated themselves, save Iblis: he [too] was one of those invisible beings, but then he turned away from his Sustainer's command. Will you, then, take him and his cohorts for (your], masters instead of Me, although they are your foe? How vile an exchange on the evildoers' part!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 50,
          "number": 2190,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "I did not make them witnesses of the creation of the heavens and the earth, nor of the creation of their own selves; and neither do I [have any need to] take as My helpers those [beings] that lead [men] astray.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 51,
          "number": 2191,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "Hence, [bear in mind] the Day on which He will say, \"Call [now] unto those beings whom you imagined to have a share in My divinity!\" - whereupon they will invoke them, but those [beings] will not respond to them: for We shall have placed between them an unbridgeable gulf.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 52,
          "number": 2192,
          "juz": 15,
          "numpage": 299
        },
        {
          "text": "And those who were lost in sin will behold the fire, and will know that they are bound to fall into it, and will find no way of escape therefrom.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 53,
          "number": 2193,
          "juz": 15,
          "numpage": 299
        }
      ]
    },
    {
      "298": [
        {
          "text": "And having [thus] sinned against himself, he entered his garden, saying, \"I do not think that this will ever perish!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 35,
          "number": 2175,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "And neither do I think that the Last Hour will ever come. But even if [it should come, and] I am brought before my Sustainer, I will surely find something even better than this as [my last] resort!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 36,
          "number": 2176,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "And his friend answered him in the course of their argument: \"Wilt thou blaspheme against Him who has created thee out of dust, and then out of a drop of sperm, and in the end has fashioned thee into a [complete] man?",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 37,
          "number": 2177,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "But as for myself, [I. know that] He is God, my Sustainer; and I cannot attribute divine powers to any but my Sustainer.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 38,
          "number": 2178,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "And [he continued:] \"Alas, if thou hadst but said, on entering thy garden, `Whatever God wills [shall come to pass, for] there is no power save with God!' Although, as thou seest, I have less wealth and offspring than thou,",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 39,
          "number": 2179,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "yet it may well be that my Sustainer will give me something better than thy garden -just as He may let loose a calamity out of heaven upon this [thy garden], so that it becomes a heap of barren dust",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 40,
          "number": 2180,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "or its water sinks deep into the ground, so that thou wilt never be able to find it again!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 41,
          "number": 2181,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "And [thus it happened:] his fruitful gardens were encompassed [by ruin], and there he was, wringing his hands over all that he had spent on that which now lay waste, with its trellises caved in; and he could but say, \"Oh, would that I had not attributed divine powers to any but my Sustainer!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 42,
          "number": 2182,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "-for now he had nought to succour him in God's stead, nor could he succour himself.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 43,
          "number": 2183,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "For thus it is: all protective power belongs to God alone, the True One. He is the best to grant recompense, and the best to determine what is to be.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 44,
          "number": 2184,
          "juz": 15,
          "numpage": 298
        },
        {
          "text": "AND PROPOUND unto them the parable of the life of this world: [it is] like the water which We send down from the skies, and which is absorbed by the plants of the earth: but [in time] they turn into dry. stubble which the winds blow freely about. And it is God [alone] who determines all things.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 45,
          "number": 2185,
          "juz": 15,
          "numpage": 298
        }
      ]
    },
    {
      "297": [
        {
          "text": "And contain thyself in patience by the side of all who at morn and at evening invoke their Sustainer, seeking His countenance, and let not thine eyes pass beyond them in quest of the beauties of this world's life; and pay no heed to any whose heart We have rendered heedless of all remembrance of Us because he had always followed [only] his own desires, abandoning all that is good and true.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 28,
          "number": 2168,
          "juz": 15,
          "numpage": 297
        },
        {
          "text": "And say: \"The truth [has now come] from your Sustainer: let, then, him who wills, believe in it, and let him who wills, reject it.\" Verily, for all who sin against themselves [by rejecting Our truth] We have readied a fire whose billowing folds will encompass them from all sides; and if they beg for water, they will be given water [hot] like molten lead, which will scald their faces: how dreadful a drink, and how evil a place to rest!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 29,
          "number": 2169,
          "juz": 15,
          "numpage": 297
        },
        {
          "text": "[But,] behold, as for those who attain to faith and do righteous deeds-verily, We do not fail to requite any who persevere in doing good:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 30,
          "number": 2170,
          "juz": 15,
          "numpage": 297
        },
        {
          "text": "theirs shall be gardens of perpetual bliss - [gardens] through which running waters flow - wherein they will be adorned with bracelets of gold and will wear green garments of silk and brocade, [and] wherein upon couches they will recline:\" how excellent a recompense, and how goodly a place to rest!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 31,
          "number": 2171,
          "juz": 15,
          "numpage": 297
        },
        {
          "text": "AND PROPOUND unto them the parable of two men, upon one of whom We had bestowed two vinyards, and surrounded them with date-palms, and placed a field of grain in-between.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 32,
          "number": 2172,
          "juz": 15,
          "numpage": 297
        },
        {
          "text": "Each of the two gardens yielded its produce and never failed therein in any way, for We had caused a stream to gush forth in the midst of each of them.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 33,
          "number": 2173,
          "juz": 15,
          "numpage": 297
        },
        {
          "text": "And so [the man] had fruit in abundance. And [one day] he said to his friend, bandying words with him, \"More wealth have I than thou, and mightier am I as regards [the number and power of my] followers!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 34,
          "number": 2174,
          "juz": 15,
          "numpage": 297
        }
      ]
    },
    {
      "296": [
        {
          "text": "AND IN THIS way have We drawn [people's] attention to their story, so that they might know - whenever they debate among themselves as to what happened to those [Men of the Cave] that God's promise [of resurrection] is true, and that there can be no doubt as to [the coming of] the Last Hour. And so, some [people] said: \"Erect a building in their memory; God knows best what happened to them.\" Said they whose opinion prevailed in the end: \"Indeed, we must surely raise a house of worship in their memory!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 21,
          "number": 2161,
          "juz": 15,
          "numpage": 296
        },
        {
          "text": "[And in times to come] some will say, \"[They were] three, the fourth of them being their dog,\" while others will say, \"Five, with their dog as the sixth of them\" -idly guessing at something of which they can have no knowledge -and [so on, until] some will say, \"[They were] seven, the eighth of them being their dog.\" Say: \"My Sustainer knows best how many they were. None but a few have any [real] knowledge of them. Hence, do not argue about them otherwise than by way of an obvious argument, and do not ask any of those [story-tellers] to enlighten thee about them.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 22,
          "number": 2162,
          "juz": 15,
          "numpage": 296
        },
        {
          "text": "AND NEVER say about anything, \"Behold, I shall do this tomorrow,\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 23,
          "number": 2163,
          "juz": 15,
          "numpage": 296
        },
        {
          "text": "without (adding], \"if God so wills.\" And if thou shouldst forget [thyself at the time, and become aware of it later], call thy Sustainer to mind and say: \"I pray that my Sustainer guide me, even closer than this, to a consciousness of what is right!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 24,
          "number": 2164,
          "juz": 15,
          "numpage": 296
        },
        {
          "text": "AND [some people assert], \"They remained in their cave three hundred years\"; and some have added nine [to that number].",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 25,
          "number": 2165,
          "juz": 15,
          "numpage": 296
        },
        {
          "text": "Say: \"God knows best how long they remained [there]. His [alone] is the knowledge of the hidden reality of the heavens and the earth: how well does He see and hear! No guardian have they apart from Him, since He allots to no one a share in His rule!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 26,
          "number": 2166,
          "juz": 15,
          "numpage": 296
        },
        {
          "text": "AND CONVEY [to the world] whatever has been revealed to thee of thy Sustainer's writ. There is nothing that could alter His words; and thou canst find no refuge other than with Him.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 27,
          "number": 2167,
          "juz": 15,
          "numpage": 296
        }
      ]
    },
    {
      "295": [
        {
          "text": "Hence, now that you have withdrawn from them and from all that they worship instead of God, take refuge in that cave: God will spread His grace over you, and will endow you - whatever your [outward] condition - with all that your souls may need!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 16,
          "number": 2156,
          "juz": 15,
          "numpage": 295
        },
        {
          "text": "And [for many a year] thou might have seen the sun, on its rising, incline away from their cave on the right, and, on its setting, turn aside from them on the left, while they lived on in that spacious chamber, [bearing witness to] this of God's messages: He whom God guides, he alone has found the right way; whereas for him whom He lets go astray thou canst never find any protector who would point out the right way.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 17,
          "number": 2157,
          "juz": 15,
          "numpage": 295
        },
        {
          "text": "And thou wouldst have thought that they were awake, whereas they lay asleep. And We caused them to turn over repeatedly, now to the right, now to the left; and their dog [lay] on the threshold, its forepaws outstretched. Hadst thou come upon them [unprepared], thou wouldst surely have turned away from them in flight, and wouldst surely have been filled with awe of them.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 18,
          "number": 2158,
          "juz": 15,
          "numpage": 295
        },
        {
          "text": "And so, [in the course of time,] We awakened them; and they began to ask one another [as to what had happened to them]. One of them asked: \"How long have you remained thus?\" [The others] answered: \"We have remained thus a day, or part of a day.\" Said they [who were endowed with deeper insight]: \"Your Sustainer knows best how long you have thus remained. Let, then, one of you go with these silver coins to the town, and let him find out what food is purest there, and bring you thereof [some] provisions. But let him behave with great care and by no means make anyone aware of you:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 19,
          "number": 2159,
          "juz": 15,
          "numpage": 295
        },
        {
          "text": "for, behold, if they should come to know of you, they might stone you to death or force you back to their faith-in which case you would never attain to any good!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 20,
          "number": 2160,
          "juz": 15,
          "numpage": 295
        }
      ]
    },
    {
      "294": [
        {
          "text": "No knowledge whatever have they of Him, and neither had their forefathers: dreadful -is this saying that comes out of their mouths, [and] nothing but falsehood do they utter!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 5,
          "number": 2145,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "But wouldst thou, perhaps, torment thyself to death with grief over them if they are not willing to believe in this message?",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 6,
          "number": 2146,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "Behold, We have willed that all beauty on earth be a means by which We put men to a test, [showing] which of them are best in conduct;",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 7,
          "number": 2147,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "and, verily, [in time] We shall reduce all that is on it to barren dust!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 8,
          "number": 2148,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "[AND SINCE the life of this world is but a test, dost thou [really] think that [the parable of] the Men of the Cave and of [their devotion to] the scriptures could be deemed more wondrous than any [other] of Our messages?",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 9,
          "number": 2149,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "When those youths took refuge in the cave, they prayed: \"O our Sustainer! Bestow on us grace from Thyself, and endow us, whatever our [outward] condition, with consciousness of what is right!\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 10,
          "number": 2150,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "And thereupon We veiled their ears in the cave for many a year,",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 11,
          "number": 2151,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "and then We awakened Christianity was no longer persecuted and had even become the official religion of the Roman Empire. The ancient coin (dating from the reign of Decius) with which the young man wanted to pay for his purchases immediately aroused curiosity; people began to question the stranger, and the story of the Men of the Cave and their miraculous sleep came to light. them: [and We did all this] so that We might mark out [to the world] which of the two points of view showed a better comprehension of the time-span during which they had remained in this state.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 12,
          "number": 2152,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "[And now] We shall truly relate to thee their story: Behold, they were young men who had attained to faith in their Sustainer: and [so] We deepened their consciousness of the right way",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 13,
          "number": 2153,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "and endowed their hearts with strength, so that they stood up and said [to one another]: \"Our Sustainer is the Sustainer of the heavens and the earth. Never shall we invoke any deity other than Him: [if we did,] we should indeed have uttered an enormity!",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 14,
          "number": 2154,
          "juz": 15,
          "numpage": 294
        },
        {
          "text": "These people of ours have taken to worshipping [other] deities instead of Him, without being able to adduce any reasonable evidence in support of their beliefs; and who could be more wicked than he who invents a lie about God?",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 15,
          "number": 2155,
          "juz": 15,
          "numpage": 294
        }
      ]
    },
    {
      "293": [
        {
          "text": "AND AS a guide towards the truth have We bestowed this [revelation] from on high; with this [very] truth has it come down [unto thee, O Prophet]: for We have sent thee but as a herald of glad tidings and a warner,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 105,
          "number": 2134,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "[bearing] a discourse which We have gradually unfolded, so that thou might read it out to mankind by stages, seeing that We have bestowed it from on high step by step, as [one] revelation.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 106,
          "number": 2135,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "Say: \"Believe in it or do not believe.\" Behold, those who are already endowed with [innate] knowledge fall down upon their faces in prostration as soon as this [divine writ] is conveyed unto them,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 107,
          "number": 2136,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "and say, \"Limitless in His glory is our Sustainer! Verily, our Sustainer's promise has been fulfilled!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 108,
          "number": 2137,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "And so they fall down upon their faces, weeping, and [their consciousness of God's grace] increases their humility.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 109,
          "number": 2138,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "Say: \"Invoke God, or invoke the Most Gracious: by whichever name you invoke Him, [He is always the One-for] His are all the attributes of perfection. And [pray unto Him; yet] be not too loud in thy prayer nor speak it in too low a voice, but follow a way in-between;",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 110,
          "number": 2139,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "and say: \"All praise is due to God, who begets no offspring, and has no partner in His dominion, and has no weakness, and therefore no need of any aid\" -and [thus] extol His limitless greatness.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 111,
          "number": 2140,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "ALL PRAISE is due to God, who has bestowed. this divine writ from on high upon His servant, and has not allowed any deviousness to obscure its meaning:",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 1,
          "number": 2141,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "[a divine writ] unerringly straight, meant to warn [the godless] of a severe punishment from Him, and to give unto the believers who do good works the glad tiding that theirs shall be a goodly reward-",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 2,
          "number": 2142,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "[a state of bliss] in which they. shall dwell beyond the count of time.",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 3,
          "number": 2143,
          "juz": 15,
          "numpage": 293
        },
        {
          "text": "Furthermore, [this divine writ is meant] to warn all those who assert, \"God has taken unto Himself a son.\"",
          "englishName": "Al-Kahf",
          "suraNumber": 18,
          "numberInSurah": 4,
          "number": 2144,
          "juz": 15,
          "numpage": 293
        }
      ]
    },
    {
      "292": [
        {
          "text": "And he whom God guides, he alone has found the right way; whereas for those whom He lets go astray thou canst never find anyone to protect them from Him: and [so, when] We shall gather them together on the Day of Resurrection, [they will lie] prone upon their faces, blind and dumb and deaf, with hell as their goal; [and] every time [the fire] abates, We shall increase for them [its] blazing flame.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 97,
          "number": 2126,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "Such will be their requital for having rejected Our messages and having said, \"After we will have become bones and dust, shall we, forsooth, be raised from the dead in a new act of creation?\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 98,
          "number": 2127,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "Are they, then, not aware that God, who has created the heavens and the earth, has the power to create them anew in their own likeness, having, beyond any doubt, set a term for their resurrection? However, all [such] evildoers are unwilling to accept anything but blasphemy!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 99,
          "number": 2128,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "Say: \"If you were to own' all the treasure-houses of my Sustainer's bounty, lo! you would still try to hold on [to them] tightly for fear of spending [too much]: for man has always been avaricious [whereas God is limitless in His bounty].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 100,
          "number": 2129,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "AND, INDEED, We gave unto Moses nine clear messages. Ask, then, the children of Israel [to tell thee what happened] when he came unto them, [and appealed to Pharaoh,] and Pharaoh said unto him, \"Verily, O Moses, I think that thou art full of sorcery!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 101,
          "number": 2130,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "Answered [Moses]: \"Thou knowest well that none but the Sustainer of the heavens and the earth has bestowed these [miraculous signs] from on high, as a means of insight [for thee]; and, verily, O Pharaoh, [since thou hast chosen to reject them;] I think that thou art utterly lost!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 102,
          "number": 2131,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "And then Pharaoh resolved to wipe them off [the face of] the earth - whereupon We caused him and all who were with him to drown [in the sea].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 103,
          "number": 2132,
          "juz": 15,
          "numpage": 292
        },
        {
          "text": "And after that We said unto the children of Israel: \"Dwell now securely on earth - but [remember that] when the promise of the Last Day shall come to pass, We will bring you forth as [parts of] a motley crowd!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 104,
          "number": 2133,
          "juz": 15,
          "numpage": 292
        }
      ]
    },
    {
      "291": [
        {
          "text": "[Thou art spared] only by thy Sustainer's grace: behold, His favour towards thee is great indeed!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 87,
          "number": 2116,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "Say: \"If all mankind and all invisible beings would come together with a view to producing the like of this Qur'an, they could not produce its like even though they were to exert all their strength in aiding one another!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 88,
          "number": 2117,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "For, indeed, many facets have We given in this Qur'an to every kind of lesson [designed] for [the benefit of] mankind! However, most men are unwilling to accept anything but blasphemy",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 89,
          "number": 2118,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "and so they say: \"[O Muhammad,] we shall not believe thee till thou cause a spring to gush forth for us from the earth,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 90,
          "number": 2119,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "or thou have a garden of date-palms and vines and cause rivers to gush forth in their midst in a sudden rush,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 91,
          "number": 2120,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "or thou cause the skies to fall down upon us in smithereens, as thou hast threatened, or [till] thou bring God and the angels face to face before us,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 92,
          "number": 2121,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "or thou have a house [made] of gold, or thou ascend to heaven - but nay, we would not [even] believe in thy ascension unless thou bring down to us [from heaven] a writing which we [ourselves] could read! Say thou, [O Prophet:] \"Limitless in His glory is my Sustainer! Am I, then, aught but a mortal man, an apostle?\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 93,
          "number": 2122,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "Yet whenever [God's] guidance came to them [through a prophet,] nothing has ever kept people from believing [in him] save this their objection: \"Would God have sent a [mere] mortal man as His apostle?\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 94,
          "number": 2123,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "Say: \"If angels were walking about on earth as their natural abode, We would indeed have sent down unto them an angel out of heaven as Our apostle.\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 95,
          "number": 2124,
          "juz": 15,
          "numpage": 291
        },
        {
          "text": "Say: \"None can bear witness between me and you as God does: verily, fully aware is He of His creatures, and He sees all [that is in their hearts].\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 96,
          "number": 2125,
          "juz": 15,
          "numpage": 291
        }
      ]
    },
    {
      "290": [
        {
          "text": "And [since they see that they cannot persuade thee,] they endeavour to estrange thee from the land [of thy birth] with a view to driving thee away from, it-but, then, after thou wilt have left, they themselves will not remain [in it] for more than a little while:",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 76,
          "number": 2105,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "[such has been Our] way with all of Our apostles whom We sent before thy time; and no change wilt thou find in Our ways.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 77,
          "number": 2106,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "BE CONSTANT in [thy] prayer from the time when the sun has passed its zenith till the darkness of night, and [be ever mindful of its] recitation at dawn: for, behold, the recitation [of prayer] at dawn is indeed witnessed [by all that is holy].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 78,
          "number": 2107,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "And rise from thy sleep and pray during part of the night [as well], as a free offering from thee, and thy Sustainer may well raise thee to a glorious station [in the life to come].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 79,
          "number": 2108,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "And say [in thy prayer]: \"O my Sustainer! Cause me to enter [upon whatever I may do] in a manner. true and sincere, and cause me to leave [it] in a manner true and sincere, and grant me, out of Thy grace, sustaining strength!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 80,
          "number": 2109,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "And say: \"The truth has now come [to light], and falsehood has withered away: for, behold, all falsehood is bound to wither away!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 81,
          "number": 2110,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "THUS, step by step, We bestow from on high through this Qur'an all that gives health [to the spirit] and is a grace unto those who believe [in Us], the while it only adds to the ruin of evildoers:",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 82,
          "number": 2111,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "for [it often happens that] when We bestow Our blessings upon man, he turns away and arrogantly keeps aloof [from any thought of Us]; and when evil fortune touches him, he abandons all hope.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 83,
          "number": 2112,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "Say: \"Everyone acts in a manner peculiar to himself -and your Sustainer is fully aware as to who has chosen the best path.\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 84,
          "number": 2113,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "AND THEY will ask thee about [the nature of] divine inspiration. Say: \"This inspiration [comes] at my Sustainer's behest; and [you cannot understand its nature, O men, since] you have been granted very little of [real] knowledge.\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 85,
          "number": 2114,
          "juz": 15,
          "numpage": 290
        },
        {
          "text": "And if We so willed, We could indeed take away whatever We have revealed unto thee, and in that [state of need] thou wouldst find none to plead in thy behalf before Us.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 86,
          "number": 2115,
          "juz": 15,
          "numpage": 290
        }
      ]
    },
    {
      "289": [
        {
          "text": "And whenever danger befalls you at sea, all those (powers] that you are wont to invoke forsake you, [and nothing remains for you] save Him: but as soon as He has brought you safe ashore, you turn aside [and forget Him]-for, indeed, bereft of all gratitude is man!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 67,
          "number": 2096,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "Can you, then, ever feel sure that He will not cause a tract of dry land to swallow you up, or let loose upon you a deadly storm-wind, whereupon you would find none to be your protector?",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 68,
          "number": 2097,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "Or can you, perchance, feel sure that He will not make you put back to sea once again, and then let loose upon you a raging tempest and cause you to drown in requital of your ingratitude - whereupon you would find none to uphold you against Us?",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 69,
          "number": 2098,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "NOW, INDEED, We have conferred dignity on the children of Adam, and borne them over land and sea, and provided for them sustenance out of the good things of life, and favoured them far above most of Our creation:",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 70,
          "number": 2099,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "[but] one Day We shall summon all human beings [and judge them] according to the conscious disposition which governed their deeds [in life]: whereupon they whose record shall be placed in their right hand -it is they who will read their record [with happiness]. Yet none shall be wronged by as much as a hair's breadth:",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 71,
          "number": 2100,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "for whoever is blind [of heart] in this [world] will be blind in the life to come [as well], and still farther astray from the path [of truth].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 72,
          "number": 2101,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "AND,. behold, they [who have gone astray] endeavour to tempt thee away from all [the truth] with which We have inspired thee, [O Prophet,] with a view to making thee invent something else in Our name - in which case they would surely have made thee their friend!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 73,
          "number": 2102,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "And 'had We not made thee firm [in faith], thou might have inclined to them a. little",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 74,
          "number": 2103,
          "juz": 15,
          "numpage": 289
        },
        {
          "text": "in which case We would indeed have made thee taste double [chastisement] in life and double [chastisement] after death, and thou wouldst have found none to succour thee against Us!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 75,
          "number": 2104,
          "juz": 15,
          "numpage": 289
        }
      ]
    },
    {
      "288": [
        {
          "text": "And nothing has prevented Us from sending [this message, like the earlier ones,] with miraculous signs [in its wake], save [Our knowledge] that the people of olden times [only too often] gave the lie to them: thus, We provided for [the tribe of] Thamud the she-camel as a light-giving portent, and they sinned against it. And never did We send those signs for any other purpose than to convey a warning.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 59,
          "number": 2088,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "And lo! We said unto thee, [O Prophet:] \"Behold, thy Sustainer encompasses all mankind [within His knowledge and might]: and so We have ordained that the vision which We have shown thee -as also the tree [of hell,] cursed in this Qur'an - shall be but a trial for men. Now [by Our mentioning hell] We convey a warning to them: but [if they are bent on denying the truth,] this [warning] only increases their gross, overweening arrogance.\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 60,
          "number": 2089,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "AND LO! We said unto the angels, \"Prostrate yourselves before Adam\"-whereupon they all prostrated themselves, save Iblis. Said he: \"Shall I prostrate myself before one whom Thou hast created out of clay?\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 61,
          "number": 2090,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "[And] he added: \"Tell me, is this [foolish being] the one whom Thou hast exalted above me? Indeed, if Thou wilt but allow me a respite till the Day of Resurrection, I shall most certainly cause his descendants-all but a few-to obey me blindly!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 62,
          "number": 2091,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "[God] answered: \"Go [the way thou hast chosen]! But as for such of them as shall follow thee - behold, hell will be the recompense of you (all], a recompense most ample!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 63,
          "number": 2092,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "Entice, then, with thy voice such of them as thou canst, and bear upon them with all thy horses and all thy men, and be their partner in [all sins relating to] worldly goods and children, and hold out [all manner of] promises to them: and [they will not know that] whatever Satan promises them is but meant to delude the mind.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 64,
          "number": 2093,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "\"(And yet,] behold, thou shalt have no power over [such of] My servants [as place their trust in Me]: for none is as worthy of trust as thy Sustainer.\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 65,
          "number": 2094,
          "juz": 15,
          "numpage": 288
        },
        {
          "text": "YOUR SUSTAINER is He who causes ships to move onward for you through the sea, so that you might go about in quest of some of His bounty: verily, a dispenser of grace is He unto you.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 66,
          "number": 2095,
          "juz": 15,
          "numpage": 288
        }
      ]
    },
    {
      "287": [
        {
          "text": "Say: \"[You will be raised from the dead even though] you be stones or iron",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 50,
          "number": 2079,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "or any [other] substance which, to your minds, appears yet farther removed [from life]!\" And [if] thereupon they ask, \"Who is it that will' bring us back [to life]?\"-say thou: \"He who has brought you into being in the first instance.\" And [if] thereupon they shake their heads at thee [in disbelief] and ask, \"When shall this be?\"-say thou: \"It may well be soon,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 51,
          "number": 2080,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "on a Day when He will call you, and you will answer by praising Him, thinking all the while that you have tarried [on earth] but a little while.\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 52,
          "number": 2081,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "AND TELL My servants that they should speak in the most kindly manner [unto those who do not share their beliefs]: verily, Satan is always ready to stir up discord between men - for, verily, Satan is man's open foe!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 53,
          "number": 2082,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "Your Sustainer is fully aware of what you are [and what you deserve]: if He so wills, he will bestow [His] grace upon you; and if He so wills, He will chastise you. Hence, We have not sent thee [unto men, O Prophet,] with the power to determine their fate,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 54,
          "number": 2083,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "seeing that thy Sustainer is fully aware of [what is in the minds of] all beings that are in the heavens and on earth. But, indeed, We did endow some of the prophets more highly than others -just as We bestowed upon David a book of divine wisdom [in token of Our grace]",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 55,
          "number": 2084,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "SAY: \"Call upon those [beings] whom you imagine [to be endowed with divine powers] beside Him and [you will find that] they have it not in their power to remove any affliction from you, or to shift it [elsewhere]. \"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 56,
          "number": 2085,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "Those [saintly beings] whom they invoke are themselves striving to obtain their Sustainer's favour - [even] those among them who are closest [to Him] - hoping for His grace and dreading His chastisement: for, verily, thy Sustainer's chastisement is something to beware of!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 57,
          "number": 2086,
          "juz": 15,
          "numpage": 287
        },
        {
          "text": "And [bear in mind:] there is no community which We will not destroy before the Day of Resurrection, or chastise [even earlier, if it proves sinful,] with suffering severe: all this is laid down in Our decree.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 58,
          "number": 2087,
          "juz": 15,
          "numpage": 287
        }
      ]
    },
    {
      "286": [
        {
          "text": "this is part of that knowledge of right and wrong with which thy Sustainer has inspired thee. Hence, do not set up any other deity side by side with God, lest thou be cast into hell, blamed [by thyself] and rejected [by Him]!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 39,
          "number": 2068,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "HAS, THEN, your Sustainer distinguished you by [giving you] sons, and taken unto Himself daughters in the guise of angels? Verily, you are uttering a dreadful saying!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 40,
          "number": 2069,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "And, indeed, many facets have We given [to Our message] in this Qur'an, so that they [who deny the truth] might take it to heart: but all this only increases their aversion.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 41,
          "number": 2070,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "Say: \"If there were-as some people assert [other] deities side by side with Him, surely [even] they would have to strive to find a way unto Him who is enthroned on His almightiness?\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 42,
          "number": 2071,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "Limitless is He in His glory, and sublimely, immeasurably exalted above anything that men may say [about Him]",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 43,
          "number": 2072,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "The seven heavens extol His limitless glory, and the earth, and all that they contain; and there is not a single thing but extols His limitless glory and praise: but you [O men] fail to grasp the manner of their glorifying Him! Verily, He is forbearing, much-forgiving!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 44,
          "number": 2073,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "But [thus it is:] whenever thou recitest the Qur'an, We place an invisible barrier between thee and those who will not believe in the life to come: -",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 45,
          "number": 2074,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "for, over their hearts We have laid veils which prevent them from grasping its purport, and into their ears, deafness. And so, whenever thou dost mention, while reciting the Qur'an, thy Sustainer as the one and only Divine Being, they turn their backs [upon thee] in aversion.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 46,
          "number": 2075,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "We are fully aware of what they are listening for when they listen to thee: for when they are secluded among themselves, lo! these wrongdoers say [unto one another], \"If you were to follow [Muhammad, you would follow] only a man bewitched!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 47,
          "number": 2076,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "See to what they liken thee, [O Prophet simply] because they have gone astray and are now unable to find a way [to the truth]!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 48,
          "number": 2077,
          "juz": 15,
          "numpage": 286
        },
        {
          "text": "And [thus, too,] they say, \"After we will have become bones and dust, shall we, forsooth, be raised from the dead in a new act of creation?\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 49,
          "number": 2078,
          "juz": 15,
          "numpage": 286
        }
      ]
    },
    {
      "285": [
        {
          "text": "And if thou [must] turn aside from those [that are in want, because thou thyself art] seeking to obtain thy Sustainer's grace and hoping for it, at least speak unto them with gentle speech.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 28,
          "number": 2057,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And neither allow thy hand to remain shackled to thy neck, nor stretch it forth to the utmost limit [of thy capacity], lest thou find thyself blamed [by thy dependants], or even destitute.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 29,
          "number": 2058,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "Behold, thy Sustainer grants abundant sustenance, or gives it in scant measure, unto whomever He wills: verily, fully aware is He of [the needs of] His creatures, and sees them all.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 30,
          "number": 2059,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "Hence, do not kill your children for fear of poverty: it is We who shall provide sustenance for them as well as for you. Verily, killing them is a great sin.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 31,
          "number": 2060,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And do not commit adultery -for, behold, it is an abomination and an evil way.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 32,
          "number": 2061,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And do not take any human being's life -[the life] which God has willed to be, sacred-otherwise than in [the pursuit of] justice. Hence, if anyone has been slain wrongfully, We have empowered the defender of his rights [to exact a just retribution]; but even so, let him not exceed the bounds of equity in [retributive] killing. [And as for him who has been slain wrongfully -] behold, he is indeed succoured [by God]!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 33,
          "number": 2062,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And do not touch the substance of an orphan, save to improve it, before he comes of age. And be true to every promise - for, verily, [on Judgment Day] you will be called to account for every promise which you have made!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 34,
          "number": 2063,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And give full measure whenever you measure, and weigh with a balance that is true: this will be [for your own] good, and best in the end.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 35,
          "number": 2064,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And never concern thyself with anything of which thou hast no knowledge: verily, [thy] hearing and sight and heart - all of them - will be called to account for it [on Judgment Day]!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 36,
          "number": 2065,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "And walk not on earth with haughty self-conceit: for, verily, thou canst never rend the earth asunder, nor canst thou ever grow as tall as the mountains!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 37,
          "number": 2066,
          "juz": 15,
          "numpage": 285
        },
        {
          "text": "The evil of all this is odious in thy Sustainer's sight:",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 38,
          "number": 2067,
          "juz": 15,
          "numpage": 285
        }
      ]
    },
    {
      "284": [
        {
          "text": "Unto him who cares for [no more than the enjoyment of] this fleeting life We readily grant thereof as much as We please, [giving] to whomever it is Our will [to give]; but in the end We consign him to [the suffering of] hell; which he will have to endure disgraced and disowned!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 18,
          "number": 2047,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "But as for those who care for the [good of the] life to come, and strive for it as it ought to be striven for, and are [true] believers withal -they are the ones whose striving finds favour [with God]!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 19,
          "number": 2048,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "All [of them] -these as well as those -do We freely endow with some of thy Sustainer's gifts, since thy Sustainer's giving is never confined [to one kind of man].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 20,
          "number": 2049,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "Behold how We bestow [on earth) more bounty on some of them than on others: but [remember that] the life to come will be far higher in degree and far greater in merit and bounty.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 21,
          "number": 2050,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "DO NOT set up any other deity side by side with God, lest thou find thyself disgraced and forsaken:",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 22,
          "number": 2051,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "for thy Sustainer has ordained that you shall worship none but Him. And do good unto [thy] parents. Should one of them, or both, attain to old age in thy care, never say \"Ugh\" to them or scold them, but [always] speak unto them with reverent speech,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 23,
          "number": 2052,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "and spread over them humbly the wings of thy tenderness, and say: \"O my Sustainer! Bestow Thy grace upon them, even as they cherished and reared me when I was a child!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 24,
          "number": 2053,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "Your Sustainer is fully aware of what is in your hearts. If you are righteous, [He will forgive you your errors]: for, behold, He is much-forgiving to those who turn unto Him again and again.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 25,
          "number": 2054,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "And give his due to the near of kin, as well as to the needy and the wayfarer, but do not squander [thy substance] senselessly.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 26,
          "number": 2055,
          "juz": 15,
          "numpage": 284
        },
        {
          "text": "Behold, the squanderers are, indeed, of the ilk of the satans - inasmuch as Satan has indeed proved most ungrateful to his Sustainer.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 27,
          "number": 2056,
          "juz": 15,
          "numpage": 284
        }
      ]
    },
    {
      "283": [
        {
          "text": "Your Sustainer may well show mercy unto you; but if you revert [to sinning], We shall revert [to chastising you]. And [remember this:] We have ordained that [in the hereafter] hell shall close upon all who deny the truth.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 8,
          "number": 2037,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "VERILY, this Qur'an shows the way to all that is most upright, and gives the believers who do good deeds the glad tiding that theirs will be a great reward;",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 9,
          "number": 2038,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "and [it announces, too,] that We have readied grievous suffering for those who will not believe in the life to come.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 10,
          "number": 2039,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "As it is, man [often] prays for things that are bad as if he were praying for something that is good: for man is prone to be hasty [in his judgments].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 11,
          "number": 2040,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "And We have established the night and the day as two symbols; and thereupon We have effaced the symbol of night and set up [in its place] the light giving symbol of day, so that you might seek to obtain your Sustainer's bounty and be aware of the passing years and of the reckoning [that is bound to come]. For clearly, most clearly, have We spelt out everything!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 12,
          "number": 2041,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "And every human being's destiny have We tied to his neck; and on the Day of Resurrection We shall bring forth for him a record which he will find wide open;",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 13,
          "number": 2042,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "[and he will be told:] \"Read this thy record,! Sufficient is thine own self today to make out thine account!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 14,
          "number": 2043,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "Whoever chooses to follow the right path, follows it but for his own good; and whoever goes astray, goes but astray to his own hurt; and no bearer of burdens shall be made to bear another\" burden. Moreover. We would never chastise [any community for the wrong they may do] ere We have sent an apostle [to them].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 15,
          "number": 2044,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "But when [this has been done, and] it is Our will to destroy a community, We convey Our last warning to those of its people who have lost themselves entirely in the pursuit of pleasures; and [if] they [continue to] act sinfully, the sentence [of doom] passed on the community takes effect, and We break it to smithereens.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 16,
          "number": 2045,
          "juz": 15,
          "numpage": 283
        },
        {
          "text": "And how many a generation have We [thus] destroyed after [the time of] Noah! For, none has the like of thy Sustainer's awareness and insight into His creatures' sins.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 17,
          "number": 2046,
          "juz": 15,
          "numpage": 283
        }
      ]
    },
    {
      "282": [
        {
          "text": "LIMITLESS in His glory is He who transported His servant by night from the Inviolable House of Worship [at Mecca] to the Remote House of Worship [, at Jerusalem] - the environs of which We had blessed -so that We might show him some of Our symbols: for, verily, He alone is all-hearing, all-seeing.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 1,
          "number": 2030,
          "juz": 15,
          "numpage": 282
        },
        {
          "text": "And [thus, too,] We vouchsafed revelation unto Moses, and made it a [source of] guidance for the children of Israel, [commanding them:] \"Do not ascribe to any but Me the power to determine your fate,",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 2,
          "number": 2031,
          "juz": 15,
          "numpage": 282
        },
        {
          "text": "O you descendants of those whom We caused to be borne (in the ark] with Noah! Behold, he was a most grateful servant (of Ours]!\"",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 3,
          "number": 2032,
          "juz": 15,
          "numpage": 282
        },
        {
          "text": "And we made [this] known to the children of Israel through revelation: Twice, indeed, will you spread corruption on earth and will indeed become grossly overbearing!",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 4,
          "number": 2033,
          "juz": 15,
          "numpage": 282
        },
        {
          "text": "Hence, when the prediction of the first of those two [periods of iniquity] came true, We sent against you some of Our bondmen of terrible prowess in war, and they wrought havoc throughout the land: and so the prediction was fulfilled.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 5,
          "number": 2034,
          "juz": 15,
          "numpage": 282
        },
        {
          "text": "And after a time We allowed you to prevail against them once again, and aided you with wealth and offspring, and made you more numerous [than ever].",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 6,
          "number": 2035,
          "juz": 15,
          "numpage": 282
        },
        {
          "text": "[And We said:] \"If you persevere in doing good, you will but be doing good to yourselves; and if you do evil, it will be [done] to yourselves.\" And so, when the prediction of the second [period of your iniquity] came true, [We raised new enemies against you, and allowed them] to disgrace you utterly, and to enter the Temple as [their forerunners] had entered it once before, and to destroy with utter destruction all that they had conquered.",
          "englishName": "Al-Israa",
          "suraNumber": 17,
          "numberInSurah": 7,
          "number": 2036,
          "juz": 15,
          "numpage": 282
        }
      ]
    },
    {
      "281": [
        {
          "text": "And once again: Behold, thy Sustainer [shows mercy] to those who do evil out of ignorance and afterwards repent and live righteously: behold, after such [repentance] thy Sustainer is indeed much forgiving, a dispenser of grace.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 119,
          "number": 2020,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "VERILY, Abraham was a man who combined within himself all virtues, devoutly obeying God's will, turning away from all that is false, and not being of those who ascribe divinity to aught beside God:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 120,
          "number": 2021,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "[for he was always] grateful for the blessings granted by Him who had elected him and guided him onto a straight way.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 121,
          "number": 2022,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "And so We vouchsafed him good in this world; and, verily, in the life to come [too] he shall find himself among the righteous.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 122,
          "number": 2023,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "And lastly, We have inspired thee, [O Muhammad, with this message:] \"Follow the creed of Abraham, who turned away from all that is false, and was not of those who ascribe divinity to aught beside God;",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 123,
          "number": 2024,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "[and know that the observance of] the Sabbath was ordained only for those who came to hold divergent views about him; but, verily, God will judge between them on Resurrection Day with regard to all on which they were wont to differ.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 124,
          "number": 2025,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "CALL THOU (all mankind] unto thy Sustainer's path with wisdom and goodly exhortation, and argue with them in the most kindly manner- for, behold, thy Sustainer knows best as to who strays from His path, and best knows He as to who are the right-guided.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 125,
          "number": 2026,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "Hence, if you have to respond to an attack (in argument], respond only to the extent of the attack levelled against you; but to bear yourselves with patience is indeed far better for (you, since God is with] those who are patient in adversity.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 126,
          "number": 2027,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "Endure, then, with patience (all that they who deny the truth may say] -always remembering that it is none but God who gives thee the strength to endure adversity -and do not grieve over them, and neither be distressed by the false arguments which they devise:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 127,
          "number": 2028,
          "juz": 14,
          "numpage": 281
        },
        {
          "text": "for, verily, God is with those who are conscious of Him and are doers of good withal!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 128,
          "number": 2029,
          "juz": 14,
          "numpage": 281
        }
      ]
    },
    {
      "280": [
        {
          "text": "[Be conscious, then, of] the Day when every human being shall come to plead for himself [alone], and every human being shall be repaid in full for whatever he has done, and none shall be wronged.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 111,
          "number": 2012,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "AND GOD propounds [to you] a parable: [Imagine] a town which was [once] secure and at ease, with its sustenance coming to it abundantly from all quarters, and which thereupon blasphemously refused to show gratitude for God's blessings: and therefore God caused it to taste the all-embracing misery of hunger and fear in result of all [the evil] that its people had so persistently wrought.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 112,
          "number": 2013,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "And indeed, there had come unto them an apostle from among themselves -but they gave him the lie; and therefore suffering overwhelmed them while they were thus doing wrong [to themselves].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 113,
          "number": 2014,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "AND SO, partake of all the lawful, good things which God has provided for you as sustenance, and render thanks unto God for His blessings, if it is [truly] Him that you worship.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 114,
          "number": 2015,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "He has forbidden to you only carrion, and blood, and the flesh of swine, and that over which any name other than God's has been invoked; but if one is driven [to it] by necessity - neither coveting it nor exceeding his immediate need -verily, God is much forgiving, a dispenser of grace.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 115,
          "number": 2016,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "Hence, do not utter falsehoods by letting your tongues determine [at your own discretion], \"This is lawful and that is forbidden\", thus attributing your own lying inventions to God: for, behold, they who attribute their own lying inventions to God will never attain to a happy state!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 116,
          "number": 2017,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "A brief enjoyment [may be theirs in this world] -but grievous suffering awaits them [in the life to come]!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 117,
          "number": 2018,
          "juz": 14,
          "numpage": 280
        },
        {
          "text": "And [only] unto those who followed the Jewish faith did We forbid all that We have mentioned to thee ere this; and no wrong did We do to them, but it was they who persistently wronged themselves.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 118,
          "number": 2019,
          "juz": 14,
          "numpage": 280
        }
      ]
    },
    {
      "279": [
        {
          "text": "And, indeed, full well do We know that they say, \"It is but a human being that imparts [all] this to him! -[notwithstanding that] the tongue of him to whom they so maliciously point is wholly outlandish, whereas this is Arabic speech, clear [in itself] and clearly showing the truth [of its source].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 103,
          "number": 2004,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "Verily, as for those who will not believe in God's messages, God does not guide them aright; and grievous suffering will be their lot [in the life to come].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 104,
          "number": 2005,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "It is but they who will not believe in God's messages that invent this falsehood; and it is they, they who are lying!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 105,
          "number": 2006,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "As for anyone who denies God after having once attained to faith-and this, to be sure, does not apply to one who does it under duress, the while his heart remains true to his faith, but [only, to] him who willingly opens up his heart to a denial of the truth-: upon all such [falls] God's condemnation, and tremendous suffering awaits them:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 106,
          "number": 2007,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "all this, because they hold this world's life in greater esteem than the life to come, and because God does not bestow His guidance upon people who deny the truth.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 107,
          "number": 2008,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "They whose hearts and whose hearing and whose sight God has sealed -it is they, they who are heedless!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 108,
          "number": 2009,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "Truly it is they, they who in the life to come shall be the losers!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 109,
          "number": 2010,
          "juz": 14,
          "numpage": 279
        },
        {
          "text": "And yet, behold, thy Sustainer [grants His forgiveness] unto those who forsake the domain of evil after having succumbed to its temptation, and who thenceforth strive hard [in God's cause] and are patient in adversity: behold, after such [repentance] thy Sustainer is indeed much-forgiving, a dispenser of grace!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 110,
          "number": 2011,
          "juz": 14,
          "numpage": 279
        }
      ]
    },
    {
      "278": [
        {
          "text": "And do not use your oaths as a means of deceiving one another-or else [your] foot will slip after having been firm, and then you will have to taste the evil [consequences] of your having turned away from the path of God, with tremendous suffering awaiting you [in the life to come].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 94,
          "number": 1995,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "Hence, do not barter away your bond with God for a trifling gain! Verily, that which is with God is by far the best for you, if you but knew it:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 95,
          "number": 1996,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "all that is with you is bound to come to an end, whereas that which is with God is everlasting. And most certainly shall We grant unto those who are patient in adversity their reward in accordance with the best that they ever did.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 96,
          "number": 1997,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "As for anyone - be it man or woman - who does righteous deeds, and is a believer withal - him shall We most certainly cause to live a good life. and most certainly shall We grant unto such as these their reward in accordance with the best that they ever did.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 97,
          "number": 1998,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "NOW whenever thou happen to read this Qur'an, seek refuge with God from Satan, the accursed.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 98,
          "number": 1999,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "Behold, he has no power over those who have attained to faith and in their Sustainer place their tray trust:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 99,
          "number": 2000,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "he has power only over those who are willing to follow him, and who [thus] ascribe to him a share in God's divinity.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 100,
          "number": 2001,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "And now that We replace one message by another -since God is fully aware of what He bestows from on high, step by step - they [who deny the truth] are wont to say, \"Thou but inventest it!\" Nay, but most of them do not understand it!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 101,
          "number": 2002,
          "juz": 14,
          "numpage": 278
        },
        {
          "text": "Say: \"Holy inspiration has brought it down from thy Sustainer by stages, setting forth the truth, so that it might give firmness unto those who have attained to faith, and provide guidance and a glad tiding unto all who have surrendered themselves to God.\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 102,
          "number": 2003,
          "juz": 14,
          "numpage": 278
        }
      ]
    },
    {
      "277": [
        {
          "text": "Upon all who were bent on denying the truth and who turned others away from the path of God will We heap suffering upon suffering in return for all the corruption that they wrought:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 88,
          "number": 1989,
          "juz": 14,
          "numpage": 277
        },
        {
          "text": "for one Day We shall raise up within every community a witness against them from among themselves. And thee [too, O Prophet,] have We brought forth to bear witness regarding those [whom thy message may have reached], inasmuch as We have bestowed from on high upon thee, step by step, this divine writ, to make everything clear, and to provide guidance and grace and a glad tiding unto all who have surrendered themselves to God.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 89,
          "number": 1990,
          "juz": 14,
          "numpage": 277
        },
        {
          "text": "BEHOLD, God enjoins justice, and the doing of good, and generosity towards [one's] fellow-men; and He forbids all that is shameful and all that runs counter to reason, as well as envy; [and] He exhorts you [repeatedly] so that you might bear [all this] in mind.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 90,
          "number": 1991,
          "juz": 14,
          "numpage": 277
        },
        {
          "text": "And be true to your bond with God whenever you bind yourselves by a pledge, and do not break [your] oaths after having [freely] confirmed them and having called upon God to be witness to your good faith: behold, God knows all that you do.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 91,
          "number": 1992,
          "juz": 14,
          "numpage": 277
        },
        {
          "text": "Hence, be not like her who breaks and completely untwists the yarn which she [herself] has spun and made strong-[be not like this by] using your oaths as a means of deceiving one another, simply because some of you may be more powerful than others.\" By all this, God but puts you to a test-and [He does it] so that on Resurrection Day He might make clear unto you all that on which you were wont to differ.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 92,
          "number": 1993,
          "juz": 14,
          "numpage": 277
        },
        {
          "text": "For, had God so willed, He could surely have made you all one single community; however, He lets go astray him that wills [to go astray], and guides aright him that wills [to be guided]; and you will surely be called to account for all that you ever did!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 93,
          "number": 1994,
          "juz": 14,
          "numpage": 277
        }
      ]
    },
    {
      "276": [
        {
          "text": "And God has given you [the ability to build] your houses as places of rest, and has endowed you with [the skill to make] dwellings out of the skins of animals - easy for you to handle when you travel and when you camp -and [to make] furnishings and goods for temporary use of their [rough] wool and their soft, furry wool and their hair.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 80,
          "number": 1981,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "And among the many objects of His creation, God has appointed for you [various] means of protection: thus, He has given you in the mountains places of shelter, and has endowed you with [the ability to make] garments to protect you from heat [and cold], as well as such garments as might protect you from your [mutual] violence. In this way does He bestow the full measure of His blessings on you, so that you might surrender yourselves unto Him.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 81,
          "number": 1982,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "BUT IF they turn away [from thee, O Prophet, remember that] thy only duty is a clear delivery of the message [entrusted to thee].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 82,
          "number": 1983,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "They [who turn away from it] are fully aware of God's blessings, but none the less they refuse to acknowledge them [as such], since most of them are given to denying the truth.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 83,
          "number": 1984,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "But one Day We shall raise up a witness out of every community, whereupon they who were bent on denying the truth will not be allowed to plead [ignorance], and neither will they be allowed to make amends.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 84,
          "number": 1985,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "And when they who were bent on evildoing behold the suffering [that awaits them, they will realize that] it will not be lightened for them [by virtue of their pleading]; and neither will they be granted respite.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 85,
          "number": 1986,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "And when they who were wont to ascribe divinity to beings other than God behold [on Judgment Day] those beings to whom they were wont to ascribe a share in His divinity, they will exclaim: \"O our Sustainer! These are the beings to whom we ascribed a share in Thy divinity, and whom we were wont to invoke instead of Thee!\" -whereupon [those beings] will fling at them the retort: \"Behold, you have indeed been lying [to yourselves]!\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 86,
          "number": 1987,
          "juz": 14,
          "numpage": 276
        },
        {
          "text": "And on that Day will they [who had thus been sinning, belatedly] proffer their surrender to God; and all their false imagery will have forsaken them.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 87,
          "number": 1988,
          "juz": 14,
          "numpage": 276
        }
      ]
    },
    {
      "275": [
        {
          "text": "and will they [continue to] worship, instead of God, something that has it not within its power to provide for them any sustenance whatever from the heavens or the earth, and can do nothing at all?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 73,
          "number": 1974,
          "juz": 14,
          "numpage": 275
        },
        {
          "text": "Hence, do not coin any similitudes for Gods Verily, God knows [all], whereas you have no [real] knowledge.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 74,
          "number": 1975,
          "juz": 14,
          "numpage": 275
        },
        {
          "text": "God propounds [to you] the parable of [two men-] a man enslaved, unable to do anything of his own accord, and a [free] man upon whom We have bestowed goodly sustenance [as a gift] from Ourselves, so that he can spend thereof [at will, both] secretly and openly. Can these [two] be deemed equal? All praise is due to God [alone]: but most of them do not understand it.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 75,
          "number": 1976,
          "juz": 14,
          "numpage": 275
        },
        {
          "text": "And God propounds [to you] the parable of two [other] men -one of them dumb, unable to do anything of his own accord, and a sheer burden on his master: to whichever task the latter directs him, he accomplishes no good. Can such a one be considered the equal of [a wise man] who enjoins the doing of what is right and himself follows a straight way?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 76,
          "number": 1977,
          "juz": 14,
          "numpage": 275
        },
        {
          "text": "And, God's [alone] is the knowledge of the hidden reality of the heavens and the earth. And so, the advent of the Last Hour will but manifest itself [in a single moment,] like the twinkling of an eye, or closer still: for, behold, God has the power to will anything.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 77,
          "number": 1978,
          "juz": 14,
          "numpage": 275
        },
        {
          "text": "And God has brought you forth from your mothers' wombs knowing nothing-but He has endowed you with hearing, and sight, and minds, so that you might have cause to be grateful.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 78,
          "number": 1979,
          "juz": 14,
          "numpage": 275
        },
        {
          "text": "Have, then, they [who deny the truth] never considered the birds, enabled [by God] to fly in mid-air, with none but God holding them aloft? In this, behold, there are messages indeed for people who will believe!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 79,
          "number": 1980,
          "juz": 14,
          "numpage": 275
        }
      ]
    },
    {
      "274": [
        {
          "text": "AND GOD sends down water from the skies, giving life thereby to the earth after it had been lifeless: in this, behold, there is a message indeed for people who [are willing to] listen.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 65,
          "number": 1966,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "And, behold, in the cattle [too] there is indeed a lesson for you: We give you to drink of that [fluid] which is [secreted from] within their bellies between that which is to be eliminated [from the animal's body] and [its] life-blood: milk pure and pleasant to those who drink it.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 66,
          "number": 1967,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "And [We grant you nourishment] from the fruit of date-palms and vines: from it you derive intoxicants as well as wholesome sustenance -in this, behold, there is a message indeed for people who use their reason!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 67,
          "number": 1968,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "And [consider how] thy Sustainer has inspired the bee: \"Prepare for thyself dwellings in mountains and in trees, and in what [men] may build [for thee by way of hives];",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 68,
          "number": 1969,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "and then eat of all manner of fruit, and follow humbly the paths ordained for thee by thy Sustainer.\" [And lo!] there issues from within these [bees] a fluid of many hues, wherein there is health for man. In all this, behold, there is a message indeed for people who think!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 69,
          "number": 1970,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "AND GOD has created you, and in time will cause you to die; and many a one of you is reduced in old age to a most abject state, ceasing to know anything of what he once knew so well. Verily, God is all-knowing, infinite in His power!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 70,
          "number": 1971,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "And on some of you God has bestowed more abundant means of sustenance than on others: and yet, they who are more abundantly favoured are [often] unwilling to share their sustenance with those whom their right hands possess, so that they [all] might be equal in this respect. Will they, then, God's blessings [thus] deny?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 71,
          "number": 1972,
          "juz": 14,
          "numpage": 274
        },
        {
          "text": "And God has given you mates of your own kinds and has given you, through your mates, children and children's children, and has provided for you sustenance out of the good things of life. Will men, then, [continue to] believe in things false and vain, and thus blaspheme against God's blessings? -",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 72,
          "number": 1973,
          "juz": 14,
          "numpage": 274
        }
      ]
    },
    {
      "273": [
        {
          "text": "[as if] to prove their ingratitude for all that We have granted them! Enjoy, then, your [brief] life: but in time you will come to know [the truth]!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 55,
          "number": 1956,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "As it is, they ascribe - out of what We provide for them as sustenance - a share unto things of which they know nothing. By God, you shall most certainly be called to account for all your false imagery!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 56,
          "number": 1957,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "And [thus, too,] they ascribe daughters unto God, who is limitless in His glory - whereas for themselves [they would choose, if they could, only] what they desire:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 57,
          "number": 1958,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "for, whenever any of them is given the glad tiding of [the birth of] a girl, his face darkens, and he is filled with suppressed anger,",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 58,
          "number": 1959,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "avoiding all people because of the [alleged] evil of the glad tiding which he has received, [and debating within himself:] Shall he keep this [child] despite the contempt [which he feels for it]-or shall he bury it in the dust? Old, evil indeed is whatever they decide!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 59,
          "number": 1960,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "[Thus it is that] the attribute of evil applies to all who do not believe in the life to come -whereas unto God applies the attribute of all that is most sublime: for He alone is almighty, truly wise!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 60,
          "number": 1961,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "Now if God were to take men [immediately] to task for all the evil that they do [on earth], He would not leave a single living creature upon its face. However, He grants them respite until a term set [by Him]: but when the end of their term approaches, they can neither delay it by a single moment, nor can they hasten it.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 61,
          "number": 1962,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "As it is, they ascribe to God something that they [themselves] dislike -and [all the while] their tongues utter the lie that [by doing so] they earn supreme merit! Truly, they earn but the fire, and will be left out [of God's grace]!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 62,
          "number": 1963,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "By God, [O Prophet,] even before thy time have We sent apostles unto [various] communities: but [those who were bent on denying the truth have always refused to listen to Our messages because] Satan has made all their own doings seem goodly to them: and he is [as] close to them today [as he was to the sinners of yore]; hence, grievous suffering awaits them.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 63,
          "number": 1964,
          "juz": 14,
          "numpage": 273
        },
        {
          "text": "And upon thee [too] have We bestowed from on' high this divine writ for no other reason than that thou might make clear unto them all [questions of faith] on which they have come to hold divergent views, and [thus offer] guidance and grace unto people who will believe.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 64,
          "number": 1965,
          "juz": 14,
          "numpage": 273
        }
      ]
    },
    {
      "272": [
        {
          "text": "AND [even] before thy time, [O Muhammad,] We never sent [as Our apostles] any but [mortal] men, whom We inspired: and if you have not [yet] realized this, ask the followers of [earlier] revelation,\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 43,
          "number": 1944,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "[and they will tell you that their prophets, too, were but mortal men whom We had endowed] with all evidence of the truth and with books of divine wisdom. And upon thee [too] have We bestowed from on high this reminder, so that thou might make clear unto mankind all that has ever been thus bestowed upon them, and that they might take thought.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 44,
          "number": 1945,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "Can, then, they who devise evil schemes ever feel sure that God will not cause the earth to swallow them, or that suffering will not befall them without their perceiving whence [it came]? -",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 45,
          "number": 1946,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "or that He will not take them to task [suddenly] in the midst of their comings and goings, without their being able to elude [Him],",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 46,
          "number": 1947,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "or take them to task through slow decay? And yet, behold, your Sustainer is most compassionate, a dispenser of grace!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 47,
          "number": 1948,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "HAVE, THEN, they [who deny the truth] never considered any of the things that God has created _ (how] their shadows turn right and left, prostrating themselves before God and utterly submissive [to His will]?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 48,
          "number": 1949,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "For, before God prostrates itself all that is in the heavens and all that is on earth - every beast that moves, and the angels: [even] these do not bear themselves with false pride:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 49,
          "number": 1950,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "they fear their Sustainer high above them, and do whatever they are bidden to do.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 50,
          "number": 1951,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "And God has said: \"Do not take to worshipping two [or more] deities. He is the One and Only God: hence, of Me, of Me alone stand in awe!\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 51,
          "number": 1952,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "And His is all that is in the heavens and on earth, and to Him [alone] obedience is always due: will you, then, pay reverence to aught but Him?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 52,
          "number": 1953,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "For, whatever good thing comes to you, comes from God; and whenever harm befalls you, it is unto Him that you cry for help",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 53,
          "number": 1954,
          "juz": 14,
          "numpage": 272
        },
        {
          "text": "yet as soon as He has removed the harm from you, lo! some of you [begin to] ascribe to other powers a share 'in their Sustainer's divinity",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 54,
          "number": 1955,
          "juz": 14,
          "numpage": 272
        }
      ]
    },
    {
      "271": [
        {
          "text": "Now they who ascribe divinity to aught beside God say, \"Had God so willed, we would not have worshipped aught but Him - neither we nor our forefathers; nor would we have declared aught as forbidden without a commandment from Him.\" Even thus did speak those [sinners] who lived before their time; but, then, are the apostles bound to do more than clearly deliver the message [entrusted to them]?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 35,
          "number": 1936,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "And indeed, within every community have We raised up an apostle [entrusted with this message]: \"Worship God, and shun the powers of evil!\" And among those [past generations] were people whom God graced with His guidance, just as there was among them [many a one] who inevitably fell prey to grievous error: go, then, about the earth and behold what happened in the end to those who gave the lie to the truth!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 36,
          "number": 1937,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "[As for those who are bent on denying the truth-] though thou be ever so eager to show them the right way, [know that,] verily, God does not bestow His guidance upon any whom He judges to have gone astray; and such shall have none to succour them [on Resurrection Day].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 37,
          "number": 1938,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "As it is, they swear by God with their most solemn oaths, \"Never will God raise from the dead anyone who has died!\" Yea indeed! [This very thing has God promised] by a promise which He has willed upon Himself; but most people know it not.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 38,
          "number": 1939,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "[He will resurrect them] to the end that He might make clear unto them all whereon they [now] hold divergent views, and that they who are bent on denying the truth [of resurrection] might come to know that they were liars.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 39,
          "number": 1940,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "Whenever We will anything to be, We but say unto it Our word \"Be\" -and it is.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 40,
          "number": 1941,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "NOW as for those who forsake the domain of evil in the cause of God, after having suffered wrong [on account of their faith] - We shall most certainly grant them a station of good fortune in this world: but their reward in the life to come will be far greater yet. If they [who deny the truth] could but understand",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 41,
          "number": 1942,
          "juz": 14,
          "numpage": 271
        },
        {
          "text": "those who, having attained to patience in adversity, in their Sustainer place their trust!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 42,
          "number": 1943,
          "juz": 14,
          "numpage": 271
        }
      ]
    },
    {
      "270": [
        {
          "text": "And then, on Resurrection Day, He will cover them [all] with ignominy, and will say: \"Where, now, are those beings to whom you ascribed a share in My divinity, [and] for whose sake you cut yourselves off [from My guidance]?\" [Whereupon] those who [in their lifetime] were endowed with knowledge will say: \"Verily, ignominy and misery [have fallen] this day upon those who have been denying the truth-",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 27,
          "number": 1928,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "those whom the angels have gathered in death while they were still sinning against themselves!\" Then will they [who are thus arraigned] proffer their submission, [saying:] \"We did not [mean to] do any evil! [But they will be answered:] \"Yea, verily, God has full knowledge of all that you were doing!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 28,
          "number": 1929,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "Hence, enter the gates of hell, therein to abide!\" And evil, indeed, shall be the state of all who are given to false pride!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 29,
          "number": 1930,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "But [when] those who are conscious of God are asked, \"What is it that your Sustainer has bestowed from on high?\" -they answer, \"Goodness supreme!\" Good fortune awaits, in this world, all who persevere in doing good; but their ultimate state will be far better still: for, how excellent indeed will be the state of the God-conscious [in the life to come]!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 30,
          "number": 1931,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "Gardens of perpetual bliss will they enter - [gardens] through which running waters flow - having therein all that they might desire. Thus will God reward those who are conscious of Him-",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 31,
          "number": 1932,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "those whom the angels gather in death while they are in a state of inner purity, greeting them thus: \"Peace be upon you! Enter paradise by virtue of what you were doing [in life]!\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 32,
          "number": 1933,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "ARE THEY [who deny the truth] but waiting for the angels to appear unto them, or for God's judgment to become manifest? Even thus did behave those [stubborn sinners] who lived before their time; and [when they were destroyed,] it was not God who wronged them, but it was they who had wronged themselves:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 33,
          "number": 1934,
          "juz": 14,
          "numpage": 270
        },
        {
          "text": "for all the evil that they had done fell [back] upon them, and they were overwhelmed by the very thing which they had been wont to deride.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 34,
          "number": 1935,
          "juz": 14,
          "numpage": 270
        }
      ]
    },
    {
      "269": [
        {
          "text": "And he has placed firm mountains on earth, lest it sway with you, and rivers and paths, so that you might find your way,",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 15,
          "number": 1916,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "as well as [various other] means of orientation: for [it is] by the stars that men find their way.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 16,
          "number": 1917,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "IS, THEN, HE who creates comparable to any [being] that cannot create? Will you not, then, bethink yourselves?",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 17,
          "number": 1918,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "For, should you try to count God's blessings, you could never compute them! Behold, God is indeed much-forgiving, a dispenser of grace;",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 18,
          "number": 1919,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "and God knows all that you keep secret as well as all that you bring into the open.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 19,
          "number": 1920,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "Now those beings that some people invoke beside God cannot create anything, since they themselves are but created:",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 20,
          "number": 1921,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "they are dead, not living, and they do not [even] know when they will be raised from the dead!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 21,
          "number": 1922,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "Your God is the One God: but because of their false pride, the hearts of those who do not believe in the life to come refuse to admit this [truth].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 22,
          "number": 1923,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "Truly, God knows all that they keep secret as well as all that they bring into the open -[and,] behold, He does not love those who are given to arrogance,",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 23,
          "number": 1924,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "and [who], whenever they are asked, \"What is it that your Sustainer has bestowed from on high?\"-are wont to answer, \"Fables of ancient times!\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 24,
          "number": 1925,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "Hence, on Resurrection Day they shall bear the full weight of their own burdens, as well as some of the burdens of those ignorant ones whom they have led astray: oh, how evil the load with which they shall be burdened!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 25,
          "number": 1926,
          "juz": 14,
          "numpage": 269
        },
        {
          "text": "Those who lived before them did, too, devise many a blasphemy -whereupon God visited with destruction all that they had ever built, [striking] at its very foundations, so that the roof fell in upon them from above and suffering befell them without their having perceived whence it came.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 26,
          "number": 1927,
          "juz": 14,
          "numpage": 269
        }
      ]
    },
    {
      "268": [
        {
          "text": "And they carry your loads to [many] a place which [otherwise] you would be unable to reach without great hardship to yourselves. Verily, your Sustainer is most compassionate, a dispenser of grace!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 7,
          "number": 1908,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "And (it is He who creates] horses and mules and asses for you to ride, as well as for [their] beauty: and He will yet create things of which [today] you have no knowledge.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 8,
          "number": 1909,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "And [because He is your Creator,] it rests with God alone to show you the right path: yet there is [many a one] who swerves from it. However, had He so willed, He would have guided you all aright.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 9,
          "number": 1910,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "It is He who sends down water from the skies; you drink thereof, and thereof [drink] the plants upon which you pasture your beasts;",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 10,
          "number": 1911,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "[and] by virtue thereof He causes crops to grow for you, and olive trees, and date palms, and grapes, and all [other] kinds of fruit: in this, behold, there is a message indeed for people who think!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 11,
          "number": 1912,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "And He has made the night and the day and the sun and the moon subservient [to His laws, so that they be of use] to you; and all the stars are subservient to His command: in this, behold, there are messages indeed for people who use their reason!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 12,
          "number": 1913,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "And all the [beauty of] many hues-which He has created for you on earth: in this, behold, there is a message for people who (are willing to] take it to heart!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 13,
          "number": 1914,
          "juz": 14,
          "numpage": 268
        },
        {
          "text": "And He it is who has made the sea subservient [to His laws], so that you might eat fresh meat from it, and take from it gems which you may wear. And on that [very sea] one sees ships ploughing through the waves, so that you might [be able to] go forth in quest of some of His bounty, and thus have cause to be grateful [to Him].",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 14,
          "number": 1915,
          "juz": 14,
          "numpage": 268
        }
      ]
    },
    {
      "267": [
        {
          "text": "[and] who [now] declare this Qur'an to be [a tissue of] falsehoods!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 91,
          "number": 1893,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "But, by thy Sustainer! [On the Day of Judgment] We shall indeed call them to account, one and all,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 92,
          "number": 1894,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "for whatever they have done!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 93,
          "number": 1895,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "Hence, proclaim openly all that thou hast been bidden [to say], and leave alone all those who ascribe divinity to aught beside God:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 94,
          "number": 1896,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "verily, We shall suffice thee against all who [now] deride [this message - all]",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 95,
          "number": 1897,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "who assert that there are, side by side with God, other divine powers as well: for in time they will come to know [the truth].",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 96,
          "number": 1898,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "And well do We know that thy bosom is constricted by the [blasphemous] things that they say:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 97,
          "number": 1899,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "but extol thou thy Sustainer's limitless glory and praise Him, and be of those who prostrate themselves [before Him] in adoration,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 98,
          "number": 1900,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "and worship thy Sustainer till death comes to thee.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 99,
          "number": 1901,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "GOD'S JUDGMENT is [bound to] come: do not, therefore, call for its speedy advent! Limitless is He in His glory and sublimely exalted above anything to which men may ascribe a share in His divinity!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 1,
          "number": 1902,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "He causes the angels to descend with this divine inspiration, [bestowed] at His behest upon whomever He wills of His servants: \"Warn [all human beings] that there is no deity save Me: be, therefore, conscious of Me!\"",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 2,
          "number": 1903,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "He has created the heavens and the earth in accordance with [an inner] truth; sublimely exalted is He above anything to which men may ascribe a share in His divinity!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 3,
          "number": 1904,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "He creates man out of a [mere] drop of sperm: and lo! this same being shows himself endowed with the power to think and to argue!",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 4,
          "number": 1905,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "And He creates cattle: you derive warmth from them, and [various other] uses; and from them you obtain food;",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 5,
          "number": 1906,
          "juz": 14,
          "numpage": 267
        },
        {
          "text": "and you find beauty in them when you drive them home in the evenings and when you take them out to pasture in the mornings.",
          "englishName": "An-Nahl",
          "suraNumber": 16,
          "numberInSurah": 6,
          "number": 1907,
          "juz": 14,
          "numpage": 267
        }
      ]
    },
    {
      "266": [
        {
          "text": "[Lot] said: \"[Take instead] these daughters of mine, if you must do [whatever you intend to do]!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 71,
          "number": 1873,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "[But the angels spoke thus:] \"As thou livest, [O Lot, they will not listen to thee:] behold, in their delirium [of lust] they are but blindly stumbling to and fro!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 72,
          "number": 1874,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "And thereupon the blast [of Our punishment] overtook them at the time of sunrise,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 73,
          "number": 1875,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "and We turned those [sinful towns} upside down, and rained down upon them stone-hard blows of chastisement pre-ordained.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 74,
          "number": 1876,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "Verily, in all this there are messages indeed for those who can read the signs:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 75,
          "number": 1877,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "for, behold, those [towns] stood by a road that still exists.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 76,
          "number": 1878,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "Verily, herein lies a message indeed for all who believe [in God].",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 77,
          "number": 1879,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "AND THE DWELLERS of the wooded dales [of Madyan, too,] were inveterate evildoers,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 78,
          "number": 1880,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "and so We inflicted Our retribution on them. And, behold, both these [sinful communities] lived by a highway, [to this day] plain to see",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 79,
          "number": 1881,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "AND, [likewise,] indeed, the people of Al-Hijr gave the lie to [Our] message-bearers:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 80,
          "number": 1882,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "for We did vouchsafe them Our messages, but they obstinately turned their backs upon them-",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 81,
          "number": 1883,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "notwithstanding that they had been wont [to enjoy Our blessings and] to hew out dwellings from the mountains, [wherein they could live] in security",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 82,
          "number": 1884,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "and so the blast [of Our punishment] overtook them at early morn,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 83,
          "number": 1885,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "and of no avail to them was all [the power] that they had acquired.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 84,
          "number": 1886,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "AND [remember:] We have not created the heavens and the earth and all that is between them without [an inner] truth; but, behold, the Hour [when this will become clear to all] is indeed yet to come. Hence, forgive [men's failings] with fair forbearance:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 85,
          "number": 1887,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "verily, thy Sustainer is the all-knowing Creator of all things!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 86,
          "number": 1888,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "AND, INDEED, We have bestowed upon thee seven of the oft repeated [verses], and [have, thus, laid open before thee] this sublime Qur'an:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 87,
          "number": 1889,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "[so] turn, not thine eyes [longingly] towards the worldly benefits which We have granted unto some of those [that deny the truth]. And neither grieve over those [who refuse to heed thee], but spread the wings of thy tenderness over the believers,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 88,
          "number": 1890,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "and say: \"Behold, I am indeed the plain warner [promised by God]!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 89,
          "number": 1891,
          "juz": 14,
          "numpage": 266
        },
        {
          "text": "[For, thou art the bearer of a divine writ] such as We have bestowed from on high upon those who [afterwards] broke it up into parts,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 90,
          "number": 1892,
          "juz": 14,
          "numpage": 266
        }
      ]
    },
    {
      "265": [
        {
          "text": "[how,] when they presented themselves before him and bade him peace, he answered: \"Behold, we are afraid of you!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 52,
          "number": 1854,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "Said they: \"Fear not! Behold, we bring thee the glad tiding of [the birth of] a son who will be endowed with deep knowledge.\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 53,
          "number": 1855,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "Said he: \"Do you give me this glad tiding despite the fact that old age has overtaken me? Of what [strange thing], then, are you giving me a tiding!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 54,
          "number": 1856,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "They answered: \"We have given thee the glad tiding of something that is bound to come true. - so be not of those who abandon hope!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 55,
          "number": 1857,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "[Abraham] exclaimed: \"And who-other than those who have utterly lost their way-could ever abandon the hope of his Sustainer's grace?\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 56,
          "number": 1858,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "He added: \"And what [else] may you have in view, O you [heavenly] messengers?\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 57,
          "number": 1859,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "They answered: \"We are sent to people lost in sin [who are to be destroyed],",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 58,
          "number": 1860,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "barring Lot's household, all of whom, behold, we shall save -",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 59,
          "number": 1861,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "excepting only his wife, [of whom God says,] `We have ordained [that], behold, she should be among those who stay behind!'",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 60,
          "number": 1862,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "AND WHEN the messengers [of God] came to the house of Lot,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 61,
          "number": 1863,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "he said: \"Behold, you are people unknown [here]!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 62,
          "number": 1864,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "They answered: \"Nay, but we have come unto thee with [the announcement of] something that they [who are given to evil] have always been wont to call in question,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 63,
          "number": 1865,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "and we are bringing thee the certainty [of its fulfilment]: for, behold, we are speaking the truth indeed.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 64,
          "number": 1866,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "Go, then., with thy household while it is yet night, with thyself following them in the rear; and let none of you look back, but proceed whither you are bidden.\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 65,
          "number": 1867,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "And [through Our messengers] We revealed unto him this decree: \"The last remnant of those [sinners] shall be wiped out\" in the morn.\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 66,
          "number": 1868,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "And the people of the city came [unto Lot], rejoicing at the glad tiding.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 67,
          "number": 1869,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "Exclaimed [Lot]: \"Behold, these are my guests: so put me not to shame,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 68,
          "number": 1870,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "but be conscious of God and disgrace me not!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 69,
          "number": 1871,
          "juz": 14,
          "numpage": 265
        },
        {
          "text": "They answered: \"Have we not forbidden thee [to offer protection] to any kind of people?\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 70,
          "number": 1872,
          "juz": 14,
          "numpage": 265
        }
      ]
    },
    {
      "264": [
        {
          "text": "Said He: \"O Iblis! What is thy reason for not being among those who have prostrated themselves?\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 32,
          "number": 1834,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "[Iblis] replied: \"It is not for me to prostrate myself before mortal man whom Thou hast created out of sounding clay, out of dark slime transmuted!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 33,
          "number": 1835,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "Said He: \"Go forth, then, from this [angelic state]: for, behold, thou art [henceforth] accursed,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 34,
          "number": 1836,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "and [My] rejection shall be thy due until the Day of Judgment!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 35,
          "number": 1837,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "Said [Iblis]: \"Then, O my Sustainer, grant me a respite till the Day when all shall be raised from the dead!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 36,
          "number": 1838,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "Answered He: \"Verily, so be it: thou shalt be among those who are granted respite",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 37,
          "number": 1839,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "till the Day the time whereof is known [to Me alone].\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 38,
          "number": 1840,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "[Whereupon Iblis] said: \"O my Sustainer! Since Thou hast thwarted me, I shall indeed make [all that is evil] on earth seem goodly to them, and shall most certainly beguile them-into grievous error -",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 39,
          "number": 1841,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "[all] save such of them as are truly Thy servants!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 40,
          "number": 1842,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "Said He: \"This is, with Me. a straight way:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 41,
          "number": 1843,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "verily, thou shalt have no power over My creatures- unless it be such as are [already] lost in grievous error and follow thee [of their own will]:",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 42,
          "number": 1844,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "and for all such, behold, hell is the promised goal.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 43,
          "number": 1845,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "with seven gates leading into it, each gate receiving its allotted share of sinners. -",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 44,
          "number": 1846,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "VERILY, those who are conscious of God [shall find themselves in the hereafter] amidst gardens and springs,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 45,
          "number": 1847,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "[having been received with the greeting,] \"Enter here in peace, secure!\"",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 46,
          "number": 1848,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "And [by then] We shall have removed whatever unworthy thoughts or feelings may have been [lingering] in their breasts, [and they shall rest] as brethren, facing one another [in love] upon thrones of happiness.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 47,
          "number": 1849,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "No weariness shall ever touch them in this [state of bliss], and never shall they have to forgo it.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 48,
          "number": 1850,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "Tell My servants that I - I alone - am truly forgiving, a true dispenser of grace;",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 49,
          "number": 1851,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "and [also,] that the suffering which I shall impose [on sinners] will indeed be a suffering most grievous.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 50,
          "number": 1852,
          "juz": 14,
          "numpage": 264
        },
        {
          "text": "AND TELL THEM [once again] about Abraham's guests -",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 51,
          "number": 1853,
          "juz": 14,
          "numpage": 264
        }
      ]
    },
    {
      "263": [
        {
          "text": "AND, INDEED, We have set up in the heavens great constellations, and endowed them with beauty for all to behold;",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 16,
          "number": 1818,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "and We have made them secure against every satanic force accursed -",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 17,
          "number": 1819,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "so that anyone who seeks to learn [the unknowable] by stealth is pursued by a flame clear to see.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 18,
          "number": 1820,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "And the earth -We have spread it out wide, and placed on it mountains firm, and caused [life] of every kind to grow on it in a balanced manner,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 19,
          "number": 1821,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "and provided thereon means of livelihood for you [O men] as well as for all [living beings] whose sustenance does not depend on you.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 20,
          "number": 1822,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "For, no single thing exists that does not have its source with Us; and nought do We bestow from on high unless it be in accordance with a measure well-defined.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 21,
          "number": 1823,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "And We let loose the winds to fertilize [plants] and We send down water from the skies and let you drink thereof: and it is not you who dispose of its source-",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 22,
          "number": 1824,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "for, behold, it is We-We alone -who grant life and deal death, and it is We alone who shall remain after all else will have passed away!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 23,
          "number": 1825,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "And well do We know [the hearts and deeds of all human beings - both] those who lived before you and those who will come after you;",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 24,
          "number": 1826,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "and, behold, it is thy Sustainer who will gather them all together [on Judgment Day]: verily, He is wise, all-knowing!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 25,
          "number": 1827,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "AND, INDEED, We have created man out of sounding clay, out of dark-slime transmuted",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 26,
          "number": 1828,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "whereas the invisible beings We had created, [long] before that, out of the fire of scorching winds.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 27,
          "number": 1829,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "And lo! Thy Sustainer said unto the angels: \"Behold, I am about to create mortal man out of sounding clay, out of dark slime transmuted;",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 28,
          "number": 1830,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "and when I have formed him fully and breathed into him of My spirit, fall down before him in prostration!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 29,
          "number": 1831,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "Thereupon the angels prostrated themselves, all of them together,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 30,
          "number": 1832,
          "juz": 14,
          "numpage": 263
        },
        {
          "text": "save Iblis: he refused to be among those who prostrated themselves.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 31,
          "number": 1833,
          "juz": 14,
          "numpage": 263
        }
      ]
    },
    {
      "262": [
        {
          "text": "Alif. Lam. Ra. THESE ARE MESSAGES of revelation -of a discourse clear in itself and clearly showing the truth.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 1,
          "number": 1803,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "And it will come to pass that those who are [now] bent on denying this truth will wish that they had surrendered themselves to God [in their life time].",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 2,
          "number": 1804,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "Leave them alone; let them eat and enjoy themselves the while the hope [of vain delights] be guiles them: for in time they will come to know [the truth].",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 3,
          "number": 1805,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "And never have We destroyed any community [for its wrongdoing] unless a divine writ had (previously] been made known to it;",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 4,
          "number": 1806,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "[but remember that] no community can ever forestall [the end of] its term - and neither can they delay [it].",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 5,
          "number": 1807,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "And yet, they [who deny the truth] say: \"O thou unto whom this reminder has [allegedly] been bestowed from on high: verily, thou art mad!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 6,
          "number": 1808,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "Why dost thou not bring before us angels, if thou art a man of truth?",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 7,
          "number": 1809,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "[Yet] We never send down angels otherwise than in accordance with the [demands of] truth; and [were the angels to appear now,] lo! they [who reject this divine writ] would have no further respite!",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 8,
          "number": 1810,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "Behold, it is We Ourselves who have bestowed from on high, step by step, this reminder? and, behold, it is We who shall truly guard it [from all corruption].",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 9,
          "number": 1811,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "AND, INDEED, [O Prophet,] even before thy time did We send [Our apostles] unto communities of old -",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 10,
          "number": 1812,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "and never yet came an apostle to them without their deriding him.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 11,
          "number": 1813,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "Even so do We [now] cause this [scorn of Our message] to pervade the hearts of those who are lost in sin,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 12,
          "number": 1814,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "who do not believe in it, although the way which those [evildoers] of olden times had to go has long been within their ken.",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 13,
          "number": 1815,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "Yet even had We opened to them a gateway to heaven and they had ascended, on and on, up to it,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 14,
          "number": 1816,
          "juz": 14,
          "numpage": 262
        },
        {
          "text": "they would surely have said, \"It is only our eyes that are spellbound! Nay, we have been bewitched!,",
          "englishName": "Al-Hijr",
          "suraNumber": 15,
          "numberInSurah": 15,
          "number": 1817,
          "juz": 14,
          "numpage": 262
        }
      ]
    },
    {
      "261": [
        {
          "text": "the while they will be running confusedly to and fro, with their heads upraised [in supplication], unable to look away from what they shall behold, and their hearts an abysmal void.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 43,
          "number": 1793,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "Hence, warn men of the Day when this suffering may befall them, and when those who did wrong [in their lifetime] will exclaim: \"O our Sustainer! Grant us respite for a short while, so that we might respond to Thy call and follow the apostles!\" [But God will answer:] \"Why - were you not aforetime wont to swear that no kind of resurrection and retribution awaited you?",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 44,
          "number": 1794,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "And yet, you dwelt in the dwelling-places of those who had sinned against their own selves [before your time], and it was made obvious to you how We had dealt with them: for We have set forth unto you many a parable [of sin, resurrection and divine retribution].\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 45,
          "number": 1795,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "And [this retribution will befall all evildoers because] they devise that false imagery of theirs - and all their false imagery is within God's knowledge. [And never can the blasphemers prevail against the truth - not] even if their false imagery were so (well devised and so powerful] that mountains could be moved thereby.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 46,
          "number": 1796,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "HENCE, do not think that God will fail to fulfil the promise which He gave to His apostles: verily, God is almighty, an avenger of evil!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 47,
          "number": 1797,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "[His promise will be fulfilled] on the Day when the earth shall be changed into another earth, as shall be the heavens and when [all men] shall appear before God, the One who holds absolute sway over all that exists.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 48,
          "number": 1798,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "For on that Day thou wilt see all who were lost in sin linked together in fetters,",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 49,
          "number": 1799,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "clothed in garments of black pitch, with fire veiling their faces.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 50,
          "number": 1800,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "[And all shall be judged on that Day,] so that God may requite every human being for all that he has earned [in life]: verily, God is swift in reckoning!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 51,
          "number": 1801,
          "juz": 13,
          "numpage": 261
        },
        {
          "text": "THIS IS A MESSAGE unto all mankind. Hence, let them be warned thereby, and let them know that He is the One and Only God; and let those who are endowed with insight take this to heart!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 52,
          "number": 1802,
          "juz": 13,
          "numpage": 261
        }
      ]
    },
    {
      "260": [
        {
          "text": "And [always] does He give you something out of what you may be asking of Him; and should you try to count God's blessings, you could never compute them. [And yet,] behold, man is indeed most persistent in wrongdoing, stubbornly ingrate!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 34,
          "number": 1784,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "AND [remember the time] when Abraham spoke [thus]: \"O my Sustainer! Make this land secure, and preserve me and my children from ever worshipping idols -",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 35,
          "number": 1785,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "for, verily, O my Sustainer, these [false objects of worship] have led many people astray! \"Hence, [only] he who follows me [in this my faith] ~ is truly of me; and as for him who disobeys me - Thou art, verily, much-forgiving, a dispenser of grace!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 36,
          "number": 1786,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "\"O our Sustainer! Behold, I have settled some of my offspring in a valley in which there is no arable land, close to Thy sanctified Temple, so that, O our Sustainer, they might devote themselves to prayer: cause Thou, therefore, people's hearts to incline towards them and grant them fruitful sustenance, so that they might have cause to be grateful.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 37,
          "number": 1787,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "\"O our Sustainer! Thou truly knowest all that we may hide [in our hearts] as well as all that we bring into the open:' for nothing whatever, be it on earth or in heaven, remains hidden from God.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 38,
          "number": 1788,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "\"All praise is due to God, who has bestowed upon me, in my old age, Ishmael and Isaac! Behold, my Sustainer hears indeed all prayer:",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 39,
          "number": 1789,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "[hence,] O my Sustainer, cause me and [some] of my offspring to remain constant in prayer! \"And, O our Sustainer, accept this my prayer:",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 40,
          "number": 1790,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "Grant Thy forgiveness unto me, and my parents, and all the believers, on the Day on which the [last] reckoning will come to pass!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 41,
          "number": 1791,
          "juz": 13,
          "numpage": 260
        },
        {
          "text": "AND DO NOT think that God is unaware of what the evildoers are doing: He but grants them respite until the Day when their eyes will stare in horror,",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 42,
          "number": 1792,
          "juz": 13,
          "numpage": 260
        }
      ]
    },
    {
      "259": [
        {
          "text": "yielding its fruit at all times by its Sustainer's leave. And [thus it is that] God propounds parables unto men,' so that they might bethink themselves [of the truth].",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 25,
          "number": 1775,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "And the parable of a corrupt word is that of a corrupt tree, torn up [from its roots] onto the face of the earth, wholly unable to endure.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 26,
          "number": 1776,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "[Thus,] God grants firmness unto those who have attained to faith through the word that is unshakably true in the life of this world as well as in the life to come; but the wrongdoers He lets go astray: for God does whatever He wills.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 27,
          "number": 1777,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "ART THOU NOT aware of those who have preferred a denial of the truth to God's blessings, and [thereby] invited their people to alight in that abode of utter desolation",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 28,
          "number": 1778,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "- hell - which they [themselves] will ' have to endure? And how vile a state to settle in!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 29,
          "number": 1779,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "For, they claimed that there are powers that could rival God, and so they strayed from His path. Say: \"Enjoy yourselves [in this world], but, verily, the fire will be your journey's end!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 30,
          "number": 1780,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "[And] tell [those of] My servants who have attained to faith that they should be constant in prayer and spend [in Our way], secretly and openly, out of what We provide for them as sustenance, ere there come a Day when there will be no bargaining, and no mutual befriending.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 31,
          "number": 1781,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "[And remember that] it is God who has created the heavens and the earth, and who sends down water from the sky and thereby brings forth [all manner] of fruits for your sustenance; and who has made ships subservient to. you, so that they may sail through the sea at His behest; and has made the rivers subservient [to His laws, so that they be of use] to you;",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 32,
          "number": 1782,
          "juz": 13,
          "numpage": 259
        },
        {
          "text": "and has made the sun and the moon, both of them constant upon their courses, subservient [to His laws, so that they be of use] to you; and has made the night and the day subservient [to His laws, so that they be of use] to you.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 33,
          "number": 1783,
          "juz": 13,
          "numpage": 259
        }
      ]
    },
    {
      "258": [
        {
          "text": "ART THOU NOT aware that God has created the heavens and the earth in accordance with [an inner] truth? He can, if He so wills, do away with you and bring forth a new mankind [in your stead]:",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 19,
          "number": 1769,
          "juz": 13,
          "numpage": 258
        },
        {
          "text": "nor is this difficult for God.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 20,
          "number": 1770,
          "juz": 13,
          "numpage": 258
        },
        {
          "text": "And all [mankind] will appear before God [on the Day of Judgment]; and then the weak will say unto those who had gloried in their arrogance: \"Behold, We were but your followers: can you, then, relieve us of something of God's chastisement?\" [And the others] will answer: \"If God would but show us the way [to salvation], we would indeed guide you [towards it]. It is [now] all one, as far as we are concerned, whether we grieve impatiently or endure [our lot] with patience: there is no escape for us!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 21,
          "number": 1771,
          "juz": 13,
          "numpage": 258
        },
        {
          "text": "And when everything will have been decided, Satan will say: \"Behold, God promised you something that was bound to come true! I, too, held out [all manner of] promises to you -but I deceived you. Yet I had no power at all over you: I but called you-and you responded unto me. Hence, blame not me, but blame yourselves. It is not for me to respond to your cries, nor for you to respond to mine: for, behold, I have [always] refused to admit that there was any truth in your erstwhile belief that I had a share in God's divinity.\" Verily, for all evildoers there is grievous suffering in store.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 22,
          "number": 1772,
          "juz": 13,
          "numpage": 258
        },
        {
          "text": "But those who shall have attained to faith and done righteous deeds will be brought into gardens through which running waters flow, therein to abide by their Sustainer's leave, and will be welcomed with the greeting, \"Peace!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 23,
          "number": 1773,
          "juz": 13,
          "numpage": 258
        },
        {
          "text": "ART THOU NOT aware how God sets forth the parable of a good word? [It is] like a good tree, firmly rooted, [reaching out] with its branches towards the sky,",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 24,
          "number": 1774,
          "juz": 13,
          "numpage": 258
        }
      ]
    },
    {
      "257": [
        {
          "text": "Their apostles answered them: \"True, we are nothing but mortal men like yourselves: but God bestows His favour upon whomever He wills of His servants. Withal, it is not within our power to bring you a proof [of our mission], unless it be by God's leave-and [so] it is in God that all believers must place their trust.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 11,
          "number": 1761,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "And how could we not place our trust in God, seeing that it is He who has shown us the path which we are to follow? \"Hence, we shall certainly bear with patience whatever hurt you may do us: for, all who have trust [in His existence] must place their trust in God [alone]!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 12,
          "number": 1762,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "But they who denied the truth spoke [thus] unto their apostles: \"We shall most certainly expel you from our land, unless you return forthwith to our ways. Whereupon their Sustainer revealed this to His apostles: \"Most certainly shall We destroy these evildoers,",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 13,
          "number": 1763,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "and most certainly shall We cause you to dwell on earth [long] after they have passed away: this is [My promise] unto all who stand in awe of My presence, and stand in awe of My warning!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 14,
          "number": 1764,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "And they prayed [to God] that the truth be made to triumph. And [thus it is:] every arrogant enemy of the truth shall be undone [in the life to come],",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 15,
          "number": 1765,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "with hell awaiting him; and he shall be made to drink of the water of most bitter distress,",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 16,
          "number": 1766,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "gulping it [unceasingly,] little by little, and yet hardly able to swallow it. And death will beset him from every quarter - but he shall not die: for [yet more] severe suffering lies ahead of him.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 17,
          "number": 1767,
          "juz": 13,
          "numpage": 257
        },
        {
          "text": "[This, then, is] the parable of those who are bent on denying their Sustainer: all their works are as ashes which the wind blows about fiercely on a stormy day: [in the life to come,] they cannot achieve any benefit whatever from all [the good] that they may have wrought: for this [denial of God] is indeed the farthest one can go astray.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 18,
          "number": 1768,
          "juz": 13,
          "numpage": 257
        }
      ]
    },
    {
      "256": [
        {
          "text": "And, lo Moses spoke [thus] unto his people: \"Remember the blessings which God bestowed upon you when He saved you from Pharaoh's people who afflicted you with cruel suffering, and slaughtered your sons, and spared [only] your women -which was an awesome trial from your Sustainer.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 6,
          "number": 1756,
          "juz": 13,
          "numpage": 256
        },
        {
          "text": "And [remember the time] when your Sustainer made [this promise] known: 'If you are grateful [to Me], I shall most certainly give you more and more; but if you are ungrateful, verily, My chastisement will be severe indeed!\"'",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 7,
          "number": 1757,
          "juz": 13,
          "numpage": 256
        },
        {
          "text": "And Moses added: \"If you should [ever] deny the truth-you and whoever else lives on earth, all of you-[know that,] verily, God is indeed self-sufficient, ever to be praised!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 8,
          "number": 1758,
          "juz": 13,
          "numpage": 256
        },
        {
          "text": "HAVE THE STORIES of those [deniers of the truth] who lived before you never yet come within your ken - [the stories of] the people of Noah, and of [the tribes of] `Ad and Thamud, and of those who came after them? None knows them [now] save God. There came unto them their apostles with all evidence of the truth -but they covered their mouths with their hands and answered: \"Behold, we refuse to regard as true the message with which you [claim to] have been entrusted; and, behold, we are in grave doubt, amounting to suspicion, about [the meaning of] your call to us!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 9,
          "number": 1759,
          "juz": 13,
          "numpage": 256
        },
        {
          "text": "Said the apostles sent unto them: \"Can there be any doubt about [the existence and oneness of] God, the Originator of the heavens and the earth? It is He who calls unto you, so that He may forgive you [whatever is past] of your sins and grant you respite until a term [set by Him is fulfilled].\" [But] they replied: \"You are nothing but mortal men like ourselves! You want to turn us away from what our forefathers were wont to worship: well, then, bring us a clear proof [of your being God's message-bearers]!\"",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 10,
          "number": 1760,
          "juz": 13,
          "numpage": 256
        }
      ]
    },
    {
      "255": [
        {
          "text": "And [if] they who are bent on denying the truth say [unto thee, O Prophet], \"Thou hast not been sent [by God]\", say thou: \"None can bear witness between me and you as God does; and [none can bear witness as does] he who truly understands this divine writ.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 43,
          "number": 1750,
          "juz": 13,
          "numpage": 255
        },
        {
          "text": "Alif. Lam. Ra. A DIVINE WRIT [is this -a revelation] which We have bestowed upon thee from on high in order that thou might bring forth all mankind, by their Sustainer's leave, out of the depths of darkness into the light: onto the way that leads to the Almighty, the One to whom all praise is due -",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 1,
          "number": 1751,
          "juz": 13,
          "numpage": 255
        },
        {
          "text": "to God, unto whom all that is in the heavens and all that is on earth belongs. But woe unto those who deny the truth: for suffering severe",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 2,
          "number": 1752,
          "juz": 13,
          "numpage": 255
        },
        {
          "text": "awaits those who choose the life of this world as the sole object of their love, preferring it to [all thought of] the life to come, and who turn others away from the path of God and try to make it appear crooked. Such as these have indeed gone far astray!",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 3,
          "number": 1753,
          "juz": 13,
          "numpage": 255
        },
        {
          "text": "AND NEVER have We sent forth any apostle otherwise than [with a message] in his own people's tongue, so that he might make [the truth] clear unto them; but God lets go astray him that wills [to go astray], and guides him that wills [to be guided] -for He alone is almighty, truly wise.",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 4,
          "number": 1754,
          "juz": 13,
          "numpage": 255
        },
        {
          "text": "And [thus], indeed, have We sent forth Moses with Our messages [and this Our command]: \"Lead thy people out of the depths of darkness into the light, and remind them of the Days of God!\" Verily, in this [reminder] there are messages indeed for all who are wholly patient in adversity and deeply grateful [to God].",
          "englishName": "Ibrahim",
          "suraNumber": 14,
          "numberInSurah": 5,
          "number": 1755,
          "juz": 13,
          "numpage": 255
        }
      ]
    },
    {
      "254": [
        {
          "text": "THE PARABLE of the paradise promised to those who are conscious of God [is that of a garden] through which running waters flow: [but, unlike an earthly garden,] its fruits will be everlasting, and [so will be] its shade. Such will be the destiny of those who remain conscious of God-just as the destiny of those who deny the truth will be the fire.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 35,
          "number": 1742,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "Hence, they unto whom We have vouchsafed this revelation rejoice at all that has been bestowed upon thee [O Prophet] from on high; but among the followers of other creeds there are such as deny the validity of some of it. Say [unto them, O Prophet]: \"I have only been bidden to worship God, and not to ascribe divine. powers to aught beside Him: unto Him do I call [all mankind], and He is my goal!\"",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 36,
          "number": 1743,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "Thus, then, have We bestowed from on high this [divine writ] as an ordinance in the Arabic tongue. And, indeed, if thou shouldst defer to men's likes and dislikes after all the [divine] knowledge that has come unto thee, thou wouldst have none to protect thee from God, and none to shield thee [from Him].",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 37,
          "number": 1744,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "And, truly, We sent forth apostles before thee, and We appointed for them wives and offspring; and it was not given to any apostle to produce a miracle save at God's behest. Every age has had its revelation:",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 38,
          "number": 1745,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "God annuls or confirms whatever He wills [of His earlier messages] -for with Him is the source of all revelation.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 39,
          "number": 1746,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "BUT WHETHER We let thee see [in thy lifetime, O Prophet, the fulfilment of] some of what We have promised them, or whether We cause thee to die [before its fulfilment] - thy duty is no more than to deliver the message; and the reckoning is Ours.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 40,
          "number": 1747,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "Have, then, they [who deny the truth] never yet seen how We visit the earth [with Our punishment], gradually depriving it of all that is best thereon? For, [when] God judges, there is no power that could repel His judgment: and swift in reckoning is He!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 41,
          "number": 1748,
          "juz": 13,
          "numpage": 254
        },
        {
          "text": "Now those who lived before these [sinners] did, too, devise many a blasphemy -but the most subtle devising is that of God, who knows what each human being deserves: and the deniers of the truth will [in time] come to know to whom the future belongs.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 42,
          "number": 1749,
          "juz": 13,
          "numpage": 254
        }
      ]
    },
    {
      "253": [
        {
          "text": "[and so it is that] they who attain to faith and do righteous deeds are destined for happiness [in this world] and the most beauteous of all goals [in the life to come]!\"",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 29,
          "number": 1736,
          "juz": 13,
          "numpage": 253
        },
        {
          "text": "Thus have We raised thee [O Muhammad] as Our Apostle amidst a community [of unbelievers] before whose time [similar] communities have come and gone, so that thou might propound to them what We have revealed unto thee: for [in their ignorance] they deny the Most Gracious! Say: \"He is my Sustainer. There is no deity save Him. In Him have I placed my trust, and unto Him is my recourse!\"",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 30,
          "number": 1737,
          "juz": 13,
          "numpage": 253
        },
        {
          "text": "Yet even if [they should listen to] a [divine] discourse by which mountains could be moved, or the earth cleft asunder, or the dead made to speak - [they who are bent on denying the truth would still refuse to believe in it]! Nay, but God alone has the power to decide what shall be. Have, then, they who have attained to faith not yet come to know that, had God so willed; He would indeed have guided all mankind aright? But as for those who are bent on denying the truth-in result of their [evil] deeds, sudden calamities will always befall them or will alight close to their homes; [and this will continue] until God's promise [of resurrection] is fulfilled: verily, God never fails to fulfil His promise!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 31,
          "number": 1738,
          "juz": 13,
          "numpage": 253
        },
        {
          "text": "And, indeed, [even] before thy time have [God's] apostles been derided, and for a while I gave rein to those who were [thus] bent on denying the truth: but then I took them to task - and how awesome was My retribution!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 32,
          "number": 1739,
          "juz": 13,
          "numpage": 253
        },
        {
          "text": "IS, THEN, HE who has every living being in His almighty care, [dealing with each] according to what it deserves -[is, then, He like anything else that exists]? And yet, they ascribe to other beings a share in God's divinity! Say: \"Give them any name [you please]: but do you [really think that you could] inform Him of anything on earth that He does not know-or [do you] but play with words?, Nay, goodly seems their false imagery to those who are bent on denying the truth, and so they are turned away from the [right] path: and he whom God lets go astray can never find any guide.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 33,
          "number": 1740,
          "juz": 13,
          "numpage": 253
        },
        {
          "text": "For such, there is suffering in the life of this world; but, truly, [their] suffering in the life to come will be harder still, and they will have none to shield them from God.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 34,
          "number": 1741,
          "juz": 13,
          "numpage": 253
        }
      ]
    },
    {
      "252": [
        {
          "text": "CAN, THEN, he who knows that whatever has been bestowed from on high upon thee by thy Sustainer is the truth be deemed equal to one who is blind? Only they who are endowed with insight keep this in mind:",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 19,
          "number": 1726,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "they who are true to their bond with God and never break their covenant;",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 20,
          "number": 1727,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "and who keep together what God has bidden to be joined, and stand in awe of their Sustainer and fear the most evil reckoning [which awaits such as do not respond to Him];",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 21,
          "number": 1728,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "and who are patient in adversity out of a longing for their Sustainer's countenance, and are constant in prayer, and spend on others, secretly and openly, out of what We provide for them as sustenance, and [who] repel evil with good. It is these that shall find their fulfilment in the hereafter:",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 22,
          "number": 1729,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "gardens of perpetual bliss, which they shall enter together with the righteous from among their parents, their spouses, and their offspring; and the angels will come unto them from every gate [and will say]:",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 23,
          "number": 1730,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "\"Peace be upon you, because you have persevered!\" How excellent, then, this fulfilment in the hereafter!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 24,
          "number": 1731,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "But as for those who break their bond with God after it has been established [in their nature], and cut asunder what God has bidden to be joined, and spread corruption on earth -their due is rejection [by God], and theirs is a most evil fate [in the life to come].",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 25,
          "number": 1732,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "GOD GRANTS abundant sustenance, or gives it in scant measure, unto whomever He wills; and they [who are given abundance] rejoice in the life of this world - even though, as compared with the life to come, the life of this world is nought but -a fleeting pleasure.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 26,
          "number": 1733,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "NOW THOSE who are bent on denying the truth [of the Prophet's message] say, \"Why has no miraculous sign ever been bestowed upon him from on high by his Sustainer?\" Say: \"Behold, God lets go astray him who wills [to go astray], just as He guides unto Himself all who turn unto Him-",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 27,
          "number": 1734,
          "juz": 13,
          "numpage": 252
        },
        {
          "text": "those who believe, and whose hearts find their rest in the remembrance of God - for, verily, in the remembrance of God [men's] hearts do find their rest-:",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 28,
          "number": 1735,
          "juz": 13,
          "numpage": 252
        }
      ]
    },
    {
      "251": [
        {
          "text": "Unto Him [alone] is due all prayer aiming at the Ultimate Truth, since those [other beings or powers] whom men invoke instead of God cannot respond to them in any way - [so that he who invokes them is] but like one who stretches his open hands towards water, [hoping] that it will reach his mouth, the while it never reaches him. Hence, the prayer of those who deny the truth amounts to no more than losing oneself in grievous error.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 14,
          "number": 1721,
          "juz": 13,
          "numpage": 251
        },
        {
          "text": "And before God prostrate themselves, willingly or unwillingly, all [things and beings] that are in the heavens and on earth, as do their shadows in the mornings and the evenings.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 15,
          "number": 1722,
          "juz": 13,
          "numpage": 251
        },
        {
          "text": "Say: \"Who is the Sustainer of the heavens and the earth?\" Say: \"[It is] God.\" Say: \"[Why,] then, do you take for your protectors, instead of Him, such as have it not within their power to bring benefit to, or avert harm from, themselves?\" Say: \"Can the blind and the seeing be deemed equal? -or can the depths of darkness and the light be deemed equal?\" Or do they [really] believe that there are, side by side with God, other divine powers that have created the like of what He creates, so that this act of creation appears to them to be similar [to His]? Say: \"God is the Creator of all things; and He is the One who holds absolute sway over all that exists.\"",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 16,
          "number": 1723,
          "juz": 13,
          "numpage": 251
        },
        {
          "text": "[Whenever] He sends down water from the sky, and [once-dry] river-beds are running high according to their measure, the stream carries scum on its surface; and, likewise, from that [metal] which they smelt in the fire in order to make ornaments or utensils, [there rises] scum. In this way does God set forth the parable of truth and falsehood: for, as far as the scum is concerned, it passes away as [does all] dross; but that which is of benefit to man abides on earth. In this way does God set forth the parables",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 17,
          "number": 1724,
          "juz": 13,
          "numpage": 251
        },
        {
          "text": "of those who have responded to their Sustainer with a goodly response, and of those who did not respond to Him. [As for the latter,] if they possessed all that is on earth, and twice as much, they would surely offer it as ransom [on the Day of Judgment]: a most evil reckoning awaits them, and their goal is hell: and how evil a resting-place!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 18,
          "number": 1725,
          "juz": 13,
          "numpage": 251
        }
      ]
    },
    {
      "250": [
        {
          "text": "And [since, O Prophet, they are bent on denying the truth,] they challenge thee to hasten the coming upon them of evil instead of [hoping for] good although [they ought to know that] the exemplary punishments [which they now deride] have indeed come to pass before their time. Now, behold, thy Sustainer is full of forgiveness unto men despite all their evildoing: but, behold, thy Sustainer is [also] truly severe in retribution!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 6,
          "number": 1713,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "However, they who are bent on denying the truth [refuse to believe and] say, \"Why has no miraculous sign ever been bestowed on him from on high by his Sustainer?\" [But] thou art only a warmer; and [in God] all people have a guide.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 7,
          "number": 1714,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "God knows what any female bears [in her womb], and by how much the wombs may fall short [in gestation], and by how much they may increase [the average period]: for with Him everything is [created] in accordance with its scope and purpose.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 8,
          "number": 1715,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "He knows all that is beyond the reach of a created being's perception as well as all that can be witnessed by a creature's senses or mind -the Great One, the One far above anything that is or could ever be!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 9,
          "number": 1716,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "It is all alike [to Him] whether any of you conceals his thought or brings it into the open, and whether he seeks to hide [his evil deeds] under the cover of night or walks [boldly] in the light of day,",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 10,
          "number": 1717,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "[thinking that] he has hosts of helpers-both such as can be perceived by him and such as are hidden from him -that could preserve him from whatever God may have willed. Verily, God does not change men's condition unless they change their inner selves; and when God wills people to suffer evil [in consequence of their own evil deeds], there is none who could avert it: for they have none who could protect them from Him.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 11,
          "number": 1718,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "HE IT IS who displays before you the lightning, to give rise to [both] fear and hope, and calls heavy clouds into being;",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 12,
          "number": 1719,
          "juz": 13,
          "numpage": 250
        },
        {
          "text": "and the thunder extols His limitless glory and praises Him, and [so do] the angels, in awe of Him; and He [it is who] lets loose the thunderbolts and strikes with them whom He wills. And yet, they stubbornly argue about God, notwithstanding [all evidence] that He alone has the power to contrive whatever His unfathomable wisdom wills!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 13,
          "number": 1720,
          "juz": 13,
          "numpage": 250
        }
      ]
    },
    {
      "249": [
        {
          "text": "Alif. Lam. Mim. Ra. THESE ARE MESSAGES of revelation: and what has been bestowed upon thee from on high by thy Sustainer is the truth-yet most people will not believe [in it]?",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 1,
          "number": 1708,
          "juz": 13,
          "numpage": 249
        },
        {
          "text": "It is God who has raised the heavens without any supports that you could see, and is established on the throne of His almightiness; and He [it is who] has made the sun and the moon subservient [to His laws], each running its course for a term set [by Him]. He governs all that exists. Clearly does He spell out these messages, so that you might be certain in your innermost that you are destined to meet your Sustainer [on Judgment Day].",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 2,
          "number": 1709,
          "juz": 13,
          "numpage": 249
        },
        {
          "text": "And it is He who has spread the earth wide and placed on it firm mountains and running waters, and created thereon two sexes of every [kind of] plant; [and it is He who] causes the night to cover the day. Verily, in all this there are messages indeed for people who think!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 3,
          "number": 1710,
          "juz": 13,
          "numpage": 249
        },
        {
          "text": "And there are on earth [many] tracts of land close by one another [and yet widely differing from one another]; and [there are on it] vinyards, and fields of grain, and date-palms growing in clusters from one root or standing alone, [all] watered with the same water: and yet, some of them have We favoured above others by way of the food [which they provide for man and beast]. Verily, in all this there are messages indeed for people who use their reason!",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 4,
          "number": 1711,
          "juz": 13,
          "numpage": 249
        },
        {
          "text": "BUT IF thou art amazed [at the marvels of God's creation], amazing, too, is their saying, \"What! After we have become dust, shall we indeed be [restored to life] in a new act of creation?\" It is they who [thus show that they] are bent on denying their Sustainer; and it is they who carry the shackles [of their own making] around their necks; and it is they who are destined for the fire, therein to abide.",
          "englishName": "Ar-Ra'd",
          "suraNumber": 13,
          "numberInSurah": 5,
          "number": 1712,
          "juz": 13,
          "numpage": 249
        }
      ]
    },
    {
      "248": [
        {
          "text": "although thou dost not ask of them any reward for it: it is but [God's] reminder unto all mankind.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 104,
          "number": 1700,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "But [then] -how many a sign is there in the heavens and on earth which they pass by [unthinkingly], and on which they turn their backs!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 105,
          "number": 1701,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "And most of them do not even believe in God without [also] ascribing divine powers to other beings beside Him.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 106,
          "number": 1702,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "Do they, then, feel free from the fear that there might fall upon them the overwhelming terror of God's chastisement, or that the Last Hour might come upon them of a sudden, without their being aware [of its approach]?",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 107,
          "number": 1703,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "Say [O Prophet]: \"This is my way: Resting upon conscious insight accessible to reason, I am calling [you all] unto God - I and they who follow me.\" And [say:] \"Limitless is God in His glory; and I am not one of those who ascribe divinity to aught beside Him!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 108,
          "number": 1704,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "And [even] before thy time, We never sent [as Our apostles] any but [mortal] men, whom We inspired, [and whom We always chose] from among the people of the [very] communities [to whom the message was to be brought]. Have, then, they [who reject this divine writ] never journeyed about the earth and beheld what happened in the end to those [deniers of the truth] who lived before them?-and [do they not know that] to those who are conscious of God the life in the hereafter is indeed better [than this world]? Will they not, then, use their reason?",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 109,
          "number": 1705,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "[All the earlier apostles had to suffer persecution for a long time;] but at last -when those apostles had lost all hope and saw themselves branded as liars - Our succour attained to them: whereupon everyone whom We Milled [to be saved] was saved [and the deniers of the truth were destroyed]: for, never can Our punishment be averted from people who are lost in sin.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 110,
          "number": 1706,
          "juz": 13,
          "numpage": 248
        },
        {
          "text": "Indeed, in the stories of these men there is a lesson for those who are endowed with insight. [As for this revelation,] it could not possibly be a discourse invented [by man]: nay indeed, it is [a divine writ] confirming the truth of whatever there still remains [of earlier revelations], clearly spelling out everything, and [offering] guidance and grace unto people who will believe.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 111,
          "number": 1707,
          "juz": 13,
          "numpage": 248
        }
      ]
    },
    {
      "247": [
        {
          "text": "But when the bearer of good tidings came [with Joseph's tunic], he laid it over his face; and he regained his sight, [and] exclaimed: \"Did I not tell you, `Verily, I know, from God, something that you do not know'?\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 96,
          "number": 1692,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "[His sons] answered: \"O our father! Ask God to forgive us our sins, for, verily, we were sinners.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 97,
          "number": 1693,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "He said: \"I shall ask my Sustainer to forgive you: He alone is truly forgiving, a true dispenser of grace!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 98,
          "number": 1694,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "AND WHEN they [all arrived in Egypt and] presented themselves before Joseph, he drew his parents unto himself, saying, \"Enter Egypt! If God so wills, you shall be secure [from all evil]!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 99,
          "number": 1695,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "And he raised his parents to the highest place of honour; and they [all] fell down before Him, prostrating themselves in adoration. Thereupon [Joseph] said: \"O my father! This is the real meaning of my dream of long ago, which my Sustainer has made come true. And He was indeed good to me when He freed me from the prison, and [when] He brought you [all unto me] from the desert after Satan had sown discord between me and my brothers. Verily, my Sustainer is unfathomable in [the way He brings about] whatever He wills: verily, He alone is all-knowing, truly wise!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 100,
          "number": 1696,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "\"O my Sustainer! Thou hast indeed bestowed upon me something of power, and hast imparted unto me some knowledge of the inner meaning of happenings. Originator of the heavens and the earth! Thou art near unto me in this world and in the life to come: let me die as one who has surrendered himself unto Thee, and make me one with the righteous!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 101,
          "number": 1697,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "THIS ACCOUNT of something that was beyond the reach of thy perception We [now] reveal unto thee, [O Prophet:] for thou wert not with Joseph's brothers when they resolved upon what they were going to do and wove their schemes [against him].",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 102,
          "number": 1698,
          "juz": 13,
          "numpage": 247
        },
        {
          "text": "Yet - however strongly thou mayest desire it -most people will not believe [in this revelation],",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 103,
          "number": 1699,
          "juz": 13,
          "numpage": 247
        }
      ]
    },
    {
      "246": [
        {
          "text": "[Hence,] O my sons, go forth and try to obtain some tidings of Joseph and his brother; and do not lose hope of God's life-giving mercy: verily, none but people who deny the truth can ever lose hope of God's life-giving mercy.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 87,
          "number": 1683,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "[AND THE SONS of Jacob went back to Egypt and to Joseph;] and when they presented themselves before him, they said: \"O thou great one! Hardship has visited us and our folk, and so we have brought but scanty merchandise; but give us a full measure [of grain], and be charitable to us: behold, God rewards those who give in charity!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 88,
          "number": 1684,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "Replied he: \"Do you remember what you did to Joseph and his brother when you were still unaware [of right and wrong]?\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 89,
          "number": 1685,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "They exclaimed: \"Why - is it indeed thou who art Joseph?\" He answered: \"I am Joseph, and this is my brother. God has indeed been gracious unto us. Verily, if one is conscious of Him and patient in adversity behold, God does not fail to requite the doers of good!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 90,
          "number": 1686,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "[The brothers] said: \"By God! Most certainly has God raised thee high above us, and we were indeed but sinners!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 91,
          "number": 1687,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "Said he: \"No reproach shall be uttered today against you. May God forgive you your sins: for He is the most merciful of the merciful!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 92,
          "number": 1688,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "[And now] go and take this tunic of mine and lay it over my father's face, and he will recover his sight. And thereupon come [back] to me with all your family.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 93,
          "number": 1689,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "AND AS SOON as the caravan [with which Jacob's sons were travelling] was on its way, their father said [to the people around him]: \"Behold, were it not that you might consider me a dotard, [I would say that] I truly feel the breath of Joseph [in the air]!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 94,
          "number": 1690,
          "juz": 13,
          "numpage": 246
        },
        {
          "text": "They answered: \"By God! Thou art indeed still lost in thy old aberration!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 95,
          "number": 1691,
          "juz": 13,
          "numpage": 246
        }
      ]
    },
    {
      "245": [
        {
          "text": "He answered: \"May God preserve us from [the sin of] detaining any other than him with whom we have found our property-for then, behold, we would indeed be evildoers!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 79,
          "number": 1675,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "And so, when they lost all hope of [moving] him, they withdrew to take counsel [among themselves]. The eldest of them said: \"Do you not remember that your father has bound you by a solemn pledge before God - and how, before that, you had failed with regard to Joseph? Hence, I shall not depart from this land till my father gives me leave or God passes judgment in my favour: for He is the best of all judges.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 80,
          "number": 1676,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "[And as for you others,] return to your father and say: `O our father! Behold, thy son has stolen-but we [can] bear witness to no more than what has become known to us; and [although we gave you our pledge,] we could not guard against something that [lay hidden in the future and, hence,] was beyond the reach of our perception.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 81,
          "number": 1677,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "And ask thou in the town in which we were [at the time], and of the people of the caravan with whom we travelled hither, and [thou wilt find that] we, are indeed telling the truth!\"'",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 82,
          "number": 1678,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "[AND WHEN they returned to their father and told him what had happened,] he exclaimed: \"Nay, but it is your [own] minds that have made [so terrible] a happening seem a matter of little account to you! But [as for myself,] patience in adversity is most goodly; God may well bring them all [back] unto me: verily, He alone is all-knowing, truly wise!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 83,
          "number": 1679,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "But he turned away from them and said: \"O woe is me for Joseph!\"-and his eyes became dim from the grief with which he was filled.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 84,
          "number": 1680,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "Said [his sons]: \"By God! Thou wilt never cease to remember, Joseph till thou art broken in body and spirit or art dead!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 85,
          "number": 1681,
          "juz": 13,
          "numpage": 245
        },
        {
          "text": "He answered: \"It is only to God that I complain of my deep grief and my sorrow: for I know, from God, something that you do not know.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 86,
          "number": 1682,
          "juz": 13,
          "numpage": 245
        }
      ]
    },
    {
      "244": [
        {
          "text": "And [later,] when he had provided them with their provisions, he placed the [King's] drinking-cup in his brother's camel-pack. And [as they were leaving the city,] a herald called out: \"O you people of the caravan! Verily, you are thieves!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 70,
          "number": 1666,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "Turning towards the herald and his companions, the brothers asked: \"What is it that you miss?\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 71,
          "number": 1667,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "They answered: \"We miss the King's goblet; and he who produces it shall receive a camel-load [of grain as reward]!\" And [the herald added:] \"I pledge myself to this _ [promise]!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 72,
          "number": 1668,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "Said [the brothers]: \"By God! Well do you know that we have not come to commit deeds of corruption in this land, and that we have not been thieving!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 73,
          "number": 1669,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "[The Egyptians] said: \"But what shall be the requital of this [deed] if you are [proved to be] liars?\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 74,
          "number": 1670,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "[The brothers] replied: \"Its requital? He in whose camel-pack [the cup] is found-he shall be [enslaved as] a requital thereof! Thus do we [ourselves] requite the doers of [such] wrong.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 75,
          "number": 1671,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "Thereupon [they were brought before Joseph to be searched; and] he began with the bags of his half-brothers before the bag of his brother [Benjamin]: and in the end he brought forth the drinking Cups out of his brother's bag. In this way did We contrive for Joseph [the attainment of his heart's desire]: under the King's law, he would [otherwise] not have been able to detain his brother, had not God so willed. We do raise to [high] degrees [of knowledge] whomever We will - but above everyone who is endowed with knowledge there is One who knows all.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 76,
          "number": 1672,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "[As soon as the cup came to light out of Benjamin's bag, the brothers] exclaimed: \"If he has stolen-well, a brother of his used to steal afore-time!\" Thereupon Joseph said to himself, without revealing his thought to them: \"You are far worse in this respect, and God is fully aware of what you are saying.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 77,
          "number": 1673,
          "juz": 13,
          "numpage": 244
        },
        {
          "text": "They said: \"O thou great one! Behold, he has a father, a very old man: detain, therefore, one of us in his stead. Verily, we see that thou art a doer of good!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 78,
          "number": 1674,
          "juz": 13,
          "numpage": 244
        }
      ]
    },
    {
      "243": [
        {
          "text": "[Jacob] replied: \"Shall I trust you with him in the same way as I trusted you with his brother (Joseph] aforetime? [Nay,] but God's guardianship is better [than yours], for He is the most merciful of the merciful!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 64,
          "number": 1660,
          "juz": 13,
          "numpage": 243
        },
        {
          "text": "Thereupon, when they opened their packs, they discovered that their merchandise had been returned to them; [and] they said: \"O our father! What more could we desire? Here is our merchandise: it has been returned to us! [If thou send Benjamin with us,] we shall (again] be able to bring food for our family, and shall guard our brother [well], and receive in addition another camel-load of grain That [which we have brought the first time] was but a scanty measure.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 65,
          "number": 1661,
          "juz": 13,
          "numpage": 243
        },
        {
          "text": "Said [Jacob]: \"I will not send him with you until you give me a solemn pledge, before God, that you will indeed bring him back unto me, unless you yourselves be encompassed [by death]!\" And when they had given him their solemn pledge, [Jacob] said: \"God is witness to all that we say!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 66,
          "number": 1662,
          "juz": 13,
          "numpage": 243
        },
        {
          "text": "And he added: \"O my sons! Do not enter [the city all] by one gate, but enter by different gates. Yet [even so,] I can be of no avail whatever to you against [anything that may be willed by] God: judgment [as to what is to happen] rests with none but God. In Him have I placed my trust: for, all who have trust [in His existence] must place their trust in Him alone.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 67,
          "number": 1663,
          "juz": 13,
          "numpage": 243
        },
        {
          "text": "But although they entered [Joseph's city] in the way their father had bidden them, this proved of no avail whatever to them against [the plan of] God [His request] had served only to satisfy Jacob's heartfelt desire [to protect them]: for, behold, thanks to what We had imparted unto him, he was indeed endowed with the knowledge [that God's will must always prevail]; but most people know it not.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 68,
          "number": 1664,
          "juz": 13,
          "numpage": 243
        },
        {
          "text": "AND WHEN [the sons of Jacob] presented themselves before Joseph, he drew his brother [Benjamin] unto himself, saying [to him in secret]: \"Behold, I am thy brother! So grieve thou not over their past doings!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 69,
          "number": 1665,
          "juz": 13,
          "numpage": 243
        }
      ]
    },
    {
      "242": [
        {
          "text": "And yet, I am not trying to absolve myself: for, verily, man's inner self does incite [him] to evil, and saved are only they upon whom my Sustainer bestows His grace. Behold, my Sustainer is much forgiving, a dispenser of grace!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 53,
          "number": 1649,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "And the King said: \"Bring him unto me, so that I may attach him to my own person.\" And when he had spoken with him, [the King] said: \"Behold, [from] this day thou shalt be of high standing with us, invested with all trust!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 54,
          "number": 1650,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "[Joseph] replied: \"Place in my charge the store-houses of the land; behold, I shall be a good and knowing keeper.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 55,
          "number": 1651,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "And thus We established Joseph securely in the land [of Egypt]: he had full mastery over it, [doing] whatever he willed. [Thus do] We cause Our grace to alight upon whomever We will; and We do not fail to requite the doers of good.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 56,
          "number": 1652,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "But in the eyes of those who have attained to faith and have always been conscious of Us, a reward in the life to come is a far greater good [than any reward in this world].",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 57,
          "number": 1653,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "AND [after some years,] Joseph's brothers came [to Egypt] and presented themselves before him: and he knew them [at once], whereas they did not recognize him.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 58,
          "number": 1654,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "And when he had provided them with their provisions, he said: \"[When you come here next,] bring unto me that brother of yours from your father's side. Do you not see that I have given [you] full measure and have been the best of hosts?",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 59,
          "number": 1655,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "But if you do not bring him unto me, you shall never again receive a single measure [of grain] from me, nor shall you [be allowed to] come near me!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 60,
          "number": 1656,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "They answered: \"We shall try to persuade his father to part with him, and, verily, we shall do [our utmost]!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 61,
          "number": 1657,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "And [Joseph] said to his servants: \"Place their merchandise in their camel-packs, so that they may find it there when they come home, and hence be the more eager to return.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 62,
          "number": 1658,
          "juz": 13,
          "numpage": 242
        },
        {
          "text": "And so, when they returned to their father, [Joseph's brothers] said: \"O our father! All grain is [to be] withheld from us [in the future unless we bring Benjamin with us]: send, therefore, our brother with us, so that we may obtain our measure (of grain]; and, verily, we shall guard him well!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 63,
          "number": 1659,
          "juz": 13,
          "numpage": 242
        }
      ]
    },
    {
      "241": [
        {
          "text": "They answered: \" [This is one of] the most involved and confusing of dreams, and we have no deep knowledge of the real meaning of dreams.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 44,
          "number": 1640,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "At that, the one of the two [erstwhile prisoners] who had been saved, and [who suddenly] remembered [Joseph] after all that time, spoke [thus]: \"It is I who can inform you of the real meaning of this [dream]; so let me go [in search of it].\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 45,
          "number": 1641,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "[And he went to see Joseph in the prison and said to him:] \"Joseph, O thou truthful one! Enlighten us about [the meaning of a dream in which] seven fat cows were being devoured by seven emaciated ones, and seven green ears [of wheat appeared] next to [seven] others that were withered - so that I may return [with thy explanation] unto the people [of the court, and] that they may come to know [what manner of man thou art]!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 46,
          "number": 1642,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "[Joseph] replied: \"You shall sow for seven years as usual; but let all [the grain] that you harvest remain [untouched] in its ear, excepting only a little, whereof you may eat:",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 47,
          "number": 1643,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "for, after that [period of seven good years] there will come seven hard [years] which will devour all that you shall have laid up for them, excepting only a little of that which you shall have kept in store.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 48,
          "number": 1644,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "And after that there will come a year in which the people will be delivered from all distress, and in which they will press [oil and wine as before].\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 49,
          "number": 1645,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "And [as soon as Joseph's interpretation. was conveyed to him,] the King said: \"Bring him before me!\" But when the [King's] messenger came unto him, [Joseph] said: \"Go back to thy lord and ask him [first to find out the truth] about those women who cut their hands-for, behold, [until now it is] my Sustainer [alone who] has full knowledge of their guile!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 50,
          "number": 1646,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "[Thereupon the King sent for those women; and when they came,] he asked: \"What was it that you hoped to achieve when you sought to make Joseph yield himself unto you?\" The women answered: \"God save us! We did not perceive the least evil [intention] on his part!\" [And] the wife of Joseph's former master exclaimed: \"Now has the truth come to light! It was I who sought to make him yield himself unto me - whereas he, behold, was indeed speaking the truth!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 51,
          "number": 1647,
          "juz": 12,
          "numpage": 241
        },
        {
          "text": "[When Joseph learned what had happened, he said: \"I asked for] this, so that [my former master] might know that I did not betray him behind his back, and that God does not bless with His guidance the artful schemes of those who betray their trust.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 52,
          "number": 1648,
          "juz": 12,
          "numpage": 241
        }
      ]
    },
    {
      "240": [
        {
          "text": "and I follow the creed of my forefathers Abraham, Isaac and Jacob. It is not conceivable that we should [be allowed to] ascribe divinity to aught beside God: this is [an outcome] of God's bounty unto us and unto all mankind -but most people are ungrateful.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 38,
          "number": 1634,
          "juz": 12,
          "numpage": 240
        },
        {
          "text": "\"O my companions in imprisonment! Which is more reasonable: [belief in the existence of numerous divine] lords, each of them different from the other\" -or [in] the One God, who holds absolute sway over all that exists?",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 39,
          "number": 1635,
          "juz": 12,
          "numpage": 240
        },
        {
          "text": "\"All that you worship instead of God is nothing but [empty] names which you have invented - you and your forefathers- [and] for which God has bestowed no warrant from on high. Judgment [as to what is right and what is wrong] rests with God alone-[and] He has ordained that you should worship nought but Him: this is the [one] ever-true faith; but most people know it not",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 40,
          "number": 1636,
          "juz": 12,
          "numpage": 240
        },
        {
          "text": "\"[And now,] O my companions in imprisonment, [I shall tell you the meaning of your dreams:] as for one of you two, he will [again] give his lord [the King] wine to drink; but as for the other, he will be crucified, and birds will eat off his head. [But whatever be your future,] the matter on which you have asked me to enlighten you has been decided [by God].\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 41,
          "number": 1637,
          "juz": 12,
          "numpage": 240
        },
        {
          "text": "And [thereupon Joseph] said unto the one of the two whom he considered saved: \"Mention me unto thy lord [when thou art free]!\" But Satan caused him to forget to mention [Joseph] to his lord, and so he remained in prison a few [more] years.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 42,
          "number": 1638,
          "juz": 12,
          "numpage": 240
        },
        {
          "text": "AND [one day] the King said: Behold, I saw [in a dream] seven fat cows being devoured by seven emaciated ones, and seven green ears [of wheat] next to [seven] others that were withered. O you nobles! Enlighten me about [the meaning of] my dream, if you are able to interpret dreams!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 43,
          "number": 1639,
          "juz": 12,
          "numpage": 240
        }
      ]
    },
    {
      "239": [
        {
          "text": "Thereupon, when she heard of their malicious talk, she sent for them, and prepared for them a sumptuous repast, and handed each of them a knife and said [to Joseph]: \"Come out and show thyself to them!\" And when the women saw him, they were greatly amazed at his beauty, and [so flustered were they that] they cut their hands [with their knives], exclaiming, \"God save us! This is no mortal man! This is nought but a noble angel!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 31,
          "number": 1627,
          "juz": 12,
          "numpage": 239
        },
        {
          "text": "Said she: \"This, then, is he about whom you have been blaming me! And, indeed, I did try to make him yield himself unto me, but he remained chaste. Now, however, if he does not do what I bid him, he shall most certainly be imprisoned, and shall most certainly find himself among the despised!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 32,
          "number": 1628,
          "juz": 12,
          "numpage": 239
        },
        {
          "text": "Said he: \"O my Sustainer! Prison is more desirable to me than [compliance with] what these women invite me to: for, unless Thou turn away their guile from me, I might yet yield to their allure and become one of those who are unaware [of right and wrong].\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 33,
          "number": 1629,
          "juz": 12,
          "numpage": 239
        },
        {
          "text": "And his Sustainer responded to his prayer, and freed him from the threat of their guile: verily, He alone is all-hearing, all-knowing.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 34,
          "number": 1630,
          "juz": 12,
          "numpage": 239
        },
        {
          "text": "For, presently it occurred to the nobleman and his household _ [even] after they had seen all the signs [of Joseph's innocence] - that they might as well imprison him for a time.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 35,
          "number": 1631,
          "juz": 12,
          "numpage": 239
        },
        {
          "text": "NOW two young men happened to go to prison at the same time as Joseph. One of them said: \"Behold, I saw myself [in a dream] pressing wine.\" And the other said: \"Behold, I saw myself [in a dream] carrying bread on my head, and birds were eating thereof.\" [And both entreated Joseph:] \"Let us know the real meaning of this! Verily, we see that thou art one of those who know well [how to interpret dreams].",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 36,
          "number": 1632,
          "juz": 12,
          "numpage": 239
        },
        {
          "text": "[Joseph] answered: \"Ere there comes unto you the meal which you are [daily] fed, I shall have informed you of the real meaning of your dreams, [so that you might know what is to come] before it comes unto you: for this is [part] of the knowledge which my Sustainer has imparted to me. \"Behold, I have left behind me the ways of people who do not believe in God, and who persistently refuse to acknowledge the truth of the life to come;",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 37,
          "number": 1633,
          "juz": 12,
          "numpage": 239
        }
      ]
    },
    {
      "238": [
        {
          "text": "And [it so happened that] she in whose house he was living [conceived a passion for him and] sought to make him yield himself unto her; and she bolted the doors and said, \"Come thou unto me!\" [But Joseph] answered: \"May God preserve me! Behold, goodly has my master made my stay [in this house]! Verily, to no good end come they that do [such] wrong!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 23,
          "number": 1619,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "And, indeed, she desired him, and he desired her; [and he would have succumbed] had he not seen [in this temptation] an evidence of his Sustainer's truth: thus [We willed it to be] in order that We might avert from him all evil and all deeds of abomination -for, behold, he was truly one of Our servants.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 24,
          "number": 1620,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "And they both rushed to the door; and she [grasped and] rent his tunic from behind-and [lo!] they met her lord at the door! Said she: \"What ought to be the punishment of one who had evil designs on [the virtue of] thy wife - [what] but imprisonment or a [yet more] grievous chastisement?\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 25,
          "number": 1621,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "[Joseph] exclaimed: \"It was she who sought to make me yield myself unto her!\" Now one of those present, a member of her own household, suggested this: If his tunic has been torn from the front, then she is telling the truth, and he is a liar;",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 26,
          "number": 1622,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "but if his tunic has been torn from behind, then she is lying, and he is speaking the truth.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 27,
          "number": 1623,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "And when (her husband] saw that his tunic was torn from behind, he said: \"Behold, this is [an instance] of your guile, O womankind! Verily, awesome is your guile!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 28,
          "number": 1624,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "[But,] Joseph, let this pass! And thou, [O wife,] ask forgiveness for thy sin-for, verily, thou hast been greatly at fault!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 29,
          "number": 1625,
          "juz": 12,
          "numpage": 238
        },
        {
          "text": "NOW the women of the city spoke [thus to one another]: \"The wife of this nobleman is trying to induce her slave-boy to yield himself unto her! Her love for him has pierced her heart; verily, we see that she is undoubtedly suffering from an aberration!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 30,
          "number": 1626,
          "juz": 12,
          "numpage": 238
        }
      ]
    },
    {
      "237": [
        {
          "text": "And so, when they went away with him, they decided to cast him into the dark depths of the well. And We revealed [this] unto him: \"Thou wilt yet remind them of this their deed at a time when they shall not perceive [who thou art]!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 15,
          "number": 1611,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "And at nightfall they came to their father, weeping,",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 16,
          "number": 1612,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "[and] said: \"O our father! Behold, we went off racing with one another, and left Joseph behind with our things; and thereupon the wolf devoured him! But [we know that] thou wouldst not believe us even though we speak the truth\" -",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 17,
          "number": 1613,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "and they produced his tunic with false blood upon it. [But Jacob] exclaimed: \"Nay, but it is your [own] minds that have made [so terrible] a happening seem a matter of little account to you! But [as for myself,] patience in adversity is most goodly [in the sight of God]; and it is to God [alone] that I pray to give me strength to bear the misfortune which you have described to me.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 18,
          "number": 1614,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "AND THERE CAME a caravan; and they sent forth their drawer of water, and he let down his bucket into the well - [and when he saw Joseph] he exclaimed: \"Oh, what a lucky find, this boy!\" And they hid him with a view to selling him: but God had full knowledge of all that they were doing.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 19,
          "number": 1615,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "And they sold him for a paltry price - a mere few silver coins: thus low did they value him.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 20,
          "number": 1616,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "And the man from Egypt who bought him said to his wife: \"Make his stay [with us] honourable; he may well be of use to us, or we may adopt him as a son. And thus We gave unto Joseph a firm place on earth; and [We did this] so that We might impart unto him some understanding of the inner meaning of happenings. For, God always prevails in whatever be His purpose: but most people know it not.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 21,
          "number": 1617,
          "juz": 12,
          "numpage": 237
        },
        {
          "text": "And when he reached full manhood, We bestowed upon him the ability to judge [between right and wrong], as well as [innate] knowledge: for thus do We reward the doers of good.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 22,
          "number": 1618,
          "juz": 12,
          "numpage": 237
        }
      ]
    },
    {
      "236": [
        {
          "text": "[Jacob] replied: \"O my dear son! Do not relate thy dream to thy brothers lest [out of envy] they devise an evil scheme against thee; verily, Satan is man's open foe!",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 5,
          "number": 1601,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "For, [as thou hast been shown in thy dream,] even thus will thy Sustainer elect thee, and will impart unto thee some understanding of the inner meaning of happenings, and will bestow the full measure of His blessings upon thee and upon the House of Jacob -even as, aforetime, He bestowed it in full measure upon thy forefathers Abraham and Isaac. Verily, thy Sustainer is all-knowing, wise!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 6,
          "number": 1602,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "Indeed, in [the story of] Joseph and his brothers there are messages for all who search [after truth].",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 7,
          "number": 1603,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "NOW [Joseph's brothers] spoke [thus to one another:] \"Truly, Joseph and his brother [Benjamin] are dearer to our father than we, even though we are so many. Behold, our father is surely suffering from an aberration!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 8,
          "number": 1604,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "[Said one of them:] \"Slay Joseph, or else drive him away to some [faraway] land, so that your father's regard may be for you alone: and after this is done, you will be [free to repent and to live once again as] righteous people!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 9,
          "number": 1605,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "Another of them said: \"Do not slay Joseph, but -rather - if you must do something - cast him into the dark depths of this well, [whence] some caravan may pick him up.\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 10,
          "number": 1606,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "[On this they agreed; and thereupon] they spoke [thus to their father]: \"O our father! Wherefore wilt thou not trust us with Joseph, seeing that we are indeed his well-wishers?",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 11,
          "number": 1607,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "Let him go out with us tomorrow, that he may enjoy himself and play: and, verily, we shall guard him well!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 12,
          "number": 1608,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "[Jacob] answered: \"Behold, it grieves me indeed [to think] that you might take him with you, for I dread lest the wolf devour him at a moment when you are heedless of him!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 13,
          "number": 1609,
          "juz": 12,
          "numpage": 236
        },
        {
          "text": "Said they: \"Surely, if the wolf were to devour him notwithstanding that we are so many-then, behold, we ought ourselves to perish!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 14,
          "number": 1610,
          "juz": 12,
          "numpage": 236
        }
      ]
    },
    {
      "235": [
        {
          "text": "And had thy Sustainer so willed, He could surely have made all mankind one single community: but [He willed it otherwise, and so] they continue to hold divergent views'",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 118,
          "number": 1591,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "[all of them,] save those upon whom thy Sustainer has bestowed His grace. And to this end has He created them [all. But [as for those who refuse to avail themselves of divine guidance,] that word of thy Sustainer shall be fulfilled: \"Most certainly will I fill hell with invisible beings as well as with humans, all together!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 119,
          "number": 1592,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "AND [remember:] out of all the accounts relating to the [earlier] apostles We convey unto thee [only] that wherewith We [aim to] make firm thy heart: for through these [accounts] comes the truth unto thee, as well as an admonition and a reminder unto all believers.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 120,
          "number": 1593,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "And say unto those who will not believe: \"Do anything that may be within your power, [while] we, behold, shall labour [in God's way];",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 121,
          "number": 1594,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "and wait [for what is coming]: behold, we too are waiting!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 122,
          "number": 1595,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "And God alone comprehends the hidden reality of the heavens and the earth: for, all that exists goes back to Him [as its source]. Worship Him, then, and place thy trust in Him alone: for thy Sustainer is not unaware of what you do.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 123,
          "number": 1596,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "Alif. Lam. Ra. THESE ARE MESSAGES of a revelation clear in itself and clearly showing the truth:",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 1,
          "number": 1597,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "behold, We have bestowed it from on high as a discourse in the Arabic tongue, so that you might encompass it with your reason.",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 2,
          "number": 1598,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "In the measure that We reveal this Qur'an unto thee, [O Prophet,] We explain it to thee in the best possible way, seeing that ere this thou wert indeed among those who are unaware [of what revelation is].",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 3,
          "number": 1599,
          "juz": 12,
          "numpage": 235
        },
        {
          "text": "LO! Thus spoke Joseph unto his father: \"O my father! Behold, I saw [in a dream] eleven stars, as well as the sun and the moon: I saw them prostrate themselves before me!\"",
          "englishName": "Yusuf",
          "suraNumber": 12,
          "numberInSurah": 4,
          "number": 1600,
          "juz": 12,
          "numpage": 235
        }
      ]
    },
    {
      "234": [
        {
          "text": "AND SO, [O Prophet,] be not in doubt about anything that those [misguided people] worship: they but [thoughtlessly] worship as their forefathers worshipped aforetime; and, behold, We shall most certainly give them their full due [for whatever good or evil they have earned], without diminishing aught thereof.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 109,
          "number": 1582,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "And, indeed, [similar was the case when] We vouchsafed the divine writ unto Moses, and some of his people set their own views against it; and had it not been for a decree that had already gone forth from thy Sustainer, judgment would indeed have been passed on them [then and there]: for, behold, they were in grave doubt, amounting to suspicion, about him [who called them unto God].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 110,
          "number": 1583,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "And, verily, unto each and all will thy Sustainer give their full due for whatever [good or evil] they may have done: behold, He is aware of all that they do!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 111,
          "number": 1584,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "Pursue, then, the right course, as thou hast been bidden [by God], together with all who, with thee, have turned unto Him; and let none of you behave in an overweening manner: for, verily, He sees all that you do.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 112,
          "number": 1585,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "And do not incline towards, nor rely upon, those who are bent on evildoing lest the fire [of the hereafter] touch you: for [then] you would have none to protect you from God, nor would you ever be succoured [by Him].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 113,
          "number": 1586,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "And be constant in praying at the beginning and the end of the day, as well as during the early watches of the night: for, verily, good deeds drive away evil deeds: this is a reminder to all who bear [God] in mind.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 114,
          "number": 1587,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "And be patient in adversity: for, verily,. God does not fail to requite the doers of good!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 115,
          "number": 1588,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "BUT, ALAS, among those generations [whom We destroyed] before your time there were no people endowed with any virtue - [people] who would speak out against the [spread of] corruption on earth -except the few of them whom We saved [because of their righteousness], whereas those who were bent on evildoing only pursued pleasures which corrupted their whole being, and so lost themselves in sinning.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 116,
          "number": 1589,
          "juz": 12,
          "numpage": 234
        },
        {
          "text": "For, never would thy Sustainer destroy a community for wrong [beliefs alone] so long as its people behave righteously [towards one another].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 117,
          "number": 1590,
          "juz": 12,
          "numpage": 234
        }
      ]
    },
    {
      "233": [
        {
          "text": "[And so] he shall go before his people on the Day of Resurrection, having led them [in this world] towards the fire [of the life to come]; and vile was the destination towards which they were led -",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 98,
          "number": 1571,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "seeing that they were pursued by [God's] rejection in this [world], and [shall be finally overtaken by it] on the Day of Resurrection; [and] vile was the gift which they were given!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 99,
          "number": 1572,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "THIS ACCOUNT of the [fate of those ancient] communities - some of them still remaining, and some [extinct like] a field mown-down - We convey unto thee [as a lesson for mankind]:",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 100,
          "number": 1573,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "for, no wrong did We do to them, but it was they who wronged themselves. And when thy Sustainer's judgment came to pass, those deities of theirs which they had been wont to invoke instead of God proved of no avail whatever to them, and brought them no more than utter perdition.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 101,
          "number": 1574,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "And such is thy Sustainer's punishing grasp whenever He takes to task any community that is given to evildoing: verily, His punishing grasp is grievous, severe!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 102,
          "number": 1575,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "Herein, behold, lies a message indeed for all who fear the suffering [which may befall them] in the life to come, (and are conscious of the coming of] that Day on which all mankind shall be gathered together-that Day [of Judgment] which shall be witnessed [by all that ever lived],",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 103,
          "number": 1576,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "and which We shall not delay beyond a term set [by Us].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 104,
          "number": 1577,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "When that Day comes, not a soul will speak. unless it be by His leave; and of those [that are gathered together], some will be wretched and some, happy.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 105,
          "number": 1578,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "Now as for those who [by their deeds] will have brought wretchedness upon them-selves, [they shall live] in the fire, where they will have [nothing but] moans and sobs [to relieve their pain],",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 106,
          "number": 1579,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "therein to abide as long as the heavens and the earth endure-unless thy Sustainer wills it otherwise: for, verily, thy Sustainer is a sovereign doer of whatever He wills.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 107,
          "number": 1580,
          "juz": 12,
          "numpage": 233
        },
        {
          "text": "But as for those who [by virtue of their past deeds] will have been blest with happiness, [they shall live] in paradise, therein to abide as long as the heavens and the earth endure -unless thy Sustainer wills it otherwise -as a gift unceasing.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 108,
          "number": 1581,
          "juz": 12,
          "numpage": 233
        }
      ]
    },
    {
      "232": [
        {
          "text": "\"And, O my people, let not [your] dissent from me drive you into sin, lest there befall you the like of what befell the people of Noah, or the people of Hud. or the people of Salih: and [remember that] the people of Lot lived not very far from you!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 89,
          "number": 1562,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "Hence, ask your Sustainer to forgive you your sins, and then turn towards Him in repentance - for, verily, my Sustainer is a dispenser of grace, a fount of love!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 90,
          "number": 1563,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "[But his people] said: \"O Shu'ayb! We cannot grasp the purport of much of what thou sayest; on the other hand, behold, we do see clearly how- weak thou art in our midst:\" and were it not for thy family, we would have most certainly stoned thee to death, considering that thou hast no power over us!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 91,
          "number": 1564,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "Said he: \"O my people! Do you hold my family in greater esteem than God? -for, Him you regard as something that may be cast behind you and be forgotten! Verily, my Sustainer encompasses [with His might] all that you do!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 92,
          "number": 1565,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "Hence, O my people, do [to me] anything that may be within your power, [while] I, behold, shall labour [in God's way]; in time you will come to know which [of us] shall be visited by suffering that will cover him with ignominy, and which [of us] is a liar. Watch, then, [for what is coming:] behold, I shall watch with you!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 93,
          "number": 1566,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "And so, when Our judgment came to pass, by Our grace We saved Shu'ayb and those who shared his faith, whereas the blast [of Our punishment] overtook those who had been bent on evildoing: and then they lay lifeless, in their very homes, on the ground,",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 94,
          "number": 1567,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "as though they had never lived there. Oh, away with [the people of] Madyan, even as the Thamud have been done away with!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 95,
          "number": 1568,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "AND, INDEED, We sent Moses with Our messages and a manifest authority [from Us]",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 96,
          "number": 1569,
          "juz": 12,
          "numpage": 232
        },
        {
          "text": "unto Pharaoh and his great ones: but these followed [only] Pharaoh's bidding - and Pharaoh's bidding led by no means to what is right.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 97,
          "number": 1570,
          "juz": 12,
          "numpage": 232
        }
      ]
    },
    {
      "231": [
        {
          "text": "And so, when Our judgment came to pass, We turned those [sinful towns] upside down, and rained down upon them stone-hard blows of chastisement pre-ordained. one upon another,",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 82,
          "number": 1555,
          "juz": 12,
          "numpage": 231
        },
        {
          "text": "marked out in thy Sustainer's sight [for the punishment of such as are lost in sin]. And these [blows of God-willed doom] are never far from evildoers",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 83,
          "number": 1556,
          "juz": 12,
          "numpage": 231
        },
        {
          "text": "AND UNTO [the people of] Madyan [We sent] their brother Shu'ayb. He said: \"O my people! Worship God [alone]: you have no deity other than Him; and do not give short measure and weight [in any of your dealings with men]. Behold, I see you [now] in a happy state; but, verily, I dread lest suffering befall you on a Day that will encompass [you with doom]!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 84,
          "number": 1557,
          "juz": 12,
          "numpage": 231
        },
        {
          "text": "Hence, O my people, [always] give full measure and weight, with equity, and do not deprive people of what is rightfully theirs, and do not act wickedly on earth by spreading corruption.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 85,
          "number": 1558,
          "juz": 12,
          "numpage": 231
        },
        {
          "text": "That which rests with God is best for you, if you but believe [in Him]! However, I am not your keeper.\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 86,
          "number": 1559,
          "juz": 12,
          "numpage": 231
        },
        {
          "text": "Said they: \"O Shu'ayb! Does thy [habit of] praying compel thee to demand of us that we give up all that our forefathers were wont to worship, or that we refrain from doing whatever we please with our possessions? Behold, [thou wouldst have us believe that] thou art indeed the only clement, the only right-minded man!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 87,
          "number": 1560,
          "juz": 12,
          "numpage": 231
        },
        {
          "text": "He answered: \"O my people! What do you think? If [it be true that] I am taking my stand on a clear evidence from my Sustainer, who has vouchsafed me goodly sustenance [as a gift] from Himself - [how could I speak to you otherwise than I do]? And yet, I have no desire to do, out of opposition to you, what I am asking you not to do: I desire no more than to set things to rights in so far as it lies within my power; but the achievement of my aim depends on God alone. In Him have I placed my trust, and unto Him do I always turn!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 88,
          "number": 1561,
          "juz": 12,
          "numpage": 231
        }
      ]
    },
    {
      "230": [
        {
          "text": "Said she: \"Oh, woe is me! Shall I bear a child, now that I am an old woman and this husband of mine is an old man? Verily, that would be a strange thing indeed!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 72,
          "number": 1545,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "Answered [the messengers]: \"Dost thou deem it strange that God should decree what He wills? The grace of God and His blessings be upon you, O people of this house! Verily, ever to be praised, sublime is He!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 73,
          "number": 1546,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "And when the fear had left Abraham, and the glad tiding had been conveyed to him, he began to plead with Us for Lot's people:",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 74,
          "number": 1547,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "for, behold, Abraham was most clement, most tender-hearted, intent upon turning to God again and again.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 75,
          "number": 1548,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "[But God's messengers replied:] \"O Abraham! Desist from this [pleading]! Behold, thy Sustainer's judgment has already gone forth: and, verily, there shall fall upon them a chastisement which none can avert!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 76,
          "number": 1549,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "AND WHEN Our messengers came unto Lot, he was sorely grieved on their account, seeing that it was beyond his power to shield them; and he exclaimed: \"This is a woeful day!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 77,
          "number": 1550,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "And his people came running to him, impelled towards his house [by their desire]: for they had ever been wont to commit [such], abominations. Said [Lot]: \"O my people! [Take instead] these daughters of mine: they are purer for you [than men]! Be, then, conscious of God, and disgrace me not by [assaulting] my guests. Is there not among you even one right-minded man?\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 78,
          "number": 1551,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "They answered: \"Thou hast always known that we have no use whatever for thy daughters; and, verily, well dost thou know what we want!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 79,
          "number": 1552,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "Exclaimed [Lot]: \"Would that I had the strength to defeat you, or that I could lean upon some mightier support!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 80,
          "number": 1553,
          "juz": 12,
          "numpage": 230
        },
        {
          "text": "[Whereupon the angels] said: \"O Lot! Behold, we are messengers from thy Sustainer! Never shall [thy enemies] attain to thee! Depart, then, with thy household while it is yet night, and let none of you look back; [and take with thee all thy family] with the exception of thy wife: for, behold, that which is to befall these [people of Sodom] shall befall her [as well]. Verily, their appointed time is the morning [and] is not the morning nigh?\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 81,
          "number": 1554,
          "juz": 12,
          "numpage": 230
        }
      ]
    },
    {
      "229": [
        {
          "text": "He retorted: \"O my people! What do you think? If [it be true that] I am taking my stand on a cleat evidence from my Sustainer, who has vouchsafed unto me grace from Himself -[if this be true,] who would shield me from God were I to rebel against Him? Hence, what you are offering me is no more than perdition!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 63,
          "number": 1536,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "And [then he said]: \"O my people! This she-camel belonging to God shall be a token for you: so leave her alone to pasture on God's earth, and do her no harm, lest speedy chastisement befall you!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 64,
          "number": 1537,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "But they cruelly slaughtered her. And thereupon [Salih] said: \"[Only] for three days [more] shall you enjoy life in your homes: this is a judgment which will not be belied!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 65,
          "number": 1538,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "And so, when Our judgment came to pass, by Our grace We saved Salih and those who shared his faith; and [We saved them, too,] from the ignominy of [Our rejection on] that Day [of Resurrection]. Verily, thy Sustainer alone is powerful, almighty!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 66,
          "number": 1539,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "And the blast [of God's punishment] overtook those who had been bent on evildoing: and then they lay lifeless, in their very homes, on the ground,",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 67,
          "number": 1540,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "as though they had never lived there. Oh, verily, [the tribe of] Thamud denied their Sustainer! Oh, away with the Thamud!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 68,
          "number": 1541,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "AND, INDEED, there came unto Abraham Our [heavenly] messengers, bearing a glad tiding. They bade him peace; [and] he answered, \"[And upon you be] peace!\"-and made haste to place before them a roasted calf.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 69,
          "number": 1542,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "But when he saw that their hands did not reach out towards it, he deemed their conduct strange and became apprehensive of them. [But] they said: \"Fear not! Behold, we are sent to the people of Lot.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 70,
          "number": 1543,
          "juz": 12,
          "numpage": 229
        },
        {
          "text": "And his wife, standing [nearby], laughed [with happiness]; whereupon We gave her the glad tiding of [the birth of] Isaac and, after Isaac, of [his son] Jacob.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 71,
          "number": 1544,
          "juz": 12,
          "numpage": 229
        }
      ]
    },
    {
      "228": [
        {
          "text": "We can say no more than that one of our gods may have smitten thee with something evil!\" Answered [Hud]: \"Behold, I call God to witness - and you, too, be [my] witnesses - that, verily, it is not in me to ascribe divinity, as you do, to aught",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 54,
          "number": 1527,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "beside Him! Contrive, then, [anything that you may wish] against me, all of you, and give me no respite!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 55,
          "number": 1528,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "Behold, I have placed my trust in God, [who is] my Sustainer as well as your Sustainer: for there is no living creature which He does not hold by its forelock. Verily, straight is my Sustainer's way!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 56,
          "number": 1529,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "\"But if you choose to turn away, then [know that] I have delivered to you the message with which I was sent unto you, and [that] my Sustainer may cause another people to take your place, whereas you will in no wise harm Him. Verily, my Sustainer watches over all things!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 57,
          "number": 1530,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "And so, when Our judgment came to pass, by Our grace We saved Hud and those who shared his faith; and We saved them [too] from suffering severe [in the life to come].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 58,
          "number": 1531,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "And that was [the end of the tribe of] `Ad, [who] had rejected their Sustainer's messages, and rebelled against His apostles, and followed the bidding of every arrogant enemy of the truth.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 59,
          "number": 1532,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "And they were pursued in this world by [God's] rejection, and [shall finally be overtaken by it] on the Day of Resurrection. Oh, verily, [the tribe of] `Ad denied their Sustainer! Oh, away with the `Ad, the people of Hud!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 60,
          "number": 1533,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "AND UNTO [the tribe of] Thamud [We sent] their brother Salih. He said: \"O my people! Worship God [alone]: you have no deity other than Him. He brought you into being out of the earth, and made you thrive thereon. Ask Him, therefore, to forgive you your sins, and then turn towards Him in repentance-for, verily, my Sustainer is ever-near, responding [to the call of whoever calls unto Him]!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 61,
          "number": 1534,
          "juz": 12,
          "numpage": 228
        },
        {
          "text": "They answered: \"O Salih! Great hopes did we place in thee ere this! Wouldst thou forbid us to worship what our forefathers were wont to worship? Because [of this], behold, we are in grave doubt, amounting to suspicion, about [the meaning of] thy call to us!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 62,
          "number": 1535,
          "juz": 12,
          "numpage": 228
        }
      ]
    },
    {
      "227": [
        {
          "text": "[God] answered: \"O Noah, behold, he was not of thy family, for, verily, he was unrighteous in his conduct. And thou shalt not ask of Me anything whereof thou canst not have any knowledge: thus, behold, do I admonish thee lest thou become one of those who are unaware [of what is right].\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 46,
          "number": 1519,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "Said [Noah]: \"O my Sustainer! Verily, I seek refuge with Thee from [ever again] asking of Thee anything whereof I cannot have any knowledge! For unless Thou grant me forgiveness and bestow Thy mercy upon me, I shall be among the lost!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 47,
          "number": 1520,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "[Thereupon] the word was spoken: \"O Noah! Disembark in peace from Us, and with [Our] blessings upon thee as well as upon the people [who are with thee, and the righteous ones that will spring from thee and] from those who are with thee. But [as for the unrighteous] folk [that will spring from you] - We shall allow them to enjoy life [for a little while], and then there will befall them grievous suffering from Us.\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 48,
          "number": 1521,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "THESE ACCOUNTS of something that was beyond the reach of thy perception We [now] reveal unto thee, [O Muhammad: for] neither thou nor thy people knew them [fully] ere this. Be, then, [like Noah,] patient in adversity - for, behold, the future belongs to the God-conscious!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 49,
          "number": 1522,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "AND UNTO [the tribe of] `Ad [We sent] their brother Hud. He said: \"O my people! Worship God [alone]: you have no deity other than Him. [As it is,] you are but inventors of falsehood!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 50,
          "number": 1523,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "\"O my people! No reward do I ask of you for this [message]: my reward rests with none but Him who brought me into being. Will you not, then, use your reason?",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 51,
          "number": 1524,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "\"Hence, O my people, ask your Sustainer to forgive you your sins, and then turn towards Him in repentance-[whereupon] He will shower upon you heavenly blessings abundant, and will add strength to your strength: only do not turn away [from me] as people lost in sin!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 52,
          "number": 1525,
          "juz": 12,
          "numpage": 227
        },
        {
          "text": "Said they: \"O Hud! Thou hast brought us no clear evidence [that thou art a prophet]; and we are not going to forsake our gods on thy mere word, the more so as we do not believe thee.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 53,
          "number": 1526,
          "juz": 12,
          "numpage": 227
        }
      ]
    },
    {
      "226": [
        {
          "text": "And [so Noah] set himself to building the ark; and every time the great ones of his people passed by him, they scoffed at him. [Thereupon] he said: \"If you are scoffing at us-behold, we are scoffing at you [and your ignorance], just as you are scoffing at us.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 38,
          "number": 1511,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "But in time you will come to know who it is that [in this world] shall be visited by suffering which will cover him with ignominy, and upon whom long lasting suffering shall alight [in the life to come]!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 39,
          "number": 1512,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "[And so it went on] till, when Our judgment came to pass, and waters gushed forth in torrents over the face of the earth, We said [unto Noah]: \"Place on board of this [ark] one pair of each [kind of animal] of either sex, as well as thy family -except those on whom [Our] sentence has already been passed -and all [others] who have attained to faith!\"-for, only a few [of Noah's people] shared his faith.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 40,
          "number": 1513,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "So he said [unto his followers]: \"Embark in this [ship]! In the name of God be its run and its riding at anchor! Behold, my. Sustainer is indeed much-forgiving, a dispenser of grace!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 41,
          "number": 1514,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "And it moved on with them into waves that were like mountains. At that [moment] Noah cried out to a son of his, who had kept himself aloof [from the others]: \"O my dear son! Embark with us, and remain not with those who deny the truth!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 42,
          "number": 1515,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "[But the son] answered: \"I shall betake myself to a mountain that will protect me from the waters.\" Said [Noah]: \"Today there is no protection [for anyone] from God's judgment, save [for] those who have earned [His] mercy!\" And a wave rose up between them, and [the son] was among those who were drowned.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 43,
          "number": 1516,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "And the word was spoken: \"O earth, swallow up thy waters! And, O sky, cease [thy rain]!\" And the waters sank into the earth, and the will [of God] was done, and the ark came to rest on Mount Judl And the word was spoken: \"Away with these evildoing folk!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 44,
          "number": 1517,
          "juz": 12,
          "numpage": 226
        },
        {
          "text": "And Noah called out to his Sustainer, and said: \"O my Sustainer! Verily, my son was of my family; and, verily, Thy promise always comes true, and Thou art the most just of all judges!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 45,
          "number": 1518,
          "juz": 12,
          "numpage": 226
        }
      ]
    },
    {
      "225": [
        {
          "text": "\"And, O my people, no benefit do I ask of you for this [message]: my reward rests with none but God. And I shall not repulse [any of] those who have attained to faith. Verily, they [know that they] are destined to meet their Sustainer, whereas in you I see people without any awareness [of right and wrong]!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 29,
          "number": 1502,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "And, O my people, who would shield me from God were I to repulse them? Will you not, then, keep this in mind?",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 30,
          "number": 1503,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "\"And I do not say unto you, `God's treasures are with me'; nor [do I say], `I know the reality which is beyond the reach of human perception'; nor do I say, 'Behold, I am an angel'; nor do I say of those whom your eyes hold in contempt, `Never will God grant them any good'- for God is fully aware of what is in their hearts. [Were I to speak thus,] verily, I would indeed be among the evildoers.\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 31,
          "number": 1504,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "[But the great ones] said: \"O Noah! Thou hast contended with us in argument, and hast [needlessly] prolonged our controversy: bring upon us, therefore, that with which thou dost threaten us, if thou art a man of truth!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 32,
          "number": 1505,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "He answered: \"Only God can bring it upon you, if He so wills, and you shall not elude it:",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 33,
          "number": 1506,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "for, my advice will not benefit you -much as I desire to give you good advice - if it be God's will that you shall remain lost in grievous error. He is your Sustainer, and unto Him you must return.\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 34,
          "number": 1507,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "DO SOME, perchance, assert, \"[Muhammad] has invented this [story]\"? Say [O Prophet]: \"If I have invented it, upon me be this sin; but far be it from me to commit the sin of which you are guilty.\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 35,
          "number": 1508,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "AND THIS was revealed unto Noah: \"Never will any of thy people believe except those who have already attained to faith. Be not, then, distressed by anything that they may do,",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 36,
          "number": 1509,
          "juz": 12,
          "numpage": 225
        },
        {
          "text": "but build, under Our eyes and according to Our inspiration, the ark [that shall save thee and those who follow thee]; and do not appeal to Me in behalf of those who are bent on evildoing for, behold, they are destined to be drowned!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 37,
          "number": 1510,
          "juz": 12,
          "numpage": 225
        }
      ]
    },
    {
      "224": [
        {
          "text": "Never can they elude [their final reckoning, even if they remain unscathed] on earth: never will they find anyone who could protect them from God. [In the life to come] double suffering will be imposed on them for having lost the ability to hear [the truth] and having failed to see [it].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 20,
          "number": 1493,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "It is they who have squandered their own selves-for [on the Day of Resurrection] all their false imagery will have forsaken them:",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 21,
          "number": 1494,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "truly it is they, they who in the life to come shall be the losers!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 22,
          "number": 1495,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "Behold, [only] those who attain to faith and do righteous deeds and humble themselves before their Sustainer - [only] they are destined for paradise, and there shall they abide.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 23,
          "number": 1496,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "These two kinds of man may be likened to the blind and deaf and the seeing and hearing. Can these two be deemed alike in [their] nature? ` Will you not, then, keep this in mind?",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 24,
          "number": 1497,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "AND INDEED, [it was with the same message that] We sent forth Noah unto his people: \"Behold, I come unto you with the plain warning",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 25,
          "number": 1498,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "that you may worship none but God-for, verily, I fear lest suffering befall you on a grievous Day!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 26,
          "number": 1499,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "But the great ones among his people, who refused to acknowledge the truth, answered: \"We do not see in thee anything but a mortal man like ourselves; and we do not see that any follow thee save those who are quite obviously the most abject among us; and we do not see that you could be in any way superior to us: on the contrary, we think that you are liars!\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 27,
          "number": 1500,
          "juz": 12,
          "numpage": 224
        },
        {
          "text": "Said [Noah]: \"O my people! What do you think? If [it be true that I am taking my stand on a clear evidence from my~ Sustainer, who has vouchsafed unto me grace from Himself - [a revelation] to which you have remained blind-: [if this be true,] can we force it on you even though it be hateful to you?",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 28,
          "number": 1501,
          "juz": 12,
          "numpage": 224
        }
      ]
    },
    {
      "223": [
        {
          "text": "and so they assert, \"[Muhammad himself] has invented this [Qur'an)!\" Say [unto them]: \"Produce, then, ten surahs of similar merit, invented [by yourselves], and [to this end] call to your aid whomever you can, other than, God, if what you say is true!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 13,
          "number": 1486,
          "juz": 12,
          "numpage": 223
        },
        {
          "text": "And if they [whom you have called to your aid] are not able to help you, then know that [this Qur'an] has been bestowed from on high out of God's wisdom alone, and that there is no deity save Him. Will you, then, surrender yourselves unto Him?\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 14,
          "number": 1487,
          "juz": 12,
          "numpage": 223
        },
        {
          "text": "AS FOR THOSE who care for [no more than] the life of this world and its bounties -We shall repay them in full for all that they did in this [life], and they, shall not be deprived of their just due therein:",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 15,
          "number": 1488,
          "juz": 12,
          "numpage": 223
        },
        {
          "text": "[yet] it is they who, in the life to come, shall have nothing but the fire -for in vain shall be all that they wrought in this [world], and worthless all that they ever did!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 16,
          "number": 1489,
          "juz": 12,
          "numpage": 223
        },
        {
          "text": "Can, then, [he who cares for no more than the life of this world be compared with] one who takes his stand on a clear evidence from his Sustainer, conveyed through [this] testimony from Him, as was the revelation vouchsafed to Moses aforetime-[a divine writ ordained by Him] to be a guidance and grace [unto man]? They [who understand this message-it is they alone who truly] believe in it; whereas for any of those who, leagued together [in common hostility], deny its truth - the fire shall be their appointed state [in the life to come]. And so, be not in doubt about this [revelation]: behold, it is the truth from thy Sustainer, even though most people will not believe in it.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 17,
          "number": 1490,
          "juz": 12,
          "numpage": 223
        },
        {
          "text": "And who could be more wicked than they who attribute their own lying inventions to God? [On the Day of Judgment, such as] these shall be arraigned before their Sustainer, and those who are called upon to bear witness [against them] shall say, \"It is they who uttered lies about their Sustainer!\" Oh, verily, God's rejection is the due of all evildoers",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 18,
          "number": 1491,
          "juz": 12,
          "numpage": 223
        },
        {
          "text": "who turn others away from the path of God and try to make it appear crooked -since it is they, they who refuse to acknowledge the truth of the life to come!",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 19,
          "number": 1492,
          "juz": 12,
          "numpage": 223
        }
      ]
    },
    {
      "222": [
        {
          "text": "And there is no living creature on earth but depends for its sustenance on God; and He knows its time-limit [on earth] and its resting-place [after death]: all [this] is laid down in [His] clear decree.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 6,
          "number": 1479,
          "juz": 12,
          "numpage": 222
        },
        {
          "text": "And He it is who has created the heavens and the earth in six aeons; and [ever since He has willed to create life,] the throne of His almightiness has rested upon water. [God reminds you of your dependence on Him] in order to test you [and thus to make manifest] which of you is best in conduct. For thus it is: if thou sayest [unto men], \"Behold, you shall be raised again after death!\" - they who are bent on denying the truth are sure to answer, \"This is clearly nothing but an enchanting delusion\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 7,
          "number": 1480,
          "juz": 12,
          "numpage": 222
        },
        {
          "text": "And thus it is: if We defer their suffering until a time-limit set [by Us], they are sure to say, \"What is preventing it [from coming now]?\" Oh, verily, on the Day when it befalls them there will be nothing to avert it from them; and they shall be overwhelmed by the very thing which they were wont to deride.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 8,
          "number": 1481,
          "juz": 12,
          "numpage": 222
        },
        {
          "text": "And thus it is: if We let man taste some of Our grace, and then take it away from him -behold, he abandons all hope, forgetting all gratitude [for Our past favours].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 9,
          "number": 1482,
          "juz": 12,
          "numpage": 222
        },
        {
          "text": "And thus it is: if We let him taste ease and plenty after hardship has visited him, he is sure to say, \"Gone is all affliction from me!\"-for, behold, he is given to vain exultation, and glories only in himself.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 10,
          "number": 1483,
          "juz": 12,
          "numpage": 222
        },
        {
          "text": "[And thus it is with most men-] save those who are patient in adversity and do righteous deeds: it is they whom forgiveness of sins awaits, and a great reward.",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 11,
          "number": 1484,
          "juz": 12,
          "numpage": 222
        },
        {
          "text": "IS IT, then, conceivable [O Prophet] that thou couldst omit any part of what is being revealed unto thee [because the deniers of the truth dislike it,-and] because thy heart is distressed at their saying, \"Why has not a treasure been bestowed upon him from on high?\" -or, \"[Why has not] an angel come [visibly:: with him?\" [They fail to understand that] thou art only a warner, whereas God has everything in His care;",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 12,
          "number": 1485,
          "juz": 12,
          "numpage": 222
        }
      ]
    },
    {
      "221": [
        {
          "text": "And [know that] if God should touch thee with misfortune, there is none who could remove it save Him; and if He intends good for thee, there is none who could turn away His bounty: He causes it to alight upon whomsoever He wills of His servants. And He alone is truly forgiving, truly a dispenser of grace.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 107,
          "number": 1471,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "SAY [O Prophet]: \"O mankind! The truth from your Sustainer has now come unto you. Whoever, therefore, chooses to follow the right path, follows it but for his own good; and whoever chooses to go astray, goes but astray to his own hurt. And I am not responsible for your conduct.\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 108,
          "number": 1472,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "And [as for thyself, O Muhammad,] follow but what is being revealed unto thee, and be patient in adversity, until God shall give His judgment: for He is the best of all judges.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 109,
          "number": 1473,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "Alif. Lam. Ra. A DIVINE WRIT [is this], with messages that have been made clear in and by themselves, and have been distinctly spelled out as well - [bestowed upon you] out of the grace of One who is wise, all-aware,",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 1,
          "number": 1474,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "so that you may worship none but God. [Say, O Prophet:] \"Behold, I come unto you from Him [as] a warner and a bearer of glad tidings:",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 2,
          "number": 1475,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "Ask your Sustainer to forgive you your sins, and then turn towards Him in repentance - [whereupon] He will grant you a goodly enjoyment of life [in this world] until a term set [by Him is fulfilled]; and [in the life to come] He will bestow upon everyone possessed of merit [a full reward for] his merit. But if you turn away, then, verily, I dread for you the suffering [which is bound to befall you] on that awesome Day",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 3,
          "number": 1476,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "Unto God you all must return: and He has the power to will anything.\"",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 4,
          "number": 1477,
          "juz": 11,
          "numpage": 221
        },
        {
          "text": "Oh, verily, they [who are bent on denying the truth of this divine writ] are enshrouding their hearts in order to hide from Him. Oh, verily, [even] when they cover themselves with their garments [in order not to see or hear], He knows all that they keep secret as well as all that they bring into the open - for, behold, He has full knowledge of what is in the hearts [of men].",
          "englishName": "Hud",
          "suraNumber": 11,
          "numberInSurah": 5,
          "number": 1478,
          "juz": 11,
          "numpage": 221
        }
      ]
    },
    {
      "220": [
        {
          "text": "For, alas, there has never yet been any community that attained to faith [in its entirety,] and thereupon benefited by its faith, except the people of Jonah. When they came to believe, We removed from them the suffering of disgrace [which otherwise would have befallen them even] in the life of this world, and allowed them to enjoy their life during the time allotted to them.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 98,
          "number": 1462,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "And [thus it is:] had thy Sustainer so willed, all those who live on earth would surely have attained to faith, all of them: dost thou, then, think that thou couldst compel people to believe,",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 99,
          "number": 1463,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "notwithstanding that no human being can ever attain to faith otherwise than by God's leave, and [that] it is He who lays the loathsome evil [of disbelief] upon those who will not use -their reason?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 100,
          "number": 1464,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "Say: \"Consider whatever there is in the heavens and on earth!\" But of what avail could all the messages and all the warnings be to people who will not believe?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 101,
          "number": 1465,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "Can such, then, expect anything else [to befall them] but the like of the days of [calamity which befell] those [deniers of the truth] who passed away before them? Say: \"Wait, then, [for what will happen) verily, I shall wait with you!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 102,
          "number": 1466,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "[For thus it always happens: We seal the doom of all who deny the truth and give the lie to Our messages;] and thereupon We save Our apostles and those who have attained to faith. Thus have We willed it upon Ourselves: We save all who believe [in Us],",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 103,
          "number": 1467,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "SAY [O Prophet]: \"O mankind! If you are in doubt as to what my faith is, then [know that] I do not worship those beings whom you worship beside God, but [that] I worship God alone, who shall cause you [all] to die: for I have been bidden to be among those who believe [in Him alone].\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 104,
          "number": 1468,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "Hence, [O man,] set thy face steadfastly towards the [true] faith, turning away from all that is false, and be not among those who ascribe divinity to aught beside God.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 105,
          "number": 1469,
          "juz": 11,
          "numpage": 220
        },
        {
          "text": "Thus, do not invoke, side by side with God, anything that can neither benefit thee nor harm thee: for, behold, if thou do it, thou wilt surely be among the evildoers!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 106,
          "number": 1470,
          "juz": 11,
          "numpage": 220
        }
      ]
    },
    {
      "219": [
        {
          "text": "[God] answered: \"Accepted is this your prayer! Continue, then, both of you, steadfastly on the right way, and follow not the path of those who have no knowledge [of right and wrong].\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 89,
          "number": 1453,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "And We brought the children of Israel across the sea; and thereupon Pharaoh and his hosts pursued them with vehement insolence and tyranny, until [they were overwhelmed by the waters of the sea. And] when he was about to drown, [Pharaoh] exclaimed: \"I have come to believe that there is no deity save Him in whom the children of Israel believe, and I am of those who surrender themselves unto Him!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 90,
          "number": 1454,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "[But God said:] \"Now? -when ever before this thou hast been rebelling [against Us], and hast been among those who spread corruption?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 91,
          "number": 1455,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "[Nay,] but today We shall save only thy body, so that thou mayest be a [warning] sign unto those who will come after thee: for, behold, a good many people are heedless of Our messages!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 92,
          "number": 1456,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "And [thereafter], indeed, We assigned unto the children of Israel a most goodly abode, and provided for them sustenance out of the good things of life. And it was not until knowledge [of God's revelation] was vouchsafed to them that they began to hold divergent views: [but,] verily, thy Sustainer will judge between them on Resurrection Day regarding all on which they were wont to differ.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 93,
          "number": 1457,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "AND SO, [O man,] if thou art in doubt about [the truth of] what We have [now] bestowed upon thee from on high, ask those who read the divine writ [revealed] before thy time: [and thou wilt find that,] surely, the truth has now come unto thee from thy Sustainer. Be not, then, among the doubters -",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 94,
          "number": 1458,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "and neither be among those who are bent on giving the lie to God's messages, lest thou find thyself among the lost.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 95,
          "number": 1459,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "Verily, they against whom thy Sustainer's word [of judgment] has come true will not attain to faith -",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 96,
          "number": 1460,
          "juz": 11,
          "numpage": 219
        },
        {
          "text": "even though every sign [of the truth] should come within their ken - until they behold the grievous suffering [that awaits them in the life to come].",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 97,
          "number": 1461,
          "juz": 11,
          "numpage": 219
        }
      ]
    },
    {
      "218": [
        {
          "text": "And Pharaoh commanded: \"Bring before me every sorcerer of great knowledge!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 79,
          "number": 1443,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "And when the sorcerers came, Moses said unto them: \"Throw whatever you may [wish to] throw!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 80,
          "number": 1444,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "And when they threw down [their staffs and cast a spell upon the people's eyes], Moses said unto them: \"What you have contrived is [but] sorcery which, verily, God will bring to nought! Verily, God does not further the works of spreaders of corruption -",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 81,
          "number": 1445,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "whereas by His words God proves the truth to be true, however hateful this may be to those who are lost in sin!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 82,
          "number": 1446,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "But none save a few of his people declared their faith in Moses, [while others held back] for fear of Pharaoh and their great ones, lest they persecute them: for, verily, Pharaoh was mighty on earth and was, verily, of those who are given to excesses.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 83,
          "number": 1447,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "And Moses said: \"O my people! If you believe in God, place your trust in Him -if you have [truly] surrendered yourselves unto Him!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 84,
          "number": 1448,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "Whereupon they answered: \"In God have we placed our trust! O our Sustainer, make us not a plaything for evildoing folk,",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 85,
          "number": 1449,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "and save us, by Thy grace, from people who deny the truth!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 86,
          "number": 1450,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "And [thus] did We inspire Moses and his brother: \"Set aside for your people some houses in the city, and [tell them], `Turn your houses into places of worship, and be constant in prayer!' And give thou [O Moses] the glad tiding [of God's succour] to all believers.\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 87,
          "number": 1451,
          "juz": 11,
          "numpage": 218
        },
        {
          "text": "And Moses prayed: \"O our Sustainer! Verily, splendour and riches hast Thou vouchsafed, in the life of this world, unto Pharaoh and his great ones-with the result, O our Sustainer, that they are leading [others] astray from Thy path! O our Sustainer! Wipe out their riches, and harden their hearts, so that they may not attain to faith ere they see the grievous suffering [that awaits them]!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 88,
          "number": 1452,
          "juz": 11,
          "numpage": 218
        }
      ]
    },
    {
      "217": [
        {
          "text": "AND CONVEY unto them the story of Noah-when he said unto his people: \"O my people! If my presence [among you] and my announcement of God's messages are repugnant to you -well, in God have I placed my trust. Decide, then, upon what you are going to do [against me], and [call to your aid] those beings to whom you ascribe a share in God's divinity; and once you have chosen your course of action, let no hesitation deflect you from it; and then carry out against me [whatever you may have decided], and give me no respite!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 71,
          "number": 1435,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "But if you turn away [from the message which I bear, remember that] I have asked no reward whatever of you: my reward rests with none but God, for I have been bidden to be among those who have surrendered themselves unto Him.\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 72,
          "number": 1436,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "And yet they gave him the lie! And so We saved him and all who stood by him, in the ark, and made them inherit [the earth], the while We caused those who gave the lie to Our messages to drown: behold, then, what happened in the end to those people who had been warned [in vain]!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 73,
          "number": 1437,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "AND THEN, after him, We sent forth [other] apostles-each one unto his own people -and they brought them all evidence of the truth; but they would not believe in anything to which they had once given the lie: thus it is that We seal the hearts of such as [are wont to] transgress the bounds of what is right.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 74,
          "number": 1438,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "And after those [earlier prophets] We sent Moses and Aaron with Our messages unto Pharaoh and his great ones: but they gloried in their arrogance, for they were people lost in sin.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 75,
          "number": 1439,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "And so, when the truth came to them from Us, they said, \"Behold, this is clearly nothing but sorcery!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 76,
          "number": 1440,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "Said Moses: \"Do you speak thus of the truth after it has been brought to you? Can this be sorcery? But sorcerers can never come to a happy end!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 77,
          "number": 1441,
          "juz": 11,
          "numpage": 217
        },
        {
          "text": "[The great ones] replied: \"Hast thou come to turn us away from what we found our forefathers believing in and doing, so that the two of you might become supreme in this land? However, we do not believe in you two!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 78,
          "number": 1442,
          "juz": 11,
          "numpage": 217
        }
      ]
    },
    {
      "216": [
        {
          "text": "Oh, verily, they who are close to God - no fear need they have, and neither shall they grieve:",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 62,
          "number": 1426,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "they who have attained to faith and have always been conscious of Him.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 63,
          "number": 1427,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "For them there is the glad tiding [of happiness] in the life of this world and in the life to come; [and since] nothing could ever alter [the outcome of] God's promises, this, this is the triumph supreme!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 64,
          "number": 1428,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "And be not grieved by the sayings of those [who deny the truth]. Behold, all might and glory belong to God alone: He alone is all-hearing, all-knowing.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 65,
          "number": 1429,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "OH, VERILY, unto God belongs whoever is in the heavens and whoever is on earth: hence, what is it that they follow-those who invoke, beside God. beings to whom they ascribe a share in His divinity? They follow but the conjectures [of others], and themselves do nothing but guess -",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 66,
          "number": 1430,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "[whereas] it is He who has made the night for you, so that you might have rest therein, and the day, to make [you] see in this, behold, there are messages indeed for people who [are willing to] listen.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 67,
          "number": 1431,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "[And yet] they assert, \"God has taken unto Himself a son!\" Limitless is He in His glory! Self-sufficient is He: unto Him belongs all that is in the heavens and all that is on earth! No evidence whatever have you for this [assertion]! Would you ascribe unto God something which you cannot know?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 68,
          "number": 1432,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "Say: \"Verily, they who attribute their own lying inventions to God will never attain to a happy state!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 69,
          "number": 1433,
          "juz": 11,
          "numpage": 216
        },
        {
          "text": "[A brief] enjoyment in this world-and thereafter unto Us they must return: and then We shall let them taste suffering severe as an outcome of their persistent denial of the truth.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 70,
          "number": 1434,
          "juz": 11,
          "numpage": 216
        }
      ]
    },
    {
      "215": [
        {
          "text": "And all human beings that have been doing evil's would surely, if they possessed all that is on earth, offer it as ransom [on Judgment Day]; and when they see the suffering [that awaits them], they will be unable to express their remorse. But judgment will be passed on them in all equity; and they will not be wronged.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 54,
          "number": 1418,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "Oh, verily, unto God belongs all that is in the heavens and on earth! Oh, verily, God's promise always comes true-but most of them know it not!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 55,
          "number": 1419,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "He alone grants life and deals death; and unto Him you all must return.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 56,
          "number": 1420,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "O MANKIND! There has now come unto you an admonition from your Sustainer, and a cure for all [the ill] that may be in men's hearts, and guidance and grace unto all who believe [in Him].",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 57,
          "number": 1421,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "Say: \"In [this] bounty of God and in His grace-in this, then, let them rejoice: it is better than all [the worldly wealth] that they may amass!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 58,
          "number": 1422,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "Say: \"Have you ever considered all the means of sustenance which God has bestowed upon you from on high -and which you thereupon divide into 'things forbidden' and 'things lawful? Say: \"Has God given you leave [to do this] -or do you, perchance, attribute your own guesswork to God?\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 59,
          "number": 1423,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "But what do they think-they who attribute their own lying inventions to God-[what do they think will happen to them]. on the Day of Resurrection? Behold, God is indeed limitless in His bounty unto men -but most of them are ungrateful.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 60,
          "number": 1424,
          "juz": 11,
          "numpage": 215
        },
        {
          "text": "AND IN whatever condition thou mayest find thyself, [O Prophet,] and whatever discourse of this [divine writ] thou mayest be reciting, and whatever work you [all, O men,] may do -[remember that] We are your witness [from the moment] when you enter upon it: for, not even an atom's weight [of whatever there is] on earth or in heaven escapes thy Sustainer's knowledge; and neither is there anything smaller than that, or larger, but is recorded in [His] clear decree.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 61,
          "number": 1425,
          "juz": 11,
          "numpage": 215
        }
      ]
    },
    {
      "214": [
        {
          "text": "And there are among them such as [pretend to] look towards thee: but canst thou show the right way to the blind even though they cannot see?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 43,
          "number": 1407,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "Verily, God does not do the least wrong unto men, but it is men who wrong themselves.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 44,
          "number": 1408,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "And on the Day when He shall gather them [unto Himself, it will seem to them] as if they had not tarried [on earth] longer than an hour of a day, knowing one another; [and] lost indeed will be they who [in their lifetime] considered it a lie that they were destined to meet God, and [thus] failed to find the right' way.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 45,
          "number": 1409,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "And whether We show thee [in this world something of what We hold in store for those [deniers of the truth], or whether We cause thee to die [before that retribution takes place - know that, in the end], it is unto Us that they must return; and God is witness to all that they do.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 46,
          "number": 1410,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "NOW every community has had an apostle; and only after their apostle has appeared [and delivered his message] is judgment passed on them, in all equity; and never are they wronged.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 47,
          "number": 1411,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "And yet, they [who deny the truth] are wont to ask, \"When is that promise [of resurrection and judgment] to be fulfilled? [Answer this, O you who believe in it,] if you are men of truth!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 48,
          "number": 1412,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "Say [O Prophet]: \"It is not within my power to avert harm from, or bring benefit to, myself, except as God may please. For all people a term has been set: when the end of their term approaches, they can neither delay it by a single moment, nor hasten it.\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 49,
          "number": 1413,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "Say: \"Have you ever considered [how you would feel] if His chastisement were to befall you by night or by day? What could there be in that prospect that people lost in sin should wish to hasten?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 50,
          "number": 1414,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "Will you, then, believe in it [only] after it has come to pass -[on the Day when you will be asked, `Do you believe in it] now, after having [contemptuously] called for its speedy advent?'",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 51,
          "number": 1415,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "-whereupon those who [in their lifetime] were bent on evildoing will be told, `Taste suffering abiding! Is this requital anything but the just due for what you were wont to do?\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 52,
          "number": 1416,
          "juz": 11,
          "numpage": 214
        },
        {
          "text": "And some people ask thee, \"Is all this true?\" Say: \"Yea, by my Sustainer! It is most certainly true, and you cannot elude [the final reckoning]!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 53,
          "number": 1417,
          "juz": 11,
          "numpage": 214
        }
      ]
    },
    {
      "213": [
        {
          "text": "Say: \"Can any of those beings to whom you ascribe a share in God's divinity create [life] in the first instance, and then bring it forth anew?” Say: \"It is God [alone] who creates [all life] in the first instance, and then brings it forth anew. How perverted, then, are your minds!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 34,
          "number": 1398,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "Say: \"Does any of those beings to whom you ascribe a share in God's divinity guide unto the truth?\" Say: \"It is God [alone] who guides unto the truth. Which, then, is more worthy to be followed - He who guides unto the truth, or he who cannot find the right way unless he is guided? What, then, is amiss with you and your judgment?\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 35,
          "number": 1399,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "For, most of them follow nothing but conjecture: [and,] behold, conjecture can never be a substitute for truth. Verily, God has full knowledge of all that they do.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 36,
          "number": 1400,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "Now this Qur'an could not possibly have been devised by anyone save God: nay indeed, it confirms the truth of whatever there still remains [of earlier revelations] and clearly spells out the revelation [which comes] -let there be no doubt about it-from the Sustainer of all the worlds.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 37,
          "number": 1401,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "And yet, they [who are bent on denying the truth] assert, \"[Muhammad] has invented it!\" Say [unto them]: \"Produce, then, a surah of similar merit; and [to this end] call to your aid whomever you can, other than God, if what you say is true!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 38,
          "number": 1402,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "Nay, but they are bent on giving the lie to everything the wisdom whereof they do not comprehend, and ere its inner meaning has become clear to them Even thus did those who lived before their time give the lie to the truth: and behold what happened in the end to those evildoers!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 39,
          "number": 1403,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "And there are among them such as will in time come to believe in this [divine writ], just as there are among them such as will never believe in it; and thy Sustainer is fully aware as to who are the spreaders of corruption.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 40,
          "number": 1404,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "And [so, O Prophet,] if they give thee the lie, say: \"To me [shall be accounted] my doings, and to you, your doings: you are not accountable for what I am doing, and I am not accountable for whatever you do.\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 41,
          "number": 1405,
          "juz": 11,
          "numpage": 213
        },
        {
          "text": "And there are among them such as (pretend to] listen to thee: but canst thou cause the deaf to hearken even though they will not use their reason?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 42,
          "number": 1406,
          "juz": 11,
          "numpage": 213
        }
      ]
    },
    {
      "212": [
        {
          "text": "For those who persevere in doing good there is the ultimate good in store, and more [than that]. No darkness and no ignominy will overshadow their faces [on Resurrection Day]: it is they who are destined for paradise, therein to abide.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 26,
          "number": 1390,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "But us for those who have done evil deeds - the recompense of an evil, deed shall be the like thereof: and - since they will have none to defend them against God - ignominy will overshadow them as though their faces were veiled by the night's own darkness: it is they who are destined for the fire. therein to abide.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 27,
          "number": 1391,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "For, one Day We shall gather them all together, and then We shall say unto those who [in their lifetime] ascribed divinity to aught but God, \"Stand where you are, you and those [beings and powers] to whom you were wont to ascribe a share in God's divinity! -for by then We shall have [visibly] separated them from one another. And the beings to whom they had ascribed a share in God's divinity will say [to those who had worshipped them], \"It was not us that you were wont to worship;",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 28,
          "number": 1392,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "and none can bear witness between us and you as God does: we were, for certain, unaware of your worshipping [us].",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 29,
          "number": 1393,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "There and then will every human being clearly apprehend what he has done in the past; and all will be brought back unto God, their true Lord Supreme, and all their false imagery will have forsaken them.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 30,
          "number": 1394,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "SAY: \"Who is it that provides you with sustenance out of heaven and earth, or who is it that has full power over [your] hearing and sight? And who is it that brings forth the living out of that which is dead, and brings forth the dead out of that which is alive? And who is it that governs all that exists?\" And they will [surely] answer: \"[It is] God.\" Say, then: \"Will you not, then, become [fully] conscious of Him-",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 31,
          "number": 1395,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "seeing that He is God, your Sustainer, the Ultimate Truth? For, after the truth [has been forsaken], what is there [left] but error? How, then, can you lose sight of the truth?\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 32,
          "number": 1396,
          "juz": 11,
          "numpage": 212
        },
        {
          "text": "Thus is thy Sustainer's word proved true with regard to such as are bent on sinful doings: they will not believes",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 33,
          "number": 1397,
          "juz": 11,
          "numpage": 212
        }
      ]
    },
    {
      "211": [
        {
          "text": "And [thus it is:] whenever We let [such] people taste [some of Our] grace after hardship has visited them-lo! they forthwith turn to devising false arguments against Our messages. Say: \"God is swifter [than you] in His deep devising!\" Behold, Our [heavenly] messengers are recording all that you may devise!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 21,
          "number": 1385,
          "juz": 11,
          "numpage": 211
        },
        {
          "text": "He it is who enables you to travel on land and sea. And [behold what happens] when you go to sea in ships: [they go to sea in ships,] and they sail on in them in a favourable wind, and they rejoice thereat until there comes upon them a tempest, and waves surge towards them from all sides, so that they believe themselves to be encompassed [by death; and then] they call unto God, [at that moment] sincere in their faith in Him alone, \"If Thou wilt but save us from this, we shall most certainly be among the grateful!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 22,
          "number": 1386,
          "juz": 11,
          "numpage": 211
        },
        {
          "text": "Yet as soon as He has saved them from this [danger], lo! they behave outrageously on earth, offending against all right! O men! All your outrageous deeds are bound to fall back upon your own selves! [You care only for] the enjoyment of life in this world: [but remember that] in the end unto Us you must return, whereupon We shall make you truly understand all that you were doing [in life].",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 23,
          "number": 1387,
          "juz": 11,
          "numpage": 211
        },
        {
          "text": "The parable of the life of this world is but that of rain which We send down from the sky, and which is absorbed by the plants of the earth whereof men and animals draw nourishment, until -when the earth has assumed its artful adornment and has been embellished, and they who dwell on it believe that they have gained mastery over it -there comes down upon it Our judgment, by night or by day, and We cause it to become [like] a field mown down, as if there had been no yesterday. Thus clearly do We spell out these messages unto people who think!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 24,
          "number": 1388,
          "juz": 11,
          "numpage": 211
        },
        {
          "text": "AND [know that] God invites [man] unto the abode of peace, and guides him that wills [to be guided] onto a straight way.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 25,
          "number": 1389,
          "juz": 11,
          "numpage": 211
        }
      ]
    },
    {
      "210": [
        {
          "text": "AND [thus it is:] whenever Our messages are conveyed unto them in all their clarity, those who do not believe that they are destined to meet Us [are wont to] say, \"Bring us a discourse other than this, or alter this one.” Say [O Prophet]: \"It is not conceivable that I should alter it of my own volition; I only follow what is revealed to me. Behold, I would dread, were I [thus] to rebel against my Sustainer, the suffering [which would befall me] on that awesome Day [of Judgment]!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 15,
          "number": 1379,
          "juz": 11,
          "numpage": 210
        },
        {
          "text": "Say: \"Had God willed it [otherwise], I would not have conveyed this [divine writ] unto you, nor would He have brought it to your knowledge. Indeed. a whole lifetime have I dwelt among you ere this [revelation came unto me]: will you not, then, use your reason?”",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 16,
          "number": 1380,
          "juz": 11,
          "numpage": 210
        },
        {
          "text": "And who could be more wicked than they who attribute their own lying inventions to God or give the lie to His messages? Verily, those who are lost in sin will never attain to a happy state -",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 17,
          "number": 1381,
          "juz": 11,
          "numpage": 210
        },
        {
          "text": "and [neither will] they [who] worship, side by side with God, things or beings that can neither harm nor benefit them, saying [to themselves], \"These are our intercessors with God!\" Say: \"Do you [think that you could] inform God of anything in the heavens or on earth that He does not know? Limitless is He in His glory, and sublimely exalted above anything to which men may ascribe a share in His divinity!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 18,
          "number": 1382,
          "juz": 11,
          "numpage": 210
        },
        {
          "text": "AND [know that] all mankind were once but one single community, and only later did they begin to hold divergent views. And had it not been for a decree- that had already gone forth from thy Sustainer, all their differences would indeed have been settled [from the outset].",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 19,
          "number": 1383,
          "juz": 11,
          "numpage": 210
        },
        {
          "text": "NOW THEY [who deny the truth] are wont to ask, \"Why has no miraculous sign ever been bestowed upon him from on high by his Sustainer?\" Say, then: \"God's alone is the knowledge of that which. is beyond the reach of human perception. Wait, then, [until His will becomes manifest:] verily, I shall wait with you!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 20,
          "number": 1384,
          "juz": 11,
          "numpage": 210
        }
      ]
    },
    {
      "209": [
        {
          "text": "Verily, as for those who do not believe that they are destined to meet Us, but content themselves with the life of this world and do not look beyond it, and are heedless of Our messages -",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 7,
          "number": 1371,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "their goal is the fire in return for all [the evil] that they were wont to do.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 8,
          "number": 1372,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "[But,] verily, as for those who have attained to faith and do righteous deeds - their Sustainer guides them aright by means of their faith. [In the life to come,] running waters will flow at their feet in gardens of bliss;",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 9,
          "number": 1373,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "[and] in that [state of happiness] they will call out, \"Limitless art Thou in Thy glory, O God!\"-and will be answered with the greeting, \"Peace!\" And their call will close with [the words], \"All praise is due to God, the Sustainer of all the worlds!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 10,
          "number": 1374,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "NOW IF GOD were to hasten for human beings the ill [which they deserve by their sinning] in the same manner as they [themselves] would hasten [the coming to them of what they consider to be] good, their end would indeed come forthwith! But We leave them alone [for a while] -all those who do not believe that they are destined to meet Us: [We leave them alone] in their overweening arrogance, blindly stumbling to and fro.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 11,
          "number": 1375,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "For [thus it is:] when affliction befalls man, he cries out unto Us, whether he be lying on his side or sitting or standing; but as soon as We have freed him of his affliction, he goes on as though he had never invoked Us to save him from the affliction that befell him! Thus do their own doings seem goodly unto those who waste their own selves.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 12,
          "number": 1376,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "And, indeed, We destroyed before your time [whole] generations when they [persistently] did evil although the apostles sent unto them brought them all evidence of the truth; for they refused to believe [them]. Thus do We requite people who are lost in sin.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 13,
          "number": 1377,
          "juz": 11,
          "numpage": 209
        },
        {
          "text": "And thereupon We made you their successors on earth, so that We might behold how you act.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 14,
          "number": 1378,
          "juz": 11,
          "numpage": 209
        }
      ]
    },
    {
      "208": [
        {
          "text": "Alif Lam. Ra. THESE ARE MESSAGES of the divine writ, full of wisdom.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 1,
          "number": 1365,
          "juz": 11,
          "numpage": 208
        },
        {
          "text": "Do people deem it strange that We should have inspired a man from their own midst [with this Our message]: \"Warn all mankind, and give unto those who have attained to faith the glad tiding that in their Sustainer's sight they surpass all others in that they are completely sincere-? [Only] they who deny the truth say, \"Behold. he is clearly but a spellbinder!\"",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 2,
          "number": 1366,
          "juz": 11,
          "numpage": 208
        },
        {
          "text": "VERILY, your Sustainer is God, who has created the heavens and the earth in six aeons, and is established on the throne of His almightiness, governing all that exists. There is none that could intercede with Him unless He grants leave therefor. Thus is God, your Sustainer: worship, therefore, Him [alone]: will you not, then, keep this in mind?",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 3,
          "number": 1367,
          "juz": 11,
          "numpage": 208
        },
        {
          "text": "Unto Him you all must return: this is, in truth, God's promise-for, behold, He creates [man] in the first instance, and then brings him forth anew to the end that He may reward with equity all who attain to faith and do righteous deeds; whereas for those who are bent on denying the truth there is in store a draught of burning despair and grievous suffering because of their persistent refusal to acknowledge the truth.",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 4,
          "number": 1368,
          "juz": 11,
          "numpage": 208
        },
        {
          "text": "He it is who has made the sun a [source of] radiant light and the moon a light [reflected], and has determined for it phases so that you might know how to compute the years and to measure [time]. None of this has God created without [an inner] truth. Clearly does He spell out these messages unto people of [innate] knowledge:",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 5,
          "number": 1369,
          "juz": 11,
          "numpage": 208
        },
        {
          "text": "for, verily, in the alternating of night and day, and in all that God has created in the heavens and on earth there are messages indeed for people who are conscious of Him!",
          "englishName": "Yunus",
          "suraNumber": 10,
          "numberInSurah": 6,
          "number": 1370,
          "juz": 11,
          "numpage": 208
        }
      ]
    },
    {
      "207": [
        {
          "text": "O you who have attained to faith! Fight against those deniers of the truth who are near you, and let them find you adamant; and know that. God is with those who are conscious of Him.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 123,
          "number": 1358,
          "juz": 11,
          "numpage": 207
        },
        {
          "text": "YET WHENEVER a surah [of this divine writ] is bestowed from on high, some of the deniers of the truth are prone to ask, \"Which of you has this [message] strengthened in his faith?\" Now as for those who have attained to faith, it does strengthen them in their faith, and they rejoice in the glad tiding [which God has given them].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 124,
          "number": 1359,
          "juz": 11,
          "numpage": 207
        },
        {
          "text": "But as for those in whose hearts is disease, each new message but adds another [element of] disbelief to the disbelief which they already harbour, and they die while [still] refusing to acknowledge the truth.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 125,
          "number": 1360,
          "juz": 11,
          "numpage": 207
        },
        {
          "text": "Are they, then, not aware that they are being tested year-in, year-out? And yet, they do not repent and do not bethink themselves [of God];",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 126,
          "number": 1361,
          "juz": 11,
          "numpage": 207
        },
        {
          "text": "and whenever a surah is bestowed from on high, they look at one another [and say, as it were], \"Is there anyone who can see what is in your hearts?\" -and then they turn away. God has turned their hearts away [from the truth] - for they are people who will not grasp it.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 127,
          "number": 1362,
          "juz": 11,
          "numpage": 207
        },
        {
          "text": "INDEED, there has come unto you [O mankind] an Apostle from among yourselves: heavily weighs -upon him [the thought] that you might suffer [in the life to come]; full of concern for you [is he, and] full of compassion and mercy towards the believers.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 128,
          "number": 1363,
          "juz": 11,
          "numpage": 207
        },
        {
          "text": "But if those [who are bent on denying the truth] turn away, say: \"God is enough for me! There is no- deity save Him. In Him have I placed my trust, for He is the Sustainer, in awesome almightiness enthroned.\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 129,
          "number": 1364,
          "juz": 11,
          "numpage": 207
        }
      ]
    },
    {
      "206": [
        {
          "text": "And [He turned in His mercy, too,] towards the three [groups of believers] who had fallen prey to corruption, until in the end-after the earth, despite all its vastness, had become [too] narrow for them and their souls had become [utterly] constricted they came to know with certainty that there is no refuge from God other than [a return] unto Him; and thereupon He turned again unto them in His mercy, so that they might repent: for, verily, God alone is an acceptor of repentance, a dispenser of grace.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 118,
          "number": 1353,
          "juz": 11,
          "numpage": 206
        },
        {
          "text": "O YOU who have attained to faith! Remain conscious of God, and be among those who are true to their word!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 119,
          "number": 1354,
          "juz": 11,
          "numpage": 206
        },
        {
          "text": "It does not behove the people of the [Prophet's] City and the bedouin [who live] around them to hold back from following God's Apostle, or to care for their own selves more than for him -for, whenever they suffer from thirst or weariness or hunger in God's cause, and whenever they take any step which confounds those who deny the truth, and whenever there comes to them from the enemy whatever may be destined for them -[whenever anything thereof comes to pass,,] a good deed is recorded in their favour. Verily, God does not fail to requite the doers of good!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 120,
          "number": 1355,
          "juz": 11,
          "numpage": 206
        },
        {
          "text": "And whenever they spend anything [for the sake of God], be it little or much, and whenever they move on earth [in God's cause] -it is recorded in their favour, and God will grant them the best reward for all that they have been doing.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 121,
          "number": 1356,
          "juz": 11,
          "numpage": 206
        },
        {
          "text": "With all this, it is not desirable that all of the believers take the field [in time of war]. From within every group in their midst, some shall refrain from going forth to war, and shall devote themselves [instead] to acquiring a deeper knowledge of the Faith. and [thus be able to] teach their home-coming brethren, so that these [too] might guard themselves against evil.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 122,
          "number": 1357,
          "juz": 11,
          "numpage": 206
        }
      ]
    },
    {
      "205": [
        {
          "text": "[It is a triumph of] those who turn [unto God] in repentance [whenever they have sinned], and who worship and praise [Him], and go on and on [seeking His goodly acceptance], and bow down [before Him] and prostrate themselves in adoration, and enjoin the doing of what is right and forbid the doing of what is wrong, and keep to the bounds set by God. And give thou [O Prophet] the glad tiding [of God's promise] to all believers.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 112,
          "number": 1347,
          "juz": 11,
          "numpage": 205
        },
        {
          "text": "IT DOES NOT behove the Prophet and those who have attained to faith to pray that they who ascribed divinity to aught beside God be forgiven [by Him] even though they happened to be [their] near of kin -after it has been made clear unto them that those [dead sinners] are destined for. the blazing fire.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 113,
          "number": 1348,
          "juz": 11,
          "numpage": 205
        },
        {
          "text": "And Abraham's prayer that his father be forgiven was but due to a promise which he had given the latter [in his lifetime]; but when it was made clear unto him that he had been God's enemy, [Abraham] disavowed him - [although;] behold, Abraham was most tender-hearted. most clement.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 114,
          "number": 1349,
          "juz": 11,
          "numpage": 205
        },
        {
          "text": "And God would never-after having invited them to His guidance -condemn people for going astray ere He has made [entirely] clear unto them of what they should beware. Verily, God has full knowledge of everything.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 115,
          "number": 1350,
          "juz": 11,
          "numpage": 205
        },
        {
          "text": "Verily, God's alone is the dominion over the heavens and the earth; He [alone] grants life and deals death; and there is none besides God who could protect you or bring you succour.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 116,
          "number": 1351,
          "juz": 11,
          "numpage": 205
        },
        {
          "text": "INDEED, God has turned in His mercy unto the Prophet, as well as unto those who have forsaken the domain of evil and those who have sheltered and succoured the Faiths - [all] those who followed him in the hour of distress, when the hearts of some of the other believers had well-nigh swerved from faith. And once again: He has turned unto them in His mercy - for, behold, He is compassionate towards them, a dispenser of grace.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 117,
          "number": 1352,
          "juz": 11,
          "numpage": 205
        }
      ]
    },
    {
      "204": [
        {
          "text": "AND [there are hypocrites] who have established a [separate] house of worship in order to create mischief, and to promote apostasy and disunity among the believers, and to provide an outpost for all who from the outset have been warring against God and His Apostle. And they will surely swear [to you, O believers], \"We had but the best of intentions!\" -the while God [Himself] bears witness that they are lying.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 107,
          "number": 1342,
          "juz": 11,
          "numpage": 204
        },
        {
          "text": "Never set foot in such a place! Only a house of worship founded, from the very first day, upon God-consciousness is worthy of thy setting foot therein -[a house of worship] wherein there are men desirous of growing in purity: for God loves all who purify themselves.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 108,
          "number": 1343,
          "juz": 11,
          "numpage": 204
        },
        {
          "text": "Which. then, is the better: he who has founded his building on God-consciousness and [a desire for] His goodly acceptance-or he who has founded his building on the edge of a water-worn, crumbling river-bank, so that it [is bound to] tumble down with him into the fire of hell? For, God does not grace with His guidance people who [deliberately] do wrong:",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 109,
          "number": 1344,
          "juz": 11,
          "numpage": 204
        },
        {
          "text": "the building which they have built will never cease to be a source of deep disquiet in their hearts until their hearts crumble to pieces. And God is all-knowing, wise.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 110,
          "number": 1345,
          "juz": 11,
          "numpage": 204
        },
        {
          "text": "BEHOLD, God has bought of the believers their lives and their possessions, promising them paradise in return, [and so] they fight in God's cause, and slay, and are slain: a promise which in truth He has willed upon Himself in [the words of] the Torah, and the Gospel, and the Qur'an. And who could be more faithful to his covenant than God? Rejoice, then, in the bargain which you have made with Him: for this, this is the triumph supreme!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 111,
          "number": 1346,
          "juz": 11,
          "numpage": 204
        }
      ]
    },
    {
      "203": [
        {
          "text": "And as for the first and foremost of those who have forsaken the domain of evil and of those who have sheltered and succoured the Faith, as well as those who follow them in [the way of] righteousness - God is well-pleased with them, and well--pleased are they with Him. And for them has He readied gardens through which running waters flow, therein to abide beyond the count of time: this is the triumph supreme!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 100,
          "number": 1335,
          "juz": 11,
          "numpage": 203
        },
        {
          "text": "But among the bedouin who dwell around you there are hypocrites; and among the people of the [Prophet's] City [too] there are such as have grown insolent in [their] hypocrisy. Thou dost not [always] know them, [O Muhammad - but] We know them. We shall cause them to suffer doubly [in this world]; and then they will be given over to awesome suffering [in the life to come].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 101,
          "number": 1336,
          "juz": 11,
          "numpage": 203
        },
        {
          "text": "And [there are] others - [people who] have become conscious of their sinning after having done righteous deeds side by side with evil ones; [and] it may well be that God will accept their repentance: for, verily, God is much-forgiving, a dispenser' of grace.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 102,
          "number": 1337,
          "juz": 11,
          "numpage": 203
        },
        {
          "text": "[Hence, O Prophet,] accept that [part] of their possessions which is offered for the sake of God, so that thou mayest cleanse them thereby and cause them to grow in purity, and pray for them: behold, thy prayer will be [a source of] comfort to them-for God is all-hearing, all-knowing.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 103,
          "number": 1338,
          "juz": 11,
          "numpage": 203
        },
        {
          "text": "Do they not know that it is God alone who can accept the repentance of His servants and is the [true] recipient of whatever is offered for His sake - and that God alone is an acceptor of repentance, a dispenser of grace?",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 104,
          "number": 1339,
          "juz": 11,
          "numpage": 203
        },
        {
          "text": "And say [unto them, O Prophet]: \"Act! And God will behold your deeds, and [so will] His Apostle, and the believers: and [in the end] you will be brought before Him who knows all that is beyond the reach of a created being's perception as well as all that can be witnessed by a creature's senses or mind -and then He will make you understand what you have been doing.\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 105,
          "number": 1340,
          "juz": 11,
          "numpage": 203
        },
        {
          "text": "And [there are yet] others-[people whose cases are] deferred until God wills to judge them: He will either chastise them or turn again unto them in His mercy-for God is all-knowing, wise.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 106,
          "number": 1341,
          "juz": 11,
          "numpage": 203
        }
      ]
    },
    {
      "202": [
        {
          "text": "[And] they will [still] be offering excuses to you when you return to them, [from the campaign]! Say: \"Do not offer [empty] excuses, [for] we shall not believe you: God has already enlightened us about you. And God will behold your [future] deeds, and [so will] His Apostle; and in the end you will be brought before Him who knows all that is beyond the reach of a created being's perception as well as all that can be witnessed by a creature's senses or mind, and then He will make you truly understand what you were doing [in life].\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 94,
          "number": 1329,
          "juz": 11,
          "numpage": 202
        },
        {
          "text": "When you will have returned to them, (O believers,] they will swear to you by God, [repeating their excuses,] with a view to your letting them be. Let them be, then: behold, they are loathsome, and hell is their goal in recompense for what they were wont to do.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 95,
          "number": 1330,
          "juz": 11,
          "numpage": 202
        },
        {
          "text": "They will swear to you with a view to making you pleased with them: but [even] should you be pleased with them, verily, God shall never be pleased with iniquitous folk.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 96,
          "number": 1331,
          "juz": 11,
          "numpage": 202
        },
        {
          "text": "[The hypocrites among] the bedouin are more tenacious in [their] refusal to acknowledge the truth and in [their] hypocrisy [than are settled people], and more liable to ignore the ordinances which God has bestowed from on high upon His Apostle - but God is all-knowing, wise.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 97,
          "number": 1332,
          "juz": 11,
          "numpage": 202
        },
        {
          "text": "And among the bedouin there are such as regard all that they might spend [in God's cause] as a loss, and wait for misfortune to encompass you, [O believers: but] it is they whom evil fortune shall encompass - for God is all-hearing, all-knowing.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 98,
          "number": 1333,
          "juz": 11,
          "numpage": 202
        },
        {
          "text": "However, among the bedouin there are [also] such as believe in God and the Last Day, and regard all that they spend (in God's cause] as a means of drawing them nearer to God and of [their being remembered in] the. Apostle's prayers. Oh, verily, it shall [indeed] be a means of [God's] nearness to them, [for] God will admit them unto His grace: verily, God is much-forgiving, a dispenser of grace!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 99,
          "number": 1334,
          "juz": 11,
          "numpage": 202
        }
      ]
    },
    {
      "201": [
        {
          "text": "They were well-pleased to remain with those who were left behind - where for their hearts have been sealed, so that they cannot grasp the truth.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 87,
          "number": 1322,
          "juz": 10,
          "numpage": 201
        },
        {
          "text": "The Apostle, however, and all who share his faith strive hard [in God's cause] with, their possessions and their lives: and it is they whom the most excellent things await [in the life to come], and it is they, they who shall attain to a happy state!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 88,
          "number": 1323,
          "juz": 10,
          "numpage": 201
        },
        {
          "text": "God has readied for them gardens through which running waters flow, therein to abide: and this is the triumph supreme!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 89,
          "number": 1324,
          "juz": 10,
          "numpage": 201
        },
        {
          "text": "AND THERE came [unto the Apostle] such of the bedouin as had some excuse to offer, [with the request] that they be granted exemption, whereas those who were bent on giving the lie to God and His Apostle [simply] remained at home. [And] grievous suffering is bound to befall such of them as are bent on denying the truth!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 90,
          "number": 1325,
          "juz": 10,
          "numpage": 201
        },
        {
          "text": "[But] no blame shall attach to the weak, nor to the sick, nor to those who have no means. [to equip themselves], provided that they are sincere towards God and His Apostle: there is no cause to reproach the doers of good, for God is much-forgiving, a dispenser of grace.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 91,
          "number": 1326,
          "juz": 10,
          "numpage": 201
        },
        {
          "text": "Nor [shall blame attach] to those who, when they came unto thee [O Prophet, with the request] that thou provide them with mounts, were told by thee, \"I cannot find anything whereon to mount you\" - [whereupon] they turned away, their eyes overflowing with tears out of sorrow that they had no means to spend [on their equipment].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 92,
          "number": 1327,
          "juz": 10,
          "numpage": 201
        },
        {
          "text": "Only they may rightly be reproached who asked thee for exemption even though they were fully able [to go to war]. They were well-pleased to remain with those who were left behind -wherefore God has sealed their hearts, so that they do not know [what they are doing].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 93,
          "number": 1328,
          "juz": 11,
          "numpage": 201
        }
      ]
    },
    {
      "200": [
        {
          "text": "[And] whether thou dost pray [unto God] that they be forgiven or dost not pray for them - [it will all be the same: for even] if thou wert to pray seventy times that they be forgiven, God will not forgive them, seeing that they are bent on denying God and His Apostle. And God does not bestow His guidance upon such iniquitous folk.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 80,
          "number": 1315,
          "juz": 10,
          "numpage": 200
        },
        {
          "text": "THOSE [hypocrites] who were left behind rejoiced in their staying away [from war] after [the departure of] God's Apostle, for they hated the thought of striving with their possessions and their lives in God's cause; and they had [even] said [to the others], \"Do not go forth to war in this heat!\" Say: \"The fire of hell is hotter by far!\" Had they but grasped this truth!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 81,
          "number": 1316,
          "juz": 10,
          "numpage": 200
        },
        {
          "text": "Let them, then, laugh a little - for they will weep a lot in return for what they have earned.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 82,
          "number": 1317,
          "juz": 10,
          "numpage": 200
        },
        {
          "text": "Hence, [O Prophet,] if God brings thee again face to face with some of them,\" and then they ask thy leave to go forth [to war with thee], say: \"Never shall you go forth with me. nor shall you fight an enemy together with me! Behold, you were well-pleased to stay at home on that first occasion: stay at home, then, with, those who [are obliged to] remain behind!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 83,
          "number": 1318,
          "juz": 10,
          "numpage": 200
        },
        {
          "text": "And never shalt thou pray over any of them that has died, and never shalt thou stand by his grave: for, behold, they were bent on denying God and His Apostle, and they died in this their iniquity.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 84,
          "number": 1319,
          "juz": 10,
          "numpage": 200
        },
        {
          "text": "And let not their worldly goods and [the happiness which they may derive from] their children excite thy admiration: God but wants to chastise them by these means in [the life of] this world, and [to cause] their souls to depart while they are [still] denying the truth.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 85,
          "number": 1320,
          "juz": 10,
          "numpage": 200
        },
        {
          "text": "[They are indeed denying it:] for, when they were called upon through revelation, \"Believe in God, and strive hard [in His cause] together with His Apostle,\" [even] such of them as were well able [to go to war] asked thee for exemption, saying, \"Allow us to stay behind with those who remain at home!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 86,
          "number": 1321,
          "juz": 10,
          "numpage": 200
        }
      ]
    },
    {
      "199": [
        {
          "text": "O PROPHET! Strive hard against the deniers of the truth and the hypocrites, and be adamant with them. And [if they do not repent,] their goal shall be hell -and how vile a journey's end!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 73,
          "number": 1308,
          "juz": 10,
          "numpage": 199
        },
        {
          "text": "[The hypocrites] swear by God that they have said nothing [wrong]; yet most certainly have they uttered a saying which amounts to a denial of the truth, and have [thus] denied the truth after [having professed] their self-surrender to God: for they were aiming at something which was beyond their reach. And they could find no fault [with the Faith] save that God had enriched them and [caused] His Apostle [to enrich them] out of His bounty! Hence, if they repent, it will be for their own good. But if they turn away, God will cause them to suffer grievous suffering in this world and in the life to come, and they will find no helper on earth, and none to give [them] succour.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 74,
          "number": 1309,
          "juz": 10,
          "numpage": 199
        },
        {
          "text": "And among them are such as vow unto God, \"If indeed He grant us [something] out of His bounty, we shall most certainly spend in charity, and shall most certainly be among the righteous!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 75,
          "number": 1310,
          "juz": 10,
          "numpage": 199
        },
        {
          "text": "But as soon as He has given them [aught] out of His bounty. they cling to it niggardly, and turn away in their obstinacy [from all that they have vowed]:",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 76,
          "number": 1311,
          "juz": 10,
          "numpage": 199
        },
        {
          "text": "whereupon He causes hypocrisy to take root in their hearts, [therein to remain] until the Day on which they shall meet Him\" - because they have failed to fulfil the vow which they had made unto God, and because they were wont to lie.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 77,
          "number": 1312,
          "juz": 10,
          "numpage": 199
        },
        {
          "text": "Do they not know that God knows [all] their hidden thoughts and their secret confabulations, and that God knows fully all the things that are beyond the reach of human perception'?",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 78,
          "number": 1313,
          "juz": 10,
          "numpage": 199
        },
        {
          "text": "[It is these hypocrites] who find fault with such of the believers as give for the sake of God more than they are duty-bound to give, as well as with, such as find nothing [to give] beyond [the meagre fruits of] their toil, and who scoff at them [all]. God will cause their scoffing to rebound on themselves. and grievous suffering awaits them.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 79,
          "number": 1314,
          "juz": 10,
          "numpage": 199
        }
      ]
    },
    {
      "198": [
        {
          "text": "[Say unto them: \"You are] like those [hypocrites] who lived before your time. Greater than you were they in power, and richer in wealth and in children; and they enjoyed their share [of happiness]. And you have been enjoying your share -just as those who preceded you enjoyed their share; and you have been indulging in scurrilous talk -just as they indulged in it. It is they whose works have come to nought in this world and in the life to come - and it is they, they who are the cost!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 69,
          "number": 1304,
          "juz": 10,
          "numpage": 198
        },
        {
          "text": "Have, then, the stories of those who preceded them never come within the ken of these [hypocrites and deniers of the truth]?-[the stories] of Noah's people, and of [the tribes of] `Ad and Thamud, and of Abraham's people, and of the folk of Madyan, and of the cities that were overthrown? To [all of] them their apostles had come with all evidence of the truth. [but they rejected them:] and so it was not God who wronged them [by His punishment], but it was they who wronged themselves.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 70,
          "number": 1305,
          "juz": 10,
          "numpage": 198
        },
        {
          "text": "AND [as for] the believers, both men and women they are close unto one another: they [all] enjoin the doing of what is right and forbid the doing of what is wrong, and are constant in prayer, and render the purifying dues, and pay heed unto God and His Apostle. It is they upon whom God will bestow His grace: verily, God is almighty, wise!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 71,
          "number": 1306,
          "juz": 10,
          "numpage": 198
        },
        {
          "text": "God has promised the believers, both men and women, gardens through which running waters flow, therein to abide, and goodly dwellings in gardens of perpetual bliss: but God's goodly acceptance is the greatest [bliss of all] -for this, this is the triumph supreme!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 72,
          "number": 1307,
          "juz": 10,
          "numpage": 198
        }
      ]
    },
    {
      "197": [
        {
          "text": "[The hypocrites] swear to you by God [that they are acting in good faith], with a view to pleasing you [O believers] - the while it is God and His Apostle whose pleasure they should seek above all else, if indeed they are believers!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 62,
          "number": 1297,
          "juz": 10,
          "numpage": 197
        },
        {
          "text": "Do they not know that for him who sets himself against God and His Apostle there is in store the fire of hell, therein to abide -that most awesome disgrace?",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 63,
          "number": 1298,
          "juz": 10,
          "numpage": 197
        },
        {
          "text": "[Some of] the hypocrites dread lest a [new] surah be revealed [in evidence] against them, making them understand what is [really] in their hearts. Say: \"Go on mocking! Behold, God will bring to light the very thing that you are dreading!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 64,
          "number": 1299,
          "juz": 10,
          "numpage": 197
        },
        {
          "text": "Yet, indeed, if thou wert to question them. they would surely answer, \"We were only indulging in idle talk, and were playing [with words]. Say: \"Were you, then. mocking at God and His messages and His Apostle?",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 65,
          "number": 1300,
          "juz": 10,
          "numpage": 197
        },
        {
          "text": "Do not offer [empty] excuses! You have indeed denied the truth after [having professed] your belief [in it] - Though We may efface the sin of some of you, We shall chastise others - seeing that they were lost in sin.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 66,
          "number": 1301,
          "juz": 10,
          "numpage": 197
        },
        {
          "text": "The hypocrites, both men and women. are all of a kind: they enjoin the doing of what is wrong and forbid the doing of what is right and withhold their hands [from doing good]. They are oblivious of God, and so He is oblivious of them. Verily, the hypocrites-it is they, they who are truly iniquitously",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 67,
          "number": 1302,
          "juz": 10,
          "numpage": 197
        },
        {
          "text": "God- has promised the hypocrites, both men and women-as well as the [outright] deniers of the _ truth-the fire of hell, therein to abide: this shall be their allotted_ portion. For, God has rejected them, and long-lasting suffering awaits them.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 68,
          "number": 1303,
          "juz": 10,
          "numpage": 197
        }
      ]
    },
    {
      "196": [
        {
          "text": "Let not, then, their worldly goods or [the happiness which they may derive from] their children excite thy admiration: God but wants to chastise them by these means in this worldly life, and [to cause] their souls to depart while they are [still] denying the truth.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 55,
          "number": 1290,
          "juz": 10,
          "numpage": 196
        },
        {
          "text": "And they swear by God that they do indeed belong to you -the while they do not belong to you, but are [only] people ridden by fear:",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 56,
          "number": 1291,
          "juz": 10,
          "numpage": 196
        },
        {
          "text": "if they could but find a place of refuge, or any cavern, or a crevice [in the earth], they would turn towards it in headlong haste.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 57,
          "number": 1292,
          "juz": 10,
          "numpage": 196
        },
        {
          "text": "And among them are such as find fault with thee [O Prophet] concerning [the distribution of] the offerings given for the sake of God: if they are given something thereof, they are well-pleased; but if they are not given anything thereof, lo! they are consumed with anger.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 58,
          "number": 1293,
          "juz": 10,
          "numpage": 196
        },
        {
          "text": "And yet, [it would be but for their own good] if they were to content themselves with what God has given them and [caused] His Apostle [to give them], and would say, \"God is enough for us! God will give us [whatever He wills] out of His bounty, and [will cause] His Apostle [to give us, too]: verily, unto God alone do we turn with hope!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 59,
          "number": 1294,
          "juz": 10,
          "numpage": 196
        },
        {
          "text": "The offerings given for the sake of God are [meant] only for the poor and the needy, and those who are in charge thereof, and those whose hearts are to be won over, and for the freeing of human beings from bondage, and [for] those who are over burdened with debts, and [for every struggle] in God's cause, and [for] the wayfarer: [this is] an ordinance from God - and God is all-knowing, wise.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 60,
          "number": 1295,
          "juz": 10,
          "numpage": 196
        },
        {
          "text": "AND AMONG those [enemies of the truth] there are such as malign the Prophet by saying, \"He is all ear.\" Say: \"[Yes,] he is all ear, [listening] to what is good for you! He believes in God, and trusts the believers, and is [a manifestation of God's] grace towards such of you as have [truly] attained to faith. And as for those who malign God's Apostle - grievous suffering awaits them [in the life to come]!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 61,
          "number": 1296,
          "juz": 10,
          "numpage": 196
        }
      ]
    },
    {
      "195": [
        {
          "text": "Indeed, even before this time have they tried to stir up discord and devised all manner of plots against thee, [O Prophet,] until the truth was revealed and God's will became manifest, however hateful this may have been to them.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 48,
          "number": 1283,
          "juz": 10,
          "numpage": 195
        },
        {
          "text": "And among them there was [many a one] who said,\" Grant me permission [to remain at home], and do not put me to too hard a test!\" Oh, verily, [by making such a request] they had [already failed in their test and] succumbed to a temptation to evil: and, behold, hell will indeed encompass all who refuse to acknowledge the truth!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 49,
          "number": 1284,
          "juz": 10,
          "numpage": 195
        },
        {
          "text": "Should good fortune alight on thee, [O Prophet,] it will grieve them; and should misfortune befall thee, they will say [to themselves], \"We have already taken our precautions beforehand!\" - and will turn away, and will rejoice.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 50,
          "number": 1285,
          "juz": 10,
          "numpage": 195
        },
        {
          "text": "Say: \"Never can anything befall us save what God has decreed! He is our Lord Supreme; and in God let the believers place their trust!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 51,
          "number": 1286,
          "juz": 10,
          "numpage": 195
        },
        {
          "text": "Say: \"Are you, perchance, hopefully waiting for something [bad] to happen to us-[the while nothing can happen to us] save one of the two best things? But as far as you are concerned, we are hopefully waiting for God to inflict chastisement upon you, [either] from Himself or by our hands! Wait, then, hopefully; behold, we shall hopefully wait with you!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 52,
          "number": 1287,
          "juz": 10,
          "numpage": 195
        },
        {
          "text": "Say: \"You may spend [anything], willingly-or unwillingly, [pretending that you do it for the sake of God:] it shall never be accepted from you -for, verily, you are people bent on iniquity!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 53,
          "number": 1288,
          "juz": 10,
          "numpage": 195
        },
        {
          "text": "For, only this prevents their spending from being accepted from them: they are bent on refusing to acknowledge God and His Apostle, and never pray without reluctance, and never spend [on righteous causes] without resentment.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 54,
          "number": 1289,
          "juz": 10,
          "numpage": 195
        }
      ]
    },
    {
      "194": [
        {
          "text": "Go forth to war, whether it be easy or difficult [for you], and strive hard in God's cause with your possessions and your lives: this is for your own good -if you but knew it!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 41,
          "number": 1276,
          "juz": 10,
          "numpage": 194
        },
        {
          "text": "Had there been [a prospect of] immediate gain, and an easy journey, they would certainly have followed thee, [O Prophet:] but the distance was too great for them. And yet, [after your return, O believers,] they will swear by God, \"Had we been able to do so, we would certainly have set out with you!\" - [and by thus falsely swearing] they will be destroying their own selves: for God knows indeed that they are lying!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 42,
          "number": 1277,
          "juz": 10,
          "numpage": 194
        },
        {
          "text": "May God pardon thee [O Prophet]! Why didst thou grant them permission [to stay at home] ere it had become obvious to thee as to who was speaking the truth, and [ere] thou camest to know. [who were] the liars?",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 43,
          "number": 1278,
          "juz": 10,
          "numpage": 194
        },
        {
          "text": "Those who [truly] believe in God and the Last Day do not ask thee for exemption from struggling with their possessions and their lives [in God's cause]-and God has full knowledge as to who is conscious of Him-:",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 44,
          "number": 1279,
          "juz": 10,
          "numpage": 194
        },
        {
          "text": "only those ask thee for exemption who do not [truly] believe in God and the Last Day and whose hearts have become a prey to doubt, so that in their doubting they waver between one thing and another.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 45,
          "number": 1280,
          "juz": 10,
          "numpage": 194
        },
        {
          "text": "For, had they been [truly] desirous of setting out [with thee], they would surely have made some preparation therefor: but God was averse to their taking the field, and so He caused them to hold back when it was said, \"[You may] stay at home with all [the others] who stay at home.\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 46,
          "number": 1281,
          "juz": 10,
          "numpage": 194
        },
        {
          "text": "Had these [hypocrites] set out with_ you, [O believers,] they would have added nothing to you save the evil of corruption, and would surely have scurried to and fro in your midst, seeking to stir up discord among you, seeing that there are in your midst such as would have lent them ear: but God has full knowledge of the evildoers.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 47,
          "number": 1282,
          "juz": 10,
          "numpage": 194
        }
      ]
    },
    {
      "193": [
        {
          "text": "The intercalation [of months] is but one more instance of [their] refusal to acknowledge the truth- [a means] by which those who are bent on denying the truth are led astray. They declare this [intercalation] to be permissible in one year and forbidden in [another] year, in order to conform [outwardly] to the number of months which God has hallowed: and thus they make allowable what God has forbidden. Goodly seems unto them the evil of their own doings, since God does not grace with His guidance people who refuse to acknowledge the truth.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 37,
          "number": 1272,
          "juz": 10,
          "numpage": 193
        },
        {
          "text": "O YOU who have attained to faith! What is amiss with you that, when you are called upon, \"Go forth to war in God's cause,\" you cling heavily to the earth? Would you content yourselves with [the comforts of] this worldly life in preference to [the good of] the life to come? But the enjoyment of life in this world is but a paltry thing when compared with the life to come!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 38,
          "number": 1273,
          "juz": 10,
          "numpage": 193
        },
        {
          "text": "If you do not go forth to war [in God's cause], He will chastise you with grievous chastisement, and will place another people in your stead - whereas you shall in no wise harm Him: for, God has the power to will anything.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 39,
          "number": 1274,
          "juz": 10,
          "numpage": 193
        },
        {
          "text": "If you do not succour the Apostle, then [know that God will do so -just as] God succoured him at the time when those who were bent on denying the truth drove him away, [and he was but] one of two: when these two were [hiding] in the cave, [and] the Apostle said to his companion, \"Grieve not: verily, God is with us.\" And thereupon God bestowed upon him from on high His (gift of] inner peace, and brought utterly low the cause of those who were bent on denying the truth, whereas the cause of God remained supreme: for God is almighty, wise.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 40,
          "number": 1275,
          "juz": 10,
          "numpage": 193
        }
      ]
    },
    {
      "192": [
        {
          "text": "They want to extinguish God's [guiding] light with their utterances: but God will not allow [this to pass], for He has willed to spread His light in all its fullness, however hateful this may be to all who deny the truth.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 32,
          "number": 1267,
          "juz": 10,
          "numpage": 192
        },
        {
          "text": "He it is who has sent forth His Apostle with the [task of spreading] guidance and the religion of truth, to the end that He may cause it to prevail over all [false] religion -however hateful this may be to those who ascribe divinity to aught beside God.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 33,
          "number": 1268,
          "juz": 10,
          "numpage": 192
        },
        {
          "text": "O you who have attained to faith! Behold, many of the rabbis and monks do indeed wrongfully devour men's possessions and turn [others] away from the path of God. But as for all who lay up treasures of gold and silver and do not spend them for the sake of God - give them the tiding of grievous suffering [in the life to come]:",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 34,
          "number": 1269,
          "juz": 10,
          "numpage": 192
        },
        {
          "text": "on the Day when that [hoarded wealth] shall be heated in the fire of hell and their foreheads and their sides and their backs branded therewith, [those sinners shall be told:] \"These are the treasures which you have laid up for yourselves! Taste, then, [the evil of] your hoarded treasures!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 35,
          "number": 1270,
          "juz": 10,
          "numpage": 192
        },
        {
          "text": "BEHOLD, the number of months, in the sight of God, is twelve months, [laid down] in God's decree on the day when He created the heavens and the earth; [and] out of these, four are sacred: this is the ever-true law [of God]. Do not, then, sin against yourselves with regard to these [months]. And fight against those who ascribe divinity to aught beside God, all together-just as they fight against you, [O believers,] all together -and know that God is with those who are conscious of Him.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 36,
          "number": 1271,
          "juz": 10,
          "numpage": 192
        }
      ]
    },
    {
      "191": [
        {
          "text": "But with all this, God will turn in His mercy unto whom He wills: for God is much-forgiving, a dispenser of grace.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 27,
          "number": 1262,
          "juz": 10,
          "numpage": 191
        },
        {
          "text": "O YOU who have attained to faith! Those who ascribe divinity to aught beside God are nothing but impure: and so they shall not approach the Inviolable House of Worship from this year onwards And should you fear poverty, then [know that] in time God will enrich you out of His bounty, if He so wills: for, verily, God is all-knowing, wise!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 28,
          "number": 1263,
          "juz": 10,
          "numpage": 191
        },
        {
          "text": "[And] fight against those who - despite having been vouchsafed revelation [aforetime] -do not [truly] believe either in God or the Last Day, and do not consider forbidden that which God and His Apostle have forbidden, and do not follow the religion of truth [which God has enjoined upon them] till they [agree to] pay the exemption tax with a willing hand, after having been humbled [in war].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 29,
          "number": 1264,
          "juz": 10,
          "numpage": 191
        },
        {
          "text": "AND THE JEWS say, \"Ezra is God's son,\" while the Christians say, \"The Christ is God's son.\" Such are the sayings which they utter with their mouths, following in spirit assertions made in earlier times by people who denied the truth! [They deserve the imprecation:] \"May God destroy them!\" How perverted are their minds!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 30,
          "number": 1265,
          "juz": 10,
          "numpage": 191
        },
        {
          "text": "They have taken their rabbis and their monks-as well as the Christ, son of Mary-for their lords beside God, although they had been bidden to worship none but the One God, save whom there is no deity: the One who is utterly remote, in His limitless glory, from anything to which they may ascribe a share in His divinity!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 31,
          "number": 1266,
          "juz": 10,
          "numpage": 191
        }
      ]
    },
    {
      "190": [
        {
          "text": "Their Sustainer gives them the glad tiding of the grace [that flows] from Him, and of [His] goodly acceptance, and of the gardens which await them, full of lasting bliss,",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 21,
          "number": 1256,
          "juz": 10,
          "numpage": 190
        },
        {
          "text": "therein to abide beyond the count of time. Verily, with God is a mighty reward!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 22,
          "number": 1257,
          "juz": 10,
          "numpage": 190
        },
        {
          "text": "O YOU who have attained to faith! Do not take your fathers and your brothers for allies if a denial of the truth is dearer to them than faith: for those of you who ally themselves with them-it is they, they who are evildoers!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 23,
          "number": 1258,
          "juz": 10,
          "numpage": 190
        },
        {
          "text": "Say: \"If your fathers and your sons and your brothers and your spouses and your clan, and the worldly goods which you have acquired, and the commerce whereof you fear a decline, and the dwellings in which you take pleasure - [if all these] are dearer to you than God and His Apostle and the struggle in His cause, then wait until God makes manifest His will; and [know that] God does not grace iniquitous folk with His guidance.\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 24,
          "number": 1259,
          "juz": 10,
          "numpage": 190
        },
        {
          "text": "Indeed, God has succoured you on many battlefields, [when you were few;] and [He did so, too,] on the Day of Hunayn, when you took pride in your great numbers and they proved of no avail whatever to you -for the earth, despite all its vastness, became [too] narrow for you and you turned back, retreating:",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 25,
          "number": 1260,
          "juz": 10,
          "numpage": 190
        },
        {
          "text": "whereupon God bestowed from on high His [gift of] inner peace upon His Apostle and upon the believers, and bestowed [upon you] from on high forces which you could not see, and chastised those who were bent on denying the truth: for such is the recompense of all who deny the truth!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 26,
          "number": 1261,
          "juz": 10,
          "numpage": 190
        }
      ]
    },
    {
      "189": [
        {
          "text": "Fight against them! God will chastise them by your hands, and will bring disgrace upon them, and will succour you against them; and He will soothe the bosoms of those who believe,",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 14,
          "number": 1249,
          "juz": 10,
          "numpage": 189
        },
        {
          "text": "and will remove the wrath that is in their hearts. And God will turn in His mercy unto whom He wills: for, God is all-knowing, wise.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 15,
          "number": 1250,
          "juz": 10,
          "numpage": 189
        },
        {
          "text": "Do you [O believers] think that you will be spared unless God takes cognizance of your having striven hard [in His cause] without seeking help from any but God and His Apostle and those who believe in Him? For, God is aware of all that you do.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 16,
          "number": 1251,
          "juz": 10,
          "numpage": 189
        },
        {
          "text": "IT IS NOT for those who ascribe divinity to aught beside God to visit or tend God's houses of worship, the while [by their beliefs] they bear witness against themselves that they are denying the truth. It is they whose works shall come to nought, and they who in the fire shall abide",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 17,
          "number": 1252,
          "juz": 10,
          "numpage": 189
        },
        {
          "text": "Only he should visit or tend God's houses of worship who believes in God and the Last Day, and is constant in prayer, and spends in charity, and stands in awe of none but God: for [only such as] these may hope to be among the right-guided!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 18,
          "number": 1253,
          "juz": 10,
          "numpage": 189
        },
        {
          "text": "Do you, perchance, regard the [mere] giving of water to pilgrims and the tending of the Inviolable House of Worship as being equal to [the works of] one who believes in God and the Last Day and strives hard in God's cause? These [things] are not equal in the sight of God. And God does not grace with His guidance people who [deliberately] do wrong.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 19,
          "number": 1254,
          "juz": 10,
          "numpage": 189
        },
        {
          "text": "Those who believe, and who have forsaken the domain of evil and have striven hard in God's cause with their possessions and their lives have the highest rank in the sight of God; and it is they, they who shall triumph [in the end]!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 20,
          "number": 1255,
          "juz": 10,
          "numpage": 189
        }
      ]
    },
    {
      "188": [
        {
          "text": "HOW COULD they who ascribe divinity to aught beside God be granted a covenant by God and His Apostle, unless it be those [of them] with whom you [O believers] have made a covenant in the vicinity of the Inviolable House of Worship? [As for the latter,] so long as they remain true to you, be true to them: for, verily, God loves those who are conscious of Him.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 7,
          "number": 1242,
          "juz": 10,
          "numpage": 188
        },
        {
          "text": "How [else could it be]? -since, if they [who are hostile to you] were to overcome you, they would not respect any tie [with you,] nor any obligation to protect [you]. They seek to please you with their mouths, the while their hearts remain averse [to you]; and most of them are iniquitous.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 8,
          "number": 1243,
          "juz": 10,
          "numpage": 188
        },
        {
          "text": "God's messages have they bartered away for a trifling gain, and have thus turned away from His path: evil, behold, is all that they are wont to do,",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 9,
          "number": 1244,
          "juz": 10,
          "numpage": 188
        },
        {
          "text": "respecting no tie and no protective obligation with regard to a believer; and it is they, they who transgress the bounds of what is right!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 10,
          "number": 1245,
          "juz": 10,
          "numpage": 188
        },
        {
          "text": "Yet if they repent, and take to prayer, and render the purifying dues, they become your brethren in faith: and clearly do We spell out these messages unto people of [innate] knowledge!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 11,
          "number": 1246,
          "juz": 10,
          "numpage": 188
        },
        {
          "text": "But if they break their solemn pledges after having concluded a covenant, and revile your religion, then fight against these archetypes of faithlessness who, behold, have no [regard for their own] pledges, so that they might desist [from aggression].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 12,
          "number": 1247,
          "juz": 10,
          "numpage": 188
        },
        {
          "text": "Would you, perchance, fail to fight against people who have broken their solemn pledges, and have done all that they could to drive the Apostle away, and have been first to attack you? Do you hold them in awe? Nay, it is God alone of whom you ought to stand in awe, if you are [truly] believers!",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 13,
          "number": 1248,
          "juz": 10,
          "numpage": 188
        }
      ]
    },
    {
      "187": [
        {
          "text": "DISAVOWAL by God and His Apostle [is herewith announced] unto those who ascribe divinity to aught beside God, [and] with whom you [O: believers] have made a covenant.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 1,
          "number": 1236,
          "juz": 10,
          "numpage": 187
        },
        {
          "text": "[Announce unto them:] \"Go, then, [freely] about the earth for four months -but know that you can never elude God, and that, verily, God shall bring disgrace upon all who refuse to acknowledge the truth!\"",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 2,
          "number": 1237,
          "juz": 10,
          "numpage": 187
        },
        {
          "text": "And a proclamation from God and His Apostle [is herewith made] unto all mankind on this day of the Greatest Pilgrimage: \"God disavows all who ascribe divinity to aught beside Him, and [so does] His Apostle. Hence, if you repent, it shall be for your own good; and if you turn away, then know that you can never elude God!\" And unto those who are bent on denying the truth give thou [O Prophet] the tiding of grievous chastisement.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 3,
          "number": 1238,
          "juz": 10,
          "numpage": 187
        },
        {
          "text": "But excepted shall be -from among those who ascribe divinity to aught beside God - [people] with whom you [O believers] have made a covenant and who thereafter have in no wise failed to fulfil their obligations towards you, and neither have aided anyone against you: observe, then, your covenant with them until the end of the term agreed with them. Verily, God loves those who are conscious of Him.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 4,
          "number": 1239,
          "juz": 10,
          "numpage": 187
        },
        {
          "text": "And so, when the sacred months are over, slay those who ascribe divinity to aught beside God wherever you may come upon them, and take them captive, and besiege them, and lie in wait for them at every conceivable place! Yet if they repent, and take to prayer, and render the purifying dues, let them go their way: for, behold, God is much forgiving, a dispenser of grace.",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 5,
          "number": 1240,
          "juz": 10,
          "numpage": 187
        },
        {
          "text": "And if any of those who ascribe divinity to aught beside God seeks thy protection, grant him protection, so that he might [be able to] hear the word of God [from thee]; and thereupon convey him to a place where he can feel secure: this, because they [may be] people who [sin only because they] do not know [the truth].",
          "englishName": "At-Tawba",
          "suraNumber": 9,
          "numberInSurah": 6,
          "number": 1241,
          "juz": 10,
          "numpage": 187
        }
      ]
    },
    {
      "186": [
        {
          "text": "[Hence,] O Prophet, say unto the captives who are in your hands: \"If God finds any good in your hearts, He will give you something better than all that has been taken from you, and will forgive you your sins: for God is much-forgiving, a dispenser of grace.\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 70,
          "number": 1230,
          "juz": 10,
          "numpage": 186
        },
        {
          "text": "And should they but seek to play false with thee -well, they were false to God [Himself] ere this: but He gave [the believers] mastery over them. And God is all-knowing, wise.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 71,
          "number": 1231,
          "juz": 10,
          "numpage": 186
        },
        {
          "text": "BEHOLD, as for those who have attained to faith, and who have forsaken the domain of evil and are striving hard, with their possessions and their lives, in God's cause, as well as those who shelter and succour [them] - these are [truly] the friends and protectors of one another. But as for those who have come to believe without having migrated [to your country] - you are in no wise responsible for their protection until such a time as they migrate [to you]. Yet, if they ask you for succour against religious persecution, it is your duty to give [them] this succour-except against a people between whom and yourselves there is a covenant: for God sees all that you do.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 72,
          "number": 1232,
          "juz": 10,
          "numpage": 186
        },
        {
          "text": "With all this, [remember that] those who are bent on denying the truth are allies of one another; and unless you act likewise [among yourselves], oppression will reign on earth, and great corruption.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 73,
          "number": 1233,
          "juz": 10,
          "numpage": 186
        },
        {
          "text": "And they who have attained to faith, and who have forsaken the domain of evil and are striving hard in God's cause, as well as those who shelter and succour [them]-it is they, they who are truly believers! Forgiveness of sins awaits them, and a most excellent sustenance.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 74,
          "number": 1234,
          "juz": 10,
          "numpage": 186
        },
        {
          "text": "And as for those who henceforth come to believe, and who forsake the domain of evil and strive hard [in God's cause] together with you - these [too] shall belong to you; and they who are [thus] closely related have the highest claim on one another in [accordance with] God's decree. Verily, God has full knowledge of everything.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 75,
          "number": 1235,
          "juz": 10,
          "numpage": 186
        }
      ]
    },
    {
      "185": [
        {
          "text": "And should they seek but to deceive thee [by their show of peace] - behold, God is enough for thee! He it is who has strengthened thee with His succour, and by giving thee believing followers",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 62,
          "number": 1222,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "whose hearts He has brought together: [for,] if thou hadst expended all that is on earth, thou couldst not have brought their hearts together [by thyself]: but God did bring them together. Verily, He is almighty, wise.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 63,
          "number": 1223,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "O Prophet! God is enough for thee and those of the believers who follow thee!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 64,
          "number": 1224,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "O Prophet! Inspire the believers to conquer all fear of death when fighting, [so that,] if there be twenty of you who are patient in adversity, they might overcome two hundred; and [that,] if there be one hundred of you, they might overcome one thousand of those who are bent on denying the truth, because they are people who cannot grasp it.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 65,
          "number": 1225,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "For the time being, [however,] God has lightened your burden. for He knows that you are weak: and so, if there be one hundred of you who are patient in adversity, they should [be able to]' overcome two hundred; and if there be one thousand of you, they should [be able to] overcome two thousand by God's leave: for God is with those who are patient in adversity.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 66,
          "number": 1226,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "IT DOES NOT behove a prophet to keep captives unless he has battled strenuously on earth. You may desire the fleeting gains of this world-but God desires [for you the good of] the life to come: and God is almighty, wise.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 67,
          "number": 1227,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "Had it not been for a decree from God that had already gone forth, there would indeed have befallen you a tremendous chastisement on account of all [the captives] that you took.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 68,
          "number": 1228,
          "juz": 10,
          "numpage": 185
        },
        {
          "text": "Enjoy, then, all that is lawful and good among the things which you have gained in war, and remain conscious of God: verily, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 69,
          "number": 1229,
          "juz": 10,
          "numpage": 185
        }
      ]
    },
    {
      "184": [
        {
          "text": "This, because God would never change the blessings with which He has graced a people unless they change their inner selves: and [know] that God is all-hearing, all-seeing.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 53,
          "number": 1213,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "[To those sinners shall happen] the like of what happened to Pharaoh's people and those who lived before them: they gave the lie to their Sustainer's messages - and so We destroyed them in return for their sins, and caused Pharaoh's people to drown: for they were evildoers all.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 54,
          "number": 1214,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "Verily, the vilest creatures in the sight of God are those who are bent on denying the truth and therefore do not believe.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 55,
          "number": 1215,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "AS FOR THOSE with whom thou hast made a covenant, and who thereupon break their covenant on every occasion, not being conscious of God-",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 56,
          "number": 1216,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "if thou find them at war [with you], make of them a fearsome example for those who follow them, so that they might take it to heart;",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 57,
          "number": 1217,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "or, if thou hast reason to fear treachery from people [with whom thou hast made a covenant], cast it back at them in an equitable manner: for, verily, God does not love the treacherous!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 58,
          "number": 1218,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "And let them not think - those who are bent on denying the truth -that they shall escapee [God]: behold, they can never frustrate [His purpose].",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 59,
          "number": 1219,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "Hence, make ready against them whatever force and war mounts you are able to muster, so that you might deter thereby the enemies of God, who are your enemies as well, and others besides them of whom you may be unaware, [but] of whom God is aware; and whatever you may expend in God's cause shall be repaid to you in full, and you shall not be wronged.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 60,
          "number": 1220,
          "juz": 10,
          "numpage": 184
        },
        {
          "text": "But if they incline to peace, incline thou to it as well, and place thy trust in God: verily, He alone is all-hearing, all-knowing!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 61,
          "number": 1221,
          "juz": 10,
          "numpage": 184
        }
      ]
    },
    {
      "183": [
        {
          "text": "And pay heed unto God and His Apostle, and do not [allow yourselves to] be at variance with one another, lest you lose heart and your moral strength desert you. And be patient in adversity: for, verily, God is with those who are patient in adversity.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 46,
          "number": 1206,
          "juz": 10,
          "numpage": 183
        },
        {
          "text": "And be not like those [unbelievers] who went forth from their homelands full of self-conceit and a desire to be seen and praised by men for they were trying to turn others away from the path of God -the while God encompassed all their doings [with His might].",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 47,
          "number": 1207,
          "juz": 10,
          "numpage": 183
        },
        {
          "text": "And, lo, Satan made all their doings seem goodly to them, and said, \"No one can overcome you this day, for, behold, I shall be your protector!\" but as soon as the two hosts came within sight of one another, he turned on his heels and said, \"Behold, I am not responsible for you: behold, I see something that you do not see: behold, I fear God-for God is severe in retribution!\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 48,
          "number": 1208,
          "juz": 10,
          "numpage": 183
        },
        {
          "text": "At the same time, the hypocrites and those in whose hearts was disease were saying, \"Their faith has deluded these [believers]!\" But he who places his trust in God [knows that], verily, God is almighty, wise.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 49,
          "number": 1209,
          "juz": 10,
          "numpage": 183
        },
        {
          "text": "AND IF thou couldst but see [how it will be] when He causes those who are bent on denying the truth to die: the angels will strike their faces: and their backs, and [will say]: \"Taste suffering through fire",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 50,
          "number": 1210,
          "juz": 10,
          "numpage": 183
        },
        {
          "text": "in return for what your own hands have wrought - for, never does God do the least wrong to His creatures!\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 51,
          "number": 1211,
          "juz": 10,
          "numpage": 183
        },
        {
          "text": "[To them shall happen.] the like of what happened to Pharaoh's people and those who lived before them: they denied the truth of God's messagesand so God took them to task for their sins. Verily, God is powerful, severe in retribution!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 52,
          "number": 1212,
          "juz": 10,
          "numpage": 183
        }
      ]
    },
    {
      "182": [
        {
          "text": "AND KNOW that whatever booty you acquire [in war], one-fifth thereof belongs to God and the Apostle, and the near of kin, and the orphans, and the needy, and the wayfarer. [This you must observe] if you believe in God and in what We bestowed from on high upon Our servant on the day when the true was distinguished from the false - the day when the two hosts met in battle. And God has the power to will anything.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 41,
          "number": 1201,
          "juz": 10,
          "numpage": 182
        },
        {
          "text": "[Remember that day] when you were at the near end of the valley [of Badr], and they were at its farthest end, while the caravan was below you. And if you had known that a battle was to take place, you would indeed have refused to accept the challenge: but [the battle was brought about none the less,] so that God might accomplish a thing [which He willed] to be done, [and] that he who would perish might perish in clear evidence of the truth, and that he who would remain alive might live in clear evidence of the truth. And, behold, God is indeed all-hearing, all-knowing.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 42,
          "number": 1202,
          "juz": 10,
          "numpage": 182
        },
        {
          "text": "Lo! God showed them to thee in a dream as few: for, had He shown them to you as many, you would surely have lost heart, and would surely have disagreed with one another about what to do. But God saved [you from this]: verily, He has full knowledge of what is the hearts [of men].",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 43,
          "number": 1203,
          "juz": 10,
          "numpage": 182
        },
        {
          "text": "And so, when you met in battle, He made them appear as few in your eyes -just as He made you appear as of little account in their eyes - so that God might accomplish a thing [which He willed] to be done: for all things go back to God [as their source].",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 44,
          "number": 1204,
          "juz": 10,
          "numpage": 182
        },
        {
          "text": "[Hence,] O you who have attained to faith, when you meet a host in battle, be firm, and remember God often; so that you might attain to a happy state!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 45,
          "number": 1205,
          "juz": 10,
          "numpage": 182
        }
      ]
    },
    {
      "181": [
        {
          "text": "But what have they [now] in their favour that God should not chastise them-seeing that they bar [the believers] from the Inviolable House of Worship, although they are not its [rightful] guardians? None but the God-conscious can be its guardians: but of this most of these [evildoers] are unaware;",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 34,
          "number": 1194,
          "juz": 9,
          "numpage": 181
        },
        {
          "text": "and their prayers before the Temple are nothing but whistling and clapping of hands. Taste then, [O unbelievers,] this chastisement as an outcome of your persistent denial of the truth!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 35,
          "number": 1195,
          "juz": 9,
          "numpage": 181
        },
        {
          "text": "Behold, those who are bent on denying the truth are spending their riches in order to turn others away from the path of God; and they will go on spending them until they become [a source of] intense regret for them; and then they will be overcome! And those who [until their death] have denied the truth shall be gathered unto hell,",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 36,
          "number": 1196,
          "juz": 9,
          "numpage": 181
        },
        {
          "text": "so that God might separate the bad from the good, and join the bad with one another, and link them all together [within His condemnation], and then place them in hell. They, they are the lost!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 37,
          "number": 1197,
          "juz": 9,
          "numpage": 181
        },
        {
          "text": "Tell those who are bent on denying the truth that if they desist, all that is past shall be forgiven them; but if they revert [to their wrongdoing], let them remember what happened to the like of them in times gone by.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 38,
          "number": 1198,
          "juz": 9,
          "numpage": 181
        },
        {
          "text": "And fight against them until there is no more oppression and all worship is devoted to God alone. And if they desist-behold, God sees all that they do;",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 39,
          "number": 1199,
          "juz": 9,
          "numpage": 181
        },
        {
          "text": "and if they turn away [from righteousness], know that God is your Lord Supreme: [and] how excellent is this Lord Supreme, and how excellent this Giver of Succour!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 40,
          "number": 1200,
          "juz": 9,
          "numpage": 181
        }
      ]
    },
    {
      "180": [
        {
          "text": "And remember the time when you were few [and] helpless on earth, fearful lest people do away with you - whereupon He sheltered you, and strengthened you with His succour, and provided for you sustenance out of the good things of life, so that you might have cause to be grateful.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 26,
          "number": 1186,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "[Hence,] O you who have attained to faith, do not be false to God and the Apostle, and do not knowingly be false to the trust that has been reposed in you;",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 27,
          "number": 1187,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "and know that your worldly goods and your children are but a trial and a temptation, and that with God there is a tremendous reward.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 28,
          "number": 1188,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "O you who have attained to faith! If you remain conscious of God. He will endow you with a standard by which to discern the true from the false, and will efface your bad deeds, and will forgive you your sins: for God is limitless in His great bounty.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 29,
          "number": 1189,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "AND [remember, O Prophet,] how those who were bent on denying the truth were scheming against thee, in order to restrain thee [from preaching], or to slay thee, or to drive thee away: thus have they [always] schemed: but God brought their scheming to nought-for God is above all schemers.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 30,
          "number": 1190,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "And whenever Our messages were conveyed to them, they would say, \"We have heard [all this] before; if we wanted, we could certainly compose sayings like these [ourselves]: they are nothing but fables of ancient times!\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 31,
          "number": 1191,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "And, lo, they would say, \"O God! If this. be indeed the truth from Thee, then rain down upon us stones from the skies, or inflict [some other] grievous suffering on us!\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 32,
          "number": 1192,
          "juz": 9,
          "numpage": 180
        },
        {
          "text": "But God did not choose thus to chastise them whip thou [O Prophet] wert still among them, nor would God chastise them when they [might yet] ask for forgiveness.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 33,
          "number": 1193,
          "juz": 9,
          "numpage": 180
        }
      ]
    },
    {
      "179": [
        {
          "text": "And yet, [O believers,] it was not you who slew the enemy, but it was God who slew them; and it was not thou who cast [terror into them, O Prophet], when thou didst cast it, but it was God who cast it: and [He did all this] in order that He might test the believers by a goodly test of His Own or daining. Verily, God is all-hearing, all-knowing!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 17,
          "number": 1177,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "This [was God's purpose] -and also [to show] that God renders vain the artful schemes of those who deny the truth.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 18,
          "number": 1178,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "If you have been praying for victory, [O believers] - victory has now indeed come unto you. And if you abstain [from sinning], it will be for your own good; but if you revert to it, We shall revoke [Our promise of aid] - and never will your community be of any avail to you, however great its numbers: for, behold, God is [only] with those who believe!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 19,
          "number": 1179,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "[Hence,] O you who have attained to faith, pay heed unto God and His Apostle, and do not turn away from Him now that you hear [His message];",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 20,
          "number": 1180,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "and be not like those who say, \"We have heard\", the while they do not hearken.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 21,
          "number": 1181,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "Verily, the vilest of all creatures in the sight of God are those deaf, those dumb ones who do not use their reason.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 22,
          "number": 1182,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "For, if God had seen any good in them, He would certainly have made them hear: but [as it is,] even if He had made them hear, they would surely have turned away in their obstinacy.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 23,
          "number": 1183,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "O you who have attained to faith! Respond to the call of God and the Apostle whenever he calls you unto that which will give you life; and know that God intervenes between man and [the desires of] his heart, and that unto Him you shall be gathered.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 24,
          "number": 1184,
          "juz": 9,
          "numpage": 179
        },
        {
          "text": "And beware of that temptation to evil which does not befall only those among you who are bent on denying the truth, to the exclusion of others; and know that God is severe in retribution.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 25,
          "number": 1185,
          "juz": 9,
          "numpage": 179
        }
      ]
    },
    {
      "178": [
        {
          "text": "Lo! You were praying unto your Sustainer for aid, whereupon He thus responded to you: \"I shall, verily, aid you with a thousand angels following one upon' another!\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 9,
          "number": 1169,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "And God ordained this only as a glad tiding, and that your hearts should thereby be set at rest-since no succour can come from any save God: verily, God is almighty, wise!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 10,
          "number": 1170,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "[Remember how it was] when He caused inner calm to enfold you, as an assurance from Him, and sent down upon you water from the skies, so that He might purify you thereby and free you from Satan's unclean whisperings and strengthen your hearts and thus make firm your steps.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 11,
          "number": 1171,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "Lo! Thy Sustainer inspired the angels [to convey this His message to the believers]: \"I am with you!\" [And He commanded the angels:] \"And, give firmness unto those who have attained to faith [with these words from Me]: `I shall cast terror into the hearts of those who are bent on denying the truth; strike, then, their necks, [O believers,] and strike off every one of their finger-tips!\"",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 12,
          "number": 1172,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "This, because they have cut themselves off from God and His Apostle: and as for him who cuts himself off from God and His Apostle - verily, God is severe in retribution.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 13,
          "number": 1173,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "This [for you, O enemies of God]! Taste it, then, [and know] that suffering through fire awaits those who deny the truth!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 14,
          "number": 1174,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "O YOU who have attained to faith! When you meet in battle those who are bent on denying the truth, advancing in great force, do not turn your backs on them:",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 15,
          "number": 1175,
          "juz": 9,
          "numpage": 178
        },
        {
          "text": "for, whoever on that day turns his back on them-unless it be in a battle manoeuvre or in an endeavour to join another troop [of the believers] - shall indeed have earned the burden of God's condemnation, and his goal shall be hell: and how vile a journey's end!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 16,
          "number": 1176,
          "juz": 9,
          "numpage": 178
        }
      ]
    },
    {
      "177": [
        {
          "text": "HEY WILL ASK thee about the spoils of war. Say: \"All spoils of war belong to God and the Apostle.\" Remain, then, conscious of God, and keep alive the bonds of brotherhood among yourselves, and pay heed unto God and His Apostle, if you are [truly] believers!",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 1,
          "number": 1161,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "Believers are only they whose hearts tremble with awe whenever God is mentioned, and whose faith is strengthened whenever His messages are conveyed unto them, and who in their Sustainer place their trust -",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 2,
          "number": 1162,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "those who are constant in prayer and spend on others out of what We provide for them as sustenance:",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 3,
          "number": 1163,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "it is they, they who are truly believers! Theirs shall be great dignity in their Sustainer's sight, and forgiveness of sins, and a most excellent sustenance.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 4,
          "number": 1164,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "EVEN AS thy Sustainer brought thee forth from thy home [to fight] in the cause of the truth, although some of the believers were averse to it,",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 5,
          "number": 1165,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "[so, too,] they would argue with thee about the truth [itself] after it had become manifest - just as if they were being driven towards death and beheld it with their very eyes.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 6,
          "number": 1166,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "And, lo, God gave you the promise that one of the two [enemy] hosts would fall to you: and you would have liked to seize the less powerful one, whereas it was God's will to prove the truth to be true in accordance with His words, and to wipe out the last remnant of those who denied the truth -",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 7,
          "number": 1167,
          "juz": 9,
          "numpage": 177
        },
        {
          "text": "so that He might prove the truth to be true and the false to be false, however hateful this might be to those who were lost in sin.",
          "englishName": "Al-Anfaal",
          "suraNumber": 8,
          "numberInSurah": 8,
          "number": 1168,
          "juz": 9,
          "numpage": 177
        }
      ]
    },
    {
      "176": [
        {
          "text": "Verily, my protector is God, who has bestowed this divine writ from on high: for it is He who protects the righteous,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 196,
          "number": 1150,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "whereas all those whom you invoke in His stead are neither able to give you succour nor can succour themselves;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 197,
          "number": 1151,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "and if you pray unto them for guidance, they do not hear; and though thou mayest imagine that they behold thee, they do not see.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 198,
          "number": 1152,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "MAKE due allowance for man's nature, and enjoin the doing of what is right; and leave alone all those who choose to remain ignorant.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 199,
          "number": 1153,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "And if it should happen that a prompting from Satan stirs thee up [to blind anger], seek refuge with God: behold, He is all-hearing, all-knowing.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 200,
          "number": 1154,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "Verily, they who are conscious of God bethink themselves [of Him] whenever any dark suggestion from Satan touches them - whereupon, lo! they begin to see [things] clearly,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 201,
          "number": 1155,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "even though their [godless] brethren would [like to] draw them into error: and then they cannot fail [to do what is right].",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 202,
          "number": 1156,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "And yet, when thou [O Prophet] dost not produce any miracle for them, some [people] say, \"Why dost thou not seek to obtain it [from God]? Say: \"I only follow whatever is being revealed to me by my Sustainer: this [revelation] is a means of insight from your Sustainer, and a guidance and grace unto people who will believe.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 203,
          "number": 1157,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "Hence, when the Qur'an is voiced, hearken unto it, and listen in silence, so that you might be graced with [God's] mercy.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 204,
          "number": 1158,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "And bethink thyself of thy Sustainer humbly and with awe, and without raising thy voice, at morn and at evening; and do not allow thyself to be heedless.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 205,
          "number": 1159,
          "juz": 9,
          "numpage": 176
        },
        {
          "text": "Behold, those who are near unto thy Sustainer are never too proud to worship Him; and they extol His limitless glory, and prostrate themselves before Him [alone].",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 206,
          "number": 1160,
          "juz": 9,
          "numpage": 176
        }
      ]
    },
    {
      "175": [
        {
          "text": "Say [O Prophet]: \"It is not within my power to bring benefit to, or avert harm from, myself, except as God may please. And if I knew that which is beyond the reach of human perception, abundant good fortune-would surely have fallen to my lot, and no evil would ever have touched me. I am nothing but a warner, and a herald of glad tidings unto people who will believe.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 188,
          "number": 1142,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "IT IS HE who has created you [all] out of one living entity, and out of it brought into being its mate, so that man might incline [with love] towards woman. And so, when he has embraced her, she conceives [what at first is] a light burden, and continues to bear it. Then, when she grows heavy [with child], they both call unto God, their Sustainer, \"If Thou indeed grant us a sound [child], we shall most certainly be among the grateful!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 189,
          "number": 1143,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "And yet, as soon as He has granted them sound [offspring], they begin to ascribe to other powers beside Him a share in bringing about what He has granted them! Sublimely exalted, however, is God- above anything to which men may ascribe a share in His divinity!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 190,
          "number": 1144,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "Will they, then,- ascribe divinity, side by side with Him, unto beings that cannot create anything since they themselves are created -",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 191,
          "number": 1145,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "and neither are able to give them succour nor can succour themselves,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 192,
          "number": 1146,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "and, if you pray to them for guidance, do not respond to you? As far as you are concerned, it is all one whether you invoke them or keep silent.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 193,
          "number": 1147,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "Verily, all those whom you invoke beside God are but created beings like yourselves: invoke them, then, and let them answer your prayer - if what you claim is true!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 194,
          "number": 1148,
          "juz": 9,
          "numpage": 175
        },
        {
          "text": "Have these [images], perchance, feet on which they could walk? Or have they hands with which they could grasp? Or have they eyes with which they could see? Or have they ears with which they could hear? Say [O Prophet]: \"Summon to your aid all those to whom you ascribe a share in God's divinity, and thereupon contrive [anything you may wish] against me, and give me no respite!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 195,
          "number": 1149,
          "juz": 9,
          "numpage": 175
        }
      ]
    },
    {
      "174": [
        {
          "text": "And most certainly have We destined for hell many of the invisible beings and men who have hearts with which they fail to grasp the truth, and eyes with which they fail to see, and ears with which they fail to hear. They are like cattle -nay, they are even less conscious of the right way: it is they, they who are the [truly] heedless!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 179,
          "number": 1133,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "AND GOD'S [alone] are the attributes of perfection; invoke Him, then, by these, and stand aloof from all who distort the meaning of His attributes: they shall be requited for all that they were wont to do!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 180,
          "number": 1134,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "Now, among those whom We have created there are people who guide [others] in the way of the truth and act justly in its light.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 181,
          "number": 1135,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "But as for those who are bent on giving the lie to Our messages-We shall bring them low, step by step, without their perceiving how it came about:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 182,
          "number": 1136,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "for, behold, though I may give them rein for a while. My subtle scheme is exceedingly firm!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 183,
          "number": 1137,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "Has it, then, never occurred to them that there is no madness whatever in [this] their fellowman? He is only a plain warner.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 184,
          "number": 1138,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "Have they, then, never considered [God's] mighty dominion over the heavens and the earth, and all the things that God has created, and [asked themselves] whether, perchance, the end of their own term might already have drawn nigh? In what other tiding, then, will they, after this, believe?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 185,
          "number": 1139,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "For those whom God lets go astray, there is no guide; and He shall leave them in their overweening arrogance, blindly stumbling to and fro.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 186,
          "number": 1140,
          "juz": 9,
          "numpage": 174
        },
        {
          "text": "THEY WILL ASK thee [O Prophet] about the Last Hour: \"When will it come to pass?\" Say: \"Verily, knowledge thereof rests with my Sustainer alone. None but He will reveal it in its time. Heavily will it weigh on the heavens and the earth; [and] it will not fall upon you otherwise than of a sudden.\" They will ask thee - as if thou couldst gain insight into this [mystery] by dint of persistent inquiry! Say: \"Knowledge thereof rests with my Sustainer alone; but [of this] most people are unaware.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 187,
          "number": 1141,
          "juz": 9,
          "numpage": 174
        }
      ]
    },
    {
      "173": [
        {
          "text": "And [did We not say,] when We caused Mount Sinai to quake above the children of Israel as though it were a [mere] shadow, and they thought that it would fall upon them, \"Hold fast with [all your] strength unto what We have vouchsafed you, and bear in mind all that is therein, so that you might remain conscious of God\"?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 171,
          "number": 1125,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "AND WHENEVER thy Sustainer brings forth their offspring from the loins of the children of Adam, He [thus] calls upon them to bear witness about themselves: \"Am I not your Sustainer?\" - to which they answer: \"Yea, indeed, we do bear witness thereto!\" [Of this We remind you,] lest you say on the Day of Resurrection, \"Verily, we were unaware of this\";",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 172,
          "number": 1126,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "or lest you say, \"Verily, it was but our forefathers who, in times gone by, began to ascribe divinity to other beings beside God; and we were but their late offspring: wilt Thou, then, destroy us for the doings of those inventors of falsehoods?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 173,
          "number": 1127,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "And thus clearly do We spell out these messages; and [We do it] so that they [who have sinned] might return [unto Us].",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 174,
          "number": 1128,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "And tell them what happens to him to whom We vouchsafe Our messages and who then discards them: Satan catches up with him, and he strays, like so many others, into grievous error.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 175,
          "number": 1129,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "Now had We so willed, We could indeed have exalted him by means of those [messages]: but he always clung to the earth and followed but his own desires. Thus, his parable is that of an [excited] dog: if thou approach him threateningly, he will pant with his tongue lolling; and. if thou leave him alone, he will pant with his tongue lolling. Such is the parable of those who are bent on giving the lie to Our messages. Tell [them], then, this story, so that they might take thought.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 176,
          "number": 1130,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "Evil is the example of people who are bent on giving the lie to Our messages: for it is against their own selves that they are sinning!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 177,
          "number": 1131,
          "juz": 9,
          "numpage": 173
        },
        {
          "text": "He whom God guides, he alone is truly guided; whereas those whom He lets go astray-it is they, they who are the losers!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 178,
          "number": 1132,
          "juz": 9,
          "numpage": 173
        }
      ]
    },
    {
      "172": [
        {
          "text": "And whenever some people among them asked [those who tried to restrain the Sabbath-breakers], \"Why do you preach to people whom God is about to destroy or [at least] to chastise with suffering severe?\" -the pious ones would answer, \"In order to be free from blame before your Sustainer, and that these [transgressors, too,] might become conscious of Him.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 164,
          "number": 1118,
          "juz": 9,
          "numpage": 172
        },
        {
          "text": "And thereupon, when those [sinners] had forgotten all that they had been told to take to. heart, We saved those who had tried to prevent the doing of evil, and overwhelmed those who had been bent on evildoing with dreadful suffering for all their iniquity;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 165,
          "number": 1119,
          "juz": 9,
          "numpage": 172
        },
        {
          "text": "and then, when they disdainfully persisted in doing what they had been forbidden to do, We said unto them: \"Be as apes despicable!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 166,
          "number": 1120,
          "juz": 9,
          "numpage": 172
        },
        {
          "text": "And lo! Thy Sustainer made it known that most certainly He would rouse against them, unto Resurrection Day, people who would afflict them with cruel suffering: verily, thy Sustainer is swift in retribution - yet, verily, He is [also] much-forgiving, a dispenser of grace.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 167,
          "number": 1121,
          "juz": 9,
          "numpage": 172
        },
        {
          "text": "And We dispersed them as [separate] communities all over the earth; some of them were righteous, and some of them less than that: and the latter We tried with blessings as well as with afflictions, so that they might mend their ways.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 168,
          "number": 1122,
          "juz": 9,
          "numpage": 172
        },
        {
          "text": "And they have been succeeded by [new] generations who - [in spite of] having inherited the divine writ-clutch but at the fleeting good of this lower world and say, \"We shall be forgiven, the while they are ready, if another such fleeting good should come their way, to clutch at it [and sin again]. Have they not been solemnly pledged through the divine writ not to attribute unto God aught but what is true, and [have they not] read again and again all that is therein? Since the life in the hereafter is the better [of the two] for all who are conscious of God -will you not, then, use your reason?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 169,
          "number": 1123,
          "juz": 9,
          "numpage": 172
        },
        {
          "text": "For [We shall requite] all those who hold fast to the divine writ and are constant in prayer: verily, We shall not fail to requite those who enjoin the doing of what is right!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 170,
          "number": 1124,
          "juz": 9,
          "numpage": 172
        }
      ]
    },
    {
      "171": [
        {
          "text": "And We divided them into twelve tribes, [or] communities. And when his people asked Moses for water, We inspired him, \"Strike the rock with thy staff!\" -whereupon twelve springs gushed forth from it, so that all the people knew whence to drink., And We caused the clouds to comfort them with their shade, and We sent down unto them manna and quails, [saying:] \"Partake of the good things which We have provided for you as sustenance.\" And [by all their sinning] they did no harm unto Us-but [only] against their own selves did they sin.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 160,
          "number": 1114,
          "juz": 9,
          "numpage": 171
        },
        {
          "text": "And [remember] when you were told: \"Dwell in this land and eat of its food as you may desire; but say, `Remove Thou from us the burden of our sins,' and enter the gate humbly - [whereupon] We shall forgive you your sins [and] shall amply reward the doers of good.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 161,
          "number": 1115,
          "juz": 9,
          "numpage": 171
        },
        {
          "text": "But those among them who were bent on wrongdoing substituted another saying for that which they had been given: and so We let loose against them a plague from heaven in requital of all their evil doings.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 162,
          "number": 1116,
          "juz": 9,
          "numpage": 171
        },
        {
          "text": "And ask them about that town which stood by the sea: how its people would profane the Sabbath whenever their fish came to them, breaking the water's surface, on a day on which they ought to have kept Sabbath -because they would not come to them on other than Sabbath-days! Thus did We try them by means of their [own] iniquitous doings.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 163,
          "number": 1117,
          "juz": 9,
          "numpage": 171
        }
      ]
    },
    {
      "170": [
        {
          "text": "And ordain Thou for us what is good in this world as well as in the life to come: behold, unto Thee have we turned in repentance!\" [God] answered: \"With My chastisement do I afflict whom I will - but My grace overspreads everything: and so I shall confer it on those who are conscious of Me and spend in charity, and who believe in Our messages-",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 156,
          "number": 1110,
          "juz": 9,
          "numpage": 170
        },
        {
          "text": "those who shall follow the [last] Apostle, the unlettered Prophet whom they shall find described in the Torah that is with them, and [later on] in the Gospel: [the Prophet] who will enjoin upon them the doing of what is right and forbid them the doing of what is wrong, and make lawful to them the good things of life and forbid them the bad things, and lift from them their burdens and the shackles that were upon them [aforetime]. Those, therefore, who shall believe in him, and honour him, and succour him, and follow the light that has been bestowed from on high through him-it is they that shall attain to a happy state.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 157,
          "number": 1111,
          "juz": 9,
          "numpage": 170
        },
        {
          "text": "Say [O Muhammad]: \"O mankind! Verily, I am an apostle of God to all of you, [sent by Him] unto whom the dominion over the heavens and the earth belongs! There is no deity save Him; He [alone] grants life and deals death!\" Believe, then, in God and His Apostle-the unlettered Prophet who believes in God and His words-and follow him, so that you might find guidance!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 158,
          "number": 1112,
          "juz": 9,
          "numpage": 170
        },
        {
          "text": "AND AMONG the folk of Moses there have been people who would guide [others] in the way of the truth and act justly in its light.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 159,
          "number": 1113,
          "juz": 9,
          "numpage": 170
        }
      ]
    },
    {
      "169": [
        {
          "text": "And when Moses returned to his people, full of wrath and sorrow, he exclaimed: \"Vile is the course which you have followed in my absence! Have you forsaken your Sustainer's commandment?\" And he threw down the tablets [of the Law], and seized his brother's head, dragging him towards himself. Cried Aaron: \"O my mother's son! Behold, the people brought me low and almost slew me: so let not mine enemies rejoice at my affliction, and count me not among the evildoing folk!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 150,
          "number": 1104,
          "juz": 9,
          "numpage": 169
        },
        {
          "text": "Said [Moses]: \"O my Sustainer! Grant Thou forgiveness unto me and my brother, and admit us unto Thy grace: for Thou art the most merciful of the merciful!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 151,
          "number": 1105,
          "juz": 9,
          "numpage": 169
        },
        {
          "text": "[And to Aaron he said:] \"Verily, as for those who have taken to worshipping the [golden] - calf - their Sustainer's condemnation will overtake them, and ignominy [will be their lot] in the -life of this world!\" For thus do We requite all who invent [such] falsehood.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 152,
          "number": 1106,
          "juz": 9,
          "numpage": 169
        },
        {
          "text": "But as for those who do bad deeds and afterwards repent and [truly] believe-verily, after such repentance thy Sustainer is indeed much forgiving, a dispenser of grace!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 153,
          "number": 1107,
          "juz": 9,
          "numpage": 169
        },
        {
          "text": "And when Moses' wrath as stilled, he took up the tablets, in the writing whereof there was guidance and grace for all who stood in awe of their Sustainer.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 154,
          "number": 1108,
          "juz": 9,
          "numpage": 169
        },
        {
          "text": "And Moses chose out of his people seventy men to come [and pray for forgiveness] at a time set by Us. Then, when violent trembling seized them, he prayed: \"O my Sustainer! Hadst Thou so willed, Thou wouldst have destroyed them ere this, and me [with them]. Wilt Thou destroy us for what the weak-minded among us have done? (All] this is but a trial from Thee, whereby Thou allowest to go astray whom Thou willest, and guidest aright whom Thou willest. Thou art near unto us: grant us, then, forgiveness and have mercy on us - for Thou art the best of all forgivers!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 155,
          "number": 1109,
          "juz": 9,
          "numpage": 169
        }
      ]
    },
    {
      "168": [
        {
          "text": "Said [God]: \"O Moses! Behold, I have raised thee above all people by virtue of the messages which I have entrusted to thee, and by virtue of My speaking [unto thee]: hold fast, therefore, unto what I have vouchsafed thee, and be among the grateful!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 144,
          "number": 1098,
          "juz": 9,
          "numpage": 168
        },
        {
          "text": "And We ordained for him in the tablets [of the Law] all manner of admonition, clearly spelling out everything. And [We said:] \"Hold fast unto them with [all thy] strength, and bid thy people to hold fast to their most goodly rules.\" I will show you the way the iniquitous shall go.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 145,
          "number": 1099,
          "juz": 9,
          "numpage": 168
        },
        {
          "text": "From My messages shall I cause to turn away all those who, without any right, behave haughtily on earth: for, though they may see every sign [of the truth], they do not believe in it, and though they may see the path of rectitude, they do not choose to follow it-whereas, if they see a path of error, they take it for their own: this, because they have given the lie to Our messages, and have remained heedless of them,\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 146,
          "number": 1100,
          "juz": 9,
          "numpage": 168
        },
        {
          "text": "Hence, all who give the lie to Our messages, and [thus] to the truth of the life to come -in vain shall be all their doings: [for] are they to be rewarded for aught but what they were wont to do?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 147,
          "number": 1101,
          "juz": 9,
          "numpage": 168
        },
        {
          "text": "AND IN his absence the people of Moses took to worshipping the effigy of a calf [made] of their ornaments, which gave forth a lowing sound.\" Did they not see that it could neither speak unto them nor guide them in any way? [And yet] they took to worshipping it, for they were evildoers:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 148,
          "number": 1102,
          "juz": 9,
          "numpage": 168
        },
        {
          "text": "although [later,] when they would smite their hands in remorse, having perceived that they had gone astray, they would say, \"Indeed, unless our Sustainer have mercy on us and grant us forgiveness, we shall most certainly be among the lost!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 149,
          "number": 1103,
          "juz": 9,
          "numpage": 168
        }
      ]
    },
    {
      "167": [
        {
          "text": "AND WE BROUGHT the children of Israel across the sea; and thereupon they came upon people who were devoted to the worship of some idols of theirs. Said [the children of Israel]: \"O Moses, set up for us a god even as they have gods!\" He replied: \"Verily, you are people without any awareness [of right and wrong]!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 138,
          "number": 1092,
          "juz": 9,
          "numpage": 167
        },
        {
          "text": "As for these here -verily, their way of life is bound to lead to destruction; and worthless is all that they have ever done!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 139,
          "number": 1093,
          "juz": 9,
          "numpage": 167
        },
        {
          "text": "[And] he said: \"Am I to seek for you a deity other than God, although it is He who has favoured you above all other people?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 140,
          "number": 1094,
          "juz": 9,
          "numpage": 167
        },
        {
          "text": "And [he reminded them of this word of God]: \"Lo, We saved you from Pharaoh's people who afflicted you with cruel suffering, slaying your sons in great numbers and sparing [only] your women - which was an awesome trial from your Sustainer.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 141,
          "number": 1095,
          "juz": 9,
          "numpage": 167
        },
        {
          "text": "AND [then] We appointed for Moses thirty nights [on Mount Sinai]; and We added to them ten, whereby the term of forty nights set bye, his Sustainer was fulfilled. And Moses said unto his brother Aaron: \"Take thou my place among my people; and act righteously, and follow not the path of the spreaders of corruption.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 142,
          "number": 1096,
          "juz": 9,
          "numpage": 167
        },
        {
          "text": "And when Moses came [to Mount Sinai] at the time set by Us, and his Sustainer spoke unto him, he said: \"O my Sustainer! Show [Thyself] unto me, so that I might behold Thee!\" Said [God]: \"Never canst thou see Me. However, behold this mountain: if it remains firm in its place, then - only then - wilt thou see Me. And as soon as his Sustainer revealed His glory to the mountain, He caused it to crumble to dust; and Moses fell down in a swoon. And when he came to himself, he said: \"Limitless art Thou in Thy glory! Unto Thee do I turn in repentance; and I shall [always] be the first to believe in Thee!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 143,
          "number": 1097,
          "juz": 9,
          "numpage": 167
        }
      ]
    },
    {
      "166": [
        {
          "text": "But whenever good fortune alighted upon them, they would say, \"This is [but] our due\"; and whenever affliction befell them, they would blame their evil fortune on Moses and those who followed him. Oh, verily, their [evil] fortune had been decreed by God-but most of them knew it not.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 131,
          "number": 1085,
          "juz": 9,
          "numpage": 166
        },
        {
          "text": "And they said [unto Moses]: \"Whatever sign thou mayest produce before us in order to cast a spell upon us thereby, we shall not believe thee!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 132,
          "number": 1086,
          "juz": 9,
          "numpage": 166
        },
        {
          "text": "Thereupon We let loose upon them floods, and [plagues of] locusts, and lice, and frogs, and [water turning into] blood -distinct signs [all]: but they gloried in their arrogance, for they were people lost in sin.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 133,
          "number": 1087,
          "juz": 9,
          "numpage": 166
        },
        {
          "text": "And whenever a plague struck them, they would cry: \"O Moses, pray for us to thy Sustainer on the strength of the covenant [of prophethood] which He has made with thee! If thou remove this plague from us, we will truly believe in thee. and will let the children of Israel go with thee!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 134,
          "number": 1088,
          "juz": 9,
          "numpage": 166
        },
        {
          "text": "But whenever We removed the plague from them, giving them time to make good their promise, lo, they would break their word.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 135,
          "number": 1089,
          "juz": 9,
          "numpage": 166
        },
        {
          "text": "And so We inflicted Our retribution on them, and caused them to drown in the sea, because they had given the lie to Our messages and had been heedless of them;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 136,
          "number": 1090,
          "juz": 9,
          "numpage": 166
        },
        {
          "text": "whereas unto the people who [in the past] had been deemed utterly low, We gave as their heritage the eastern and western parts of the land that We had blessed. And [thus] thy Sustainer's good promise unto the children of Israel was fulfilled in result of their patience in adversity; whereas We utterly destroyed all that Pharaoh and his people had wrought, and all that they had built.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 137,
          "number": 1091,
          "juz": 9,
          "numpage": 166
        }
      ]
    },
    {
      "165": [
        {
          "text": "[and] exclaiming: \"We have come to believe in the Sustainer of all the worlds,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 121,
          "number": 1075,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "the Sustainer of Moses and Aaron!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 122,
          "number": 1076,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "Said Pharaoh: \"Have you come to believe in him ere I have given you permission? Behold, this is indeed a plot which you have cunningly devised in this [my] city in order to drive out its people hence! But in time you shall come to know, [my revenge]:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 123,
          "number": 1077,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "most certainly shall I cut off your hands and your feet in great numbers, because of [your] perverseness, and then I shall most certainly crucify you, in great numbers, all together! \"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 124,
          "number": 1078,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "They answered: \"Verily, unto our Sustainer do we turn-",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 125,
          "number": 1079,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "for thou takest vengeance on us only because we have come to believe in our Sustainer's messages as soon as they came to us. O our Sustainer! Shower us with patience in adversity, and make us die as men who have surrendered themselves unto Thee!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 126,
          "number": 1080,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "And the great ones among Pharaoh's people said: \"Wilt thou allow Moses and his people to spread corruption on earth, and to [cause thy people to] forsake thee and thy gods?\" [Pharaoh] replied: \"We shall slay their sons in great numbers and shall spare [only] their women: for, verily, we hold sway over them!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 127,
          "number": 1081,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "[And] Moses said unto his people: \"Turn unto God for aid, and have patience in adversity. Verily, all the earth belongs to God: He gives it as a heritage - to such as He wills of His servants; and the future belongs to the God-conscious!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 128,
          "number": 1082,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "[But the children of Israel] said: \"We have suffered hurt ere thou camest to us and since thou hast come to us!\" [Moses] replied: \"It may well be that your Sustainer will destroy your foe and make you inherit the earth: and thereupon he will behold how you act.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 129,
          "number": 1083,
          "juz": 9,
          "numpage": 165
        },
        {
          "text": "And most certainly did We overwhelm Pharaoh's people with drought and scarcity of fruits, so that they might take it to heart.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 130,
          "number": 1084,
          "juz": 9,
          "numpage": 165
        }
      ]
    },
    {
      "164": [
        {
          "text": "so constituted that I cannot say anything about God but the truth. I have now come unto you with a clear evidence from your Sustainer: let, then, the children of Israel go with me!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 105,
          "number": 1059,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "Said [Pharaoh]: \"If thou hast come with a sign, produce it-if thou art a man of truth!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 106,
          "number": 1060,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "Thereupon [Moses] threw down his staff, and lo! it was a serpent, plainly visible;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 107,
          "number": 1061,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "and he drew forth his hand, and lo! it appeared [shining] white to the beholders.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 108,
          "number": 1062,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "The great ones among Pharaoh's people said: \"Verily, this is indeed a sorcerer of great knowledge,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 109,
          "number": 1063,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "who wants to drive you out of your land! \" [Said Pharaoh:] \"What, then, do you advise?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 110,
          "number": 1064,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "They answered: \"Let him and his brother wait awhile, and send unto all cities heralds",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 111,
          "number": 1065,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "who shall bring before thee every sorcerer of great knowledge.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 112,
          "number": 1066,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "And the sorcerers came unto Pharaoh [and] said: \"Verily, we ought to have a great reward if it is we who prevail.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 113,
          "number": 1067,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "Answered [Pharaoh]: \"Yes; and, verily, you shall be among those who are near unto me.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 114,
          "number": 1068,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "They said: \"O Moses! Either thou shalt throw [thy staff first], or we shall [be the first to] throw.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 115,
          "number": 1069,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "He answered: \"You throw [first].\" And when they threw down [their staffs], they cast a spell upon the people's eyes, and struck them with awe, and produced mighty sorcery.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 116,
          "number": 1070,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "And [then] We inspired Moses, \"Throw down thy staff!\" -and lo! it swallowed up all their deceptions:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 117,
          "number": 1071,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "whereupon the truth was established, and vain was proved all that they had been doing.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 118,
          "number": 1072,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "And thus were they vanquished there and then, and became utterly humiliated.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 119,
          "number": 1073,
          "juz": 9,
          "numpage": 164
        },
        {
          "text": "And down fell the sorcerers, prostrating themselves",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 120,
          "number": 1074,
          "juz": 9,
          "numpage": 164
        }
      ]
    },
    {
      "163": [
        {
          "text": "Yet if the people of those communities had but attained to faith and been conscious of Us, We would indeed have opened up for them blessings out of heaven and earth: but they gave the lie to the truth - and so We took them to task through what they [themselves] had been doing.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 96,
          "number": 1050,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "Can, then, the people of any community ever feel secure that Our punishment will not come upon them by night, while they are asleep?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 97,
          "number": 1051,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "Why, can the people of any community ever feel secure that Our punishment will not come upon them in broad daylight, while they are engaged in (worldly] play?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 98,
          "number": 1052,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "Can they, then, ever feel secure from God's deep devising? But none feels secure from God's deep devising save people who are [already] lost.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 99,
          "number": 1053,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "Has it, then, not become obvious unto those who have inherited the earth in the wake of former generations that, if We so willed, We could smite them [too] by means of their sins, sealing their hearts so that they cannot hear [the truth]?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 100,
          "number": 1054,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "Unto those [earlier] communities - some of whose stories We [now] relate unto thee -there had indeed come apostles of their own with all evidence of the truth; but they would not believe in anything to which they had once given the lie: thus it is that God seals the hearts of those who deny the truth;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 101,
          "number": 1055,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "and in most of them We found no [inner] bond with anything that is right -and most of them We found to be iniquitous indeed.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 102,
          "number": 1056,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "AND AFTER those [early people] We sent Moses with Our messages unto Pharaoh and his great ones, and they wilfully rejected them: and behold what happened in the end to those spreaders of corruption!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 103,
          "number": 1057,
          "juz": 9,
          "numpage": 163
        },
        {
          "text": "And Moses said: \"O Pharaoh! Verily, I am an apostle from the Sustainer of all the worlds,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 104,
          "number": 1058,
          "juz": 9,
          "numpage": 163
        }
      ]
    },
    {
      "162": [
        {
          "text": "Said the great ones among his people, who gloried in their arrogance: \"Most certainly, O Shu'ayb, we shall expel thee and thy fellow-believers from our land, unless you indeed return to our ways!\" Said [Shu'ayb]: \"Why, even though we abhor [them]?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 88,
          "number": 1042,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "We should be guilty of blaspheming against God were we to return to your ways after God has saved us from them! It is not conceivable that we should return to them-unless God, our Sustainer, so wills. All things does our Sustainer embrace within His knowledge; in God do we place our trust. O our Sustainer! Lay Thou open the truth between us and our people -for Thou art the best of all to lay open the truth!”",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 89,
          "number": 1043,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "But the great ones among his people, who were bent on denying the truth, said [to his followers]: \"Indeed, if you follow Shu'ayb, you will, verily, be the losers!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 90,
          "number": 1044,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "Thereupon an earthquake overtook them: and then they lay lifeless, in their very homes, on the ground -",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 91,
          "number": 1045,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "they who had given the lie to Shu'ayb - as though they had never lived there: they who had given the lie to Shu'ayb -it was they who were the losers!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 92,
          "number": 1046,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "And he turned away from them, and said: \"O my people! Indeed, I delivered unto you my Sustainer's message and gave you good advice: how, then, could I mourn for people who have denied the truth?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 93,
          "number": 1047,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "AND NEVER YET have We sent a prophet unto any community without trying its people with misfortune and hardship, so that they might humble themselves;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 94,
          "number": 1048,
          "juz": 9,
          "numpage": 162
        },
        {
          "text": "then We transformed the affliction into ease of life, so that they throve and said [to themselves], \"Misfortune and hardship befell our forefathers as well -whereupon We took them to task, all of a sudden, without their being aware [of what was coming].",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 95,
          "number": 1049,
          "juz": 9,
          "numpage": 162
        }
      ]
    },
    {
      "161": [
        {
          "text": "But his people's only answer was this: \"Expel them from your land! Verily, they are folk who make themselves out to be pure!”",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 82,
          "number": 1036,
          "juz": 8,
          "numpage": 161
        },
        {
          "text": "Thereupon We saved him and his household except his wife, who was among those that stayed behind",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 83,
          "number": 1037,
          "juz": 8,
          "numpage": 161
        },
        {
          "text": "the while We rained a rain [of destruction] upon the others: and behold what happened in the end to those people lost in sin!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 84,
          "number": 1038,
          "juz": 8,
          "numpage": 161
        },
        {
          "text": "AND UNTO [the people of] Madyan [We sent] their brother Shu'ayb. He said: \"O my people! Worship God alone: you have no deity other than Him. Clear evidence of the truth has now come unto you from your Sustainer. Give, therefore, full measure and weight [in all your dealings], and do not deprive people of what is rightfully theirs; and do not spread corruption on earth after it has be, en so well ordered: [all] this is for your own good, 'if you would but believe.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 85,
          "number": 1039,
          "juz": 8,
          "numpage": 161
        },
        {
          "text": "And do not lie in ambush by every road [that leads to the truth], threatening and trying to turn away from God's path all who believe in Him, and trying to make it appear crooked. And remember [the time] when you were few, and [how] He made you many: and behold what happened in the end to the spreaders of corruption!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 86,
          "number": 1040,
          "juz": 8,
          "numpage": 161
        },
        {
          "text": "\"And if there be some among you who have come to believe in the message which I bear, the while the others do not believe, then have patience in adversity till God shall judge between us [and them]: for He is the best of all judges!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 87,
          "number": 1041,
          "juz": 8,
          "numpage": 161
        }
      ]
    },
    {
      "160": [
        {
          "text": "\"And remember how He made you heirs to [the tribe of] `Ad and settled you firmly on earth, so that you [are able to] build for yourselves castles on its plains and hew out mountains [to serve you] as dwellings: remember, then, God's blessings, and do not act wickedly on earth by spreading corruption.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 74,
          "number": 1028,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "The great ones among his people, who gloried in their arrogance towards all who were deemed weak, said unto the believers among them: \"Do you [really] know that salih has been sent by his Sustainer?\" They answered: \"Verily, we believe in the message which he bears.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 75,
          "number": 1029,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "[But] the arrogant ones said: \"Behold, what you have come to believe in we refuse to regard as true!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 76,
          "number": 1030,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "And then they cruelly slaughtered the she-camel and turned with disdain from their Sustainer's commandment, and said: \"O Salih! Bring about that [punishment] with which thou hast threatened us, if thou art truly one of God's message bearers!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 77,
          "number": 1031,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "Thereupon an earthquake overtook them: and then they lay lifeless, in their very homes, on the ground.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 78,
          "number": 1032,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "And [Salih] turned away from them, and said: \"O my people! Indeed, I delivered unto you my Sustainer's message and gave you good advice: but you did not love those who gave [you] good advice.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 79,
          "number": 1033,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "AND [remember] Lot, when he said unto his people: \"Will you commit abominations such as none in all the world has ever done before you?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 80,
          "number": 1034,
          "juz": 8,
          "numpage": 160
        },
        {
          "text": "Verily, with lust you approach men instead of women: nay, but you are people given to excesses!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 81,
          "number": 1035,
          "juz": 8,
          "numpage": 160
        }
      ]
    },
    {
      "159": [
        {
          "text": "I am delivering unto you my Sustainer's messages and advising you truly and wells.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 68,
          "number": 1022,
          "juz": 8,
          "numpage": 159
        },
        {
          "text": "Why, do you deem it strange that a tiding from your Sustainer should have come unto you through a man from among yourselves, so that he might warn you? Do but remember how He made you heirs to Noah's people, and endowed you abundantly with power: remember, then, God's blessings, so that you might attain to a happy state!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 69,
          "number": 1023,
          "juz": 8,
          "numpage": 159
        },
        {
          "text": "They answered: \"Hast thou come to us [with the demand] that we worship God alone, and give up all that our forefathers were wont to worship? Bring about, then, that [punishment] with which thou hast threatened us, if thou art a man of truth!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 70,
          "number": 1024,
          "juz": 8,
          "numpage": 159
        },
        {
          "text": "Said [Hud]: \"You are already beset by loath some evils and by your Sustainer's condemnation! Do you argue with me about the [empty] names which you have invented - you and your forefathers - for which God has bestowed no warrant from on high? Wait, then, [for what will happen:] verily, I shall wait with you!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 71,
          "number": 1025,
          "juz": 8,
          "numpage": 159
        },
        {
          "text": "And so, by Our grace, We saved him and those who stood by him, the while We wiped out the last remnant of those who gave the lie to Our messages and would not believe.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 72,
          "number": 1026,
          "juz": 8,
          "numpage": 159
        },
        {
          "text": "AND UNTO [the tribe of] Thamud [We sent] their brother salih He said: \"O my people! Worship God alone: you have no deity other than Him. Clear evidence of the truth has now come unto you from your Sustainer. \"This she-camel belonging to God shall be a token for you: so leave her alone to pasture on God's earth, and do her no harm, lest grievous chastisement befall you.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 73,
          "number": 1027,
          "juz": 8,
          "numpage": 159
        }
      ]
    },
    {
      "158": [
        {
          "text": "As for the good land, its vegetation comes forth [in abundance] by its Sustainer's leave, whereas from the bad it comes forth but poorly. Thus do We give many facets to Our messages for [the benefit of] people who are grateful!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 58,
          "number": 1012,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "INDEED, We sent forth Noah unto his people, and he said: \"O my people! Worship God alone: you have no deity other than Him. Verily, I fear lest suffering befall you on an awesome Day!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 59,
          "number": 1013,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "The great ones among his people replied: \"Verily, we see that thou art obviously lost in error!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 60,
          "number": 1014,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "Said [Noah]: \"O my people! There is no error in me, but I am an apostle from the Sustainer of all the worlds.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 61,
          "number": 1015,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "I am delivering unto you my Sustainer's messages and giving you good advice: for I know [through revelation] from God what you do not know.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 62,
          "number": 1016,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "Why, do you deem it strange that a tiding from your Sustainer should have come unto you through a man from among yourselves, so that he might warn you, and that you might become conscious of God, and that you might be graced with His mercy?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 63,
          "number": 1017,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "And yet they gave him the lie! And so We saved him and those who stood by him, in the ark, the while We caused those who had given the lie to Our messages to drown: verily, they were blind folk!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 64,
          "number": 1018,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "AND UNTO [the tribe of] `Ad [We sent] their brother Hud. He said: \"O my people! Worship God alone: you have no deity other than Him. Will you not, then, be conscious of Him?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 65,
          "number": 1019,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "Said the great ones among his people, who refused to acknowledge the truth: \"Verily, we see that thou art weak-minded; and, verily, we think that thou art a liar!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 66,
          "number": 1020,
          "juz": 8,
          "numpage": 158
        },
        {
          "text": "Said [Hud]: \"O my people! There is no weak-mindedness in me, but I am an apostle from the Sustainer of all the worlds.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 67,
          "number": 1021,
          "juz": 8,
          "numpage": 158
        }
      ]
    },
    {
      "157": [
        {
          "text": "for, indeed, We did convey unto them a divine writ which We clearly, and wisely, spelled out - a guidance and a grace unto people who will believe.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 52,
          "number": 1006,
          "juz": 8,
          "numpage": 157
        },
        {
          "text": "Are [the unbelievers] but waiting for the final meaning of that [Day of Judgment] to unfold? [But] on the Day when its final meaning is unfolded, those who aforetime had been oblivious thereof will say: \"Our Sustainer's apostles have indeed told us the truth! Have we, then, any intercessors who could intercede in our behalf? Or could we be brought back [to life] so that we might act otherwise than we were wont to act?\" Indeed, they will have squandered their own selves, and all their false imagery will have forsaken them.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 53,
          "number": 1007,
          "juz": 8,
          "numpage": 157
        },
        {
          "text": "VERILY, your Sustainer is God, who has created the heavens and the earth in six aeons, and is established on the throne of His almightiness. He covers the day with the night in swift pursuit, with the sun and the moon and the stars subservient to His command: oh, verily, His is all creation and all command. Hallowed is God, the Sustainer of all the worlds!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 54,
          "number": 1008,
          "juz": 8,
          "numpage": 157
        },
        {
          "text": "Call unto your Sustainer humbly, and in the secrecy of your hearts. Verily, He loves not those who transgress the bounds of what is right:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 55,
          "number": 1009,
          "juz": 8,
          "numpage": 157
        },
        {
          "text": "hence, do not spread corruption on earth after it has been so well ordered. And call unto Him with fear and longing: verily, God's grace is ever near unto the doers of good!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 56,
          "number": 1010,
          "juz": 8,
          "numpage": 157
        },
        {
          "text": "And He it is who sends forth the winds as a glad tiding of His coming grace-so that, when they have brought heavy clouds, We may drive them towards dead land and cause thereby water to descend; and by this means do We cause all manner of fruit to come forth. Even thus shall We cause the \\dead to come forth: [and this] you ought to keep in mind.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 57,
          "number": 1011,
          "juz": 8,
          "numpage": 157
        }
      ]
    },
    {
      "156": [
        {
          "text": "And the inmates of paradise will call out to the inmates of the fire: \"Now we have found that what our Sustainer promised us has come true; have you. too, found that what your Sustainer promised you has come true?\" [The others] will answer, \"Yes!\"-whereupon from their midst a voice will loudly proclaim: \"God's rejection is the due of the evildoers",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 44,
          "number": 998,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "who turn others away from God's path and try to make it appear crooked, and who refuse to acknowledge the truth of the life to come!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 45,
          "number": 999,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "And between the two there will be a barrier. And there will be persons who [in life] were endowed with the faculty of discernment [between right and wrong], recognizing each by its mark. And they will call out unto the inmates of paradise, \"Peace be upon you!\"-not having entered it themselves, but longing [for it].",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 46,
          "number": 1000,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "And whenever their eyes are turned towards the inmates of the fire, they will cry: \"O our Sustainer! Place us not among the people who have been guilty of evildoing!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 47,
          "number": 1001,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "And they who [in life] had possessed this faculty of discernment will call out to those whom they recognize by their marks [as sinners], saying: \"What has your amassing [of wealth] availed you, and all the false pride of your past?",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 48,
          "number": 1002,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "Are those [blessed ones] the self-same people of whom you once solemnly declared, `Never will God bestow His grace upon them'? [For now they have been told,] `Enter paradise; no fear need you have, and neither shall you grieve!\"'",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 49,
          "number": 1003,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "And the inmates of the fire will call out unto the inmates of paradise: \"Pour some water upon us, or some of the sustenance [of paradise] which God has provided for you!\" [The inmates of paradise] will reply: \"Verily, God has denied both to those who have denied the truth -",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 50,
          "number": 1004,
          "juz": 8,
          "numpage": 156
        },
        {
          "text": "those who, beguiled by the life of this world, have made play and passing delights their religion! [And God will say:] \"And so We shall be oblivious of them today as they were oblivious of the coming of this their Day [of Judgment], and as Our messages they did deny:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 51,
          "number": 1005,
          "juz": 8,
          "numpage": 156
        }
      ]
    },
    {
      "155": [
        {
          "text": "[And God] will say: \"Join those hosts of invisible beings and humans who have gone before you into the fire!\" [And] every time a host enters [the fire], it will curse its fellow-host -so much so that, when they all shall have passed into it, one after another, the last of them will speak [thus] of the first of them: \"O our Sustainer! It is they who have led us astray: give, them, therefore, double suffering through fire!\" He, will reply: \"Every one of you deserves double suffering -but you know it not.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 38,
          "number": 992,
          "juz": 8,
          "numpage": 155
        },
        {
          "text": "And the first of them will say unto the last of them: \"So you were in no wise superior to us! Taste, then, this suffering for all [the evil] that you were wont to do!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 39,
          "number": 993,
          "juz": 8,
          "numpage": 155
        },
        {
          "text": "VERILY, unto those who give the lie to Our messages and scorn them in their pride, the gates of heaven shall not be opened; and they shall not enter paradise any more than a twisted rope can pass through a needle's eye: for thus do We requite such as are lost in sin.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 40,
          "number": 994,
          "juz": 8,
          "numpage": 155
        },
        {
          "text": "Hell will be their resting-place and their covering as well: for thus do We requite the evildoers.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 41,
          "number": 995,
          "juz": 8,
          "numpage": 155
        },
        {
          "text": "But those who attain to faith and do righteous deeds - [and] We do not burden any human being with more than he is well able to bear - they are destined for paradise, therein to abide,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 42,
          "number": 996,
          "juz": 8,
          "numpage": 155
        },
        {
          "text": "after We shall have removed whatever unworthy thoughts or feelings may have been [lingering] in their bosoms. Running waters will flow at their feet; and they will say: \"All praise is due to God, who has guided us unto this; for we would certainly not have found the right path unless God had guided us! Indeed, our Sustainer's apostles have told us the truth!\" And [a voice] will call out unto them: \"This is the paradise which you have inherited by virtue of your past deeds!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 43,
          "number": 997,
          "juz": 8,
          "numpage": 155
        }
      ]
    },
    {
      "154": [
        {
          "text": "O CHILDREN of Adam! Beautify yourselves for every act of worship, and eat and drink [freely], but do not waste: verily, He does not love the wasteful!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 31,
          "number": 985,
          "juz": 8,
          "numpage": 154
        },
        {
          "text": "Say: \"Who is there to forbid the beauty which God has brought forth for His creatures, and the good things from among the means of sustenance?\" Say: \"They are [lawful] in the life of this world unto all who have attained to faith - to be theirs alone on Resurrection Day.\" Thus clearly do We spell out these messages unto people of [innate] knowledge!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 32,
          "number": 986,
          "juz": 8,
          "numpage": 154
        },
        {
          "text": "Say: \"Verily, my Sustainer has forbidden only shameful deeds, be they open or secret, and [every kind of] sinning, and unjustified envy, and the ascribing of divinity to aught beside Him - since He has never bestowed any warrant therefor from on high and the attributing unto God of aught of which you have no knowledge.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 33,
          "number": 987,
          "juz": 8,
          "numpage": 154
        },
        {
          "text": "And for all people a term has been set: and when [the end of] their term approaches, they can neither delay it by a single moment, nor can they hasten it.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 34,
          "number": 988,
          "juz": 8,
          "numpage": 154
        },
        {
          "text": "O CHILDREN of Adam! Whenever there come unto you apostles of your own, conveying My messages unto you, then all who are conscious of Me and live righteously - no fear need they have, and neither shall they grieve;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 35,
          "number": 989,
          "juz": 8,
          "numpage": 154
        },
        {
          "text": "but they who give the lie to Our messages and scorn them in their pride-these are destined for the fire, therein to abide.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 36,
          "number": 990,
          "juz": 8,
          "numpage": 154
        },
        {
          "text": "And who could be more wicked than they who attribute their own lying inventions to God or give the lie to His messages? Whatever has been decreed to be their lot [in life] will be theirs -till there shall come _ unto them Our messengers to cause them to die, [and] shall say, \"Where, now, are those beings whom you were wont to invoke beside God?\" And [those sinners] will reply, \"They have forsaken us!\" -and [thus] they will bear witness against themselves that they had been denying the truth.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 37,
          "number": 991,
          "juz": 8,
          "numpage": 154
        }
      ]
    },
    {
      "153": [
        {
          "text": "The two replied: \"O our Sustainer! We have sinned against ourselves -and unless Thou grant us forgiveness and bestow Thy mercy upon us, we shall most certainly be lost!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 23,
          "number": 977,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "Said He: \"Down with you, [and be henceforth] enemies unto one another, having on earth your abode and livelihood for a while:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 24,
          "number": 978,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "there shall you live\" - He added - \"and there shall you die, and thence shall you be brought forth [on Resurrection Day]!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 25,
          "number": 979,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "O CHILDREN of Adam! Indeed, We have bestowed upon you from on high [the knowledge of making] garments to cover your nakedness, and as a thing of beauty: but the garment of God-consciousness is the best of all. Herein lies a message from God, so that man” might take it to heart.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 26,
          "number": 980,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "O children of Adam! Do not allow Satan to seduce you in the same way as he caused your ancestors to be driven out of the garden: he deprived them of their garment [of God-consciousness] in order to make them aware of their nakedness. Verily, he and his tribe are lying in wait for you where you cannot perceive them! Verily, We have placed [all manner of] satanic forces near unto those who do not [truly] believe;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 27,
          "number": 981,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "and [so,] whenever they commit a shameful deed, they are wont to say, \"We found our forefathers doing it,\" and, \"God has enjoined it upon us.\" Say: \"Behold, never does God enjoin deeds of abomination. Would you attribute unto God something of which you have no knowledge?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 28,
          "number": 982,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "Say: \"My Sustainer has [but] enjoined the doing of what is right; and [He desires you to] put your whole being into every act of worship, and to call unto Him, sincere in your faith in Him alone. As it was He who brought you into being in the first instance, so also [unto Him] you will return:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 29,
          "number": 983,
          "juz": 8,
          "numpage": 153
        },
        {
          "text": "some [of you] He will have graced with His guidance, whereas, for some a straying from the right path will have become unavoidable: for, behold, they will have taken [their own] evil impulses for their masters in preference to God, thinking all the while that they have found the right path!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 30,
          "number": 984,
          "juz": 8,
          "numpage": 153
        }
      ]
    },
    {
      "152": [
        {
          "text": "[And God] said: \"What has kept thee from prostrating thyself when I commanded thee?\" Answered [Iblis]: \"I am better than he: Thou hast created me out of fire, whereas him Thou hast created out of clay.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 12,
          "number": 966,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "[God] said: \"Down with thee, then, from this [state] -for it is not meet for thee to show arrogance here! Go forth, then: verily, among the humiliated shalt thou be!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 13,
          "number": 967,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "Said [Iblis]: \"Grant me a respite till the Day when all shall be raised from the dead.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 14,
          "number": 968,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "[And God] replied: \"Verily, thou shalt be among those who are granted a respite.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 15,
          "number": 969,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "[Whereupon Iblis] said: \"Now that Thou hast thwarted me,\" shall most certainly lie in ambush for them all along Thy straight way,",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 16,
          "number": 970,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "and shall most certainly fall upon them openly as well as in a manner beyond their ken, and from their right and from their left: and most of them Thou wilt find ungrateful.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 17,
          "number": 971,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "[And God] said: \"Go forth from here, disgraced and disowned! [And] as for such of them as follow thee - I will most certainly fill hell with you all!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 18,
          "number": 972,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "And [as for thee], O Adam', dwell thou and thy wife in this garden, and eat, both of you, whatever you may wish; but do not approach this one tree, lest you become evildoers!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 19,
          "number": 973,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "Thereupon Satan whispered unto the two with a view to making them conscious of their nakedness, of which [hitherto] they had been unaware; and he said: \"Your Sustainer has but forbidden you this tree lest you two become [as] angels, or lest you live forever.\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 20,
          "number": 974,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "And he swore unto them, \"Verily, I am of those who wish you well indeed!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 21,
          "number": 975,
          "juz": 8,
          "numpage": 152
        },
        {
          "text": "-and thus he led them on with deluding thoughts. But as soon as the two had tasted [the fruit] of the tree, they became conscious of their nakedness; and they began to cover themselves with pieced-together leaves from the garden. And their Sustainer called unto them: \"Did I not forbid that tree unto you and tell you, `Verily, Satan is your open foe'?\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 22,
          "number": 976,
          "juz": 8,
          "numpage": 152
        }
      ]
    },
    {
      "151": [
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 1,
          "number": 955,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "A DIVINE WRIT has been bestowed from on high upon thee -and let there be no doubt about this in thy heart-in order that thou mayest warn [the erring] thereby, and [thus] admonish the believers:",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 2,
          "number": 956,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "\"Follow what has been sent down unto you by your Sustainer, and follow no masters other than Him. How seldom do you keep this in mind!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 3,
          "number": 957,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "And how many a [rebellious] community have We destroyed, with Our punishment coming upon it by night, or while they were resting at noontide!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 4,
          "number": 958,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "And when Our punishment came upon them, they had nothing to say for themselves, and could only cry, \"Verily, we were wrongdoers!\"",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 5,
          "number": 959,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "Thus, [on Judgment Day] We shall most certainly call to account all those unto whom a [divine] message was sent, and We shall most certainly call to account the message-bearers [themselves];",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 6,
          "number": 960,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "and thereupon We shall most certainly reveal unto them Our knowledge [of their doings]: for never have We been absent [from them].",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 7,
          "number": 961,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "And true will be the weighing on that Day; and those whose weight [of good deeds] is heavy in the balance -it is they, they who shall attain to a happy state;",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 8,
          "number": 962,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "whereas those whose weight is light in the balance - it is they who will have squandered their own selves by their wilful rejection of Our messages.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 9,
          "number": 963,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "YEA, INDEED, [O men,] We have given you a [bountiful] place on earth, and appointed thereon means of livelihood for you: [yet] how seldom are you grateful!",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 10,
          "number": 964,
          "juz": 8,
          "numpage": 151
        },
        {
          "text": "Yea, indeed, We have created you, and then formed you; and then We said unto the angels, \"Prostrate yourselves before Adam!\" - whereupon they [all] prostrated themselves, save Iblis: he was not among those who prostrated themselves.",
          "englishName": "Al-A'raaf",
          "suraNumber": 7,
          "numberInSurah": 11,
          "number": 965,
          "juz": 8,
          "numpage": 151
        }
      ]
    },
    {
      "150": [
        {
          "text": "Do they, perchance, wait for the angels to appear unto them, or for thy Sustainer [Himself] to appear, or for some of thy Sustainer's [final] portents to appear? [But] on the Day when thy Sustainer's [final] portents do appear, believing will be of no avail to any human being who did not believe before, or who, while believing, did no good works. Say: \"Wait, [then, for the Last Day, O unbelievers:] behold, we [believers] are waiting, too!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 158,
          "number": 947,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "VERILY, as for those who have broken the unity of their faith and have become sects - thou hast nothing to do with them. Behold, their case rests with God: and in time He will make them understand what they were doing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 159,
          "number": 948,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "Whoever shall come [before God] with a good deed will gain ten times the like thereof; but whoever shall come with an evil deed will be requited with no more than the like thereof; and none shall be wronged.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 160,
          "number": 949,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "SAY: \"Behold, my Sustainer has guided me onto a straight way through an ever-true faith-the way of Abraham, who turned away from all that is false, and wa,. not of those who ascribe divinity to aught beside Him.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 161,
          "number": 950,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "Say: \"Behold, my prayer, and (all] my acts of worship, and my living and my dying are for God [alone], the Sustainer of all the worlds,",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 162,
          "number": 951,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "in whose divinity none has a share: for thus have I been bidden-and I shall [always] be foremost among those who surrender themselves unto Him.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 163,
          "number": 952,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "Say: \"Am I, then, to seek a sustainer other than God, when He is the Sustainer of all things?\" And whatever [wrong] any human being commits rests upon himself alone; and no bearer of burdens shall be made to bear another's burden. And, in time, unto your Sustainer you all must return: and then He will make you. [truly] understand all that on which you were wont to differ.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 164,
          "number": 953,
          "juz": 8,
          "numpage": 150
        },
        {
          "text": "For, He it is who has made you inherit the earth, and has raised some of you by degrees above others, so that He might try you by means of what He has bestowed upon you. Verily, thy Sustainer is swift in retribution: yet, behold, He is indeed much-forgiving, a dispenser of grace.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 165,
          "number": 954,
          "juz": 8,
          "numpage": 150
        }
      ]
    },
    {
      "149": [
        {
          "text": "and do not touch the substance of an orphan - save to improve it-before he comes of age.\" And [in all your dealings] give full measure and weight, with equity: [however,] We do not burden any human being with more than he is well able to bear; and when you voice an opinion, be just, even though it be [against] one near of kin. And [always] observe your bond with God: this has He enjoined upon you, so that you might keep it in mind.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 152,
          "number": 941,
          "juz": 8,
          "numpage": 149
        },
        {
          "text": "And [know] that this is the way leading straight unto Me: follow it, then, and follow not other ways, lest they cause you to deviate from His way. [All] this has He enjoined upon you, so that you might remain conscious of Him.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 153,
          "number": 942,
          "juz": 8,
          "numpage": 149
        },
        {
          "text": "AND ONCE AGAIN: We vouchsafed the divine writ unto Moses in fulfilment [of Our favour] upon those who persevered in doing good, clearly spelling out everything, and [thus providing] guidance and grace, so that they might have faith in the [final] meeting with their Sustainer.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 154,
          "number": 943,
          "juz": 8,
          "numpage": 149
        },
        {
          "text": "And this, too, is a divine writ which We have bestowed from on high, a blessed one: follow it, then, and be conscious of God, so that you might be graced with His mercy.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 155,
          "number": 944,
          "juz": 8,
          "numpage": 149
        },
        {
          "text": "It has been given to you] lest you say, \"Only unto two groups of people, [both of them] before our time, has a divine writ been bestowed from on high and we were indeed unaware of their teachings\";",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 156,
          "number": 945,
          "juz": 8,
          "numpage": 149
        },
        {
          "text": "or lest you say, \"If a divine writ had been bestowed from on high upon us, we would surely have followed its guidance better than they did.\" And so, a clear evidence of the truth has now come unto you from your Sustainer, and guidance, and grace. Who, then, could be more wicked than he who gives the lie to God's messages, and turns away from them in disdain? We shall requite those who turn away from Our messages in disdain with evil suffering for having thus turned away!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 157,
          "number": 946,
          "juz": 8,
          "numpage": 149
        }
      ]
    },
    {
      "148": [
        {
          "text": "And if they give thee the lie, say: \"Limitless is your Sustainer in His grace; but His punishment shall not be averted from people who are lost in sin.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 147,
          "number": 936,
          "juz": 8,
          "numpage": 148
        },
        {
          "text": "THOSE who are bent on ascribing divinity to aught beside God will say, \"Had God so willed, we would not have ascribed divinity to aught but Him, nor would our forefathers [have done so]; and neither would we have declared as forbidden anything [that He has allowed].\" Even so did those who lived before them give the lie to the truth -until they came to taste Our punishment! Say: \"Have you any [certain] knowledge which you could proffer to us? You follow but [other people's] conjectures, and you yourselves do nothing but guess.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 148,
          "number": 937,
          "juz": 8,
          "numpage": 148
        },
        {
          "text": "Say: \"[Know,] then, that the final evidence [of all truth] rests with God alone; and had He so willed, He would have guided you all aright.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 149,
          "number": 938,
          "juz": 8,
          "numpage": 148
        },
        {
          "text": "Say: \"Bring forward your witnesses who could bear witness that God has forbidden [all] this!\" -and if they bear witness [falsely], do not bear witness with them; and do not follow the errant views of those who have given the lie to Our messages, nor of those who believe not in the life to come, and who regard other powers as their Sustainer's equals!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 150,
          "number": 939,
          "juz": 8,
          "numpage": 148
        },
        {
          "text": "Say: \"Come, let me convey unto you what God has [really] forbidden to you: \"Do not ascribe divinity, in any way, to aught beside Him; and [do not offend against but, rather,] do good unto your parents; and do not kill your children for fear of poverty - [for] it is We who shall provide sustenance for you as well as for them; and do not commit any shameful deeds, be they open or secret; and do not take any human being's life-[the life] which God has declared to be sacred -otherwise than in [the pursuit of] justice: this has He enjoined upon you so that you might use your reason;",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 151,
          "number": 940,
          "juz": 8,
          "numpage": 148
        }
      ]
    },
    {
      "147": [
        {
          "text": "[His followers would have it that, in certain cases, any of these] four kinds of cattle of either sex [is unlawful to man]: either of the two sexes of sheep and of goats. Ask [them]: \"Is it the two males that He has forbidden, or the two females, or that which the wombs of the two females may contain? Tell me what you know in this respect, if what you say is true.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 143,
          "number": 932,
          "juz": 8,
          "numpage": 147
        },
        {
          "text": "And [likewise they declare as unlawful] either of the two sexes of camels and of bovine cattle. Ask [them]: \"Is it the two males that He has forbidden, or the two females, or that which the wombs of the two females may contain? Is it, perchance, that you [yourselves] were witnesses when God enjoined [all] this upon you?\" And who could be more wicked than he who, without any [real] knowledge, attributes his own lying inventions to God, and thus leads people astray'? Behold, God does not grace [such] evildoing folk with His guidance.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 144,
          "number": 933,
          "juz": 8,
          "numpage": 147
        },
        {
          "text": "Say [O Prophet]: \"In all that has been revealed unto me, I do not find anything forbidden to eat, if one wants to eat thereof, unless it be carrion, or blood poured forth, or the flesh of swine-for that, behold, is loathsome-or a sinful offering over which any name other than God's has been invoked. But if one is driven by necessity - neither coveting it nor exceeding his immediate need -then [know that], behold, thy Sustainer is much-forgiving, a dispenser of grace.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 145,
          "number": 934,
          "juz": 8,
          "numpage": 147
        },
        {
          "text": "And [only] unto those who followed the Jewish faith did We forbid all beasts that have claws; and We forbade unto them the fat of both oxen and sheep, excepting that which is in their backs or entrails or that which is within the bone: thus did We requite them for their evildoing-for, behold, We are true to Our word!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 146,
          "number": 935,
          "juz": 8,
          "numpage": 147
        }
      ]
    },
    {
      "146": [
        {
          "text": "And they say, \"Such-and-such cattle and fruits of the field are sacred; none may eat thereof save those whom we will [to do so]\" -so they [falsely] claim; and [they declare that] it is forbidden to burden the backs of certain kinds of cattle; and there are cattle over which they do not pronounce God's name - falsely attributing [the origin of these customs] to Him. [But] He will requite them for all their false imagery.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 138,
          "number": 927,
          "juz": 8,
          "numpage": 146
        },
        {
          "text": "And they say, \"All that is in the wombs of such-and-such cattle is reserved for our males and forbidden to our women; but if it be stillborn, then both may have their share thereof.\" [God] will requite them for all that they [falsely] attribute [to Him]: behold, He is wise, all-knowing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 139,
          "number": 928,
          "juz": 8,
          "numpage": 146
        },
        {
          "text": "Lost, indeed, are they who, in their weak-minded ignorance, slay their children and declare as forbidden that which God has provided for them as sustenance, falsely ascribing [such prohibitions] to God: they have gone astray and have not found the right path.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 140,
          "number": 929,
          "juz": 8,
          "numpage": 146
        },
        {
          "text": "For it is He who has brought into being gardens-[both] the cultivated ones and those growing wild -and the date-palm, and fields bearing multiform produce, and the olive tree, and the pomegranate: [all] resembling one another and yet so different! Eat of their fruit when it comes to fruition, and give [unto the poor] their due on harvest day. And do not waste [God's bounties]: verily, He does not love the wasteful!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 141,
          "number": 930,
          "juz": 8,
          "numpage": 146
        },
        {
          "text": "And of the cattle reared for work and for the sake of their flesh, eat whatever God has provided for you as sustenance, and follow not Satan's foot-steps: behold, he is your open foe!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 142,
          "number": 931,
          "juz": 8,
          "numpage": 146
        }
      ]
    },
    {
      "145": [
        {
          "text": "for all shall be judged according to their [conscious] deeds - and thy Sustainer is not unaware of what they do.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 132,
          "number": 921,
          "juz": 8,
          "numpage": 145
        },
        {
          "text": "And thy Sustainer alone is self-sufficient, limitless in His grace. If He so wills, He may put an end to you and thereafter cause whom He wills to succeed you - even as He has brought you into being out of other people's seed.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 133,
          "number": 922,
          "juz": 8,
          "numpage": 145
        },
        {
          "text": "Verily, that [reckoning] which you are promised is bound to come, and you cannot elude it!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 134,
          "number": 923,
          "juz": 8,
          "numpage": 145
        },
        {
          "text": "Say: \"O my [unbelieving] people! Do yet all that may be within your power, [while] I, behold, shall labour [in God's way]; and in time you will come to know to whom the future belongs. Verily, never will evildoers attain to a happy state!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 135,
          "number": 924,
          "juz": 8,
          "numpage": 145
        },
        {
          "text": "AND OUT OF whatever He has created of the fruits of the field and the cattle, they assign unto God a portion, saying, \"This belongs to God\"-or so they [falsely] claim - \"and this is for those beings who, we are convinced, have a share in God's divinity.\" But that which is assigned to the beings associated in their minds with God does not bring [them] closer to God - whereas that which is assigned to God brings [them but] closer to those beings to whom they ascribe a share in His divinity. Bad, indeed, is their judgment!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 136,
          "number": 925,
          "juz": 8,
          "numpage": 145
        },
        {
          "text": "And, likewise, their belief in beings or powers that are supposed to have a share in God's divinity makes [even] the slaying of their children seem goodly to many of those who ascribe divinity to aught beside God, thus bringing them to ruin and confusing them in their faith. Yet, unless God had so willed, they would not be doing all this: stand, therefore, aloof from them and all their false imagery!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 137,
          "number": 926,
          "juz": 8,
          "numpage": 145
        }
      ]
    },
    {
      "144": [
        {
          "text": "And whomsoever God wills to guide, his bosom He opens wide with willingness towards self-surrender [unto Him]; and whomsoever He wills to let go astray, his bosom He causes to be tight and constricted, as if he were climbing unto the skies: it is thus that God inflicts horror upon those who will not believe.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 125,
          "number": 914,
          "juz": 8,
          "numpage": 144
        },
        {
          "text": "And undeviating is this thy Sustainer's way. Clearly, indeed, have We spelled out these messages unto people who [are willing to] take them to heart!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 126,
          "number": 915,
          "juz": 8,
          "numpage": 144
        },
        {
          "text": "Theirs shall be an abode of peace with their Sustainer; and He shall be near unto them in result of what they have been doing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 127,
          "number": 916,
          "juz": 8,
          "numpage": 144
        },
        {
          "text": "AND ON THE DAY when He shall gather them [all] together, [He will say:] \"O you who have lived in close communion with [evil] invisible beings! A great many [other] human beings have you ensnared! And those of the humans who were close to them will say: \"O our Sustainer! We did enjoy one another's fellowship [in life]; but (now that] we have reached the end of our term-the term which Thou hast laid down for us - (we see the error of our ways]!\" [But] He will say: \"The fire shall be your abode, therein to abide-unless God wills it otherwise.\" Verily, thy Sustainer is wise, all-knowing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 128,
          "number": 917,
          "juz": 8,
          "numpage": 144
        },
        {
          "text": "And in this manner do We cause evildoers to seduce one another by means of their (evil] doings.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 129,
          "number": 918,
          "juz": 8,
          "numpage": 144
        },
        {
          "text": "[And thus will God continue:] \"O you who have lived in close communion with [evil] invisible beings and [like-minded] humans! Have there not come unto you apostles from among yourselves, who conveyed unto you My messages and warned you of the coming of this your Day [of Judgment]?\" They will answer: \"We do bear witness against ourselves!\"-for the life of this world had beguiled them: and so they will bear witness against themselves that they had been denying the truth.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 130,
          "number": 919,
          "juz": 8,
          "numpage": 144
        },
        {
          "text": "And so it is that thy Sustainer would never destroy a community for its wrongdoing so long as its people are still unaware [of the meaning of right and wrong]:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 131,
          "number": 920,
          "juz": 8,
          "numpage": 144
        }
      ]
    },
    {
      "143": [
        {
          "text": "And why should you not eat of that over which God's name has been pronounced, seeing that He has so clearly spelled out to you what He has forbidden you [to eat] unless you are compelled [to do so]? But, behold, [it is precisely in such matters that] many people lead others astray by their own errant views, without [having any real] knowledge. Verily, thy Sustainer is fully aware of those who transgress the bounds of what is right.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 119,
          "number": 908,
          "juz": 8,
          "numpage": 143
        },
        {
          "text": "But abstain from sinning, be it open or secret-for, behold, those who commit sins shall be requited for all that they have earned.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 120,
          "number": 909,
          "juz": 8,
          "numpage": 143
        },
        {
          "text": "Hence, eat not of that over which God's name has not been pronounced: for this would be sinful conduct indeed. And, verily, the evil impulses [within men's hearts] whisper unto those who have made them their own that they should involve you in argument [as to what is and what is not a sin]; and if you pay heed unto them, lo! you will become [like] those who ascribe divinity to other beings or forces beside God.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 121,
          "number": 910,
          "juz": 8,
          "numpage": 143
        },
        {
          "text": "IS THEN -HE who was dead [in spirit] and whom We thereupon gave life, and for whom We set up a light whereby he might see his way among men - [is then he] like one [who is lost] in darkness deep, out of which he cannot emerge? [But] thus it is: goodly seem all their own doings to those who deny the truth.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 122,
          "number": 911,
          "juz": 8,
          "numpage": 143
        },
        {
          "text": "And it is in this way that We cause the great ones in every land to become its [greatest] evildoers, there to weave their schemes: yet it is only against themselves that they scheme -and they perceive it not.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 123,
          "number": 912,
          "juz": 8,
          "numpage": 143
        },
        {
          "text": "And whenever. a [divine] message comes to them, they say, \"We shall not believe unless we are given the like of what God's apostles were given!\" [But] God knows best upon whom to bestow His message. Abasement in the sight of God will befall those who have become guilty of evildoing, and suffering severe for all the schemes which they were wont to weave.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 124,
          "number": 913,
          "juz": 8,
          "numpage": 143
        }
      ]
    },
    {
      "142": [
        {
          "text": "And even if We were to send down angels unto them, and if the dead were to speak unto them and [even if] We were to assemble before them, face to face, all the things [that can prove the truth], they would still not believe unless God so willed But [of this] most of them are entirely unaware.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 111,
          "number": 900,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "AND THUS it is that against every prophet We have set up as enemies the evil forces from among humans as well as from among invisible beings that whisper unto one another glittering half-truths meant to delude the mind But they could not do this unless thy Sustainer had so willed: stand, therefore, aloof from them and from all their false imagery!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 112,
          "number": 901,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "Yet, to the end that the hearts of those who do not believe in the life to come might incline towards Him, and that in Him they might find contentment, and that they might earn whatever they can earn [of merit] -",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 113,
          "number": 902,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "[say thou:] \"Am I, then, to look unto anyone but God for judgment [as to what is right and wrong], when it is He who has bestowed upon you from on high this divine writ, clearly spelling out the truth?\" And those unto whom We have vouchsafed revelation aforetime know that this one, too, has been bestowed from on high, step by step, by thy Sustainer. Be not, then, among the doubters-",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 114,
          "number": 903,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "for, truly and justly has thy Sustainer's promise been fulfilled. There is no power that could alter [the fulfilment of] His promises: and He alone is all-hearing, all-knowing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 115,
          "number": 904,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "Now if thou pay heed unto the majority of those [who live] on earth, they will but lead thee astray from the path of God: they follow but [other people's] conjectures, and they themselves do nothing but guess.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 116,
          "number": 905,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "Verily, thy Sustainer knows best as to who strays from His path, and best knows He as to who are the right-guided.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 117,
          "number": 906,
          "juz": 8,
          "numpage": 142
        },
        {
          "text": "EAT, then, of that over which God's name has been pronounced, if you truly believe in His messages.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 118,
          "number": 907,
          "juz": 8,
          "numpage": 142
        }
      ]
    },
    {
      "141": [
        {
          "text": "Such is God, your Sustainer: there is no deity save Him, the Creator of everything: worship, then, Him alone -for it is He who has everything in His care.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 102,
          "number": 891,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "No human vision can encompass Him, whereas He encompasses all human vision: for He alone is unfathomable, all-aware.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 103,
          "number": 892,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "Means of insight have now come unto you from your Sustainer [through this divine writ]. Whoever, therefore, chooses to see, does so for his own good; and whoever chooses to remain blind, does so to his own hurt. And [say unto the blind of heart]: \"I am not your keeper.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 104,
          "number": 893,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "And thus do We give many facets to Our messages. And to the end that they might say, \"Thou hast taken [all this] well to heart,\" and that We might make it clear unto people of [innate] knowledge,",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 105,
          "number": 894,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "follow thou what has been revealed unto thee by thy Sustainer - save whom there is no deity - and turn thy back upon all who ascribe divinity to aught beside Him.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 106,
          "number": 895,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "Yet if God had so willed, they would not have ascribed divinity to aught beside Him; hence, We have not made thee their keeper, and neither art thou responsible for their conduct.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 107,
          "number": 896,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "But do not revile those [beings] whom they invoke instead of God, lest they revile God out of spite, and in ignorance: for, goodly indeed have We made their own doings appear unto every community In time, [however,] unto their Sustainer they must return: and then He will make them [truly] understand all that they were doing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 108,
          "number": 897,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "Now they swear by God with their most solemn oaths that if a miracle were shown to them, they would indeed believe in this [divine writ]. Say: \"Miracles are in the power of God alone.\" And for all you know, even if one should be shown to them, they would not believe",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 109,
          "number": 898,
          "juz": 7,
          "numpage": 141
        },
        {
          "text": "so long as We keep their hearts and their eyes turned [away from the truth], even as they did not believe in it in the first instance: and [so] We shall leave them in their overweening arrogance, blindly stumbling to and fro.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 110,
          "number": 899,
          "juz": 7,
          "numpage": 141
        }
      ]
    },
    {
      "140": [
        {
          "text": "VERILY, God is the One who cleaves the grain and the fruit-kernel asunder, bringing forth the living out of that which is dead, and He is the One who brings forth the dead out of that which is alive. This, then, is God: and yet, how perverted are your minds!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 95,
          "number": 884,
          "juz": 7,
          "numpage": 140
        },
        {
          "text": "[He is] the One who causes the dawn to break; and He has made the night to be [a source of] stillness, and the sun and the moon to run their appointed courses: [all] this is laid down by the will of the Almighty, the All-Knowing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 96,
          "number": 885,
          "juz": 7,
          "numpage": 140
        },
        {
          "text": "And He it is who has set up for you the stars so that you might be guided by them in the midst of the deep darkness of land and sea: clearly, indeed, have We spelled out these messages unto people of [innate] knowledge!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 97,
          "number": 886,
          "juz": 7,
          "numpage": 140
        },
        {
          "text": "And He it is who has brought you [all] into being out of one living entity, and [has appointed for each of you] a time-limit [on earth] and a resting-place [after death]: clearly, indeed, have We spelled out these messages unto people who can grasp the truth!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 98,
          "number": 887,
          "juz": 7,
          "numpage": 140
        },
        {
          "text": "And He it is who has caused waters to come down from the sky; and by this means have We brought forth all living growth, and out of this have We brought forth verdure. Out of this do We bring forth close-growing grain; and out of the spathe of the palm tree, dates in thick clusters; and gardens of vines, and the olive tree, and the pomegranate: [all] so alike, and yet so different! Behold their fruit when it comes to fruition and ripens! Verily, in all this there are messages indeed for people who will believe!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 99,
          "number": 888,
          "juz": 7,
          "numpage": 140
        },
        {
          "text": "And yet, some [people] have come to attribute to all manner of invisible beings a place side by side with God - although it is He who has created them [all]; and in their ignorance they have invented for Him sons and daughters! Limitless is He is His glory, and sublimely exalted above anything that men may devise by way of definition:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 100,
          "number": 889,
          "juz": 7,
          "numpage": 140
        },
        {
          "text": "the Originator of the heavens and the earth! How could it be that He should have a child without there ever having been a mate for Him - since it is He who has created everything, and He alone knows everything?",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 101,
          "number": 890,
          "juz": 7,
          "numpage": 140
        }
      ]
    },
    {
      "139": [
        {
          "text": "For, no true understanding of God have they when they say, \"Never has God revealed anything unto man.\" Say: \"Who has bestowed from on high the divine writ which Moses brought unto men as a light and a guidance, [and] which you treat as [mere] leaves of paper, making a show of them the while you conceal [so] much - although you have been taught [by it] what neither you nor your forefathers had ever known?\" Say: \"God [has revealed that divine writ]!\" - and then leave them to play at their vain talk.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 91,
          "number": 880,
          "juz": 7,
          "numpage": 139
        },
        {
          "text": "And this, too, is a divine writ which We have bestowed from on high - blessed, confirming the truth of whatever there still remains [of earlier revelations] -and [this] in order that thou mayest warn the foremost of all cities and all who dwell around it. And those who believe in the life to come do believe in this [warning]; and it is they who are ever-mindful of their prayers.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 92,
          "number": 881,
          "juz": 7,
          "numpage": 139
        },
        {
          "text": "And who could be more wicked than he who invents a lie about God, or says, \"This has been revealed unto me,\" the while nothing has been revealed to him? - or he who says, \"I, too, can bestow from on high the like of what God has bestowed\"? If thou couldst but see [how it will be] when these evildoers find themselves in the agonies of death, and the angels stretch forth their hands [and call]: \"Give up your souls! Today you shall be requited with the suffering of humiliation for having attributed to God something that is not true, and for having persistently scorned His messages in your arrogance!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 93,
          "number": 882,
          "juz": 7,
          "numpage": 139
        },
        {
          "text": "[And God shall say:] \"And now, indeed, you have come unto Us in a lonely state, even as We created you in the first instance; and you have left behind you all that We bestowed on you [in your lifetime]. And We do not see with you those intercessors of yours whom you supposed to have a share in God's divinity with regard to yourselves! Indeed, all the bonds between you [and your earthly life] are now severed, and all your former fancies have forsaken you!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 94,
          "number": 883,
          "juz": 7,
          "numpage": 139
        }
      ]
    },
    {
      "138": [
        {
          "text": "Those who have attained to faith, and who have not obscured their faith by wrongdoing-it is they who shall be secure, since it is they who have found the right path!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 82,
          "number": 871,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "And this was Our argument which We vouchsafed unto Abraham against his people: [for] We do raise by degrees whom We will. Verily, thy Sustainer is wise, all-knowing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 83,
          "number": 872,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "And We bestowed upon him Isaac and Jacob; and We guided each of them as We had guided Noah aforetime. And out of his offspring, [We bestowed prophethood upon] David, and Solomon, and Job, and Joseph, and Moses, and Aaron: for thus do We reward the doers of good;",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 84,
          "number": 873,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "and [upon] Zachariah, and John, and Jesus, and Elijah: every one of them was of the righteous;",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 85,
          "number": 874,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "and [upon] Ishmael, and Elisha, and Jonah, and Lot. And every one of them did We favour above other people;",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 86,
          "number": 875,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "and [We exalted likewise] some of their forefathers and-their offspring and their brethren: We elected them [all], and guided them onto a straight way.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 87,
          "number": 876,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "Such is God's guidance: He guides therewith whomever He wills of His servants. And had they ascribed divinity to aught beside Him-in vain, indeed, would have been all [the good] that they ever did:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 88,
          "number": 877,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "[but] it was to them that We vouchsafed revelation, and sound judgment, and prophethood. And now, although the unbelievers may choose to deny these truths, [know that] We have entrusted them to people who will never refuse to acknowledge them-",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 89,
          "number": 878,
          "juz": 7,
          "numpage": 138
        },
        {
          "text": "to those whom God has guided. Follow, then, their guidance, [and] say: \"No reward do I ask of you for this [truth]: behold, it is but an admonition unto all mankind!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 90,
          "number": 879,
          "juz": 7,
          "numpage": 138
        }
      ]
    },
    {
      "137": [
        {
          "text": "AND, LO, [thus] spoke Abraham unto his father Azar: \"Takest thou idols for gods? Verily, I see that thou and thy people have obviously gone astray!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 74,
          "number": 863,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "And thus We gave Abraham [his first] insight into [God's] mighty dominion over the heavens and the earth - and [this] to the end that he might become one of those who are inwardly sure.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 75,
          "number": 864,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "Then, when the night overshadowed him with its darkness, he beheld a star;., [and] he exclaimed, \"This is my Sustainer!\" -but when it went down, he said, \"I love not the things that go down.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 76,
          "number": 865,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "Then, when he beheld the moon rising, he said, \"This is my Sustainer!\"-but when it went down, he said, \"Indeed, if my Sustainer guide me not. I will most certainly become one of the people who go astray!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 77,
          "number": 866,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "Then, when he beheld the sun rising, he said, \"This is my Sustainer! This one is the greatest [of all]!\" - but when it [too] went down, he exclaimed: \"O my people! Behold, far be it from me to ascribe divinity, as you do, to aught beside God!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 78,
          "number": 867,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "Behold, unto Him who brought into being the heavens and the earth have I turned my face, having turned away from all that is false; and I am not of those who ascribe divinity to aught beside Him.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 79,
          "number": 868,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "And his people argued with him. He said: \"Do you argue with me about God, when it is He who has guided me? But I do not fear anything to which you ascribe divinity side by side with Him, [for no evil can befall me] unless my Sustainer so wills. All things does my Sustainer embrace within His knowledge; will you not, then, keep this in mind?",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 80,
          "number": 869,
          "juz": 7,
          "numpage": 137
        },
        {
          "text": "And why should I fear anything that you worship side by side with Him, seeing that you are not afraid of ascribing divinity to other powers beside God without His ever having bestowed upon you from on high any warrant therefor? [Tell me,] then, which of the two parties has a better right to feel secure -if you happen to know [the answer]?",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 81,
          "number": 870,
          "juz": 7,
          "numpage": 137
        }
      ]
    },
    {
      "136": [
        {
          "text": "for whom those who are conscious of God are in no wise accountable. Theirs, however, is the duty to admonish [the sinners], so that they might become conscious of God.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 69,
          "number": 858,
          "juz": 7,
          "numpage": 136
        },
        {
          "text": "And leave to themselves all those who, beguiled by the life of this world, have made play and passing delights their religion; but remind [them] herewith that [in the life to come] every human being shall be held in pledge for whatever wrong he has done, and shall have none to protect him from God, and none to intercede for him; and though he offer any conceivable ransom, it shall not be accepted from him. It is [people such as] these that shall be held in pledge for the wrong they have done; for them there is [in the life to come] a draught of burning despair and grievous suffering awaits them because of their persistent refusal to acknowledge the truth.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 70,
          "number": 859,
          "juz": 7,
          "numpage": 136
        },
        {
          "text": "SAY: \"Shall we invoke, instead of God, something that can neither benefit us nor harm us, and [thus] turn around on our heels after God has guided us aright? -like one whom the satans have enticed into blundering after earthly lusts, the while his companions, trying to guide him, call out unto him [from afar], `Come thou to us!\"' Say: \"Verily, God's guidance is the only guidance: and so we have been bidden to surrender ourselves unto the Sustainer of all the worlds,",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 71,
          "number": 860,
          "juz": 7,
          "numpage": 136
        },
        {
          "text": "and to be constant in prayer and conscious of Him: for it is He unto whom you all shall be gathered.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 72,
          "number": 861,
          "juz": 7,
          "numpage": 136
        },
        {
          "text": "And He it is who has created the heavens and the earth in accordance with [an inner] truth - and whenever He says, \"Be,\" His word comes true; and His will be the dominion on the Day when the trumpet [of resurrection] is blown. He knows all that is beyond the reach of a created being's perception, as well as all that can be witnessed by a creature's senses or mind: for He alone is truly wise, all-aware.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 73,
          "number": 862,
          "juz": 7,
          "numpage": 136
        }
      ]
    },
    {
      "135": [
        {
          "text": "And He it is who causes you to be [like] dead at night, and knows what you work in daytime; and He brings you back to life each days in order that a term set [by Him] be fulfilled. In the end, unto Him you must return: and then He will make you understand all that you were doing [in life].",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 60,
          "number": 849,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "And He alone holds sway over His servants. And He sends forth heavenly forces to watch over you until, when death approaches any of you, Our messengers cause him to die: and they do not overlook [anyone].",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 61,
          "number": 850,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "And they [who have died] are thereupon brought before God, their true Lord Supreme. Oh, verily, His alone is all judgment: and He is the swiftest of all reckoners!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 62,
          "number": 851,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "Say: \"Who is it that saves you from the dark dangers of land and sea [when] you call unto Him humbly, and in the secrecy of your hearts, `If He will but save us from this [distress], we shall most certainly be among the grateful'?\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 63,
          "number": 852,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "Say: \"God [alone] can save you from this and from every distress - and still you ascribe divinity to other powers beside Him!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 64,
          "number": 853,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "Say: \"It is He alone who has the power to let loose upon you suffering from above you or from beneath your feet, or to confound you with mutual discord and let you taste the fear of one another.\" Behold how many facets we give to these messages, so that they might understand the truth.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 65,
          "number": 854,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "and yet, to all this thy peoples have given the lie, although it is the truth. Say [then]: \"I am not responsible for your conduct.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 66,
          "number": 855,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "Every tiding [from God] has a term set for its fulfilment: and in time you will come to know [the truth].\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 67,
          "number": 856,
          "juz": 7,
          "numpage": 135
        },
        {
          "text": "NOW, whenever thou meet such as indulge in [blasphemous] talk about Our messages, turn thy back upon them until they begin to talk of other things and if Satan should ever cause thee to forget [thyself], remain not, after recollection, in the company of such evildoing folk,",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 68,
          "number": 857,
          "juz": 7,
          "numpage": 135
        }
      ]
    },
    {
      "134": [
        {
          "text": "For it is in this way that We try men through one another - to the end that they might ask, \"Has God, then, bestowed His favour upon those others in preference to us?\" Does not God know best as to who is grateful [to Him]?",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 53,
          "number": 842,
          "juz": 7,
          "numpage": 134
        },
        {
          "text": "And when those who believe in Our messages come unto thee, say: \"Peace be upon you. Your Sustainer has willed upon Himself the law of grace and mercy - so that if any of you does a bad deed out of ignorance, and thereafter repents and lives righteously, He shall be [found] much-forgiving, a dispenser of grace.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 54,
          "number": 843,
          "juz": 7,
          "numpage": 134
        },
        {
          "text": "And thus clearly do We spell out Our messages: and [We do it] so that the path of those who are lost in sin might be distinct [from that of the righteous].",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 55,
          "number": 844,
          "juz": 7,
          "numpage": 134
        },
        {
          "text": "SAY [to the deniers of the truth]: \"Behold, I have been forbidden to worship those [beings] whom you invoke instead of God.\" Say: \"I do not follow your errant views -or else I should have gone astray, and should not be among those who have found the right path.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 56,
          "number": 845,
          "juz": 7,
          "numpage": 134
        },
        {
          "text": "Say: \"Behold, I take my stand on a clear evidence from my Sustainer-and [so] it is to Him that you are giving the lie! Not in my power is that which [in your ignorance] you so hastily demand: judgment rests with none but God. He shall declare the truth, since it is He who is the best judge between truth and falsehood.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 57,
          "number": 846,
          "juz": 7,
          "numpage": 134
        },
        {
          "text": "Say: \"If that which you so hastily demand were in my power, everything would indeed have been decided between me and you. But God knows best as to who is doing wrong.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 58,
          "number": 847,
          "juz": 7,
          "numpage": 134
        },
        {
          "text": "For, with Him are the keys to the things that are beyond the reach of a created being's perception: none knows them but He. And He knows all that is on land and in the sea; and not a leaf falls but He knows it; and neither is there a grain in the earth's deep darkness, nor anything: living or dead, but is recorded in [His] clear decree.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 59,
          "number": 848,
          "juz": 7,
          "numpage": 134
        }
      ]
    },
    {
      "133": [
        {
          "text": "and [in the end,] the last remnant of those folk who had been bent on evildoing was wiped out. For all praise is due to God, the Sustainer of all the worlds.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 45,
          "number": 834,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "Say: \"What do you think? If God should take away your hearing and your sight and seal your hearts - what deity but God is there that could bring it all back to you?\" Behold how many facets we give to our messages-and yet they turn away in disdain!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 46,
          "number": 835,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "Say: \"Can you imagine what your condition will be if God's chastisement befalls you, either suddenly or in a [gradually] perceptible manner? [But then-] will any but evildoing folk [ever] be destroyed?",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 47,
          "number": 836,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "And We send [Our] message-bearers only as heralds of glad tidings and as warners: hence, all who believe and live righteously -no fear need they have, and neither shall they grieve;",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 48,
          "number": 837,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "whereas those who give the lie to Our messages - suffering will afflict them in result of all their sinful doings.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 49,
          "number": 838,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "Say [O Prophet]: \"I do not say unto you, 'God's treasures are with me,'; nor [do I say], 'I know the things that are beyond the reach of human perception'; nor do I say unto you, 'Behold, I am an angel': I but follow what is revealed to me.\" Say: \"Can the blind and the seeing be deemed equal? Will you not, then, take thought?\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 50,
          "number": 839,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "And warn hereby those who fear lest they be gathered unto their Sustainer with none to protect them from Him or to intercede with Him, so that they might become [fully] conscious of Him.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 51,
          "number": 840,
          "juz": 7,
          "numpage": 133
        },
        {
          "text": "Hence, repulse not [any of] those who at morn and evening invoke their Sustainer, seeking His countenance. Thou art in no wise accountable for them-just as they are in no wise accountable for thee -and thou hast therefore no right to repulse them: for then thou wouldst be among the evildoers.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 52,
          "number": 841,
          "juz": 7,
          "numpage": 133
        }
      ]
    },
    {
      "132": [
        {
          "text": "Only they who listen [with their hearts] can respond to a call; and as for the dead [of heart], God [alone] can raise them from the dead, whereupon unto Him they shall return.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 36,
          "number": 825,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "And they say, \"Why has no miraculous sign been bestowed on him from on high by his Sustainer?\" Say: \"Behold, God has the power to bestow any sign from on high.\" Yet most human beings are unaware of this",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 37,
          "number": 826,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "although there is no beast that walks on earth and no bird that flies on its two wings which is not [God's] creature like yourselves: no single thing have We neglected in Our decree. And once again: Unto their Sustainer shall they [all] be gathered.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 38,
          "number": 827,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "And they who give the lie to Our messages are deaf and dumb, in darkness deep. Whomever God wills, He lets go astray; and whomever He wills, He places upon a straight way.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 39,
          "number": 828,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "Say: \"Can you see yourselves invoking any but God when God's chastisement befalls you [in this world], or the Last Hour comes upon you? [Tell me this,] if you are men of truth!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 40,
          "number": 829,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "Nay, but it is Him alone that you will invoke - whereupon He may, if He so wills, remove that [ill] which caused you to call unto Him; and you will have forgotten all that. to which you [now] ascribe divinity side by side with Him.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 41,
          "number": 830,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "And, indeed, We sent Our messages unto people before thy time, [O Prophet,] and visited them with misfortune and hardship so that they might humble themselves:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 42,
          "number": 831,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "yet when the misfortune decreed by Us befell them, they did not humble themselves, but rather their hearts grew hard, for Satan had made all their doings seem goodly to them.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 43,
          "number": 832,
          "juz": 7,
          "numpage": 132
        },
        {
          "text": "Then, when they had forgotten all that they had been told to take to heart, We threw open to them the gates of all [good] things until -even as they were rejoicing in what they had been granted - We suddenly took them to task: and lo! they were broken in spirit;",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 44,
          "number": 833,
          "juz": 7,
          "numpage": 132
        }
      ]
    },
    {
      "131": [
        {
          "text": "But nay -[they will say this only because] the truth which they used to conceal [from themselves] in the past will have become obvious to them; and if they were brought back [to life], they would return to the very thing which was forbidden to them: for behold, they are indeed liars!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 28,
          "number": 817,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "And some [of the unbelievers] say, \"There is nothing beyond our life in this world, for We shall not be raised from the dead.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 29,
          "number": 818,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "If thou couldst but see [them] when they shall be made to stand before their Sustainer [and] He will say, \"Is not this the truth?\" They will answer: \"Yea, indeed, by our Sustainer!\" [Whereupon] He will say: \"Taste, then, the suffering that comes from your having refused to acknowledge the truth!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 30,
          "number": 819,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "Lost indeed are they who consider it a lie that they will have to meet God -till the Last Hour suddenly comes upon them, [and] they cry, \"Alas for us, that we disregarded it!\" - for they shall bear on their backs the burden of their sins: oh, how evil the load with which they shall be burdened!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 31,
          "number": 820,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "And nothing is the life of this world but a play and a passing delight; and the life in the hereafter is by far the better for all who are conscious of God. Will you not, then, use your reason?",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 32,
          "number": 821,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "Well do We know that what such people say grieves thee indeed: yet, behold, it is not thee to whom they give the lie, but God's messages do these evildoers deny.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 33,
          "number": 822,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "And, indeed, [even] before thy time have apostles been given the lie, and they endured with patience all those charges of falsehood, and all the hurt done to them, till succour came unto them from Us: for there is no power that could alter [the outcome of] God's promises. And some of the histories of those apostles have already come within thy ken.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 34,
          "number": 823,
          "juz": 7,
          "numpage": 131
        },
        {
          "text": "And if it distress thee that those who deny the truth turn their backs on thee - why, then, if thou art able to go down deep into the earth or to ascend a ladder unto heaven in order to bring them a [yet more convincing] message, [do so;] but [remember that] had God so willed, He would indeed have gathered them all unto [His] guidance. Do not, therefore, allow thyself to ignore [God's ways].",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 35,
          "number": 824,
          "juz": 7,
          "numpage": 131
        }
      ]
    },
    {
      "130": [
        {
          "text": "Say: \"What could most weightily bear witness to the truth?\" Say: \"God is witness between me and you; and this Qur’an has been revealed unto me so that on the strength thereof I might warn you and all whom it may reach.\" Could you in truth bear witness that there are other deities side by side with God? Say: \"I bear no [such] witness!\" Say: \"He is the One God; and, behold, far be it from me to ascribe divinity, as, you do, to aught beside Him!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 19,
          "number": 808,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "They unto whom we have vouchsafed revelation aforetime know this as they know their own children; yet those [of them] who have squandered their own selves -it is they who refuse to believe.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 20,
          "number": 809,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "And who could be more wicked than he who attributes his own lying inventions to God or gives the lie to His messages? Verily, such evildoers will never attain to a happy state:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 21,
          "number": 810,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "for one Day We shall gather them all together, and then We shall say unto those who ascribed divinity to aught beside God: \"Where, now, are those beings whom you imagined to have a share in God's divinity?\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 22,
          "number": 811,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "Whereupon, in their utter confusion, they will only [be able to] say: \"By God, our Sustainer, we did not [mean to] ascribe divinity to aught beside Him!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 23,
          "number": 812,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "Behold how they have lied to themselves -and [how] their false imagery has forsaken them!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 24,
          "number": 813,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "And there are among them such as [seem to] listen to thee [O Prophet]: but over their hearts We have laid veils which prevent them from grasping the truth, and into their ears, deafness. And were they to see every sign [of the truth], they would still not believe in it-so much so that when they come unto thee to contend with thee, those who are bent on denying the truth say, \"This is nothing but fables of ancient times!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 25,
          "number": 814,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "And they bar others therefrom. and go far away from it: but they destroy none but themselves, and perceive it not.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 26,
          "number": 815,
          "juz": 7,
          "numpage": 130
        },
        {
          "text": "If thou couldst but see [them] when they will be made to stand before the fire and will say, \"Oh, would that we were brought back [to life]: then we would not give the lie to our Sustainer's messages, but would be among the believers!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 27,
          "number": 816,
          "juz": 7,
          "numpage": 130
        }
      ]
    },
    {
      "129": [
        {
          "text": "And (even] if We had appointed an angel as Our message-bearer, We would certainly have made him [appear as] a man - and thus We would only have confused them in the same way as they are now confusing themselves.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 9,
          "number": 798,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "And, indeed, [even] before thy time have apostles been derided - but those who scoffed at them were [in the end] overwhelmed by the very thing which they were wont to deride.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 10,
          "number": 799,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "Say: \"Go all over the earth, and behold what happened in the end to those who gave the lie to the truth!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 11,
          "number": 800,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "Say: \"Unto whom belongs all that is in the heavens and on earth?\" Say: \"Unto God, who has willed upon Himself the law of grace and mercy. He will assuredly gather you all together on the Day of Resurrection, [the coming of] which is beyond all doubt: yet those who have squandered their own selves-it is they who refuse to believe [in Him],",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 12,
          "number": 801,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "although His is all that dwells in the night and the day, and He alone is all-hearing, all-knowing.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 13,
          "number": 802,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "Say: \"Am I to take for my master anyone but God, the Originator of the heavens and the earth, when it is He who gives nourishment and Himself needs none?” Say: \"I am bidden to be foremost among those who surrender themselves unto God, and not to be among those who ascribe divinity to aught beside Him.\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 14,
          "number": 803,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "Say: \"Behold, I would dread, were I [thus] to rebel against my Sustainer, the suffering [which would befall me] on that awesome Day [of Judgment].\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 15,
          "number": 804,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "Upon him who shall be spared on that Day, He will indeed have bestowed His grace: and this will be a manifest triumph.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 16,
          "number": 805,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "And if God should touch thee with misfortune, there is none who could remove it but He; and if He should touch thee with good fortune -it is He who has the power to will anything:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 17,
          "number": 806,
          "juz": 7,
          "numpage": 129
        },
        {
          "text": "for He alone holds sway over His creatures, and He alone is truly wise, all-aware.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 18,
          "number": 807,
          "juz": 7,
          "numpage": 129
        }
      ]
    },
    {
      "128": [
        {
          "text": "ALL PRAISE is due to God, who has created the heavens and the earth, and brought into being deep darkness as well as light: and yet, those who are bent on denying the truth regard other powers as their Sustainer's equals!",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 1,
          "number": 790,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "He it is who has created you out of clay, and then has decreed a term [for you] - a term known [only] to him. And yet you doubt –",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 2,
          "number": 791,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "although He is God in the heavens and on earth, knowing all that you keep secret as well as all that you do openly, and knowing what you deserve.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 3,
          "number": 792,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "Yet whenever any of their Sustainer's messages comes unto them, they [who are bent on denying the truth] turn their backs upon it:",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 4,
          "number": 793,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "and so they give the lie to this truth now that it has come unto them. In time, however, they will come to understand what it was that they were wont to deride.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 5,
          "number": 794,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "Do they not see how many a generation We have destroyed before their time - [people] whom We had given a [bountiful] place on earth, the like of which We never gave unto you, and upon whom We showered heavenly blessings abundant, and at whose feet We made running waters flow? And yet we destroyed them for their sins, and gave rise to other people in their stead.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 6,
          "number": 795,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "But even if we had sent down unto thee [O Prophet] a writing on paper, and they had touched it with their own hands - those who are bent on denying the truth would indeed have said, \"This is clearly nothing but a deception!\"",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 7,
          "number": 796,
          "juz": 7,
          "numpage": 128
        },
        {
          "text": "They are saying, too, \"Why has not an angel (visibly] been sent down unto him?\" But had we sent down an angel, all would indeed have been decided, and they would have been allowed no further respite [for repentance.",
          "englishName": "Al-An'aam",
          "suraNumber": 6,
          "numberInSurah": 8,
          "number": 797,
          "juz": 7,
          "numpage": 128
        }
      ]
    },
    {
      "127": [
        {
          "text": "Said Jesus, the son of Mary: \"O God, our Sustainer! Send down upon us a repast from heaven: it shall be an ever-recurring feast for us - for the first and the last of us -and a sign from Thee. And provide us our sustenance, for Thou art the best of providers!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 114,
          "number": 783,
          "juz": 7,
          "numpage": 127
        },
        {
          "text": "God answered: \"Verily, I [always] do send it down unto you: and so, if any of you should henceforth deny [this] truth, on him, behold, will I inflict suffering the like of which I have never [yet] inflicted upon anyone in the world!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 115,
          "number": 784,
          "juz": 7,
          "numpage": 127
        },
        {
          "text": "AND LO! God said: O Jesus, son of Mary! Didst thou say unto men, `Worship me and my mother as deities beside God'?\" [Jesus] answered: \"Limitless art Thou in Thy glory! It would not have been possible for me to say what I had no right to [say]! Had I said this, Thou wouldst indeed have known it! Thou knowest all that is within myself, whereas I know not what is in Thy Self. Verily, it is Thou alone who fully knowest all the things that are beyond the reach of a created being's perception.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 116,
          "number": 785,
          "juz": 7,
          "numpage": 127
        },
        {
          "text": "Nothing did I tell them beyond what Thou didst bid me [to say]: `Worship God, [who is] my Sustainer as well as your Sustainer.' And I bore witness to what they did as long as I dwelt in their midst; but since Thou hast caused me to die, Thou alone hast been their keeper: for Thou art witness unto everything.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 117,
          "number": 786,
          "juz": 7,
          "numpage": 127
        },
        {
          "text": "If thou cause them to suffer - verily, they are Thy servants; and if Thou forgive them - verily, Thou alone art almighty, truly wise!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 118,
          "number": 787,
          "juz": 7,
          "numpage": 127
        },
        {
          "text": "[AND on Judgment Day] God will say: \"Today, their truthfulness shall benefit all who have been true to their word: theirs shall be gardens through which running waters flow, therein to abide beyond the count of time; well-pleased is God with them, and well-pleased are they with Him: this is the triumph supreme.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 119,
          "number": 788,
          "juz": 7,
          "numpage": 127
        },
        {
          "text": "God's is the dominion over the heavens and the earth and all that they contain; and He has the power to will anything.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 120,
          "number": 789,
          "juz": 7,
          "numpage": 127
        }
      ]
    },
    {
      "126": [
        {
          "text": "ON THE DAY when God shall assemble all the apostles and shall ask, \"What response did you receive?\" -they will answer, \"We have no knowledge; verily, it is Thou alone who fully knowest all the things that are beyond the reach of a created being's perception.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 109,
          "number": 778,
          "juz": 7,
          "numpage": 126
        },
        {
          "text": "Lo! God will say: \"O Jesus, son of Mary! Remember the blessings which I bestowed upon thee and thy mother-how I strengthened thee with holy inspiration, so that thou couldst speak unto men in thy cradle, and as a grown man; and how I imparted unto thee revelation and wisdom, including the Torah and the Gospel; and how by My leave thou didst create out of clay, as it were, the shape of [thy followers'] destiny, and then didst breathe into it, so that it might become, by My leave, [their] destiny; and how thou didst heal the blind and the leper by My leave, and how thou didst raise the dead by My leave; and how I prevented the children of Israel from harming thee when thou camest unto them with all evidence of the truth, and [when] those of them who were bent on denying the truth were saying, `This is clearly nothing but deception!\"'",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 110,
          "number": 779,
          "juz": 7,
          "numpage": 126
        },
        {
          "text": "AND [remember the time] when I inspired the white-garbed ones: Believe in Me and in My Apostle!\" They answered: \"We believe; and bear Thou witness that we have surrendered ourselves [unto Thee].\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 111,
          "number": 780,
          "juz": 7,
          "numpage": 126
        },
        {
          "text": "[And,] lo, the white-garbed ones said: \"O Jesus, son of Mary! Could thy Sustainer send down unto us a repast from heaven?\" [Jesus] answered: \"Be conscious of God, if you are [truly] believers!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 112,
          "number": 781,
          "juz": 7,
          "numpage": 126
        },
        {
          "text": "Said they: \"We desire to partake thereof, so that our hearts might be set fully at rest, and that we might know that thou hast spoken the truth to us, and that we might be of those who bear witness thereto!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 113,
          "number": 782,
          "juz": 7,
          "numpage": 126
        }
      ]
    },
    {
      "125": [
        {
          "text": "for when they are told, \"Come unto that which God has bestowed from on high, and unto the Apostle\" - they answer, \"Enough for us is that which we found our forefathers believing in and doing.\" Why, even though their forefathers knew nothing, and were devoid of all guidance?",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 104,
          "number": 773,
          "juz": 7,
          "numpage": 125
        },
        {
          "text": "O you who have attained to faith! It is [but] for your own selves that you are responsible: those who go astray can do you no harm if you [yourselves] are on the right path. Unto God you all must return: and then He will make you [truly] understand all that you were doing [in life].",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 105,
          "number": 774,
          "juz": 7,
          "numpage": 125
        },
        {
          "text": "O YOU who have attained to faith! Let there be witnesses to what you do when death approaches you and you are about to make bequests: two persons of probity from among your own people, or -if the pangs of death come upon you while you are travelling far from home -two other persons from [among people] other than your own. Take hold of the two after having prayed; and if you have any doubt in your mind, let each of them swear by God, \"We shall not sell this [our word] for any price, even though it were [for the sake of] a near kinsman; and neither shall we conceal aught of what we have witnessed before God -or else, may we indeed be counted among the sinful.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 106,
          "number": 775,
          "juz": 7,
          "numpage": 125
        },
        {
          "text": "But if afterwards it should come to light that the two [witnesses] have become guilty of [this very] sin, then two others - from among those whom the two former have deprived of their right - shall take their place and shall swear by God, \"Our testimony is indeed truer than the testimony of these two, and we have not transgressed the bounds of what is right - or else, may we indeed be counted among the evildoers!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 107,
          "number": 776,
          "juz": 7,
          "numpage": 125
        },
        {
          "text": "Thus it will be more likely that people will offer testimony in accordance with the truth-or else they will [have cause to] fear that their oaths will be refuted by the oaths of others. Be, then, conscious of God, and hearken [unto Him]: for God does not bestow His guidance upon iniquitous folk.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 108,
          "number": 777,
          "juz": 7,
          "numpage": 125
        }
      ]
    },
    {
      "124": [
        {
          "text": "Lawful to you is all water-game, and what the sea brings forth, as a provision for you [who are settled] as well as for travellers, although you are forbidden to hunt on land while you are in the state of pilgrimage. And be conscious of God, unto whom you shall be gathered.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 96,
          "number": 765,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "God has laid down that the Ka'bah, the Inviolable Temple, shall be a symbol for all mankind; and [so, too] the sacred month [of pilgrimage] and the garlanded offerings [are symbols] meant to make you aware that God is aware of all that is in the heavens and all that is on earth, and that God has full knowledge of everything.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 97,
          "number": 766,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "Know that God is severe in retribution -and that God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 98,
          "number": 767,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "No more is the Apostle bound to do than deliver the message [entrusted to him]: and God knows all that you do openly, and all that you would conceal.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 99,
          "number": 768,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "Say: \"There is no comparison between the bad things and the good things, even though very many of the bad things may please thee greatly. Be, then, conscious of God, O you who are endowed with insight, so that you might attain to a happy state!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 100,
          "number": 769,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "O YOU who have attained to faith! Do not ask about matters which, if they were to be made manifest to you [in terms of law], might cause you hardship; for, if you should ask about them while the Qur'an is being revealed, they might [indeed] be made manifest to you [as laws]. God has absolved [you from any obligation] in this respect: for God is much-forgiving, forbearing.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 101,
          "number": 770,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "People before your time have indeed asked such questions-and in result thereof have come to deny the truth.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 102,
          "number": 771,
          "juz": 7,
          "numpage": 124
        },
        {
          "text": "IT IS NOT of God's ordaining that certain kinds of cattle should be marked out by superstition and set aside from the use of man; yet those who are bent on denying the truth attribute their own lying inventions to God. And most of them never use their reason:",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 103,
          "number": 772,
          "juz": 7,
          "numpage": 124
        }
      ]
    },
    {
      "123": [
        {
          "text": "O YOU who have attained to faith! Intoxicants, and games of chance, and idolatrous practices, and the divining of the future are but a loathsome evil of Satan's doing:' shun it, then, so that you might attain to a happy state!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 90,
          "number": 759,
          "juz": 7,
          "numpage": 123
        },
        {
          "text": "By means of intoxicants and games of chance Satan seeks only to sow enmity and hatred among you, and to turn you away from the remembrance of God and from prayer. Will you not, then, desist?'",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 91,
          "number": 760,
          "juz": 7,
          "numpage": 123
        },
        {
          "text": "Hence, pay heed unto God, and pay heed unto the Apostle, and be ever on your guard [against evil]; and if you turn away, then know that Our Apostle's only duty is a clear delivery of the message [entrusted to him].",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 92,
          "number": 761,
          "juz": 7,
          "numpage": 123
        },
        {
          "text": "Those who have attained to faith and do righteous deeds incur no sin by partaking of whatever they may, so long as they are conscious of God and [truly] believe and do righteous deeds, and continue to be conscious of God and to believe, and grow ever more conscious of God, and persevere in doing good: for God loves the doers of good.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 93,
          "number": 762,
          "juz": 7,
          "numpage": 123
        },
        {
          "text": "O YOU who have attained to faith! Most certainly God will try you by means of the game which may come within the reach of your hands and your weapons [while you are on pilgrimage], so that God might mark out those who fear Him although He is beyond the reach of human perception. And as for him who, after all this, transgresses the bounds of what is right-grievous suffering awaits him!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 94,
          "number": 763,
          "juz": 7,
          "numpage": 123
        },
        {
          "text": "O you who have attained to faith! Kill no game while you are in the state of pilgrimage. And whoever of you kills it intentionally, [shall make] amends in cattle equivalent to what he has killed - with two persons of probity giving their judgment thereon- to be brought as an offering to the Ka`bah; or else he may atone for his sin by feeding the needy, or by the equivalent thereof in fasting: [this,] in order that. he taste the full gravity of his deed, [while] God shall have effaced the past. But whoever does it again, God will inflict His retribution on him: for God is almighty, an avenger of evil.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 95,
          "number": 764,
          "juz": 7,
          "numpage": 123
        }
      ]
    },
    {
      "122": [
        {
          "text": "For, when they come to understand what has been bestowed from on high upon this Apostle, thou canst see their eyes overflow with tears, because they recognize something of its truth; [and] they say: \"O our Sustainer! We do believe; make us one, then, with all who bear witness to the truth.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 83,
          "number": 752,
          "juz": 7,
          "numpage": 122
        },
        {
          "text": "And how could we fail to believe in God and in whatever truth has come unto us, when we so fervently desire that our Sustainer count us among the righteous?\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 84,
          "number": 753,
          "juz": 7,
          "numpage": 122
        },
        {
          "text": "And for this their belief God will reward them with gardens through which running waters flow, therein to abide: for such is the requital of the doers of good;",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 85,
          "number": 754,
          "juz": 7,
          "numpage": 122
        },
        {
          "text": "whereas they who are bent on denying the truth and giving the lie to Our messages - they are destined for the blazing fire.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 86,
          "number": 755,
          "juz": 7,
          "numpage": 122
        },
        {
          "text": "O YOU who have attained to faith! Do not deprive yourselves of the good things of life which God has made lawful to you, but do not transgress the bounds of what is right: verily, God does not love those who transgress the bounds of what is right.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 87,
          "number": 756,
          "juz": 7,
          "numpage": 122
        },
        {
          "text": "Thus, partake of the lawful, good things which God grants you as sustenance, and be conscious of God, in whom you believe.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 88,
          "number": 757,
          "juz": 7,
          "numpage": 122
        },
        {
          "text": "GOD will not take you to task for oaths which you may have uttered without thought, but He will take you to task for oaths which you have sworn in earnest. Thus, the breaking of an oath must be atoned for by feeding ten needy persons with more or less the same food as you are wont to give to your own families, or by clothing them, or by freeing a human being from bondage; and he who has not the wherewithal shall fast for three days [instead]. This shall be the atonement for your oaths whenever you have sworn [and broken them]. But be mindful of your oaths!' Thus God makes clear unto you His messages, so that you might have cause to be grateful.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 89,
          "number": 758,
          "juz": 7,
          "numpage": 122
        }
      ]
    },
    {
      "121": [
        {
          "text": "Say: \"O followers of the Gospel! Do not overstep the bounds [of truth] in your religious beliefs; and do not follow the errant views of people who have gone astray aforetime, and have led many [others] astray, and are still straying from the right path.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 77,
          "number": 746,
          "juz": 6,
          "numpage": 121
        },
        {
          "text": "THOSE of the children of Israel who were bent on denying the truth have [already] been cursed by the tongue of David and of Jesus, the son of Mary? this, because they rebelled [against God] and persisted in transgressing the bounds of what is right.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 78,
          "number": 747,
          "juz": 6,
          "numpage": 121
        },
        {
          "text": "They would not prevent one another from doing whatever hateful things they did: vile indeed was what they were wont to do!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 79,
          "number": 748,
          "juz": 6,
          "numpage": 121
        },
        {
          "text": "[And now] thou canst see many of them allying themselves with those who are bent on denying the truth! [So] vile indeed is what their passions make them do that God has condemned them; and in suffering shall they abide.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 80,
          "number": 749,
          "juz": 6,
          "numpage": 121
        },
        {
          "text": "For, if they [truly] believed in God and their Prophet and all that was bestowed upon him from on high, they would not take those [deniers of the truth] for their allies: but most of them are iniquitous.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 81,
          "number": 750,
          "juz": 6,
          "numpage": 121
        },
        {
          "text": "Thou wilt surely find that, of all people, the most hostile to those who believe [in this divine writ] are the Jews as well as those who are bent on ascribing divinity to aught beside God; and thou wilt surely find that, of all people, they who say, \"Behold, we are Christians,\" come closest to feeling affection for those who believe [in this divine writ]: this is so because there are priests and monks among them, and because these are not given to arrogance.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 82,
          "number": 751,
          "juz": 7,
          "numpage": 121
        }
      ]
    },
    {
      "120": [
        {
          "text": "thinking that no harm would befall them; and so they became blind and deaf [of heart]. Thereafter God accepted their repentance: and again many of them became blind and deaf. But God sees all that they do.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 71,
          "number": 740,
          "juz": 6,
          "numpage": 120
        },
        {
          "text": "Indeed, the truth deny they who say, \"Behold, God is the Christ, son of Mary\" - seeing that the Christ [himself] said, \"O children of Israel! Worship God [alone], who is my Sustainer as well as your Sustainer.\" Behold, whoever ascribes divinity to any being beside God, unto him will God deny paradise, and his goal shall be the fire: and such evildoers will have none to succour them!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 72,
          "number": 741,
          "juz": 6,
          "numpage": 120
        },
        {
          "text": "Indeed, the truth deny they who say, \"Behold, God is the third of a trinity\" - seeing that there is no deity whatever save the One God. And unless they desist from this their assertion, grievous suffering is bound to befall such of them as are bent on denying the truth.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 73,
          "number": 742,
          "juz": 6,
          "numpage": 120
        },
        {
          "text": "Will they not, then, turn towards God in repentance, and ask His forgiveness? For God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 74,
          "number": 743,
          "juz": 6,
          "numpage": 120
        },
        {
          "text": "The Christ, son of Mary, was but an apostle: all [other] apostles had passed away before him; and his mother was one who never deviated from the truth; and they both ate food [like other mortals]. Behold how clear We make these messages unto them: and then behold how perverted are their minds!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 75,
          "number": 744,
          "juz": 6,
          "numpage": 120
        },
        {
          "text": "Say: \"Would you worship, beside God, aught that has no power either to harm or to benefit you-when God alone is all-hearing, all-knowing?\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 76,
          "number": 745,
          "juz": 6,
          "numpage": 120
        }
      ]
    },
    {
      "119": [
        {
          "text": "If the followers of the Bible would but attain to [true] faith and God-consciousness, We should indeed efface their [previous] bad deeds, and indeed bring them into gardens of bliss;",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 65,
          "number": 734,
          "juz": 6,
          "numpage": 119
        },
        {
          "text": "and if they would but truly observe the Torah and the Gospel and all [the revelation] that has been bestowed from on high upon them by their Sustainer, they would indeed partake of all the blessings of heaven and earth. Some of them do pursue a right course; but as for most of them -vile indeed is what they do!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 66,
          "number": 735,
          "juz": 6,
          "numpage": 119
        },
        {
          "text": "O APOSTLE! Announce all that has been bestowed from on high upon thee by thy Sustainer: for unless thou doest it fully, thou wilt not have delivered His message [at all]. And God will protect thee from [unbelieving] men: behold, God does not guide people who refuse to acknowledge the truth.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 67,
          "number": 736,
          "juz": 6,
          "numpage": 119
        },
        {
          "text": "Say: \"O followers of the Bible! You have no valid ground for your beliefs -unless you [truly] observe the Torah and the Gospel, and all that has been bestowed from on high upon you by your Sustainer!\" Yet all that has been bestowed from on high upon thee [O Prophet] by thy Sustainer is bound to make many of them yet more stubborn in their overweening arrogance and in their denial of the truth. But sorrow not over people who deny the truth:",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 68,
          "number": 737,
          "juz": 6,
          "numpage": 119
        },
        {
          "text": "for, verily, those who have attained to faith [in this divine writ], as well as those who follow the Jewish faith, and the Sabians, and the Christians - all who believe in God and the Last Day and do righteous deeds - no fear need they have, and neither shall they grieve.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 69,
          "number": 738,
          "juz": 6,
          "numpage": 119
        },
        {
          "text": "INDEED, We accepted a solemn pledge from the children of Israel, and We sent apostles unto them; [but] every time an apostle came unto them with anything that was not to their liking, [they rebelled:] to some of them they gave the lie, while others they would slay,",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 70,
          "number": 739,
          "juz": 6,
          "numpage": 119
        }
      ]
    },
    {
      "118": [
        {
          "text": "for, when you call to prayer, they mock at it and make a jest of it-simply because they are people who do not use their reason.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 58,
          "number": 727,
          "juz": 6,
          "numpage": 118
        },
        {
          "text": "Say: \"O followers of earlier revelation! Do you find fault with us for no other reason than that we believe in God [alone], and in that which He has bestowed from on high upon us as well as that which He has bestowed aforetime? - or [is it only] because most of you are iniquitous?\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 59,
          "number": 728,
          "juz": 6,
          "numpage": 118
        },
        {
          "text": "Say: \"Shall I tell you who, in the sight of God, deserves a yet worse retribution than these? They whom God has rejected and whom He has condemned, and whom He has turned into apes and swine because they worshipped the powers of evil: these are yet worse in station, and farther astray from the right path [than the mockers].\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 60,
          "number": 729,
          "juz": 6,
          "numpage": 118
        },
        {
          "text": "For, when they come unto you, they say, \"We do believe\": whereas, in fact, they come with the resolve to deny the truth, and depart in the same state. But God is fully aware of all that they would conceal.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 61,
          "number": 730,
          "juz": 6,
          "numpage": 118
        },
        {
          "text": "And thou canst see many of them vie with one another in sinning and tyrannical conduct and in their swallowing of all that is evil.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 62,
          "number": 731,
          "juz": 6,
          "numpage": 118
        },
        {
          "text": "Why do not their men of God and their rabbis forbid them to make sinful assertions and to swallow all that is evil? Vile indeed is what they contrive!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 63,
          "number": 732,
          "juz": 6,
          "numpage": 118
        },
        {
          "text": "And the Jews say, \"God's hand is shackled!\" It is their own hands that are shackled; and rejected [by God] are they because of this their assertion. Nay, but wide are His hands stretched out: He dispenses [bounty] as He wills. But all that has been bestowed from on high upon thee [O Prophet] by thy Sustainer is bound to make many of them yet more stubborn in their overweening arrogance and in their denial of the truth. And so We have cast enmity and hatred among the followers of the Bible, [to last] until Resurrection Day; every time they light the fires of war, God extinguishes them; and they labour hard to spread corruption on earth: and God does not -love the spreaders of corruption.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 64,
          "number": 733,
          "juz": 6,
          "numpage": 118
        }
      ]
    },
    {
      "117": [
        {
          "text": "O YOU who have attained to faith! Do not take the Jews and the Christians for your allies: they are but allies of one another and whoever of you allies himself with them becomes, verily, one of them; behold, God does not guide such evildoers.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 51,
          "number": 720,
          "juz": 6,
          "numpage": 117
        },
        {
          "text": "And yet thou canst see how those in whose hearts there is disease vie with one another for their good will, saying [to themselves], \"We fear lest fortune turn against us.\" But God may well bring about good fortune [for the believers] or any [other] event of His own devising, whereupon those [waverers] will be smitten with remorse for the thoughts which they had secretly harboured within themselves-",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 52,
          "number": 721,
          "juz": 6,
          "numpage": 117
        },
        {
          "text": "while those who have attained to faith will say [to one another], \"Are these the selfsame people who swore by God with their most solemn oaths that they were indeed with you? In vain are all their works, for now they are lost!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 53,
          "number": 722,
          "juz": 6,
          "numpage": 117
        },
        {
          "text": "O you who have attained to faith! If you ever abandon your faith,\" God will in time bring forth [in your stead] people whom He loves and who love Him - humble towards the believers, proud towards all who deny the truth: [people] who strive hard in God's cause, and do not fear to be censured by anyone who might censure them: such is God's favour, which He grants unto whom He wills. And God is infinite, all-knowing.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 54,
          "number": 723,
          "juz": 6,
          "numpage": 117
        },
        {
          "text": "Behold, your only helper shall be God, and His Apostle, and those who have attained to faith - those that are constant in prayer, and render the purifying dues, and bow down [before God]:",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 55,
          "number": 724,
          "juz": 6,
          "numpage": 117
        },
        {
          "text": "for, all who ally themselves with God and His Apostle and those who have attained to faith - behold, it is they, the partisans of God, who shall be victorious!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 56,
          "number": 725,
          "juz": 6,
          "numpage": 117
        },
        {
          "text": "O you who have attained to faith! Do not take for your friends such as mock at your, faith and make a jest of it-be they from among those who have been vouchsafed revelation before your time, or [from among] those who deny the truth [of revelation as such] -but remain conscious of God, if you are [truly] believers:",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 57,
          "number": 726,
          "juz": 6,
          "numpage": 117
        }
      ]
    },
    {
      "116": [
        {
          "text": "And We caused Jesus, the son of Mary, to follow in the footsteps of those [earlier prophets], confirming the truth of whatever there still remained of the Torah; and We vouchsafed unto him the Gospel, wherein there was guidance and light, confirming the truth of whatever there still remained of the Torah, and as a guidance and admonition unto the God-conscious.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 46,
          "number": 715,
          "juz": 6,
          "numpage": 116
        },
        {
          "text": "Let, then, the followers of the Gospel judge in accordance with what God has revealed therein: for they who do not judge in the light of what God has bestowed from on high-it is they, they who are truly iniquitous!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 47,
          "number": 716,
          "juz": 6,
          "numpage": 116
        },
        {
          "text": "And unto thee [O Prophet] have We vouchsafed this divine writ, setting forth the truth, confirming the truth of whatever there still remains of earlier revelations and determining what is true therein. Judge, then, between the followers of earlier revelation in accordance with what God has bestowed from on high, and do not follow their errant views, forsaking the truth that has come unto thee. Unto every one of you have We appointed a [different] law and way of life. And if God had so willed, He could surely have made you all one single community: but [He willed it otherwise] in order to test you by means of what He has vouchsafed unto, you. Vie, then, with one another in doing good works! Unto God you all must return; and then He will make you truly understand all that on which you were wont to differ.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 48,
          "number": 717,
          "juz": 6,
          "numpage": 116
        },
        {
          "text": "Hence, judge between the followers of earlier revelation in accordance with what God has bestowed from on high, and do not follow their errant views; and beware of them, lest they tempt thee away from aught that God has bestowed from on high upon thee. And if they turn away [from His commandments], then know that it is but God's will [thus] to afflict them for some of their sins: for, behold, a great many people are iniquitous indeed.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 49,
          "number": 718,
          "juz": 6,
          "numpage": 116
        },
        {
          "text": "Do they, perchance, desire [to be ruled by] the law of pagan ignorance? But for people who have inner certainty, who could be a better law-giver than God?",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 50,
          "number": 719,
          "juz": 6,
          "numpage": 116
        }
      ]
    },
    {
      "115": [
        {
          "text": "those who eagerly listen to any falsehood, greedily swallowing all that is evil! Hence, if they come to thee [for judgment], thou mayest either judge between them or leave them alone: for, if thou leave them alone, they cannot harm thee in any way. But if thou dost judge, judge between them with equity: verily, God knows those who act equitably.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 42,
          "number": 711,
          "juz": 6,
          "numpage": 115
        },
        {
          "text": "But how is it that they ask thee for judgment seeing that they have the Torah, containing God's injunctions - and thereafter turn away [from thy judgment]? Such as these, then, are no [true] believers.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 43,
          "number": 712,
          "juz": 6,
          "numpage": 115
        },
        {
          "text": "Verily, it is We who bestowed from on high the Torah, wherein there was guidance and light. On `its strength did the prophets, who had surrendered themselves unto God, deliver judgment unto those who followed the Jewish faith; and so did the [early] men of God and the rabbis, inasmuch as some of God's writ had been entrusted to their care; and they [all] bore witness to its truth. Therefore, [O children of Israel,] hold not men in awe, but stand in awe of Me; and do not barter away My messages for a trifling gain: for they who do not judge in accordance with what God has bestowed from on high are, indeed, deniers of the truth!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 44,
          "number": 713,
          "juz": 6,
          "numpage": 115
        },
        {
          "text": "And We ordained for them in that [Torah]: A life for a life, and an eye for an eye, and a nose for a nose, and an ear for an ear, and a tooth for a tooth, and a [similar] retribution for wounds; but he who shall forgo it out of charity will atone thereby for some of his past sins. And they who do not judge in accordance with what God has revealed - they, they are the evildoers!,",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 45,
          "number": 714,
          "juz": 6,
          "numpage": 115
        }
      ]
    },
    {
      "114": [
        {
          "text": "They will wish to come out of the fire, but they shall not come out of it; and long-lasting suffering awaits them.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 37,
          "number": 706,
          "juz": 6,
          "numpage": 114
        },
        {
          "text": "NOW AS FOR the man who steals and the woman who steals, cut off the hand of either of them in requital for what they have wrought, as a deterrent ordained by God: for God is almighty, wise.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 38,
          "number": 707,
          "juz": 6,
          "numpage": 114
        },
        {
          "text": "But as for him who repents after having thus done wrong, and makes amends, behold, God will accept his repentance: verily, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 39,
          "number": 708,
          "juz": 6,
          "numpage": 114
        },
        {
          "text": "Dost thou not know that God's is the dominion over the heavens and the earth? He chastises whom He wills, and He forgives whom He wills: for God has the power to will anything.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 40,
          "number": 709,
          "juz": 6,
          "numpage": 114
        },
        {
          "text": "O APOSTLE! Be not grieved by those who vie with one another in denying the truth: such as those who say with their mouths, \"We believe,\" the while their hearts do not believe; and such of the Jewish faith as eagerly listen to any falsehood, eagerly listen to other people without having come to thee [for enlightenment]. They distort the meaning of the [revealed] words, taking them out of their context, saying [to themselves], \"If such-and-such [teaching] is vouchsafed unto you, accept it; but if it is not vouchsafed unto you, be on your guard!\" [Be not grieved by them-] for if God wills anyone to be tempted to evil, thou canst in no wise prevail with God in his behalf. It is they whose hearts God is not willing to cleanse. Theirs shall be ignominy in this world, and awesome suffering in the life to come-",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 41,
          "number": 710,
          "juz": 6,
          "numpage": 114
        }
      ]
    },
    {
      "113": [
        {
          "text": "Because of this did We ordain unto the children of Israel that if anyone slays a human being-unless it be [in punishment] for murder or for spreading corruption on earth-it shall be as though he had slain all mankind; whereas, if anyone saves a life, it shall be as though he had saved the lives of all mankind. And, indeed, there came unto them Our apostles with all evidence of the truth: yet, behold, notwithstanding all this, many of them go on committing all manner of excesses on earth.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 32,
          "number": 701,
          "juz": 6,
          "numpage": 113
        },
        {
          "text": "It is but a just recompense for those who make war on God and His apostle, and endeavour to spread corruption on earth, that they are being slain in great' numbers, or crucified in great numbers, or have, in l' result of their perverseness, their hands and feet cut off in great numbers, or are being [entirely] banished from [the face of] the earth: such is their ignominy in this world. But in the life to come [yet more] awesome suffering awaits them-",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 33,
          "number": 702,
          "juz": 6,
          "numpage": 113
        },
        {
          "text": "save for such [of them] as repent ere you [O believers] become more powerful than they: for you must know that God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 34,
          "number": 703,
          "juz": 6,
          "numpage": 113
        },
        {
          "text": "O YOU who have attained to faith! Remain conscious of God, and seek to come closer unto Him, and strive hard in His cause, so that you might attain to a happy state.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 35,
          "number": 704,
          "juz": 6,
          "numpage": 113
        },
        {
          "text": "Verily, if those who are bent on denying the truth had all that is on earth, and twice as much, to offer as ransom from suffering on the Day of Resurrection, it would not be accepted from them: for grievous suffering awaits them.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 36,
          "number": 705,
          "juz": 6,
          "numpage": 113
        }
      ]
    },
    {
      "112": [
        {
          "text": "[But] they said: \"O Moses! Behold, never shall we enter that [land] so long as those others are in it. Go forth, then, thou and thy Sustainer, and fight, both of you! We, behold, shall remain here!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 24,
          "number": 693,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "Prayed [Moses]: \"O my Sustainer! Of none am I master but of myself and my brother [Aaron]: draw Thou, then, a dividing-line between us and these iniquitous folk!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 25,
          "number": 694,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "Answered He: \"Then, verily, this [land] shall be forbidden to them for forty years, while they wander on earth, bewildered, to and fro; and sorrow thou not over these iniquitous folk.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 26,
          "number": 695,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "AND CONVEY unto them, setting forth the truth, the story of the two sons of Adam -how each offered a sacrifice, and it was accepted from one of them whereas it was not accepted from the other. [And Cain] said: \"I will surely slay thee!\" [Abel] replied: \"Behold, God accepts only from those who are conscious of Him.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 27,
          "number": 696,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "Even if thou lay thy hand on me to slay me, I shall not lay my hand on thee to slay thee: behold, I fear God, the Sustainer of all the worlds.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 28,
          "number": 697,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "I am willing, indeed, for thee to bear [the burden of] all the sins ever done by me as well as of the sin done by thee: [but] then thou wouldst be destined for the fire, since that is the requital of evildoers!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 29,
          "number": 698,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "But the other's passion drove him to slaying his brother; and he slew him: and thus he became one of the lost.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 30,
          "number": 699,
          "juz": 6,
          "numpage": 112
        },
        {
          "text": "Thereupon God sent forth a raven which scratched the earth, to show him how he might conceal the nakedness of his brother's body. [And Cain] cried out: \"Oh, woe is me! Am I then too weak to do what this raven did, and to conceal the nakedness of my brother's body?\" - and was thereupon smitten with remorse.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 31,
          "number": 700,
          "juz": 6,
          "numpage": 112
        }
      ]
    },
    {
      "111": [
        {
          "text": "And [both] the Jews and the Christians say, \"We are God's children, and His beloved ones.\" Say: \"Why, then, does He cause you to suffer for your sins? Nay, you are but human beings of His creating. He forgives whom He wills, and He causes to suffer whom He wills: for God's is the dominion over the heavens and the earth and all that is between them, and with Him is all journeys' end.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 18,
          "number": 687,
          "juz": 6,
          "numpage": 111
        },
        {
          "text": "O followers of the Bible! Now, after a long time during which no apostles have appeared, there has come unto you [this] Our Apostle to make [the truth] clear to you, lest you say, \"No bearer of glad tidings has come unto us, nor any warner \": for now there has come unto you a bearer of glad tidings and a warner -since God has the power to will anything.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 19,
          "number": 688,
          "juz": 6,
          "numpage": 111
        },
        {
          "text": "AND, LO, Moses said unto his people:\" O my people! Remember the blessings which God bestowed upon you when he raised up prophets among you, and made you your own masters, and granted unto you [favours] such as He had not granted to anyone else in the world.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 20,
          "number": 689,
          "juz": 6,
          "numpage": 111
        },
        {
          "text": "O my people! Enter the holy land which God has promised you; but do not turn back [on your faith], for then you will be lost!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 21,
          "number": 690,
          "juz": 6,
          "numpage": 111
        },
        {
          "text": "They answered: \"O Moses! Behold, ferocious people -dwell in that land, and we will surely not enter it unless they depart therefrom; but if they depart therefrom, then, behold, we will enter it.\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 22,
          "number": 691,
          "juz": 6,
          "numpage": 111
        },
        {
          "text": "[Whereupon] two men from among those who feared [God, and] whom God had blessed, said: \"Enter upon them through the gate' -for as soon as you enter it, behold, you shall be victorious! And in God you must place your trust if you are [truly] believers!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 23,
          "number": 692,
          "juz": 6,
          "numpage": 111
        }
      ]
    },
    {
      "110": [
        {
          "text": "And [likewise,] from those who say, \"Behold, we are Christians.\" We have accepted a solemn pledge: and they, too, have forgotten much of what they had been told to bear in mind - wherefore We have given rise among them to enmity and hatred, [to last] until Resurrection Day: and in time God will cause them to understand what they have contrived.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 14,
          "number": 683,
          "juz": 6,
          "numpage": 110
        },
        {
          "text": "O followers of the Bible! Now there has come unto you Our Apostle, to make clear unto you much of what you have been concealing [from yourselves) of the Bible, and to pardon much. Now there has come unto you from God a light, and a clear divine writ,",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 15,
          "number": 684,
          "juz": 6,
          "numpage": 110
        },
        {
          "text": "through which God shows unto all that seek His goodly acceptance the paths leading to salvation and, by His grace, brings them out of the depths of darkness into the light and guides them onto a straight way.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 16,
          "number": 685,
          "juz": 6,
          "numpage": 110
        },
        {
          "text": "Indeed, the truth deny they who say, \"Behold, God is the Christ, son of Mary.\" Say: \"And who could have prevailed with God in any way had it been His will to destroy the Christ, son of Mary, and his mother, and everyone who is on earth-all of them? For, God's is the dominion over the heavens and the earth and all that is between them; He creates what He wills: and God has the power to will anything!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 17,
          "number": 686,
          "juz": 6,
          "numpage": 110
        }
      ]
    },
    {
      "109": [
        {
          "text": "whereas they who are bent on denying the truth and giving the lie to Our messages-they are destined for the blazing fire.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 10,
          "number": 679,
          "juz": 6,
          "numpage": 109
        },
        {
          "text": "O you who have attained to faith! Remember the blessings which God bestowed upon you when [hostile] people were about to lay hands on you and He stayed their hands from you. Remain, then, conscious of God: and in God let the believers place their trust.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 11,
          "number": 680,
          "juz": 6,
          "numpage": 109
        },
        {
          "text": "AND, INDEED, God accepted a [similar] solemn pledge from the children of Israel when We caused twelve of their leaders to be sent [to Canaan as spies]. And God said: \"Behold, I shall be with you! If you are constant in prayer, and spend in charity, and believe in My apostles and aid them, and offer up unto God a goodly loan, I will surely efface your bad deeds and bring you into gardens through which running waters flow. But he from among you who, after this, denies the truth, will indeed have strayed from the right path!\"",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 12,
          "number": 681,
          "juz": 6,
          "numpage": 109
        },
        {
          "text": "Then, for having broken their solemn pledge, We rejected them and caused their hearts to harden-[so that now] they distort the meaning of the [re-vealed] words, taking them out of their context; and they have forgotten much of what they had been told to bear in mind; and from all but a few of them thou wilt always experience treachery. But pardon them, and forbear: verily, God loves the doers of good.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 13,
          "number": 682,
          "juz": 6,
          "numpage": 109
        }
      ]
    },
    {
      "108": [
        {
          "text": "O YOU who have attained to faith! When you are about to pray, wash your face, and your hands and arms up to the elbows, and pass your [wet] hands lightly over your head, and [wash] your feet up to the ankles. And if you are in a state. requiring total ablution, purify yourselves. But if you are ill, or are travelling, or have just satisfied a want of nature, or have cohabited with a woman, and can find no water-then take resort to pure dust, passing therewith lightly over your face and your hands. God does not want to impose any hardship on you, but wants to make you pure, and to bestow upon you the full measure of His blessings, so that you might have cause to be grateful.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 6,
          "number": 675,
          "juz": 6,
          "numpage": 108
        },
        {
          "text": "And [always] remember the blessings which God has bestowed upon you, and the solemn pledge by which He bound you to Himself when you said, \"We have heard, and we pay heed.\" Hence, remain conscious of God: verily, God has full knowledge of what is in the hearts [of men].",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 7,
          "number": 676,
          "juz": 6,
          "numpage": 108
        },
        {
          "text": "O YOU who have attained to faith! Be ever steadfast in your devotion to God, bearing witness to the truth in all equity; and never let hatred of any-one lead you into the sin of deviating from justice. Be just: this is closest to being God-conscious. And remain conscious of God: verily, God is aware of all that you do.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 8,
          "number": 677,
          "juz": 6,
          "numpage": 108
        },
        {
          "text": "God has promised unto those who attain to faith and do good works [that] theirs shall be forgiveness of sins, and a mighty reward;",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 9,
          "number": 678,
          "juz": 6,
          "numpage": 108
        }
      ]
    },
    {
      "107": [
        {
          "text": "FORBIDDEN to you is carrion, and blood, and the flesh of swine, and that over which any name other than God's has been invoked, and the animal that has been strangled, or beaten to death, or killed by a fall, or gored to death, or savaged by a beast of prey, save that which you [yourselves] may have slaughtered while it was still alive; and [forbidden to you is] all that has been slaughtered on idolatrous altars. And [you are forbidden] to seek to learn through divination what the future may hold in store for you: this is sinful conduct. Today, those who are bent on denying the truth have lost all hope of [your ever forsaking] your religion: do not, then, hold them in awe, but stand in awe of Me! Today have I perfected your religious law for you, and have bestowed upon you the full measure of My blessings, and willed that self-surrender unto Me shall be your religion. As for him, however, who is driven [to what is forbidden] by dire necessity and not by an inclination to sinning -behold, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 3,
          "number": 672,
          "juz": 6,
          "numpage": 107
        },
        {
          "text": "They will ask thee as to what is lawful to them. Say: \"Lawful to you are all the good things of life.\" And as for those hunting animals which you train by imparting to them something of the knowledge that God has imparted to yourselves-eat of what they seize for you, but mention God's name over it. and remain conscious of God: verily, God is swift in reckoning.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 4,
          "number": 673,
          "juz": 6,
          "numpage": 107
        },
        {
          "text": "Today, all the good things of life have been made lawful to you. And the food of those who have been vouchsafed revelation aforetime is lawful to you, and your food is lawful to them. And [lawful to you are], in wedlock, women from among those who believe [in this divine writ], and, in wedlock, women from among those who have been vouchsafed revelation before your time -provided that you give them their dowers, taking them in honest wedlock, not in fornication, nor as secret love-companions. But as for him who rejects belief [in God] - in vain will be all his works: for in the life to come he shall be among the lost.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 5,
          "number": 674,
          "juz": 6,
          "numpage": 107
        }
      ]
    },
    {
      "106": [
        {
          "text": "THEY WILL ASK thee to enlighten them. Say: \"God enlightens you [thus] about the laws concerning [inheritance from] those who leave no heir in the direct line: If a man dies childless and has a sister, she shall inherit one-half of what he has left, just as he shall inherit from her if she dies childless. But if there are two sisters, both [together] shall have two-thirds of what he has left; and if there are brothers and sisters, then the male shall have the equal of two females' share.\" God makes [all this] clear unto you, lest you go astray; and God knows everything.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 176,
          "number": 669,
          "juz": 6,
          "numpage": 106
        },
        {
          "text": "O YOU who have attained to faith! Be true to your covenants! Lawful to you is the [flesh of every] beast that feeds on plants, save what is mentioned to you [hereinafter]: but you are not allowed to hunt while you are in the state of pilgrimage. Behold, God ordains in accordance with His will.",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 1,
          "number": 670,
          "juz": 6,
          "numpage": 106
        },
        {
          "text": "O you who have attained to faith! Offend not against the symbols set up by God, nor against the sacred month [of pilgrimage], nor against the garlanded offerings, nor against those who flock to the Inviolable Temple, seeking favour with their Sustainer and His goodly acceptance; and [only] after your pilgrimage is over are you free to hunt. And never let your hatred of people who would bar you from the Inviolable House of Worship lead you into the sin of aggression: but rather help one another in furthering virtue and God-consciousness, and do not help one another in furthering evil and enmity; and remain conscious of God: for, behold, God is severe in retribution!",
          "englishName": "Al-Maaida",
          "suraNumber": 5,
          "numberInSurah": 2,
          "number": 671,
          "juz": 6,
          "numpage": 106
        }
      ]
    },
    {
      "105": [
        {
          "text": "O FOLLOWERS of the Gospel! Do not overstep the bounds [of truth] in your religious beliefs, and do not say of God anything but the truth. The Christ Jesus, son of Mary, was but God's Apostle - [the fulfilment of] His promise which He had conveyed unto Mary - and a soul created by Him. Believe, then, in God and His apostles, and do not say, \"[God is] a trinity\". Desist [from this assertion] for your own good. God is but One God; utterly remote is He, in His glory, from having a son: unto Him belongs all that is in the heavens and all that is on earth; and none is as worthy of trust as God.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 171,
          "number": 664,
          "juz": 6,
          "numpage": 105
        },
        {
          "text": "Never did the Christ feel too proud to be God's servant, nor do the angels who are near unto Him. And those who feel too proud to serve Him and glory in their arrogance [should know that on Judgment Day] He will gather them all unto Himself:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 172,
          "number": 665,
          "juz": 6,
          "numpage": 105
        },
        {
          "text": "whereupon unto those who attained to faith and did good deeds He will grant their just rewards, and give them yet more out of His bounty; whereas those who felt too proud and gloried in their arrogance He will chastise with grievous suffering: and they shall find none to protect them from God, and none to bring them succour.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 173,
          "number": 666,
          "juz": 6,
          "numpage": 105
        },
        {
          "text": "O MANKIND! A manifestation of the truth has now come unto you from your Sustainer, and We have sent down unto you a clear light.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 174,
          "number": 667,
          "juz": 6,
          "numpage": 105
        },
        {
          "text": "And as for those who have attained to faith in God and hold fast unto Him - He will enfold them within His grace and bounty, and guide them unto Himself by a straight way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 175,
          "number": 668,
          "juz": 6,
          "numpage": 105
        }
      ]
    },
    {
      "104": [
        {
          "text": "BEHOLD, We have inspired thee [O Prophet] just as We inspired Noah and all the prophets after him - as We inspired Abraham, and Ishmael, and Isaac, and Jacob, and their descendants, including Jesus and Job, and Jonah, and Aaron, and Solomon; and as We vouchsafed unto David a book of divine wisdom;",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 163,
          "number": 656,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "and as [We inspired other] apostles whom We have mentioned to thee ere this, as well as apostles whom We have not mentioned to thee; and as God spoke His word unto Moses:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 164,
          "number": 657,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "[We sent all these] apostles as heralds of glad tidings and as warners, so that men might have no excuse before God after [the coming of] these apostles: and God is indeed al­mighty, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 165,
          "number": 658,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "However it be, God [Himself] bears witness to the truth of what He has bestowed from on high upon thee: out of His own wisdom has He bestowed it from on high, with the angels bearing witness thereto - although none can bear witness as God does.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 166,
          "number": 659,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "Behold, those who are bent on denying the truth and on turning others away from the path of God have indeed gone far astray.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 167,
          "number": 660,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "Behold, those who are bent on denying the truth and on evildoing - God will indeed not forgive them, nor will He guide them onto any road",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 168,
          "number": 661,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "but the road that leads to hell, therein to abide beyond the count of time: and this is indeed easy for God.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 169,
          "number": 662,
          "juz": 6,
          "numpage": 104
        },
        {
          "text": "O mankind! The Apostle has now come unto you with the truth from your Sustainer: believe, then, for your own good! And if you deny the truth - behold, unto God belongs all that is in the heavens and all that is on earth, and God is indeed all-knowing, wise!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 170,
          "number": 663,
          "juz": 6,
          "numpage": 104
        }
      ]
    },
    {
      "103": [
        {
          "text": "And so, [We punished them] for the break­ing of their pledge, and their refusal to acknowledge God's messages, and their slaying of prophets against all right, and their boast, \"Our hearts are already full of knowledge\"- nay, but God has sealed their hearts in result of their denial of the truth, and [now] they believe in but few things -;",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 155,
          "number": 648,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "and for their refusal to acknowledge the truth, and the awesome calumny which they utter against Mary,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 156,
          "number": 649,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "and their boast, \"Behold, we have slain the Christ Jesus, son of Mary, [who claimed to be] an apostle of God!\" However, they did not slay him, and neither did they crucify him, but it only seemed to them [as if it had been] so; and, verily, those who hold conflict­ing views thereon are indeed confused, having no [real] knowledge thereof, and following mere con­jecture. For, of a certainty, they did not slay him:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 157,
          "number": 650,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "nay, God exalted him unto Himself - and God is indeed almighty, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 158,
          "number": 651,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "Yet there is not one of the followers of earlier revelation who does not, at the moment of his death, grasp the truth about Jesus; and on the Day of Resurrection he [himself] shall bear witness to the truth against them.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 159,
          "number": 652,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "So, then, for the wickedness committed by those who followed the Jewish faith did We deny unto them certain of the good things of life which [aforetime] had been allowed to them; and [We did this] for their having so often turned away from the path of God,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 160,
          "number": 653,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "and [for] their taking usury although it had been forbidden to them, and their wrongful devouring of other people's possessions. And for those from among them who [continue to] deny the truth We have readied grievous suffering.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 161,
          "number": 654,
          "juz": 6,
          "numpage": 103
        },
        {
          "text": "But as for those from among them who are deeply rooted in knowledge, and the believers who believe in that which has been bestowed upon thee from on high as well as that which was bestowed from on high before thee, and those who are [es­pecially] constant in prayer, and spend in charity, and all who believe in God and the Last Day - these it is unto whom We shall grant a mighty reward.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 162,
          "number": 655,
          "juz": 6,
          "numpage": 103
        }
      ]
    },
    {
      "102": [
        {
          "text": "God does not like any evil to be mentioned openly, unless it be by him who has been wronged (thereby) And God is indeed all-hearing, all-knowing,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 148,
          "number": 641,
          "juz": 6,
          "numpage": 102
        },
        {
          "text": "whether you do good openly or in secret, or pardon others for evil [done unto you]: for, behold, God is indeed an absolver of sins, infinite in His power.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 149,
          "number": 642,
          "juz": 6,
          "numpage": 102
        },
        {
          "text": "VERILY, those who deny God and His apostles by endeavouring to make a distinction between [belief in] God and [belief in] His apostles, and who say, \"We believe in the one but we deny the other,\" and want to pursue a path in-between -",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 150,
          "number": 643,
          "juz": 6,
          "numpage": 102
        },
        {
          "text": "it is they, they who are truly denying the truth: and for those who deny the truth We have readied shameful suffering.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 151,
          "number": 644,
          "juz": 6,
          "numpage": 102
        },
        {
          "text": "But as for those who believe in God and His apostles and make no distinction between any of them - unto them, in time, will He grant their re­wards [in full]. And God is indeed much-forgiving, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 152,
          "number": 645,
          "juz": 6,
          "numpage": 102
        },
        {
          "text": "THE FOLLOWERS of the Old Testament demand of thee [O Prophet] that thou cause a revelation to be sent down to them from heaven. And an even greater thing than this did they demand of Moses when they said, \"Make us see God face to face\" - whereupon the thunderbolt of punishment overtook them for this their wickedness. After that, they took to worshipping the [golden] calf - and this after all evidence of the truth had come unto them! None the less, We effaced this [sin of theirs], and vouchsafed unto Moses a clear proof [of the truth],",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 153,
          "number": 646,
          "juz": 6,
          "numpage": 102
        },
        {
          "text": "raising Mount Sinai high above them in witness of their solemn pledge. And We said unto them, “Enter the gate humbly”; and We told them, \"Do not break the Sabbath-law\"; and We accepted from them a most solemn pledge.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 154,
          "number": 647,
          "juz": 6,
          "numpage": 102
        }
      ]
    },
    {
      "101": [
        {
          "text": "who but wait to see what betides you: thus, if triumph comes to you from God, they say, \"Were we not on your side?\"- whereas if those who deny the truth are in luck, they say [to them], \"Have we not earned your affection by defending you against those be­lievers?'' But God will judge between you all on the Day of Resurrection; and never will God allow those who deny the truth to harm the believers.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 141,
          "number": 634,
          "juz": 5,
          "numpage": 101
        },
        {
          "text": "Behold, the hypocrites seek to deceive God - the while it is He who causes them to be deceived [by themselves] And when they rise to pray, they rise reluctantly, only to be seen and praised by men, remembering God but seldom,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 142,
          "number": 635,
          "juz": 5,
          "numpage": 101
        },
        {
          "text": "wavering be­tween this and that, [true] neither to these nor those. But for him whom God lets go astray thou canst never find any way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 143,
          "number": 636,
          "juz": 5,
          "numpage": 101
        },
        {
          "text": "O you who have attained to faith! Do not take the deniers of the truth for your allies in preference to the believers! Do you want to place before God a manifest proof of your guilt?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 144,
          "number": 637,
          "juz": 5,
          "numpage": 101
        },
        {
          "text": "Verily, the hypocrites shall be in the lowest depth of the fire, and thou wilt find none who could succour them.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 145,
          "number": 638,
          "juz": 5,
          "numpage": 101
        },
        {
          "text": "But excepted shall be they who repent, and live righteously, and hold fast unto God, and grow sincere in their faith in God alone: for these shall be one with the believers - and in time God will grant to all believers a mighty reward.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 146,
          "number": 639,
          "juz": 5,
          "numpage": 101
        },
        {
          "text": "Why would God cause you to suffer [for your past sins] if you are grateful and attain to belief - seeing that God is always responsive to gratitude, all-knowing?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 147,
          "number": 640,
          "juz": 5,
          "numpage": 101
        }
      ]
    },
    {
      "100": [
        {
          "text": "O YOU who have attained to faith! Be ever steadfast in upholding equity, bearing witness to the truth for the sake of God, even though it be against your own selves or your parents and kinsfolk. Whether the person concerned be rich or poor, God's claim takes precedence over [the claims of] either of them. Do not, then, follow your own desires, lest you swerve from justice: for if you distort [the truth], behold, God is indeed aware of all that you do!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 135,
          "number": 628,
          "juz": 5,
          "numpage": 100
        },
        {
          "text": "O you who have attained to faith! Hold fast unto your belief in God and His Apostle, and in the divine writ which He has bestowed from on high upon His Apostle, step by step, as well as in the revelation which He sent down aforetime: for he who denies God, and His angels, and His revelations, and His apostles, and the Last Day, has indeed gone far astray.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 136,
          "number": 629,
          "juz": 5,
          "numpage": 100
        },
        {
          "text": "Behold, as for those who come to believe, and then deny the truth, and again come to believe, and again deny the truth, and thereafter grow stubborn in their denial of the truth - God will not forgive them, nor will He guide them in any way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 137,
          "number": 630,
          "juz": 5,
          "numpage": 100
        },
        {
          "text": "Announce thou to such hypocrites that grievous suffering awaits them.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 138,
          "number": 631,
          "juz": 5,
          "numpage": 100
        },
        {
          "text": "As for those who take the deniers of the truth for their allies in preference to the believers - do they hope to be honoured by them when, behold, all honour belongs to God [alone]?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 139,
          "number": 632,
          "juz": 5,
          "numpage": 100
        },
        {
          "text": "And, indeed, He has enjoined upon you in this divine writ that whenever you hear people deny the truth of God's messages and mock at them, you shall avoid their company until they begin to talk of other things - or else, verily, you will become like them. Behold, together with those who deny the truth God will gather in hell the hypocrites,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 140,
          "number": 633,
          "juz": 5,
          "numpage": 100
        }
      ]
    },
    {
      "99": [
        {
          "text": "And if a woman has reason to fear ill-treat­ment from her husband, or that he might turn away from her, it shall not be wrong for the two to set things peacefully to rights between themselves: for peace is best, and selfishness is ever-present in human souls. But if you do good and are conscious of Him - behold, God is indeed aware of all that you do.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 128,
          "number": 621,
          "juz": 5,
          "numpage": 99
        },
        {
          "text": "And it will not be within your power to treat your wives with equal fairness, however much you may desire it; and so, do not allow yourselves to incline towards one to the exclusion of the other, leaving her in a state, as it were, of having and not having a husband. But if you put things to rights and are conscious of Him - behold, God is indeed much-forgiving, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 129,
          "number": 622,
          "juz": 5,
          "numpage": 99
        },
        {
          "text": "And if husband and wife do separate, God shall provide for each of them out of His abundance: for God is indeed infinite, wise,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 130,
          "number": 623,
          "juz": 5,
          "numpage": 99
        },
        {
          "text": "and unto God belongs all that is in the heavens and all that is on earth. AND, INDEED, We have enjoined upon those who were granted revelation before your time, as well as upon yourselves, to remain conscious of God. And if you deny Him - behold, unto God belongs all that is in the heavens and all that is on earth, and God is indeed self-sufficient, ever to be praised.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 131,
          "number": 624,
          "juz": 5,
          "numpage": 99
        },
        {
          "text": "And unto God belongs all that is in the heavens and all that is on earth; and none is as worthy of trust as God.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 132,
          "number": 625,
          "juz": 5,
          "numpage": 99
        },
        {
          "text": "If He so wills, He can cause you, O mankind, to disappear, and bring forth other beings [in your stead]: for God has indeed the power to do this.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 133,
          "number": 626,
          "juz": 5,
          "numpage": 99
        },
        {
          "text": "If one desires the rewards of this world, [let him remember that] with God are the rewards of [both] this world and the life to come: and God is indeed all-hearing, all-seeing.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 134,
          "number": 627,
          "juz": 5,
          "numpage": 99
        }
      ]
    },
    {
      "98": [
        {
          "text": "Yet those who attain to faith and do righteous deeds We shall bring into gardens through which running waters flow, therein to abide beyond the count of time: this is, in truth, God's promise - and whose word could be truer than God's?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 122,
          "number": 615,
          "juz": 5,
          "numpage": 98
        },
        {
          "text": "It may not accord with your wishful thinking - nor with the wishful thinking of the fol­lowers of earlier revelation - [that] he who does evil shall be requited for it, and shall find none to protect him from God, and none to bring him succour,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 123,
          "number": 616,
          "juz": 5,
          "numpage": 98
        },
        {
          "text": "whereas anyone - be it man or woman - who does [whatever he can] of good deeds and is a believer withal, shall enter paradise, and shall not be wronged by as much as [would fill] the groove of a date-stone.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 124,
          "number": 617,
          "juz": 5,
          "numpage": 98
        },
        {
          "text": "And who could be of better faith than he who surrenders his whole being unto God and is a doer of good withal, and follows the creed of Abraham, who turned away from all that is false - seeing that God exalted Abraham with His love?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 125,
          "number": 618,
          "juz": 5,
          "numpage": 98
        },
        {
          "text": "For, unto God belongs all that is in the heavens and all that is on earth; and, indeed, God encompasses everything.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 126,
          "number": 619,
          "juz": 5,
          "numpage": 98
        },
        {
          "text": "AND THEY will ask thee to enlighten them about the laws concerning women. Say: \"God [Himself] en­lightens you about the laws concerning them\"- for [His will is shown] in what is being conveyed unto you through this divine writ about orphan women [in your charge], to whom - because you yourselves may be desirous of marrying them - you do not give that which has been ordained for them; and about help­less children; and about your duty to treat orphans with equity. And whatever good you may do - be­hold, God has indeed full knowledge thereof.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 127,
          "number": 620,
          "juz": 5,
          "numpage": 98
        }
      ]
    },
    {
      "97": [
        {
          "text": "NO GOOD comes, as a rule, out of secret confabulations - saving such as are devoted to en­joining charity, or equitable dealings, or setting things to rights between people: and unto him who does this out of a longing for God's goodly acceptance We shall in time grant a mighty reward.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 114,
          "number": 607,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "But as for him who, after guidance has been vouchsafed to him, cuts himself off from the Apostle and follows a path other than that of the believers - him shall We leave unto that which he himself has chosen, and shall cause him to endure hell: and how evil a journey’s end!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 115,
          "number": 608,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "VERILY, God does not forgive the ascribing of divinity to aught beside Him, although He forgives any lesser sin unto whomever He wills: for those who ascribe divinity to aught beside God have indeed gone far astray.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 116,
          "number": 609,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "In His stead, they invoke only life­less symbols - thus invoking none but a rebellious Satan",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 117,
          "number": 610,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "whom God has rejected for having said, \"Verily, of Thy servants I shall most certainly take my due share,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 118,
          "number": 611,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "and shall lead them astray, and fill them with vain desires; and I shall command them - and they will cut off the ears of cattle [in idolatrous sacrifice]; and I shall command them - and they will corrupt God's creation!\" But all who take Satan rather than God for their master do indeed, most clearly, lose all:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 119,
          "number": 612,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "he holds out promises to them, and fills them with vain desires: yet whatever Satan promises them is but meant to delude the mind.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 120,
          "number": 613,
          "juz": 5,
          "numpage": 97
        },
        {
          "text": "Such as these have hell for their goal: and they shall find no way to escape therefrom.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 121,
          "number": 614,
          "juz": 5,
          "numpage": 97
        }
      ]
    },
    {
      "96": [
        {
          "text": "but pray God to forgive [them]: behold, God is indeed much-forgiving, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 106,
          "number": 599,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "Yet do not argue in behalf of those who are false to their own selves: verily, God does not love those who betray their trust and persist in sinful ways.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 107,
          "number": 600,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "They would conceal their doings from men; but from God they cannot conceal them - for He is with them whenever they devise, in the dark of night, all manner of beliefs which He does not approve. And God indeed encompasses [with His knowledge] whatever they do.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 108,
          "number": 601,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "Oh, you might well argue in their behalf in the life of this world: but who will argue in their behalf with God on the Day of Resurrection, or who will be their defender?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 109,
          "number": 602,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "Yet he who does evil or [otherwise] sins against himself, and thereafter prays God to forgive him, shall find God much-forgiving, a dispenser of grace:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 110,
          "number": 603,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "for he who commits a sin, commits it only to his own hurt; and God is indeed all-knowing, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 111,
          "number": 604,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "But he who commits a fault or a sin and then throws the blame therefore on an innocent person, burdens himself with the guilt of calumny and [yet another] flagrant sin.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 112,
          "number": 605,
          "juz": 5,
          "numpage": 96
        },
        {
          "text": "And but for God's favour upon thee and His grace, some of those [who are false to themselves] would indeed endeavour to lead thee astray; yet none but themselves do they lead astray. Nor can they harm thee in any way, since God has bestowed upon thee from on high this divine writ and [given thee] wisdom, and has imparted unto thee the knowledge of what thou didst not know. And God's favour upon thee is tremendous indeed.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 113,
          "number": 606,
          "juz": 5,
          "numpage": 96
        }
      ]
    },
    {
      "95": [
        {
          "text": "Thus, when thou art among the believers and about to lead them in prayer, let [only] part of them stand up with thee, retaining their arms. Then, after they have finished their prayer, let them provide you cover while another group, who have not yet prayed, shall come forward and pray with thee, being fully prepared against danger and retaining their arms: (for) those who are bent on denying the truth would love to see you oblivious of your arms and your equipment, so that they might fall upon you in a surprise attack. But it shall not be wrong for you to lay down your arms [while you pray] if you are troubled by rain or if you are ill; but [always] be fully prepared against danger. Verily, God has readied shameful suffering for all who deny the truth!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 102,
          "number": 595,
          "juz": 5,
          "numpage": 95
        },
        {
          "text": "And when you have finished your prayer, remember God - standing and sitting and lying down; and when you are once again secure, observe your prayers [fully]. Verily, for all believers prayer is indeed a sacred duty linked to particular times [of day].",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 103,
          "number": 596,
          "juz": 5,
          "numpage": 95
        },
        {
          "text": "And be not faint of heart when you seek out the [enemy] host. If you happen to suffer pain, behold, they suffer pain even as you suffer it: but you are hoping [to receive] from God what they cannot hope for. And God is indeed all-knowing, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 104,
          "number": 597,
          "juz": 5,
          "numpage": 95
        },
        {
          "text": "BEHOLD, We have bestowed upon thee from on high this divine writ, setting forth the truth, so that thou may judge between people in accordance with what God has taught thee. Hence, do not contend with those who are false to their trust,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 105,
          "number": 598,
          "juz": 5,
          "numpage": 95
        }
      ]
    },
    {
      "94": [
        {
          "text": "SUCH of the believers as remain passive - other than the disabled - cannot be deemed equal to those who strive hard in God's cause with their possessions and their lives: God has exalted those who strive hard with their possessions and their lives far above those who remain passive. Although God has promised the ultimate good unto all [believers], yet has God exalted those who strive hard above those who remain passive by [promising them] a mighty reward -",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 95,
          "number": 588,
          "juz": 5,
          "numpage": 94
        },
        {
          "text": "[many] degrees thereof - and for­giveness of sins, and His grace; for God is indeed much-forgiving, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 96,
          "number": 589,
          "juz": 5,
          "numpage": 94
        },
        {
          "text": "Behold, those whom the angels gather in death while they are still sinning against themselves, [the angels] will ask, \"What was wrong with you?'' They will answer: \"We were too weak on earth.\" [The angels] will say: \"Was, then, God's earth not wide enough for you to forsake the domain of evil?\" For such, then, the goal is hell - and how evil a journey's end!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 97,
          "number": 590,
          "juz": 5,
          "numpage": 94
        },
        {
          "text": "But excepted shall be the truly helpless - be they men or women or children - who cannot bring forth any strength and have not been shown the right way:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 98,
          "number": 591,
          "juz": 5,
          "numpage": 94
        },
        {
          "text": "as for them, God may well efface their sin - for God is indeed an absolver of sins, much-forgiving.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 99,
          "number": 592,
          "juz": 5,
          "numpage": 94
        },
        {
          "text": "And he who forsakes the domain of evil for the sake of God shall find on earth many a lonely road, as well as life abundant. And if anyone leaves his home, fleeing from evil unto God and His Apostle, and then death overtakes him - his reward is ready with God: for God iss indeed much-forgiving, a dis­penser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 100,
          "number": 593,
          "juz": 5,
          "numpage": 94
        },
        {
          "text": "AND WHEN you go forth [to war] on earth, you will incur no sin by shortening your prayers if you have reason to fear that those who are bent on denying the truth might suddenly fall upon you: for, verily, those who deny the truth are your open foes.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 101,
          "number": 594,
          "juz": 5,
          "numpage": 94
        }
      ]
    },
    {
      "93": [
        {
          "text": "AND IT IS not conceivable that a believer should slay another believer, unless it be by mistake. And upon him who has slain a believer by mistake there is the duty of freeing a believing soul from bondage and paying an indemnity to the victim's relations, unless they forgo it by way of charity. Now if the slain, while himself a believer, belonged to a people who are at war with you, [the penance shall be confined to] the freeing of a believing soul from bondage; whereas, if he belonged to a people to whom you are bound by a covenant, [it shall consist of] an indemnity to be paid to his relations in ad­dition to the freeing of a believing soul from bon­dage. And he who does not have the wherewithal shall fast [instead] for two consecutive months. (This is) the atonement ordained by God: and God is indeed all-knowing, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 92,
          "number": 585,
          "juz": 5,
          "numpage": 93
        },
        {
          "text": "But whoever deliberately slays another be­liever, his requital shall be hell, therein to abide; and God will condemn him, and will reject him, and will prepare for him awesome suffering.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 93,
          "number": 586,
          "juz": 5,
          "numpage": 93
        },
        {
          "text": "[Hence,] O you who have attained to faith, when you go forth [to war] in God's cause, use your discernment, and do not - out of a desire for the fleeting gains of this worldly life - say unto anyone who offers you the greeting of peace, \"Thou art not a believer\" for with God there are gains abundant. You, too, were once in the same condition - but God has been gracious unto you. Use, therefore, your discernment: verily, God is always aware of what you do.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 94,
          "number": 587,
          "juz": 5,
          "numpage": 93
        }
      ]
    },
    {
      "92": [
        {
          "text": "God - save whom there is no deity - will surely gather you all together on the Day of Resurrection, [the coming of] which is beyond all doubt: and whose word could be truer than God's?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 87,
          "number": 580,
          "juz": 5,
          "numpage": 92
        },
        {
          "text": "How, then, could you be of two minds about the hypocrites, seeing that God [Himself] has disowned them because of their guilt? Do you, perchance, seek to guide those whom God has let go astray - when for him whom God lets go astray thou canst never find any way?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 88,
          "number": 581,
          "juz": 5,
          "numpage": 92
        },
        {
          "text": "They would love to see you deny the truth even as they have denied it, so that you should be like them. Do not, therefore, take them for your allies until they forsake the domain of evil for the sake of God; and if they revert to [open] enmity, seize them and slay them wherever you may find them. And do not take any of them for your ally or giver of succour,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 89,
          "number": 582,
          "juz": 5,
          "numpage": 92
        },
        {
          "text": "unless it be such [of them] as have ties with people to whom you yourselves are bound by a covenant, or such as come unto you because their hearts shrink from [the thought of] making war either on you or on their own folk - although, if God had willed to make them stronger than you, they would certainly have made war on you. Thus, if they let you be, and do not make war on you, and offer you peace, God does not allow you to harm them.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 90,
          "number": 583,
          "juz": 5,
          "numpage": 92
        },
        {
          "text": "You will find [that there are] others who would like to be safe from you as well as safe from their own folk, [but who,] whenever they are faced anew with temptation to evil, plunge into it headlong. Hence, if they do not let you be, and do not offer you peace, and do not stay their hands, seize them and slay them whenever you come upon them: for it is against these that We have clearly empowered you [to make war].",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 91,
          "number": 584,
          "juz": 5,
          "numpage": 92
        }
      ]
    },
    {
      "91": [
        {
          "text": "Whoever pays heed unto the Apostle pays heed unto God thereby; and as for those who turn away - We have not sent thee to be their keeper.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 80,
          "number": 573,
          "juz": 5,
          "numpage": 91
        },
        {
          "text": "And they say, \"We do pay heed unto thee'' - but when they leave thy presence, some of them devise, in the dark of night, [beliefs] other than thou art voicing; and all the while God records what they thus devise in the dark of night. Leave them, then, alone, and place thy trust in God: for none is as worthy of trust as God.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 81,
          "number": 574,
          "juz": 5,
          "numpage": 91
        },
        {
          "text": "Will they not, then, try to understand this Qur’an? Had it issued from any but God, they would surely have found in it many an inner contradiction!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 82,
          "number": 575,
          "juz": 5,
          "numpage": 91
        },
        {
          "text": "AND IF any [secret] matter pertaining to peace or war comes within their ken, they spread it abroad - whereas, if they would but refer it unto the Apostle and unto those from among the believers who have been entrusted with authority, such of them as are engaged in obtaining intelligence would indeed know [what to do with] it And but for God's bounty towards you, and His grace, all but a few of you would certainly have followed Satan.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 83,
          "number": 576,
          "juz": 5,
          "numpage": 91
        },
        {
          "text": "Fight thou, then, in God's cause - since thou art but responsible for thine own self - and inspire the believers to overcome all fear of death. God may well curb the might of those who are bent on denying the truth: for God is stronger in might, and stronger in ability to deter.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 84,
          "number": 577,
          "juz": 5,
          "numpage": 91
        },
        {
          "text": "Whoever rallies to a good cause shall have a share in its blessings; and whoever rallies to an evil cause shall be answerable for his part in it: for, indeed, God watches over everything.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 85,
          "number": 578,
          "juz": 5,
          "numpage": 91
        },
        {
          "text": "But when you are greeted with a greeting [of peace], answer with an even better greeting, or [at least] with the like thereof. Verily, God keeps count indeed of all things.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 86,
          "number": 579,
          "juz": 5,
          "numpage": 91
        }
      ]
    },
    {
      "90": [
        {
          "text": "And how could you refuse to fight in the cause of God and of the utterly helpless men and women and children who are crying, \"O our Sustainer! Lead us forth [to freedom] out of this land whose people are oppressors, and raise for us, out of Thy grace, a protector, and raise for us, out of Thy grace, one who will bring us succour!\"",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 75,
          "number": 568,
          "juz": 5,
          "numpage": 90
        },
        {
          "text": "Those who have attained to faith fight in the cause of God, whereas those who are bent on denying the truth fight in the cause of the powers of evil. Fight, then, against those friends of Satan: verily, Satan's guile is weak indeed!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 76,
          "number": 569,
          "juz": 5,
          "numpage": 90
        },
        {
          "text": "ART THOU NOT aware of those who have been told, \"Curb your hands, and be constant in prayer, and render the purifying dues\"? But as soon as fighting [in God's cause] is ordained for them, lo, some of them stand in awe of men as one should stand in awe of God - or in even greater awe - and say, \"O our Sustainer! Why hast Thou ordained fighting for us? If only Thou hadst granted us a delay for a little while!\" Say: \"Brief is the enjoyment of this world, whereas the life to come is the best for all who are conscious of God - since none of you shall be wronged by as much as a hair's breadth.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 77,
          "number": 570,
          "juz": 5,
          "numpage": 90
        },
        {
          "text": "Wherever you may be, death will overtake you - even though you be in towers raised high. \"Yet, when a good thing happens to them, some [people] say, \"This is from God,\" whereas when evil befalls them, they say, \"This is from thee [O fellow­man]!” Say: \"All is from God.\" What, then, is amiss with these people that they are in no wise near to grasping the truth of what they are told?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 78,
          "number": 571,
          "juz": 5,
          "numpage": 90
        },
        {
          "text": "Whatever good happens to thee is from God; and whatever evil befalls thee is from thyself. AND WE have sent thee [O Muhammad] as an apostle unto all mankind: and none can bear witness [thereto] as God does.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 79,
          "number": 572,
          "juz": 5,
          "numpage": 90
        }
      ]
    },
    {
      "89": [
        {
          "text": "Yet if We were to ordain for them, \"Lay down your lives,\" or, \"Forsake your homelands,\" only a very few of them would do it - although, if they did what they are admonished to do, it would indeed be for their own good and apt to strengthen them greatly [in faith],",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 66,
          "number": 559,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "whereupon We should indeed grant them, out of Our grace, a mighty reward,",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 67,
          "number": 560,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "and indeed guide them onto a straight way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 68,
          "number": 561,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "For, all who pay heed unto God and the Apostle shall be among those upon whom God has bestowed His blessings: the prophets, and those who never deviated from the truth, and those who [with their lives] bore witness to the truth, and the right­eous ones: and how goodly a company are these!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 69,
          "number": 562,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "Such is the bounty of God - and none has the knowledge which God has.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 70,
          "number": 563,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "O YOU who have attained to faith! Be fully prepared against danger, whether you go to war in small groups or all together.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 71,
          "number": 564,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "And, behold, there are indeed among you such as would lag behind, and then, if calamity befalls you, say, \"God has bestowed His favour upon me in that I did not accompany them.\"",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 72,
          "number": 565,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "But if good fortune comes to you from God, such a person is sure to say - just as if there had never been any question of love between you and him -: \"Oh, would that I had been with them, and thus had a [share in their] mighty triumph!\"",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 73,
          "number": 566,
          "juz": 5,
          "numpage": 89
        },
        {
          "text": "Hence, let them fight in God's cause - all who are willing to barter the life of this world for the life to come: for unto him who fights in God's cause, whether he be slain or be victorious, We shall in time grant a mighty reward.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 74,
          "number": 567,
          "juz": 5,
          "numpage": 89
        }
      ]
    },
    {
      "88": [
        {
          "text": "ART THOU NOT aware of those who claim that they believe in what has been bestowed from on high upon thee, [O Prophet,] as well as in what was bestowed from on high before thee, [and yet] are willing to defer to the rule of the powers of evil - although they were bidden to deny it, seeing that Satan but wants to lead them far astray?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 60,
          "number": 553,
          "juz": 5,
          "numpage": 88
        },
        {
          "text": "And so, whenever they are told, \"Come unto that which God has be­stowed from on high, and unto the Apostle,\" thou canst see these hypocrites turn away from thee with aversion.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 61,
          "number": 554,
          "juz": 5,
          "numpage": 88
        },
        {
          "text": "But how [will they fare] when calamity befalls them [on the Day of Judgment] because of what they have wrought in this world - whereupon they will come to thee, swearing by God, \"Our aim was but to do good, and to bring about harmony\"?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 62,
          "number": 555,
          "juz": 5,
          "numpage": 88
        },
        {
          "text": "As for them - God knows all that is in their hearts; so leave them alone, and admonish them, and speak unto them about themselves in a gravely search­ing manner:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 63,
          "number": 556,
          "juz": 5,
          "numpage": 88
        },
        {
          "text": "for We have never sent any apostle save that he should be heeded by God's leave. If, then, after having sinned against themselves, they would but come round to thee and ask God to forgive them - with the Apostle, too, praying that they be forgiven - they would assuredly find that God is an acceptor of repentance, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 64,
          "number": 557,
          "juz": 5,
          "numpage": 88
        },
        {
          "text": "But nay, by thy Sustainer! They do not [really] believe unless they make thee [O Prophet] a judge of all on which they disagree among themselves, and then find in their hearts no bar to an acceptance of thy decision and give themselves up [to it] in utter self-surrender.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 65,
          "number": 558,
          "juz": 5,
          "numpage": 88
        }
      ]
    },
    {
      "87": [
        {
          "text": "It is they whom God has rejected: and he whom God rejects shall find none to succour him.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 52,
          "number": 545,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "Have they, perchance, a share in [God's] dominion? But [if they had], lo, they would not give to other people as much as [would fill] the groove of a date-stone!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 53,
          "number": 546,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "Do they, perchance, envy other people for what God has granted them out of His bounty? But then, We did grant revelation and wisdom unto the House of Abraham, and We did bestow on them a mighty dominion:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 54,
          "number": 547,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "and among them are such as [truly] believe in him, and among them are such as have turned away from him. And nothing could be as burning as [the fire of] hell:",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 55,
          "number": 548,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "for, verily, those who are bent on denying the truth of Our messages We shall, in time, cause to endure fire: [and] every time their skins are burnt off, We shall replace them with new skins, so that they may taste suffering [in full] Verily, God is almighty, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 56,
          "number": 549,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "But those who attain to faith and do righteous deeds We shall bring into gardens through which running waters flow, therein to abide beyond the count of time; there shall they have spouses pure: and [thus] We shall bring them unto happiness abounding.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 57,
          "number": 550,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "BEHOLD, God bids you to deliver all that you have been entrusted with unto those who are entitled thereto, and whenever you judge between people, to judge with justice. Verily, most excellent is what God exhorts you to do: verily, God is all-hearing, all-seeing!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 58,
          "number": 551,
          "juz": 5,
          "numpage": 87
        },
        {
          "text": "O you who have attained to faith! Pay heed unto God, and pay heed unto the Apostle and unto those from among you who have been entrusted with authority; and if you are at variance over any matter, refer it unto God and the Apostle, if you [truly] believe in God and the Last Day. This is the best [for you], and best in the end.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 59,
          "number": 552,
          "juz": 5,
          "numpage": 87
        }
      ]
    },
    {
      "86": [
        {
          "text": "But God knows best who are your enemies: and none can befriend as God does, and none can give succour as God does.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 45,
          "number": 538,
          "juz": 5,
          "numpage": 86
        },
        {
          "text": "Among those of the Jewish faith there are some who distort the meaning of the [revealed] words, taking them out of their context and saying, [as it were,] \"We have heard, but we disobey,\" and, \"Hear without hearkening,” and, “Hearken thou unto us, (O Muhammad)” - thus making a play with their tongues, and implying that the [true] Faith is false. And had they but said, \"We have heard, and we pay heed,\" and \"Hear [us], and have patience with us,\" it would indeed have been for their own good, and more upright: but God has rejected them because of their refusal to acknowledge the truth - for it is in but few things that they believe.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 46,
          "number": 539,
          "juz": 5,
          "numpage": 86
        },
        {
          "text": "O you who have been granted revelation [aforetime]! Believe in what We have [now] bestowed from on high in confirmation of whatever [of the truth] you already possess, lest We efface your hopes and bring them to an end - just as We rejected those people who broke the Sabbath: for God's will is always done.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 47,
          "number": 540,
          "juz": 5,
          "numpage": 86
        },
        {
          "text": "VERILY, God does not forgive the ascribing of divinity to aught beside Him, although He forgives any lesser sin unto whomever He wills: for he who ascribes divinity to aught beside God has indeed contrived an awesome sin.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 48,
          "number": 541,
          "juz": 5,
          "numpage": 86
        },
        {
          "text": "Art thou not aware of those who consider themselves pure? Nay, but it is God who causes whomever He wills to grow in purity; and none shall be wronged by as much as a hair's breadth.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 49,
          "number": 542,
          "juz": 5,
          "numpage": 86
        },
        {
          "text": "Behold how they attribute their own lying inventions to God - than which there is no sin more obvious.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 50,
          "number": 543,
          "juz": 5,
          "numpage": 86
        },
        {
          "text": "Art thou not aware of those who, having been granted their share of the divine writ, [now] believe in baseless mysteries and in the powers of evil, and maintain that those who are bent on denying the truth are more surely guided than those who have attained to faith?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 51,
          "number": 544,
          "juz": 5,
          "numpage": 86
        }
      ]
    },
    {
      "85": [
        {
          "text": "And [God does not love] those who spend their possessions on others [only] to be seen and praised by men, the while they believe neither in God nor in the Last Day; and he who has Satan for a soul-mate, how evil a soul-mate has he!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 38,
          "number": 531,
          "juz": 5,
          "numpage": 85
        },
        {
          "text": "And what would they have to fear if they would but believe in God and the Last Day, and spend [in His way] out of what God has granted them as sustenance - since God has indeed full knowledge of them?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 39,
          "number": 532,
          "juz": 5,
          "numpage": 85
        },
        {
          "text": "Verily, God does not wrong [anyone] by as much as an atom's weight; and if there be a good deed, He will multiply it, and will bestow out of His grace a mighty reward.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 40,
          "number": 533,
          "juz": 5,
          "numpage": 85
        },
        {
          "text": "How, then, [will the sinners fare on Judgment Day,] when We shall bring forward witnesses from within every community, and bring thee [O Prophet] as witness against them?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 41,
          "number": 534,
          "juz": 5,
          "numpage": 85
        },
        {
          "text": "Those who were bent on denying the truth and paid no heed to the Apostle will on that Day wish that the earth would swallow them: but they shall not [be able to] conceal from God anything that has happened.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 42,
          "number": 535,
          "juz": 5,
          "numpage": 85
        },
        {
          "text": "O YOU who have attained to faith! Do not attempt to pray while you are in a state of drunkenness, [but wait] until you know what you are saying; nor yet [while you are] in a state requiring total ablution, until you have bathed - except if you are travelling [and are unable to do so]. But if you are ill, or are travelling, or have just satisfied a want of nature, or have cohabited with a woman, and can find no water - then take resort to pure dust, passing [there­with] lightly over your face and your hands. Behold, God is indeed an absolver of sins, much-forgiving.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 43,
          "number": 536,
          "juz": 5,
          "numpage": 85
        },
        {
          "text": "ART THOU NOT aware of those who, having been granted their share of the divine writ, now barter it away for error, and want you [too] to lose your way?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 44,
          "number": 537,
          "juz": 5,
          "numpage": 85
        }
      ]
    },
    {
      "84": [
        {
          "text": "MEN SHALL take full care of women with the bounties which God has bestowed more abundantly on the former than on the latter, and with what they may spend out of their possessions. And the right­eous women are the truly devout ones, who guard the intimacy which God has [ordained to be] guar­ded. And as for those women whose ill-will you have reason to fear, admonish them [first]; then leave them alone in bed; then beat them; and if thereupon they pay you heed, do not seek to harm them. Behold, God is indeed most high, great!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 34,
          "number": 527,
          "juz": 5,
          "numpage": 84
        },
        {
          "text": "And if you have reason to fear that a breach might occur between a [married] couple, appoint an arbiter from among his people and an arbiter from among her people; if they both want to set things aright, God may bring about their reconciliation. Be­hold, God is indeed all-knowing, aware.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 35,
          "number": 528,
          "juz": 5,
          "numpage": 84
        },
        {
          "text": "AND WORSHIP God [alone], and do not ascribe divinity, in any way, to aught beside Him. And do good unto your parents, and near of kin, and unto orphans, and the needy, and the neighbour from among your own people, and the neighbour who is a stranger, and the friend by your side, and the wayfarer, and those whom you rightfully possess. Verily, God does not love any of those who, full of self-conceit, act in a boastful manner;",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 36,
          "number": 529,
          "juz": 5,
          "numpage": 84
        },
        {
          "text": "[nor] those who are niggardly, and bid others to be niggardly, and conceal whatever God has bestowed upon them out of His bounty; and so We have readied shameful suffering for all who thus deny the truth.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 37,
          "number": 530,
          "juz": 5,
          "numpage": 84
        }
      ]
    },
    {
      "83": [
        {
          "text": "And God wants to turn unto you in His mercy, whereas those who follow [only] their own lusts want you to drift far away from the right path.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 27,
          "number": 520,
          "juz": 5,
          "numpage": 83
        },
        {
          "text": "God wants to lighten your burdens: for man has been created weak.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 28,
          "number": 521,
          "juz": 5,
          "numpage": 83
        },
        {
          "text": "O YOU who have attained to faith! Do not devour one another's possessions wrongfully - not even by way of trade based on mutual agreement - and do not destroy one another: for, behold, God is indeed a dispenser of grace unto you!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 29,
          "number": 522,
          "juz": 5,
          "numpage": 83
        },
        {
          "text": "And as for him who does this with malicious intent and a will to do wrong - him shall We, in time, cause to endure [suffering through] fire: for this is indeed easy for God.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 30,
          "number": 523,
          "juz": 5,
          "numpage": 83
        },
        {
          "text": "If you avoid the great sins, which you have been enjoined to shun, We shall efface your [minor] bad deeds, and shall cause you to enter an abode of glory.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 31,
          "number": 524,
          "juz": 5,
          "numpage": 83
        },
        {
          "text": "Hence, do not covet the bounties which God has bestowed more abundantly on some of you than on others. Men shall have a benefit from what they earn, and women shall have a benefit from what they earn. Ask, therefore, God [to give you] out of His bounty: behold, God has indeed full knowledge of everything.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 32,
          "number": 525,
          "juz": 5,
          "numpage": 83
        },
        {
          "text": "And unto everyone have We appointed heirs to what he may leave behind: parents, and near kinsfolk, and those to whom you have pledged your troth give them, therefore, their share. Behold, God is indeed a witness unto everything.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 33,
          "number": 526,
          "juz": 5,
          "numpage": 83
        }
      ]
    },
    {
      "82": [
        {
          "text": "And [forbidden to you are] all married women other than those whom you rightfully possess [through wedlock]: this is God's ordinance, binding upon you. But lawful to you are all [women] beyond these, for you to seek out, offering them of your possessions, taking them in honest wedlock, and not in fornication. And unto those with whom you desire to enjoy marriage, you shall give the dowers due to them; but you will incur no sin if, after [having agreed upon] this lawful due, you freely agree with one another upon anything [else]: behold, God is indeed all-knowing, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 24,
          "number": 517,
          "juz": 5,
          "numpage": 82
        },
        {
          "text": "And as for those of you who, owing to cir­cumstances, are not in a position to marry free believing women, [let them marry] believing maidens from among those whom you rightfully possess. And God knows all about your faith; each one of you is an issue of the other. Marry them, then, with their people's leave, and give them their dowers in an equitable manner - they being women who give themselves in honest wedlock, not in fornication, nor as secret love-companions. And when they are married, and thereafter become guilty of immoral conduct, they shall be liable to half the penalty to which free married women are liable. This [permission to marry slave-girls applies] to those of you who fear lest they stumble into evil. But it is for your own good to persevere in patience [and to abstain from such marriages]: and God is much-forgiving, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 25,
          "number": 518,
          "juz": 5,
          "numpage": 82
        },
        {
          "text": "God wants to make [all this] clear unto you, and to guide you onto the [righteous] ways of life of those who preceded you, and to turn unto you in His mercy: for God is all-knowing, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 26,
          "number": 519,
          "juz": 5,
          "numpage": 82
        }
      ]
    },
    {
      "81": [
        {
          "text": "But if you desire to give up a wife and to take another in her stead, do not take away anything of what you have given the first one, however much it may have been. Would you, perchance, take it away by slandering her and thus committing a manifest sin?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 20,
          "number": 513,
          "juz": 4,
          "numpage": 81
        },
        {
          "text": "And how could you take it away after you have given yourselves to one another, and she has received a most solemn pledge from you?",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 21,
          "number": 514,
          "juz": 4,
          "numpage": 81
        },
        {
          "text": "AND DO NOT marry women whom your fathers have previously married - although what is past is past: this, verily, is a shameful deed, and a hateful thing, and an evil way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 22,
          "number": 515,
          "juz": 4,
          "numpage": 81
        },
        {
          "text": "Forbidden to you are your mothers, and your daughters, and your sisters, and your aunts paternal and maternal, and a brother's daughters, and a sister's daughters; and your milk-mothers, and your milk-sisters; and the mothers of your wives; and your step-daughters - who are your foster children - born of your wives with whom you have consummated your marriage; but if you have not consummated your marriage, you will incur no sin [by marrying their daughters]; and [forbidden to you are] the spouses of the sons who have sprung from your loins; and [you are forbidden] to have two sisters [as your wives] at one and the same time - but what is past is past: for, behold, God is indeed much-forgiving, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 23,
          "number": 516,
          "juz": 4,
          "numpage": 81
        }
      ]
    },
    {
      "80": [
        {
          "text": "AND AS FOR those of your women who become guilty of immoral conduct, call upon four from among you who have witnessed their guilt; and if these bear witness thereto, confine the guilty women to their houses until death takes them away or God opens for them a way [through repentance].",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 15,
          "number": 508,
          "juz": 4,
          "numpage": 80
        },
        {
          "text": "And punish [thus] both of the guilty parties; but if they both repent and mend their ways, leave them alone: for, behold, God is an acceptor of repentance, a dispenser of grace.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 16,
          "number": 509,
          "juz": 4,
          "numpage": 80
        },
        {
          "text": "Verily, God's acceptance of repentance relates only to those who do evil out of ignorance and then repent before their time runs out: and it is they unto whom God will turn again in His mercy - for God is all-knowing, wise;",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 17,
          "number": 510,
          "juz": 4,
          "numpage": 80
        },
        {
          "text": "whereas repentance shall not be accepted from those who do evil deeds until their dying hour and then say, \"Behold, I now repent\"; nor from those who die as deniers of the truth: it is these for whom We have readied grievous suffering.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 18,
          "number": 511,
          "juz": 4,
          "numpage": 80
        },
        {
          "text": "O YOU who have attained to faith! It is not lawful for you to [try to] become heirs to your wives [by holding onto them] against their will; and neither shall you keep them under constraint with a view to taking away anything of what you may have given them, unless it be that they have become guilty, in an obvious man­ner, of immoral conduct. And consort with your wives in a goodly manner; for if you dislike them, it may well be that you dislike something which God might yet make a source of abundant good.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 19,
          "number": 512,
          "juz": 4,
          "numpage": 80
        }
      ]
    },
    {
      "79": [
        {
          "text": "And you shall inherit one-half of what your wives leave behind, provided they have left no child; but if they have left a child, then you shall have one-quarter of what they leave behind, after [the deduction of] any bequest they may have made, or any debt [they may have incurred]. And your widows shall have one-quarter of what you leave behind, provided you have left no child; but if you have left a child, then they shall have one-eighth of what you leave behind, after [the deduction of] any bequest you may have made, or any debt [you may have incurred]. And if a man or a woman has no heir in the direct line, but has a brother or a sister, then each of these two shall inherit one-sixth; but if there are more than two, then they shall share in one-third [of the inheritance], after [the deduction of] any bequest that may have been made, or any debt [that may have been incurred], neither of which having been intended to harm [the heirs]. [This is] an injunction from God: and God is all-knowing, forbearing.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 12,
          "number": 505,
          "juz": 4,
          "numpage": 79
        },
        {
          "text": "These are the bounds set by God. And whoever pays heed unto God and His Apostle, him will He bring into gardens through which running waters flow, therein to abide: and this is a triumph supreme.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 13,
          "number": 506,
          "juz": 4,
          "numpage": 79
        },
        {
          "text": "And whoever rebels against God and His Apostle and transgresses His bounds, him will He commit unto fire, therein to abide; and shameful suffering awaits him.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 14,
          "number": 507,
          "juz": 4,
          "numpage": 79
        }
      ]
    },
    {
      "78": [
        {
          "text": "MEN SHALL have a share in what parents and kinsfolk leave behind, and women shall have a share in what parents and kinsfolk leave behind, whether it be little or much - a share ordained [by God].",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 7,
          "number": 500,
          "juz": 4,
          "numpage": 78
        },
        {
          "text": "And when [other] near of kin and orphans and needy persons are present at the distribution [of inheritance], give them something thereof for their sustenance, and speak unto them in a kindly way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 8,
          "number": 501,
          "juz": 4,
          "numpage": 78
        },
        {
          "text": "And let them stand in awe [of God], those [legal heirs] - who, if they [themselves] had to leave behind weak offspring, would feel fear on their account - and let them remain conscious of God, and let them speak [to the poor] in a just manner.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 9,
          "number": 502,
          "juz": 4,
          "numpage": 78
        },
        {
          "text": "Behold, those who sinfully devour the posses­sions of orphans but fill their bellies with fire: for [in the life to come] they will have to endure a blazing flame!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 10,
          "number": 503,
          "juz": 4,
          "numpage": 78
        },
        {
          "text": "CONCERNING [the inheritance of] your children, God enjoins [this] upon you: The male shall have the equal of two females’ share; but if there are more than two females, they shall have two-thirds of what [their parents] leave behind; and if there is only one daughter, she shall have one-half thereof. And as for the parents [of the deceased], each of them shall have one-sixth of what he leaves behind, in the event of his having [left] a child; but if he has left no child and his parents are his [only] heirs, then his mother shall have one-third; and if he has brothers and sisters, then his mother shall have one-sixth after [the deduction of] any bequest he may have made, or any debt [he may have incurred]. As for your parents and your children - you know not which of them is more deserving of benefit from you: [therefore this] ordinance from God. Verily, God is all-knowing, wise.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 11,
          "number": 504,
          "juz": 4,
          "numpage": 78
        }
      ]
    },
    {
      "77": [
        {
          "text": "O MANKIND! Be conscious of your Sustainer, who has created you out of one living entity, and out of it created its mate, and out of the two spread abroad a multitude of men and women. And remain conscious of God, in whose name you demand [your rights] from one another, and of these ties of kinship. Verily, God is ever watchful over you!",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 1,
          "number": 494,
          "juz": 4,
          "numpage": 77
        },
        {
          "text": "Hence, render unto the orphans their posses­sions, and do not substitute bad things [of your own] for the good things [that belong to them], and do not consume their possessions together with your own: this, verily, is a great crime.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 2,
          "number": 495,
          "juz": 4,
          "numpage": 77
        },
        {
          "text": "And if you have reason to fear that you might not act equitably towards orphans, then marry from among [other] women such as are lawful to you - [even] two, or three, or four: but if you have reason to fear that you might not be able to treat them with equal fairness, then [only] one - or [from among] those whom you rightfully possess. This will make it more likely that you will not deviate from the right course.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 3,
          "number": 496,
          "juz": 4,
          "numpage": 77
        },
        {
          "text": "And give unto women their marriage portions in the spirit of a gift; but if they, of their own accord, give up unto you aught thereof, then enjoy it with pleasure and good cheer.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 4,
          "number": 497,
          "juz": 4,
          "numpage": 77
        },
        {
          "text": "And do not entrust to those who are weak of judgment the possessions which God has placed in your charge for [their] support; but let them have their sustenance therefrom, and clothe them, and speak unto them in a kindly way.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 5,
          "number": 498,
          "juz": 4,
          "numpage": 77
        },
        {
          "text": "And test the orphans [in your charge] until they reach a marriage­able age; then, if you find them to be mature of mind, hand over to them their possessions; and do not consume them by wasteful spending, and in haste, ere they grow up. And let him who is rich abstain entirely [from his ward's property]; and let him who is poor partake thereof in a fair manner. And when you hand over to them their possessions, let there be witnesses on their behalf - although none can take count as God does.",
          "englishName": "An-Nisaa",
          "suraNumber": 4,
          "numberInSurah": 6,
          "number": 499,
          "juz": 4,
          "numpage": 77
        }
      ]
    },
    {
      "76": [
        {
          "text": "And thus does their Sustainer answer their prayer: \"I shall not lose sight of the labour of any of you who labours [in My way], be it man or woman: each of you is an issue of the other. Hence, as for those who forsake the domain of evil, and are driven from their homelands, and suffer hurt in My cause, and fight [for it], and are slain - I shall most certainly efface their bad deeds, and shall most certainly bring them into gardens through which running waters flow, as a reward from God: for with God is the most beauteous of rewards.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 195,
          "number": 488,
          "juz": 4,
          "numpage": 76
        },
        {
          "text": "LET IT NOT deceive thee that those who are bent on denying the truth seem to be able to do as they please on earth:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 196,
          "number": 489,
          "juz": 4,
          "numpage": 76
        },
        {
          "text": "it is [but] a brief enjoyment, with hell thereafter as their goal - and how vile a resting-place! -",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 197,
          "number": 490,
          "juz": 4,
          "numpage": 76
        },
        {
          "text": "whereas those who remain conscious of their Sustainer shall have gardens through which running waters flow, therein to abide: a ready welcome from God. And that which is with God is best for the truly virtuous.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 198,
          "number": 491,
          "juz": 4,
          "numpage": 76
        },
        {
          "text": "And, behold, among the followers of earlier revelation there are indeed such as [truly] believe in God, and in that which has been bestowed from on high upon you as well as in that which has been bestowed upon them. Standing in awe of God, they do not barter away God's messages for a trifling gain. They shall have their reward with their Sustainer - for, behold, God is swift in reckoning!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 199,
          "number": 492,
          "juz": 4,
          "numpage": 76
        },
        {
          "text": "O you who have attained to faith! Be patient in adversity, and vie in patience with one another, and be ever ready [to do what is right], and remain conscious of God, so that you might attain to a happy state!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 200,
          "number": 493,
          "juz": 4,
          "numpage": 76
        }
      ]
    },
    {
      "75": [
        {
          "text": "AND LO, God accepted a solemn pledge from those who were granted earlier revelation [when He bade them]: \"Make it known unto mankind, and do not conceal it!\" But they cast this [pledge] behind their backs, and bartered it away for a trifling gain: and how evil was their bargain!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 187,
          "number": 480,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "Think not that those who exult in what they have thus contrived, and who love to be praised for what they have not done - think not that they will escape suffering: for grievous suffering does await them [in the life to come].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 188,
          "number": 481,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "AND UNTO GOD belongs the dominion over the heavens and the earth: and God has the power to will anything.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 189,
          "number": 482,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "Verily, in the creation of the heavens and the earth, and in the succession of night and day, there are indeed messages for all who are endowed with insight,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 190,
          "number": 483,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "[and] who remember God when they stand, and when they sit, and when they lie down to sleep, and [thus] reflect on the creation of the heavens and the earth: \"O our Sustainer! Thou hast not created [aught of] this without meaning and purpose. Limitless art Thou in Thy glory! Keep us safe, then, from suffering through fire!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 191,
          "number": 484,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "\"O our Sustainer! Whomsoever Thou shalt commit to the fire, him, verily, wilt Thou have brought to disgrace [in this world]; and such evildoers will have none to succour them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 192,
          "number": 485,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "\"O our Sustainer! Behold, we heard a voice call [us] unto faith, `Believe in your Sustainer!' - and so we came to believe. O our Sustainer! Forgive us, then, our sins, and efface our bad deeds; and let us die the death of the truly virtuous!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 193,
          "number": 486,
          "juz": 4,
          "numpage": 75
        },
        {
          "text": "\"And, O our Sustainer, grant us that which Thou hast promised us through Thy apostles, and disgrace us not on Resurrection Day! Verily, Thou never failest to fulfil Thy promise!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 194,
          "number": 487,
          "juz": 4,
          "numpage": 75
        }
      ]
    },
    {
      "74": [
        {
          "text": "God has indeed heard the saying of those who said, \"Behold, God is poor while we are rich!\" We shall record what they have said, as well as their slaying of prophets against all right, and We shall say [unto them on Judgment Day]: \"Taste suffering through fire",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 181,
          "number": 474,
          "juz": 4,
          "numpage": 74
        },
        {
          "text": "in return for what your own hands have wrought - for never does God do the least wrong to His creatures!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 182,
          "number": 475,
          "juz": 4,
          "numpage": 74
        },
        {
          "text": "As for those who maintain, \"Behold, God has bidden us not to believe in any apostle unless he comes unto us with burnt offerings\" - say [unto them, O Prophet]: \"Even before me there came unto you apostles with all evidence of the truth, and with that whereof you speak: why, then, did you slay them, if what you say is true?\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 183,
          "number": 476,
          "juz": 4,
          "numpage": 74
        },
        {
          "text": "And if they give thee the lie - even so, before thy time, have [other] apostles been given the lie when they came with all evidence of the truth, and with books of divine wisdom, and with light-giving revelation.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 184,
          "number": 477,
          "juz": 4,
          "numpage": 74
        },
        {
          "text": "Every human being is bound to taste death: but only on the Day of Resurrection will you be requited in full [for whatever you have done] - whereupon he that shall be drawn away from the fire and brought into paradise will indeed have gained a triumph: for the life of this world is nothing but an enjoyment of self-delusion.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 185,
          "number": 478,
          "juz": 4,
          "numpage": 74
        },
        {
          "text": "You shall most certainly be tried in your possessions and in your persons; and indeed you shall hear many hurtful things from those to whom revelation was granted before your time, as well as from those who have come to ascribe divinity to other beings beside God. But if you remain patient in adversity and conscious of Him - this, behold, is something to set one's heart upon.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 186,
          "number": 479,
          "juz": 4,
          "numpage": 74
        }
      ]
    },
    {
      "73": [
        {
          "text": "- and returned [from the battle] with God's blessings and bounty, without having been touched by evil: for they had been striving after God's goodly acceptance - and God is limitless in His great bounty.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 174,
          "number": 467,
          "juz": 4,
          "numpage": 73
        },
        {
          "text": "It is but Satan who instils [into you] fear of his allies: so fear them not, but fear Me, if you are [truly] believers!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 175,
          "number": 468,
          "juz": 4,
          "numpage": 73
        },
        {
          "text": "And be not grieved by those who vie with one another in denying the truth: verily, they can in no wise harm God. It is God's will that they shall have no share in the [blessings of the] life to come; and tremendous suffering awaits them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 176,
          "number": 469,
          "juz": 4,
          "numpage": 73
        },
        {
          "text": "Verily, they who have bought a denial of the truth at the price of faith can in no wise harm God, whereas grievous suffering awaits them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 177,
          "number": 470,
          "juz": 4,
          "numpage": 73
        },
        {
          "text": "And they should not think - they who are bent on denying the truth - that Our giving them rein is good for them: We give them rein only to let them grow in sinfulness; and shameful suffering awaits them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 178,
          "number": 471,
          "juz": 4,
          "numpage": 73
        },
        {
          "text": "It is not God's will [O you who deny the truth] to abandon the believers to your way of life: [and] to that end He will set apart the bad from the good. And it is not God's will to give you insight into that which is beyond the reach of human perception: but [to that end] God elects whomsoever He wills from among His apostles. Believe, then, in God and His apostles; for if you believe and are conscious of Him, a magnificent requital awaits you.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 179,
          "number": 472,
          "juz": 4,
          "numpage": 73
        },
        {
          "text": "AND THEY should not think - they who niggardly cling to all that God has granted them out of His bounty - that this is good for them: nay, it is bad for them. That to which they [so] niggardly cling will, on the Day of Resurrection, be hung about their necks: for unto God [alone] belongs the heritage of the heavens and of the earth; and God is aware of all that you do.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 180,
          "number": 473,
          "juz": 4,
          "numpage": 73
        }
      ]
    },
    {
      "72": [
        {
          "text": "and all that befell you on the day when the two hosts met in battle happened by God's leave, so that He might mark out the [true] believers,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 166,
          "number": 459,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "and mark out those who were tainted with hypocrisy and, when they were told, \"Come, fight in God's cause\" - or, \"Defend yourselves\" - answered, \"If we but knew [that it would come to a] fight, we would indeed follow you.\" Unto apostasy were they nearer on that day than unto faith, uttering with their mouths something which was not in their hearts, the while God knew fully well what they were trying to conceal:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 167,
          "number": 460,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "they who, having themselves held back [from fighting, later] said of their [slain] brethren, \"Had they but paid heed to us, they would not have been slain.\" Say: \"Avert, then, death from yourselves, if what you say is true!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 168,
          "number": 461,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "But do not think of those that have been slain in God's cause as dead. Nay, they are alive! With their Sustainer have they their sustenance,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 169,
          "number": 462,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "exulting in that [martyrdom] which God has bestowed upon them out of His bounty. And they rejoice in the glad tiding given to those [of their brethren] who have been left behind and have not yet joined them, that no fear need they have, and neither shall they grieve:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 170,
          "number": 463,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "they rejoice in the glad tiding of God's blessings and bounty, and [in the promise] that God will not fail to requite the believers",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 171,
          "number": 464,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "who responded to the call of God and the Apostle after misfortune had befallen them. A magnificent requital awaits those of them who have persevered in doing good and remained conscious of God:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 172,
          "number": 465,
          "juz": 4,
          "numpage": 72
        },
        {
          "text": "those who have been warned by other people, \"Behold, a host has gathered against you; so beware of them!\" - whereupon this only increased their faith, so that they answered, \"God is enough for us; and how excellent a guardian is He!”",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 173,
          "number": 466,
          "juz": 4,
          "numpage": 72
        }
      ]
    },
    {
      "71": [
        {
          "text": "for, indeed, if you die or are slain, it will surely be unto God that you shall be gathered.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 158,
          "number": 451,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "And it was by God's grace that thou [O Prophet] didst deal gently with thy followers: for if thou hadst been harsh and hard of heart, they would indeed have broken away from thee. Pardon them, then, and pray that they be forgiven. And take counsel with them in all matters of public concern; then, when thou hast decided upon a course of action, place thy trust in God: for, verily, God loves those who place their trust in Him.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 159,
          "number": 452,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "If God succours you, none can ever overcome you; but if He should forsake you, who could succour you thereafter? In God, then, let the believers place their trust!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 160,
          "number": 453,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "AND IT IS not conceivable that a prophet should deceive - since he who deceives shall be faced with his deceit on the Day of Resurrection, when every human being shall be repaid in full for whatever he has done, and none shall be wronged.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 161,
          "number": 454,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "Is then he who strives after God's goodly acceptance like unto him who has earned the burden of God's condemnation and whose goal is hell? - and how vile a journey's end!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 162,
          "number": 455,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "They are on [entirely] different levels in the sight of God; for God sees all that they do.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 163,
          "number": 456,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "Indeed, God bestowed a favour upon the believers when he raised up in their midst an apostle from among themselves, to convey His messages unto them, and to cause them to grow in purity, and to impart unto them the divine writ as well as wisdom - whereas before that they were indeed, most obviously, lost in error.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 164,
          "number": 457,
          "juz": 4,
          "numpage": 71
        },
        {
          "text": "AND DO YOU, now that a calamity has befallen you after you had inflicted twice as much [on your foes], ask yourselves, \"How has this come about?\" Say: \"It has come from your own selves.\" Verily, God has the power to will anything:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 165,
          "number": 458,
          "juz": 4,
          "numpage": 71
        }
      ]
    },
    {
      "70": [
        {
          "text": "Then, after this woe, He sent down upon you a sense of security, an inner calm which enfolded some of you, whereas the others, who cared mainly for themselves, entertained wrong thoughts about God - thoughts of pagan ignorance - saying, \"Did we, then, have any power of decision [in this matter]?\" Say: \"Verily, all power of decision does rest with God\" - [but as for them,] they are trying to conceal within themselves that [weakness of faith] which they would not reveal unto thee, [O Prophet, by] saying, \"If we had any power of decision, we would not have left so many dead behind.\" Say [unto them]: \"Even if you had remained in your homes, those [of you] whose death had been ordained would indeed have gone forth to the places where they were destined to lie down.\" And [all this befell you] so that God might put to a test all that you harbour in your bosoms, and render your innermost hearts pure of all dross: for God is aware of what is in the hearts [of men].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 154,
          "number": 447,
          "juz": 4,
          "numpage": 70
        },
        {
          "text": "Behold, as for those of you who turned away [from their duty] on the day when the two hosts met in battle - Satan caused them to stumble only by means of something that they [themselves] had done. But now God has effaced this sin of theirs: verily, God is much-forgiving, forbearing.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 155,
          "number": 448,
          "juz": 4,
          "numpage": 70
        },
        {
          "text": "O you who have attained to faith! Be not like those who are bent on denying the truth and say of their brethren [who die] after having set out on a journey to faraway places or gone forth to war, \"Had they but remained with us, they would not have died,\" or, \"they would not have been slain\" - for God will cause such thoughts to become a source of bitter regret in their hearts, since it is God who grants life and deals death. And God sees all that you do.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 156,
          "number": 449,
          "juz": 4,
          "numpage": 70
        },
        {
          "text": "And if indeed you are slain or die in God's cause, then surely forgiveness from God and His grace are better than all that one could amass [in this world]:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 157,
          "number": 450,
          "juz": 4,
          "numpage": 70
        }
      ]
    },
    {
      "69": [
        {
          "text": "O YOU who have attained to faith! If you pay heed to those who are bent on denying the truth, they will cause you to turn back on your heels, and you will be the losers.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 149,
          "number": 442,
          "juz": 4,
          "numpage": 69
        },
        {
          "text": "Nay, but God alone is your Lord Supreme, and His is the best succour.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 150,
          "number": 443,
          "juz": 4,
          "numpage": 69
        },
        {
          "text": "Into the hearts of those who are bent on denying the truth We shall cast dread in return for their ascribing divinity, side by side with God, to other beings - [something] for which He has never bestowed any warrant from on high; and their goal is the fire - and how evil that abode of evildoers!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 151,
          "number": 444,
          "juz": 4,
          "numpage": 69
        },
        {
          "text": "AND, INDEED, God made good His promise unto you when, by His leave, you were about to destroy your foes - until the moment when you lost heart and acted contrary to the [Prophet's] command, and disobeyed after He had brought you within view of that [victory] for which you were longing. There were among you such as cared for this world [alone], just as there were among you such as cared for the life to come: whereupon, in order that He might put you to a test, He prevented you from defeating your foes. But now He has effaced your sin: for God is limitless in His bounty unto the believers.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 152,
          "number": 445,
          "juz": 4,
          "numpage": 69
        },
        {
          "text": "[Remember the time] when you fled, paying no heed to anyone, while at your rear the Apostle was calling out to you - wherefore He requited you with woe in return for [the Apostle's] woe, so that you should not grieve [merely] over what had escaped you, nor over what had befallen you: for God is aware of all that you do.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 153,
          "number": 446,
          "juz": 4,
          "numpage": 69
        }
      ]
    },
    {
      "68": [
        {
          "text": "and that God might render pure of all dross those who have attained to faith, and bring to nought those who deny the truth.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 141,
          "number": 434,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "Do you think that you could enter paradise unless God takes cognizance of your having striven hard [in His cause], and takes cognizance of your having been patient in adversity?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 142,
          "number": 435,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "For, indeed, you did long for death [in God’s cause] before you came face to face with it; and now you have seen it with your own eyes!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 143,
          "number": 436,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "AND MUHAMMAD is only an apostle; all the [other] apostles have passed away before him: if, then, he dies or is slain, will you turn about on your heels? But he that turns about on his heels can in no wise harm God - whereas God will requite all who are grateful [to Him].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 144,
          "number": 437,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "And no human being can die save by God's leave, at a term pre-ordained. And if one desires the rewards of this world, We shall grant him thereof; and if one desires the rewards of the life to come, We shall grant him thereof; and We shall requite those who are grateful [to Us].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 145,
          "number": 438,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "And how many a prophet has had to fight [in God's cause], followed by many God-devoted men: and they did not become faint of heart for all that they had to suffer in God's cause, and neither did they weaken, nor did they abase themselves [before the enemy], since God loves those who are patient in adversity;",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 146,
          "number": 439,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "and all that they said was this: \"O our Sustainer! Forgive us our sins and the lack of moderation in our doings! And make firm our steps, and succour us against people who deny the truth!\" -",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 147,
          "number": 440,
          "juz": 4,
          "numpage": 68
        },
        {
          "text": "whereupon God granted them the rewards of this world, as well as the goodliest rewards of the life to come: for God loves the doers of good.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 148,
          "number": 441,
          "juz": 4,
          "numpage": 68
        }
      ]
    },
    {
      "67": [
        {
          "text": "And vie with one another to attain to your Sustainer's forgiveness and to a paradise as vast as the heavens and the earth, which has been readied for the God-conscious",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 133,
          "number": 426,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "who spend [in His way] in time of plenty and in time of hardship, and hold in check their anger, and pardon their fellow-men because God loves the doers of good;",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 134,
          "number": 427,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "and who, when they have committed a shameful deed or have [otherwise] sinned against themselves, remember God and pray that their sins be forgiven - for who but God could forgive sins? - and do not knowingly persist in doing whatever [wrong] they may have done.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 135,
          "number": 428,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "These it is who shall have as their reward forgiveness from their Sustainer, and gardens through which running waters flow, therein to abide: and how excellent a reward for those who labour!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 136,
          "number": 429,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "[MANY] WAYS of life have passed away before your time. Go, then, about the earth and behold what happened in the end to those who gave the lie to the truth:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 137,
          "number": 430,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "this [should be] a clear lesson unto all men, and a guidance and an admonition unto the God-conscious.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 138,
          "number": 431,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "Be not, then, faint of heart, and grieve not: for you are bound to rise high if you are [truly] believers.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 139,
          "number": 432,
          "juz": 4,
          "numpage": 67
        },
        {
          "text": "If misfortune touches you, [know that] similar misfortune has touched [other] people as well; for it is by turns that We apportion unto men such days [of fortune and misfortune]: and [this] to the end that God might mark out those who have attained to faith, and choose from among you such as [with their lives] bear witness to the truth - since God does not love evildoers -",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 140,
          "number": 433,
          "juz": 4,
          "numpage": 67
        }
      ]
    },
    {
      "66": [
        {
          "text": "when two groups from among you were about to lose heart, although God was near unto them and it is in God that the believers must place their trust:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 122,
          "number": 415,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "for, indeed, God did succour you at Badr, when you were utterly weak. Remain, then, conscious of God, so that you might have cause to be grateful.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 123,
          "number": 416,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "[And remember] when thou didst say unto the believers: \"Is it not enough for you [to know] that your Sustainer will aid you with three thousand angels sent down [from on high]?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 124,
          "number": 417,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "Nay, but if you are patient in adversity and conscious of Him, and the enemy should fall upon you of a sudden, your Sustainer will aid you with five thousand angels swooping down!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 125,
          "number": 418,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "And God ordained this [to be said by His Apostle] only as a glad tiding for you, and that your hearts should thereby be set at rest - since no succour can come from any save God, the Almighty, the Truly Wise -",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 126,
          "number": 419,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "[and] that [through you] He might destroy some of those who were bent on denying the truth, and so abase the others that they would withdraw in utter hopelessness.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 127,
          "number": 420,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "[And] it is in no wise for thee [O Prophet] to decide whether He shall accept their repentance or chastise them - for, behold, they are but wrongdoers,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 128,
          "number": 421,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "whereas unto God belongs all that is in the heavens and all that is on earth: He forgives whom He wills, and He chastises whom He wills; and God is much-forgiving, a dispenser of grace.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 129,
          "number": 422,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "O YOU who have attained to faith! Do not gorge yourselves on usury, doubling and re-doubling it - but remain conscious of God, so that you might attain to a happy state;",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 130,
          "number": 423,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "and beware of the fire which awaits those who deny the truth!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 131,
          "number": 424,
          "juz": 4,
          "numpage": 66
        },
        {
          "text": "And pay heed unto God and the Apostle, so that you might be graced with mercy.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 132,
          "number": 425,
          "juz": 4,
          "numpage": 66
        }
      ]
    },
    {
      "65": [
        {
          "text": "[But,] behold, as for those who are bent on denying the truth - neither their worldly possessions nor their children will in the least avail them against God: and it is they who are destined for the fire, therein to abide.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 116,
          "number": 409,
          "juz": 4,
          "numpage": 65
        },
        {
          "text": "The parable of what they spend on the life of this world is that of an icy wind which smites the tilth of people who have sinned against themselves, and destroys it: for, it is not God who does them wrong, but it is they who are wronging themselves.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 117,
          "number": 410,
          "juz": 4,
          "numpage": 65
        },
        {
          "text": "O YOU who have attained to faith! Do not take for your bosom-friends people who are not of your kind. They spare no effort to corrupt you; they would love to see you in distress. Vehement hatred has already come into the open from out of their mouths, but what their hearts conceal is yet worse. We have indeed made the signs [thereof] clear unto you, if you would but use your reason.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 118,
          "number": 411,
          "juz": 4,
          "numpage": 65
        },
        {
          "text": "Lo! It is you who [are prepared to] love them, but they will not love you, although you believe in all of the revelation. And when they meet you, they assert, \"We believe [as you believe]\"; but when they find themselves alone, they gnaw their fingers in rage against you. Say: \"Perish in your rage! Behold, God has full knowledge of what is in the hearts [of men]!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 119,
          "number": 412,
          "juz": 4,
          "numpage": 65
        },
        {
          "text": "If good fortune comes to you, it grieves them; and if evil befalls you, they rejoice in it. But if you are patient in adversity and conscious of God, their guile cannot harm you at all: for, verily, God encompasses [with His might] all that they do.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 120,
          "number": 413,
          "juz": 4,
          "numpage": 65
        },
        {
          "text": "AND [remember, O Prophet, the day] when thou didst set out from thy home at early morn to place the believers in battle array. And God was all-hearing, all-knowing",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 121,
          "number": 414,
          "juz": 4,
          "numpage": 65
        }
      ]
    },
    {
      "64": [
        {
          "text": "And unto God belongs all that is in the heavens and all that is on earth; and all things go back to God [as their source].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 109,
          "number": 402,
          "juz": 4,
          "numpage": 64
        },
        {
          "text": "YOU ARE indeed the best community that has ever been brought forth for [the good of] mankind: you enjoin the doing of what is right and forbid the doing of what is wrong, and you believe in God. Now if the followers of earlier revelation had attained to [this kind of] faith, it would have been for their own good; [but only few] among them are believers, while most of them are iniquitous:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 110,
          "number": 403,
          "juz": 4,
          "numpage": 64
        },
        {
          "text": "[but] these can never inflict more than a passing hurt on you; and if they fight against you, they will turn their backs upon you [in flight], and will not be succoured.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 111,
          "number": 404,
          "juz": 4,
          "numpage": 64
        },
        {
          "text": "Overshadowed by ignominy are they wherever they may be, save [when they bind themselves again] in a bond with God and a bond with men; for they have earned the burden of God's condemnation, and are overshadowed by humiliation: all this [has befallen them] because they persisted in denying the truth of God's messages and in slaying the prophets against all right: all this, because they rebelled [against God], and persisted in transgressing the bounds of what is right.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 112,
          "number": 405,
          "juz": 4,
          "numpage": 64
        },
        {
          "text": "[But] they are not all alike: among the followers of earlier revelation there are upright people, who recite God's messages throughout the night, and prostrate themselves [before Him].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 113,
          "number": 406,
          "juz": 4,
          "numpage": 64
        },
        {
          "text": "They believe in God and the Last Day, and enjoin the doing of what is right and forbid the doing of what is wrong, and vie with one another in doing good works: and these are among the righteous.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 114,
          "number": 407,
          "juz": 4,
          "numpage": 64
        },
        {
          "text": "And whatever good they do, they shall never be denied the reward thereof: for, God has full knowledge of those who are conscious of Him.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 115,
          "number": 408,
          "juz": 4,
          "numpage": 64
        }
      ]
    },
    {
      "63": [
        {
          "text": "And how could you deny the truth when it is unto you that God's messages are being conveyed, and it is in your midst that His Apostle lives? But he who holds fast unto God has already been guided onto a straight way.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 101,
          "number": 394,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "O you who have attained to faith! Be conscious of God with all the consciousness that is due to Him, and do not allow death to overtake you ere you have surrendered yourselves unto Him.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 102,
          "number": 395,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "And hold fast, all together, unto the bond with God, and do not draw apart from one another. And remember the blessings which God has bestowed upon you: how, when you were enemies, He brought your hearts together, so that through His blessing you became brethren; and [how, when] you were on the brink of a fiery abyss. He saved you from it. In this way God makes clear His messages unto you, so that you might find guidance,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 103,
          "number": 396,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "and that there might grow out of you a community [of people] who invite unto all that is good, and enjoin the doing of what is right and forbid the doing of what is wrong: and it is they, they who shall attain to a happy state!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 104,
          "number": 397,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "And be not like those who have drawn apart from one another and have taken to conflicting views after all evidence of the truth has come unto them: for these it is for whom tremendous suffering is in store",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 105,
          "number": 398,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "on the Day [of Judgment] when some faces will shine [with happiness] and some faces will be dark [with grief]. And as for those with faces darkened, [they shall be told:] \"Did you deny the truth after having attained to faith? Taste, then, this suffering for having denied the truth!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 106,
          "number": 399,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "But as for those with faces shining, they shall be within God's grace, therein to abide.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 107,
          "number": 400,
          "juz": 4,
          "numpage": 63
        },
        {
          "text": "These are God's messages: We convey them unto thee, setting forth the truth, since God wills no wrong to His creation.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 108,
          "number": 401,
          "juz": 4,
          "numpage": 63
        }
      ]
    },
    {
      "62": [
        {
          "text": "[But as for you, O believers,] never shall you attain to true piety unless you spend on others out of what you cherish yourselves; and whatever you spend - verily, God has full knowledge thereof.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 92,
          "number": 385,
          "juz": 3,
          "numpage": 62
        },
        {
          "text": "ALL FOOD was lawful unto the children of Israel, save what Israel had made unlawful unto itself [by its sinning] before the Torah was bestowed from on high. Say: \"Come forward, then, with the Torah and recite it, if what you say is true!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 93,
          "number": 386,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "And all who henceforth invent lies about God - it is they, they who are evildoers!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 94,
          "number": 387,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "Say: \"God has spoken the truth: follow, then, the creed of Abraham, who turned away from all that is false, and was not of those who ascribe divinity to aught beside God.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 95,
          "number": 388,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "Behold, the first Temple ever set up for mankind was indeed the one at Bakkah: rich in blessing, and a [source of] guidance unto all the worlds,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 96,
          "number": 389,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "full of clear messages. [It is] the place whereon Abraham once stood; and whoever enters it finds inner peace. Hence, pilgrimage unto the Temple is a duty owed to God by all people who are able to undertake it. And as for those who deny the truth - verily, God does not stand in need of anything in all the worlds.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 97,
          "number": 390,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "SAY: \"O followers of earlier revelation! Why do you refuse to acknowledge the truth of God's messages, when God is witness to all that you do?\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 98,
          "number": 391,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "Say: \"O followers of earlier revelation! Why do you [endeavour to] bar those who have come to believe [in this divine writ] from the path of God by trying to make it appear crooked, when you yourselves bear witness [to its being straight]? For, God is not unaware of what you do.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 99,
          "number": 392,
          "juz": 4,
          "numpage": 62
        },
        {
          "text": "O you who have attained to faith! If you pay heed to some of those to whom revelation was vouchsafed aforetime, they might cause you to renounce the truth after you have come to believe [in it].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 100,
          "number": 393,
          "juz": 4,
          "numpage": 62
        }
      ]
    },
    {
      "61": [
        {
          "text": "Say: \"We believe in God, and in that which has been bestowed from on high upon us, and that which has been bestowed upon Abraham and Ishmael and Isaac and Jacob and their descendants, and that which has been vouchsafed by their Sustainer unto Moses and Jesus and all the [other] prophets: we make no distinction between any of them. And unto Him do we surrender ourselves.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 84,
          "number": 377,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "For, if one goes in search of a religion other than self-surrender unto God, it will never be accepted from him, and in the life to come he shall be among the lost.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 85,
          "number": 378,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "How would God bestow His guidance upon people who have resolved to deny the truth after having attained to faith, and having borne witness that this Apostle is true, and [after] all evidence of the truth has come unto them? For, God does not guide such evildoing folk.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 86,
          "number": 379,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "Their requital shall be rejection by God, and by the angels, and by all [righteous] men.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 87,
          "number": 380,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "In this state shall they abide; [and] neither will their suffering be lightened, nor will they be granted respite.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 88,
          "number": 381,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "But excepted shall be they that afterwards repent and put themselves to rights: for, behold, God is much-forgiving, a dispenser of grace.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 89,
          "number": 382,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "Verily, as for those who are bent on denying the truth after having attained to faith, and then grow [ever more stubborn] in their refusal to acknowledge the truth, their repentance [of other sins] shall not be accepted: for it is they who have truly gone astray.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 90,
          "number": 383,
          "juz": 3,
          "numpage": 61
        },
        {
          "text": "Verily, as for those who are bent on denying the truth and die as deniers of the truth - not all the gold on earth could ever be their ransom. It is they for whom grievous suffering is in store; and they shall have none to succour them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 91,
          "number": 384,
          "juz": 3,
          "numpage": 61
        }
      ]
    },
    {
      "60": [
        {
          "text": "And, behold, there are indeed some among them who distort the Bible with their tongues, so as to make you think that [what they say] is from the Bible, the while it is not from the Bible; and who say, \"This is from God,\" the while it is not from God: and thus do they tell a lie about God, being well aware [that it is a lie].",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 78,
          "number": 371,
          "juz": 3,
          "numpage": 60
        },
        {
          "text": "It is not conceivable that a human being unto whom God had granted revelation, and sound judgment, and prophethood, should thereafter have said unto people, \"Worship me beside God\"; but rather [did he exhort them], \"Become men of God by spreading the knowledge of the divine writ, and by your own deep study [thereof].\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 79,
          "number": 372,
          "juz": 3,
          "numpage": 60
        },
        {
          "text": "And neither did he bid you to take the angels and the prophets for your lords: [for] would he bid you to deny the truth after you have surrendered yourselves unto God?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 80,
          "number": 373,
          "juz": 3,
          "numpage": 60
        },
        {
          "text": "AND, LO, God accepted, through the prophets, this solemn pledge [from the followers of earlier revelation]: \"If, after all the revelation and the wisdom which I have vouchsafed unto you, there comes to you an apostle confirming the truth already in your possession, you must believe in him and succour him. Do you\" - said He - \"acknowledge and accept My bond on this condition?\" They answered: \"We do acknowledge it.\" Said He: \"Then bear witness [thereto], and I shall be your witness.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 81,
          "number": 374,
          "juz": 3,
          "numpage": 60
        },
        {
          "text": "And, henceforth, all who turn away [from this pledge] - it is they, they who are truly iniquitous!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 82,
          "number": 375,
          "juz": 3,
          "numpage": 60
        },
        {
          "text": "Do they seek, perchance, a faith other than in God, although it is unto Him that whatever is in the heavens and on earth surrenders itself, willingly or unwillingly, since unto Him all must return?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 83,
          "number": 376,
          "juz": 3,
          "numpage": 60
        }
      ]
    },
    {
      "59": [
        {
          "text": "O followers of earlier revelation! Why do you cloak the truth with falsehood and conceal the truth of which you are [so well] aware?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 71,
          "number": 364,
          "juz": 3,
          "numpage": 59
        },
        {
          "text": "And some of the followers of earlier revelation say [to one another]: \"Declare your belief in what has been revealed unto those who believe [in Muhammad] at the beginning of the day, and deny the truth of what came later, so that they might go back [on their faith];",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 72,
          "number": 365,
          "juz": 3,
          "numpage": 59
        },
        {
          "text": "but do not [really] believe anyone who does not follow your own faith.\" Say: \"Behold, all [true] guidance is God's guidance, consisting in one's being granted [revelation] such as you have been granted.\" Or would they contend against you before your Sustainer? Say: \"Behold, all bounty is in the hand of God; He grants it unto whom He wills: for God is infinite, all-knowing,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 73,
          "number": 366,
          "juz": 3,
          "numpage": 59
        },
        {
          "text": "singling out for His grace whom He wills. And God is limitless in His great bounty.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 74,
          "number": 367,
          "juz": 3,
          "numpage": 59
        },
        {
          "text": "AND AMONG the followers of earlier revelation there is many a one who, if thou entrust him with a treasure, will [faithfully] restore it to thee; and there is among them many a one who, if thou entrust him with a tiny gold coin, will not restore it to thee unless thou keep standing over him - which is an outcome of their assertion, \"No blame can attach to us [for anything that we may do] with regard to these unlettered folk\": and [so] they tell a lie about God, being well aware [that it is a lie].\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 75,
          "number": 368,
          "juz": 3,
          "numpage": 59
        },
        {
          "text": "Nay, but [God is aware of] those who keep their bond with Him, and are conscious of Him: and, verily, God loves those who are conscious of Him.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 76,
          "number": 369,
          "juz": 3,
          "numpage": 59
        },
        {
          "text": "Behold, those who barter away their bond with God and their own pledges for a trifling gain - they shall not partake in the blessings of the life to come; and God will neither speak unto them nor look upon them on the Day of Resurrection, nor will He cleanse them of their sins; and grievous suffering awaits them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 77,
          "number": 370,
          "juz": 3,
          "numpage": 59
        }
      ]
    },
    {
      "58": [
        {
          "text": "Behold, this is indeed the truth of the matter, and there is no deity whatever save God; and, verily, God - He alone - is almighty, truly wise.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 62,
          "number": 355,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "And if they turn away [from this truth] - behold, God has full knowledge of the spreaders of corruption.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 63,
          "number": 356,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "Say: \"O followers of earlier revelation! Come unto that tenet which we and you hold in common: that we shall worship none but God, and that we shall not ascribe divinity to aught beside Him, and that we shall not take human beings for our lords beside God.\" And if they turn away, then say: \"Bear witness that it is we who have surrendered ourselves unto Him.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 64,
          "number": 357,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "O FOLLOWERS of earlier revelation! Why do you argue about Abraham, seeing that the Torah and the Gospel were not revealed till [long] after him? Will you not, then, use your reason?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 65,
          "number": 358,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "Lo! You are the ones who would argue about that which is known to you; but why do you argue about something which is unknown to you? Yet God knows [it], whereas you do not know:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 66,
          "number": 359,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "Abraham was neither a \"Jew\" nor a \"Christian\", but was one who turned away from all that is false, having surrendered himself unto God; and he was not of those who ascribe divinity to aught beside Him.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 67,
          "number": 360,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "Behold, the people who have the best claim to Abraham are surely those who follow him - as does this Prophet and all who believe [in him] - and God is near unto the believers.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 68,
          "number": 361,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "Some of the followers of earlier revelation would love to lead you astray: yet none do they lead astray but themselves, and perceive it not.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 69,
          "number": 362,
          "juz": 3,
          "numpage": 58
        },
        {
          "text": "O followers of earlier revelation! Why do you deny the truth of God's messages to which you yourselves bear witness?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 70,
          "number": 363,
          "juz": 3,
          "numpage": 58
        }
      ]
    },
    {
      "57": [
        {
          "text": "O our Sustainer! We believe in what Thou hast bestowed from on high, and we follow this Apostle; make us one, then, with all who bear witness [to the truth]!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 53,
          "number": 346,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "And the unbelievers schemed [against Jesus]; but God brought their scheming to nought: for God is above all schemers.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 54,
          "number": 347,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "Lo! God said: \"O Jesus! Verily, I shall cause thee to die, and shall exalt thee unto Me, and cleanse thee of [the presence of] those who are bent on denying the truth; and I shall place those who follow thee [far] above those who are bent on denying the truth, unto the Day of Resurrection. In the end, unto Me you all must return, and I shall judge between you with regard to all on which you were wont to differ.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 55,
          "number": 348,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "\"And as for those who are bent on denying the truth, I shall cause them to suffer a suffering severe in this world and in the life to come, and they shall have none to succour them;",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 56,
          "number": 349,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "whereas unto those who attain to faith and do good works He will grant their reward in full: for God does not love evildoers.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 57,
          "number": 350,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "THIS MESSAGE do We convey unto thee, and this tiding full of wisdom:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 58,
          "number": 351,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "Verily, in the sight of God, the nature of Jesus is as the nature of Adam, whom He created out of dust and then said unto him, \"Be\" - and he is.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 59,
          "number": 352,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "[This is] the truth from thy Sustainer; be not, then, among the doubters!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 60,
          "number": 353,
          "juz": 3,
          "numpage": 57
        },
        {
          "text": "And if anyone should argue with thee about this [truth] after all the knowledge that has come unto thee, say: \"Come! Let us summon our sons and your sons, and our women and your women, and ourselves and yourselves; and then let us pray [together] humbly and ardently, and let us invoke God's curse upon those [of us] who are telling a lie.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 61,
          "number": 354,
          "juz": 3,
          "numpage": 57
        }
      ]
    },
    {
      "56": [
        {
          "text": "And he shall speak unto men in his cradle, and as a grown man, and shall be of the righteous.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 46,
          "number": 339,
          "juz": 3,
          "numpage": 56
        },
        {
          "text": "Said she: \"O my Sustainer! How can I have a son when no man has ever touched me?\" [The angel] answered: \"Thus it is: God creates what He wills when He wills a thing to be, He but says unto it, 'Be' - and it is.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 47,
          "number": 340,
          "juz": 3,
          "numpage": 56
        },
        {
          "text": "And he will impart unto thy son revelation, and wisdom, and the Torah, and the Gospel,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 48,
          "number": 341,
          "juz": 3,
          "numpage": 56
        },
        {
          "text": "and [will make him] an apostle unto the children of Israel.” \"I HAVE COME unto you with a message from your Sustainer. I shall create for you out of clay, as it were, the shape of [your] destiny, and then breathe into it, so that it might become [your] destiny by God's leave; and I shall heal the blind and the leper, and bring the dead back to life by God's leave; and I shall let you know what you may eat and what you should store up in your houses. Behold, in all this there is indeed a message for you, if you are [truly] believers.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 49,
          "number": 342,
          "juz": 3,
          "numpage": 56
        },
        {
          "text": "\"And [I have come] to confirm the truth of whatever there still remains of the Torah, and to make lawful unto you some of the things which [aforetime] were forbidden to you. And I have come unto you with a message from your Sustainer; remain, then, conscious of God, and pay heed unto me.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 50,
          "number": 343,
          "juz": 3,
          "numpage": 56
        },
        {
          "text": "\"Verily, God is my Sustainer as well as your Sustainer; so worship Him [alone]: this is a straight way.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 51,
          "number": 344,
          "juz": 3,
          "numpage": 56
        },
        {
          "text": "And when Jesus became aware of their refusal to acknowledge the truth, he asked: \"Who will be my helpers in God's cause?\" The white-garbed ones replied: \"We shall be [thy] helpers [in the cause] of God! We believe in God: and bear thou witness that we have surrendered ourselves unto Him!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 52,
          "number": 345,
          "juz": 3,
          "numpage": 56
        }
      ]
    },
    {
      "55": [
        {
          "text": "In that self-same place, Zachariah prayed unto his Sustainer, saying: \"O my Sustainer! Bestow upon me [too], out of Thy grace, the gift of goodly offspring; for Thou, indeed, hearest all prayer.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 38,
          "number": 331,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "Thereupon, as he stood praying in the sanctuary, the angels called out unto him: \"God sends thee the glad tiding of [the birth of] John, who shall confirm the truth of a word from God, and [shall be] outstanding among men, and utterly chaste, and a prophet from among the righteous.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 39,
          "number": 332,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "[Zachariah] exclaimed: \"O my Sustainer! How can I have a son when old age has already overtaken me, and my wife is barren?\" Answered [the angel]: \"Thus it is: God does what He wills.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 40,
          "number": 333,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "[Zachariah] prayed: \"O my Sustainer! Appoint a sign for me!\" Said [the angel]: \"Thy sign shall be that for three days thou wilt not speak unto men other than by gestures. And remember thy Sustainer unceasingly, and extol His limitless glory by night and by day.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 41,
          "number": 334,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "AND LO! The angels said: \"O Mary! Behold, God has elected thee and made thee pure, and raised thee above all the women of the world.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 42,
          "number": 335,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "O Mary! Remain thou truly devout unto thy Sustainer, and prostrate thyself in worship, and bow down with those who bow down [before Him].\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 43,
          "number": 336,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "This account of something that was beyond the reach of thy perception We [now] reveal unto thee: for thou wert not with them when they drew lots as to which of them should be Mary's guardian, and thou wert not with them when they contended [about it] with one another.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 44,
          "number": 337,
          "juz": 3,
          "numpage": 55
        },
        {
          "text": "Lo! The angels said: \"O Mary! Behold, God sends thee the glad tiding, through a word from Him, [of a son] who shall become known as the Christ Jesus, son of Mary, of great honour in this world and in the life to come, and [shall be] of those who are drawn near unto God.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 45,
          "number": 338,
          "juz": 3,
          "numpage": 55
        }
      ]
    },
    {
      "54": [
        {
          "text": "On the Day when every human being will find himself faced with all the good that he has done, and with all the evil that he has done, [many a one] will wish that there were a long span of time between himself and that [Day]. Hence, God warns you to beware of Him; but God is most compassionate towards His creatures.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 30,
          "number": 323,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "Say [O Prophet]: \"If you love God, follow me, [and] God will love you and forgive you your sins; for God is much-forgiving, a dispenser of grace.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 31,
          "number": 324,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "Say: “Pay heed unto God and the Apostle.” And if they turn away - verely, God does not love those who deny the truth.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 32,
          "number": 325,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "BEHOLD, God raised Adam, and Noah, and the House of Abraham, and the House of `Imran above all mankind,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 33,
          "number": 326,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "in one line of descent. And God was all-hearing, all-knowing",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 34,
          "number": 327,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "when a woman of [the House of] `Imran prayed: \"O my Sustainer! Behold, unto Thee do I vow [the child] that is in, my womb, to be devoted to Thy service. Accept it, then, from me: verily, Thou alone art all-hearing, all-knowing!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 35,
          "number": 328,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "But when she had given birth to the child, she said: \"O my Sustainer! Behold, I have given birth to a female\" - the while God had been fully aware of what she would give birth to, and [fully aware] that no male child [she might have hoped for] could ever have been like this female - \"and I have named her Mary. And, verily, I seek Thy protection for her and her offspring against Satan, the accursed.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 36,
          "number": 329,
          "juz": 3,
          "numpage": 54
        },
        {
          "text": "And thereupon her Sustainer accepted the girl-child with goodly acceptance, and caused her to grow up in goodly growth, and placed her in the care of Zachariah. Whenever Zachariah visited her in the sanctuary, he found her provided with food. He would ask: \"O Mary, whence came this unto thee?\" She would answer: \"It is from God; behold, God grants sustenance unto whom He wills, beyond all reckoning.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 37,
          "number": 330,
          "juz": 3,
          "numpage": 54
        }
      ]
    },
    {
      "53": [
        {
          "text": "Art thou not aware of those who have been granted their share of revelation [aforetime]? They have been called upon to let God's writ be their law - and yet some of them turn away [from it] in their obstinacy,",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 23,
          "number": 316,
          "juz": 3,
          "numpage": 53
        },
        {
          "text": "simply because they claim, \"The fire will most certainly not touch us for more than a limited number of days\": and thus the false beliefs which they invented have [in time] caused them to betray their faith.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 24,
          "number": 317,
          "juz": 3,
          "numpage": 53
        },
        {
          "text": "How, then, [will they fare] when We shall gather them all together to witness the Day about [the coming of] which there is no doubt, and every human being shall be repaid in full for what he has done, and none shall be wronged?",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 25,
          "number": 318,
          "juz": 3,
          "numpage": 53
        },
        {
          "text": "SAY: \"O God, Lord of all dominion! Thou grantest dominion unto whom Thou willest, and takest away dominion from whom Thou willest; and Thou exaltest whom Thou willest, and abasest whom Thou willest. In Thy hand is all good. Verily, Thou hast the power to will anything.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 26,
          "number": 319,
          "juz": 3,
          "numpage": 53
        },
        {
          "text": "\"Thou makest the night grow longer by shortening the day, and Thou makest the day grow longer by shortening the night. And Thou bringest forth the living out of that which is dead, and Thou bringest forth the dead out of that which is alive. And Thou grantest sustenance unto whom `Thou willest, beyond all reckoning.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 27,
          "number": 320,
          "juz": 3,
          "numpage": 53
        },
        {
          "text": "LET NOT the believers take those who deny the truth for their allies in preference to the believers - since he who does this cuts himself off from God in everything - unless it be to protect yourselves against them in this way. But God warns you to beware of Him: for with God is all journeys' end.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 28,
          "number": 321,
          "juz": 3,
          "numpage": 53
        },
        {
          "text": "Say: \"Whether you conceal what is in your hearts or bring it into the open, God knows it: for He knows all that is in the heavens and all that is on earth; and God has the power to will anything.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 29,
          "number": 322,
          "juz": 3,
          "numpage": 53
        }
      ]
    },
    {
      "52": [
        {
          "text": "those who say, \"O our Sustainer! Behold, we believe [in Thee]; forgive us, then, our sins, and keep us safe from suffering through the fire\" -:",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 16,
          "number": 309,
          "juz": 3,
          "numpage": 52
        },
        {
          "text": "those who are patient in adversity, and true to their word, and truly devout, and who spend [in God's way], and pray for forgiveness from their innermost hearts.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 17,
          "number": 310,
          "juz": 3,
          "numpage": 52
        },
        {
          "text": "GOD [Himself] proffers evidence - and [so do] the angels and all who are endowed with knowledge - that there is no deity save Him, the Upholder of Equity: there is no deity save Him, the Almighty, the Truly Wise.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 18,
          "number": 311,
          "juz": 3,
          "numpage": 52
        },
        {
          "text": "Behold, the only [true] religion in the sight of God is [man's] self-surrender unto Him; and those who were vouchsafed revelation aforetime took, out of mutual jealousy, to divergent views [on this point] only after knowledge [thereof] had come unto them. But as for him who denies the truth of God's messages - behold, God is swift in reckoning!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 19,
          "number": 312,
          "juz": 3,
          "numpage": 52
        },
        {
          "text": "Thus, [O Prophet,] if they argue with thee, say, \"I have surrendered my whole being unto God, and [so have] all who follow me!\" - and ask those who have been vouchsafed revelation aforetime, as well as all unlettered people, \"Have you [too] surrendered yourselves unto Him?\" And if they surrender themselves unto Him, they are on the right path; but if they turn away - behold, thy duty is no more than to deliver the message: for God sees all that is in [the hearts of] His creatures.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 20,
          "number": 313,
          "juz": 3,
          "numpage": 52
        },
        {
          "text": "Verily, as for those who deny the truth of God's messages, and slay the prophets against all right, and slay people who enjoin equity - announce unto them a grievous chastisement.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 21,
          "number": 314,
          "juz": 3,
          "numpage": 52
        },
        {
          "text": "It is they whose works shall come to nought both in this world and in the life to come; and they shall have none to succour them.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 22,
          "number": 315,
          "juz": 3,
          "numpage": 52
        }
      ]
    },
    {
      "51": [
        {
          "text": "BEHOLD, as for those who are bent on denying the truth - neither their worldly possessions nor their offspring will in the least avail them against God; and it is they, they who shall be the fuel of the fire!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 10,
          "number": 303,
          "juz": 3,
          "numpage": 51
        },
        {
          "text": "[To them shall - happen] the like of what happened to Pharaoh's people and those who lived before them: they gave the lie to Our messages - and so God took them to task for their sins: for God is severe in retribution.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 11,
          "number": 304,
          "juz": 3,
          "numpage": 51
        },
        {
          "text": "Say unto those who are bent on denying the truth: \"You shall be overcome and gathered unto hell - and how evil a resting-place!\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 12,
          "number": 305,
          "juz": 3,
          "numpage": 51
        },
        {
          "text": "You have already had a sign in the two hosts that met in battle, one host fighting in God's cause and the other denying Him; with their own eyes [the former] saw the others as twice their own number: but God strengthens with His succour whom He wills. In this, behold, there is indeed a lesson for all who have eyes to see.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 13,
          "number": 306,
          "juz": 3,
          "numpage": 51
        },
        {
          "text": "ALLURING unto man is the enjoyment of worldly desires through women, and children, and heaped-up treasures of gold and silver, and horses of high mark, and cattle, and lands. All this may be enjoyed in the life of this world - but the most beauteous of all goals is with God.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 14,
          "number": 307,
          "juz": 3,
          "numpage": 51
        },
        {
          "text": "Say: \"Shall I tell you of better things than those [earthly joys]? For the God-conscious there are, with their Sustainer, gardens through which running waters flow, therein to abide, and spouses pure, and God's goodly acceptance.\" And God sees all that is in [the hearts of] His servants -",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 15,
          "number": 308,
          "juz": 3,
          "numpage": 51
        }
      ]
    },
    {
      "50": [
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 1,
          "number": 294,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "GOD - there is no deity save Him, the Ever-Living, the Self-Subsistent Fount of All Being!",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 2,
          "number": 295,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "Step by step has He bestowed upon thee from on high this divine writ, setting forth the truth which confirms whatever there still remains [of earlier revelations]: for it is He who has bestowed from on high the Torah and the Gospel",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 3,
          "number": 296,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "aforetime, as a guidance unto mankind, and it is He who has bestowed [upon man] the standard by which to discern the true from the false. Behold, as for those who are bent on denying God's messages - grievous suffering awaits them: for God is almighty, an avenger of evil.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 4,
          "number": 297,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "Verily, nothing on earth or in the heavens is hidden from God.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 5,
          "number": 298,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "He it is who shapes you in the wombs as He wills. There is no deity save Him, the Almighty, the Truly Wise.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 6,
          "number": 299,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "He it is who has bestowed upon thee from on high this divine writ, containing messages that are clear in and by themselves - and these are the essence of the divine writ - as well as others that are allegorical. Now those whose hearts are given to swerving from the truth go after that part of the divine writ which has been expressed in allegory, seeking out [what is bound to create] confusion, and seeking [to arrive at] its final meaning [in an arbitrary manner]; but none save God knows its final meaning. Hence, those who are deeply rooted in knowledge say: \"We believe in it; the whole [of the divine writ] is from our Sustainer - albeit none takes this to heart save those who are endowed with insight.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 7,
          "number": 300,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "\"O our Sustainer! Let not our hearts swerve from the truth after Thou hast guided us; and bestow upon us the gift of Thy grace: verily, Thou art the [true] Giver of Gifts.",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 8,
          "number": 301,
          "juz": 3,
          "numpage": 50
        },
        {
          "text": "\"O our Sustainer! Verily, Thou wilt gather mankind together to witness the Day about [the coming of] which there is no doubt: verily, God never fails to fulfil His promise.\"",
          "englishName": "Aal-i-Imraan",
          "suraNumber": 3,
          "numberInSurah": 9,
          "number": 302,
          "juz": 3,
          "numpage": 50
        }
      ]
    },
    {
      "49": [
        {
          "text": "And if you are on a journey and cannot find a scribe, pledges [may be taken] in hand: but if you trust one another, then let him who is trusted fulfil his trust, and let him be conscious of God, his Sustainer. And do not conceal what you have witnessed - for, verily, he who conceals it is sinful at heart; and God has full knowledge of all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 283,
          "number": 290,
          "juz": 3,
          "numpage": 49
        },
        {
          "text": "Unto God belongs all that is in the heavens and all that is on earth. And whether you bring into the open what is in your minds or conceal it, God will call you to account for it; and then He will forgive whom He wills, and will chastise whom He wills: for God has the power to will anything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 284,
          "number": 291,
          "juz": 3,
          "numpage": 49
        },
        {
          "text": "THE APOSTLE, and the believers with him, believe in what has been bestowed upon him from on high by his Sustainer: they all believe in God, and His angels, and His revelations, and His apostles, making no distinction between any of His apostles; and they say: We have heard, and we pay heed. Grant us Thy forgiveness, O our Sustainer, for with Thee is all journeys' end!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 285,
          "number": 292,
          "juz": 3,
          "numpage": 49
        },
        {
          "text": "God does not burden any human being with more than he is well able to bear: in his favour shall be whatever good he does, and against him whatever evil he does. O our Sustainer! Take us not to task if we forget or unwittingly do wrong! \"O our Sustainer! Lay not upon us a burden such as Thou didst lay upon those who lived before us! O our Sustainer! Make us not bear burdens which we have no strength to bear! \"And efface Thou our sins, and grant us forgiveness, and bestow Thy mercy upon us! Thou art our Lord Supreme: succour us, then, against people who deny the truth!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 286,
          "number": 293,
          "juz": 3,
          "numpage": 49
        }
      ]
    },
    {
      "48": [
        {
          "text": "O YOU who have attained to faith! Whenever you give or take credit for a stated term, set it down in writing. And let a scribe write it down equitably between you; and no scribe shall refuse to write as God has taught him: thus shall he write. And let him who contracts the debt dictate; and let him be conscious of God, his Sustainer, and not weaken anything of his undertaking. And if he who contracts the debt is weak of mind or body, or, is not able to dictate himself, then let him who watches over his interests dictate equitably. And call upon two of your men to act as witnesses; and if two men are not available, then a man and two women from among such as are acceptable to you as witnesses, so that if one of them should make a mistake, the other could remind her. And the witnesses must not refuse [to give evidence] whenever they are called upon. And be not loath to write down every contractual provision, be it small or great, together with the time at which it falls due; this is more equitable in the sight of God, more reliable as evidence, and more likely to prevent you from having doubts [later]. If, however, [the transaction] concerns ready merchandise which you transfer directly unto one another, you will incur no sin if you do not write it down. And have witnesses whenever you trade with one another, but neither scribe nor witness must suffer harm; for if you do [them harm], behold, it will be sinful conduct on your part. And remain conscious of God, since it is God who teaches you [herewith] - and God has full knowledge of everything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 282,
          "number": 289,
          "juz": 3,
          "numpage": 48
        }
      ]
    },
    {
      "47": [
        {
          "text": "THOSE who gorge themselves on usury behave but as he might behave whom Satan has confounded with his touch; for they say, \"Buying and selling is but a kind of usury\" - the while God has made buying and selling lawful and usury unlawful. Hence, whoever becomes aware of his Sustainer's admonition, and thereupon desists [from usury], may keep his past gains, and it will be for God to judge him; but as for those who return to it -they are destined for the fire, therein to abide!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 275,
          "number": 282,
          "juz": 3,
          "numpage": 47
        },
        {
          "text": "God deprives usurious gains of all blessing, whereas He blesses charitable deeds with manifold increase. And God does not love anyone who is stubbornly ingrate and persists in sinful ways.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 276,
          "number": 283,
          "juz": 3,
          "numpage": 47
        },
        {
          "text": "Verily, those who have attained to faith and do good works, and are constant in prayer, and dispense charity - they shall have their reward with their Sustainer, and no fear need they have, and neither shall they grieve.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 277,
          "number": 284,
          "juz": 3,
          "numpage": 47
        },
        {
          "text": "O you who have attained to faith! Remain conscious of God. and give up all outstanding gains from usury, if you are [truly] believers;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 278,
          "number": 285,
          "juz": 3,
          "numpage": 47
        },
        {
          "text": "for if you do it not, then know that you are at war with God and His Apostle. But if you repent, then you shall be entitled to [the return of] your principal: you will do no wrong, and neither will you be wronged.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 279,
          "number": 286,
          "juz": 3,
          "numpage": 47
        },
        {
          "text": "If, however, [the debtor] is in straitened circumstances, [grant him] a delay until a time of ease; and it would be for your own good -if you but knew it -to remit [the debt entirely] by way of charity.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 280,
          "number": 287,
          "juz": 3,
          "numpage": 47
        },
        {
          "text": "And be conscious of the Day on which you shall be brought back unto God, whereupon every human being shall be repaid in full for what he has earned, and none shall be wronged.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 281,
          "number": 288,
          "juz": 3,
          "numpage": 47
        }
      ]
    },
    {
      "46": [
        {
          "text": "For, whatever you may spend on others, or whatever you may vow [to spend], verily, God knows it; and those who do wrong [by withholding charity] shall have none to succour them.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 270,
          "number": 277,
          "juz": 3,
          "numpage": 46
        },
        {
          "text": "If you do deeds of charity openly, it is well; but if you bestow it upon the needy in secret, it will be even better for you, and it will atone for some of your bad deeds. And God is aware of all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 271,
          "number": 278,
          "juz": 3,
          "numpage": 46
        },
        {
          "text": "It is not for thee [O Prophet] to make people follow the right path, since it is God [alone] who guides whom He wills. And whatever good you may spend on others is for your own good, provided that you spend only out of a longing for God's countenance: for, whatever good you may spend will be repaid unto you in full, and you shall not be wronged.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 272,
          "number": 279,
          "juz": 3,
          "numpage": 46
        },
        {
          "text": "[And give] unto [such of] the needy who, being wholly wrapped up in God's cause, are unable to go about the earth [in search of livelihood]. He who is unaware [of their condition] might think that they are wealthy, because they abstain [from begging]; [but] thou canst recognize them by their special mark: they do not beg of men with importunity. And whatever good you may spend [on them], verily, God knows it all.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 273,
          "number": 280,
          "juz": 3,
          "numpage": 46
        },
        {
          "text": "Those who spend their possessions [for the sake of God] by night and by day, secretly and openly, shall have their reward with their Sustainer; and no fear need they have, and neither shall they grieve.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 274,
          "number": 281,
          "juz": 3,
          "numpage": 46
        }
      ]
    },
    {
      "45": [
        {
          "text": "And the parable of those who spend their possessions out of a longing to please God, and out of their own inner certainty, is that of a garden on high, fertile ground: a rainstorm smites it, and thereupon it brings forth its fruit twofold; and if no rainstorm smites it, soft rain [falls upon it]. And God sees all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 265,
          "number": 272,
          "juz": 3,
          "numpage": 45
        },
        {
          "text": "Would any of you like to have a garden of date-palms and vines, through which running waters flow, and have all manner of fruit therein - and then be overtaken by old age, with only weak children to [look after] him-and then [see] it smitten by a fiery whirlwind and utterly scorched? In this way God makes clear His messages unto you, so that you might take thought.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 266,
          "number": 273,
          "juz": 3,
          "numpage": 45
        },
        {
          "text": "O you who have attained to faith! Spend on others out of the good things which you may have acquired, and out of that which We bring forth for you from the earth; and choose not for your spending the bad things which you yourselves would not accept without averting your eyes in disdain. And know that God is self-sufficient, ever to be praised.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 267,
          "number": 274,
          "juz": 3,
          "numpage": 45
        },
        {
          "text": "Satan threatens you with the prospect of poverty and bids you to be niggardly, whereas God promises you His forgiveness and bounty; and God is infinite, all-knowing,",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 268,
          "number": 275,
          "juz": 3,
          "numpage": 45
        },
        {
          "text": "granting wisdom unto whom He wills: and whoever is granted wisdom has indeed been granted wealth abundant. But none bears this in mind save those who are endowed with insight.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 269,
          "number": 276,
          "juz": 3,
          "numpage": 45
        }
      ]
    },
    {
      "44": [
        {
          "text": "And, lo, Abraham said: \"O my Sustainer! Show me how Thou givest life unto the dead!\" Said He: \"Hast thou, then, no faith?\" (Abraham) answered: \"Yea, but [let me see it] so that my heart may be set fully at rest.\" Said He: \"Take, then, four birds and teach them to obey thee; then place them separately on every hill [around thee]; then summon them: they will come flying to thee. And know that God is almighty, wise.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 260,
          "number": 267,
          "juz": 3,
          "numpage": 44
        },
        {
          "text": "THE PARABLE of those who spend their possessions for the sake of God is that of a grain out of which grow seven ears, in every ear a hundred grains: for God grants manifold increase unto whom He wills; and God is infinite, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 261,
          "number": 268,
          "juz": 3,
          "numpage": 44
        },
        {
          "text": "They who spend their possessions for the sake of God and do not thereafter mar their spending by stressing their own benevolence and hurting [the feelings of the needy] shall have their reward with 'their Sustainer, and no fear need they have, and neither shall they grieve.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 262,
          "number": 269,
          "juz": 3,
          "numpage": 44
        },
        {
          "text": "A kind word and the veiling of another's want is better than a charitable deed followed by hurt; and God is self-sufficient, forbearing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 263,
          "number": 270,
          "juz": 3,
          "numpage": 44
        },
        {
          "text": "O you who have attained to faith! Do not deprive your charitable deeds of all worth by stressing your own benevolence and hurting [the feelings of the needy], as does he who spends his wealth only to be seen and praised by men, and believes not in God and the Last Day: for his parable is that of a smooth rock with [a little] earth upon it - and then a rainstorm smites it and leaves it hard and bare. Such as these shall have no gain whatever from all their [good] works: for God does not guide people who refuse to acknowledge the truth.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 264,
          "number": 271,
          "juz": 3,
          "numpage": 44
        }
      ]
    },
    {
      "43": [
        {
          "text": "God is near unto those who have faith, taking them out of deep darkness into the light - whereas near unto those who are bent on denying the truth are the powers of evil that take them out of the light into darkness deep: it is they who are destined for the fire, therein to abide.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 257,
          "number": 264,
          "juz": 3,
          "numpage": 43
        },
        {
          "text": "ART THOU NOT aware of that [king] who argued with Abraham about his Sustainer, (simply] because God had granted him kingship? Lo! Abraham said: \"My Sustainer is He who grants life and deals death.\" [The king] replied: \"I [too] grant life and deal death!\" Said Abraham: \"Verily, God causes the sun to rise in the east; cause it, then, to rise in the west!\" Thereupon he who was bent on denying the truth remained dumbfounded: for God does not guide people who [deliberately] do wrong.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 258,
          "number": 265,
          "juz": 3,
          "numpage": 43
        },
        {
          "text": "Or [art thou, O man, of the same mind] as he who passed by a town deserted by its people, with its roofs caved in, [and] said, \"How could God bring all this back to life after its death?\" Thereupon God caused him to be dead for a hundred years; where after He brought him back to life [and] said: \"How long hast thou remained thus?\" He answered: \"I have remained thus a day, or part of a day.\" Said [God]: \"Nay, but thou hast remained thus for a hundred years! But look at thy food and thy drink-untouched is it by the passing of years - and look at thine ass! And [We did all this so that We might make thee a symbol unto men. And look at the bones [of animals and men] - how We put them together and then clothe them with flesh!\" And when [all this] became clear to him, he said: \"I know [now] that God has the power to will anything!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 259,
          "number": 266,
          "juz": 3,
          "numpage": 43
        }
      ]
    },
    {
      "42": [
        {
          "text": "Some of these apostles have We endowed more highly than others: among them were such as were spoken to by God [Himself], and some He has raised yet higher.' And We vouchsafed unto Jesus, the son of Mary, all evidence of the truth, and strengthened him with holy inspiration. And if God had so willed, they who succeeded those [apostles] would not have contended with one another after all evidence of the truth had come to them; but [as it was,] they did take to divergent views, and some of them attained to faith, while some of them came to deny the truth. Yet if God had so willed, they would not have contended with one another: but God does whatever He wills.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 253,
          "number": 260,
          "juz": 3,
          "numpage": 42
        },
        {
          "text": "O YOU who have attained to faith! Spend [in Our way] out of what We have granted you as sustenance ere there come a Day when there will be no bargaining, and no friendship, and no intercession. And they who deny the truth -it is they who are evildoers!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 254,
          "number": 261,
          "juz": 3,
          "numpage": 42
        },
        {
          "text": "GOD - there is no deity save Him, the Ever-Living, the Self-Subsistent Fount of All Being. Neither slumber overtakes Him, nor sleep. His is all that is in the heavens and all that is on earth. Who is there that could intercede with Him, unless it be by His leave? He knows all that lies open before men and all that is hidden from them, whereas they cannot attain to aught of His knowledge save that which He wills [them to attain]. His eternal power overspreads the heavens and the earth, and their upholding wearies Him not. And he alone is truly exalted, tremendous.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 255,
          "number": 262,
          "juz": 3,
          "numpage": 42
        },
        {
          "text": "THERE SHALL BE no coercion in matters of faith. Distinct has now become the right way from [the way of] error: hence, he who rejects the powers of evil and believes in God has indeed taken hold of a support most unfailing, which shall never give way: for God is all-hearing, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 256,
          "number": 263,
          "juz": 3,
          "numpage": 42
        }
      ]
    },
    {
      "41": [
        {
          "text": "And when Saul set out with his forces, he said: \"Behold, God will now try you by a river: he who shall drink of it will not belong to me, whereas he who shall refrain from tasting it - he, indeed, will belong to me; but forgiven shall be he who shall scoop up but a single handful.\" However, save for a few of them, they all drank [their fill] of it. And as soon as he and those who had kept faith with him had crossed the river, the others said: \"No strength have we today [to stand up] against Goliath and his forces!\" [Yet] those who knew with certainty that they were destined to meet God, replied: \"How often has a small host overcome a great host by God's leave! For God is with those who are patient in adversity.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 249,
          "number": 256,
          "juz": 2,
          "numpage": 41
        },
        {
          "text": "And when they came face to face with Goliath and his forces, they prayed: \"O our Sustainer! Shower us with patience in adversity, and make firm our steps, and succour us against the people who deny the truth!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 250,
          "number": 257,
          "juz": 2,
          "numpage": 41
        },
        {
          "text": "And thereupon, by God's leave, they routed them. And David slew Goliath; and God bestowed upon him dominion, and wisdom, and imparted to him the knowledge of whatever He willed. And if God had not enabled people to defend themselves against one another, corruption would surely overwhelm the earth: but God is limitless in His bounty unto all the worlds.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 251,
          "number": 258,
          "juz": 2,
          "numpage": 41
        },
        {
          "text": "THESE are God's messages: We convey them unto thee, [O Prophet,] setting forth the truth-for, verily, thou art among those who have been entrusted with a message.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 252,
          "number": 259,
          "juz": 2,
          "numpage": 41
        }
      ]
    },
    {
      "40": [
        {
          "text": "Art thou not aware of those elders of the children of Israel, after the time of Moses, how they said unto a prophet of theirs, \"Raise up a king for us, [and] we shall fight in God's cause\"? Said he: \"Would you, perchance, refrain from fighting if fighting is ordained for you?\" They answered: \"And why should we not fight in God's cause when we and our children have been driven from our homelands?\" Yet, when fighting was ordained for them, they did turn back, save for a few of them; but God had full knowledge of the evildoers.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 246,
          "number": 253,
          "juz": 2,
          "numpage": 40
        },
        {
          "text": "And their prophet said unto those elders: \"Behold, now God has raised up Saul to be your king.\" They said: \"How can he have dominion over us when we have a better claim to dominion than he, and he has not [even] been endowed with abundant wealth?\" [The prophet] replied: \"Behold, God has exalted him above you, and endowed him abundantly with knowledge and bodily perfection. And God bestows His dominion upon whom He wills: for God is infinite, all-knowing.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 247,
          "number": 254,
          "juz": 2,
          "numpage": 40
        },
        {
          "text": "And their prophet said unto them: \"Behold, it shall be a sign of his [rightful] dominion that you will be granted a heart endowed by your Sustainer with inner peace and with all that is enduring in the angel-borne heritage left behind by the House of Moses and the House of Aaron. Herein, behold, there shall indeed be a sign for you if you are [truly] believers.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 248,
          "number": 255,
          "juz": 2,
          "numpage": 40
        }
      ]
    },
    {
      "39": [
        {
          "text": "BE EVER mindful of prayers, and of praying in the most excellent way; and stand before God in devout obedience.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 238,
          "number": 245,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "But if you are in danger, [pray] walking or riding; and when you are again secure, bear God in mind - since it is He who taught you what you did not previously know.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 239,
          "number": 246,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "AND IF any of you die and leave wives behind, they bequeath thereby to their widows [the right to] one year's maintenance without their being obliged to leave [the dead husband's home]. If, however, they leave [of their own accord], there shall be no sin in whatever they may do with themselves in a lawful manner. And God is almighty, wise.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 240,
          "number": 247,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "And the divorced women, too, shall have [a right to] maintenance in a goodly manner: this is a duty for all who are conscious of God.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 241,
          "number": 248,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "In this way God makes clear unto you His messages, so that you might [learn to] use your reason.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 242,
          "number": 249,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "ART THOU NOT aware of those who forsook their homelands in their thousands for fear of death-whereupon God said unto them, \"Die,\" and later brought them back to life? Behold, God is indeed limitless in His bounty unto man -but most people are ungrateful.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 243,
          "number": 250,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "Fight, then, in God's cause, and know that God is all-hearing, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 244,
          "number": 251,
          "juz": 2,
          "numpage": 39
        },
        {
          "text": "Who is it that will offer up unto God a goodly loan, which He will amply repay, with manifold increase? For, God takes away, and He gives abundantly; and it is unto Him that you shall be brought back.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 245,
          "number": 252,
          "juz": 2,
          "numpage": 39
        }
      ]
    },
    {
      "38": [
        {
          "text": "And if any of you die and leave wives behind, they shall undergo, without remarrying, a waiting-period of four months and ten days; whereupon, when they have reached the end of their waiting-term, there shall be no sin in whatever they may do with their persons in a lawful manner. And God is aware of all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 234,
          "number": 241,
          "juz": 2,
          "numpage": 38
        },
        {
          "text": "But you will incur no sin if you give a hint of [an intended] marriage-offer to [any of] these women, or if you conceive such an intention without making it obvious: [for] God knows that you intend to ask them in marriage. Do not, however, plight your troth with them in secret, but speak only in a decent manner; and do not proceed with tying the marriage-knot ere the ordained [term of waiting] has come to its end. And know that God knows what is in your minds, and therefore remain conscious of Him; and know, too, that God is much-forgiving, forbearing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 235,
          "number": 242,
          "juz": 2,
          "numpage": 38
        },
        {
          "text": "You will incur no sin if you divorce women while you have not yet touched them nor settled a dower upon them; but [even in such a case] make provision for them - the affluent according to his means, and the straitened according to his means - a provision in an equitable manner: this is a duty upon all who would do good.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 236,
          "number": 243,
          "juz": 2,
          "numpage": 38
        },
        {
          "text": "And if you divorce them before having touched them, but after having settled a dower upon them, then [give them] half of what you have settled - unless it be that they forgo their claim or he in whose hand is the marriage-tie forgoes his claim [to half of the dower]: and to forgo what is due to you is more in accord with God-consciousness. And forget not [that you are to act with] grace towards one another: verily, God sees all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 237,
          "number": 244,
          "juz": 2,
          "numpage": 38
        }
      ]
    },
    {
      "37": [
        {
          "text": "And so, when you divorce women and they are about to reach the end of their waiting-term, then either retain them in a fair manner or let them go in a fair manner. But do not retain them against their will in order to hurt [them]: for he who does so sins indeed against himself. And do not take [these] messages of God in a frivolous spirit; and remember the blessings with which God has graced you, and all the revelation and the wisdom which He has bestowed on you from on high in order to admonish you thereby; and remain conscious of God, and know that God has full knowledge of everything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 231,
          "number": 238,
          "juz": 2,
          "numpage": 37
        },
        {
          "text": "And when you divorce women, and they have come to the end of their waiting-term, hinder them not from marrying other men if they have agreed with each other in a fair manner. This is an admonition unto every one of you who believes in God and the Last Day; it is the most virtuous [way] for you, and the cleanest. And God knows, whereas you do not know.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 232,
          "number": 239,
          "juz": 2,
          "numpage": 37
        },
        {
          "text": "And the [divorced] mothers may nurse their children for two whole years, if they wish to complete the period of nursing; and it is incumbent upon him who has begotten the child to provide in a fair manner for their sustenance and clothing. No human being shall be burdened with more than he is well able to bear: neither shall a mother be made to suffer because of her child, nor, because of his child, he who has begotten it. And the same duty rests upon the [father's] heir. And if both [parents] decide, by mutual consent and counsel, upon separation [of mother and child], they will incur no sin [thereby]; and if you decide to entrust your children to foster-mothers, you will incur no sin provided you ensure, in a fair manner, the safety of the child which you are handing over. But remain conscious of God, and know that God sees all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 233,
          "number": 240,
          "juz": 2,
          "numpage": 37
        }
      ]
    },
    {
      "36": [
        {
          "text": "God will not take you to task for oaths which you may have uttered without thought, but will take you to task [only] for what your hearts have conceived [in earnest]: for God is much-forgiving, forbearing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 225,
          "number": 232,
          "juz": 2,
          "numpage": 36
        },
        {
          "text": "Those who take an oath that they will not approach their wives shall have four months of grace; and if they go back [on their oath] -behold, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 226,
          "number": 233,
          "juz": 2,
          "numpage": 36
        },
        {
          "text": "But if they are resolved on divorce -behold, God is all-hearing, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 227,
          "number": 234,
          "juz": 2,
          "numpage": 36
        },
        {
          "text": "And the divorced women shall undergo, without remarrying, a waiting-period of three monthly courses: for it is not lawful for them to conceal what God may have created in their wombs, if they believe in God and the Last Day. And during this period their husbands are fully entitled to take them back, if they desire reconciliation; but, in accordance with justice, the rights of the wives [with regard to their husbands] are equal to the [husbands'] rights with regard to them, although men have precedence over them [in this respect]. And God is almighty, wise.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 228,
          "number": 235,
          "juz": 2,
          "numpage": 36
        },
        {
          "text": "A divorce may be [revoked] twice, whereupon the marriage must either be resumed in fairness or dissolved in a goodly manner. And it is not lawful for you to take back anything of what you have ever given to your wives unless both [partners] have cause to fear that they may not be able to keep within the bounds set by God: hence, if you have cause to fear that the two may not be able to keep within the bounds set by God, there shall be no sin upon either of them for what the wife may give up [to her husband] in order to free herself. These are the bounds set by God; do not, then, transgress them: for they who transgress the bounds set by God-it is they, they who are evildoers!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 229,
          "number": 236,
          "juz": 2,
          "numpage": 36
        },
        {
          "text": "And if he divorces her [finally], she shall thereafter not be lawful unto him unless she first takes another man for husband; then, if the latter divorces her, there shall be no sin upon either of the two if they return to one another-provided that both of them think that they will be able to keep within the bounds set by God: for these are the bounds of God which He makes clear unto people of [innate] knowledge.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 230,
          "number": 237,
          "juz": 2,
          "numpage": 36
        }
      ]
    },
    {
      "35": [
        {
          "text": "on this world and on the life to come. And they will ask thee about [how to deal with] orphans. Say: \"To improve their condition is best.\" And if you share their life, [remember that] they are your brethren: for God distinguishes between him who spoils things and him who improves. And had God so willed, He would indeed have imposed on you hardships which you would not have been able to bear: [but,] behold, God is almighty, wise!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 220,
          "number": 227,
          "juz": 2,
          "numpage": 35
        },
        {
          "text": "AND DO NOT marry women who ascribe divinity to aught beside God ere they attain to [true] belief: for any believing bondwoman [of God] is certainly better than a woman who ascribes divinity to aught beside God, even though she please you greatly. And do not give your women in marriage to men who ascribe divinity to aught beside God ere they attain to [true] belief: for- any believing bondman [of God] is certainly better than a man who ascribes divinity to aught beside God, even though he please you greatly. [Such as] these invite unto the fire, whereas God invites unto paradise, and unto [the achievement of] forgiveness by His leave; and He makes clear His messages unto mankind, so that they might bear them in mind.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 221,
          "number": 228,
          "juz": 2,
          "numpage": 35
        },
        {
          "text": "AND THEY will ask thee about [woman's] monthly courses. Say: \"It is a vulnerable condition. Keep, therefore, aloof from women during their monthly courses, and do not draw near unto them until they are cleansed; and when they are cleansed, go in unto them as God has bidden you to do.\" Verily, God loves those who turn unto Him in repentance and He loves those who keep themselves pure.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 222,
          "number": 229,
          "juz": 2,
          "numpage": 35
        },
        {
          "text": "Your wives are your tilth; go, then, unto your tilth as you may desire, but first provide something for your souls, and remain conscious of God, and know that you are destined to meet Him. And give glad tidings unto those who believe.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 223,
          "number": 230,
          "juz": 2,
          "numpage": 35
        },
        {
          "text": "AND DO NOT allow your oaths in the name of God to become an obstacle to virtue and God-consciousness and the promotion of peace between men: for God is all-hearing, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 224,
          "number": 231,
          "juz": 2,
          "numpage": 35
        }
      ]
    },
    {
      "34": [
        {
          "text": "FIGHTING is ordained for you, even though it be hateful to you; but it may well be that you hate a thing the while it is good for you, and it may well be that you love a thing the while it is bad for you: and God knows, whereas you do not know.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 216,
          "number": 223,
          "juz": 2,
          "numpage": 34
        },
        {
          "text": "They will ask thee about fighting in the sacred month. Say: \"Fighting in it is an awesome thing (grave transgression); but turning men away from the path of God and denying Him, and [turning them away from] the Inviolable House of Worship and expelling its people there from - [all this] is yet more awesome (greater transgression) in the sight of God, since oppression is more awesome (greater transgression) than killing.\" [Your enemies] will not cease to fight against you till they have turned you away from your faith, if they can. But if any of you should turn away from his faith and die as a denier of the truth - these it is whose works will go for nought in this world and in the life to come; and these it is who are destined for the fire, therein to abide.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 217,
          "number": 224,
          "juz": 2,
          "numpage": 34
        },
        {
          "text": "Verily, they who have attained to faith, and they who have forsaken the domain of evil and are striving hard in God's cause - these it is who may look forward to God's grace: for God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 218,
          "number": 225,
          "juz": 2,
          "numpage": 34
        },
        {
          "text": "THEY WILL ASK thee about intoxicants and games of chance. Say: \"In both there is great evil as well as some benefit for man; but the evil which they cause is greater than the benefit which they bring.\" And they will ask thee as to what they should spend [in God's cause]. Say: \"Whatever you can spare.\" In this way God makes clear unto you His messages, so that you might reflect",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 219,
          "number": 226,
          "juz": 2,
          "numpage": 34
        }
      ]
    },
    {
      "33": [
        {
          "text": "Ask the children of Israel how many a clear message We have given them! And if one alters God's blessed message after it has reached him - verily, God is severe in retribution!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 211,
          "number": 218,
          "juz": 2,
          "numpage": 33
        },
        {
          "text": "Unto those who are bent on denying the truth the life of this world [alone] seems goodly; hence, they scoff at those who have attained to faith: but they who are conscious of God shall be above them on Resurrection Day. And God grants sustenance unto whom He wills, beyond all reckoning.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 212,
          "number": 219,
          "juz": 2,
          "numpage": 33
        },
        {
          "text": "ALL MANKIND were once one single community; [then they began to differ -] whereupon God raised up the prophets as heralds of glad tidings and as warners, and through them bestowed revelation from on high, setting forth the truth, so that it might decide between people with regard to all on which they had come to hold divergent views. Yet none other than the selfsame people who had been granted this [revelation] began, out of mutual jealousy, to disagree about its meaning after all evidence of the truth had come unto them. But God guided the believers unto the truth about which, by His leave, they had disagreed: for God guides onto a straight way him that wills [to be guided].",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 213,
          "number": 220,
          "juz": 2,
          "numpage": 33
        },
        {
          "text": "[But] do you think that you could enter para-dise without having suffered like those [believers] who passed away before you? Misfortune and hardship befell them, and so shaken were they that the apostle, and the believers with him, would exclaim, \"When will God's succour come?\" Oh, verily, God's succour is [always] near!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 214,
          "number": 221,
          "juz": 2,
          "numpage": 33
        },
        {
          "text": "THEY WILL ASK thee as to what they should spend on others. Say: \"Whatever of your wealth you spend shall [first] be for your parents, and for the near of kin, and the orphans, and the needy, and the wayfarer; and whatever good you do, verily, God has full knowledge thereof.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 215,
          "number": 222,
          "juz": 2,
          "numpage": 33
        }
      ]
    },
    {
      "32": [
        {
          "text": "And bear God in mind during the appointed days; but he who hurries away within two days shall incur no sin, and he who tarries longer shall incur no sin, provided that he is conscious of God. Hence, remain conscious of God, and know that unto Him you shall be gathered.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 203,
          "number": 210,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "NOW THERE IS a kind of man whose views on the life of this world may please thee greatly, and [the more so as] he cites God as witness to what is in his heart and is, moreover, exceedingly skillful in argument.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 204,
          "number": 211,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "But whenever he prevails, he goes about the earth spreading corruption and destroying [man's] tilth and progeny: and God does not love corruption.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 205,
          "number": 212,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "And whenever he is told, \"Be conscious of God,\" his false pride drives him into sin: wherefore hell will be his allotted portion-and how vile a resting-place!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 206,
          "number": 213,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "But there is [also] a kind of man who would willingly sell his own self in order to please God: and God is most compassionate towards His servants.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 207,
          "number": 214,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "O you who have attained to faith! Surrender yourselves wholly unto God, and follow not Satan's footsteps, for, verily, he is your open foe.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 208,
          "number": 215,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "And if you should stumble after all evidence of the truth has come unto you, then know that, verily, God is almighty, wise.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 209,
          "number": 216,
          "juz": 2,
          "numpage": 32
        },
        {
          "text": "Are these people waiting, perchance, for God to reveal Himself unto them in the shadows of the clouds, together with the angels - although [by then] all will have been decided, and unto God all things will have been brought back?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 210,
          "number": 217,
          "juz": 2,
          "numpage": 32
        }
      ]
    },
    {
      "31": [
        {
          "text": "The pilgrimage shall take place in the months appointed for it. And whoever undertakes the pilgrimage in those [months] shall, while on pilgrimage, abstain from lewd speech, from all wicked conduct, and from quarrelling; and whatever good you may do, God is aware of it. And make provision for yourselves - but, verily, the best of all provisions is God-consciousness: remain, then, conscious of Me, O you who are endowed with insight!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 197,
          "number": 204,
          "juz": 2,
          "numpage": 31
        },
        {
          "text": "[However,] you will be committing no sin if [during the pilgrimage] you seek to obtain any bounty from your Sustainer. And when you surge downward in multitudes from `Arafat, remember God at the holy place, and remember Him as the One who guided you after you had indeed been lost on your way;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 198,
          "number": 205,
          "juz": 2,
          "numpage": 31
        },
        {
          "text": "and surge onward together with the multitude of all the other people who surge onward, and ask God to forgive you your sins: for, verily, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 199,
          "number": 206,
          "juz": 2,
          "numpage": 31
        },
        {
          "text": "And when you have performed your acts of worship, [continue to] bear God in mind as you would bear your own fathers in mind-nay, with a yet keener remembrance! For there are people who [merely] pray, \"O our Sustainer! Give us in this world\" -and such shall not partake in the blessings of the life to come.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 200,
          "number": 207,
          "juz": 2,
          "numpage": 31
        },
        {
          "text": "But there are among them such as pray, \"O our Sustainer! Grant us good in this world and good in the life to come, and keep us safe from suffering through the fire\":",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 201,
          "number": 208,
          "juz": 2,
          "numpage": 31
        },
        {
          "text": "it is these. that shall have their portion [of happiness] in return for what they have earned. And God is swift in reckoning.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 202,
          "number": 209,
          "juz": 2,
          "numpage": 31
        }
      ]
    },
    {
      "30": [
        {
          "text": "And slay them wherever you may come upon them, and drive them away from wherever they drove you away - for oppression is even worse than killing. And fight not against them near the Inviolable House of Worship unless they fight against you there first; but if they fight against you, slay them: such shall be the recompense of those who deny the truth.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 191,
          "number": 198,
          "juz": 2,
          "numpage": 30
        },
        {
          "text": "But if they desist-behold, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 192,
          "number": 199,
          "juz": 2,
          "numpage": 30
        },
        {
          "text": "Hence, fight against them until there is no more oppression and all worship is devoted to God alone; but if they desist, then all hostility shall cease, save against those who [wilfully] do wrong.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 193,
          "number": 200,
          "juz": 2,
          "numpage": 30
        },
        {
          "text": "Fight during the sacred months if you are attacked: for a violation of sanctity is [subject to the law of] just retribution. Thus, if anyone commits aggression against you, attack him just as he has attacked you - but remain conscious of God, and know that God is with those who are conscious of Him.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 194,
          "number": 201,
          "juz": 2,
          "numpage": 30
        },
        {
          "text": "And spend [freely] in God's cause, and let not your own hands throw you into destruction; and persevere in doing good: behold, God loves the doers of good.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 195,
          "number": 202,
          "juz": 2,
          "numpage": 30
        },
        {
          "text": "AND PERFORM the pilgrimage and the pious visit [to Mecca] in honour of God; and if you are held back, give instead whatever offering you can easily afford. And do not shave your heads until the offering has been sacrificed; but he from among you who is ill or suffers from an ailment of the head shall redeem himself by fasting, or alms, or [any other] act of worship. And if you are hale and secure, then he who takes advantage of a pious visit before the [time of] pilgrimage shall give whatever offering he can easily afford; whereas he who cannot afford it shall fast for three days during the pilgrimage and for seven days after your return: that is, ten full [days]. All this relates to him who does not live near the Inviolable House of Worship. And remain conscious of God, and know that God is severe in retribution.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 196,
          "number": 203,
          "juz": 2,
          "numpage": 30
        }
      ]
    },
    {
      "29": [
        {
          "text": "IT IS lawful for you to go in unto your wives during the night preceding the [day's] fast: they are as a garment for you, and you are as a garment for them. God is aware that you would have deprived yourselves of this right, and so He has turned unto you in His mercy and removed this hardship from you. Now, then, you may lie with them skin to skin, and avail yourselves of that which God has ordained for you, and eat and drink until you can discern the white streak of dawn against the blackness of night, and then resume fasting until nightfall; but do not lie with them skin to skin when you are about to abide in meditation in houses of worship. These are the bounds set by God: do not, then, offend against them - [for] it is thus that God makes clear His messages unto mankind, so that they might remain conscious of Him.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 187,
          "number": 194,
          "juz": 2,
          "numpage": 29
        },
        {
          "text": "AND DEVOUR NOT one another's possessions wrongfully, and neither employ legal artifices with a view to devouring sinfully, and knowingly, anything that by right belongs to others.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 188,
          "number": 195,
          "juz": 2,
          "numpage": 29
        },
        {
          "text": "THEY WILL ASK thee about the new moons. Say: \"They indicate the periods for [various doings of] mankind, including the pilgrimage.\" However, piety does not consist in your entering houses from the rear, [as it were,] but truly pious is he who is conscious of God. Hence, enter houses through their doors, and remain conscious of God, so that you might attain to a happy state.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 189,
          "number": 196,
          "juz": 2,
          "numpage": 29
        },
        {
          "text": "AND FIGHT in God's cause against those who wage war against you, but do not commit aggression-for, verily, God does not love aggressors.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 190,
          "number": 197,
          "juz": 2,
          "numpage": 29
        }
      ]
    },
    {
      "28": [
        {
          "text": "If, however, one has reason to fear that the testator has committed a mistake or a [deliberate] wrong, and thereupon brings about a settlement between the heirs, he will incur no sin [thereby]. Verily, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 182,
          "number": 189,
          "juz": 2,
          "numpage": 28
        },
        {
          "text": "O YOU who have attained to faith! Fasting is ordained for you as it was ordained for those before you, so that you might remain conscious of God:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 183,
          "number": 190,
          "juz": 2,
          "numpage": 28
        },
        {
          "text": "[fasting] during a certain number of days. But whoever of you is ill, or on a journey, [shall fast instead for the same] number of other days; and [in such cases] it is incumbent upon those who can afford it to make sacrifice by feeding a needy person. And whoever does more good than he is bound to do does good unto himself thereby; for to fast is to do good unto yourselves - if you but knew it.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 184,
          "number": 191,
          "juz": 2,
          "numpage": 28
        },
        {
          "text": "It was the month of Ramadan in which the Qur'an was [first] bestowed from on high as a guidance unto man and a self-evident proof of that guidance, and as the standard by which to discern the true from the false. Hence, whoever of you lives to see this month shall fast throughout it; but he that is ill, or on a journey, [shall fast instead for the same] number of other days. God wills that you shall have ease, and does not will you to suffer hardship; but [He desires] that you complete the number [of days required], and that you extol God for His having guided you aright, and that you render your thanks [unto Him].",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 185,
          "number": 192,
          "juz": 2,
          "numpage": 28
        },
        {
          "text": "AND IF My servants ask thee about Me - behold, I am near; I respond to the call of him who calls, whenever he calls unto Me: let them, then, respond unto Me, and believe in Me, so that they might follow the right way.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 186,
          "number": 193,
          "juz": 2,
          "numpage": 28
        }
      ]
    },
    {
      "27": [
        {
          "text": "True piety does not consist in turning your faces towards the east or the west - but truly pious is he who believes in God, and the Last Day; and the angels, and revelation, and the prophets; and spends his substance - however much he himself may cherish - it - upon his near of kin, and the orphans, and the needy, and the wayfarer, and the beggars, and for the freeing of human beings from bondage; and is constant in prayer, and renders the purifying dues; and [truly pious are] they who keep their promises whenever they promise, and are patient in misfortune and hardship and in time of peril: it is they that have proved themselves true, and it is they, they who are conscious of God.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 177,
          "number": 184,
          "juz": 2,
          "numpage": 27
        },
        {
          "text": "O YOU who have attained to faith! Just retribution is ordained for you in cases of killing: the free for the free, and the slave for the slave, and the woman for the woman. And if something [of his guilt] is remitted to a guilty person by his brother, this [remission] shall be adhered to with fairness, and restitution to his fellow-man shall be made in a goodly manner. This is an alleviation from your Sustainer, and an act of His grace. And for him who, none the less, wilfully transgresses the bounds of what is right, there is grievous suffering in store:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 178,
          "number": 185,
          "juz": 2,
          "numpage": 27
        },
        {
          "text": "for, in [the law of] just retribution, O you who are endowed with insight, there is life for you, so that you might remain conscious of God!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 179,
          "number": 186,
          "juz": 2,
          "numpage": 27
        },
        {
          "text": "IT IS ordained for you, when death approaches any of you and he is leaving behind much wealth, to make bequests in favour of his parents and [other] near of kin in accordance with what is fair: I this is binding on all who are conscious of God.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 180,
          "number": 187,
          "juz": 2,
          "numpage": 27
        },
        {
          "text": "And if anyone alters such a provision - after having come to know it, the sin of acting thus shall fall only upon those who have altered it. Verily, God is all-hearing, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 181,
          "number": 188,
          "juz": 2,
          "numpage": 27
        }
      ]
    },
    {
      "26": [
        {
          "text": "But when they are told, \"Follow what God has bestowed from on high,\" some answer, \"Nay, we shall follow [only] that which we found our forefathers believing in and doing.\" Why, even if their forefathers did not use their reason at all, and were devoid of all guidance?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 170,
          "number": 177,
          "juz": 2,
          "numpage": 26
        },
        {
          "text": "And so, the parable of those who re bent on denying the truth is that of the beast which hears the shepherd's cry, and hears in it nothing but the sound of a voice and a call. Deaf are they, and dumb, and blind: for they do not use their reason.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 171,
          "number": 178,
          "juz": 2,
          "numpage": 26
        },
        {
          "text": "O you who have attained to faith! Partake of the good things which We have provided for you as sustenance, and render thanks unto God, if it is [truly] Him that you worship.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 172,
          "number": 179,
          "juz": 2,
          "numpage": 26
        },
        {
          "text": "He has forbidden to you only carrion, and blood, and the flesh of swine, and that over which any name other than God's has been invoked; but if one is driven by necessity - neither coveting it nor exceeding his immediate need -no sin shall be upon him: for, behold, God is much-forgiving, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 173,
          "number": 180,
          "juz": 2,
          "numpage": 26
        },
        {
          "text": "VERILY, as for those who suppress aught of the revelation which God has bestowed from on high, and barter it away for a trifling gain - they but fill their bellies with fire. And God will not speak unto them on the Day of Resurrection, nor will He cleanse them [of their sins]; and grievous suffering awaits them.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 174,
          "number": 181,
          "juz": 2,
          "numpage": 26
        },
        {
          "text": "It is they who take error in exchange for guidance, and suffering in exchange for forgiveness: yet how little do they seem to fear the fire!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 175,
          "number": 182,
          "juz": 2,
          "numpage": 26
        },
        {
          "text": "Thus it is: since it is God who bestows the divine writ from on high, setting forth the truth, all those who set their own views against the divine writ are, verily, most deeply in the wrong.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 176,
          "number": 183,
          "juz": 2,
          "numpage": 26
        }
      ]
    },
    {
      "25": [
        {
          "text": "Verily, in the creation of the heavens and of the earth, and the succession of night and day: and in the ships that speed through the sea with what is useful to man: and in the waters which God sends down from the sky, giving life thereby to the earth after it had, been lifeless, and causing all manner of living creatures to multiply thereon: and in the change of the winds, and the clouds that run their appointed courses between sky and earth: [in all this] there are messages indeed for people who use their reason.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 164,
          "number": 171,
          "juz": 2,
          "numpage": 25
        },
        {
          "text": "And yet there are people who choose to believe in beings that allegedly rival God, loving them as [only] God should be loved: whereas those who have attained to faith love God more than all else. If they who are bent on evildoing could but see - as see they will when they are made to suffer [on Resurrection Day] -that all might belongs to God alone, and that God is severe in [meting out] punishment!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 165,
          "number": 172,
          "juz": 2,
          "numpage": 25
        },
        {
          "text": "[On that Day] it will come to pass that those who had been [falsely] adored shall disown their followers, and the latter shall see the suffering [that awaits them], with all their hopes cut to pieces!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 166,
          "number": 173,
          "juz": 2,
          "numpage": 25
        },
        {
          "text": "And then those followers shall say: \"Would that we had a second chance [in life], so that we could disown them as they have disowned us!\" Thus will God show them their works [in a manner that will cause them] batter regrets; but they will not come out of the fire.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 167,
          "number": 174,
          "juz": 2,
          "numpage": 25
        },
        {
          "text": "O MANKIND! Partake of what is lawful and good on earth, and follow not Satan's footsteps: for, verily, he is your open foe,",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 168,
          "number": 175,
          "juz": 2,
          "numpage": 25
        },
        {
          "text": "and bids you only to do evil, and to commit deeds of abomination, and to attribute unto God something of which you have no knowledge.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 169,
          "number": 176,
          "juz": 2,
          "numpage": 25
        }
      ]
    },
    {
      "24": [
        {
          "text": "And say not of those who are slain in God's cause, \"They are dead\": nay, they are alive, but you perceive it not.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 154,
          "number": 161,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "And most certainly shall We try you by means of danger, and hunger, and loss of worldly goods, of lives and of [labour's] fruits. But give glad tidings unto those who are patient in adversity",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 155,
          "number": 162,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "who, when calamity befalls them, say, \"Verily, unto God do we belong and, verily, unto Him we shall return.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 156,
          "number": 163,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "It is they upon whom their Sustainer's blessings and grace are bestowed, and it is they, they who are on the right path!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 157,
          "number": 164,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "[Hence,] behold, As-Safa and Al-Marwah are among the symbols set up by God; and thus, no wrong does he who, having come to the Temple on pilgrimage or on a pious visit, strides to and fro between these two: for, if one does more good than he is bound to do-behold, God is responsive to gratitude, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 158,
          "number": 165,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "BEHOLD, as for those who suppress aught of the evidence of the truth and of the guidance which We have bestowed from on high, after We have made it clear unto mankind through the divine writ - these it is whom God will reject, and whom all who can judge will reject.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 159,
          "number": 166,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "Excepted, however, shall be they that repent, and put themselves to rights, and make known the truth: and it is they whose repentance I shall accept-for I alone am the Acceptor of Repentance, the Dispenser of Grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 160,
          "number": 167,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "Behold, as for those who are bent on denying the truth and die as deniers of the truth -their due is rejection by God, and by the angels,, and by all [righteous] men.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 161,
          "number": 168,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "In this state shall they abide; [and] neither will their suffering, be lightened, nor will they be granted respite.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 162,
          "number": 169,
          "juz": 2,
          "numpage": 24
        },
        {
          "text": "AND YOUR GOD is the One God: there is no deity save Him, the Most Gracious, the Dispenser of Grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 163,
          "number": 170,
          "juz": 2,
          "numpage": 24
        }
      ]
    },
    {
      "23": [
        {
          "text": "They unto whom We have vouchsafed revelation aforetime know it as they know their own children: but, behold, some of them knowingly suppress the truth -",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 146,
          "number": 153,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "the truth from thy Sustainer! Be not, then, among the doubters:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 147,
          "number": 154,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "for, every community faces a direction of its own, of which He is the focal point. Vie, therefore, with one another in doing good works. Wherever you may be, God will gather you all unto Himself: for, verily, God has the power to will anything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 148,
          "number": 155,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "Thus, from wherever thou mayest come forth, turn thy face [in prayer] towards the Inviolable House of Worship-for,. behold, this [commandment] comes in truth from thy Sustainer; and God is not unaware of what you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 149,
          "number": 156,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "Hence, from wherever thou mayest come forth, turn thy face [in prayer] towards the Inviolable House of Worship; and wherever you all may be, turn your faces towards it, so that people should have no argument against you unless they are bent upon wrongdoing. And hold not them in awe, but stand in awe of Me, and [obey Me,] so that I might bestow upon you the full measure of My blessings., and that you might follow the right path.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 150,
          "number": 157,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "Even as We have sent unto you an apostle from among yourselves to convey unto you Our messages, and to cause you to grow in purity, and to impart unto you revelation and wisdom, and to teach you that which you knew not:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 151,
          "number": 158,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "so remember Me, and I shall remember you; and be grateful unto Me, and deny Me not.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 152,
          "number": 159,
          "juz": 2,
          "numpage": 23
        },
        {
          "text": "O YOU who have attained to faith! Seek aid in steadfast patience and prayer: for, behold, God is with those who are patient in adversity.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 153,
          "number": 160,
          "juz": 2,
          "numpage": 23
        }
      ]
    },
    {
      "22": [
        {
          "text": "THE WEAK-MINDED among people will say, \"What has turned them away from the direction of prayer which they have hitherto observed?\" Say: \"God's is the east and the west; He guides whom He wills onto a straight way.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 142,
          "number": 149,
          "juz": 2,
          "numpage": 22
        },
        {
          "text": "And thus have We willed you to be a community of the middle way, so that [with your lives] you might bear witness to the truth before all mankind, and that the Apostle might bear witness to it before you. And it is only to the end that We might make a clear distinction between those who follow the Apostle and those who turn about on their heels that We have appointed [for this community] the direction of prayer which thou [O Prophet] hast formerly observed: for this was indeed a hard test for all but those whom God has guided aright. But God will surely not lose sight of your faith-for, behold, God is most compassionate towards man, a dispenser of grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 143,
          "number": 150,
          "juz": 2,
          "numpage": 22
        },
        {
          "text": "We have seen thee [O Prophet] often turn thy face towards heaven [for guidance]: and now We shall indeed make thee turn in prayer in a direction which will fulfil thy desire. Turn, then, thy face towards the Inviolable House of Worship; and wherever you all may be, turn your faces towards it [in prayer]. And, verily, those who have been vouchsafed revelation aforetime know well that this [commandment] comes in truth from their Sustainer; and God is not unaware of what they do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 144,
          "number": 151,
          "juz": 2,
          "numpage": 22
        },
        {
          "text": "And yet, even if thou wert to place all evidence before those who have been vouchsafed earlier revelation, they would not follow thy direction of prayer; and neither mayest thou follow their direction of prayer, nor even do they follow one another's direction. And if thou shouldst follow their errant views after all the knowledge that has come unto thee thou wouldst surely be among the evildoers.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 145,
          "number": 152,
          "juz": 2,
          "numpage": 22
        }
      ]
    },
    {
      "21": [
        {
          "text": "AND THEY say, \"Be Jews\" - or, \"Christians\" - \"and you shall be on the right path.\" Say: \"Nay, but [ours is] the creed of Abraham, who turned away from all that is false, and was not of those who ascribe divinity to aught beside God.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 135,
          "number": 142,
          "juz": 1,
          "numpage": 21
        },
        {
          "text": "Say: \"We believe in God, and in that which has been bestowed from on high upon us, and that which has been bestowed upon Abraham and Ishmael and Isaac and Jacob and, their descendants, and that which has been vouchsafed to Moses and Jesus; and that which has been vouchsafed to all the [other] prophets by their Sustainer: we make no distinction between any of them. And it is unto Him that we surrender ourselves.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 136,
          "number": 143,
          "juz": 1,
          "numpage": 21
        },
        {
          "text": "And if [others] come to believe in the way you believe, they will indeed find themselves on the right path; and if they turn away, it is but they who will be deeply in the wrong, and God will protect thee from them: for He alone is all-hearing, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 137,
          "number": 144,
          "juz": 1,
          "numpage": 21
        },
        {
          "text": "[Say: \"Our life takes its] hue from God! And who could give a better hue [to life] than God, if we but truly worship Him?\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 138,
          "number": 145,
          "juz": 1,
          "numpage": 21
        },
        {
          "text": "Say [to the Jews and the Christians]: \"Do you argue with us about God? But He is our Sustainer as well as your Sustainer - and unto us shall be accounted our deeds, and unto you, your deeds; and it- is unto Him alone that we devote ourselves.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 139,
          "number": 146,
          "juz": 1,
          "numpage": 21
        },
        {
          "text": "\"Do you claim that Abraham and Ishmael and Isaac and Jacob and their descendants were `Jews' or `Christians'?\" Say: \"Do you know more than God does? And who could be more wicked than he who suppresses a testimony given to him by God? Yet God is not unmindful of what you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 140,
          "number": 147,
          "juz": 1,
          "numpage": 21
        },
        {
          "text": "\"Now those people have passed away; unto them shall be accounted what they have earned, and unto you, what you have earned; and you will not be judged on the strength of what they did.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 141,
          "number": 148,
          "juz": 1,
          "numpage": 21
        }
      ]
    },
    {
      "20": [
        {
          "text": "And when Abraham and Ishmael were raising the foundations of the Temple, [they prayed:] \"O our Sustainer! Accept Thou this from us: for, verily, Thou alone art all-hearing, all-knowing!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 127,
          "number": 134,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "\"O our Sustainer! Make us surrender ourselves unto Thee, and make out of our offspring a community that shall surrender itself unto Thee, and show us our ways of worship, and accept our repentance: for, verily, Thou alone art the Acceptor of Repentance, the Dispenser of Grace!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 128,
          "number": 135,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "\"O our Sustainer! Raise up from the midst of our off spring an apostle from among themselves, who shall convey unto them Thy messages, and impart unto them revelation as well as wisdom, and cause them to grow in purity: for, verily, Thou alone art almighty, truly wise!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 129,
          "number": 136,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "And who, unless he be weak of mind, would want to abandon Abraham's creed, seeing that We have indeed raised him high in this world, and that, verily, in the life to come he shall be among the righteous?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 130,
          "number": 137,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "When his Sustainer said to him, \"Surrender thyself unto Me!\" - he answered, \"I have surrendered myself unto [Thee,] the Sustainer of all the worlds.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 131,
          "number": 138,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "And this very thing did Abraham bequeath unto his children, and [so did] Jacob: \"O my children! Behold, God has granted you the purest faith; so do not allow death to overtake you ere you have surrendered yourselves unto Him.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 132,
          "number": 139,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "Nay, but you [yourselves, O children of Israel,] bear witness that when death was approaching Jacob, he said unto his sons: \"Whom will you worship after I am gone?\" They answered: \"We will worship thy God, the God of thy forefathers Abraham and Ishmael and Isaac, the One God; and unto Him we surrender ourselves.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 133,
          "number": 140,
          "juz": 1,
          "numpage": 20
        },
        {
          "text": "Now those people have passed away; unto them shall be accounted what they have earned, and unto you, what you have earned; and you will not be, judged on the strength of what they did.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 134,
          "number": 141,
          "juz": 1,
          "numpage": 20
        }
      ]
    },
    {
      "19": [
        {
          "text": "For, never will the Jews be pleased with thee. nor yet the Christians, unless thou follow their own creeds. Say: \"Behold, God's guidance is the only true guidance.\" And, indeed, if thou shouldst follow their errant views after all the knowledge that has come unto thee. thou wouldst have none to protect thee from God, and none to bring thee succour.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 120,
          "number": 127,
          "juz": 1,
          "numpage": 19
        },
        {
          "text": "Those unto whom We have vouchsafed the divine writ [and who] follow it as it ought to be followed -it is they who [truly] believe in it; whereas all who choose to deny its truth -it is they, they who are the losers!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 121,
          "number": 128,
          "juz": 1,
          "numpage": 19
        },
        {
          "text": "O CHILDREN of Israel! Remember those blessings of Mine with which I graced you, and how I favoured you above all other people;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 122,
          "number": 129,
          "juz": 1,
          "numpage": 19
        },
        {
          "text": "and remain conscious of [the coming of] a Day when no human being shall in the least avail another, nor shall ransom be accepted from any of them, nor shall intercession be of any use to them, and none shall be succoured.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 123,
          "number": 130,
          "juz": 1,
          "numpage": 19
        },
        {
          "text": "And [remember this:] when his Sustainer tried Abraham by [His] commandments and the latter fulfilled them, He said: \"Behold, I shall make thee a leader of men. \"Abraham asked: \"And [wilt Thou make leaders] of my offspring as well? \"[God] answered: \"My covenant does not embrace the evildoers.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 124,
          "number": 131,
          "juz": 1,
          "numpage": 19
        },
        {
          "text": "AND LO! We made the Temple a goal to which people might repair again and again, and a sanctuary: take then, the place whereon Abraham once stood as your place of prayer.\" And thus did We command Abraham and Ishmael: \"Purify My Temple for those who will walk around it, and those who will abide near it in meditation, and those who will bow down and prostrate themselves [in prayer].\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 125,
          "number": 132,
          "juz": 1,
          "numpage": 19
        },
        {
          "text": "And, lo, Abraham prayed: \"O my Sustainer! Make this a land secure, and grant its people fruitful sustenance - such of them as believe in God and the Last Day.\" [God] answered: \"And whoever shall deny the truth, him will I let enjoy himself for a short while -but in the end I shall drive him to suffering through fire: and how vile a journey's end!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 126,
          "number": 133,
          "juz": 1,
          "numpage": 19
        }
      ]
    },
    {
      "18": [
        {
          "text": "Furthermore, the Jews assert, \"The Christians have no valid ground for their beliefs,\" while the Christians assert, \"The Jews have no valid ground for their beliefs\" - and both quote the divine writ! Even thus, like unto what they say, have [always] spoken those who were devoid of knowledge;\" but it is God who will judge between them on Resurrection Day with regard to all on which they were wont to differ.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 113,
          "number": 120,
          "juz": 1,
          "numpage": 18
        },
        {
          "text": "Hence, who could be more wicked than those who bar the mention of God's name from [any of] His houses of worship and strive for their ruin, [although] they have no right to enter them save in fear [of God]? For them, in this world, there is ignominy in store; and for them, in the life to come, awesome suffering.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 114,
          "number": 121,
          "juz": 1,
          "numpage": 18
        },
        {
          "text": "And God's is the east and the west: and wherever you turn, there is God's countenance. Behold, God is infinite, all-knowing.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 115,
          "number": 122,
          "juz": 1,
          "numpage": 18
        },
        {
          "text": "And yet some people assert, \"God has taken unto Himself a son!\" Limitless is He in His glory! Nay, but His is all that is in the heavens and on earth; all things devoutly obey His will.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 116,
          "number": 123,
          "juz": 1,
          "numpage": 18
        },
        {
          "text": "The Originator is He of the heavens and the earth: and when He wills a thing to be, He but says unto it, \"Be\" -and it is.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 117,
          "number": 124,
          "juz": 1,
          "numpage": 18
        },
        {
          "text": "AND [only] those who are devoid of knowledge say, \"Why does God not speak unto us, nor is a [miraculous] sign shown to us?\" Even thus, like unto what they, say, spoke those who lived before their time their hearts are all alike. Indeed, We have made all the signs manifest unto people who are endowed with inner certainty.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 118,
          "number": 125,
          "juz": 1,
          "numpage": 18
        },
        {
          "text": "Verily, We have sent thee [O Prophet] with the truth, as a bearer of glad tidings and a warner: and thou shalt not be held accountable for those who are destined for the blazing fire.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 119,
          "number": 126,
          "juz": 1,
          "numpage": 18
        }
      ]
    },
    {
      "17": [
        {
          "text": "Any message which, We annul or consign to oblivion We replace with a better or a similar one. Dost thou not know that God has the power to will anything?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 106,
          "number": 113,
          "juz": 1,
          "numpage": 17
        },
        {
          "text": "Dost thou not know that God's is the dominion over the heavens and the earth, and that besides God you have none to protect you or bring you succour?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 107,
          "number": 114,
          "juz": 1,
          "numpage": 17
        },
        {
          "text": "Would you, perchance, ask of the Apostle who has been sent unto you what was asked aforetime of Moses? But whoever chooses to deny the [evidence of the] truth, instead of believing in it, has already strayed from the right path.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 108,
          "number": 115,
          "juz": 1,
          "numpage": 17
        },
        {
          "text": "Out of their selfish envy, many among the followers of earlier revelation would like to bring you back to denying the truth after you have attained to faith - [even] after the truth has become clear unto them. None the less, forgive and forbear, until God shall make manifest His will: behold, God has the power to will anything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 109,
          "number": 116,
          "juz": 1,
          "numpage": 17
        },
        {
          "text": "And be constant in prayer, and render the purifying dues; for, whatever good deed you send ahead for your own selves, you shall find it with God: behold, God sees all that you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 110,
          "number": 117,
          "juz": 1,
          "numpage": 17
        },
        {
          "text": "AND THEY claim, \"None shall ever enter paradise unless he be a Jew\" - or, \"a Christian\". Such are their wishful beliefs! Say: \"Produce an evidence for what you are claiming, if what you say is true!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 111,
          "number": 118,
          "juz": 1,
          "numpage": 17
        },
        {
          "text": "Yea, indeed: everyone who surrenders his whole being unto God, and is a doer of good withal, shall have his reward with his Sustainer; and all such need have no fear, and neither shall they grieve.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 112,
          "number": 119,
          "juz": 1,
          "numpage": 17
        }
      ]
    },
    {
      "16": [
        {
          "text": "and follow [instead] that which the evil ones used to practice during Solomon's reign - for it was not Solomon who denied the truth, but those evil ones denied it by teaching people sorcery -; and [they follow] that which has come down through the two angels in Babylon, Hurut and Marut-although these two never taught it to anyone without first declaring, \"We are but a temptation to evil: do not, then, deny [God's] truth!\" And they learn from these two how to create discord between a man and his wife; but whereas they can harm none thereby save by God's leave, they acquire a knowledge that only harms themselves and does not benefit them - although they know; indeed, that he who acquires this [knowledge] shall have no share in the good of the life to come. For, vile indeed is that [art] for which they have sold their own selves -had they but known it!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 102,
          "number": 109,
          "juz": 1,
          "numpage": 16
        },
        {
          "text": "And had they but believed and been conscious of Him, reward from God would indeed have brought them good-had they but known it!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 103,
          "number": 110,
          "juz": 1,
          "numpage": 16
        },
        {
          "text": "O YOU who have attained to faith! Do not say [to the Prophet], \"Listen to us,\" but rather say, \"Have patience with us,\" and hearken [unto him], since grievous suffering awaits those who deny the truth.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 104,
          "number": 111,
          "juz": 1,
          "numpage": 16
        },
        {
          "text": "Neither those from among the followers of earlier revelation who are bent on denying the truth, nor those who ascribe divinity to other beings beside God, would like to see any good ever bestowed upon you from on high by your Sustainer; but God singles out for His grace whom He wills-for God is limitless in His great bounty.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 105,
          "number": 112,
          "juz": 1,
          "numpage": 16
        }
      ]
    },
    {
      "15": [
        {
          "text": "Say: \"If an afterlife with God is to be for you alone, to the exclusion of all other people, then. you should long for death-if what you say is true!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 94,
          "number": 101,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "But never will they long for it, because [they are aware] of what their hands have sent ahead in this world: and God has full knowledge of evildoers.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 95,
          "number": 102,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "And thou wilt most certainly find that they cling to life more eagerly than any other people, even more than those who are bent on ascribing divinity to other beings beside God: every one of them would love to live a thousand years, although the grant of long life could not save him from suffering [in the hereafter]: for God sees all that they do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 96,
          "number": 103,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "SAY [O Prophet]: \"Whosoever is an enemy of Gabriel\" -who,, verily, by God's leave, has brought down upon thy heart this [divine writ] which confirms the truth of whatever there still remains [of earlier revelations], and is a guidance and a glad tiding for the believers-:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 97,
          "number": 104,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "\"whosover is an enemy of God and His angels and His message-bearers, including Gabriel and Michael, [should know that,] verily, God is the enemy of all who deny the truth.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 98,
          "number": 105,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "For, clear messages indeed have We bestowed upon thee from on high; and none denies their truth save the iniquitous.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 99,
          "number": 106,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "Is it not so that every time they made a promise [unto God], some of them cast it aside? Nay, indeed: most of them do not believe.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 100,
          "number": 107,
          "juz": 1,
          "numpage": 15
        },
        {
          "text": "And [even now,] when there has come unto them an apostle from God, confirming the truth already in their possession, some of those who were granted revelation aforetime cast the divine writ behind their backs as though unaware [of what it says],",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 101,
          "number": 108,
          "juz": 1,
          "numpage": 15
        }
      ]
    },
    {
      "14": [
        {
          "text": "And whenever there came unto them a [new] revelation from God, confirming the truth already in their possession-and [bear in mind that] aforetime they used to pray for victory over those who were bent on denying the truth -: whenever there came unto them something which they recognized [as the truth], they would deny it. And God's rejection is the due of all who deny the truth.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 89,
          "number": 96,
          "juz": 1,
          "numpage": 14
        },
        {
          "text": "Vile is that [false pride] for which they have sold their own selves by denying the truth of what God has bestowed from on high, out of envy that God should bestow aught of His favour upon whomsoever He wills of His servants: and thus have they earned the burden of God's condemnation, over and over. And for those who deny the truth there is shameful suffering in store.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 90,
          "number": 97,
          "juz": 1,
          "numpage": 14
        },
        {
          "text": "For when they are told, \"Believe in what God has bestowed from on high,\" they reply, \"We believe [only] in what has been bestowed on us\"-and they deny the truth of everything else, although it be a truth confirming the one already in their possession. Say \"Why, then, did you slay God's prophets aforetime, if you were (truly] believers?\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 91,
          "number": 98,
          "juz": 1,
          "numpage": 14
        },
        {
          "text": "And indeed, there came unto you Moses with all evidence of the truth - and thereupon. in his absence, you took to worshipping the (golden] calf, and acted wickedly.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 92,
          "number": 99,
          "juz": 1,
          "numpage": 14
        },
        {
          "text": "And, lo, We accepted your solemn pledge, raising Mount Sinai high above you, [saying,] \"Hold fast with [all your] strength unto what We have vouchsafed you, and hearken unto it!\" [But] they say, \"We have heard, but we disobey\" - for their hearts are filled to overflowing with love of the [golden] calf because of their refusal to acknowledge the truth. Say: \"Vile is what this [false] belief of yours enjoins upon you-if indeed you are believers!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 93,
          "number": 100,
          "juz": 1,
          "numpage": 14
        }
      ]
    },
    {
      "13": [
        {
          "text": "And lo! We accepted your solemn pledge that you would not shed one another's blood, and would not drive one another from your homelands - whereupon you acknowledged it; and thereto you bear witness [even now].",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 84,
          "number": 91,
          "juz": 1,
          "numpage": 13
        },
        {
          "text": "And yet, it is you who slay one another and drive some of your own people from their homelands, aiding one another against them in sin and hatred; but if they come to you as captives, you ransom them - although the very [act of] driving them away has been made unlawful to you!. Do you, then, believe in some parts of the divine writ and deny the truth of other parts? What, then, could be the reward of those among you who do such things but ignominy in the life of this world and, on the Day of Resurrection, commitment to most grievous suffering? For God is not unmindful of what you do.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 85,
          "number": 92,
          "juz": 1,
          "numpage": 13
        },
        {
          "text": "All who buy the life of this world at the price of the life to come - their suffering shall not be lightened, nor shall they be succoured!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 86,
          "number": 93,
          "juz": 1,
          "numpage": 13
        },
        {
          "text": "For, indeed, We vouchsafed unto Moses the divine writ and caused apostle after apostle to follow him; and We vouchsafed unto Jesus, the son of Mary, all evidence of the truth, and strengthened him with holy inspiration. [Yet] is it not so that every time an apostle came unto you with something that was not to your liking, you gloried in your arrogance, and to some of them you gave the lie, while others you would slay?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 87,
          "number": 94,
          "juz": 1,
          "numpage": 13
        },
        {
          "text": "But they say, \"Our hearts are already full of knowledge.\" Nay, but God has rejected them because of their refusal to acknowledge the truth: for, few are the things in which they believe.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 88,
          "number": 95,
          "juz": 1,
          "numpage": 13
        }
      ]
    },
    {
      "12": [
        {
          "text": "Do they not know, then, that God is aware of all that they would conceal as well as of all that they bring into the open?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 77,
          "number": 84,
          "juz": 1,
          "numpage": 12
        },
        {
          "text": "And there are among them unlettered people who have no real knowledge of the divine writ, [following] only wishful beliefs and depending on nothing but conjecture.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 78,
          "number": 85,
          "juz": 1,
          "numpage": 12
        },
        {
          "text": "Woe, then, unto those who write down, with their own hands, [something which they claim to be] divine writ, and then say. \"This is from God,\" in order to acquire a trifling gain thereby; woe, then, unto them for what their hands have written, and woe unto them for all that they may have gained!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 79,
          "number": 86,
          "juz": 1,
          "numpage": 12
        },
        {
          "text": "And they say, \"The fire will most certainly not touch us for more than a limited number of days.\" Say [unto them]: \"Have you received a promise from God - for God never breaks His promise - or do you attribute to God something which you cannot know?\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 80,
          "number": 87,
          "juz": 1,
          "numpage": 12
        },
        {
          "text": "Yea! Those who earn evil and by their sinfulness are engulfed - they are destined for the fire. therein to abide;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 81,
          "number": 88,
          "juz": 1,
          "numpage": 12
        },
        {
          "text": "whereas those who attain to faith and do righteous deeds -they are destined for paradise, therein to abide.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 82,
          "number": 89,
          "juz": 1,
          "numpage": 12
        },
        {
          "text": "AND LO! We accepted this solemn pledge from [you,] ' the children of Israel: \"You shall worship none but God; and you shall do good unto your parents and kinsfolk, and the orphans, and the poor; and you shall speak unto all people in a kindly way; and you shall be constant in prayer; and you shall spend in charity.” And yet, save for a few of you, you turned away: for you are obstinate folk!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 83,
          "number": 90,
          "juz": 1,
          "numpage": 12
        }
      ]
    },
    {
      "11": [
        {
          "text": "Said' they: \"Pray on our behalf unto thy Sustainer that He make clear to us what she is to be like, for to us all cows resemble one another; and then, if God so wills, we shall truly be guided aright!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 70,
          "number": 77,
          "juz": 1,
          "numpage": 11
        },
        {
          "text": "[Moses] answered: \"Behold, He says it is to be a cow not broken-in to plough the earth or to water the crops, free of fault, without markings of any other colour.\"Said they: \"At last thou hast brought out the truth!\"-and thereupon they sacrificed her, although they had almost left it undone.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 71,
          "number": 78,
          "juz": 1,
          "numpage": 11
        },
        {
          "text": "For, O children of Israel, because you had slain a human being and then cast the blame for this [crime] upon one another -although God will bring to light what you would conceals",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 72,
          "number": 79,
          "juz": 1,
          "numpage": 11
        },
        {
          "text": "We said: \"Apply this [principle] to some of those [cases of unresolved murder]: in this way God saves lives from death and shows you His will, so that you might [learn to] use your reason.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 73,
          "number": 80,
          "juz": 1,
          "numpage": 11
        },
        {
          "text": "And yet, after all this, your hearts hardened and became like rocks, or even harder: for, behold, there are rocks from which streams gush forth; and, behold, there are some from which, when they are cleft, water issues; and, behold, there are some that fall down for awe of God And God is not unmindful of what you do!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 74,
          "number": 81,
          "juz": 1,
          "numpage": 11
        },
        {
          "text": "CAN YOU, then, hope that they will believe in what you are preaching - seeing that a good many of them were wont to listen to the word of God and then, after having understood it, to pervert it knowingly?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 75,
          "number": 82,
          "juz": 1,
          "numpage": 11
        },
        {
          "text": "For, when they meet those who have attained to faith. they say, \"We believe [as you believe]\" - but when they find themselves alone with one another, they say. \"Do you inform them of what God has disclosed to you, so that they might use it in argument against you, quoting the words of your Sustainer? Will you not. then, use your reason?\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 76,
          "number": 83,
          "juz": 1,
          "numpage": 11
        }
      ]
    },
    {
      "10": [
        {
          "text": "VERILY, those who have attained to faith [in this divine writ], as well as those who follow the Jewish faith, and the Christians, and the Sabians -all who believe in God and the Last Day and do righteous deeds-shall have their reward with their Sustainer; and no fear need they have, and neither shall they grieve.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 62,
          "number": 69,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "AND LO! We accepted your solemn pledge, raising Mount Sinai high above you, [and saying;] \"Hold fast with [all your] strength unto what We have vouchsafed you, and bear in mind all that is therein, so that you might remain conscious of God!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 63,
          "number": 70,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "And you turned away after that-! And had it not been for God's favour upon you and His grace, you would surely have found yourselves among the lost;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 64,
          "number": 71,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "for you are well aware of those from among you who profaned the Sabbath, whereupon We said unto them, \"Be as apes despicable!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 65,
          "number": 72,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "and set them up as a warning example for their time and for all times to come, as well as an admonition to all who are conscious of God.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 66,
          "number": 73,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "AND LO! Moses said unto his people: \"Behold, God bids you to sacrifice a cow.\" They said: \"Dost thou mock at us?\" He answered: \"I seek refuge with God against being so ignorant!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 67,
          "number": 74,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "Said they: \"Pray on our behalf unto thy Sustainer that He make clear to us what she is to be like.\" [Moses] replied: \"Behold, He says it is to be a cow neither old nor immature, but of art age in-between. Do, then, what you have been bidden!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 68,
          "number": 75,
          "juz": 1,
          "numpage": 10
        },
        {
          "text": "Said they: \"Pray on our behalf unto thy Sustainer that He make clear to us what her colour should be. \"[Mopes] answered: \"Behold; He says it is to be a yellow cow, bright of hue, pleasing to the beholder.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 69,
          "number": 76,
          "juz": 1,
          "numpage": 10
        }
      ]
    },
    {
      "9": [
        {
          "text": "And [remember the time] when We said: \"Enter this land, and eat of its food as you may desire. abundantly; but enter the gate humbly and say, `Remove Thou from us the burden of our sins', [whereupon] We shall forgive you your sins, and shall amply reward the doers of good.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 58,
          "number": 65,
          "juz": 1,
          "numpage": 9
        },
        {
          "text": "But those who were bent on evildoing substituted another saying for that which had been given them: and so We sent down upon those evildoers a plague from heaven in requital for all their iniquity.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 59,
          "number": 66,
          "juz": 1,
          "numpage": 9
        },
        {
          "text": "And [remember] when Moses prayed for water for his people and We replied, \"Strike the rock with thy staff!\"-whereupon twelve springs gushed forth from it, so that all the people knew whence to drink. [And Moses said:] \"Eat and drink the sustenance provided by God, and do not act wickedly on earth by spreading corruption.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 60,
          "number": 67,
          "juz": 1,
          "numpage": 9
        },
        {
          "text": "And [remember] when you said: \"O Moses, indeed we cannot endure but one kind of food; pray, then, to thy Sustainer that He bring forth for us aught of what grows from the earth - of its herbs, its cucumbers, its garlic, its lentils, its onions.\" Said [Moses]: \"Would you take a lesser thing in exchange for what is [so much] better? Go back in shame to Egypt, and then you can have what you are asking for!\" And so, ignominy and humiliation overshadowed them, and they earned the burden of God's condemnation: all this, because they persisted in denying the truth of God's messages and in slaying the prophets against all right: all this, because they rebelled [against God], and persisted in transgressing the bounds of what is right.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 61,
          "number": 68,
          "juz": 1,
          "numpage": 9
        }
      ]
    },
    {
      "8": [
        {
          "text": "And [remember the time] when We saved you from Pharaoh's people, who afflicted you with cruel suffering, slaughtering your sons and sparing [only] your women - which was an awesome trial from your Sustainer;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 49,
          "number": 56,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "and when We cleft the sea before you, and thus saved you and caused Pharaoh's people to drown before your very eyes;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 50,
          "number": 57,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "and when We appointed for Moses forty nights [on Mount Sinai], and in his absence you took to worshipping the [golden] calf, and thus became evildoers:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 51,
          "number": 58,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "yet, even after that, We blotted out this your sin, so that you might have cause to be grateful.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 52,
          "number": 59,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "And [remember the time] when We vouchsafed unto Moses the divine writ-and [thus] a standard by which to discern the true from the false -so that you might be guided aright;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 53,
          "number": 60,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "and when Moses said unto his people: \"O my people! Verily, you have sinned against yourselves by worshipping the calf; turn, then. in repentance to your Maker and mortify yourselves; this will be the best for you in your Maker's sight\". And thereupon He accepted your repentance: for, behold, He alone is the Acceptor of Repentance, the Dispenser of Grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 54,
          "number": 61,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "And [remember] when you said, \"O Moses. indeed we shall not believe thee unto we see God face to face!\" - whereupon the thunderbolt of punishment overtook you before your very eyes.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 55,
          "number": 62,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "But We raised you again after you had been as dead, so that you might have cause to be grateful.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 56,
          "number": 63,
          "juz": 1,
          "numpage": 8
        },
        {
          "text": "And We caused the clouds to comfort you with their shade, and sent down unto you manna and quails. [saying,] \"Partake of the good things which We have provided for you as sustenance.\" And [by all their sinning] they did no harm unto Us-but [only] against their own selves did they sin.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 57,
          "number": 64,
          "juz": 1,
          "numpage": 8
        }
      ]
    },
    {
      "7": [
        {
          "text": "[For although] We did say, \"Down with you all from this [state],\" there shall, none the less, most certainly come unto you guidance from Me: and those who follow My guidance need have no fear, and neither shall they grieve;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 38,
          "number": 45,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "but those who are bent on denying the truth and giving the lie to Our messages - they are destined for the fire, and therein shall they abide.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 39,
          "number": 46,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "O CHILDREN of Israel! Remember those blessings of Mine with which I graced you, and fulfil your promise unto Me, [whereupon] I shall fulfil My promise unto you; and of Me, of Me stand in awe!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 40,
          "number": 47,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "Believe in that which I have [now] bestowed from on high, confirming the truth already in your possession, and be not foremost among those who deny its truth; and do not barter away My messages for a trifling gain; and of Me, of Me be conscious",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 41,
          "number": 48,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "And do not overlay the truth with falsehood, and do not knowingly suppress the truth;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 42,
          "number": 49,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "and be constant in prayer, and spend in charity, and bow down in prayer with all who thus bow down.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 43,
          "number": 50,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "Do you bid other people to be pious, the while you forget your own selves -and yet you recite the divine writ? Will you not, then, use your reason?;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 44,
          "number": 51,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "And seek aid in steadfast patience and prayer: and this, indeed, is a hard thing for all but the humble in spirit,",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 45,
          "number": 52,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "who know with certainty that they shall meet their Sustainer and that unto Him they shall return.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 46,
          "number": 53,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "O children of Israel! Remember those blessings of Mine with which I graced you, and how I favoured you above all other people;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 47,
          "number": 54,
          "juz": 1,
          "numpage": 7
        },
        {
          "text": "and remain conscious of [the coming of] a Day when no human being shall in the least avail another, nor shall intercession be accepted from any of them, nor ransom taken from them, and none shall be succoured.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 48,
          "number": 55,
          "juz": 1,
          "numpage": 7
        }
      ]
    },
    {
      "6": [
        {
          "text": "AND LO! Thy Sustainer said unto the angels: \"Behold, I am about to establish upon earth one who shall inherit it.\" They said: \"Wilt Thou place on it such as will spread corruption thereon and shed blood -whereas it is we who extol Thy limitless glory, and praise Thee, and hallow Thy name?\" [God] answered: \"Verily, I know that which you do not know.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 30,
          "number": 37,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "And He imparted unto Adam the names of all things; then He brought them within the ken of the angels and said: \"Declare unto Me the names of these [things], if what you say is true.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 31,
          "number": 38,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "They replied: \"Limitless art Thou in Thy glory! No knowledge have we save that which Thou hast imparted unto us. Verily, Thou alone art all-knowing, truly wise.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 32,
          "number": 39,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "Said He: \"O Adam, convey unto them the names of these [things].\" And as soon as [Adam] had conveyed unto them their names, [God] said: \"Did I not say unto you, `Verily, I alone know the hidden reality of the heavens and the earth, and know all that you bring into the open and all. that you would conceal'?\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 33,
          "number": 40,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "And when We told the angels, \"Prostrate yourselves before Adam!\" -they all prostrated themselves, save Iblis, who refused and gloried in his arrogance: and thus he became one of those who deny the truth.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 34,
          "number": 41,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "And We said: \"O Adam, dwell thou and thy wife in this garden, and eat freely thereof, both of you, whatever you may wish; but do not approach this one tree, lest you become wrongdoers.\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 35,
          "number": 42,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "But Satan caused them both to stumble therein, and thus brought about the loss of their erstwhile state. And so We said: \"Down with you, [and be henceforth] enemies unto one another; and on earth you shall have your abode and your livelihood for a while!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 36,
          "number": 43,
          "juz": 1,
          "numpage": 6
        },
        {
          "text": "Thereupon Adam received words [of guidance] from his Sustainer, and He accepted his repentance: for, verily, He alone is the-Acceptor of Repentance, the Dispenser of Grace.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 37,
          "number": 44,
          "juz": 1,
          "numpage": 6
        }
      ]
    },
    {
      "5": [
        {
          "text": "But unto those who have attained to faith and do good works give the glad tiding that theirs shall be gardens through which running waters flow. Whenever they are granted fruits there from as their appointed sustenance, they will say, \"It is this that in days of yore was granted to us as our sustenance!\"-for they shall be given something that will recall that [past]. And there shall they have spouses pure, and there shall they abide.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 25,
          "number": 32,
          "juz": 1,
          "numpage": 5
        },
        {
          "text": "Behold, God does not disdain to propound a parable of a gnat, or of something [even] less than that. Now, as for those who have attained to faith, they know that it is the truth from their Sustainer - whereas those who are bent on denying the truth say, \"What could God mean by this parable?\" In this way does He cause many a one to go astray, just as He guides many a one aright: but none does He cause thereby to go astray save the iniquitous,",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 26,
          "number": 33,
          "juz": 1,
          "numpage": 5
        },
        {
          "text": "who break their bond with God after it has been established [in their nature], and cut asunder what God has bidden to be joined, and spread corruption on earth: these it is that shall be the losers.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 27,
          "number": 34,
          "juz": 1,
          "numpage": 5
        },
        {
          "text": "How can you refuse to acknowledge God, seeing that you were lifeless and He gave you life, and that He will cause you to die and then will bring you again to life, whereupon unto Him you will be brought back?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 28,
          "number": 35,
          "juz": 1,
          "numpage": 5
        },
        {
          "text": "He it is who has created for you all that is on earth, and has applied His design to the heavens and fashioned them into seven heavens; and He alone has full knowledge of everything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 29,
          "number": 36,
          "juz": 1,
          "numpage": 5
        }
      ]
    },
    {
      "4": [
        {
          "text": "Their parable is that of people who kindle a fire: but as soon as it has illumined all around them, God takes away their light and leaves them in utter darkness, wherein they cannot see:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 17,
          "number": 24,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "Deaf, dumb, blind - and they cannot turn back.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 18,
          "number": 25,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "Or [the parable] of a violent cloudburst in the sky, with utter darkness, thunder and lightning: they put their fingers into their ears to keep out the peals of thunder, in terror of death; but God encompasses [with His might] all who deny the truth.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 19,
          "number": 26,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "The lightning well-nigh takes away their sight; whenever it gives them light, they advance therein, and whenever darkness falls around them, they stand still. And if God so willed, He could indeed take away their hearing and their sight: for, verily, God has the power to will anything.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 20,
          "number": 27,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "O MANKIND! Worship your Sustainer, who has created you and those who lived before you, so that you might remain conscious of Him",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 21,
          "number": 28,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "who has made the earth a resting-place for you and the sky a canopy, and has sent down water from the sky and thereby brought forth fruits for your sustenance: do not, then, claim that there is any power that could rival God, when you know [that He is One].",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 22,
          "number": 29,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "And if you doubt any part of what We have, bestowed from on high, step by step, upon Our servant [Muhammad], then produce a surah of similar merit, and call upon any other than God to bear witness for you -if what you say is true!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 23,
          "number": 30,
          "juz": 1,
          "numpage": 4
        },
        {
          "text": "And if you cannot do it-and most certainly you cannot do it-then be conscious of the fire whose fuel is human beings and stones which awaits all who deny the truth!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 24,
          "number": 31,
          "juz": 1,
          "numpage": 4
        }
      ]
    },
    {
      "3": [
        {
          "text": "BEHOLD, as for those who are bent on denying the truth - it is all one to them whether thou warnest them or dost not warn them: they will not believe.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 6,
          "number": 13,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "God; has sealed their hearts and their hearing, and over their eyes is a veil; and awesome suffering awaits them.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 7,
          "number": 14,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "And there are people who say, \"We do believe in God and the Last Day,\" the while they do not [really] believe.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 8,
          "number": 15,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "They would deceive God and those who have attained to faith-the while they deceive none but themselves, and perceive it not.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 9,
          "number": 16,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "In their hearts is disease, and so God lets their disease increase; and grievous suffering awaits them because of their persistent lying.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 10,
          "number": 17,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "And when they are told, \"Do not spread corruption on earth,\" they answer, \"We are but improving things!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 11,
          "number": 18,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "Oh, verily, it is they, they who are spreading corruption but they perceive it not?",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 12,
          "number": 19,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "And when they are told, \"Believe as other people believe,\" they answer, \"Shall we believe as the weak-minded believe?\" Oh, verily, it is they, they who are weak-minded -but they know it not!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 13,
          "number": 20,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "And when they meet those who have attained to faith, they assert, \"We believe [as you believe]\"; but when they find themselves alone with their- evil impulses, they say, \"Verily, we are with you; we were only mocking!\"",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 14,
          "number": 21,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "God will requite them for their mockery, and will leave them for a while in their overweening arrogance, blindly stumbling to and fro:",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 15,
          "number": 22,
          "juz": 1,
          "numpage": 3
        },
        {
          "text": "[for] it is they who have taken error in exchange for guidance; and neither has their bargain brought them gain, nor have they found guidance [elsewhere].",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 16,
          "number": 23,
          "juz": 1,
          "numpage": 3
        }
      ]
    },
    {
      "2": [
        {
          "text": "Alif. Lam. Mim.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 1,
          "number": 8,
          "juz": 1,
          "numpage": 2
        },
        {
          "text": "THIS DIVINE WRIT - let there be no doubt about it is [meant to be] a guidance for all the God-conscious.",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 2,
          "number": 9,
          "juz": 1,
          "numpage": 2
        },
        {
          "text": "Who believe in [the existence of] that which is beyond the reach of human perception, and are constant in prayer, and spend on others out of what We provide for them as sustenance;",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 3,
          "number": 10,
          "juz": 1,
          "numpage": 2
        },
        {
          "text": "And who believe in that which has been bestowed from on high upon thee, [O Prophet,] as well as in that which was bestowed before thy time: for it is they who in their innermost are certain of the life to come!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 4,
          "number": 11,
          "juz": 1,
          "numpage": 2
        },
        {
          "text": "It is they who follow the guidance [which comes from their Sustainer; and it is they, they who' shall attain to a happy state!",
          "englishName": "Al-Baqara",
          "suraNumber": 2,
          "numberInSurah": 5,
          "number": 12,
          "juz": 1,
          "numpage": 2
        }
      ]
    },
    {
      "1": [
        {
          "text": "In the name of God, The Most Gracious, The Dispenser of Grace:",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 1,
          "number": 1,
          "juz": 1,
          "numpage": 1
        },
        {
          "text": "All praise is due to God alone, the Sustainer of all the worlds,",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 2,
          "number": 2,
          "juz": 1,
          "numpage": 1
        },
        {
          "text": "The Most Gracious, the Dispenser of Grace,",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 3,
          "number": 3,
          "juz": 1,
          "numpage": 1
        },
        {
          "text": "Lord of the Day of Judgment!",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 4,
          "number": 4,
          "juz": 1,
          "numpage": 1
        },
        {
          "text": "Thee alone do we worship; and unto Thee alone do we turn for aid.",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 5,
          "number": 5,
          "juz": 1,
          "numpage": 1
        },
        {
          "text": "Guide us the straight way.",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 6,
          "number": 6,
          "juz": 1,
          "numpage": 1
        },
        {
          "text": "The way of those upon whom Thou hast bestowed Thy blessings, not of those who have been condemned [by Thee], nor of those who go astray!",
          "englishName": "Al-Faatiha",
          "suraNumber": 1,
          "numberInSurah": 7,
          "number": 7,
          "juz": 1,
          "numpage": 1
        }
      ]
    }
  ]