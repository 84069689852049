import React, { useState, useEffect, useContext } from "react";
import MenuList from "../components/MenuList";
import MenuListDuas from "../components/MenuListDuas";
import MenuLink from "../components/MenuLink";
import CustomLink from "../components/CustomLink";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoLogoFacebook,
  IoLogoInstagram,
} from "react-icons/io";
import { StoreContext } from "../contexts/StoreContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { useTranslation } from "react-i18next";

function Sidebar() {
  const { t } = useTranslation();
  const { menuHide, setMenuHide } = useContext(StoreContext);
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  const { innerWidth: devWidth } = window;
  const [menuShow, setMenuShow] = useState(devWidth > 1200 ? true : false);

  const menuShowHide = () => {
    setMenuShow(!menuShow);
    setMenuHide(!menuHide);
  };

  useEffect(() => {}, []);

  return (
    <aside
      className={"Sidebar " + (!menuShow ? "Menu-hide" : "show")}
      style={{ background: theme.sidebarbg, borderRightColor: theme.border }}
    >
      {!menuShow ? (
        <IoIosArrowForward
          onClick={menuShowHide}
          className={
            "Sidebar-menu-icon " + (!menuShow ? "Sidebar-menu-icon-move" : "")
          }
        />
      ) : (
        <IoIosArrowBack
          onClick={menuShowHide}
          style={
            isLightTheme && devWidth < 1100
              ? { color: "#333" }
              : { color: "#fff" }
          }
          className={
            "Sidebar-menu-icon Sidebar-menu-icon-back " +
            (!menuShow ? "Sidebar-menu-icon-move" : "show")
          }
        />
      )}
      <div className="Sidebar-box">
        <div
          className={
            "Sidebar-logo " + (!menuShow ? "Sidebar-logo-hide" : "show")
          }
          style={{
            borderBottomColor: theme.border,
          }}
        >
          <a
            href="https://www.facebook.com/TheNobleQuranApplication"
            target="_blank"
          >
            <IoLogoFacebook
              style={{
                background: theme.background,
                color: theme.syntax,
                fontSize: 26,
                marginRight: 10,
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/thenoblequran.app/"
            target="_blank"
          >
            <IoLogoInstagram
              style={{
                background: theme.background,
                color: theme.syntax,
                fontSize: 26,
              }}
            />
          </a>
        </div>
        <MenuList
          title={t("Surah")}
          list={t("SuraData", { returnObjects: true })}
        />
        <MenuList
          title={t("Juz")}
          list={t("JuzData", { returnObjects: true })}
        />
        <MenuListDuas
          title={t("Duas")}
          list={t("DuasData", { returnObjects: true })}
        />
        <MenuLink title={t("Mobile Apps")} urli="mobile-apps" />
        <CustomLink
          title={t("Contact")}
          urli="mailto:support@thenoblequran.app"
        />
      </div>
    </aside>
  );
}

export default Sidebar;
