import React, { useState, useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import ShareLinks from "../components/ShareLinks";
import { useTranslation } from "react-i18next";

function MobileApps() {
  const { t } = useTranslation();
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;

  return (
    <div className="Mobile-apps">
      <div className="Quran-app">
        <h3 className="Apps-title" style={{ color: theme.syntax }}>
          {t("The Noble Quran")}
        </h3>
        <div className="Duas-images">
          {isLightTheme ? (
            <div className="screens">
              <img
                src="../images/screens/screen2-light.png"
                className="screens-img"
                alt="duas form the Quran android"
              />
              <img
                src="../images/screens/screen1-light.png"
                className="screens-img"
                alt="duas form the Quran ios"
              />
            </div>
          ) : (
            <div className="screens">
              <img
                src="../images/screens/screen2-dark.png"
                className="screens-img"
                alt="duas form the Quran android"
              />
              <img
                src="../images/screens/screen1-dark.png"
                className="screens-img"
                alt="duas form the Quran ios"
              />
            </div>
          )}

          <div className="qr-code">
            <img
              src="../images/kuran.png"
              className="qr-code-img"
              alt="duas form the Quran android"
            />
            <img
              src="../images/kuranios.png"
              className="qr-code-img"
              alt="duas form the Quran ios"
            />
          </div>
          <div className="Donwload-wrapper">
            <a
              href="https://apps.apple.com/hr/app/pocket-quran/id1492230596?l=hr"
              target="_blank"
              className="Download-ios"
              style={{ background: theme.bg }}
            >
              <div>
                <img src="../images/apple-white.png" className="Apple-icon" />
              </div>
              <div className="Download-title">
                <span>{t("Download")}</span>
                <span>App Store</span>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mojquranaplikacija"
              target="_blank"
              className="Download-android"
              style={{ background: theme.bg }}
            >
              <div>
                <img src="../images/playstore.png" className="Playstore-icon" />
              </div>
              <div className="Download-title">
                <span>{t("Download")}</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="Duas-app">
        <h3 className="Apps-title" style={{ color: theme.syntax }}>
          {t("Duas from the Quran")}
        </h3>
        <div className="Duas-images">
          {isLightTheme ? (
            <div className="screens">
              <img
                src="../images/screens/duas1-light.png"
                className="screens-img"
                alt="duas form the Quran android"
              />
              <img
                src="../images/screens/duas2-light.png"
                className="screens-img"
                alt="duas form the Quran ios"
              />
            </div>
          ) : (
            <div className="screens">
              <img
                src="../images/screens/duas1-dark.png"
                className="screens-img"
                alt="duas form the Quran android"
              />
              <img
                src="../images/screens/duas2-dark.png"
                className="screens-img"
                alt="duas form the Quran ios"
              />
            </div>
          )}

          <div className="qr-code">
            <img
              src="../images/dove.png"
              className="qr-code-img"
              alt="duas form the Quran android"
            />
            <img
              src="../images/doveios.png"
              className="qr-code-img"
              alt="duas form the Quran ios"
            />
          </div>
          <div className="Donwload-wrapper">
            <a
              href="https://apps.apple.com/us/app/duas-from-the-quran/id1498460355?ls=1"
              target="_blank"
              className="Download-ios"
              style={{ background: theme.bg }}
            >
              <div>
                <img src="../images/apple-white.png" className="Apple-icon" />
              </div>
              <div className="Download-title">
                <span>{t("Download")}</span>
                <span>App Store</span>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ionic.quran123"
              target="_blank"
              className="Download-android"
              style={{ background: theme.bg }}
            >
              <div>
                <img src="../images/playstore.png" className="Playstore-icon" />
              </div>
              <div className="Download-title">
                <span>{t("Download")}</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <ShareLinks />
    </div>
  );
}

export default MobileApps;
