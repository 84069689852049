import React from "react";
import Img from "react-image";

const CustomImage = (props) => {
  return (
    <div className="Custom-img-box">
      {props.darkmode ? (
        <Img
          src={props.lightpath}
          loader={<img className="page-image" src="/img/loading.png" />}
          className="page-image"
        />
      ) : (
        <Img
          src={props.darkpath}
          loader={<img className="page-image" src="/img/loading.png" />}
          className="page-image"
        />
      )}
    </div>
  );
};

export default CustomImage;
