export default [ 
      {
        "identifier": "ar.muyassar",
        "language": "ar",
        "languageName": "Arabic",
        "name": "تفسير المیسر",
        "englishName": "King Fahad Quran Complex",
        "format": "text",
        "type": "tafsir"
      },
      {
        "identifier": "az.mammadaliyev",
        "language": "az",
        "languageName": "Azerbaijan",
        "name": "Məmmədəliyev & Bünyadov",
        "englishName": "Vasim Mammadaliyev and Ziya Bunyadov",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "az.musayev",
        "language": "az",
        "languageName": "Azerbaijan",
        "name": "Musayev",
        "englishName": "Alikhan Musayev",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ba.mehanovic",
        "language": "ba",
        "name": "Kur'an - sa prevodom (značenja) na bosanski jezik, utemeljen na Ibn Kesirovom tumačenju, i kratki komentar",
        "englishName": "Quran translation by Muhamed Mehanovic",
        "format": "text",
        "type": "translation",
        "direction": "ltr"
      },
      {
        "identifier": "bs.korkut",
        "language": "bs",
        "languageName": "Bosnian",
        "name": "Korkut",
        "englishName": "Besim Korkut",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "bn.bengali",
        "language": "bn",
        "languageName": "Bengali",
        "name": "মুহিউদ্দীন খান",
        "englishName": "Muhiuddin Khan",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "cs.hrbek",
        "language": "cs",
        "languageName": "Czech",
        "name": "Hrbek",
        "englishName": "Preklad I. Hrbek",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "cs.nykl",
        "language": "cs",
        "languageName": "Czech",
        "name": "Nykl",
        "englishName": "A. R. Nykl",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "de.aburida",
        "language": "de",
        "languageName": "German",
        "name": "Abu Rida",
        "englishName": "Abu Rida Muhammad ibn Ahmad ibn Rassoul",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "de.bubenheim",
        "language": "de",
        "languageName": "German",
        "name": "Bubenheim & Elyas",
        "englishName": "A. S. F. Bubenheim and N. Elyas",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "de.khoury",
        "language": "de",
        "languageName": "German",
        "name": "Khoury",
        "englishName": "Adel Theodor Khoury",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "de.zaidan",
        "language": "de",
        "languageName": "German",
        "name": "Zaidan",
        "englishName": "Amir Zaidan",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "dv.divehi",
        "language": "dv",
        "languageName": "Divehi",
        "name": "ދިވެހި",
        "englishName": "Office of the President of Maldives",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.ahmedali",
        "language": "en",
        "languageName": "English",
        "name": "Ahmed Ali",
        "englishName": "Ahmed Ali",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.ahmedraza",
        "language": "en",
        "languageName": "English",
        "name": "Ahmed Raza Khan",
        "englishName": "Ahmed Raza Khan",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.arberry",
        "language": "en",
        "languageName": "English",
        "name": "Arberry",
        "englishName": "A. J. Arberry",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.asad",
        "language": "en",
        "languageName": "English",
        "name": "Asad",
        "englishName": "Muhammad Asad",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.daryabadi",
        "language": "en",
        "languageName": "English",
        "name": "Daryabadi",
        "englishName": "Abdul Majid Daryabadi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.hilali",
        "language": "en",
        "languageName": "English",
        "name": "Hilali & Khan",
        "englishName": "Muhammad Taqi-ud-Din al-Hilali and Muhammad Muhsin Khan",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.pickthall",
        "language": "en",
        "languageName": "English",
        "name": "Pickthall",
        "englishName": "Mohammed Marmaduke William Pickthall",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.qaribullah",
        "language": "en",
        "languageName": "English",
        "name": "Qaribullah & Darwish",
        "englishName": "Hasan al-Fatih Qaribullah and Ahmad Darwish",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.sahih",
        "language": "en",
        "languageName": "English",
        "name": "Saheeh International",
        "englishName": "Saheeh International",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.sarwar",
        "language": "en",
        "languageName": "English",
        "name": "Sarwar",
        "englishName": "Muhammad Sarwar",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.yusufali",
        "language": "en",
        "languageName": "English",
        "name": "Yusuf Ali",
        "englishName": "Abdullah Yusuf Ali",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.ayati",
        "language": "fa",
        "languageName": "Persian",
        "name": "آیتی",
        "englishName": "AbdolMohammad Ayati",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.fooladvand",
        "language": "fa",
        "languageName": "Persian",
        "name": "فولادوند",
        "englishName": "Mohammad Mahdi Fooladvand",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.ghomshei",
        "language": "fa",
        "languageName": "Persian",
        "name": "الهی قمشه‌ای",
        "englishName": "Mahdi Elahi Ghomshei",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.makarem",
        "language": "fa",
        "languageName": "Persian",
        "name": "مکارم شیرازی",
        "englishName": "Naser Makarem Shirazi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fr.hamidullah",
        "language": "fr",
        "languageName": "French",
        "name": "Hamidullah",
        "englishName": "Muhammad Hamidullah",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ha.gumi", 
        "language": "ha",
        "languageName": "Hausa",
        "name": "Gumi",
        "englishName": "Abubakar Mahmoud Gumi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "hi.hindi",
        "language": "hi",
        "languageName": "Hindi",
        "name": "फ़ारूक़ ख़ान & नदवी",
        "englishName": "Suhel Farooq Khan and Saifur Rahman Nadwi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "id.indonesian",
        "language": "id",
        "languageName": "Indonesian",
        "name": "Bahasa Indonesia",
        "englishName": "Unknown",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "it.piccardo",
        "language": "it",
        "languageName": "Italian",
        "name": "Piccardo",
        "englishName": "Hamza Roberto Piccardo",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ja.japanese",
        "language": "ja",
        "languageName": "Japanese",
        "name": "Japanese",
        "englishName": "Unknown",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ko.korean",
        "language": "ko",
        "languageName": "Korean",
        "name": "Korean",
        "englishName": "Unknown",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ku.asan",
        "language": "ku",
        "languageName": "Arabic",
        "name": "ته‌فسیری ئاسان",
        "englishName": "Burhan Muhammad-Amin",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ml.abdulhameed",
        "language": "ml", 
        "languageName": "Malayalam",
        "name": "അബ്ദുല്‍ ഹമീദ് & പറപ്പൂര്‍",
        "englishName": "Cheriyamundam Abdul Hameed and Kunhi Mohammed Parappoor",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "nl.keyzer",
        "language": "nl",
        "languageName": "Dutch",
        "name": "Keyzer",
        "englishName": "Salomo Keyzer",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "no.berg",
        "language": "no",
        "languageName": "Norwegian",
        "name": "Einar Berg",
        "englishName": "Einar Berg",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "pl.bielawskiego",
        "language": "pl",
        "languageName": "Polish",
        "name": "Bielawskiego",
        "englishName": "Józefa Bielawskiego",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "pt.elhayek",
        "language": "pt",
        "languageName": "Portuguese",
        "name": "El-Hayek",
        "englishName": "Samir El-Hayek",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ro.grigore",
        "language": "ro",
        "languageName": "Romanian",
        "name": "Grigore",
        "englishName": "George Grigore",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.kuliev",
        "language": "ru",
        "languageName": "Russian",
        "name": "Кулиев",
        "englishName": "Elmir Kuliev",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.osmanov",
        "language": "ru",
        "languageName": "Russian",
        "name": "Османов",
        "englishName": "Magomed-Nuri Osmanovich Osmanov",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.porokhova",
        "language": "ru",
        "languageName": "Russian",
        "name": "Порохова",
        "englishName": "V. Porokhova",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "sd.amroti",
        "language": "sd",
        "languageName": "Sindhi",
        "name": "امروٽي",
        "englishName": "Taj Mehmood Amroti",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "so.abduh",
        "language": "so",
        "languageName": "Somali",
        "name": "Abduh",
        "englishName": "Mahmud Muhammad Abduh",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "sq.ahmeti",
        "language": "sq", 
        "languageName": "Albanian",
        "name": "Sherif Ahmeti",
        "englishName": "Sherif Ahmeti",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "sq.mehdiu",
        "language": "sq",
        "languageName": "Albanian",
        "name": "Feti Mehdiu",
        "englishName": "Feti Mehdiu",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "sq.nahi",
        "language": "sq",
        "languageName": "Albanian",
        "name": "Efendi Nahi",
        "englishName": "Hasan Efendi Nahi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "sv.bernstrom",
        "language": "sv",
        "languageName": "Swedish",
        "name": "Bernström",
        "englishName": "Knut Bernström",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "sw.barwani",
        "language": "sw",
        "languageName": "Swahili",
        "name": "Al-Barwani",
        "englishName": "Ali Muhsin Al-Barwani",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ta.tamil",
        "language": "ta",
        "languageName": "Tamil",
        "name": "ஜான் டிரஸ்ட்",
        "englishName": "Jan Turst Foundation",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tg.ayati",
        "language": "tg",
        "languageName": "Tajik",
        "name": "Оятӣ",
        "englishName": "AbdolMohammad Ayati",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "th.thai",
        "language": "th",
        "languageName": "Thai",
        "name": "ภาษาไทย",
        "englishName": "King Fahad Quran Complex",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.ates",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Süleyman Ateş",
        "englishName": "Suleyman Ates",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.bulac",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Alİ Bulaç",
        "englishName": "Alİ Bulaç",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.diyanet",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Diyanet İşleri",
        "englishName": "Diyanet Isleri",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.golpinarli",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Abdulbakî Gölpınarlı",
        "englishName": "Abdulbaki Golpinarli",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.ozturk",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Öztürk",
        "englishName": "Yasar Nuri Ozturk",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.transliteration",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Çeviriyazı",
        "englishName": "Muhammet Abay",
        "format": "text",
        "type": "transliteration"
      },
      {
        "identifier": "tr.vakfi",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Diyanet Vakfı",
        "englishName": "Diyanet Vakfi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.yazir",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Elmalılı Hamdi Yazır",
        "englishName": "Elmalili Hamdi Yazir",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.yildirim",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Suat Yıldırım",
        "englishName": "Suat Yildirim",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tr.yuksel",
        "language": "tr",
        "languageName": "Turkish",
        "name": "Edip Yüksel",
        "englishName": "Edip Yüksel",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "tt.nugman",
        "language": "tt",
        "languageName": "Tatar",
        "name": "Yakub Ibn Nugman",
        "englishName": "Yakub Ibn Nugman",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ug.saleh",
        "language": "ug",
        "languageName": "Uyghur",
        "name": "محمد صالح",
        "englishName": "Muhammad Saleh",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.ahmedali",
        "language": "ur",
        "languageName": "Urdu",
        "name": "احمد علی",
        "englishName": "Ahmed Ali",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.jalandhry",
        "language": "ur",
        "languageName": "Urdu",
        "name": "جالندہری",
        "englishName": "Fateh Muhammad Jalandhry",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.jawadi",
        "language": "ur",
        "languageName": "Urdu",
        "name": "علامہ جوادی",
        "englishName": "Syed Zeeshan Haider Jawadi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.kanzuliman",
        "language": "ur",
        "languageName": "Urdu",
        "name": "احمد رضا خان",
        "englishName": "Ahmed Raza Khan",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.qadri",
        "language": "ur",
        "languageName": "Urdu",
        "name": "طاہر القادری",
        "englishName": "Tahir ul Qadri",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "uz.sodik",
        "language": "uz",
        "languageName": "	Uzbek",
        "name": "Мухаммад Содик",
        "englishName": "Muhammad Sodik Muhammad Yusuf",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.maududi",
        "language": "en",
        "languageName": "English",
        "name": "Maududi",
        "englishName": "Abul Ala Maududi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.shakir",
        "language": "en",
        "languageName": "English",
        "name": "Shakir",
        "englishName": "Mohammad Habib Shakir",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "en.transliteration",
        "language": "en",
        "languageName": "English",
        "name": "Transliteration",
        "englishName": "English Transliteration",
        "format": "text",
        "type": "transliteration"
      },
      {
        "identifier": "es.cortes",
        "language": "es",
        "languageName": "Spanish",
        "name": "Cortes",
        "englishName": "Julio Cortes",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.ansarian",
        "language": "fa",
        "languageName": "Persian",
        "name": "انصاریان",
        "englishName": "Hussain Ansarian",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "quran-simple",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Simple",
        "englishName": "Simple",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-simple-clean",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Simple Clean",
        "englishName": "Simple Clean",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-simple-enhanced",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Simple Enhanced",
        "englishName": "Simple Enhanced",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-simple-min",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Simple Minimal",
        "englishName": "Simple Minimal",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-uthmani-min",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Uthmani Minimal",
        "englishName": "Uthmani Minimal",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-uthmani",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Uthamani",
        "englishName": "Uthamani",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "bg.theophanov",
        "language": "bg",
        "languageName": "Bulgarian",
        "name": "Теофанов",
        "englishName": "Tzvetan Theophanov",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "bs.mlivo",
        "language": "bs",
        "languageName": "Bosnian",
        "name": "Mlivo",
        "englishName": "Mustafa Mlivo",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.bahrampour",
        "language": "fa",
        "languageName": "Persian",
        "name": "بهرام پور",
        "englishName": "Abolfazl Bahrampour",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "es.asad",
        "language": "es",
        "languageName": "Spanish",
        "name": "Asad",
        "englishName": "Muhammad Asad - Abdurrasak Pérez",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.khorramshahi",
        "language": "fa",
        "languageName": "Persian",
        "name": "خرمشاهی",
        "englishName": "Baha'oddin Khorramshahi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.mojtabavi",
        "language": "fa",
        "languageName": "Persian",
        "name": "مجتبوی",
        "englishName": "Sayyed Jalaloddin Mojtabavi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "hi.farooq",
        "language": "hi",
        "languageName": "Hindi",
        "name": "फ़ारूक़ ख़ान & अहमद",
        "englishName": "Muhammad Farooq Khan and Muhammad Ahmed",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "id.muntakhab",
        "language": "id",
        "languageName": "Indonesian",
        "name": "Quraish Shihab",
        "englishName": "Muhammad Quraish Shihab et al.",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ms.basmeih",
        "language": "ms",
        "languageName": "Malay",
        "name": "Basmeih",
        "englishName": "Abdullah Muhammad Basmeih",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.abuadel",
        "language": "ru",
        "languageName": "	Russian",
        "name": "Абу Адель",
        "englishName": "Abu Adel",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.krachkovsky",
        "language": "ru",
        "languageName": "Russian",
        "name": "Крачковский",
        "englishName": "Ignaty Yulianovich Krachkovsky",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.muntahab",
        "language": "ru",
        "languageName": "Russian",
        "name": "Аль-Мунтахаб",
        "englishName": "Ministry of Awqaf, Egypt",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ru.sablukov",
        "language": "ru",
        "languageName": "Russian",
        "name": "Саблуков",
        "englishName": "Gordy Semyonovich Sablukov",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.junagarhi",
        "language": "ur",
        "languageName": "Urdu",
        "name": "محمد جوناگڑھی",
        "englishName": "Muhammad Junagarhi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ur.maududi",
        "language": "ur",
        "languageName": "Urdu",
        "name": "ابوالاعلی مودودی",
        "englishName": "Abul A'ala Maududi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "zh.jian",
        "language": "zh",
        "languageName": "",
        "name": "Ma Jian",
        "englishName": "Ma Jian",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "zh.majian",
        "language": "zh",
        "languageName": "Zhuang",
        "name": "Ma Jian (Traditional)",
        "englishName": "Ma Jian",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.khorramdel",
        "language": "fa",
        "languageName": "Persian",
        "name": "خرمدل",
        "englishName": "Mostafa Khorramdel",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "fa.moezzi",
        "language": "fa",
        "languageName": "Persian",
        "name": "معزی",
        "englishName": "Mohammad Kazem Moezzi",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "ar.jalalayn",
        "language": "ar",
        "languageName": "Arabic",
        "name": "تفسير الجلالين",
        "englishName": "Jalal ad-Din al-Mahalli and Jalal ad-Din as-Suyuti",
        "format": "text",
        "type": "tafsir"
      },
      {
        "identifier": "quran-tajweed",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Tajweed",
        "englishName": "Tajweed",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-wordbyword",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Word By Word",
        "englishName": "Word By Word",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-kids",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Kids",
        "englishName": "Kids",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "quran-corpus-qd",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Corpus",
        "englishName": "Corpus",
        "format": "text",
        "type": "quran"
      },
      {
        "identifier": "si.naseemismail",
        "language": "si",
        "languageName": "Sinhala",
        "name": "Naseem Ismail",
        "englishName": "Naseem Isamil and Masoor Maulana, Kaleel",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "quran-buck",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Buck",
        "englishName": "Buck",
        "format": "text",
        "type": "translation"
      },
      {
        "identifier": "zh.mazhonggang",
        "language": "zh",
        "languageName": "Zhuang",
        "name": "Ma Zhong Gang",
        "englishName": "马仲刚",
        "format": "text",
        "type": "translation"
      }
]