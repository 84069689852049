import React, { useState, useContext } from "react";
import listTranslations from "../data/listTranslations";
import listReciters from "../data/audiolist";
import { StoreContext } from "../contexts/StoreContext";
import { ThemeContext } from '../contexts/ThemeContext';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import ShareLinks from "../components/ShareLinks";
import { useTranslation } from "react-i18next";

function Settings(props) {
  const { t } = useTranslation();
  const {
    translationId,
    updateTranslation,
    reciter,
    updateReciter,
  } = useContext(StoreContext);
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  const [downloading, setDownloading] = useState(false);
  const [listShow, setlistShow] = useState(false);
  const [audioShow, setaudioShow] = useState(false);

  const saveTranslation = (ident) => {
    if (translationId === ident) {
      console.log("ima prevod");
    } else {
      setDownloading(true);
      return fetch("https://api.alquran.cloud/v1/quran/" + ident)
        .then((response) => response.json())
        .then((responseJson) => {
          const podaci = responseJson.data.surahs;
          const translateIdentifier = responseJson.data.edition.identifier;
          const allPages = podaci.reduce((acc, curr) => {
            curr.ayahs.forEach((page) => {
              var pageKey = `${page.page}`;
              if (!acc[pageKey]) {
                acc[pageKey] = [];
              }
              acc[pageKey].push({
                text: page.text,
                englishName: curr.englishName,
                suraNumber: curr.number,
                numberInSurah: page.numberInSurah,
                number: page.number,
                juz: page.juz,
                numpage: page.page,
              });
            });
            return acc;
          }, {});
          const finalArr = Object.entries(allPages).map(([page, arraj]) => {
            return {
              [page]: arraj,
            };
          });
          const finArr = finalArr.reverse();
          const dataw = JSON.stringify(finArr);
          console.log(finArr)
          updateTranslation(dataw, translateIdentifier);
          setDownloading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const saveAudio = (ident) => {
    if (reciter === ident) {
      console.log("ima audio");
    } else {
      updateReciter(ident);
    }
  };

  const translations = listTranslations.map((item, index) => (
    <div
      style={{ background: theme.background, color: theme.syntax, borderBottomColor: theme.border }}
      className={
        "Translation-lists " +
        (listShow ? "active-list" : "")
      }
      key={index}
      onClick={() => saveTranslation(item.identifier)}
    >
      <div
      className={
        "Translation-list-1 " +
        (translationId === item.identifier ? "C-green" : "C-white")
      }>
        <span>{item.name}</span>
        <span>{item.languageName}</span>
      </div>
      <div className={
        "Translation-list-2 " +
        (translationId === item.identifier ? "C-green" : "C-white")
      }>
        <span>{item.englishName}</span>
        <span>{item.type}</span>
      </div>
    </div>
  ));    

  const audio = listReciters.map((item, index) => (
    <div
      style={{ background: theme.background, color: theme.syntax, borderBottomColor: theme.border }}
      className={
        "Translation-lists " +
        (audioShow ? "active-list" : "")
      }
      key={index}
      onClick={() => saveAudio(item.identifier)}
    >
      <div className={
        "Translation-list-2 " +
        (reciter === item.identifier ? "C-green" : "C-white")
      }>
        <span>{item.name}</span>
        <span>{item.languageName}</span>
      </div>
    </div>
  ));

  const showList = () => {
    setaudioShow(false)
    setlistShow(!listShow);
  }

  const showAudioList = () => {
    setlistShow(false)
    setaudioShow(!audioShow);
  }
  
  return (
    <div className="Suhuf-settings scrolling">
       <div className="Banner-header Settings-banner-header">
         <h1 className="Banner-title" style={{ color: theme.syntax }}>{t('Set the settings')}</h1>
       </div>
      {downloading ? (
        <div className="Downloading-box">
          <h1 className="Downloading-element">Downloading</h1>
        </div>
      ) : null}
      <div className="Suhuf-settings-box">
      <div className="Settings-page">
        <div className="Translation-box">
          <h3 style={{ color: theme.syntax }} onClick={showList} >{t('Select Translation')}
          <IoIosArrowDown
          className={
            "Translation-arrow " + (listShow ? "active" : "")
          }
        />
          </h3>
          {translations}
        </div>
        <div className="Audio-box">
          <h3 style={{ color: theme.syntax }} onClick={showAudioList}>{t('Select Audio')}
          <IoIosArrowDown
          className={
            "Translation-arrow " + (audioShow ? "active" : "")
          } />
          </h3>
          {audio}
        </div>
      </div>
      </div>
      <ShareLinks />
    </div>
  );
}

export default Settings;
