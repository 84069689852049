import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import Card from "../components/Card";
import { ThemeContext } from '../contexts/ThemeContext';
import ShareLinks from "../components/ShareLinks";
import { useTranslation } from "react-i18next";

function Duas() {
  const { t, i18n } = useTranslation();
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  let { suraname } = useParams();
  const [position, setPosition] = useState(0);
  const [duasList, setDuasList] = useState(() => {
    let newdata = {};
    t('DuasData',  { returnObjects: true }).map((item, index) => {
      if (item.surah.includes(suraname)) {
        newdata = item.duas;
        setPosition(index)
      }
    });
    return newdata;
  });

  useEffect(() => {
    let newdata = {};
    t('DuasData',  { returnObjects: true }).map((item, index) => {
      if (index == position) {
        newdata = item.duas;
      }
    });
    setDuasList(newdata);
  }, [t]);
  
  return (
    <div className="Duas">
      <div className="Banner-header">
      <h1 className="Banner-title"  style={{ color: theme.syntax }}>Duas from the Noble Quran</h1>
      <p style={{ color: theme.syntax }}>{suraname}</p>
      </div>
      <div className="Duas-list">
        {duasList.map((item, index) => {
            return <Card data={item} />;
        })}
      </div>
      <ShareLinks />
    </div>
  );
}

export default withRouter(Duas);
