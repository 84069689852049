export default [
    {
      "number": 1,
      "name": "سُورَةُ ٱلْفَاتِحَةِ",
      "englishName": "Al-Faatiha",
      "englishNameTranslation": "The Opening",
      "numberOfAyahs": 7,
      "revelationType": "Meccan",
      "page": 2
    },
    {
      "number": 2,
      "name": "سورة البقرة",
      "englishName": "Al-Baqara",
      "englishNameTranslation": "The Cow",
      "numberOfAyahs": 286,
      "revelationType": "Medinan",
      "page": 2
    },
    {
      "number": 3,
      "name": "سورة آل عمران",
      "englishName": "Aal-i-Imraan",
      "englishNameTranslation": "The Family of Imraan",
      "numberOfAyahs": 200,
      "revelationType": "Medinan",
      "page": 50
    },
    {
      "number": 4,
      "name": "سورة النساء",
      "englishName": "An-Nisaa",
      "englishNameTranslation": "The Women",
      "numberOfAyahs": 176,
      "revelationType": "Medinan",
      "page": 77
    },
    {
      "number": 5,
      "name": "سورة المائدة",
      "englishName": "Al-Maaida",
      "englishNameTranslation": "The Table",
      "numberOfAyahs": 120,
      "revelationType": "Medinan",
      "page": 106
    },
    {
      "number": 6,
      "name": "سورة الأنعام",
      "englishName": "Al-An'aam",
      "englishNameTranslation": "The Cattle",
      "numberOfAyahs": 165,
      "revelationType": "Meccan",
      "page": 128
    },
    {
      "number": 7,
      "name": "سورة الأعراف",
      "englishName": "Al-A'raaf",
      "englishNameTranslation": "The Heights",
      "numberOfAyahs": 206,
      "revelationType": "Meccan",
      "page": 151
    },
    {
      "number": 8,
      "name": "سورة الأنفال",
      "englishName": "Al-Anfaal",
      "englishNameTranslation": "The Spoils of War",
      "numberOfAyahs": 75,
      "revelationType": "Medinan",
      "page": 177
    },
    {
      "number": 9,
      "name": "سورة التوبة",
      "englishName": "At-Tawba",
      "englishNameTranslation": "The Repentance",
      "numberOfAyahs": 129,
      "revelationType": "Medinan",
      "page": 187
    },
    {
      "number": 10,
      "name": "سورة يونس",
      "englishName": "Yunus",
      "englishNameTranslation": "Jonas",
      "numberOfAyahs": 109,
      "revelationType": "Meccan",
      "page": 208
    },
    {
      "number": 11,
      "name": "سورة هود",
      "englishName": "Hud",
      "englishNameTranslation": "Hud",
      "numberOfAyahs": 123,
      "revelationType": "Meccan",
      "page": 221
    },
    {
      "number": 12,
      "name": "سورة يوسف",
      "englishName": "Yusuf",
      "englishNameTranslation": "Joseph",
      "numberOfAyahs": 111,
      "revelationType": "Meccan",
      "page": 235
    },
    {
      "number": 13,
      "name": "سورة الرعد",
      "englishName": "Ar-Ra'd",
      "englishNameTranslation": "The Thunder",
      "numberOfAyahs": 43,
      "revelationType": "Medinan",
      "page": 249
    },
    {
      "number": 14,
      "name": "سورة ابراهيم",
      "englishName": "Ibrahim",
      "englishNameTranslation": "Abraham",
      "numberOfAyahs": 52,
      "revelationType": "Meccan",
      "page": 255
    },
    {
      "number": 15,
      "name": "سورة الحجر",
      "englishName": "Al-Hijr",
      "englishNameTranslation": "The Rock",
      "numberOfAyahs": 99,
      "revelationType": "Meccan",
      "page": 262
    },
    {
      "number": 16,
      "name": "سورة النحل",
      "englishName": "An-Nahl",
      "englishNameTranslation": "The Bee",
      "numberOfAyahs": 128,
      "revelationType": "Meccan",
      "page": 267
    },
    {
      "number": 17,
      "name": "سورة الإسراء",
      "englishName": "Al-Israa",
      "englishNameTranslation": "The Night Journey",
      "numberOfAyahs": 111,
      "revelationType": "Meccan",
      "page": 282
    },
    {
      "number": 18,
      "name": "سورة الكهف",
      "englishName": "Al-Kahf",
      "englishNameTranslation": "The Cave",
      "numberOfAyahs": 110,
      "revelationType": "Meccan",
      "page": 293
    },
    {
      "number": 19,
      "name": "سورة مريم",
      "englishName": "Maryam",
      "englishNameTranslation": "Mary",
      "numberOfAyahs": 98,
      "revelationType": "Meccan",
      "page": 306
    },
    {
      "number": 20,
      "name": "سورة طه",
      "englishName": "Taa-Haa",
      "englishNameTranslation": "Taa-Haa",
      "numberOfAyahs": 135,
      "revelationType": "Meccan",
      "page": 312
    },
    {
      "number": 21,
      "name": "سورة الأنبياء",
      "englishName": "Al-Anbiyaa",
      "englishNameTranslation": "The Prophets",
      "numberOfAyahs": 112,
      "revelationType": "Meccan",
      "page": 322
    },
    {
      "number": 22,
      "name": "سورة الحج",
      "englishName": "Al-Hajj",
      "englishNameTranslation": "The Pilgrimage",
      "numberOfAyahs": 78,
      "revelationType": "Medinan",
      "page": 332
    },
    {
      "number": 23,
      "name": "سورة المؤمنون",
      "englishName": "Al-Muminoon",
      "englishNameTranslation": "The Believers",
      "numberOfAyahs": 118,
      "revelationType": "Meccan",
      "page": 342
    },
    {
      "number": 24,
      "name": "سورة النور",
      "englishName": "An-Noor",
      "englishNameTranslation": "The Light",
      "numberOfAyahs": 64,
      "revelationType": "Medinan",
      "page": 350
    },
    {
      "number": 25,
      "name": "سورة الفرقان",
      "englishName": "Al-Furqaan",
      "englishNameTranslation": "The Criterion",
      "numberOfAyahs": 77,
      "revelationType": "Meccan",
      "page": 359
    },
    {
      "number": 26,
      "name": "سورة الشعراء",
      "englishName": "Ash-Shu'araa",
      "englishNameTranslation": "The Poets",
      "numberOfAyahs": 227,
      "revelationType": "Meccan",
      "page": 367
    },
    {
      "number": 27,
      "name": "سورة النمل",
      "englishName": "An-Naml",
      "englishNameTranslation": "The Ant",
      "numberOfAyahs": 93,
      "revelationType": "Meccan",
      "page": 377
    },
    {
      "number": 28,
      "name": "سورة القصص",
      "englishName": "Al-Qasas",
      "englishNameTranslation": "The Stories",
      "numberOfAyahs": 88,
      "revelationType": "Meccan",
      "page": 385
    },
    {
      "number": 29,
      "name": "سورة العنكبوت",
      "englishName": "Al-Ankaboot",
      "englishNameTranslation": "The Spider",
      "numberOfAyahs": 69,
      "revelationType": "Meccan",
      "page": 396
    },
    {
      "number": 30,
      "name": "سورة الروم",
      "englishName": "Ar-Room",
      "englishNameTranslation": "The Romans",
      "numberOfAyahs": 60,
      "revelationType": "Meccan",
      "page": 404
    },
    {
      "number": 31,
      "name": "سورة لقمان",
      "englishName": "Luqman",
      "englishNameTranslation": "Luqman",
      "numberOfAyahs": 34,
      "revelationType": "Meccan",
      "page": 411
    },
    {
      "number": 32,
      "name": "سورة السجدة",
      "englishName": "As-Sajda",
      "englishNameTranslation": "The Prostration",
      "numberOfAyahs": 30,
      "revelationType": "Meccan",
      "page": 415
    },
    {
      "number": 33,
      "name": "سورة الأحزاب",
      "englishName": "Al-Ahzaab",
      "englishNameTranslation": "The Clans",
      "numberOfAyahs": 73,
      "revelationType": "Medinan",
      "page": 418
    },
    {
      "number": 34,
      "name": "سورة سَبَأ",
      "englishName": "Saba",
      "englishNameTranslation": "Sheba",
      "numberOfAyahs": 54,
      "revelationType": "Meccan",
      "page": 428
    },
    {
      "number": 35,
      "name": "سورة فاطر",
      "englishName": "Faatir",
      "englishNameTranslation": "The Originator",
      "numberOfAyahs": 45,
      "revelationType": "Meccan",
      "page": 434
    },
    {
      "number": 36,
      "name": "سورة يس",
      "englishName": "Yaseen",
      "englishNameTranslation": "Yaseen",
      "numberOfAyahs": 83,
      "revelationType": "Meccan",
      "page": 440
    },
    {
      "number": 37,
      "name": "سورة الصافات",
      "englishName": "As-Saaffaat",
      "englishNameTranslation": "Those drawn up in Ranks",
      "numberOfAyahs": 182,
      "revelationType": "Meccan",
      "page": 446
    },
    {
      "number": 38,
      "name": "سورة ص",
      "englishName": "Saad",
      "englishNameTranslation": "The letter Saad",
      "numberOfAyahs": 88,
      "revelationType": "Meccan",
      "page": 453
    },
    {
      "number": 39,
      "name": "سورة الزمر",
      "englishName": "Az-Zumar",
      "englishNameTranslation": "The Groups",
      "numberOfAyahs": 75,
      "revelationType": "Meccan",
      "page": 458
    },
    {
      "number": 40,
      "name": "سورة غافر",
      "englishName": "Ghafir",
      "englishNameTranslation": "The Forgiver",
      "numberOfAyahs": 85,
      "revelationType": "Meccan",
      "page": 467
    },
    {
      "number": 41,
      "name": "سورة فصلت",
      "englishName": "Fussilat",
      "englishNameTranslation": "Explained in detail",
      "numberOfAyahs": 54,
      "revelationType": "Meccan",
      "page": 477
    },
    {
      "number": 42,
      "name": "سورة الشورى",
      "englishName": "Ash-Shura",
      "englishNameTranslation": "Consultation",
      "numberOfAyahs": 53,
      "revelationType": "Meccan",
      "page": 483
    },
    {
      "number": 43,
      "name": "سورة الزخرف",
      "englishName": "Az-Zukhruf",
      "englishNameTranslation": "Ornaments of gold",
      "numberOfAyahs": 89,
      "revelationType": "Meccan",
      "page": 489
    },
    {
      "number": 44,
      "name": "سورة الدخان",
      "englishName": "Ad-Dukhaan",
      "englishNameTranslation": "The Smoke",
      "numberOfAyahs": 59,
      "revelationType": "Meccan",
      "page": 496
    },
    {
      "number": 45,
      "name": "سورة الجاثية",
      "englishName": "Al-Jaathiya",
      "englishNameTranslation": "Crouching",
      "numberOfAyahs": 37,
      "revelationType": "Meccan",
      "page": 499
    },
    {
      "number": 46,
      "name": "سورة الأحقاف",
      "englishName": "Al-Ahqaf",
      "englishNameTranslation": "The Dunes",
      "numberOfAyahs": 35,
      "revelationType": "Meccan",
      "page": 502
    },
    {
      "number": 47,
      "name": "سورة محمد",
      "englishName": "Muhammad",
      "englishNameTranslation": "Muhammad",
      "numberOfAyahs": 38,
      "revelationType": "Medinan",
      "page": 507
    },
    {
      "number": 48,
      "name": "سورة الفتح",
      "englishName": "Al-Fath",
      "englishNameTranslation": "The Victory",
      "numberOfAyahs": 29,
      "revelationType": "Medinan",
      "page": 511
    },
    {
      "number": 49,
      "name": "سورة الحجرات",
      "englishName": "Al-Hujuraat",
      "englishNameTranslation": "The Inner Apartments",
      "numberOfAyahs": 18,
      "revelationType": "Medinan",
      "page": 515
    },
    {
      "number": 50,
      "name": "سورة ق",
      "englishName": "Qaaf",
      "englishNameTranslation": "The letter Qaaf",
      "numberOfAyahs": 45,
      "revelationType": "Meccan",
      "page": 518
    },
    {
      "number": 51,
      "name": "سورة الذاريات",
      "englishName": "Adh-Dhaariyat",
      "englishNameTranslation": "The Winnowing Winds",
      "numberOfAyahs": 60,
      "revelationType": "Meccan",
      "page": 520
    },
    {
      "number": 52,
      "name": "سورة الطور",
      "englishName": "At-Tur",
      "englishNameTranslation": "The Mount",
      "numberOfAyahs": 49,
      "revelationType": "Meccan",
      "page": 523
    },
    {
      "number": 53,
      "name": "سورة النجم",
      "englishName": "An-Najm",
      "englishNameTranslation": "The Star",
      "numberOfAyahs": 62,
      "revelationType": "Meccan",
      "page": 526
    },
    {
      "number": 54,
      "name": "سورة القمر",
      "englishName": "Al-Qamar",
      "englishNameTranslation": "The Moon",
      "numberOfAyahs": 55,
      "revelationType": "Meccan",
      "page": 528
    },
    {
      "number": 55,
      "name": "سورة الرحمن",
      "englishName": "Ar-Rahmaan",
      "englishNameTranslation": "The Beneficent",
      "numberOfAyahs": 78,
      "revelationType": "Medinan",
      "page": 531
    },
    {
      "number": 56,
      "name": "سورة الواقعة",
      "englishName": "Al-Waaqia",
      "englishNameTranslation": "The Inevitable",
      "numberOfAyahs": 96,
      "revelationType": "Meccan",
      "page": 534
    },
    {
      "number": 57,
      "name": "سورة الحديد",
      "englishName": "Al-Hadid",
      "englishNameTranslation": "The Iron",
      "numberOfAyahs": 29,
      "revelationType": "Medinan",
      "page": 537
    },
    {
      "number": 58,
      "name": "سورة المجادلة",
      "englishName": "Al-Mujaadila",
      "englishNameTranslation": "The Pleading Woman",
      "numberOfAyahs": 22,
      "revelationType": "Medinan",
      "page": 542
    },
    {
      "number": 59,
      "name": "سورة الحشر",
      "englishName": "Al-Hashr",
      "englishNameTranslation": "The Exile",
      "numberOfAyahs": 24,
      "revelationType": "Medinan",
      "page": 545
    },
    {
      "number": 60,
      "name": "سورة الممتحنة",
      "englishName": "Al-Mumtahana",
      "englishNameTranslation": "She that is to be examined",
      "numberOfAyahs": 13,
      "revelationType": "Medinan",
      "page": 549
    },
    {
      "number": 61,
      "name": "سورة الصف",
      "englishName": "As-Saff",
      "englishNameTranslation": "The Ranks",
      "numberOfAyahs": 14,
      "revelationType": "Medinan",
      "page": 551
    },
    {
      "number": 62,
      "name": "سورة الجمعة",
      "englishName": "Al-Jumu'a",
      "englishNameTranslation": "Friday",
      "numberOfAyahs": 11,
      "revelationType": "Medinan",
      "page": 553
    },
    {
      "number": 63,
      "name": "سورة المنافقون",
      "englishName": "Al-Munaafiqoon",
      "englishNameTranslation": "The Hypocrites",
      "numberOfAyahs": 11,
      "revelationType": "Medinan",
      "page": 554
    },
    {
      "number": 64,
      "name": "سورة التغابن",
      "englishName": "At-Taghaabun",
      "englishNameTranslation": "Mutual Disillusion",
      "numberOfAyahs": 18,
      "revelationType": "Medinan",
      "page": 556
    },
    {
      "number": 65,
      "name": "سورة الطلاق",
      "englishName": "At-Talaaq",
      "englishNameTranslation": "Divorce",
      "numberOfAyahs": 12,
      "revelationType": "Medinan",
      "page": 558
    },
    {
      "number": 66,
      "name": "سورة التحريم",
      "englishName": "At-Tahrim",
      "englishNameTranslation": "The Prohibition",
      "numberOfAyahs": 12,
      "revelationType": "Medinan",
      "page": 560
    },
    {
      "number": 67,
      "name": "سورة الملك",
      "englishName": "Al-Mulk",
      "englishNameTranslation": "The Sovereignty",
      "numberOfAyahs": 30,
      "revelationType": "Meccan",
      "page": 562
    },
    {
      "number": 68,
      "name": "سورة القلم",
      "englishName": "Al-Qalam",
      "englishNameTranslation": "The Pen",
      "numberOfAyahs": 52,
      "revelationType": "Meccan",
      "page": 564
    },
    {
      "number": 69,
      "name": "سورة الحاقة",
      "englishName": "Al-Haaqqa",
      "englishNameTranslation": "The Reality",
      "numberOfAyahs": 52,
      "revelationType": "Meccan",
      "page": 566
    },
    {
      "number": 70,
      "name": "سورة المعارج",
      "englishName": "Al-Ma'aarij",
      "englishNameTranslation": "The Ascending Stairways",
      "numberOfAyahs": 44,
      "revelationType": "Meccan",
      "page": 568
    },
    {
      "number": 71,
      "name": "سورة نوح",
      "englishName": "Nooh",
      "englishNameTranslation": "Noah",
      "numberOfAyahs": 28,
      "revelationType": "Meccan",
      "page": 570
    },
    {
      "number": 72,
      "name": "سورة الجن",
      "englishName": "Al-Jinn",
      "englishNameTranslation": "The Jinn",
      "numberOfAyahs": 28,
      "revelationType": "Meccan",
      "page": 572
    },
    {
      "number": 73,
      "name": "سورة المزمل",
      "englishName": "Al-Muzzammil",
      "englishNameTranslation": "The Enshrouded One",
      "numberOfAyahs": 20,
      "revelationType": "Meccan",
      "page": 574
    },
    {
      "number": 74,
      "name": "سورة المدثر",
      "englishName": "Al-Muddaththir",
      "englishNameTranslation": "The Cloaked One",
      "numberOfAyahs": 56,
      "revelationType": "Meccan",
      "page": 575
    },
    {
      "number": 75,
      "name": "سورة القيامة",
      "englishName": "Al-Qiyaama",
      "englishNameTranslation": "The Resurrection",
      "numberOfAyahs": 40,
      "revelationType": "Meccan",
      "page": 577
    },
    {
      "number": 76,
      "name": "سورة الانسان",
      "englishName": "Al-Insaan",
      "englishNameTranslation": "Man",
      "numberOfAyahs": 31,
      "revelationType": "Medinan",
      "page": 578
    },
    {
      "number": 77,
      "name": "سورة المرسلات",
      "englishName": "Al-Mursalaat",
      "englishNameTranslation": "The Emissaries",
      "numberOfAyahs": 50,
      "revelationType": "Meccan",
      "page": 580
    },
    {
      "number": 78,
      "name": "سورة النبأ",
      "englishName": "An-Naba",
      "englishNameTranslation": "The Announcement",
      "numberOfAyahs": 40,
      "revelationType": "Meccan",
      "page": 582
    },
    {
      "number": 79,
      "name": "سورة النازعات",
      "englishName": "An-Naazi'aat",
      "englishNameTranslation": "Those who drag forth",
      "numberOfAyahs": 46,
      "revelationType": "Meccan",
      "page": 583
    },
    {
      "number": 80,
      "name": "سورة عبس",
      "englishName": "Abasa",
      "englishNameTranslation": "He frowned",
      "numberOfAyahs": 42,
      "revelationType": "Meccan",
      "page": 585
    },
    {
      "number": 81,
      "name": "سورة التكوير",
      "englishName": "At-Takwir",
      "englishNameTranslation": "The Overthrowing",
      "numberOfAyahs": 29,
      "revelationType": "Meccan",
      "page": 586
    },
    {
      "number": 82,
      "name": "سورة الإنفطار",
      "englishName": "Al-Infitaar",
      "englishNameTranslation": "The Cleaving",
      "numberOfAyahs": 19,
      "revelationType": "Meccan",
      "page": 587
    },
    {
      "number": 83,
      "name": "سورة المطففين",
      "englishName": "Al-Mutaffifin",
      "englishNameTranslation": "Defrauding",
      "numberOfAyahs": 36,
      "revelationType": "Meccan",
      "page": 587
    },
    {
      "number": 84,
      "name": "سورة الإنشقاق",
      "englishName": "Al-Inshiqaaq",
      "englishNameTranslation": "The Splitting Open",
      "numberOfAyahs": 25,
      "revelationType": "Meccan",
      "page": 589
    },
    {
      "number": 85,
      "name": "سورة البروج",
      "englishName": "Al-Burooj",
      "englishNameTranslation": "The Constellations",
      "numberOfAyahs": 22,
      "revelationType": "Meccan",
      "page": 590
    },
    {
      "number": 86,
      "name": "سورة الطارق",
      "englishName": "At-Taariq",
      "englishNameTranslation": "The Morning Star",
      "numberOfAyahs": 17,
      "revelationType": "Meccan",
      "page": 590
    },
    {
      "number": 87,
      "name": "سورة الأعلى",
      "englishName": "Al-A'laa",
      "englishNameTranslation": "The Most High",
      "numberOfAyahs": 19,
      "revelationType": "Meccan",
      "page": 591
    },
    {
      "number": 88,
      "name": "سورة الغاشية",
      "englishName": "Al-Ghaashiya",
      "englishNameTranslation": "The Overwhelming",
      "numberOfAyahs": 26,
      "revelationType": "Meccan",
      "page": 592
    },
    {
      "number": 89,
      "name": "سورة الفجر",
      "englishName": "Al-Fajr",
      "englishNameTranslation": "The Dawn",
      "numberOfAyahs": 30,
      "revelationType": "Meccan",
      "page": 593
    },
    {
      "number": 90,
      "name": "سورة البلد",
      "englishName": "Al-Balad",
      "englishNameTranslation": "The City",
      "numberOfAyahs": 20,
      "revelationType": "Meccan",
      "page": 594
    },
    {
      "number": 91,
      "name": "سورة الشمس",
      "englishName": "Ash-Shams",
      "englishNameTranslation": "The Sun",
      "numberOfAyahs": 15,
      "revelationType": "Meccan",
      "page": 595
    },
    {
      "number": 92,
      "name": "سورة الليل",
      "englishName": "Al-Lail",
      "englishNameTranslation": "The Night",
      "numberOfAyahs": 21,
      "revelationType": "Meccan",
      "page": 595
    },
    {
      "number": 93,
      "name": "سورة الضحى",
      "englishName": "Ad-Dhuhaa",
      "englishNameTranslation": "The Morning Hours",
      "numberOfAyahs": 11,
      "revelationType": "Meccan",
      "page": 596
    },
    {
      "number": 94,
      "name": "سورة الشرح",
      "englishName": "Ash-Sharh",
      "englishNameTranslation": "The Consolation",
      "numberOfAyahs": 8,
      "revelationType": "Meccan",
      "page": 596
    },
    {
      "number": 95,
      "name": "سورة التين",
      "englishName": "At-Tin",
      "englishNameTranslation": "The Fig",
      "numberOfAyahs": 8,
      "revelationType": "Meccan",
      "page": 597
    },
    {
      "number": 96,
      "name": "سورة العلق",
      "englishName": "Al-Alaq",
      "englishNameTranslation": "The Clot",
      "numberOfAyahs": 19,
      "revelationType": "Meccan",
      "page": 597
    },
    {
      "number": 97,
      "name": "سورة القدر",
      "englishName": "Al-Qadr",
      "englishNameTranslation": "The Power, Fate",
      "numberOfAyahs": 5,
      "revelationType": "Meccan",
      "page": 598
    },
    {
      "number": 98,
      "name": "سورة البينة",
      "englishName": "Al-Bayyina",
      "englishNameTranslation": "The Evidence",
      "numberOfAyahs": 8,
      "revelationType": "Medinan",
      "page": 598
    },
    {
      "number": 99,
      "name": "سورة الزلزلة",
      "englishName": "Az-Zalzala",
      "englishNameTranslation": "The Earthquake",
      "numberOfAyahs": 8,
      "revelationType": "Medinan",
      "page": 599
    },
    {
      "number": 100,
      "name": "سورة العاديات",
      "englishName": "Al-Aadiyaat",
      "englishNameTranslation": "The Chargers",
      "numberOfAyahs": 11,
      "revelationType": "Meccan",
      "page": 599
    },
    {
      "number": 101,
      "name": "سورة القارعة",
      "englishName": "Al-Qaari'a",
      "englishNameTranslation": "The Calamity",
      "numberOfAyahs": 11,
      "revelationType": "Meccan",
      "page": 600
    },
    {
      "number": 102,
      "name": "سورة التكاثر",
      "englishName": "At-Takaathur",
      "englishNameTranslation": "Competition",
      "numberOfAyahs": 8,
      "revelationType": "Meccan",
      "page": 600
    },
    {
      "number": 103,
      "name": "سورة العصر",
      "englishName": "Al-Asr",
      "englishNameTranslation": "The Declining Day, Epoch",
      "numberOfAyahs": 3,
      "revelationType": "Meccan",
      "page": 601
    },
    {
      "number": 104,
      "name": "سورة الهمزة",
      "englishName": "Al-Humaza",
      "englishNameTranslation": "The Traducer",
      "numberOfAyahs": 9,
      "revelationType": "Meccan",
      "page": 601
    },
    {
      "number": 105,
      "name": "سورة الفيل",
      "englishName": "Al-Fil",
      "englishNameTranslation": "The Elephant",
      "numberOfAyahs": 5,
      "revelationType": "Meccan",
      "page": 601
    },
    {
      "number": 106,
      "name": "سورة قريش",
      "englishName": "Quraish",
      "englishNameTranslation": "Quraysh",
      "numberOfAyahs": 4,
      "revelationType": "Meccan",
      "page": 602
    },
    {
      "number": 107,
      "name": "سورة الماعون",
      "englishName": "Al-Maa'un",
      "englishNameTranslation": "Almsgiving",
      "numberOfAyahs": 7,
      "revelationType": "Meccan",
      "page": 602
    },
    {
      "number": 108,
      "name": "سورة الكوثر",
      "englishName": "Al-Kawthar",
      "englishNameTranslation": "Abundance",
      "numberOfAyahs": 3,
      "revelationType": "Meccan",
      "page": 602
    },
    {
      "number": 109,
      "name": "سورة الكافرون",
      "englishName": "Al-Kaafiroon",
      "englishNameTranslation": "The Disbelievers",
      "numberOfAyahs": 6,
      "revelationType": "Meccan",
      "page": 603
    },
    {
      "number": 110,
      "name": "سورة النصر",
      "englishName": "An-Nasr",
      "englishNameTranslation": "Divine Support",
      "numberOfAyahs": 3,
      "revelationType": "Medinan",
      "page": 603
    },
    {
      "number": 111,
      "name": "سورة المسد",
      "englishName": "Al-Masad",
      "englishNameTranslation": "The Palm Fibre",
      "numberOfAyahs": 5,
      "revelationType": "Meccan",
      "page": 603
    },
    {
      "number": 112,
      "name": "سورة الإخلاص",
      "englishName": "Al-Ikhlaas",
      "englishNameTranslation": "Sincerity",
      "numberOfAyahs": 4,
      "revelationType": "Meccan",
      "page": 604
    },
    {
      "number": 113,
      "name": "سورة الفلق",
      "englishName": "Al-Falaq",
      "englishNameTranslation": "The Dawn",
      "numberOfAyahs": 5,
      "revelationType": "Meccan",
      "page": 604
    },
    {
      "number": 114,
      "name": "سورة الناس",
      "englishName": "An-Naas",
      "englishNameTranslation": "Mankind",
      "numberOfAyahs": 6,
      "revelationType": "Meccan",
      "page": 604
    }
  ]