export default [
    {
        "identifier": "ar.abdulbasitmurattal",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Abdul Basit",
        "englishName": "Abdul Basit",
        "format": "audio",
        "type": "translation"
      },
      {
        "identifier": "ar.abdullahbasfar",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Abdullah Basfar",
        "englishName": "Abdullah Basfar",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.abdurrahmaansudais",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Abdurrahmaan As-Sudais",
        "englishName": "Abdurrahmaan As-Sudais",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.abdulsamad",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Abdul Samad",
        "englishName": "Abdul Samad",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.shaatree",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Abu Bakr Ash-Shaatree",
        "englishName": "Abu Bakr Ash-Shaatree",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.ahmedajamy",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Ahmed ibn Ali al-Ajamy",
        "englishName": "Ahmed ibn Ali al-Ajamy",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.alafasy",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Alafasy",
        "englishName": "Mishary Alafasy",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.hanirifai",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Hani Rifai",
        "englishName": "Hani Rifai",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.husary",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Husary",
        "englishName": "Husary",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.husarymujawwad",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Husary (Mujawwad)",
        "englishName": "Husary (Mujawwad)",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.hudhaify",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Hudhaify",
        "englishName": "Hudhaify",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.ibrahimakhbar",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Ibrahim Akhdar",
        "englishName": "Ibrahim Akhdar",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.mahermuaiqly",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Maher Al Muaiqly",
        "englishName": "Maher Al Muaiqly",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.minshawi",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Minshawi",
        "englishName": "Minshawi",
        "format": "audio",
        "type": "translation"
      },
      {
        "identifier": "ar.minshawimujawwad",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Minshawy (Mujawwad)",
        "englishName": "Minshawy (Mujawwad)",
        "format": "audio",
        "type": "translation"
      },
      {
        "identifier": "ar.muhammadayyoub",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Muhammad Ayyoub",
        "englishName": "Muhammad Ayyoub",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.muhammadjibreel",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Muhammad Jibreel",
        "englishName": "Muhammad Jibreel",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.saoodshuraym",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Saood bin Ibraaheem Ash-Shuraym",
        "englishName": "Saood bin Ibraaheem Ash-Shuraym",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "en.walk",
        "language": "en",
        "languageName": "English",
        "name": "Ibrahim Walk",
        "englishName": "Ibrahim Walk",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "fa.hedayatfarfooladvand",
        "language": "fa",
        "languageName": "Persian",
        "name": "Fooladvand - Hedayatfar",
        "englishName": "Fooladvand - Hedayatfar",
        "format": "audio",
        "type": "translation"
      },
      {
        "identifier": "ar.parhizgar",
        "language": "ar",
        "languageName": "Arabic",
        "name": "Parhizgar",
        "englishName": "Parhizgar",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ur.khan",
        "language": "ur",
        "languageName": "Urdu",
        "name": "Shamshad Ali Khan",
        "englishName": "Shamshad Ali Khan",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "zh.chinese",
        "language": "zh",
        "languageName": "Chinese",
        "name": "中文",
        "englishName": "Chinese",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "fr.leclerc",
        "language": "fr",
        "languageName": "French",
        "name": "Youssouf Leclerc",
        "englishName": "Youssouf Leclerc",
        "format": "audio",
        "type": "versebyverse"
      },
      {
        "identifier": "ar.aymanswoaid",
        "language": "ar",
        "languageName": "Arabic",
        "name": "أيمن سويد",
        "englishName": "Ayman Sowaid",
        "format": "audio",
        "type": "versebyverse"
      }
]