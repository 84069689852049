import React, { useState, useEffect, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import databypage from "../data/completeByPage";
import Slider from "react-slick";
import Img from "react-image";   
import { StoreContext } from "../contexts/StoreContext";
import { IoIosBook } from "react-icons/io";
import { BsFillBookHalfFill, BsBook } from "react-icons/bs";
import { ThemeContext } from '../contexts/ThemeContext';
import CustomImage from '../components/CustomImage';
import ShareLinks from "../components/ShareLinks";
import AudioAyat from '../components/AudioAyat';
import { useTranslation } from "react-i18next";

let QuranData = databypage.reverse();

function QuranPage(props) {
  const { t, i18n } = useTranslation();
  const { translation, mode, updateMode, menuHide, updateRecentPage, reciter } = useContext(StoreContext);
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  let { name, page } = useParams();
  const { pageNumber } = props.location.data ? props.location.data : 2;
  let initpage = 1 + (603 - page);
  const [initNo, setInitNo] = useState(initpage)
  const [prevodData, setPrevodData] = useState(() => {
    let arraymerge = databypage.slice();
    arraymerge.map((item, index) => {
      Object.values(item)[0].map((item2, index2) => {
        item2.texttransl = Object.values(translation[index])[0][index2]["text"];
      });
    });
    return arraymerge;
  });
  const [playAudio, setPlayAudio] = useState(() => {
    let audioarray = databypage.slice();
    let ajet = 1;
    audioarray.map((item, index) => {
      Object.values(item)[0].map((item2, index2) => {
        if(index2 == 0) {
        if (item2.numpage == page) {
          ajet = item2.number
          }
        }
      });
    });
    return ajet;
  });

  useEffect(() => {
    console.log("");
  });

  const styles = {
    item: {
      fontSize: 10,
    },
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 180000,
    cssEase: "linear",
    slidesToShow: 2,
    slidesToScroll: 2,
    swipeToSlide: true,
    draggable: true,
    lazyLoad: true,
    initialSlide: initNo,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: function (currentSlide, nextSlide) {
    //  console.log("before change", currentSlide, nextSlide);
    updateRecentPage(nextSlide);
    },
    afterChange: function (currentSlide) {
     // console.log("after change", currentSlide);
    // updateRecentPage(currentSlide);
      setInitNo(currentSlide);
    },
  };
  const settingsTwo = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 180000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    lazyLoad: true,
    initialSlide: initNo,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: function (currentSlide, nextSlide) {
     // console.log("before change", currentSlide, nextSlide); 
     updateRecentPage(nextSlide);
    },
    afterChange: function (currentSlide) {
     // console.log("after change", currentSlide);
      setInitNo(currentSlide);
    },
  };

  return (
    <div className="Suhufi">
      <div className={"Mode-links " + (menuHide ? 'Sidebar-show' : 'Sidebar-hide')}>
        <BsBook className="Mode-link" onClick={() => updateMode(3)} />
        <BsFillBookHalfFill
          className="Mode-link arabic-translate"
          onClick={() => updateMode(2)}
        />
        <IoIosBook className="Mode-link" onClick={() => updateMode(1)} />
      </div>
      {mode == 1 ? (
        <div className={"Slider-wrapper " + (isLightTheme ? "light" : "dark")}>
          <div className="Slider-container">
            <Slider {...settings}>
              {databypage.map((item, index) =>
                Object.values(item)[0].map((it, key) => {
                  return key == 0 ? (
                    <div key={key}>
                      <div className="Page-no" style={styles.item}>
                        <span style={{ color: theme.syntax }}>{t('Juz')} {it.juz}</span>
                        <span style={{ color: theme.syntax }}>{it.numpage}</span>
                        <span style={{ color: theme.syntax }}>{it.englishName}</span>
                      </div>
                      <CustomImage 
                            lightpath={it.url} 
                            darkpath={it.urldark}
                            darkmode={isLightTheme} />
                    </div>
                  ) : null;
                })
              )}
            </Slider>
          </div>
        </div>
      ) : null}
      {mode == 2 ? (
         <div className={"Slider-wrapper " + (isLightTheme ? "light" : "dark")}>
        <div className="Slider-container">
          <Slider {...settingsTwo}>
            {prevodData.map((item, index) => {
              const text = Object.values(item)[0].map((it, index) => (
                <div className="Text-left" key={index}>
                  <span className="Number-in-sura">{it.numberInSurah}. </span>
                  {it.texttransl}
                </div>
              ));
              const slika = Object.values(item)[0].map((it, index) => {
                return index == 0 ? (
                  <div key={index}>
                    <CustomImage 
                          lightpath={it.url} 
                          darkpath={it.urldark}
                          darkmode={isLightTheme} />
                  </div>
                ) : null;
              });
              const info = Object.values(item)[0].map((it, index) => {
                return index == 0 ? (
                  <div key={index} className="Page-no" style={styles.item}>
                    <span style={{ color: theme.syntax }}>{t('Juz')} {it.juz}</span>
                    <span style={{ color: theme.syntax }}>{it.numpage}</span>
                    <span style={{ color: theme.syntax }}> {it.englishName} </span>
                  </div>
                ) : null;
              });
              return (
                <div className="Translate-arabic-slider-item" key={index}>
                  {info}
                  <div className="TranslateArabic-page">
                    <div className="Translate-text-box" style={{ color: theme.syntax }}>{text}</div>
                    <div>{slika}</div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
          </div>
      ) : null}
      {mode == 3 ? (
        <div className={"Slider-wrapper " + (isLightTheme ? "light" : "dark")}>
        <div className="Slider-container">
          <Slider {...settings}>
            {translation.map((item, index) => (
              <div className="Translate-slider-item" key={index}>
                {Object.values(item)[0].map((it, key) => {
                  return (
                    <div key={key}>
                      {key == 0 ? (
                        <div className="Page-no" style={styles.item}>
                          <span style={{ color: theme.syntax }}>{t('Juz')} {it.juz}</span>
                          <span style={{ color: theme.syntax }}>{it.numpage}</span>
                          <span style={{ color: theme.syntax }}>{it.englishName} </span>
                        </div>
                      ) : null}
                      <div className="Translate-text-box" style={{ color: theme.syntax }}><span className="Number-in-sura">{it.numberInSurah}. </span>{it.text}</div>
                    </div>
                  );
                })}
              </div>
            ))}
          </Slider>
        </div>
        </div>
      ) : null}
      <ShareLinks />
      <AudioAyat ayat={playAudio} sejh={reciter} page={page} />
    </div>
  );
}

export default withRouter(QuranPage);
