import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ThemeContext } from '../contexts/ThemeContext';

function MenuList({ title, list }) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;
  const [showList, setShowList] = useState(false);
  const listShowHide = () => {
    setShowList(!showList);
  };
  return (
    <div className="Sidebar-links">
      <button className="Sidebar-button">
        <div className="Sidebar-button-title" style={{ color: theme.syntax }} onClick={listShowHide}>
          <span>{title}</span>
          {!showList ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </div>
      </button>
      {showList ? (
        <ul className="Sidebar-lists">
          {list.map((item, index) =>
            item.data ? (
              <li key={index} className="Sidebar-list Sidebar-juz-list">
                <span className="Sidebar-link" style={{ color: theme.syntax }}>{item.englishName}</span>
                {item.data.map((juz, i) => {
                  return (
                    <Link
                      className="Sidebar-link Juz-link"
                      style={{ color: theme.syntax }}
                      to={{
                        pathname:
                          "/surah/" + `${juz.sura}` + "/page/" + `${juz.page}`,
                        data: {
                          pageNumber: `${juz.page}`,
                        },
                      }}
                    >
                      {juz.sura} - {juz.page}
                    </Link>
                  );
                })}
              </li>
            ) : (
              <li key={index} className="Sidebar-list">
                <Link
                  className="Sidebar-link"
                  style={{ color: theme.syntax }}
                  to={{
                    pathname:
                      "/surah/" +
                      `${item.englishName}` +
                      "/page/" +
                      `${item.page}`,
                    data: {
                      pageNumber: `${item.page}`,
                    },
                  }}
                >
                  {item.englishName} - {item.number == 1 ? '1' : item.page}
                </Link>
              </li>
            )
          )}
        </ul>
      ) : null}
    </div>
  );
}

export default MenuList;
