import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from '../contexts/ThemeContext';

function Card({ data }) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const theme = isLightTheme ? light : dark;

  return (
    <div className="Card" style={{ background: theme.sidebarbg, color: theme.syntax }}>
      <div className="Card-header">{data.name}</div>
      <div className="Card-body">
        <p className="Ayat-text">{data.ayat}</p>
        <p>{data.text}</p>
      </div>
    </div>
  );
}

export default Card;
