export default [
    {
      surah: 'Al-Faatiha',
      duas: [
        {
          id: '1', 
          name: 'Al-Faatiha 6-7',
          ayat: 'اهْدِنَا الصِّرَاطَ الْمُسْتَقِيم  صِرَاطَ الَّذِينَ أَنْعَمْتَ عَلَيْهِمْ غَيْرِ الْمَغْضُوبِ عَلَيْهِمْ وَلَا الضَّالِّينَ',
          text: 'Guide us to the straight path - The path of those upon whom You have bestowed favor, not of those who have evoked [Your] anger or of those who are astray.'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'Al-Baqara',
      duas: [
        {
          id: '2',
          name: 'El-Bekare 127',
          ayat: ' رَبَّنَا تَقَبَّلْ مِنَّا إِنَّكَ أَنْتَ السَّمِيعُ الْعَلِيمُ ',
          text: 'Our Lord, accept [this] from us. Indeed You are the Hearing, the Knowing.'
        },
        {
          id: '3',
          name: 'Al-Baqara 128',
          ayat: 'رَبَّنَا وَاجْعَلْنَا مُسْلِمَيْنِ لَكَ وَمِنْ ذُرِّيَّتِنَا أُمَّةً مُسْلِمَةً لَكَ وَأَرِنَا مَنَاسِكَنَا وَتُبْ عَلَيْنَا إِنَّكَ أَنْتَ التَّوَّابُ الرَّحِيمُ',
          text: 'Our Lord, and make us Muslims [in submission] to You and from our descendants a Muslim nation [in submission] to You. And show us our rites and accept our repentance. Indeed, You are the Accepting of repentance, the Merciful.'
        },
        {
          id: '4',
          name: 'Al-Baqara 129',
          ayat: 'رَبَّنَا وَابْعَثْ فِيهِمْ رَسُولًا مِنْهُمْ يَتْلُو عَلَيْهِمْ آيَاتِكَ وَيُعَلِّمُهُمُ الْكِتَابَ وَالْحِكْمَةَ وَيُزَكِّيهِمْ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ',
          text: 'Our Lord, and send among them a messenger from themselves who will recite to them Your verses and teach them the Book and wisdom and purify them. Indeed, You are the Exalted in Might, the Wise.'
        },
        {
          id: '5',
          name: 'Al-Baqara 201',
          ayat: 'وَمِنْهُمْ مَنْ يَقُولُ رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ',
          text: 'Our Lord, give us in this world [that which is] good and in the Hereafter [that which is] good and protect us from the punishment of the Fire.'
        },
        {
          id: '6',
          name: 'Al-Baqara 250',
          ayat: 'وَلَمَّا بَرَزُوا لِجَالُوتَ وَجُنُودِهِ قَالُوا رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Our Lord, pour upon us patience and plant firmly our feet and give us victory over the disbelieving people.'
        },
        {
          id: '7',
          name: 'Al-Baqara 285',
          ayat: ' وَقَالُوا سَمِعْنَا وَأَطَعْنَا غُفْرَانَكَ رَبَّنَا وَإِلَيْكَ الْمَصِير',
          text: 'We hear and we obey. [We seek] Your forgiveness, our Lord, and to You is the [final] destination.'
        },
        {
          id: '8',
          name: 'Al-Baqara 286',
          ayat: 'رَبَّنَا لَا تُؤَاخِذْنَا إِنْ نَسِينَا أَوْ أَخْطَأْنَا رَبَّنَا وَلَا تَحْمِلْ عَلَيْنَا إِصْرًا كَمَا حَمَلْتَهُ عَلَى الَّذِينَ مِنْ قَبْلِنَا رَبَّنَا وَلَا تُحَمِّلْنَا مَا لَا طَاقَةَ لَنَا بِهِ وَاعْفُ عَنَّا وَاغْفِرْ لَنَا وَارْحَمْنَا أَنْتَ مَوْلَانَا فَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Our Lord, do not impose blame upon us if we have forgotten or erred. Our Lord, and lay not upon us a burden like that which You laid upon those before us. Our Lord, and burden us not with that which we have no ability to bear. And pardon us; and forgive us; and have mercy upon us. You are our protector, so give us victory over the disbelieving people.'
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'Aal-Imran',
      duas: [
        {
          id: '9',
          name: 'Aal-Imran 8-9',
          ayat: ' رَبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِنْ لَدُنْكَ رَحْمَةً إِنَّكَ أَنْتَ الْوَهَّابُ. رَبَّنَا إِنَّكَ جَامِعُ النَّاسِ لِيَوْمٍ لَا رَيْبَ فِيهِ إِنَّ اللَّهَ لَا يُخْلِفُ الْمِيعَادَ ',
          text: 'Our Lord, let not our hearts deviate after You have guided us and grant us from Yourself mercy. Indeed, You are the Bestower. Our Lord, surely You will gather the people for a Day about which there is no doubt. Indeed, Allah does not fail in His promise.'
        },
        {
          id: '10',
          name: 'Aal-Imran 16',
          ayat: '  رَبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّار ',
          text: 'Our Lord, indeed we have believed, so forgive us our sins and protect us from the punishment of the Fire.'
        },
        {
          id: '11',
          name: 'Aal-Imran 38',
          ayat: ' رَبِّ هَبْ لِي مِنْ لَدُنْكَ ذُرِّيَّةً طَيِّبَةً إِنَّكَ سَمِيعُ الدُّعَاءِ',
          text: 'My Lord, grant me from Yourself a good offspring. Indeed, You are the Hearer of supplication.'
        },
        {
          id: '12',
          name: 'Aal-Imran 53',
          ayat: 'رَبَّنَا آمَنَّا بِمَا أَنْزَلْتَ وَاتَّبَعْنَا الرَّسُولَ فَاكْتُبْنَا مَعَ الشَّاهِدِينَ',
          text: 'Our Lord, we have believed in what You revealed and have followed the messenger Jesus, so register us among the witnesses [to truth].'
        },
        {
          id: '13',
          name: 'Aal-Imran 147',
          ayat: ' رَبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وَانْصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ',
          text: 'Our Lord, forgive us our sins and the excess [committed] in our affairs and plant firmly our feet and give us victory over the disbelieving people.'
        },
        {
          id: '14',
          name: 'Aal-Imran 191-194',
          ayat: ' رَبَّنَا مَا خَلَقْتَ هَذَا بَاطِلًا سُبْحَانَكَ فَقِنَا عَذَابَ النَّارِ. رَبَّنَا إِنَّكَ مَنْ تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنْصَار. رَبَّنَا إِنَّنَا سَمِعْنَا مُنَادِيًا يُنَادِي لِلْإِيمَانِ أَنْ آمِنُوا بِرَبِّكُمْ فَآمَنَّا رَبَّنَا فَاغْفِرْ لَنَا ذُنُوبَنَا وَكَفِّرْ عَنَّا سَيِّئَاتِنَا وَتَوَفَّنَا مَعَ الْأَبْرَار. رَبَّنَا وَآتِنَا مَا وَعَدْتَنَا عَلَى رُسُلِكَ وَلَا تُخْزِنَا يَوْمَ الْقِيَامَةِ إِنَّكَ لَا تُخْلِفُ الْمِيعَادَ ',
          text: 'Our Lord, You did not create this aimlessly; exalted are You [above such a thing]; then protect us from the punishment of the Fire. Our Lord, indeed whoever You admit to the Fire - You have disgraced him, and for the wrongdoers there are no helpers. Our Lord, indeed we have heard a caller calling to faith, [saying], "Believe in your Lord," and we have believed. Our Lord, so forgive us our sins and remove from us our misdeeds and cause us to die with the righteous. Our Lord, and grant us what You promised us through Your messengers and do not disgrace us on the Day of Resurrection. Indeed, You do not fail in [Your] promise. '
        }
      ],
      icon: 'cloudy-night'
    },
    {
      surah: 'An-Nisaa',
      duas: [
        {
          id: '15',
          name: 'An-Nisaa 75',
          ayat: ' رَبَّنَا أَخْرِجْنَا مِنْ هَذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا',
          text: 'Our Lord, take us out of this city of oppressive people and appoint for us from Yourself a protector and appoint for us from Yourself a helper?'
        }
      ],
      icon: 'cloudy-night'
    },
    {
        surah: 'Al-Araaf',
        duas: [
          {
            id: '16',
            name: 'Al-Araaf 23',
            ayat: '  رَبَّنَا ظَلَمْنَا أَنْفُسَنَا وَإِنْ لَمْ تَغْفِرْ لَنَا وَتَرْحَمْنَا لَنَكُونَنَّ مِنَ الْخَاسِرِينَ',
            text: 'Our Lord, we have wronged ourselves, and if You do not forgive us and have mercy upon us, we will surely be among the losers.'
          },
          {
            id: '17',
            name: 'Al-Araaf 47',
            ayat: ' رَبَّنَا أَخْرِجْنَا مِنْ هَذِهِ الْقَرْيَةِ الظَّالِمِ أَهْلُهَا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ وَلِيًّا وَاجْعَلْ لَنَا مِنْ لَدُنْكَ نَصِيرًا',
            text: 'Our Lord, do not place us with the wrongdoing people.'
          },
          {
            id: '18',
            name: 'Al-Araaf 89',
            ayat: ' رَبَّنَا افْتَحْ بَيْنَنَا وَبَيْنَ قَوْمِنَا بِالْحَقِّ وَأَنْتَ خَيْرُ الْفَاتِحِينَ',
            text: 'Our Lord, decide between us and our people in truth, and You are the best of those who give decision.'
          },
          {
            id: '19',
            name: 'Al-Araaf 126',
            ayat: ' رَبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَتَوَفَّنَا مُسْلِمِينَ',
            text: 'Our Lord, pour upon us patience and let us die as Muslims [in submission to You].'
          },
          {
            id: '20',
            name: 'Al-Araaf 151',
            ayat: ' رَبِّ اغْفِرْ لِي وَلِأَخِي وَأَدْخِلْنَا فِي رَحْمَتِكَ وَأَنْتَ أَرْحَمُ الرَّاحِمِينَ',
            text: 'My Lord, forgive me and my brother and admit us into Your mercy, for You are the most merciful of the merciful.'
          },
          {
            id: '21',
            name: 'Al-Araaf 155-156',
            ayat: ' أَنْتَ وَلِيُّنَا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الْغَافِرِينَ. وَاكْتُبْ لَنَا فِي هَذِهِ الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ إِنَّا هُدْنَا إِلَيْكَ',
            text: 'You are our Protector, so forgive us and have mercy upon us; and You are the best of forgivers.  And decree for us in this world [that which is] good and [also] in the Hereafter; indeed, we have turned back to You.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Yunus',
        duas: [
          {
            id: '22',
            name: 'Yunus 85-86',
            ayat: ' عَلَى اللَّهِ تَوَكَّلْنَا رَبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِلْقَوْمِ الظَّالِمِينَ ',
            text: 'Upon Allah do we rely. Our Lord, make us not [objects of] trial for the wrongdoing people. And save us by Your mercy from the disbelieving people.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Hud',
        duas: [
          {
            id: '23',
            name: 'Hud 47',
            ayat: ' َرَبِّ إِنِّي أَعُوذُ بِكَ أَنْ أَسْأَلَكَ مَا لَيْسَ لِي بِهِ عِلْمٌ وَإِلَّا تَغْفِرْ لِي وَتَرْحَمْنِي أَكُنْ مِنَ الْخَاسِرِينَ ',
            text: 'My Lord, I seek refuge in You from asking that of which I have no knowledge. And unless You forgive me and have mercy upon me, I will be among the losers.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Yusuf',
        duas: [
          {
            id: '24',
            name: 'Yusuf 101',
            ayat: '  فَاطِرَ السَّمَاوَاتِ وَالْأَرْضِ أَنْتَ وَلِيِّي فِي الدُّنْيَا وَالْآخِرَةِ تَوَفَّنِي مُسْلِمًا وَأَلْحِقْنِي بِالصَّالِحِينَ',
            text: 'Creator of the heavens and earth, You are my protector in this world and in the Hereafter. Cause me to die a Muslim and join me with the righteous.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Ibrahim',
        duas: [
          {
            id: '25',
            name: 'Ibrahim 40-41',
            ayat: ' َرَبِّ اجْعَلْنِي مُقِيمَ الصَّلَاةِ وَمِنْ ذُرِّيَّتِي رَبَّنَا وَتَقَبَّلْ دُعَاءِ. رَبَّنَا اغْفِرْ لِي وَلِوَالِدَيَّ وَلِلْمُؤْمِنِينَ يَوْمَ يَقُومُ الْحِسَابُ',
            text: 'My Lord, make me an establisher of prayer, and [many] from my descendants. Our Lord, and accept my supplication. Our Lord, forgive me and my parents and the believers the Day the account is established.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Israa',
        duas: [
          {
            id: '26',
            name: 'Al-Israa 80',
            ayat: ' َرَبِّ أَدْخِلْنِي مُدْخَلَ صِدْقٍ وَأَخْرِجْنِي مُخْرَجَ صِدْقٍ وَاجْعَلْ لِي مِنْ لَدُنْكَ سُلْطَانًا نَصِيرًا',
            text: 'My Lord, cause me to enter a sound entrance and to exit a sound exit and grant me from Yourself a supporting authority.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Kehf',
        duas: [
          {
            id: '27',
            name: 'Al-Kehf 10',
            ayat: ' َرَبَّنَا آتِنَا مِنْ لَدُنْكَ رَحْمَةً وَهَيِّئْ لَنَا مِنْ أَمْرِنَا رَشَدًا',
            text: 'Our Lord, grant us from Yourself mercy and prepare for us from our affair right guidance.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Maryam',
        duas: [
          {
            id: '28',
            name: 'Maryam 5',
            ayat: ' هَبْ لِي مِنْ لَدُنْكَ وَلِيًّا',
            text: 'Give me from Yourself an heir.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Taa-haa',
        duas: [
          {
            id: '29',
            name: 'Taa-haa 25-28',
            ayat: ' رَبِّ اشْرَحْ لِي صَدْرِي, وَيَسِّرْ لِي أَمْرِي, َاحْلُلْ عُقْدَةً مِنْ لِسَانِي, يَفْقَهُوا قَوْلِي ',
            text: 'My Lord, expand for me my breast [with assurance] and ease for me my task and untie the knot from my tongue that they may understand my speech.'
          },
          {
            id: '30',
            name: 'Taa-haa 114 ',
            ayat: ' رَبِّ زِدْنِي عِلْمًا ',
            text: 'My Lord, increase me in knowledge.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Anbiyaa',
        duas: [
          {
            id: '31',
            name: 'Al-Anbiyaa 87',
            ayat: ' لَا إِلَهَ إِلَّا أَنْتَ سُبْحَانَكَ إِنِّي كُنْتُ مِنَ الظَّالِمِينَ ',
            text: 'There is no deity except You; exalted are You. Indeed, I have been of the wrongdoers.'
          },
          {
            id: '32',
            name: 'Al-Anbiyaa 89',
            ayat: ' رَبِّ لَا تَذَرْنِي فَرْدًا وَأَنْتَ خَيْرُ الْوَارِثِينَ ',
            text: 'My Lord, do not leave me alone [with no heir], while you are the best of inheritors.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Muminoon',
        duas: [
          {
            id: '33',
            name: 'Al-Muminoon 29',
            ayat: '  رَبِّ أَنْزِلْنِي مُنْزَلًا مُبَارَكًا وَأَنْتَ خَيْرُ الْمُنْزِلِينَ ',
            text: 'My Lord, let me land at a blessed landing place, and You are the best to accommodate [us].'
          },
          {
            id: '34',
            name: 'Al-Muminoon 97-98',
            ayat: ' رَبِّ أَعُوذُ بِكَ مِنْ هَمَزَاتِ الشَّيَاطِينِ, وَأَعُوذُ بِكَ رَبِّ أَنْ يَحْضُرُونِ ',
            text: 'My Lord, I seek refuge in You from the incitements of the devils, and I seek refuge in You, my Lord, lest they be present with me.'
          },
          {
            id: '35',
            name: 'Al-Muminoon 109',
            ayat: ' رَبَّنَا آمَنَّا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الرَّاحِمِينََ',
            text: 'Our Lord, we have believed, so forgive us and have mercy upon us, and You are the best of the merciful. '
          },
          {
            id: '36',
            name: 'Al-Muminoon 118',
            ayat: ' رَبِّ اغْفِرْ وَارْحَمْ وَأَنْتَ خَيْرُ الرَّاحِمِينَ ',
            text: 'My Lord, forgive and have mercy, and You are the best of the merciful.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'Al-Furqaan',
        duas: [
          {
            id: '37',
            name: 'Al-Furqaan 65-66',
            ayat: ' رَبَّنَا اصْرِفْ عَنَّا عَذَابَ جَهَنَّمَ إِنَّ عَذَابَهَا كَانَ غَرَامًا إِنَّهَا سَاءَتْ مُسْتَقَرًّا وَمُقَامًا ',
            text: 'Our Lord, avert from us the punishment of Hell. Indeed, its punishment is ever adhering; Indeed, it is evil as a settlement and residence.'
          },
          {
            id: '38',
            name: 'Al-Furqaan 74',
            ayat: ' رَبَّنَا هَبْ لَنَا مِنْ أَزْوَاجِنَا وَذُرِّيَّاتِنَا قُرَّةَ أَعْيُنٍ وَاجْعَلْنَا لِلْمُتَّقِينَ إِمَامًا ',
            text: 'Our Lord, grant us from among our wives and offspring comfort to our eyes and make us an example for the righteous.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Ash-Shuaraa',
        duas: [
          {
            id: '39',
            name: 'Ash-Shuaraa 83-87',
            ayat: ' رَبِّ هَبْ لِي حُكْمًا وَأَلْحِقْنِي بِالصَّالِحِينَ, وَاجْعَلْ لِي لِسَانَ صِدْقٍ فِي الْآخِرِينَ, وَاجْعَلْنِي مِنْ وَرَثَةِ جَنَّةِ النَّعِيمِ, وَاغْفِرْ لِأَبِي إِنَّهُ كَانَ مِنَ الضَّالِّينَ, وَلَا تُخْزِنِي يَوْمَ يُبْعَثُونَ ',
            text: 'My Lord, grant me authority and join me with the righteous.   And grant me a reputation of honor among later generations. And place me among the inheritors of the Garden of Pleasure. And forgive my father. Indeed, he has been of those astray. And do not disgrace me on the Day they are [all] resurrected. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
        surah: 'An-Naml',
          duas: [
            {
              id: '40',
              name: 'An-Naml 19',
              ayat: ' رَبِّ أَوْزِعْنِي أَنْ أَشْكُرَ نِعْمَتَكَ الَّتِي أَنْعَمْتَ عَلَيَّ وَعَلَى وَالِدَيَّ وَأَنْ أَعْمَلَ صَالِحًا تَرْضَاهُ وَأَدْخِلْنِي بِرَحْمَتِكَ فِي عِبَادِكَ الصَّالِحِينَ ',
              text: ' My Lord, enable me to be grateful for Your favor which You have bestowed upon me and upon my parents and to do righteousness of which You approve. And admit me by Your mercy into [the ranks of] Your righteous servants.'
            }
          ],
          icon: 'cloudy-night'
        },
      {
      surah: 'Al-Qasas',
        duas: [
          {
            id: '41',
            name: 'Al-Qasas 16',
            ayat: ' قَالَ رَبِّ إِنِّي ظَلَمْتُ نَفْسِي فَاغْفِرْ لِي فَغَفَرَ لَهُ إِنَّهُ هُوَ الْغَفُورُ الرَّحِيمُ ',
            text: 'My Lord, indeed I have wronged myself, so forgive me, and He forgave him. Indeed, He is the Forgiving, the Merciful. '
          }
        ],
        icon: 'cloudy-night'
      },
       {
      surah: 'Al-Ankaboot',
        duas: [
          {
            id: '42',
            name: 'Al-Ankaboot 30',
            ayat: '  رَبِّ انْصُرْنِي عَلَى الْقَوْمِ الْمُفْسِدِينَ ',
            text: ' My Lord, support me against the corrupting people. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Gafir (Al-Mumin)',
        duas: [
          {
            id: '43',
            name: 'Gafir 7-9',
            ayat: ' َرَبَّنَا وَسِعْتَ كُلَّ شَيْءٍ رَحْمَةً وَعِلْمًا فَاغْفِرْ لِلَّذِينَ تَابُوا وَاتَّبَعُوا سَبِيلَكَ وَقِهِمْ عَذَابَ الْجَحِيم, رَبَّنَا وَأَدْخِلْهُمْ جَنَّاتِ عَدْنٍ الَّتِي وَعَدْتَهُمْ وَمَنْ صَلَحَ مِنْ آبَائِهِمْ وَأَزْوَاجِهِمْ وَذُرِّيَّاتِهِمْ إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ, وَقِهِمُ السَّيِّئَاتِ وَمَنْ تَقِ السَّيِّئَاتِ يَوْمَئِذٍ فَقَدْ رَحِمْتَهُ وَذَلِكَ هُوَ الْفَوْزُ الْعَظِيمُ',
            text: 'Our Lord, You have encompassed all things in mercy and knowledge, so forgive those who have repented and followed Your way and protect them from the punishment of Hellfire. Our Lord, and admit them to gardens of perpetual residence which You have promised them and whoever was righteous among their fathers, their spouses and their offspring. Indeed, it is You who is the Exalted in Might, the Wise. And protect them from the evil consequences [of their deeds]. And he whom You protect from evil consequences that Day - You will have given him mercy. And that is the great attainment. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Ad-Dukhaan',
        duas: [
          {
            id: '44',
            name: 'Ad-Dukhaan 12',
            ayat: ' رَبَّنَا اكْشِفْ عَنَّا الْعَذَابَ إِنَّا مُؤْمِنُونَ',
            text: 'Our Lord, remove from us the torment; indeed, we are believers.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Ahqaf',
        duas: [
          {
            id: '45',
            name: 'Al-Ahqaf 15',
            ayat: ' رَبِّ أَوْزِعْنِي أَنْ أَشْكُرَ نِعْمَتَكَ الَّتِي أَنْعَمْتَ عَلَيَّ وَعَلَى وَالِدَيَّ وَأَنْ أَعْمَلَ صَالِحًا تَرْضَاهُ وَأَصْلِحْ لِي فِي ذُرِّيَّتِي إِنِّي تُبْتُ إِلَيْكَ وَإِنِّي مِنَ الْمُسْلِمِينََ',
            text: 'My Lord, enable me to be grateful for Your favor which You have bestowed upon me and upon my parents and to work righteousness of which You will approve and make righteous for me my offspring. Indeed, I have repented to You, and indeed, I am of the Muslims. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Hashr',
        duas: [
          {
            id: '46',
            name: 'Al-Hashr 10',
            ayat: ' رَبَّنَا اغْفِرْ لَنَا وَلِإِخْوَانِنَا الَّذِينَ سَبَقُونَا بِالْإِيمَانِ وَلَا تَجْعَلْ فِي قُلُوبِنَا غِلًّا لِلَّذِينَ آمَنُوا رَبَّنَا إِنَّكَ رَءُوفٌ رَحِيمٌ َ',
            text: 'Our Lord, forgive us and our brothers who preceded us in faith and put not in our hearts [any] resentment toward those who have believed. Our Lord, indeed You are Kind and Merciful.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Mumtahana',
        duas: [
          {
            id: '47',
            name: 'Al-Mumtahana 4-5',
            ayat: '  رَبَّنَا عَلَيْكَ تَوَكَّلْنَا وَإِلَيْكَ أَنَبْنَا وَإِلَيْكَ الْمَصِيرُ رَبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِلَّذِينَ كَفَرُوا وَاغْفِرْ لَنَا رَبَّنَا إِنَّكَ أَنْتَ الْعَزِيزُ الْحَكِيمُ َ',
            text: ' Our Lord, upon You we have relied, and to You we have returned, and to You is the destination. Our Lord, make us not [objects of] torment for the disbelievers and forgive us, our Lord. Indeed, it is You who is the Exalted in Might, the Wise.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'At-Tahrim',
        duas: [
          {
            id: '48',
            name: 'At-Tahrim 8',
            ayat: '  رَبَّنَا أَتْمِمْ لَنَا نُورَنَا وَاغْفِرْ لَنَا إِنَّكَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ َ',
            text: 'Our Lord, perfect for us our light and forgive us. Indeed, You are over all things competent.'
          },
          {
            id: '49',
            name: 'At-Tahrim 11',
            ayat: '  رَبِّ ابْنِ لِي عِنْدَكَ بَيْتًا فِي الْجَنَّةِ َ',
            text: 'My Lord, build for me near You a house in Paradise.'
          },
          {
            id: '50',
            name: 'At-Tahrim 11',
            ayat: ' نَجِّنِي مِنَ الْقَوْمِ الظَّالِمِينََ',
            text: 'Save me from the wrongdoing people.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Nooh',
        duas: [
          {
            id: '51',
            name: 'Nooh 26-28',
            ayat: ' رَبِّ لَا تَذَرْ عَلَى الْأَرْضِ مِنَ الْكَافِرِينَ دَيَّارًا, إِنَّكَ إِنْ تَذَرْهُمْ يُضِلُّوا عِبَادَكَ وَلَا يَلِدُوا إِلَّا فَاجِرًا كَفَّارًا, رَبِّ اغْفِرْ لِي وَلِوَالِدَيَّ وَلِمَنْ دَخَلَ بَيْتِيَ مُؤْمِنًا وَلِلْمُؤْمِنِينَ وَالْمُؤْمِنَاتِ وَلَا تَزِدِ الظَّالِمِينَ إِلَّا تَبَارًا ',
            text: 'My Lord, do not leave upon the earth from among the disbelievers an inhabitant. Indeed, if You leave them, they will mislead Your servants and not beget except [every] wicked one and [confirmed] disbeliever. My Lord, forgive me and my parents and whoever enters my house a believer and the believing men and believing women. And do not increase the wrongdoers except in destruction.'
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'Al-Falaq',
        duas: [
          {
            id: '52',
            name: 'Al-Falaq 1-5',
            ayat: ' أَعُوذُ بِرَبِّ الْفَلَق مِنْ شَرِّ مَا خَلَقَ وَمِنْ شَرِّ غَاسِقٍ إِذَا وَقَبَ وَمِنْ شَرِّ النَّفَّاثَاتِ فِي الْعُقَدِ وَمِنْ شَرِّ حَاسِدٍ إِذَا حَسَدَ ',
            text: 'I seek refuge in the Lord of daybreak, from the evil of that which He created, and from the evil of darkness when it settles, and from the evil of the blowers in knots, and from the evil of an envier when he envies. '
          }
        ],
        icon: 'cloudy-night'
      },
      {
      surah: 'An-Naas',
        duas: [
          {
            id: '53',
            name: 'An-Naas 1-6',
            ayat: '  أَعُوذُ بِرَبِّ النَّاسِ مَلِكِ النَّاسِ إِلَهِ النَّاسِ مِنْ شَرِّ الْوَسْوَاسِ الْخَنَّاسِ الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ مِنَ الْجِنَّةِ وَالنَّاسِ ',
            text: 'I seek refuge in the Lord of mankind, The Sovereign of mankind. The God of mankind, From the evil of the retreating whisperer - Who whispers [evil] into the breasts of mankind - From among the jinn and mankind.'
          }
        ],
        icon: 'cloudy-night'
      }
  ];
  