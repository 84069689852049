import React from "react";
import Layout from "./layout/Layout";
import "./App.css";
import Home from "./pages/Home";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import QuranPage from "./pages/QuranPage";
import Settings from "./pages/Settings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivacyPolicyDuas from "./pages/PrivacyPolicyDuas";
import MobileApps from "./pages/MobileApps";
import Duas from "./pages/Duas";
import StoreContextProvider from "./contexts/StoreContext";
import ThemeContextProvider from "./contexts/ThemeContext";

function App() {
  return (
    <ThemeContextProvider>
      <StoreContextProvider>
        <Layout>
          <AnimationApp />
        </Layout>
      </StoreContextProvider>
    </ThemeContextProvider>
  );
}

const AnimationApp = withRouter(({ location }) => {
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/duas/:suraname" children={<Duas />} />
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/mobile-apps">
            <MobileApps />
          </Route>
          <Route path="/page/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/page/duas/privacy-policy">
            <PrivacyPolicyDuas />
          </Route>
          <Route path="/surah/:name/page/:page" children={<QuranPage />} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default App;
