import React, { useState, useContext, useEffect, useRef } from "react";
import { IoIosRepeat, IoMdClose } from "react-icons/io";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { ThemeContext } from "../contexts/ThemeContext";
import { StoreContext } from "../contexts/StoreContext";

function AudioAyat(props) {
  const { isLightTheme, light, dark } = useContext(ThemeContext);
  const { reciter } = useContext(StoreContext);
  const theme = isLightTheme ? light : dark;
  const [audioAyat, setAudioAyat] = useState(() => {  
    let path = "/" + props.sejh + "/" + props.ayat + ".mp3";
    let url = `https://cdn.islamic.network/quran/audio/128${path}`;
    return url;
  });

  const [ayahs, setAyahs] = useState(() => {
    let option = [];
    for (let i = 1; i < 8; i++) {
      option.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return option;
  });

  const [surah, setSurah] = useState(() => {
    let option = [];
    let dataPage = "https://api.alquran.cloud/v1/surah";
    fetch(dataPage)
      .then((res) => res.json())
      .then(
        (result) => {
          let resObj = result.data;
          resObj.map((item, index) => {
            option.push(
              <option
                key={index}
                value={item.number}
                id={item.numberOfAyahs + 1}
              >
                {item.englishName}
              </option>
            );
          });
        },
        (error) => {
          console.log(error);
        }
      );
    return option;
  });
  const [ayatNo, setAyatNo] = useState(props.ayat);
  const [ucac, setUcac] = useState(props.sejh);
  const [options, setOptions] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [selectedAyah, setSelectedAyah] = useState(1);
  const [selectedSura, setSelectedSura] = useState(1);
  const [closeAudio, setCloseAudio] = useState(false);

  const audioEl = useRef(null);

  // useEffect(() => {
  //  console.log(reciter)
  // });

  const handleStart = () => {
    let newAyatNo = ayatNo + 1;
    setAyatNo(newAyatNo);
  };

  const handleEnded = () => {
    let url = `https://cdn.islamic.network/quran/audio/128/${ucac}/${ayatNo}.mp3`;
    console.log(url);
    audioEl.current.pause();
    audioEl.current.load();
    setAudioAyat(url);
    audioEl.current.play();
  };

  const handleOptions = () => {
    setOptions(!options);
  };

  const handleRepeat = () => {
    setRepeat(!repeat);
  };

  const handleSurah = (event) => {
    let tag = event.target[event.target.selectedIndex].id;
    setSelectedSura(event.target.value);
    setAyahs(() => {
      let option = [];
      for (let i = 1; i < tag; i++) {
        option.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return option;
    });
  };

  const handleChangeAyah = (event) => {
    setSelectedAyah(event.target.value);
  };

  const startRepeat = () => {
    audioEl.current.pause();
    audioEl.current.load();
    let apiData = "https://api.alquran.cloud/v1/surah/" + selectedSura;
    let startAyah = 1;
    fetch(apiData)
      .then((res) => res.json())
      .then(
        (result) => {
          let resOb = result.data.ayahs;
          resOb.map((item, index) => {
            if (item.numberInSurah == selectedAyah) {
              startAyah = item.number;
              setAyatNo(startAyah);
              let url = `https://cdn.islamic.network/quran/audio/128/${ucac}/${startAyah}.mp3`;
              setAudioAyat(url);
              audioEl.current.play();
              setRepeat(true);
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handlePrev = () => {
    audioEl.current.pause();
    audioEl.current.load();
    let newAyatNo = ayatNo - 2;
    setAyatNo(newAyatNo);
    let url = `https://cdn.islamic.network/quran/audio/128/${ucac}/${newAyatNo}.mp3`;
    setAudioAyat(url);
    audioEl.current.play();
  };

  const handleNext = () => {
    audioEl.current.pause();
    audioEl.current.load();
    let url = `https://cdn.islamic.network/quran/audio/128/${ucac}/${ayatNo}.mp3`;
    setAudioAyat(url);
    audioEl.current.play();
  };

  const startPlay = () => {
    audioEl.current.pause();
    audioEl.current.load();
    let apiData = "https://api.alquran.cloud/v1/surah/" + selectedSura;
    let startAyah = 1;
    fetch(apiData)
      .then((res) => res.json())
      .then(
        (result) => {
          let resOb = result.data.ayahs;
          resOb.map((item, index) => {
            if (item.numberInSurah == selectedAyah) {
              startAyah = item.number;
              setAyatNo(startAyah);
              let url = `https://cdn.islamic.network/quran/audio/128/${ucac}/${startAyah}.mp3`;
              setAudioAyat(url);
              audioEl.current.play();
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <div className={"Audio-ayat " + (closeAudio ? "Close-class" : "")}>
      <div
        className="Close-audio-box"
        onClick={() => setCloseAudio(!closeAudio)}
      >
        <IoMdClose className="Close-audio" />
      </div>
      {options ? (
        <div
          className="Audio-controls"
          style={{ background: theme.audioBg, color: theme.syntax }}
        >
          <div className="Audio-controls-box">
            <div className="Controls-item">
              <div className="Repeat-box">
                <label>Repeat</label>
                <input
                  name="repeat"
                  type="checkbox"
                  checked={repeat}
                  onChange={handleRepeat}
                />
              </div>
              <div className="Select-box">
                <div className="Select-sura-item">
                  <label>Surah:</label>
                  <select
                    className="Select-sura"
                    value={selectedSura}
                    onChange={handleSurah}
                  >
                    {surah}
                  </select>
                </div>
                <div className="Select-ayah-item">
                  <label>Ayahs:</label>
                  <select
                    className="Select-ayah"
                    value={selectedAyah}
                    onChange={handleChangeAyah}
                  >
                    {ayahs}
                  </select>
                </div>
              </div>
            </div>
            <div className="Controls-item">
              <div className="Start-button">
                {repeat ? (
                  <button onClick={startRepeat}>Repeat</button>
                ) : (
                  <button onClick={startPlay}>Play</button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="Player-controls">
        <audio
          preload="none"
          className="Audio-player"
          ref={audioEl}
          controls
          loop={repeat}
          onPlay={handleStart}
          onEnded={handleEnded}
        >
          <source src={audioAyat} type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
        <div className="Audio-open-button">
          <div className="Repeat-icon" onClick={handleOptions}>
            <IoIosRepeat className="Open-button" />
          </div>
          <div className="Prev-icon" onClick={handlePrev}>
            <FaChevronLeft className="Prev-button" />
          </div>
          <div className="Next-icon" onClick={handleNext}>
            <FaChevronRight className="Next-button" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudioAyat;
