import React, { useState, useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import Switch from "react-switch";
import { IoMdSunny, IoIosMoon } from "react-icons/io";

const ThemeToggle = () => {
  const { toggleTheme } = useContext(ThemeContext);
  const [checkedButton, setCheckedButton] = useState(false)

  const handleChange = (checked) => {
    setCheckedButton(checked);
    toggleTheme()
  }

  return ( 
    <Switch 
    onChange={handleChange} 
    checked={checkedButton} 
    offColor="#555"
    onColor="#49d0c5"
    offHandleColor="#fff"
    onHandleColor="#fff"
    checkedIcon={<IoMdSunny className="Sun" />}
    uncheckedIcon={<IoIosMoon className="Moon" />}

    />
  );
};
 
export default ThemeToggle;